<template>
  <Box text-align="center">
    <Button variant="outlined" icon-name="tasks">
      プロジェクト管理
    </Button>
  </Box>
  <Divider class="my-2" />
  <Flex v-if="isLoading" justify-center class="py-8">
    <Loading />
  </Flex>
  <Box v-if="!isLoading && favProjects.length === 0" class="py-8">
    <Typography text-align="center" size="s" line-height="1.5">
      お気に入りに登録されている<br />
      プロジェクトはありません
    </Typography>
  </Box>
  <template v-if="!isLoading && favProjects.length > 0">
    <Box max-height="400px" overflow="hidden auto" class="py-2">
      <template v-for="project in favProjects">
        <ListItem v-if="project.favorite" :key="project.projectId" dense>
          <Flex
            align-center
            justify-space-between
            @click="onClickRow(project.projectId)"
          >
            <Flex align-center>
              <Icon :name="compIcon(project.projectType)" class="mr-2" />
              <TypographyEllipsis width="100%">
                {{ project.projectName }}{{ project.projectId }}
              </TypographyEllipsis>
            </Flex>
            <IconButton
              icon-name="star"
              color="tertiary"
              @click.stop="onClickFavorite(project)"
            ></IconButton>
          </Flex>
        </ListItem>
      </template>
    </Box>
  </template>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import { CAMPAIGN_ROUTES } from '@/router';
import { useRouter } from 'vue-router';
import Box from '@/components/layout/Box.vue';
import Loading from '@/components/ui/Loading';
import Flex from '@/components/layout/Flex.vue';
import Icon from '@/components/ui/Icon.vue';
import IconButton from '@/components/ui/IconButton.vue';
import ListItem from '@/components/ui/ListItem.vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';

import {
  fetchProjectList,
  useProjectList
} from '@/composables/campaign/brand_lift/list';
import { useUserInfoStore } from '@/store/userInfo';

export default defineComponent({
  name: 'HeaderNavigationProjectMenu',
  components: {
    Box,
    Loading,
    Flex,
    Icon,
    IconButton,
    ListItem,
    Typography,
    TypographyEllipsis
  },
  setup() {
    // TODO: プロジェクトのショットカット機能はv2以降に持ち越しになりました。
    const router = useRouter();
    const { params } = router;
    const userInfoStore = useUserInfoStore();
    const companyInfo = userInfoStore.getCompanyInfo(params.companyId);
    const isLoading = ref(true);
    const favProjects = ref([]);
    // preアイコンの設定
    const intIcons = {
      REACH_ANALYSIS: 'chart-line'
    };

    // src/pages/campaign/index.vueを踏襲
    // プロジェクト一覧の取得
    // TODO: 2022.5月時点でリーチ分析しかないのでfetchProjectListを流しましたが、v2以降でリーチ分析以外のプロジェクト一覧取得API /companies/{company-id}/xxxx-projectsができる（だろう)と想定して、更にそれらをまとめた /companies/{company-id}/projects もしくは /companies/{company-id}/projects-fav ができる（だろう)と想定してください。
    const projects = fetchProjectList(
      companyInfo.workspaceId,
      companyInfo.companyId
    ).list;
    // アイコンクリックでお気に入りの解除
    const { onClickFavorite } = useProjectList();

    // プロジェクト一覧からお気に入りを抽出
    watch(
      projects,
      () => {
        isLoading.value = false;
        favProjects.value = projects.value.filter(project => project.favorite);
      },
      { deep: true }
    );

    // preアイコンを変更: default star
    const compIcon = key => {
      return intIcons[key] || 'star';
    };

    // リストクリックで遷移
    const onClickRow = projectId => {
      router.push({
        name: CAMPAIGN_ROUTES.graph,
        params: { projectId }
      });
    };

    return {
      CAMPAIGN_ROUTES,
      isLoading,
      projects,
      favProjects,
      compIcon,
      onClickFavorite,
      onClickRow
    };
  }
});
</script>
