<template>
  <g clip-path="url(#clip0_2_30384)">
    <path
      d="M3.65937 4.07757C3.93125 4.02664 4.20937 4.00007 4.49687 4.00007H9.50313C11.9875 4.00007 14 6.01257 14 8.49695C14 9.58445 13.6125 10.5876 12.9625 11.3688L16.6625 14.2688C17.7406 13.8157 18.5 12.7219 18.5 11.5032C18.5 9.9282 17.2781 8.61882 15.7031 8.5157L15.45 8.49695C15.0375 8.47195 14.725 8.11257 14.7531 7.70007C14.7781 7.28757 15.1375 6.97507 15.55 7.0032L15.8031 7.01882C18.1656 7.17507 20 9.13757 20 11.5032C20 13.0844 19.1844 14.4751 17.95 15.2782L19.7125 16.6594C20.0375 16.9157 20.0969 17.3876 19.8406 17.7126C19.5844 18.0376 19.1125 18.0969 18.7875 17.8407L0.287375 3.34039C-0.038625 3.08476 -0.09575 2.61351 0.15975 2.28745C0.415313 1.96145 0.886563 1.90429 1.21281 2.15982L3.65937 4.07757ZM5.475 5.50007L11.7531 10.4438C12.2313 9.91882 12.5 9.23757 12.5 8.49695C12.5 6.8407 11.1594 5.50007 9.50313 5.50007H5.475ZM10.4969 16.0001C8.0125 16.0001 6 13.9876 6 11.5032C6 10.9563 6.09687 10.4313 6.27812 9.94695L7.55 10.9501C7.51875 11.1282 7.5 11.3157 7.5 11.5032C7.5 13.1594 8.84062 14.5001 10.4969 14.5001H12.0562L13.9625 16.0001H10.4969ZM0.941875 5.7407L2.12 6.67195C1.73125 7.17507 1.5 7.80945 1.5 8.49695C1.5 10.0719 2.72281 11.3813 4.29688 11.4844L4.55 11.5032C4.9625 11.5282 5.275 11.8876 5.24687 12.3001C5.22187 12.7126 4.8625 13.0251 4.45 12.9969L4.19688 12.9813C1.835 12.8251 0 10.8626 0 8.49695C0 7.45945 0.351562 6.5032 0.941875 5.7407Z"
      fill-opacity="0.25"
    />
  </g>
  <defs>
    <clipPath id="clip0_2_30384">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
