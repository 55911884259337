
import { defineComponent, watch, computed } from 'vue';
import { useField } from 'vee-validate';
import BaseTextField from '@/components/ui/BaseTextField.vue';
import ErrorMessage from '@/components/ui/ErrorMessage.vue';

export default defineComponent({
  components: { BaseTextField, ErrorMessage },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    placeholder: String,
    inputmode: String,
    size: String,
    dense: Boolean,
    width: String,
    margin: String,
    textAlign: String
  },
  setup: props => {
    const { value: inputValue, errorMessage } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value
      }
    );

    watch(
      () => props.value,
      updatedValue => {
        inputValue.value = updatedValue;
      }
    );

    return {
      errorMessage,
      inputValue,
      sizeClass: computed(() => props.size || 's'),
      denseClass: computed(() => (props.dense ? 'dense' : ''))
    };
  }
});
