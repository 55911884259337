import { defineComponent } from 'vue';

export const CMSI_ROUTES = {
  layout: 'cmsiLayout',
  create: 'cmsiCreate',
  edit: 'cmsiEdit',
  reportEdit: 'cmsiReportEdit'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const MENU_TITLE = '売上効果分析';
const TITLE = {
  layout: '',
  create: 'レポート新規作成' + ' | ' + MENU_TITLE,
  edit: '編集' + ' | ' + MENU_TITLE,
  reportEdit: 'レポート編集' + ' | ' + MENU_TITLE
} as const;

const PATH = '/company/:companyId/cmsi';

export default [
  {
    name: CMSI_ROUTES.layout,
    path: PATH,
    meta: {
      children: true
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/cmsi/layout.vue'),
    children: [
      {
        path: `${PATH}/:productId/edit`,
        name: CMSI_ROUTES.edit,
        meta: {
          children: true,
          title: TITLE.edit
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/cmsi/edit.vue')
      },
      {
        path: `${PATH}/:productId/create`,
        name: CMSI_ROUTES.create,
        meta: {
          children: true,
          title: TITLE.create
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/cmsi/create.vue')
      },
      {
        path: `${PATH}/:productId/report/:reportId/edit`,
        name: CMSI_ROUTES.reportEdit,
        meta: {
          children: true,
          title: TITLE.reportEdit
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/cmsi/reportEdit.vue')
      }
    ]
  }
];
