<template>
  <span class="ui-required">
    <Typography size="xs" line-height="1" color="inherit">必須</Typography>
  </span>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'Required',
  components: { Typography }
});
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';

.ui-required {
  display: inline-block;
  padding: $spacing-base;
  background-color: var(--red-blue_red-5);
  border-radius: 4px;
  color: var(--contrast-color);
}
</style>
