<template>
  <Maintenance v-if="false" />
  <Flex
    v-else
    flex-direction="column"
    align-center
    justify-center
    height="100vh"
  >
    <Logo type="header" class="mb-14" />
    <ValidationForm
      class="login-form"
      :validation-schema="schema"
      v-slot="{ meta }"
      @submit="submitHandler"
    >
      <Card
        title="ログイン"
        :card-content-style="{
          margin: '0 auto',
          padding: '24px 15px'
        }"
      >
        <Flex alignCenter>
          <Label size="m" class="mr-4" bold>アカウント</Label>
          <Box flex="1">
            <VeeTextField size="m" type="text" name="email" />
          </Box>
        </Flex>

        <Flex alignCenter class="mt-6">
          <Label size="m" class="mr-4" bold>パスワード</Label>
          <Box flex="1">
            <VeeTextField size="m" type="password" name="password" />
          </Box>
        </Flex>
        <Flex justifyEnd class="mt-6">
          <Icon name="question" class="forget-password-icon" />
          <router-link :to="{ name: toForgotPasswordName }" class="ml-2">
            <TextLink size="m">
              パスワードを忘れた
            </TextLink>
          </router-link>
        </Flex>
        <Button
          class="mt-6"
          block
          type="submit"
          size="l"
          :disabled="!meta.valid || isLoading"
        >
          ログイン
        </Button>
      </Card>
    </ValidationForm>
    <Flex align-center class="mt-14">
      <router-link :to="{ name: toSignupName }" class="ml-2">
        <TextLink size="m">
          アカウントをお持ちでない方はこちら
        </TextLink>
      </router-link>
    </Flex>
  </Flex>
</template>
<script>
import { defineComponent } from 'vue';
import { ROUTE_NAMES } from '@/router';
import { Form as ValidationForm } from 'vee-validate';
import * as Yup from 'yup';
import { VALIDATION_SCHEMA } from '@/common/validation';
import { load } from 'recaptcha-v3';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import Label from '@/components/ui/Label.vue';
import Icon from '@/components/ui/Icon.vue';
import TextLink from '@/components/ui/TextLink.vue';
import VeeTextField from '@/components/veeValidate/VeeTextField.vue';
import useLogin from '@/composables/auth/login';
import Card from '@/components/ui/Card';
import Logo from '@/components/ui/Logo';
import Maintenance from '@/pages/Maintenance.vue';

export default defineComponent({
  name: 'Login',
  components: {
    Logo,
    Card,
    Box,
    Button,
    Flex,
    Label,
    TextLink,
    Icon,
    ValidationForm,
    VeeTextField,
    Maintenance
  },
  setup() {
    const { submitHandler, isLoading } = useLogin();
    const { email, password } = VALIDATION_SCHEMA;

    (() => {
      load(process.env.VUE_APP_RECAPTCHA_SITE_KEY, {
        autoHideBadge: true
      }).then(recaptcha => {
        recaptcha.hideBadge();
      });
    })();

    return {
      toForgotPasswordName: ROUTE_NAMES.forgotPassword,
      toSignupName: ROUTE_NAMES.signup,
      schema: Yup.object().shape({
        email,
        password
      }),
      submitHandler,
      isLoading
    };
  }
});
</script>
<style lang="scss" scoped>
.forget-password-icon {
  color: var(--dark-50-color);
}
.ui-card {
  width: 524px;
  margin: 0 auto;
}

@media (max-width: 540px) {
  form {
    width: 90%;
  }
  .ui-card {
    width: 100%;
  }
}
</style>
