<template>
  <path
    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
    fill="#E4EBFA"
  />
  <path
    d="M9.51709 26.249V24.94C9.51709 22.157 10.6171 19.495 12.5861 17.526C14.5551 15.557 17.2171 14.457 20.0001 14.457C22.7831 14.457 25.4451 15.557 27.4141 17.526C29.3832 19.495 30.4832 22.157 30.4832 24.94V26.249C30.4832 27.635 29.9331 28.966 28.9541 29.956C27.9751 30.935 26.6441 31.485 25.2471 31.485H14.7641C13.3781 31.485 12.0471 30.935 11.0571 29.956C10.0781 28.977 9.52809 27.646 9.52809 26.249H9.51709Z"
    fill="#4674D7"
  />
  <path
    d="M23.9931 19.2641L22.09 22.4651H23.5861C23.7621 22.4651 23.8941 22.5971 23.8941 22.7731V23.4001C23.8941 23.5761 23.7621 23.7191 23.5861 23.7191H21.353L20.935 24.4341V24.7971H23.5751C23.7511 24.7971 23.8941 24.9401 23.8941 25.1161V25.7651C23.8941 25.9411 23.7511 26.0951 23.5751 26.0951H20.935V27.9981C20.935 28.2511 20.715 28.4711 20.462 28.4711H19.527C19.274 28.4711 19.054 28.2511 19.054 27.9981V26.0951H16.535C16.359 26.0951 16.205 25.9411 16.205 25.7651V25.1161C16.205 24.9401 16.359 24.7971 16.535 24.7971H19.054V24.4341L18.658 23.7191H16.524C16.348 23.7191 16.205 23.5761 16.205 23.4001V22.7731C16.205 22.5971 16.348 22.4651 16.524 22.4651H17.976L16.018 19.2861C15.864 19.0331 16.04 18.7031 16.348 18.7031H17.525C17.822 18.7031 18.086 18.8681 18.229 19.1321L20.121 22.8941L21.848 19.1431C21.98 18.8791 22.244 18.7031 22.53 18.7031H23.6741C23.9711 18.7031 24.1471 19.0221 23.9931 19.2641Z"
    fill="#E4EBFA"
  />
  <path
    d="M22.8381 17.2624H16.788C16.271 17.2624 15.831 16.8994 15.721 16.3934C15.435 15.1174 14.588 13.8524 13.785 12.5765C12.894 11.1795 14.192 9.44145 15.798 9.83745C19.615 10.7945 21.507 9.33145 23.3881 8.47346C25.4671 7.52746 27.4911 9.87045 26.3141 11.8175C25.4011 13.3355 24.2571 14.8644 23.9161 16.3824C23.8061 16.8884 23.3661 17.2514 22.8491 17.2514L22.8381 17.2624Z"
    fill="#4674D7"
  />
</template>
