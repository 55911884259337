
import { defineComponent, computed } from 'vue';
import { PRIVILEGE } from '@/common/constant';
import Image from '@/components/ui/Image.vue';

export default defineComponent({
  name: 'AccountIcon',
  components: {
    Image
  },
  props: {
    imageUrl: String,
    /**
     * プラマリオーナー: 'PRIMARY_OWNER'<br />
     * オーナー: 'OWNER'<br />
     * 管理者：'MANAGER'<br />
     * メンバー: 'MEMBER'<br />
     */
    privilege: String,
    /**
     * `xs` : 24px * 24px<br />
     * `s` : 32px * 32px<br />
     * `m` : 64px * 64px<br />
     * `l` : 128px * 128px
     */
    size: {
      type: String,
      default: 's'
    }
  },
  setup(props) {
    return {
      sizeClass: computed(() => props.size || 's'),
      privilegeClass: computed(() => {
        switch (props.privilege) {
          case PRIVILEGE.primary:
          case PRIVILEGE.owner:
            return 'privilege owner';
          case PRIVILEGE.admin:
            return 'privilege admin';
          case PRIVILEGE.member:
            return 'privilege member';
          case PRIVILEGE.restricted:
          default:
            return '';
        }
      }),
      iconSides: computed(() => {
        if (props.size === 'xs') {
          return '15px';
        } else if (props.size === 'm') {
          return '32px';
        } else if (props.size === 'l') {
          return '48px';
        } else {
          return '20px';
        }
      })
    };
  }
});
