import { defineComponent } from 'vue';

export const PLANNING_ROUTES = {
  index: 'troIndex',
  tro: 'troProjectList',
  create: 'createTroProjectList',
  edit: 'editTroProject',
  clone: 'cloneTroProject',
  result: 'resultTroProject',
  rsm: 'rsmIndex',
  simulationCreate: 'simulationCreate',
  simulationEdit: 'simulationEdit',
  simulationClone: 'simulationClone',
  simulationResult: 'simulationResult'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE_BASE = ' | エフェクティブリーチ';
const TITLE = {
  tro: 'タイムCMプランナー' + TITLE_BASE,
  create: 'プロジェクト新規作成' + TITLE_BASE,
  edit: 'プロジェクト編集' + TITLE_BASE,
  clone: 'プロジェクト複製' + TITLE_BASE,
  result: 'プロジェクト結果' + TITLE_BASE,
  rsm: 'リーチシミュレーター' + TITLE_BASE,
  simulationCreate: 'シミュレーション作成' + TITLE_BASE,
  simulationEdit: 'シミュレーション編集' + TITLE_BASE,
  simulationClone: 'シミュレーション複製' + TITLE_BASE,
  simulationResult: 'シミュレーション結果' + TITLE_BASE
} as const;

export default [
  {
    path: '/company/:companyId',
    name: PLANNING_ROUTES.index,
    redirect: { name: PLANNING_ROUTES.tro },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/planning/index.vue'),
    children: [
      {
        path: 'tro',
        name: PLANNING_ROUTES.tro,
        meta: {
          children: true,
          title: TITLE.tro
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/tro/index.vue')
      },
      {
        path: 'tro/create',
        name: PLANNING_ROUTES.create,
        meta: {
          children: true,
          title: TITLE.create
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/tro/Create.vue')
      },
      {
        path: 'tro/:projectId/edit',
        name: PLANNING_ROUTES.edit,
        meta: {
          children: true,
          title: TITLE.edit
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/tro/Update.vue')
      },
      {
        path: 'tro/:projectId/clone',
        name: PLANNING_ROUTES.clone,
        meta: {
          children: true,
          title: TITLE.clone
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/tro/Clone.vue')
      },
      {
        path: 'tro/:projectId/result',
        name: PLANNING_ROUTES.result,
        meta: {
          children: true,
          title: TITLE.result
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/tro/Result.vue')
      },
      {
        path: 'rsm',
        name: PLANNING_ROUTES.rsm,
        meta: {
          children: true,
          title: TITLE.rsm
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/rsm/index.vue')
      },
      {
        path: 'rsm/create',
        name: PLANNING_ROUTES.simulationCreate,
        meta: {
          children: true,
          title: TITLE.simulationCreate
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/rsm/SimulationCreate.vue')
      },
      {
        path: 'rsm/:projectId/edit',
        name: PLANNING_ROUTES.simulationEdit,
        meta: {
          children: true,
          title: TITLE.simulationEdit
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/rsm/SimulationUpdate.vue')
      },
      {
        path: 'rsm/:projectId/clone',
        name: PLANNING_ROUTES.simulationClone,
        meta: {
          children: true,
          title: TITLE.simulationClone
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/rsm/SimulationClone.vue')
      },
      {
        path: 'rsm/:projectId/result',
        name: PLANNING_ROUTES.simulationResult,
        meta: {
          children: true,
          title: TITLE.simulationResult
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/planning/rsm/SimulationResult.vue')
      }
    ]
  }
];
