<template>
  <Flex flex-direction="column" align-center justify-center height="100vh">
    <Logo type="header" class="mb-9" />
    <ValidationForm
      class="login-form"
      :validation-schema="schema"
      v-slot="{ meta }"
      @submit="changePassword"
    >
      <Card
        :title="type === 'unAuth' ? 'パスワード初期化' : 'パスワードの初期設定'"
        :card-content-style="{
          margin: '0 auto',
          padding: '24px 15px'
        }"
      >
        <Flex alignCenter class="mb-6" v-if="type === 'unAuth'">
          <Box width="125px">
            <Label size="m" class="mr-4" bold>認証コード</Label>
          </Box>
          <Box flex="1">
            <VeeTextField size="m" type="text" name="code" />
          </Box>
        </Flex>

        <Flex alignCenter class="mb-6">
          <Box width="125px">
            <Label size="m" class="mr-4" bold>新しいパスワード</Label>
          </Box>
          <Box flex="1">
            <VeeTextField size="m" type="password" name="password" />
          </Box>
        </Flex>
        <Flex alignCenter class="mb-6">
          <Box width="125px">
            <Label size="m" class="mr-4" bold>もう一度入力</Label>
          </Box>
          <Box flex="1">
            <VeeTextField size="m" type="password" name="confirmPassword" />
          </Box>
        </Flex>
        <Button
          block
          type="submit"
          size="l"
          :disabled="!meta.valid || isLoading"
        >
          設定
        </Button>
      </Card>
    </ValidationForm>
    <Flex v-if="type === 'unAuth'" align-center class="mt-14">
      <a href="javascript:void(0)">
        <TextLink @click="resendConfirmCode" size="m"
          >認証コードを再送
        </TextLink>
      </a>
    </Flex>
  </Flex>
</template>
<script>
import { defineComponent } from 'vue';
import { ROUTE_NAMES } from '@/router';
import { Form as ValidationForm } from 'vee-validate';
import * as Yup from 'yup';
import { VALIDATION_SCHEMA } from '@/common/validation';
import { VALIDATION_MESSAGE } from '@/common/validation';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import Label from '@/components/ui/Label.vue';
import VeeTextField from '@/components/veeValidate/VeeTextField.vue';
import { useChangePassword } from '@/composables/auth/password';
import Card from '@/components/ui/Card';
import TextLink from '@/components/ui/TextLink';
import Logo from '@/components/ui/Logo';

export default defineComponent({
  name: 'PasswordReset',
  components: {
    Logo,
    Box,
    Button,
    Flex,
    Label,
    ValidationForm,
    VeeTextField,
    Card,
    TextLink
  },
  props: ['type'],
  setup(props) {
    const { password, confirmPassword } = VALIDATION_SCHEMA;
    const { changePassword, isLoading, resendConfirmCode } = useChangePassword(
      props.type
    );

    return {
      toLoginName: ROUTE_NAMES.login,
      schema: Yup.object().shape({
        code:
          props.type === 'unAuth'
            ? Yup.string().required(VALIDATION_MESSAGE.required)
            : false,
        password,
        confirmPassword
      }),
      changePassword,
      resendConfirmCode,
      isLoading
    };
  }
});
</script>

<style lang="scss" scoped>
.ui-card {
  width: 524px;
  margin: 0 auto;
}

@media (max-width: 540px) {
  form {
    width: 90%;
  }
  .ui-card {
    width: 100%;
  }
}
</style>
