import { ProjectApi } from '@/api';
import { Project, ReachAnalysisProject } from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import { fetchAccountAvatar } from '@/composables/fetchImage';
import useLoading from '@/composables/loading';
import useModal from '@/composables/modal';
import { ref, Ref } from 'vue';

type ProjectListItem = Project & {
  avatar: string | null;
};

interface fetchProjectListReturnType {
  list: Ref<Array<ProjectListItem>>;
  isFetchingList: Ref<boolean>;
}

interface useProjectListReturnType {
  isModalOpen: Ref<boolean>;
  isDeleting: Ref<boolean>;
  title: Ref<string | undefined>;
  closeModal: () => void;
  openModal: (ev: Event, index: number, projects: Array<Project>) => void;
  onClickDelete: (projects: Array<Project>) => Promise<void>;
}

export const useProjectList = (): useProjectListReturnType => {
  const { isModalOpen, openModal: _openModal, closeModal } = useModal();
  const currentReachAnalysisProjectId = ref<number | undefined>(0);
  const title = ref<string>();
  const isDeleting = ref<boolean>(false);
  const openModal = (ev: Event, index: number, projects: Array<Project>) => {
    ev.stopPropagation();
    currentReachAnalysisProjectId.value =
      projects[index].reachAnalysisProjectId;
    title.value = projects[index].projectName;
    _openModal();
  };

  const onClickDelete = async (projects: Array<Project>) => {
    isDeleting.value = true;
    const reachAnalysisProjectId = currentReachAnalysisProjectId.value;
    if (reachAnalysisProjectId) {
      try {
        const result = await ProjectApi.deleteReachAnalysisProjectsReachAnalysisProjectId(
          reachAnalysisProjectId
        );
        if (result.status < 200 || 300 <= result.status) {
          toast({
            title: 'エラー',
            message: '削除に失敗しました',
            variant: 'error'
          });
        } else {
          const index = projects.findIndex(
            project => project.reachAnalysisProjectId === reachAnalysisProjectId
          );
          projects.splice(index, 1);
          toast({
            title: `「${title.value}」を削除しました`,
            variant: 'success'
          });
        }
        isDeleting.value = false;
        closeModal();
      } catch (e) {
        toast({
          title: 'エラー',
          message: '削除に失敗しました',
          variant: 'error'
        });
        isDeleting.value = false;
      }
    }
  };
  return {
    isModalOpen,
    isDeleting,
    title,
    closeModal,
    openModal,
    onClickDelete
  };
};

export const fetchProjectList = (
  workspaceId: number | undefined,
  companyId: number | undefined
): fetchProjectListReturnType => {
  const list = ref<Array<ProjectListItem>>([]);
  const _fetchList = async () => {
    try {
      if (companyId && workspaceId) {
        const result = await ProjectApi.getCompaniesCompanyIdReachAnalysisProjects(
          companyId
        );
        if (200 <= result.status || result.status < 300) {
          if (result.data.list) {
            const asyncProjects = result.data.list.map(async value => {
              const accountId = value.authorSaasAccountId;
              if (accountId) {
                const accountAvatar = await fetchAccountAvatar(
                  accountId.toString()
                );
                return {
                  ...value,
                  avatar: accountAvatar
                } as ProjectListItem;
              }
              return {
                ...value
              } as ProjectListItem;
            });
            list.value = await Promise.all(asyncProjects);
          } else {
            list.value = [];
          }
        } else {
          toast({
            message: 'プロジェクトが取得できませんでした',
            variant: 'error'
          });
        }
      }
    } catch (e) {
      toast({
        message: 'プロジェクトが取得できませんでした',
        variant: 'error'
      });
    }
  };

  const [isFetchingList, fetchList] = useLoading(_fetchList);
  fetchList(null).then();

  return {
    list,
    isFetchingList
  };
};

export const fetchProject = async (
  projectId: number
): Promise<ReachAnalysisProject | undefined> => {
  try {
    const result = await ProjectApi.getReachAnalysisProject(projectId);
    if (result.status < 200 || 300 <= result.status) {
      toast({
        title: 'エラー',
        message: 'プロジェクト情報取得に失敗しました',
        variant: 'error'
      });
    } else {
      return result.data;
    }
  } catch (e) {
    toast({
      title: 'エラー',
      message: 'プロジェクト情報取得に失敗しました',
      variant: 'error'
    });
  }
};
