<template>
  <g clip-path="url(#clip0_331_1726)">
    <path
      d="M15.7363 10.5916L6.56278 19.8402C6.26908 20.1339 5.79415 20.1339 5.50045 19.8402L4.26314 18.6029C3.96944 18.3092 3.96944 17.8343 4.26314 17.5406L11.6682 10.0605L4.26314 2.58039C3.96944 2.28668 3.96944 1.81176 4.26314 1.51805L5.50045 0.280745C5.79415 -0.0129593 6.26908 -0.0129593 6.56278 0.280745L15.7363 9.5293C16.0301 9.823 16.0301 10.2979 15.7363 10.5916Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_331_1726">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="translate(0.000976562)"
      />
    </clipPath>
  </defs>
</template>
