<template>
  <div
    class="ui-card"
    :class="{ dense, float, padless, lessRounded }"
    :style="{ width, overflow }"
  >
    <div v-if="title" class="card-title">
      <Typography :size="size">{{ title }}</Typography>
    </div>
    <div v-if="titleIsCustomized" class="card-title" :class="{ denseTitle }">
      <slot name="title"></slot>
    </div>
    <div class="card-content" :style="cardContentStyle">
      <slot />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'Card',
  components: { Typography },
  props: {
    /**
     * `true`  slotによるタイトルを使用;
     * `false` Typographyによるタイトルを使用;
     */
    titleIsCustomized: Boolean,
    /**
     * `<Typography />`コンポーネント
     */
    title: String,
    /**
     * `true`  padding: 16px;
     * `false` padding: 24px;
     */
    dense: Boolean,
    /**
     * Typography Component の `size` props
     */
    size: {
      type: String,
      default: 'xl'
    },
    /**
     * float: モーダル or ポップオーバー
     */
    float: Boolean,
    cardContentStyle: Object,
    width: String,
    /**
     * `true`  padding: 0;
     * `false` padding: 24px;
     */
    padless: Boolean,
    lessRounded: Boolean,
    denseTitle: Boolean,
    overflow: String
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';

.ui-card {
  background-color: var(--contrast-color);
  border-radius: 16px;
  box-shadow: var(--shadow-2);

  &.dense:not(.float) {
    .card-content {
      padding: $spacing-base * 4;
    }
  }

  &.padless {
    .card-content {
      padding: 0;
    }
  }

  &.lessRounded {
    border-radius: 8px;
  }

  &.float {
    box-shadow: var(--shadow-8);
  }
}
.card-title {
  border-bottom: 1px solid var(--border-color);
  padding: $spacing-base * 4;

  &.denseTitle {
    padding: $spacing-base * 2 $spacing-base * 4;
  }
}
.card-content {
  padding: $spacing-base * 6;
}
</style>
