<template>
  <path
    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
    fill="#A2B9EB"
  />
  <path
    d="M13.5166 9.225H6.48331C6.05529 9.225 5.70831 9.57198 5.70831 10C5.70831 10.428 6.05529 10.775 6.48331 10.775H13.5166C13.9447 10.775 14.2916 10.428 14.2916 10C14.2916 9.57198 13.9447 9.225 13.5166 9.225Z"
    fill="white"
  />
</template>
