import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Digit3, Ripple } from '@/directive';
import { createGtm } from '@gtm-support/vue-gtm';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import Toast, { POSITION } from 'vue-toastification';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';
import router from './router';
import AxiosPlugin from './plugins/axios';
import { ROUTE_NAMES } from '@/router';
import PrimeVue from 'primevue/config';
import VuePapaParse from 'vue-papa-parse';
import VueCookies from 'vue-cookies';
import * as Sentry from '@sentry/vue';
import { datadogRum } from '@datadog/browser-rum';

// Styles
import '@/styles/main.scss';
import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons
import 'vue3-timepicker/dist/VueTimepicker.css';

Ripple.color = 'rgba(255, 255, 255, 0.3)';
Ripple.zIndex = 55;

const options = {
  position: POSITION.TOP_RIGHT,
  icon: false,
  containerClassName: 'sips-saas-toast-container'
};

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(Toast, options)
  .use(VueScrollTo)
  .use(createGtm({ id: process.env.VUE_APP_GA_GTM_KEY || '' }))
  .use(createMetaManager())
  .use(metaPlugin)
  .use(AxiosPlugin)
  .use(PrimeVue)
  .use(VuePapaParse)
  .use(VueCookies)
  .directive('ripple', Ripple)
  .directive('digit3', Digit3);

/* vueのerrorHandler */
app.config.errorHandler = (err, instance, info) => {
  console.log('errorHandler', err, instance, info);
};

app.provide('$papa', app.config.globalProperties.$papa);

/* 以下、error関連の検知 */
window.addEventListener('unhandledrejection', event => {
  const router = app.config.globalProperties.$router;
  const params = event.reason;

  if (params) {
    router.push({
      name: ROUTE_NAMES.error,
      params
    });
  }
});

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

if (
  process.env.VUE_APP_DATADOGRUM_APPLICATION_ID &&
  process.env.VUE_APP_DATADOGRUM_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOGRUM_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOGRUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'sips-saas-ui',
    env: process.env.VUE_APP_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  datadogRum.startSessionReplayRecording();
}

app.mount('#app');
