<template>
  <div>
    <label class="base-label" :class="{ block, required, disabled }">
      <Typography
        :bold="bold"
        :size="size"
        :line-height="lineHeight || '1'"
        class="base-label-label"
        :color="disabled ? 'disabled' : color"
      >
        <slot />
      </Typography>
      <Required v-if="required" class="label-required" />
    </label>
    <Typography v-if="caption" class="label-caption" size="s">{{
      caption
    }}</Typography>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Required from '@/components/ui/Required.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'Label',
  components: { Required, Typography },
  props: {
    block: Boolean,
    /**
     * true ... `<Required />` コンポーネント
     */
    required: Boolean,
    disabled: Boolean,
    bold: Boolean,
    size: {
      type: String,
      default: 's'
    },
    lineHeight: String,
    caption: String,
    color: {
      type: String,
      default: ''
    }
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';
@import '@/styles/layout';
.base-label {
  display: inline-flex;
  align-items: center;
  color: var(--text-color);
  white-space: nowrap;

  &.block {
    display: block;
  }

  &.required {
    display: flex;
    flex-wrap: wrap;
  }

  &.disabled {
    color: var(--dark-35-color);
  }
  .base-label-label {
    margin-right: $spacing-base * 1;
  }
}
.label-caption {
  color: var(--grey-text-color);
  margin-top: $spacing-base * 1;
}
</style>
