<template>
  <Typography color="link" :size="size" class="ui-text-link">
    <slot />
  </Typography>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'TextLink',
  components: { Typography },
  props: {
    size: String
  }
});
</script>
<style lang="scss" scoped>
.ui-text-link {
  display: inline-flex;
  &:hover {
    text-decoration: underline;
  }
}
</style>
