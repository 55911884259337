<template>
  <g clip-path="url(#clip0_1060_6952)">
    <path
      d="M15.625 9.53043V9.21793C15.625 8.96011 15.8359 8.74918 16.0938 8.74918H16.4062C16.6641 8.74918 16.875 8.96011 16.875 9.21793V9.53043C16.875 9.78824 16.6641 9.99918 16.4062 9.99918H16.0938C15.8359 9.99918 15.625 9.78824 15.625 9.53043ZM16.0938 12.4992H16.4062C16.6641 12.4992 16.875 12.2882 16.875 12.0304V11.7179C16.875 11.4601 16.6641 11.2492 16.4062 11.2492H16.0938C15.8359 11.2492 15.625 11.4601 15.625 11.7179V12.0304C15.625 12.2882 15.8359 12.4992 16.0938 12.4992ZM14.6875 7.16714C14.6875 7.16714 14.6289 6.87418 9.0625 6.87418C3.49609 6.87418 3.41797 7.16714 3.41797 7.16714C3.41797 7.16714 3.125 7.18668 3.125 11.2492C3.125 15.3117 3.41797 15.3312 3.41797 15.3312C3.41797 15.3312 3.4375 15.6242 9.0625 15.6242C14.6875 15.6242 14.6875 15.3312 14.6875 15.3312C14.6875 15.3312 14.9805 15.3117 14.9805 11.2492C14.9805 7.18668 14.6875 7.16714 14.6875 7.16714ZM20 5.62418V16.8742C20 17.9093 19.1602 18.7492 18.125 18.7492H17.5V19.9992H15.625L15.207 18.7492H4.79297L4.375 19.9992H2.5V18.7492H1.875C0.839844 18.7492 0 17.9093 0 16.8742V5.62418C0 4.58902 0.839844 3.74918 1.875 3.74918H7.37891L5.26953 1.59293C4.90625 1.22183 4.91406 0.628083 5.28125 0.268708C5.65234 -0.0945733 6.24609 -0.0867608 6.60547 0.280427L10 3.74918L13.3945 0.280427C13.7578 -0.0906671 14.3516 -0.0945733 14.7188 0.268708C15.0898 0.631989 15.0938 1.22574 14.7305 1.59293L12.6211 3.74918H18.125C19.1602 3.74918 20 4.58902 20 5.62418ZM18.125 5.62418H1.875V16.8742H18.125V5.62418Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_1060_6952">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
