<template>
  <path
    d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
    fill="#56C323"
  />
  <path
    d="M11.6387 5.36328H14.2344V14H12.5527V8.1582C12.5527 7.99023 12.5547 7.75586 12.5586 7.45508C12.5625 7.15039 12.5645 6.91602 12.5645 6.75195L10.9297 14H9.17773L7.55469 6.75195C7.55469 6.91602 7.55664 7.15039 7.56055 7.45508C7.56445 7.75586 7.56641 7.99023 7.56641 8.1582V14H5.88477V5.36328H8.50977L10.0801 12.1543L11.6387 5.36328Z"
    fill="white"
  />
</template>
