<template>
  <Typography line-height="1" color="error" class="error-message">
    <slot />
  </Typography>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'ErrorMessage',
  components: {
    Typography
  }
});
</script>
