<template>
  <g clip-path="url(#clip0_1829_4901)">
    <path
      d="M2.02118 10.7271C1.30118 10.7271 0.711182 11.317 0.711182 12.037V17.087C0.711182 17.807 1.30118 18.397 2.02118 18.397C2.74118 18.397 3.33118 17.807 3.33118 17.087V12.037C3.33118 11.317 2.74118 10.7271 2.02118 10.7271Z"
      fill="#4674D7"
    />
    <path
      d="M5.87104 7.85706C5.15104 7.85706 4.56104 8.44706 4.56104 9.16706V17.0871C4.56104 17.8071 5.15104 18.3971 5.87104 18.3971C6.59104 18.3971 7.18104 17.8071 7.18104 17.0871V9.16706C7.18104 8.44706 6.59104 7.85706 5.87104 7.85706Z"
      fill="#4674D7"
    />
    <path
      d="M13.9411 9.33704C14.2611 9.33704 14.5911 9.37704 14.9111 9.44704C16.4211 9.78704 17.6611 11.027 18.0011 12.537C18.2911 13.837 18.0011 15.107 17.1911 16.117C16.3911 17.107 15.2111 17.677 13.9311 17.677C13.6111 17.677 13.2811 17.637 12.9611 17.567C11.4511 17.227 10.2111 15.987 9.87112 14.477C9.58112 13.177 9.87112 11.907 10.6811 10.897C11.4811 9.90704 12.6611 9.33704 13.9411 9.33704ZM13.9411 7.83704C10.3911 7.83704 7.59112 11.107 8.41112 14.807C8.87112 16.887 10.5511 18.577 12.6411 19.037C13.0811 19.137 13.5111 19.177 13.9411 19.177C17.4911 19.177 20.2911 15.907 19.4711 12.207C19.0111 10.127 17.3311 8.43704 15.2411 7.97704C14.8011 7.87704 14.3711 7.83704 13.9411 7.83704Z"
      fill="#4674D7"
    />
    <path
      d="M16.5111 13.507C16.5111 13.627 16.4211 13.7271 16.2911 13.7271H14.7111L14.5911 13.9571V14.417H16.2911C16.4111 14.417 16.5111 14.5071 16.5111 14.6371V15.2971C16.5111 15.4171 16.4211 15.517 16.2911 15.517H14.5911V17.0271C14.5911 17.187 14.5111 17.267 14.3511 17.267H13.5211C13.3611 17.267 13.2811 17.187 13.2811 17.0271V15.517H11.5911C11.4711 15.517 11.3711 15.4271 11.3711 15.2971V14.6371C11.3711 14.5171 11.4611 14.417 11.5911 14.417H13.2811V13.9571L13.1611 13.7271H11.5911C11.4711 13.7271 11.3711 13.637 11.3711 13.507V12.847C11.3711 12.727 11.4611 12.627 11.5911 12.627H12.6211L11.6811 10.717C11.6011 10.557 11.6411 10.4771 11.8111 10.4771H12.6411C12.8011 10.4771 12.9211 10.557 13.0111 10.717L13.9511 12.627L14.8911 10.717C14.9711 10.557 15.0911 10.4771 15.2611 10.4771H16.0911C16.2511 10.4771 16.2911 10.557 16.2211 10.717L15.2811 12.627H16.3211C16.4411 12.627 16.5411 12.717 16.5411 12.847V13.507H16.5311H16.5111Z"
      fill="#4674D7"
    />
    <path
      d="M18.4412 0.787057L14.2912 0.0170568C13.7612 -0.0829432 13.2512 0.267059 13.1512 0.797059C13.0512 1.32706 13.4012 1.83706 13.9312 1.93706L15.8012 2.28706L10.6412 5.35706L7.5312 3.54706C7.2212 3.36706 6.8412 3.36706 6.5312 3.54706L0.471197 7.19706C0.0111967 7.47706 -0.138803 8.07706 0.141197 8.53706C0.321197 8.84706 0.651197 9.00706 0.981197 9.00706C1.1512 9.00706 1.3312 8.96706 1.4812 8.86706L7.0412 5.51706L10.1512 7.32706C10.4612 7.50706 10.8312 7.50706 11.1412 7.32706L16.8512 3.92706L16.5212 5.70706C16.4212 6.23706 16.7712 6.74706 17.3012 6.84706C17.3612 6.85706 17.4212 6.86706 17.4812 6.86706C17.9412 6.86706 18.3512 6.53706 18.4412 6.06706L19.2112 1.91706C19.3112 1.38706 18.9612 0.877059 18.4312 0.777059L18.4412 0.787057Z"
      fill="#4674D7"
    />
  </g>
  <defs>
    <clipPath id="clip0_1829_4901">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
