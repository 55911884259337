import { defineComponent } from 'vue';

export const PROJECT_ROUTES = {
  top: 'projectTop'
};
const project = [
  {
    path: '/project/:projectId',
    name: PROJECT_ROUTES.top,
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/projects/index.vue')
  }
];

export default project;
