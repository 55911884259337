import { differenceInDays } from 'date-fns';
import * as Yup from 'yup';

const specialChars = /[0-9~`.@!#$%^&*+=\-[\]\\';,/{}|\\":<>?]+/g;

export const VALIDATION_MESSAGE = {
  email: 'emailのフォーマットで入力してください',
  domain: 'ドメインのフォーマットで入力してください',
  domainDuplicate: 'そのドメインは既に設定されています',
  url: 'urlのフォーマットで入力してください',
  phoneNumberFormat: '電話番号のフォーマットで入力してください',
  passwordFormat:
    '英字（小文字）、数字を必ず含む半角英数字記号8文字以上を入力してください',
  emptyFormat: '英数字記号を入力してください',
  required: '必須項目です',
  confirmPassword: '最初に入力したパスワードと一致しません',
  invalidChar: '使用できない文字が入力されています',
  max10: '10文字以内で入力してください',
  max14: '14文字以内で入力してください',
  max22: '22文字以内で入力してください',
  max64: '64文字以内で入力してください',
  max140: '140文字以内で入力してください',
  domainEmpty: 'ドメインを入力してください',
  invalidNumber: '数値が不正です',
  companyNameUnique: 'カンパニー名称がワークスペース内で重複しています',
  companyDuplicate: '既に使われています',
  maxN: (n: number) => `${n}文字以内で入力してください`,
  invalidYoutubeUrl: 'YouTubeのURLではありません',
  invalidNumberWidth: (start: number, end: number) =>
    `${start}~${end.toLocaleString()}の値で入力してください`,
  duration: (s: string) => `${s}の範囲で入力してください`
} as const;

export const validDuration = (date: {
  start: Date | null;
  end: Date | null;
}): boolean => {
  if (date.start && date.end) {
    return differenceInDays(date.end, date.start as Date) + 1 >= 7;
  }
  return false;
};

export const VALIDATION_SCHEMA = {
  firstName: Yup.string()
    .max(10, VALIDATION_MESSAGE.max10)
    .required(VALIDATION_MESSAGE.required)
    .test({
      name: 'isSpecial',
      message: VALIDATION_MESSAGE.invalidChar,
      test: value => !value?.match(specialChars)
    }),
  lastName: Yup.string()
    .max(10, VALIDATION_MESSAGE.max10)
    .required(VALIDATION_MESSAGE.required)
    .test({
      name: 'isSpecial',
      message: VALIDATION_MESSAGE.invalidChar,
      test: value => !value?.match(specialChars)
    }),
  nickname: Yup.string()
    .test({
      name: 'isSpecial',
      message: VALIDATION_MESSAGE.invalidChar,
      test: value => typeof value === 'undefined' || !value.match(specialChars)
    })
    .max(14, VALIDATION_MESSAGE.max14),
  userDescription: Yup.string().max(140, VALIDATION_MESSAGE.max140),
  email: Yup.string()
    .email(VALIDATION_MESSAGE.email)
    .required(VALIDATION_MESSAGE.required),
  phoneNumber: Yup.string().matches(
    /^(0[0-9]{9,10}|0[0-9]-[0-9]{4}-[0-9]{4}|\(0[0-9]\)[0-9]{4}-[0-9]{4}|0[0-9]\([0-9]{4}\)[0-9]{4}|0[0-9]{2}-[0-9]{3}-[0-9]{4}|\(0[0-9]{2}\)[0-9]{3}-[0-9]{4}|0[0-9]{2}\([0-9]{3}\)[0-9]{4}|0[0-9]{3}-[0-9]{2}-[0-9]{4}|\(0[0-9]{3}\)[0-9]{2}-[0-9]{4}|0[0-9]{3}\([0-9]{2}\)[0-9]{4}|0[0-9]{4}-[0-9]{1}-[0-9]{4}|\(0[0-9]{4}\)[0-9]{1}-[0-9]{4}|0[0-9]{4}\([0-9]{1}\)[0-9]{4}|^\+[0-9]{2}-[0-9]{1}-[0-9]{4}-[0-9]{4}$|^\+[0-9]{2}-[0-9]{2}-[0-9]{4}-[0-9]{4}$|^\+[0-9]{11,12}|^\+[0-9]{2}-[0-9]{2}-[0-9]{3}-[0-9]{4}$|^\+[0-9]{2}-[0-9]{3}-[0-9]{2}-[0-9]{4}$|^\+[0-9]{2}-[0-9]{4}-[0-9]{1}-[0-9]{4}$|^\+[0-9]{3}-[0-9]{4}-[0-9]{4}$|^\+[0-9]{4}-[0-9]{3}-[0-9]{4}$|^\+[0-9]{5}-[0-9]{2}-[0-9]{4}$|^\+[0-9]{6}-[0-9]{1}-[0-9]{4}$|^\+[0-9]{3}\([0-9]{4}\)[0-9]{4}$|^\+[0-9]{4}\([0-9]{3}\)[0-9]{4}$|^\+[0-9]{5}\([0-9]{2}\)[0-9]{4}$|^\+[0-9]{6}\([0-9]{1}\)[0-9]{4}$|0[5-9]{1}0-[0-9]{4}-[0-9]{4}|0[5-9]{1}0\([0-9]{4}\)[0-9]{4}|^\+[0-9]{2}[5-9]{1}0-[0-9]{4}-[0-9]{4}$|^\+[0-9]{2}[5-9]{1}0\([0-9]{4}\)[0-9]{4}$)$/,
    VALIDATION_MESSAGE.phoneNumberFormat
  ),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*\d)[A-Za-z\d@\-=+()_{}[\]"'|\\></$!%*#?&;:,.^~`]{8,}$/,
      VALIDATION_MESSAGE.passwordFormat
    )
    .required(VALIDATION_MESSAGE.required),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], VALIDATION_MESSAGE.confirmPassword)
    .required(VALIDATION_MESSAGE.required)
};
