import { defineComponent } from 'vue';

export const CMSIS_ROUTES = {
  layout: 'cmsisLayout',
  index: 'cmsisIndex',
  create: 'cmsisCreate',
  edit: 'cmsisEdit',
  result: 'cmsisResult'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const MENU_TITLE = '売上効果シミュレーター';
const TITLE = {
  layout: '',
  index: 'プロジェクト一覧' + ' | ' + MENU_TITLE,
  create: 'プロジェクト新規作成' + ' | ' + MENU_TITLE,
  result: MENU_TITLE,
  edit: 'プロジェクト編集' + ' | ' + MENU_TITLE
} as const;

const PATH = '/company/:companyId/cmsis';

export default [
  {
    path: PATH,
    name: CMSIS_ROUTES.index,
    meta: {
      children: true,
      title: TITLE.index
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/cmsis/index.vue')
  },
  {
    path: `${PATH}/create`,
    name: CMSIS_ROUTES.create,
    meta: {
      children: true,
      title: TITLE.create
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/cmsis/create.vue')
  },
  {
    name: CMSIS_ROUTES.layout,
    path: PATH,
    meta: {
      children: true
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/cmsis/layout.vue'),
    children: [
      {
        path: `${PATH}/:projectId`,
        name: CMSIS_ROUTES.result,
        meta: {
          children: true,
          title: TITLE.result
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/cmsis/result.vue')
      },
      {
        path: `${PATH}/:projectId/edit`,
        name: CMSIS_ROUTES.edit,
        meta: {
          children: true,
          title: TITLE.edit
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/cmsis/edit.vue')
      }
    ]
  }
];
