import {
  asyncChangePasswordUnAuth,
  asyncCompletePasswordChallenge,
  asyncForgotPassword
} from '@/composables/auth/cognito';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { useAuthStore } from '@/store/auth';
import settings from '@/aws-exports';
import { Ref } from 'vue';
import useLoading from '@/composables/loading';
import {
  CODE_MISMATCH,
  EXPIRED_CODE_EXCEPTION,
  LIMIT_EXCEEDED_EXCEPTION,
  PASSWORD_RESET_REQUIRED,
  USER_NOT_CONFIRMED_EXCEPTION,
  USER_NOT_FOUND_ERROR
} from '@/common/messages';
import { useRouter } from 'vue-router';
import { toast } from '@/components/ui/Toast';
import { ROUTE_NAMES } from '@/router';

type requestChangingPasswordArgTypes = {
  account: string;
};

type requestChangingPasswordReturnTypes = {
  requestPasswordChangeForm: (
    values: requestChangingPasswordArgTypes
  ) => Promise<void>;
  isLoading: Ref<boolean>;
};

type changePasswordArgTypes = {
  code?: string;
  password: string;
};
type changePasswordReturnTypes = {
  changePassword: (values: changePasswordArgTypes) => Promise<void>;
  isLoading: Ref<boolean>;
  resendConfirmCode(): Promise<void>;
};

const requestChangingPassword = async (account: string) => {
  const poolData = {
    UserPoolId: settings.aws_user_pools_id,
    ClientId: settings.aws_user_pools_web_client_id
  };
  const userPool = new CognitoUserPool(poolData);

  const store = useAuthStore();
  const cognitoUser = new CognitoUser({
    Username: account,
    Pool: userPool
  });
  try {
    await asyncForgotPassword(cognitoUser);
    store.setCognitoUser(cognitoUser, account);
    return null;
  } catch (e) {
    const errorType = e as Error;
    switch (errorType.name) {
      case 'UserNotFoundException':
        return USER_NOT_FOUND_ERROR;
      default:
        return errorType.message;
    }
  }
};

const completePassword = async (password: string): Promise<string | null> => {
  const store = useAuthStore();
  try {
    await asyncCompletePasswordChallenge(store.cognitoUser, password);
    store.resetCognitoUser();
    return null;
  } catch (e) {
    const errorType = e as Error;
    switch (errorType.name) {
      case 'UserNotConfirmedException':
        return USER_NOT_CONFIRMED_EXCEPTION;
      case 'PasswordResetRequiredException':
        return PASSWORD_RESET_REQUIRED;
      case 'UserNotFoundException':
        return USER_NOT_FOUND_ERROR;
      case 'NotAuthorizedException':
      case 'InvalidParameterException':
      default:
        return errorType.message;
    }
  }
};

const changePasswordUnAuth = async (
  verificationCode: string,
  newPassword: string
): Promise<string | null> => {
  const store = useAuthStore();
  try {
    const cognitoUser = store.cognitoUser;
    await asyncChangePasswordUnAuth(
      cognitoUser as CognitoUser,
      verificationCode,
      newPassword
    );
    return null;
  } catch (e) {
    const errorType = e as Error;
    switch (errorType.name) {
      case 'CodeMismatchException':
        return CODE_MISMATCH;
      case 'LimitExceededException':
        return LIMIT_EXCEEDED_EXCEPTION;
      case 'ExpiredCodeException':
        return EXPIRED_CODE_EXCEPTION;
      case 'InvalidPasswordException':
      case 'InvalidParameterException':
      default:
        return errorType.message;
    }
  }
};

export const useRequestChangingPassword = (): requestChangingPasswordReturnTypes => {
  const router = useRouter();
  const store = useAuthStore();

  const _requestPasswordChangeForm = async (
    values: requestChangingPasswordArgTypes
  ) => {
    const error = await requestChangingPassword(values.account);

    if (error === null) {
      store.setUserAttribute(values.account);
      await router.push({
        name: ROUTE_NAMES.resetPassword,
        params: {
          type: 'unAuth'
        }
      });
    } else {
      toast({ title: error, variant: 'error' });
    }
  };

  const [isLoading, requestPasswordChangeForm] = useLoading(
    _requestPasswordChangeForm
  );
  return {
    requestPasswordChangeForm,
    isLoading
  };
};

export const useChangePassword = (type: string): changePasswordReturnTypes => {
  const router = useRouter();
  const store = useAuthStore();
  const _changePassword = async (values: changePasswordArgTypes) => {
    let error: string | null;
    if (type === 'init') {
      error = await completePassword(values.password);
    } else if (type === 'unAuth') {
      error = await changePasswordUnAuth(values.code ?? '', values.password);
    } else {
      // TODO: add authed password change flow
      error = `unhandled password reset type: ${type}`;
    }
    if (error) {
      toast({ title: error, variant: 'error' });
    } else {
      await router.push({ name: ROUTE_NAMES.login });
      toast({
        title: 'パスワードの再設定が完了しました。再度ログインしてください。',
        variant: 'success'
      });
    }
  };

  const resendConfirmCode = async () => {
    const error = await requestChangingPassword(store.userAttribute.email);
    if (error) {
      toast({ title: error, variant: 'error' });
    } else {
      toast({ title: '認証コードを再送信しました', variant: 'success' });
    }
  };

  const [isLoading, changePasswordWithLoading] = useLoading(_changePassword);
  return {
    changePassword: changePasswordWithLoading,
    isLoading,
    resendConfirmCode
  };
};
