import { defineComponent } from 'vue';

export const SIGNUP_ROUTES = {
  signup: 'signup',
  welcome: 'signupWelcom',
  complete: 'signupComplete'
};

export const SIGNUP_WORKSPACE_ROUTERS = {
  select: 'selectWorkspaces',
  apply: 'applyCreatingWorkspace',
  applyCompleted: 'completedApplyingWorkspaces'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE = {
  signup: 'サインアップ',
  welcome: 'アカウント情報入力',
  complete: 'サインアップ完了',
  select: 'ワークスペース選択',
  apply: '無料ワークスペース作成申請',
  applyCompleted: 'ワークスペース申請完了'
} as const;

export default [
  {
    path: '/signup',
    name: SIGNUP_ROUTES.signup,
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/signup/index.vue'),
    meta: {
      title: TITLE.signup,
      noAuth: true,
      noLogoLink: true
    }
  },
  {
    path: '/signup/welcome',
    name: SIGNUP_ROUTES.welcome,
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/signup/Welcome.vue'),
    meta: {
      title: TITLE.welcome,
      noAuth: true,
      noLogoLink: true
    }
  },
  {
    path: '/signup/complete',
    name: SIGNUP_ROUTES.complete,
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/signup/Complete.vue'),
    meta: {
      title: TITLE.complete,
      noAuth: true,
      noLogoLink: true
    }
  },
  {
    path: '/signup/workspace',
    name: SIGNUP_WORKSPACE_ROUTERS.select,
    meta: {
      title: TITLE.select
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/signup/SelectWorkspace.vue')
  },
  {
    path: '/signup/workspace/apply',
    name: SIGNUP_WORKSPACE_ROUTERS.apply,
    meta: {
      title: TITLE.apply
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/signup/ApplyWorkspace.vue')
  },
  {
    path: '/signup/workspace/apply/completed',
    name: SIGNUP_WORKSPACE_ROUTERS.applyCompleted,
    meta: {
      title: TITLE.applyCompleted
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/signup/ApplyWorkspaceCompleted.vue')
  }
];
