<template>
  <button class="base-button" :class="{ 'no-hover': noHover, block }">
    <slot />
  </button>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BaseButton',
  props: {
    noHover: Boolean,
    block: Boolean
  }
});
</script>
<style lang="scss">
@import '@/styles/effect';

.base-button {
  &.block {
    display: block;
  }

  &:not(.no-hover) {
    @extend .hover;
  }

  &:active:not(:disabled) {
    opacity: var(--action-opacity);
  }
}
</style>
