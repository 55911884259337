<template>
  <div>
    <Flex align-center>
      <Icon
        v-if="variant === 'success'"
        name="check-circle-contained"
        class="mr-2"
        color="inherit"
      />
      <Icon
        v-if="variant === 'error'"
        name="exclamation"
        class="mr-2"
        color="inherit"
      />
      <Typography bold line-height="1.5">{{ title }}</Typography>
    </Flex>
    <Typography v-if="message" class="mt-4">{{ message }}</Typography>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Icon from '@/components/ui/Icon.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'ToastContent',
  components: { Flex, Icon, Typography },
  props: {
    title: String,
    message: String,
    variant: String
  }
});
</script>
