import { defineComponent } from 'vue';
export const MYPAGE_ROUTES = {
  top: 'mypageTop',
  account: 'mypageAccount',
  accountEmail: 'mypageAccountEmail',
  accountPassword: 'mypageAccountPassword',
  profile: 'mypageProfile',
  changePassword: 'myPageChangePassword'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE_BASE = ' | マイページ';
const TITLE = {
  top: 'マイページ',
  account: 'アカウント' + TITLE_BASE,
  accountEmail: 'アカウント設定' + TITLE_BASE,
  accountPassword: 'パスワード設定' + TITLE_BASE,
  profile: 'プロフィール' + TITLE_BASE
} as const;

export default {
  path: '/mypage',
  component: (): ReturnType<typeof defineComponent> =>
    import('@/pages/mypage/index.vue'),
  // NOTE: metaを利用する理由。ページ毎にURLの仕様がvue一般的な実装と異なるため仕様に合わせて独自実装で対応
  // https://www.notion.so/switchm/UI-bf5854c374714c24b593743b45daf5f8#6b45a90792d64b37848d99fc30a73d7a
  meta: { topLinkName: MYPAGE_ROUTES.top },
  children: [
    {
      path: '',
      name: MYPAGE_ROUTES.top,
      meta: {
        title: TITLE.top
      },
      component: (): ReturnType<typeof defineComponent> =>
        import('@/pages/mypage/Top.vue')
    },
    // {
    //   path: 'account',
    //   name: MYPAGE_ROUTES.account,
    //   component: (): ReturnType<typeof defineComponent> =>
    //     import('@/pages/mypage/AccountSetting.vue'),
    //   children: [
    //     {
    //       path: 'email',
    //       name: MYPAGE_ROUTES.accountEmail,
    //       component: (): ReturnType<typeof defineComponent> =>
    //         import('@/pages/mypage/AccountEmail.vue')
    //     },
    //     {
    //       path: 'password',
    //       name: MYPAGE_ROUTES.accountPassword,
    //       component: (): ReturnType<typeof defineComponent> =>
    //         import('@/pages/mypage/AccountPassword.vue')
    //     }
    //   ]
    // },
    {
      path: 'password',
      name: MYPAGE_ROUTES.changePassword,
      component: (): ReturnType<typeof defineComponent> =>
        import('@/pages/mypage/AccountPassword.vue')
    },
    {
      path: 'profile',
      name: MYPAGE_ROUTES.profile,
      meta: {
        title: TITLE.profile
      },
      component: (): ReturnType<typeof defineComponent> =>
        import('@/pages/mypage/Profile.vue')
    }
  ]
};
