<template>
  <g clip-path="url(#clip0_36_2564)">
    <path
      d="M18.8873 1.1127L16.8496 3.15039C15.0967 1.39715 12.6752 0.3125 10 0.3125C4.81359 0.3125 0.579063 4.3882 0.324609 9.51145C0.311367 9.77777 0.526367 10 0.793008 10H1.88852C2.13668 10 2.34215 9.80656 2.35621 9.55883C2.58441 5.53316 5.91613 2.34375 10 2.34375C12.1157 2.34375 14.0296 3.20012 15.4146 4.58539L13.3002 6.6998C13.0049 6.99512 13.2141 7.5 13.6317 7.5H19.2188C19.4776 7.5 19.6875 7.29012 19.6875 7.03125V1.44418C19.6875 1.02656 19.1826 0.817422 18.8873 1.1127ZM19.207 10H18.1115C17.8633 10 17.6579 10.1934 17.6438 10.4412C17.4156 14.4668 14.0839 17.6562 10 17.6562C7.88426 17.6562 5.97043 16.7999 4.58539 15.4146L6.69977 13.3002C6.99508 13.0049 6.78594 12.5 6.36832 12.5H0.78125C0.522383 12.5 0.3125 12.7099 0.3125 12.9688V18.5558C0.3125 18.9734 0.817422 19.1826 1.1127 18.8873L3.15039 16.8496C4.90332 18.6029 7.32484 19.6875 10 19.6875C15.1864 19.6875 19.4209 15.6118 19.6754 10.4886C19.6886 10.2222 19.4736 10 19.207 10Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_36_2564">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
