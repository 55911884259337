<template>
  <path
    d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
    fill="#425252"
    fill-opacity="0.35"
  />
  <path
    d="M8.21875 6.86328V9.18359H10.2637C10.6699 9.18359 10.9746 9.13672 11.1777 9.04297C11.5371 8.87891 11.7168 8.55469 11.7168 8.07031C11.7168 7.54688 11.543 7.19531 11.1953 7.01562C11 6.91406 10.707 6.86328 10.3164 6.86328H8.21875ZM10.6914 5.36328C11.2969 5.375 11.7617 5.44922 12.0859 5.58594C12.4141 5.72266 12.6914 5.92383 12.918 6.18945C13.1055 6.4082 13.2539 6.65039 13.3633 6.91602C13.4727 7.18164 13.5273 7.48438 13.5273 7.82422C13.5273 8.23438 13.4238 8.63867 13.2168 9.03711C13.0098 9.43164 12.668 9.71094 12.1914 9.875C12.5898 10.0352 12.8711 10.2637 13.0352 10.5605C13.2031 10.8535 13.2871 11.3027 13.2871 11.9082V12.4883C13.2871 12.8828 13.3027 13.1504 13.334 13.291C13.3809 13.5137 13.4902 13.6777 13.6621 13.7832V14H11.6758C11.6211 13.8086 11.582 13.6543 11.5586 13.5371C11.5117 13.2949 11.4863 13.0469 11.4824 12.793L11.4707 11.9902C11.4629 11.4395 11.3613 11.0723 11.166 10.8887C10.9746 10.7051 10.6133 10.6133 10.082 10.6133H8.21875V14H6.45508V5.36328H10.6914Z"
    fill="white"
  />
</template>
