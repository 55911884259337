<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.80769" cy="2.30769" r="2.30769" fill="#425252" />
    <ellipse cx="9.80769" cy="10" rx="2.30769" ry="2.30769" fill="#425252" />
    <circle cx="9.80769" cy="17.6923" r="2.30769" fill="#425252" />
  </svg>
</template>
