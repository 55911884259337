import { UserInfoApi } from '@/api';
import {
  JoinableCompanyList,
  UserInfo,
  WorkspaceFeatureOptionStatusEnum,
  WorkspaceFeatureOptionTypeEnum
} from '@/api/openapi';
import { fetchAccountAvatar } from '@/composables/fetchImage';
import { AclRule } from '@/plugins/acl';
import { useAcl } from '@/store/acl';
import { defineStore } from 'pinia';
import { RouteLocationNormalized } from 'vue-router';

export const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    userInfo: {} as UserInfo,
    isLoadingUserInfo: false,
    joinableCompanies: [] as JoinableCompanyList['list'],
    isLoadingJoinableCompanies: true,
    accountImage: '' as string | null
  }),
  actions: {
    async fetchUserInfo() {
      this.isLoadingUserInfo = true;
      const res = await UserInfoApi.getUserinfo();
      if (res) {
        this.userInfo = res.data;
        if (this.userInfo.id) {
          this.accountImage = await fetchAccountAvatar(
            this.userInfo.id.toString()
          );
        }
        this.isLoadingUserInfo = false;
      }
    },
    async fetchJoinableCompanies() {
      this.isLoadingJoinableCompanies = true;
      const result = await UserInfoApi.getUserinfoJoinableCompanies();
      this.joinableCompanies = result.data.list ?? [];
      this.isLoadingJoinableCompanies = false;
    },
    setUserInfo(userInfo: UserInfo) {
      this.userInfo = userInfo;
      this.isLoadingUserInfo = false;
    },
    setAccountImage(imageData: string | null) {
      this.accountImage = imageData;
    },
    changeEmail(email: string) {
      this.userInfo.email = email;
    },
    changeIsLoadingUserInfo(flg: boolean) {
      this.isLoadingUserInfo = flg;
    }
  },
  getters: {
    user(): UserInfo & {
      accountImage: string | null;
      name: string;
    } {
      return {
        ...this.userInfo,
        accountImage: this.accountImage,
        name:
          this.userInfo.nickname || this.userInfo.lastName
            ? `${this.userInfo.lastName} ${this.userInfo.firstName}`
            : ''
      };
    },
    currentJoinableCompany: state => (companyId?: string) => {
      return state?.joinableCompanies?.find(
        joinableCompany => joinableCompany.companyId?.toString() === companyId
      );
    },
    currentWorkspace: state => (workspaceId?: string) =>
      state.userInfo.workspaces?.find(
        workspace => workspace.workspaceId?.toString() === workspaceId
      ),
    currentWorkspaceFromRoute: state => (to: RouteLocationNormalized) => {
      let workspaceId: string | undefined;

      workspaceId = to.params.workspaceId as string;

      if (workspaceId === undefined) {
        const companyId: string = to.params.companyId as string;
        if (companyId !== undefined) {
          workspaceId = state.joinableCompanies
            ?.find(
              joinableCompany =>
                joinableCompany.companyId?.toString() === companyId
            )
            ?.workspaceId?.toString();
        }
      }

      if (workspaceId === undefined) {
        workspaceId = to.query.workspaceId as string;
      }

      return state.userInfo.workspaces?.find(
        workspace => workspace.workspaceId?.toString() === workspaceId
      );
    },
    getWorkspaceIdFromCompanyId() {
      return (companyId: number) => {
        return this.joinableCompanies?.find(
          company => company.companyId === companyId
        );
      };
    },
    joinableCompaniesByWorkspaceId: state => (workspaceId?: string) =>
      state?.joinableCompanies?.filter(
        e => e.workspaceId?.toString() === workspaceId
      ),
    getCompanyInfo: state => (companyId: string) => {
      const company =
        state?.joinableCompanies?.find(
          company => company.companyId === parseInt(companyId)
        ) ?? null;
      const workspace = state.userInfo?.workspaces?.find(
        ws => ws.workspaceId === company?.workspaceId
      );
      const payment = workspace?.paymentType;
      const privilege = workspace?.accountPrivilege;
      if (privilege && payment) {
        const acl = useAcl();
        const rule = new AclRule(privilege).and(payment);
        acl?.setAcl(rule);
      }
      return company;
    },
    getCompaniesByWorkspaceId: state => (workspaceId: number) => {
      return (
        state?.joinableCompanies?.filter(
          company => company.workspaceId === workspaceId
        ) ?? []
      );
    },
    getFeatureOptionStatus: state => (
      to: RouteLocationNormalized,
      contractType: WorkspaceFeatureOptionTypeEnum
    ) => {
      let workspaceId: string | undefined;
      workspaceId = to.params.workspaceId as string;
      if (workspaceId === undefined) {
        const companyId: string = to.params.companyId as string;
        if (companyId !== undefined) {
          workspaceId = state.joinableCompanies
            ?.find(
              joinableCompany =>
                joinableCompany.companyId?.toString() === companyId
            )
            ?.workspaceId?.toString();
        }
      }
      if (workspaceId === undefined) {
        workspaceId = to.query.workspaceId as string;
      }

      const featureOptions = state.userInfo.workspaces?.find(
        workspace => workspace.workspaceId?.toString() === workspaceId
      )?.featureOptions;

      const status = featureOptions?.find(
        option => option.type === contractType
      )?.status;

      return status === WorkspaceFeatureOptionStatusEnum.Enabled;
    }
  }
});
