import { defineComponent } from 'vue';

export const WORKSPACE_ROUTES = {
  top: 'workspaceTop',
  users: 'workspaceUsers',
  access: 'workspaceAccess',
  application: 'workspaceApplication'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE_BASE = ' | ワークスペース';
const TITLE = {
  top: 'ワークスペース',
  users: 'ユーザー管理' + TITLE_BASE,
  access: 'アクセス権設定' + TITLE_BASE
} as const;

export default {
  path: '/workspace/:workspaceId',
  component: (): ReturnType<typeof defineComponent> =>
    import('@/pages/workspace/index.vue'),
  // NOTE: metaを利用する理由。ページ毎にURLの仕様がvue一般的な実装と異なるため仕様に合わせて独自実装で対応を利用する理由。ページ毎にURLの仕様がvue一般的な実装と異なるため仕様に合わせて独自実装で対応
  // https://www.notion.so/switchm/UI-bf5854c374714c24b593743b45daf5f8#6b45a90792d64b37848d99fc30a73d7a
  meta: { topLinkName: WORKSPACE_ROUTES.top, title: 'workspace' },

  children: [
    {
      path: '',
      name: WORKSPACE_ROUTES.top,
      meta: {
        title: TITLE.top
      },
      component: (): ReturnType<typeof defineComponent> =>
        import('@/pages/workspace/Top.vue')
    },
    {
      path: 'users',
      name: WORKSPACE_ROUTES.users,
      meta: {
        title: TITLE.users
      },
      component: (): ReturnType<typeof defineComponent> =>
        import('@/pages/workspace/Users.vue')
    },
    {
      path: 'access',
      name: WORKSPACE_ROUTES.access,
      meta: {
        title: TITLE.access
      },
      component: (): ReturnType<typeof defineComponent> =>
        import('@/pages/workspace/Access.vue')
    }
  ]
};
