import {
  AccountApi as AccountsApiConstructor,
  AreaApi as AreaApiConstructor,
  AuthApi as AuthApiConstructor,
  BasicTargetApi as BasicTargetApiConstructor,
  CmSalesImpactApi as CmSalesImpactApiConstructor,
  CmSalesImpactSimulatorApi as CmSalesImpactSimulatorApiConstructor,
  CmSponsorApi as CmSponsorApiConstructor,
  CompanyApi as CompanyApiConstructor,
  Configuration,
  CustomTargetApi as CustomTargetApiConstructor,
  DefaultApi as DefaultApiConstructor,
  HomeApi as HomeApiConstructor,
  InstantViewingRateApi as InstantViewingRateApiConstructor,
  NoticeApi as NoticeApiConstructor,
  ProductApi as ProductApiConstructor,
  ProjectApi as ProjectApiConstructor,
  ReachAnalysis2Api as ReachAnalysis2ApiConstructor,
  ReachAnalysisApi as ReachAnalysisApiConstructor,
  ReachSimulatorApi as ReachSimulatorApiConstructor,
  TimeslotApi as TimeslotApiConstructor,
  TroApi as TroApiConstructor,
  TvDataSearchApi as TvDataSearchApiConstructor,
  UserinfoApi as UserInfoApiConstructor,
  WorkspaceApi as WorkspaceApiConstructor,
  CmCategoryApi as CmCategoryApiConstructor
} from '@/api/openapi';

const apiConfig = new Configuration({
  basePath: process.env.VUE_APP_API_BASE_URL,
  baseOptions: {
    headers: {
      // Prefer: 'code=200,code=204,code=201,example=example-1'
    }
  }
});

export const AuthApi = new AuthApiConstructor(apiConfig);
export const AccountApi = new AccountsApiConstructor(apiConfig);
export const AreaApi = new AreaApiConstructor(apiConfig);
export const BasicTargetApi = new BasicTargetApiConstructor(apiConfig);
export const CmSponsorApi = new CmSponsorApiConstructor(apiConfig);
export const CompanyApi = new CompanyApiConstructor(apiConfig);
export const DefaultApi = new DefaultApiConstructor(apiConfig);
export const InstantViewingRateApi = new InstantViewingRateApiConstructor(
  apiConfig
);
export const UserInfoApi = new UserInfoApiConstructor(apiConfig);
export const WorkspaceApi = new WorkspaceApiConstructor(apiConfig);
export const ProductApi = new ProductApiConstructor(apiConfig);
export const CustomTargetApi = new CustomTargetApiConstructor(apiConfig);
export const ProjectApi = new ProjectApiConstructor(apiConfig);
export const ReachAnalysisApi = new ReachAnalysisApiConstructor(apiConfig);
export const ReachAnalysis2Api = new ReachAnalysis2ApiConstructor(apiConfig);
export const NoticeApi = new NoticeApiConstructor(apiConfig);
export const HomeApi = new HomeApiConstructor(apiConfig);
export const TimeslotApi = new TimeslotApiConstructor(apiConfig);
export const TvDataSearchApi = new TvDataSearchApiConstructor(apiConfig);
export const CmsiApi = new CmSalesImpactApiConstructor(apiConfig);
export const CmsisApi = new CmSalesImpactSimulatorApiConstructor(apiConfig);
export const TroApi = new TroApiConstructor(apiConfig);
export const ReachSimulatorApi = new ReachSimulatorApiConstructor(apiConfig);
export const CmCategoryApi = new CmCategoryApiConstructor(apiConfig);
