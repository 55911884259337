<template>
  <transition name="fade" appear>
    <div
      v-if="isOpen"
      class="ui-alert"
      :class="variant"
      style="background-color: #fff3cd; border:1px solid #ffecb5"
    >
      <Flex align-center>
        <Icon
          v-if="variant === 'emergency' || variant === 'bug'"
          name="exclamation"
          class="alert-icon"
          style="color: #D9AF4E"
        />
        <Icon
          v-else
          :name="variant !== 'error' ? 'info' : 'exclamation'"
          class="alert-icon"
        />
        <Box flex="1">
          <Typography line-height="1.5" class="ml-4 alert-typography">
            <slot />
          </Typography>
        </Box>
        <button v-if="!hideClose" class="close-button ml-4" @click="close">
          <Icon name="close" sides="12" class="close-icon" />
        </button>
      </Flex>
    </div>
  </transition>
</template>
<script>
import { defineComponent } from 'vue';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Icon from '@/components/ui/Icon.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'Alert',
  components: { Box, Flex, Icon, Typography },
  props: {
    hideClose: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String, // 'warning', 'emergency', 'bug', 'success', 'info', 'error'
      default: 'warning'
    }
  },
  emits: ['close'],
  setup(_, { emit }) {
    // TODO
    // - 表示されるときに fadein つける

    return {
      close: () => emit('close')
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/color';
@import '@/styles/spacing';

.ui-alert {
  position: relative;
  padding: $spacing-base * 4;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  box-shadow: var(--shadow-2);

  .close-button {
    position: relative;

    .close-icon {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      position: absolute;
      top: 0;
      left: -5px;
      border-radius: 50%;
      z-index: 1;
      opacity: 0;
      transition-property: opacity;
      transition-duration: var(--transition-duration);
      transition-timing-function: var(--transition-timing-function);
    }

    &:active {
      opacity: var(--action-opacity);
    }

    &:hover {
      cursor: pointer;
      &::before {
        opacity: 1;
      }
    }
  }

  &.warning {
    background-color: var(--tertiary-light-color);
    border-color: var(--tertiary-color);

    .alert-icon {
      color: var(--tertiary-dark-color);
    }

    .close-button::before {
      background: rgba($tertiary-color, 0.3);
    }
  }

  &.emergency {
    background-color: var(--red-blue_red-5);
    border-color: var(--red-blue_red-5);
    border: 0;
    color: var(--contrast-color);
    .ui-typography {
      color: inherit;
    }

    .alert-icon {
      color: var(--contrast-color);
    }

    .close-button {
      color: var(--contrast-color);

      .ui-icon {
        color: inherit;
      }
    }
  }

  &.bug {
    background-color: var(--red-blue_red-1);
    border-color: var(--red-blue_red-5);

    .alert-icon {
      color: var(--red-blue_red-5);
    }

    .close-button::before {
      background: var(--red-blue_red-2);
    }
  }

  &.error {
    background-color: var(--red-blue_red-5);
    border-color: var(--red-blue_red-5);
    .alert-typography {
      color: var(--contrast-color);
    }
    .alert-icon {
      color: var(--contrast-color);
    }
    .close-icon {
      color: var(--contrast-color);
    }
  }

  &.success {
    background-color: var(--green-light-color);
    border-color: var(--green-color);

    .alert-icon {
      color: var(--green-color);
    }

    .close-button::before {
      background: rgba($green-color, 0.3);
    }
  }

  &.info {
    background-color: var(--contrast-color);
    border-color: var(--primary-color);

    .alert-icon {
      color: var(--primary-color);
    }

    .close-button::before {
      background: var(--primary-light-color);
    }
  }
}
</style>
