import { defineComponent } from 'vue';

export const SETTINGS_ROUTES = {
  index: 'settingIndex',
  brand: 'brand',
  basic: 'basicSetting',
  brandEdit: 'brandEdit'
} as const;

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE_BASE = ' | 設定';

const TITLE = {
  index: '設定',
  brand: '商品／ブランド' + TITLE_BASE,
  basic: '基本設定' + TITLE_BASE,
  brandEdit: '商品／ブランドの編集'
} as const;

export default [
  {
    path: '/company/:companyId/setting/products',
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/settings/index.vue'),
    meta: {
      title: TITLE.index
    },
    children: [
      {
        path: '/company/:companyId/setting/products',
        name: SETTINGS_ROUTES.brand,
        meta: {
          title: TITLE.brand
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/brand/index.vue')
      },
      {
        name: SETTINGS_ROUTES.brandEdit,
        path: '/company/:companyId/setting/products/edit/:brandId',
        meta: {
          title: TITLE.brandEdit
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/brand/BrandEdit.vue')
      },
      {
        name: SETTINGS_ROUTES.basic,
        path: '/company/:companyId/setting/basic',
        meta: {
          title: TITLE.basic
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/settings/Basic.vue')
      }
    ]
  }
];
