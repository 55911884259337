<template>
  <BaseButton
    class="icon-button"
    :class="[sizeClass, iconClass]"
    :disabled="disabled"
  >
    <Icon
      :name="iconName"
      :color="disabled ? 'disabled' : color ? color : undefined"
      :sides="sides"
    />
  </BaseButton>
</template>
<script>
import { defineComponent, computed } from 'vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import Icon from '@/components/ui/Icon.vue';

export default defineComponent({
  name: 'IconButton',
  components: { BaseButton, Icon },
  props: {
    disabled: Boolean,
    size: String, // 'xxxxl' | 'l' | 'm' | 'ms' |  's'
    iconName: String,
    color: String
  },
  setup(props) {
    return {
      sizeClass: computed(() => props.size || 's'),
      iconClass: computed(() => props.iconName),
      sides: computed(() => {
        if (props.size === 'xxxxl') {
          return '40';
        } else if (props.size === 'l') {
          return '24';
        } else if (props.size === 'm') {
          return '20';
        } else if (props.size === 'ms') {
          return '16';
        } else if (props.size === 'xs') {
          return '10';
        } else {
          return '12';
        }
      })
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/effect';

.icon-button {
  position: relative;
  line-height: 0;
  vertical-align: top;

  &::before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    border-radius: 100%;
    background-color: var(--background-hover-color);
    transition-property: opacity;
    transition-duration: var(--transition-duration);
    transition-timing-function: var(--transition-timing-function);
  }

  &.xs {
    width: 10px;
    height: 10px;

    &::before {
      top: -5px;
      left: -5px;
      width: 20px;
      height: 20px;
    }
  }

  &.s {
    width: 12px;
    height: 12px;

    &::before {
      top: -5px;
      left: -5px;
      width: 22px;
      height: 22px;
    }
  }

  &.ms {
    width: 16px;
    height: 16px;

    &::before {
      top: -5px;
      left: -5px;
      width: 26px;
      height: 26px;
    }
  }

  &.m {
    width: 20px;
    height: 20px;

    &::before {
      top: -8px;
      left: -8px;
      width: 36px;
      height: 36px;
    }
  }

  &.l {
    width: 24px;
    height: 24px;

    &::before {
      top: -8px;
      left: -8px;
      width: 40px;
      height: 40px;
    }
  }

  .ui-icon {
    position: relative;
  }

  &:hover:not(:disabled) {
    color: var(--primary-color);
    fill: var(--primary-color);

    &::before {
      opacity: 1;
    }
  }

  &.disabled {
    .ui-icon {
      color: var(--form-disabled-color);
      fill: var(--form-disabled-color);
    }
  }
}
</style>
