import { Ref, ref } from 'vue';

const useToggle = (
  initialState = false
): [Ref<boolean>, () => void, () => void, () => void] => {
  const state = ref(initialState);
  const setTrue = () => (state.value = true);
  const setFalse = () => (state.value = false);
  const toggle = () => (state.value = !state.value);

  return [state, setTrue, setFalse, toggle];
};

export default useToggle;
