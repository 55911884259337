import { Ref } from 'vue';
import useToggle from './toggle';

const useModal = (
  initialState = false
): {
  isModalOpen: Ref<boolean>;
  openModal: () => void;
  closeModal: () => void;
} => {
  const [isModalOpen, openModal, closeModal] = useToggle(initialState);
  return { isModalOpen, openModal, closeModal };
};

export default useModal;
