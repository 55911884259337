
import { defineComponent, computed, ref, watchEffect } from 'vue';
export default defineComponent({
  name: 'Image',
  props: {
    src: [String],
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    height: String,
    minWidth: String
  },
  setup(props) {
    const imageElement = ref();
    const loading = ref(true);
    const noImage = ref(false);

    watchEffect(() => {
      if (props.src === undefined) {
        noImage.value = true;
        loading.value = false;
        return;
      }

      if (imageElement.value) {
        imageElement.value.onload = () => {
          if (imageElement.value.complete) {
            loading.value = false;
            noImage.value = false;
          }
        };
        imageElement.value.onerror = () => {
          loading.value = false;
          noImage.value = true;
        };
      }
    });

    return {
      imageElement,
      loading,
      noImage,
      loadingClass: computed(() => (loading.value ? 'loading' : '')),
      noImageClass: computed(() => (noImage.value ? 'no-image' : ''))
    };
  }
});
