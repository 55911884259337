<template>
  <rect width="20" height="20" rx="10" fill="none" />
  <path
    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
    fill="#7FCD5B"
  />
  <path
    d="M10.0083 7.15L10.775 8.68333C10.8167 8.75833 10.8917 8.81667 10.975 8.825L12.675 9.08333C12.8917 9.11667 12.9833 9.38333 12.825 9.54167L11.6 10.75C11.5417 10.8083 11.5083 10.9 11.525 10.9833L11.8083 12.675C11.8417 12.8917 11.6167 13.0583 11.4167 12.9583L9.89167 12.1667C9.81667 12.125 9.72501 12.125 9.64168 12.1667L8.11668 12.9583C7.91668 13.0583 7.69168 12.8917 7.72501 12.675L8.00835 10.9833C8.02501 10.9 7.99168 10.8083 7.93334 10.75L6.70834 9.54167C6.55001 9.38333 6.64168 9.11667 6.85834 9.08333L8.55834 8.825C8.64168 8.80833 8.71667 8.75833 8.75834 8.68333L9.52501 7.15C9.62501 6.95 9.90834 6.95 10.0083 7.15Z"
    fill="white"
  />
</template>
