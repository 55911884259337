<template>
  <Typography
    line-height="1"
    class="ui-list-item"
    :class="{ inactive, current, dense, 'over-hover': overHover }"
  >
    <slot />
  </Typography>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'ListItem',
  props: {
    inactive: Boolean,
    overHover: Boolean,
    current: Boolean,
    dense: Boolean // 余白低め
  },
  components: { Typography }
});
</script>
<style lang="scss">
@import '@/styles/spacing';

.ui-list-item {
  display: block;
  padding: $spacing-base * 1.5 $spacing-base * 2 $spacing-base * 1.5
    $spacing-base * 4;

  &.dense {
    padding: $spacing-base * 2;
  }

  &.current {
    background-color: var(--primary-light-color);
  }

  &:not(.inactive, .over-hover, .current) {
    cursor: pointer;
    transition-property: background-color;
    transition-duration: var(--transition-duration);
    transition-timing-function: var(--transition-timing-function);

    &:hover {
      background-color: var(--background-hover-color);
    }
  }

  &.over-hover:not(.current) {
    &:hover {
      cursor: pointer;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -$spacing-base * 6;
        width: calc(100% + #{($spacing-base * 6) * 2});
        height: 100%;
        background-color: var(--background-hover-color);
      }
    }
  }
}
</style>
