<template>
  <g clip-path="url(#clip0_1175_3919)">
    <path
      d="M16 1.03003C16.7957 1.03003 17.5587 1.3461 18.1213 1.90871C18.6839 2.47132 19 3.23438 19 4.03003V16.03C19 16.8257 18.6839 17.5887 18.1213 18.1514C17.5587 18.714 16.7957 19.03 16 19.03H4C3.20435 19.03 2.4413 18.714 1.87869 18.1514C1.31608 17.5887 1 16.8257 1 16.03V4.03003C1 3.23438 1.31608 2.47132 1.87869 1.90871C2.4413 1.3461 3.20435 1.03003 4 1.03003H16V1.03003ZM16 0.0300293H4C2.93913 0.0300293 1.92173 0.451454 1.17159 1.2016C0.42144 1.95174 0 2.96916 0 4.03003V16.03C0 17.0909 0.42144 18.1083 1.17159 18.8585C1.92173 19.6086 2.93913 20.03 4 20.03H16C17.0609 20.03 18.0783 19.6086 18.8284 18.8585C19.5786 18.1083 20 17.0909 20 16.03V4.03003C20 2.96916 19.5786 1.95174 18.8284 1.2016C18.0783 0.451454 17.0609 0.0300293 16 0.0300293Z"
    />
    <path
      d="M8.21 3.5H4.79C4.07755 3.5 3.5 4.07755 3.5 4.79V8.21C3.5 8.92245 4.07755 9.5 4.79 9.5H8.21C8.92245 9.5 9.5 8.92245 9.5 8.21V4.79C9.5 4.07755 8.92245 3.5 8.21 3.5Z"
    />
    <path
      d="M15.21 3.5H11.79C11.0776 3.5 10.5 4.07755 10.5 4.79V8.21C10.5 8.92245 11.0776 9.5 11.79 9.5H15.21C15.9224 9.5 16.5 8.92245 16.5 8.21V4.79C16.5 4.07755 15.9224 3.5 15.21 3.5Z"
    />
    <path
      d="M8.21 10.5H4.79C4.07755 10.5 3.5 11.0776 3.5 11.79V15.21C3.5 15.9224 4.07755 16.5 4.79 16.5H8.21C8.92245 16.5 9.5 15.9224 9.5 15.21V11.79C9.5 11.0776 8.92245 10.5 8.21 10.5Z"
    />
    <path
      d="M15.21 10.5H11.79C11.0776 10.5 10.5 11.0776 10.5 11.79V15.21C10.5 15.9224 11.0776 16.5 11.79 16.5H15.21C15.9224 16.5 16.5 15.9224 16.5 15.21V11.79C16.5 11.0776 15.9224 10.5 15.21 10.5Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_1175_3919">
      <rect width="20" height="20" />
    </clipPath>
  </defs>
</template>
