<template>
  <g clip-path="url(#clip0_1829_4908)">
    <path
      d="M16.3853 5.82126C16.3853 5.82126 16.3154 5.51172 9.60552 5.51172C3.26504 5.51172 2.82569 5.78132 2.79574 5.81127C2.76578 5.81127 2.43628 6.02096 2.43628 10.1547C2.43628 14.2885 2.76578 14.4882 2.79574 14.4982C2.81571 14.5282 3.19514 14.8078 9.60552 14.8078H10.594C10.604 14.7279 10.604 14.648 10.624 14.5681C10.9735 12.0219 13.1302 10.0649 15.7563 10.0649C16.0958 10.0649 16.4253 10.1048 16.7448 10.1747C16.7448 5.85121 16.3853 5.82126 16.3853 5.82126ZM12.9705 9.12629C12.7807 9.12629 12.611 9.0564 12.4612 8.96653L10.6539 10.6041C10.7238 10.7339 10.7638 10.8737 10.7638 11.0334C10.7638 11.5626 10.3344 11.992 9.80522 11.992C9.27601 11.992 8.84665 11.5626 8.84665 11.0334C8.84665 10.8737 8.89658 10.7339 8.96647 10.6041L7.948 9.66549C7.79823 9.75535 7.63847 9.82524 7.44875 9.82524C7.25904 9.82524 7.08929 9.75535 6.93952 9.6555L5.28201 11.1333C5.34192 11.2631 5.39184 11.4029 5.39184 11.5527C5.39184 12.0819 4.96249 12.5112 4.43328 12.5112C3.90408 12.5112 3.47472 12.0819 3.47472 11.5527C3.47472 11.0234 3.90408 10.5941 4.43328 10.5941C4.623 10.5941 4.79274 10.664 4.94252 10.7638L6.60003 9.28606C6.54012 9.15625 6.49019 9.01646 6.49019 8.86668C6.49019 8.33748 6.91955 7.90812 7.44875 7.90812C7.97796 7.90812 8.40731 8.33748 8.40731 8.86668C8.40731 9.02644 8.35739 9.16623 8.28749 9.29604L9.30596 10.2346C9.45574 10.1448 9.6155 10.0749 9.80522 10.0749C9.99493 10.0749 10.1647 10.1448 10.3145 10.2346L12.1217 8.59709C12.0518 8.46728 12.0119 8.32749 12.0119 8.16773C12.0119 7.63853 12.4413 7.20917 12.9705 7.20917C13.4997 7.20917 13.929 7.63853 13.929 8.16773C13.929 8.69694 13.4997 9.12629 12.9705 9.12629Z"
      fill="#4674D7"
    />
    <path
      d="M10.614 16.016H1.75734C1.40786 16.016 1.13827 15.7264 1.13827 15.3869V4.92261C1.13827 4.57314 1.41785 4.30354 1.75734 4.30354H17.4138C17.7633 4.30354 18.0329 4.58312 18.0329 4.92261V10.6241C18.2026 10.7039 18.3724 10.7838 18.5322 10.8837C18.7618 11.0334 18.9715 11.2132 19.1812 11.3929V4.75287C19.1812 3.75437 18.3724 2.94558 17.3739 2.94558H11.5327L12.9306 1.54768C13.29 1.18822 13.29 0.629056 12.9306 0.269595C12.5711 -0.0898651 12.0119 -0.0898651 11.6525 0.269595L9.56561 2.35647L7.48873 0.279582C7.12927 -0.0798779 6.57011 -0.0798779 6.21065 0.279582C5.85119 0.639043 5.85119 1.1982 6.21065 1.55766L7.60855 2.95556H1.79728C0.798776 2.95556 -0.0100098 3.76435 -0.0100098 4.76285V15.5566C-0.0100098 16.5452 0.798776 17.3639 1.79728 17.3639H2.39638V18.5621H4.20366L4.60306 17.3639H11.0234C10.8437 16.9645 10.7039 16.5452 10.624 16.1058C10.624 16.0759 10.624 16.0459 10.624 16.016H10.614Z"
      fill="#4674D7"
    />
    <path
      d="M19.1812 12.8208H19.2011C18.8816 12.3615 18.4922 11.992 18.0229 11.6925C17.8032 11.5527 17.5736 11.4329 17.3439 11.333C17.1242 11.2431 16.8946 11.1732 16.6749 11.1233C16.3754 11.0534 16.0758 11.0135 15.7563 11.0135C13.6095 11.0135 11.8522 12.6111 11.5626 14.688C11.5326 14.8777 11.5027 15.0674 11.5027 15.2571C11.5027 15.327 11.5227 15.3969 11.5227 15.4668C11.5227 15.6266 11.5426 15.7863 11.5726 15.9461C11.6525 16.4354 11.8222 16.9046 12.0618 17.334C12.7908 18.6321 14.1587 19.5207 15.7463 19.5207C18.0928 19.5207 19.9899 17.6136 19.9899 15.2771C19.9899 14.3585 19.6904 13.5197 19.1911 12.8208H19.1812ZM18.1028 17.1742C17.7433 17.6136 17.274 17.9431 16.7248 18.1328C16.4153 18.2426 16.0958 18.3125 15.7463 18.3125C15.3769 18.3125 15.0474 18.2327 14.7179 18.1228C14.2585 17.9531 13.8591 17.6835 13.5296 17.344C13.1702 16.9546 12.9206 16.4853 12.7908 15.9561C12.7508 15.8063 12.7408 15.6465 12.7308 15.4768C12.7308 15.4069 12.7109 15.337 12.7109 15.2671C12.7109 15.0574 12.7308 14.8577 12.7708 14.678C13.0504 13.2901 14.2685 12.2416 15.7463 12.2416C16.0558 12.2416 16.3454 12.3016 16.625 12.3914C16.8846 12.4713 17.1143 12.5711 17.3339 12.7109C17.5935 12.8707 17.8232 13.0704 18.0129 13.2901C18.4822 13.8193 18.7718 14.5182 18.7718 15.2771C18.7718 15.996 18.5022 16.655 18.0928 17.1742H18.1028Z"
      fill="#4674D7"
    />
    <path
      d="M17.344 15.0973C17.2841 15.0774 17.2241 15.0574 17.1542 15.0574H16.1657V13.5596C16.1657 13.4498 16.1358 13.3599 16.0859 13.2701C15.986 13.0804 15.7863 12.9506 15.5666 12.9506C15.287 12.9506 15.0774 13.1403 14.9975 13.3899C14.9775 13.4498 14.9675 13.4997 14.9675 13.5596V15.6665C14.9675 15.7663 14.9975 15.8562 15.0374 15.9361C15.1373 16.1358 15.337 16.2756 15.5666 16.2756H17.1542C17.324 16.2756 17.4838 16.2057 17.5936 16.0958C17.6635 16.016 17.7134 15.9261 17.7434 15.8163C17.7434 15.7663 17.7733 15.7164 17.7733 15.6665C17.7733 15.6265 17.7733 15.5966 17.7533 15.5666C17.7134 15.347 17.5636 15.1672 17.3539 15.0973H17.344Z"
      fill="#4674D7"
    />
  </g>
  <defs>
    <clipPath id="clip0_1829_4908">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
