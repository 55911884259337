<template>
  <g clip-path="url(#clip0_39_2570)">
    <path
      d="M19.2036 2.87609L17.0996 0.773007C16.0692 -0.257422 14.4013 -0.257578 13.3707 0.772968L0.973161 13.0895L0.012967 18.9097C-0.0915251 19.5431 0.457029 20.0917 1.09058 19.9873L6.91069 19.027L19.2281 6.62948C20.2566 5.60093 20.2664 3.9389 19.2036 2.87609ZM4.71277 11.797L11.3635 5.18937L14.8108 8.63667L8.20323 15.2874V13.672H6.32823V11.797H4.71277ZM3.20839 17.8959L2.10437 16.7919L2.57859 13.9174L3.29734 13.2032H4.92198V15.0782H6.79698V16.7028L6.08284 17.4216L3.20839 17.8959ZM18.0125 5.41413L18.0105 5.41613L18.0085 5.41812L16.1323 7.30655L12.6937 3.86789L14.5821 1.99172L14.584 1.98976L14.586 1.98781C14.9433 1.63054 15.5226 1.62613 15.8843 1.98777L17.9879 4.09144C18.3738 4.47726 18.3762 5.05038 18.0125 5.41413Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_39_2570">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
