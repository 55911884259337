<template>
  <g clip-path="url(#clip0_1825_20648)">
    <path
      d="M7 10C9.20938 10 11 8.20937 11 6C11 3.79063 9.20938 2 7 2C4.79063 2 3 3.79063 3 6C3 8.20937 4.79063 10 7 10ZM9.8 11H9.27812C8.58437 11.3188 7.8125 11.5 7 11.5C6.1875 11.5 5.41875 11.3188 4.72188 11H4.2C1.88125 11 0 12.8813 0 15.2V16.5C0 17.3281 0.671875 18 1.5 18H12.5C13.3281 18 14 17.3281 14 16.5V15.2C14 12.8813 12.1187 11 9.8 11ZM19.8937 6.9875L19.025 6.10938C18.8813 5.9625 18.6469 5.9625 18.5 6.10625L15.225 9.35625L13.8031 7.925C13.6594 7.77813 13.425 7.77812 13.2781 7.92188L12.4 8.79375C12.2531 8.9375 12.2531 9.17188 12.3969 9.31875L14.95 11.8906C15.0938 12.0375 15.3281 12.0375 15.475 11.8938L19.8906 7.5125C20.0344 7.36562 20.0375 7.13125 19.8937 6.9875Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_1825_20648">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
