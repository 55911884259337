import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { ROUTE_NAMES } from '@/router';
import { Ref } from 'vue';
import useLoading from '@/composables/loading';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js';
import settings from '@/aws-exports';
import { AuthApi } from '@/api';
import { useAuthStore, UserAttribute } from '@/store/auth';
import { asyncAuthenticateUser } from '@/composables/auth/cognito';
import {
  LIMIT_EXCEEDED_EXCEPTION,
  LOGIN_CHECK_ERROR,
  PASSWORD_CHANGE_REQUIRED,
  USER_INVALID_PASSWORD,
  USER_NOT_FOUND_ERROR
} from '@/common/messages';
import { toast } from '@/components/ui/Toast';

type loginArgsType = {
  email: string;
  password: string;
};

type loginReturnType = {
  submitHandler: (values: loginArgsType) => Promise<void>;
  isLoading: Ref<boolean>;
};

export default function useLogin(): loginReturnType {
  const router = useRouter();
  const currentRoute = useRoute();
  const store = useAuthStore();

  const _submitHandler = async (values: loginArgsType) => {
    const error = await login(
      store.setCognitoUser,
      values.email,
      values.password
    );

    let route: RouteLocationRaw = {};

    if (error === PASSWORD_CHANGE_REQUIRED) {
      route = {
        name: ROUTE_NAMES.resetPassword,
        params: {
          type: 'init'
        }
      };
    } else if (error === null) {
      store.login();
      route = {
        name: ROUTE_NAMES.root
      };
    } else {
      toast({ title: error, variant: 'error' });
      return;
    }

    const redirect = currentRoute?.params?.redirect;
    if (!!redirect && redirect instanceof String) {
      route = {
        path: redirect as string
      };
    }
    await router.push(route);
  };
  const [isLoading, submitHandler] = useLoading(_submitHandler);

  return {
    submitHandler,
    isLoading
  };
}

// export for unit test
export const login = async (
  setCognitoUser: (cognitoUser: CognitoUser, email: string) => void,
  userName: string,
  password: string
): Promise<string | null> => {
  const poolData = {
    UserPoolId: settings.aws_user_pools_id,
    ClientId: settings.aws_user_pools_web_client_id
  };
  const userPool = new CognitoUserPool(poolData);
  const cognitoUser = new CognitoUser({
    Username: userName,
    Pool: userPool
  });

  const authDetails = new AuthenticationDetails({
    Username: userName,
    Password: password
  });

  try {
    const token = await asyncAuthenticateUser(cognitoUser, authDetails);
    const response = await AuthApi.getLoginStatus({
      headers: {
        IdentityToken: token
      }
    });
    if (response.status === 200 || response.status === 204) {
      return null;
    } else {
      return LOGIN_CHECK_ERROR;
    }
  } catch (err) {
    const errorType = err as Error | UserAttribute;
    if (errorType instanceof Error) {
      switch (errorType.name) {
        case 'NotAuthorizedException':
          return USER_INVALID_PASSWORD;
        case 'UserNotFoundException':
          return USER_NOT_FOUND_ERROR;
        case 'LimitExceededException':
          return LIMIT_EXCEEDED_EXCEPTION;
        case 'InvalidParameterException':
        case 'UserNotConfirmedException':
        case 'PasswordResetRequiredException':
        default:
          return errorType.message;
      }
    } else {
      // パスワード変更
      setCognitoUser(cognitoUser, errorType.email);
      return PASSWORD_CHANGE_REQUIRED;
    }
  }
};
