<template>
  <Flex flex-direction="column" align-center justify-center height="100vh">
    <Logo class="mb-9" type="header" />
    <ValidationForm
      class="reminder-form"
      :validation-schema="schema"
      v-slot="{ meta }"
      @submit="requestPasswordChangeForm"
    >
      <Card
        title="パスワードを忘れた"
        :card-content-style="{
          margin: '0 auto',
          padding: '24px 15px'
        }"
      >
        <Typography size="m" text-align="center" line-height="1.5"
          >登録アカウント宛に再設定の案内を送信します。</Typography
        >
        <Typography size="m" text-align="center" line-height="1.5"
          >案内メールに従ってパスワードの再設定を行ってください。</Typography
        >
        <Flex alignCenter class="mt-6 mb-6">
          <Label size="m" class="mr-4" bold>アカウント</Label>
          <Box flex="1">
            <VeeTextField size="m" type="text" name="account" />
          </Box>
        </Flex>
        <Button
          class="mt-6"
          block
          type="submit"
          size="l"
          :disabled="!meta.valid || isLoading"
          icon-name="arrow-right"
          icon-sides="12"
          icon-end
        >
          送信
        </Button>
      </Card>
    </ValidationForm>
    <TextLink size="m" class="mt-9">
      <router-link :to="{ name: toLoginName }">ログイン</router-link>
    </TextLink>
  </Flex>
</template>
<script>
import { defineComponent } from 'vue';
import { ROUTE_NAMES } from '@/router';
import { Form as ValidationForm } from 'vee-validate';
import * as Yup from 'yup';
import { VALIDATION_SCHEMA } from '@/common/validation';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import Label from '@/components/ui/Label.vue';
import Card from '@/components/ui/Card.vue';
import TextLink from '@/components/ui/TextLink.vue';
import Typography from '@/components/ui/Typography.vue';
import VeeTextField from '@/components/veeValidate/VeeTextField.vue';
import { useRequestChangingPassword } from '@/composables/auth/password';
import Logo from '@/components/ui/Logo';

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    Logo,
    Card,
    Box,
    Button,
    Flex,
    Label,
    TextLink,
    Typography,
    ValidationForm,
    VeeTextField
  },
  setup() {
    const { email: account } = VALIDATION_SCHEMA;
    const {
      requestPasswordChangeForm,
      isLoading
    } = useRequestChangingPassword();
    return {
      toLoginName: ROUTE_NAMES.login,
      schema: Yup.object().shape({ account }),
      requestPasswordChangeForm,
      isLoading
    };
  }
});
</script>

<style lang="scss" scoped>
.ui-card {
  width: 524px;
  margin: 0 auto;
}

@media (max-width: 540px) {
  form {
    width: 90%;
  }
  .ui-card {
    width: 100%;
  }
}
</style>
