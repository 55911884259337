import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextField = _resolveComponent("BaseTextField")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vee-text-field", [_ctx.sizeClass, _ctx.denseClass]]),
    style: _normalizeStyle({ width: _ctx.width, margin: _ctx.margin, textAlign: _ctx.textAlign })
  }, [
    _createVNode(_component_BaseTextField, _mergeProps({
      ref: "input",
      name: _ctx.name,
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      inputmode: _ctx.inputmode,
      error: !!_ctx.errorMessage,
      size: _ctx.size
    }, _ctx.$attrs, {
      modelValue: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event))
    }), null, 16, ["name", "type", "placeholder", "inputmode", "error", "size", "modelValue"]),
    _withDirectives(_createVNode(_component_ErrorMessage, { class: "mt-1" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.errorMessage]
    ])
  ], 6))
}