<template>
  <div class="header-fixed">
    <Alert
      v-for="(message, i) in messages"
      :key="i"
      :is-open="isPopupMessages[i].isOpen"
      @close="closeAlert({ noticeId: message.noticeId, i })"
      class="rounded-0 alert"
      variant="bug"
    >
      {{ message.content }}
    </Alert>
  </div>
</template>
<script>
import { defineComponent, watch, ref } from 'vue';
import { putPopupAlert } from '@/composables/message';
import Alert from '@/components/ui/Alert.vue';

export default defineComponent({
  name: 'PopupAlert',
  components: {
    Alert
  },
  props: {
    alert: Object
  },
  setup(props) {
    const isPopupMessages = ref([]);
    const messages = ref([]);

    watch(
      () => props.alert.messages.value,
      () => {
        messages.value = props.alert.messages.value;
        isPopupMessages.value = props.alert.messages.value.map(
          ({ noticeId }) => ({
            noticeId,
            isOpen: true
          })
        );
      }
    );
    const closeAlert = ({ noticeId, i }) => {
      putPopupAlert(noticeId);
      isPopupMessages.value[i].isOpen = false;
    };
    return {
      messages,
      isPopupMessages,
      closeAlert
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/layout';
@import '@/styles/spacing';
// TODO: v1リリース以降に修正
.header-fixed {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: $z-index-clickgurard;
  .alert {
    min-height: 37px;
    border: 0 !important;
    padding: 8px 16px !important;
  }
}
</style>
