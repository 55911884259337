import { defineStore } from 'pinia';

const showSidebarKey = 'showSidebar';
const DEFAULT_SHOW_SIDEBAR = '1'; // デフォルト表示

export const useSidebarStore = defineStore('sidebarStore', {
  state: () => ({
    showSidebar: Boolean(
      parseInt(localStorage.getItem(showSidebarKey) ?? DEFAULT_SHOW_SIDEBAR, 10)
    )
  }),
  getters: {
    getShowSidebar: state => state.showSidebar
  },
  actions: {
    toggle() {
      this.showSidebar = !this.showSidebar;
      localStorage.setItem(showSidebarKey, this.showSidebar ? '1' : '0');

      // サイドバーが開閉した時に、resizeイベントを発火させ highcharts のグラフをリサイズする
      setTimeout(() => {
        dispatchEvent(new Event('resize'));
      }, 150);
    },
    show() {
      this.showSidebar = true;
      localStorage.setItem(showSidebarKey, '0');
    },
    hide() {
      this.showSidebar = false;
      localStorage.setItem(showSidebarKey, '0');
    }
  }
});
