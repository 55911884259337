<template>
  <ClickGuard v-if="isOpen" @click="close" />
  <ScrollYTransition
    appear
    scroll-y
    :reverse="reverse"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <Card
      v-if="isOpen"
      class="header-navigation-menu rounded-1"
      :style="{ right }"
      padless
    >
      <slot />
    </Card>
  </ScrollYTransition>
</template>
<script>
import { defineComponent, ref } from 'vue';
import Card from '@/components/ui/Card.vue';
import ClickGuard from '@/components/ui/ClickGuard.vue';
import ScrollYTransition from '@/components/ui/ScrollYTransition.vue';

export default defineComponent({
  name: 'HeaderNavigationMenu',
  components: { Card, ClickGuard, ScrollYTransition },
  props: {
    isOpen: Boolean,
    right: String
  },
  emits: ['close'],
  setup(_, { emit }) {
    const reverse = ref(false);

    return {
      reverse,
      close: () => emit('close'),
      afterEnter: () => (reverse.value = true),
      afterLeave: () => (reverse.value = false)
    };
  }
});
</script>

<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/layout';

.header-navigation-menu {
  position: absolute;
  width: 250px;
  margin-top: $spacing-base * 2;
  z-index: $z-index-clickgurard + 1;
}
</style>
