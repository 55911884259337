
import { defineComponent, ref, watchEffect, watch } from 'vue';
import { useRoute } from 'vue-router';
import { ROUTE_NAMES, SIGNUP_WORKSPACE_ROUTERS } from '@/router';
import { AclRule } from '@/plugins/acl';
import { useAcl } from '@/store/acl';
import { useUserInfoStore } from '@/store/userInfo';
import { useAuthStore } from '@/store/auth';
import { useBasicTarget } from '@/composables/basicTarget';
import HeaderNavigation from '@/components/layout/HeaderNavigation/index.vue';
import FooterNavigation from '@/components/layout/FooterNavigation/index.vue';
import PageLoading from '@/components/ui/PageLoading.vue';
import Fallback from '@/pages/Fallback.vue';

export default defineComponent({
  name: 'App',
  components: {
    Fallback,
    HeaderNavigation,
    FooterNavigation,
    PageLoading
  },
  setup() {
    const authStore = useAuthStore();
    const route = useRoute();
    const isLoggedIn = authStore.isLoggedIn;
    const noHeader = ref(true);
    const noFooter = ref(!isLoggedIn);
    const { getBasicTargets } = useBasicTarget();

    watchEffect(() => {
      if (!route.name || typeof route.name !== 'string') return;
      noHeader.value = [
        ROUTE_NAMES.login,
        ROUTE_NAMES.forgotPassword,
        ROUTE_NAMES.resetPassword,
        SIGNUP_WORKSPACE_ROUTERS.apply,
        SIGNUP_WORKSPACE_ROUTERS.select,
        SIGNUP_WORKSPACE_ROUTERS.applyCompleted
      ].includes(route.name);
    });

    // ログイン状態が切り替わった時にフッターの表示条件変更
    watch(
      () => authStore.isLoggedIn,
      isLoggedIn => {
        noFooter.value = !isLoggedIn;
      }
    );

    const userInfoStore = useUserInfoStore();
    const setAcl = (workspaceId: string) => {
      const acl = useAcl();
      const currentWorkspace = userInfoStore.currentWorkspace(workspaceId);
      const payment = currentWorkspace?.paymentType;
      const privilege = currentWorkspace?.accountPrivilege;
      if (privilege && payment) {
        const rule = new AclRule(privilege).and(payment);
        acl?.setAcl(rule);
      }
    };

    if (isLoggedIn) {
      getBasicTargets();
    }

    watch(
      () => route.query,
      query => {
        const workspaceId = query.workspaceId as string | undefined;
        if (workspaceId) {
          setAcl(workspaceId);
        }
      }
    );

    watch(
      () => route.params,
      params => {
        const workspaceId = params.workspaceId as string | undefined;
        if (workspaceId) {
          setAcl(workspaceId);
        }
      }
    );

    return { noHeader, noFooter, userInfoStore };
  }
});
