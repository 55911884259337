<template>
  <ClickGuard v-if="isOpen" @click="close" class="comany-menu-clickguard" />
  <ScrollYTransition
    appear
    scroll-y
    :reverse="reverse"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <Card v-if="isOpen" @click="close" class="company-menu rounded-1" padless>
      <Box v-if="companies" class="company-menu-box">
        <ListItem
          over-hover
          v-for="company in companies"
          :key="company.companyId"
          :class="{ isActive: company.companyId === currentCompany.companyId }"
          @click="selectCompany(company)"
        >
          <Flex v-if="company" justify-space-between align-center>
            <TypographyEllipsis
              width="100%"
              line-height="1.5"
              v-if="company.companyName"
              >{{ company.companyName }}
            </TypographyEllipsis>
            <Icon
              v-if="company.companyId !== currentCompany.companyId"
              name="arrow-right"
              sides="12"
            />
          </Flex>
        </ListItem>
      </Box>
      <Divider class="my-2" />
      <ListItem
        v-if="currentCompany && !currentCompany.workspaceId"
        inactive
        text-align="center"
        size="s"
        line-height="1.5"
      >
        カンパニーが選択されていません
      </ListItem>
      <ListItem v-else @click="selectWorkspace(currentCompany.workspaceId)">
        <Flex>
          <div class="mr-4">
            <WorkspaceLogo :src="workspaceLogo.image" size="m" />
          </div>
          <div>
            <Typography size="s">{{ currentCompany.workspaceName }}</Typography>
            <Typography class="mt-2">ワークスペース管理</Typography>
          </div>
        </Flex>
      </ListItem>
    </Card>
  </ScrollYTransition>
</template>
<script>
import { defineComponent, ref, watchEffect } from 'vue';
import { COMPANY_ROUTES, WORKSPACE_ROUTES } from '@/router';
import { useRouter } from 'vue-router';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Card from '@/components/ui/Card.vue';
import Divider from '@/components/ui/Divider.vue';
import ClickGuard from '@/components/ui/ClickGuard.vue';
import Icon from '@/components/ui/Icon.vue';
import ListItem from '@/components/ui/ListItem.vue';
import ScrollYTransition from '@/components/ui/ScrollYTransition.vue';
import Typography from '@/components/ui/Typography.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';
import WorkspaceLogo from '@/components/ui/WorkspaceLogo.vue';
import { fetchWorkspaceLogo } from '@/composables/fetchImage';

export default defineComponent({
  name: 'CompanyMenu',
  components: {
    Box,
    Card,
    ClickGuard,
    Divider,
    Flex,
    Icon,
    ListItem,
    ScrollYTransition,
    Typography,
    TypographyEllipsis,
    WorkspaceLogo
  },
  props: {
    currentCompany: Object,
    isOpen: Boolean,
    companies: Array,
    workspace: Object
  },
  emits: ['close'],
  setup(props, { emit }) {
    const reverse = ref(false);
    const workspaceLogo = ref({});
    const router = useRouter();

    const selectWorkspace = async workspaceId => {
      await router.push({
        name: WORKSPACE_ROUTES.top,
        params: { workspaceId }
      });
    };
    const selectCompany = async company => {
      await router.push({
        name: COMPANY_ROUTES.top,
        params: {
          companyId: company.companyId
        }
      });
    };
    watchEffect(() => {
      if (props.currentCompany && props.currentCompany.workspaceId) {
        const currentWorkspaceID = props.currentCompany.workspaceId;
        fetchWorkspaceLogo(currentWorkspaceID.toString()).then(image => {
          workspaceLogo.value = { id: currentWorkspaceID, image };
        });
      }
    });

    return {
      reverse,
      close: () => emit('close'),
      afterEnter: () => (reverse.value = true),
      afterLeave: () => (reverse.value = false),
      selectWorkspace,
      selectCompany,
      workspaceLogo
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/layout';

.comany-menu-clickguard.click-guard {
  z-index: $z-index-clickgurard + 10;
}

.company-menu {
  position: absolute;
  top: 50px;
  left: -200px;
  width: 300px;
  z-index: $z-index-clickgurard + 11;

  .ui-list-item.over-hover {
    .ui-typography,
    .ui-icon {
      position: relative;
      z-index: $z-index-clickgurard + 12;
    }
  }
}

.company-menu-box {
  overflow: hidden auto;
  max-height: 260px;
  border-radius: 4px 4px 0 0;
}

.isActive {
  background-color: var(--primary-light-color);
}
</style>
