import { defineComponent } from 'vue';

export const DATASEARCH_ROUTES = {
  index: 'index',
  timeslot: 'datasearchTimeslot',
  cmlist: 'datasearchCmlist',
  programlist: 'datasearchProgramlist',
  cmAggregate: 'cmAggregate',
  cmrf: 'datasearchCmRf'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const PARENT_MENU_TITLE = 'TVデータサーチ';
const TITLE_BASE = ' | ' + PARENT_MENU_TITLE;
const TITLE = {
  timeslot: 'ターゲットヒートマップ' + TITLE_BASE,
  cmlist: 'CMリスト' + TITLE_BASE,
  cmAggregate: 'CM期間集計' + TITLE_BASE,
  cmrf: 'CM R&F' + TITLE_BASE,
  programlist: '番組リスト' + TITLE_BASE
} as const;

export default [
  {
    path: '/company/:companyId/datasearch',
    name: DATASEARCH_ROUTES.index,
    redirect: { name: DATASEARCH_ROUTES.timeslot },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/datasearch/index.vue'),
    children: [
      {
        path: 'timeslot',
        name: DATASEARCH_ROUTES.timeslot,
        meta: {
          parent: PARENT_MENU_TITLE,
          title: TITLE.timeslot
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/datasearch/timeslot/index.vue')
      },
      {
        path: 'cmlist',
        name: DATASEARCH_ROUTES.cmlist,
        meta: {
          parent: PARENT_MENU_TITLE,
          title: TITLE.cmlist
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/datasearch/cmlist/index.vue')
      },
      {
        path: 'cmrf',
        name: DATASEARCH_ROUTES.cmrf,
        meta: {
          parent: PARENT_MENU_TITLE,
          title: TITLE.cmrf
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/datasearch/cmrf/index.vue')
      },
      {
        path: 'cmaggregate',
        name: DATASEARCH_ROUTES.cmAggregate,
        meta: {
          parent: PARENT_MENU_TITLE,
          title: TITLE.cmAggregate
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/datasearch/cmAggregate/Index.vue')
      },
      {
        path: 'programlist',
        name: DATASEARCH_ROUTES.programlist,
        meta: {
          parent: PARENT_MENU_TITLE,
          title: TITLE.programlist
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/datasearch/programlist/index.vue')
      }
    ]
  }
];
