<template>
  <g clip-path="url(#clip0_38_1205)">
    <path
      d="M14.89 8.87C16.5855 8.87 17.96 7.49552 17.96 5.8C17.96 4.10449 16.5855 2.73 14.89 2.73C13.1945 2.73 11.82 4.10449 11.82 5.8C11.82 7.49552 13.1945 8.87 14.89 8.87Z"
    />
    <path
      d="M16.42 9.92H16.3001C15.3883 10.2649 14.3818 10.2649 13.47 9.92H13.3499C12.7469 9.92517 12.1551 10.0834 11.6299 10.38C12.5825 10.7416 13.4079 11.3751 14.0035 12.2018C14.599 13.0285 14.9386 14.0119 14.98 15.03H18.46C18.6618 15.0313 18.8619 14.9927 19.0486 14.9164C19.2354 14.8401 19.4053 14.7276 19.5484 14.5854C19.6915 14.4432 19.8051 14.2741 19.8826 14.0878C19.9601 13.9015 20.0001 13.7018 20.0001 13.5C20.0001 12.5505 19.6229 11.6399 18.9515 10.9686C18.2801 10.2972 17.3695 9.92 16.42 9.92Z"
    />
    <path
      d="M7 10C9.20914 10 11 8.20914 11 6C11 3.79086 9.20914 2 7 2C4.79086 2 3 3.79086 3 6C3 8.20914 4.79086 10 7 10Z"
    />
    <path
      d="M12.5699 12.07C11.8096 11.3847 10.8236 11.0037 9.80011 11H9.28009C8.56511 11.3293 7.78724 11.4998 7.00006 11.4998C6.21288 11.4998 5.43502 11.3293 4.72003 11H4.19C3.07609 11 2.00793 11.4425 1.22028 12.2301C0.432623 13.0178 -0.00994873 14.0861 -0.00994873 15.2V16.5C-0.00994873 16.8978 0.147956 17.2793 0.42926 17.5607C0.710565 17.842 1.09223 18 1.49005 18H12.4901C12.8879 18 13.2693 17.842 13.5506 17.5607C13.8319 17.2793 13.9901 16.8978 13.9901 16.5V15.2C13.9901 15.13 13.9901 15.07 13.9901 15C13.9609 14.4416 13.8201 13.8946 13.5762 13.3914C13.3324 12.8882 12.9901 12.4389 12.5699 12.07Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_38_1205">
      <rect width="20" height="16" fill="white" transform="translate(0 2)" />
    </clipPath>
  </defs>
</template>
