<template>
  <svg viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;">
    <g>
      <polygon
        points="440.189,92.085 256.019,276.255 71.83,92.085 0,163.915 256.019,419.915 512,163.915 	"
        style="fill: rgb(75, 75, 75);"
      ></polygon>
    </g>
  </svg>
</template>
