<template>
  <div class="ui-divider" />
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Divider'
});
</script>
<style lang="scss" scoped>
.ui-divider {
  width: 100%;
  border-top: 1px solid var(--border-color);
}
</style>
