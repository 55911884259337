/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
"aws_project_region": "ap-northeast-1",
"aws_cognito_region": "ap-northeast-1",
"aws_user_pools_id": "ap-northeast-1_RYUpRSEdw",
"aws_user_pools_web_client_id": "71iq6c4f9at4q7mtra2a61h9q6",
"oauth": {}
};

export default awsmobile;

