export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT_SLASH = 'yyyy/MM/dd';
export const DATE_FORMAT_JA = 'yyyy年M月d日';
export const DATE_FORMAT_SLASH_AND_TIME = 'yyyy/MM/dd HH:mm';
export const DATE_FORMAT_JA_FULL = 'yyyy年M月d日(E) HH:mm';

/**
 * 'YYYY-MM-DD hh:mm:ss'の文字列から日付のみ取り出し
 *
 * @param dateTime 'YYYY-MM-DD hh:mm:ss'
 */
export const trimDate = (dateTime: string | undefined): string | undefined => {
  // 'YYYY-MM-DD hh:mm:ss' => 'YYYY-MM-DD'
  return dateTime?.substring(0, 10);
};

/**
 * Date型を'YYYY-MM-DD hh:mm'形式の分単位までの文字列へ変換
 *
 * @param date Date型の日時
 */
export const formatInMinute = (date: Date): string => {
  return date
    .toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'Asia/Tokyo'
    })
    .replace(/\//g, '-');
};

/**
 * Date型を'YYYY-MM-DD'形式の日付の文字列へ変換
 *
 * @param date Date型の日時
 */
export const formatDate = (date: Date): string => {
  return date
    .toLocaleString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Asia/Tokyo'
    })
    .replace(/\//g, '-');
};

export const formatWeekday = (date: Date): string => {
  return date
    .toLocaleString('ja-JP', {
      weekday: 'short',
      timeZone: 'Asia/Tokyo'
    })
    .replace(/\//g, '-');
};
