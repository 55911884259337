
import { defineComponent } from 'vue';

// suspenseの#fallback発生させるためのコンポーネント
// sleepしているがfallback表示中に#defaultの処理は動いているため
// 無駄な待ち時間にはならない
// sleep指定の時間は感覚値
export default defineComponent({
  async setup() {
    const sleep = (msec: number) =>
      new Promise(resolve => setTimeout(resolve, msec));
    await sleep(250);
    return;
  }
});
