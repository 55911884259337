<template>
  <g
    ><circle
      cx="14.6154"
      cy="9.88461"
      r="1.38462"
      transform="rotate(90 14.6154 9.88461)"
      fill="#425252"
    />
    <ellipse
      cx="10"
      cy="9.88461"
      rx="1.38462"
      ry="1.38462"
      transform="rotate(90 10 9.88461)"
      fill="#425252"
    />
    <ellipse
      cx="5.38461"
      cy="9.88461"
      rx="1.38462"
      ry="1.38462"
      transform="rotate(90 5.38461 9.88461)"
      fill="#425252"
    />
  </g>
</template>
