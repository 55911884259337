<template>
  <transition :name="transitionName" v-bind="$attrs">
    <slot />
  </transition>
</template>
<script>
import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'ScrollYTransition',
  props: {
    reverse: Boolean
  },
  setup(props) {
    return {
      transitionName: computed(() =>
        props.reverse ? 'fade-scroll-y-reverse' : 'fade-scroll-y'
      )
    };
  }
});
</script>
<style lang="scss">
@mixin fade-dafault {
  &-enter-active,
  &-leave-active {
    transition: 0.2s cubic-bezier(0, 0, 0.58, 1);
  }

  &-move {
    transition: transform 0.6s;
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}

.fade-scroll-y {
  @include fade-dafault();

  &-enter-from {
    transform: translateY(-15px);
  }

  &-leave-to {
    transform: translateY(15px);
  }
}

.fade-scroll-y-reverse {
  @include fade-dafault();

  &-enter-from {
    transform: translateY(15px);
  }

  &-leave-to {
    transform: translateY(-15px);
  }
}
</style>
