<template>
  <!-- <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  > -->
  <path
    d="M11.9 4.41002H10.72C10.48 4.41002 10.28 4.61002 10.28 4.85002V10.88C10.28 11.02 10.35 11.15 10.46 11.24L13.58 13.51C13.78 13.65 14.05 13.61 14.2 13.41L14.89 12.46C15.03 12.26 14.99 11.99 14.79 11.84L12.34 10.05V4.84002C12.34 4.60002 12.14 4.40002 11.9 4.40002V4.41002Z"
  />
  <path
    d="M11.31 0.890015C6.87 0.890015 3.18 4.06002 2.37 8.26002H1.14C0.559996 8.26002 0.219996 8.91001 0.549996 9.38001L2.62 12.41C2.91 12.83 3.52 12.83 3.81 12.41L5.88 9.38001C6.21 8.90001 5.86 8.26002 5.29 8.26002H4.19C5.08 4.59001 8.72 2.00001 12.8 2.80001C15.67 3.36001 17.98 5.67001 18.53 8.53001C19.44 13.24 15.87 17.35 11.32 17.35C9.39 17.35 7.64 16.6 6.33 15.39C6.16 15.23 5.9 15.23 5.73 15.39L5.09 16.03C4.92 16.2 4.92 16.48 5.09 16.65C6.73 18.19 8.94 19.13 11.37 19.12C16.2 19.09 20.29 15.12 20.43 10.29C20.58 5.13001 16.45 0.900015 11.32 0.900015L11.31 0.890015Z"
  />
  <!-- </svg> -->
</template>
