<template>
  <path
    d="M6.26001 13.1301C6.26001 13.5401 6.60001 13.8801 7.01001 13.8801H8.72001C8.69001 13.6701 8.66001 13.4601 8.66001 13.2501C8.66001 12.9801 8.69001 12.7001 8.74001 12.3801H7.01001C6.59001 12.3801 6.26001 12.7201 6.26001 13.1301Z"
    fill="#4674D7"
  />
  <path
    d="M4 7.99011C4.27 7.99011 4.52 7.89011 4.71 7.70011C4.9 7.51011 5 7.26011 5 6.99011C5 6.72011 4.9 6.47011 4.71 6.28011C4.52 6.09011 4.27 5.99011 4 5.99011C3.73 5.99011 3.48 6.09011 3.29 6.28011C3.1 6.47011 3 6.72011 3 6.99011C3 7.26011 3.1 7.51011 3.29 7.70011C3.48 7.89011 3.73 7.99011 4 7.99011Z"
    fill="#4674D7"
  />
  <path
    d="M4 10.9901C4.27 10.9901 4.52 10.8901 4.71 10.7001C4.9 10.5101 5 10.2601 5 9.99011C5 9.72011 4.9 9.47011 4.71 9.28011C4.52 9.09011 4.27 8.99011 4 8.99011C3.73 8.99011 3.48 9.09011 3.29 9.28011C3.1 9.47011 3 9.72011 3 9.99011C3 10.2601 3.1 10.5101 3.29 10.7001C3.48 10.8901 3.73 10.9901 4 10.9901Z"
    fill="#4674D7"
  />
  <path
    d="M14.01 3.2301H7.01001C6.59001 3.2301 6.26001 3.5701 6.26001 3.9801C6.26001 4.3901 6.60001 4.7301 7.01001 4.7301H14.01C14.43 4.7301 14.76 4.3901 14.76 3.9801C14.76 3.5701 14.42 3.2301 14.01 3.2301Z"
    fill="#4674D7"
  />
  <path
    d="M4.01001 12.1301C3.74001 12.1301 3.49001 12.2301 3.30001 12.4201C3.11001 12.6101 3.01001 12.8601 3.01001 13.1301C3.01001 13.4001 3.11001 13.6501 3.30001 13.8401C3.49001 14.0301 3.74001 14.1301 4.01001 14.1301C4.28001 14.1301 4.53001 14.0301 4.72001 13.8401C4.91001 13.6501 5.01001 13.4001 5.01001 13.1301C5.01001 12.8601 4.91001 12.6101 4.72001 12.4201C4.53001 12.2301 4.28001 12.1301 4.01001 12.1301Z"
    fill="#4674D7"
  />
  <path
    d="M4 4.9801C4.27 4.9801 4.52 4.88011 4.71 4.69011C4.9 4.50011 5 4.2501 5 3.9801C5 3.7101 4.9 3.4601 4.71 3.2701C4.52 3.0801 4.27 2.9801 4 2.9801C3.73 2.9801 3.48 3.0801 3.29 3.2701C3.1 3.4601 3 3.7101 3 3.9801C3 4.2501 3.1 4.50011 3.29 4.69011C3.48 4.88011 3.73 4.9801 4 4.9801Z"
    fill="#4674D7"
  />
  <path
    d="M6.26001 9.99011C6.26001 10.4001 6.60001 10.7401 7.01001 10.7401H9.28001C9.39001 10.5401 9.50001 10.3401 9.63001 10.1501C9.84001 9.85012 10.03 9.62012 10.25 9.40012C10.31 9.34012 10.38 9.30011 10.45 9.24011H7.01001C6.59001 9.24011 6.26001 9.58011 6.26001 9.99011Z"
    fill="#4674D7"
  />
  <path
    d="M9.88 16.6701C9.61 16.3401 9.4 15.9901 9.22 15.6401H2C1.73 15.6401 1.51 15.4201 1.51 15.1501V1.99008C1.51 1.72008 1.73 1.50008 2 1.50008H15.99C16.26 1.51008 16.48 1.73008 16.48 2.00008V8.37008C16.83 8.54008 17.17 8.74008 17.48 8.99008L17.6 9.09008C17.72 9.18008 17.84 9.29008 17.97 9.41008C17.97 9.41008 17.98 9.42008 17.99 9.43008V2.00008C17.99 0.900082 17.09 8.25374e-05 15.99 8.25374e-05H2C0.9 -0.00991746 0 0.890082 0 1.98008V15.1401C0 16.2401 0.9 17.1401 2 17.1401H10.3C10.3 17.1401 10.27 17.1201 10.26 17.1001C10.12 16.9601 10 16.8101 9.89 16.6601L9.88 16.6701Z"
    fill="#4674D7"
  />
  <path
    d="M19.5899 17.7001L17.5299 15.6401C17.8899 15.1201 18.1299 14.5201 18.2299 13.8901C18.2999 13.4901 18.2999 13.0701 18.2299 12.6101C18.0899 11.7101 17.6899 10.9201 17.0799 10.3101C16.9999 10.2301 16.9099 10.1601 16.8199 10.0901L16.6999 10.0001C15.9399 9.40009 15.0499 9.09009 14.1199 9.09009C13.6399 9.09009 13.1499 9.18009 12.6599 9.36009C12.0999 9.57009 11.5899 9.89009 11.1599 10.3101C10.9799 10.4901 10.8299 10.6901 10.6799 10.8901C10.3299 11.4001 10.0999 11.9501 9.99993 12.5501C9.95993 12.7801 9.92993 13.0301 9.92993 13.2701C9.92993 13.5301 9.95993 13.8001 10.0099 14.0701C10.1299 14.7001 10.4099 15.3001 10.8999 15.9101C10.9799 16.0201 11.0599 16.1201 11.1599 16.2201C11.7699 16.8301 12.5599 17.2301 13.4299 17.3801C13.6499 17.4201 13.8799 17.4501 14.1099 17.4501C14.3699 17.4501 14.6199 17.4301 14.8299 17.3801C15.4199 17.2801 15.9799 17.0401 16.4999 16.6801L18.6099 18.7901C18.7499 18.9301 18.9299 19.0001 19.1299 19.0001C19.3099 19.0001 19.4999 18.9301 19.6499 18.7901C19.9299 18.5101 19.9299 18.0501 19.6499 17.7601L19.5999 17.7101L19.5899 17.7001ZM16.4599 14.5901L16.4199 14.6801C16.3199 14.8401 16.1999 15.0201 16.0399 15.1801C15.8599 15.3601 15.6399 15.5001 15.4499 15.6101C15.0199 15.8401 14.5599 15.9601 14.0999 15.9601C13.6399 15.9601 13.1699 15.8401 12.7599 15.6101C12.5199 15.4901 12.3299 15.3501 12.1799 15.1901C11.8499 14.8601 11.6199 14.4501 11.4899 13.9901C11.4099 13.7001 11.3799 13.4701 11.3799 13.2501C11.3799 13.0501 11.4099 12.8301 11.4699 12.5901C11.5999 12.1001 11.8299 11.6701 12.1799 11.3201C12.2699 11.2301 12.3699 11.1501 12.4799 11.0801C12.9699 10.7101 13.5299 10.5201 14.1099 10.5201C14.3599 10.5201 14.6299 10.5801 14.8099 10.6201C15.2899 10.7501 15.7199 10.9901 16.0499 11.3201C16.2199 11.4801 16.3599 11.6801 16.4799 11.9001C16.9599 12.7301 16.9599 13.7701 16.4799 14.6001L16.4599 14.5901Z"
    fill="#4674D7"
  />
  <path
    d="M14.01 6.24011H7.01001C6.59001 6.24011 6.26001 6.58011 6.26001 6.99011C6.26001 7.40011 6.60001 7.74011 7.01001 7.74011H14.01C14.43 7.74011 14.76 7.40011 14.76 6.99011C14.76 6.58011 14.42 6.24011 14.01 6.24011Z"
    fill="#4674D7"
  />
</template>
