import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

// callback関数のままだと非同期処理になっており扱いにくいので、promiseでwrapする
export const asyncAuthenticateUser = async (
  cognitoUser: CognitoUser,
  authDetails: AuthenticationDetails
): Promise<string> => {
  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: session => {
        const idToken = session.getIdToken().getJwtToken();
        resolve(idToken);
      },
      onFailure: err => {
        reject(err);
      },
      newPasswordRequired: userAttributes => {
        delete userAttributes.email_verified;
        reject(userAttributes);
      }
    });
  });
};

export const asyncForgotPassword = async (
  cognitoUser: CognitoUser
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: data => {
        resolve(data);
      },
      onFailure: err => {
        reject(err);
      }
    });
  });
};

export const asyncChangePasswordUnAuth = async (
  cognitoUser: CognitoUser,
  verificationCode: string,
  newPassword: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: success => resolve(success),
      onFailure: err => reject(err)
    });
  });
};

export const asyncCompletePasswordChallenge = async (
  cognitoUser: CognitoUser,
  newPassword: string
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: success => resolve(success),
        onFailure: err => reject(err)
      }
    );
  });
};

export const asyncResendConfirmationCode = async (
  cognitoUser: CognitoUser
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode(err => reject(err));
  });
};
