import { AuthApi } from '@/api';
import { useBasicTarget } from '@/composables/basicTarget';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { defineStore } from 'pinia';

export interface UserAttribute {
  email: string;
}

const IsLoggedInKey = 'isLoggedIn';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    userAttribute: {
      email: ''
    } as UserAttribute,
    cognitoUser: {} as CognitoUser,
    _isLoggedIn: localStorage.getItem(IsLoggedInKey) ?? 'false'
  }),
  actions: {
    setCognitoUser(cognitoUser: CognitoUser, email: string) {
      this.cognitoUser = cognitoUser;
      this.userAttribute.email = email;
    },
    setUserAttribute(email: string) {
      this.userAttribute.email = email;
    },
    resetCognitoUser() {
      this.cognitoUser = {} as CognitoUser;
      this.userAttribute.email = '';
    },
    async logout() {
      try {
        await AuthApi.postLogout();
      } catch (error) {
        console.error(error);
      } finally {
        this._isLoggedIn = 'false';
        localStorage.setItem(IsLoggedInKey, 'false');
      }
    },
    login() {
      this._isLoggedIn = 'true';
      localStorage.setItem(IsLoggedInKey, 'true');
      useBasicTarget().getBasicTargets();
    }
  },
  getters: {
    isLoggedIn: state => {
      return state._isLoggedIn === 'true';
    }
  }
});
