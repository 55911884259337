<template>
  <span class="ui-icon" :class="[colorClass, iconClass]">
    <IconBase
      :icon-name="name"
      :width="sides"
      :height="sides"
      :viewBox="viewBox"
    >
      <Add v-if="name === 'add'" />
      <Anchor v-if="name === 'anchor'" />
      <ArrowCircleLeft v-if="name === 'arrow-circle-left'" />
      <ArrowCircleRight v-if="name === 'arrow-circle-right'" />
      <ArrowLeft v-if="name === 'arrow-left'" />
      <ArrowRight v-if="name === 'arrow-right'" />
      <ArrowBottom v-if="name === 'arrow-bottom'" />
      <ArrowUp v-if="name === 'arrow-up'" />
      <ArrowDown v-if="name === 'arrow-down'" />
      <ArrowRotate v-if="name === 'arrow-rotate'" />
      <ArrowStay v-if="name === 'arrow-stay'" />
      <Alert v-if="name === 'alert'" />
      <AngleUp v-if="name === 'angle-up'" />
      <AngleDown v-if="name === 'angle-down'" />
      <Ban v-if="name === 'ban'" />
      <Bell v-if="name === 'bell'" />
      <Building v-if="name === 'building'" />
      <Calendar v-if="name === 'calendar'" />
      <CaretDown v-if="name === 'caret-down'" />
      <ChartLine v-if="name === 'chart-line'" />
      <Check v-if="name === 'check'" />
      <CheckCircle v-if="name === 'check-circle'" />
      <CheckCircleContained v-if="name === 'check-circle-contained'" />
      <ChevronUp v-if="name === 'chevron-up'" />
      <ChevronDown v-if="name === 'chevron-down'" />
      <Close v-if="name === 'close'" />
      <CloseCircle v-if="name === 'close-circle'" />
      <Company v-if="name === 'company'" />
      <Confirmed v-if="name === 'confirmed'" />
      <Connect v-if="name === 'connect'" />
      <Db v-if="name === 'db'" />
      <Disconnect v-if="name === 'disconnect'" />
      <Download v-if="name === 'download'" />
      <Duplicate v-if="name === 'duplicate'" />
      <Early v-if="name === 'early'" />
      <Edit v-if="name === 'edit'" />
      <Ellipsis v-if="name === 'ellipsis'" />
      <VerticalEllipsis v-if="name === 'vertical-ellipsis'" />
      <Eye v-if="name === 'eye'" />
      <EyeSlash v-if="name === 'eye-slash'" />
      <Exclamation v-if="name === 'exclamation'" />
      <Folder v-if="name === 'folder'" />
      <FolderOpen v-if="name === 'folder-open'" />
      <Filter v-if="name === 'filter'" />
      <Grouping v-if="name === 'grouping'" />
      <Help v-if="name === 'help'" />
      <History v-if="name === 'history'" />
      <Home v-if="name === 'home'" />
      <InBox v-if="name === 'inbox'" />
      <Info v-if="name === 'info'" />
      <InfoCircle v-if="name === 'info-circle'" />
      <List v-if="name === 'list'" />
      <Lock v-if="name === 'lock'" />
      <LockSolid v-if="name === 'lock-solid'" />
      <Logout v-if="name === 'logout'" />
      <Menu v-if="name === 'menu'" />
      <Message v-if="name === 'message'" />
      <Minus v-if="name === 'minus'" />
      <Monitor v-if="name === 'monitor'" />
      <Mypage v-if="name === 'mypage'" />
      <Pencil v-if="name === 'pencil'" />
      <Pin v-if="name === 'pin'" />
      <Planning v-if="name === 'planning'" />
      <PrivilegePrimaryOwner v-if="name === 'privilege-primary'" />
      <PrivilegeOwner v-if="name === 'privilege-owner'" />
      <PrivilegeAdmin v-if="name === 'privilege-admin'" />
      <PrivilegeMember v-if="name === 'privilege-member'" />
      <PrivilegeRestricted v-if="name === 'privilege-restricted'" />
      <Plus v-if="name === 'plus'" />
      <Rectangle v-if="name === 'rectangle'" />
      <Return v-if="name === 'return'" />
      <Star v-if="name === 'star'" />
      <Search v-if="name === 'search'" />
      <Section v-if="name === 'section'" />
      <Setting v-if="name === 'setting'" />
      <SixDots v-if="name === 'six-dots'" />
      <BoxDrawingIchi v-if="name === 'box-drawing-ichi'" />
      <BoxDrawingKo v-if="name === 'box-drawing-ko'" />
      <BoxDrawingReverseL v-if="name === 'box-drawing-reverse-l'" />
      <BoxDrawingYo v-if="name === 'box-drawing-yo'" />
      <SyncAltRegular v-if="name === 'sync-alt-regular'" />
      <Tasks v-if="name === 'tasks'" />
      <Trash v-if="name === 'trash'" />
      <Tv v-if="name === 'tv'" />
      <TvAlt v-if="name === 'tv-alt'" />
      <Thunder v-if="name === 'thunder'" />
      <TvData v-if="name === 'tv-data'" />
      <Question v-if="name === 'question'" />
      <ChangeSelection v-if="name === 'change-selection'" />
      <User v-if="name === 'user'" />
      <UserCheck v-if="name === 'user-check'" />
      <Users v-if="name === 'users'" />
      <UserHeavy v-if="name === 'user-heavy'" />
      <UserMiddle v-if="name === 'user-middle'" />
      <UserLight v-if="name === 'user-light'" />
      <UserNone v-if="name === 'user-none'" />
      <Viewership v-if="name === 'viewership'" />
      <Peoples v-if="name === 'peoples'" />
      <Money v-if="name === 'money'" />
      <Reload v-if="name === 'reload'" />
      <Cmsi v-if="name === 'cmsi'" />
      <CustomTarget v-if="name === 'custom-target'" />
      <EffectiveReach v-if="name === 'effective-reach'" />
      <TvDataSearch v-if="name === 'tv-data-search'" />
      <RealTimeRate v-if="name === 'real-time-rate'" />
      <Reset v-if="name === 'reset'" />
      <PlusLight v-if="name === 'plusLight'" />
    </IconBase>
  </span>
</template>
<script>
import { defineComponent, computed } from 'vue';
import IconBase from '@/components/ui/icons/IconBase.vue';
import Add from '@/components/ui/icons/Add.vue';
import Anchor from '@/components/ui/icons/Anchor.vue';
import ArrowCircleLeft from '@/components/ui/icons/ArrowCircleLeft.vue';
import ArrowCircleRight from '@/components/ui/icons/ArrowCircleRight.vue';
import ArrowLeft from '@/components/ui/icons/ArrowLeft.vue';
import ArrowRight from '@/components/ui/icons/ArrowRight.vue';
import ArrowBottom from '@/components/ui/icons/ArrowBottom.vue';
import ArrowUp from '@/components/ui/icons/ArrowUp.vue';
import ArrowDown from '@/components/ui/icons/ArrowDown.vue';
import ArrowRotate from '@/components/ui/icons/ArrowRotate.vue';
import ArrowStay from '@/components/ui/icons/ArrowStay.vue';
import Alert from '@/components/ui/icons/Alert.vue';
import AngleUp from '@/components/ui/icons/AngleUp.vue';
import AngleDown from '@/components/ui/icons/AngleDown.vue';
import Ban from '@/components/ui/icons/Ban.vue';
import Bell from '@/components/ui/icons/Bell.vue';
import Building from '@/components/ui/icons/Building.vue';
import Calendar from '@/components/ui/icons/Calendar.vue';
import CaretDown from '@/components/ui/icons/CaretDown.vue';
import ChartLine from '@/components/ui/icons/ChartLine.vue';
import Check from '@/components/ui/icons/Check.vue';
import CheckCircle from '@/components/ui/icons/CheckCircle.vue';
import CheckCircleContained from '@/components/ui/icons/CheckCircleContained.vue';
import ChevronDown from '@/components/ui/icons/ChevronDown.vue';
import ChevronUp from '@/components/ui/icons/ChevronUp.vue';
import Close from '@/components/ui/icons/Close.vue';
import CloseCircle from '@/components/ui/icons/CloseCircle.vue';
import Company from '@/components/ui/icons/Company.vue';
import Confirmed from '@/components/ui/icons/Confirmed.vue';
import Connect from '@/components/ui/icons/Connect.vue';
import Db from '@/components/ui/icons/Db.vue';
import Disconnect from '@/components/ui/icons/Disconnect.vue';
import Download from '@/components/ui/icons/Download.vue';
import Duplicate from '@/components/ui/icons/Duplicate.vue';
import Early from '@/components/ui/icons/Early.vue';
import Edit from '@/components/ui/icons/Edit.vue';
import Ellipsis from '@/components/ui/icons/Ellipsis.vue';
import VerticalEllipsis from '@/components/ui/icons/VerticalEllipsis.vue';
import Eye from '@/components/ui/icons/Eye.vue';
import EyeSlash from '@/components/ui/icons/EyeSlash.vue';
import Exclamation from '@/components/ui/icons/Exclamation.vue';
import Folder from '@/components/ui/icons/Folder.vue';
import FolderOpen from '@/components/ui/icons/FolderOpen.vue';
import Filter from '@/components/ui/icons/Filter.vue';
import Grouping from '@/components/ui/icons/Grouping.vue';
import Help from '@/components/ui/icons/Help.vue';
import History from '@/components/ui/icons/History.vue';
import Home from '@/components/ui/icons/Home.vue';
import InBox from '@/components/ui/icons/InBox.vue';
import Info from '@/components/ui/icons/Info.vue';
import InfoCircle from '@/components/ui/icons/InfoCircle.vue';
import List from '@/components/ui/icons/List.vue';
import Lock from '@/components/ui/icons/Lock.vue';
import LockSolid from '@/components/ui/icons/LockSolid.vue';
import Logout from '@/components/ui/icons/Logout.vue';
import Message from '@/components/ui/icons/Message.vue';
import Minus from '@/components/ui/icons/Minus.vue';
import Monitor from '@/components/ui/icons/Monitor.vue';
import Mypage from '@/components/ui/icons/Mypage.vue';
import Pencil from '@/components/ui/icons/Pencil.vue';
import Pin from '@/components/ui/icons/Pin.vue';
import Plus from '@/components/ui/icons/Plus.vue';
import Planning from '@/components/ui/icons/Planning.vue';
import PrivilegeOwner from '@/components/ui/icons/PrivilegeOwner.vue';
import PrivilegeAdmin from '@/components/ui/icons/PrivilegeAdmin.vue';
import PrivilegeMember from '@/components/ui/icons/PrivilegeMember.vue';
import PrivilegeRestricted from '@/components/ui/icons/PrivilegeRestricted.vue';
import Question from '@/components/ui/icons/Question.vue';
import ChangeSelection from '@/components/ui/icons/ChangeSelection.vue';
import Rectangle from '@/components/ui/icons/Rectangle.vue';
import Return from '@/components/ui/icons/Return.vue';
import Search from '@/components/ui/icons/Search.vue';
import Section from '@/components/ui/icons/Section.vue';
import Setting from '@/components/ui/icons/Setting.vue';
import SixDots from '@/components/ui/icons/SixDots.vue';
import BoxDrawingIchi from '@/components/ui/icons/BoxDrawingIchi.vue';
import BoxDrawingKo from '@/components/ui/icons/BoxDrawingKo.vue';
import BoxDrawingReverseL from '@/components/ui/icons/BoxDrawingReverseL.vue';
import BoxDrawingYo from '@/components/ui/icons/BoxDrawingYo.vue';
import SyncAltRegular from '@/components/ui/icons/SyncAltRegular.vue';
import Star from '@/components/ui/icons/Star.vue';
import Tasks from '@/components/ui/icons/Tasks.vue';
import Thunder from '@/components/ui/icons/Thunder.vue';
import Trash from '@/components/ui/icons/Trash.vue';
import Tv from '@/components/ui/icons/Tv.vue';
import TvAlt from '@/components/ui/icons/TvAlt.vue';
import TvData from '@/components/ui/icons/TvData.vue';
import User from '@/components/ui/icons/User.vue';
import UserCheck from '@/components/ui/icons/UserCheck.vue';
import Users from '@/components/ui/icons/Users.vue';
import UserHeavy from '@/components/ui/icons/UserHeavy.vue';
import UserMiddle from '@/components/ui/icons/UserMiddle.vue';
import UserLight from '@/components/ui/icons/UserLight.vue';
import UserNone from '@/components/ui/icons/UserNone.vue';
import Viewership from '@/components/ui/icons/Viewership.vue';
import Peoples from '@/components/ui/icons/Peoples.vue';
import Money from '@/components/ui/icons/Money.vue';
import Reload from '@/components/ui/icons/Reload.vue';
import PrivilegePrimaryOwner from '@/components/ui/icons/PrivilegePrimaryOwner';
import Cmsi from '@/components/ui/icons/Cmsi.vue';
import EffectiveReach from '@/components/ui/icons/EffectiveReach.vue';
import CustomTarget from '@/components/ui/icons/CustomTarget.vue';
import RealTimeRate from '@/components/ui/icons/RealTimeRate.vue';
import Reset from '@/components/ui/icons/Reset.vue';
import TvDataSearch from '@/components/ui/icons/TvDataSearch.vue';
import PlusLight from '@/components/ui/icons/PlusLight.vue';
import Menu from '@/components/ui/icons/Menu.vue';

export default defineComponent({
  name: 'Icon',
  components: {
    Add,
    Anchor,
    ArrowCircleLeft,
    ArrowCircleRight,
    ArrowLeft,
    ArrowRight,
    ArrowBottom,
    ArrowDown,
    ArrowUp,
    ArrowRotate,
    ArrowStay,
    Alert,
    AngleUp,
    AngleDown,
    Ban,
    Bell,
    Building,
    Calendar,
    CaretDown,
    ChartLine,
    Check,
    CheckCircle,
    CheckCircleContained,
    ChevronUp,
    ChevronDown,
    Close,
    CloseCircle,
    Company,
    Confirmed,
    Connect,
    Db,
    Disconnect,
    Download,
    Duplicate,
    Early,
    Edit,
    Ellipsis,
    VerticalEllipsis,
    Eye,
    EyeSlash,
    Exclamation,
    Folder,
    FolderOpen,
    Filter,
    Grouping,
    Help,
    History,
    Home,
    InBox,
    Info,
    InfoCircle,
    IconBase,
    List,
    Lock,
    LockSolid,
    Logout,
    Message,
    Menu,
    Minus,
    Monitor,
    Mypage,
    Pencil,
    Pin,
    Planning,
    Plus,
    PrivilegePrimaryOwner,
    PrivilegeOwner,
    PrivilegeAdmin,
    PrivilegeMember,
    PrivilegeRestricted,
    Question,
    ChangeSelection,
    Rectangle,
    Return,
    Search,
    Section,
    Setting,
    SixDots,
    Star,
    BoxDrawingIchi,
    BoxDrawingKo,
    BoxDrawingYo,
    BoxDrawingReverseL,
    SyncAltRegular,
    Tasks,
    Thunder,
    Trash,
    Tv,
    TvAlt,
    TvData,
    User,
    UserCheck,
    Users,
    UserHeavy,
    UserMiddle,
    UserLight,
    UserNone,
    Viewership,
    Peoples,
    Money,
    Reload,
    Cmsi,
    CustomTarget,
    EffectiveReach,
    RealTimeRate,
    Reset,
    TvDataSearch,
    PlusLight
  },
  props: {
    name: String,
    color: String,
    sides: {
      type: String
    }
  },
  setup(props) {
    const resizableViewBox = ['caret-down', 'viewership', 'peoples', 'money'];
    return {
      colorClass: computed(() => props.color || ''),
      iconClass: computed(() => props.name || ''),
      viewBox: computed(() =>
        resizableViewBox.includes(props.name)
          ? `0 0 ${props.sides || 20} ${props.sides || 20}`
          : undefined
      )
    };
  }
});
</script>
<style lang="scss">
.ui-icon {
  color: var(--text-color);
  fill: var(--text-color);
  line-height: 0;

  &.primary {
    color: var(--primary-color);
  }

  &.tertiary {
    color: var(--tertiary-color);
  }

  &.white {
    color: #fff;
  }

  &.inherit {
    color: inherit;
    fill: inherit;
  }

  &.success {
    color: var(--succes-color);
  }

  &.error {
    color: var(--error-color);
  }

  &.disabled {
    color: var(--form-disabled-color);
  }
}
</style>
