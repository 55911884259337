<template>
  <template v-if="isLoggedIn">
    <Flex justify-end align-center class="footer-navigation px-8">
      <div class="mr-8">
        &copy; Switch Media, Inc.
      </div>
      <Flex align-center class="mr-8">
        <template v-for="(link, i) in links" :key="link">
          <span v-if="i !== 0" class="divider mx-2" />
          <TextLink>
            <a :href="link.to" target="_blank">
              {{ link.label }}
            </a>
          </TextLink>
        </template>
      </Flex>
      <button type="button" class="open-chatbot">
        <ChatSvg class="mr-2" />
        チャットで質問する
      </button>
    </Flex>
  </template>
</template>

<script>
import { defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { useUserInfoStore } from '@/store/userInfo';
import ChannelService from '@/plugins/chatbot.js';
import ChatSvg from '@/assets/images/chat.svg?inline';
import Flex from '@/components/layout/Flex.vue';
import TextLink from '@/components/ui/TextLink.vue';

export default defineComponent({
  components: {
    ChatSvg,
    Flex,
    TextLink
  },
  setup() {
    const authStore = useAuthStore();
    const userInfoStore = useUserInfoStore();
    const route = useRoute();
    const isLoggedIn = authStore.isLoggedIn;

    watch(
      () => userInfoStore.user.workspaces,
      () => {
        const user = userInfoStore.user;
        const strWorkspaces = user.workspaces
          .map(w => w.workspaceName)
          .join(',');
        // https://developers.channel.io/docs/web-installation
        const profile = {
          feature: route.name,
          workspaces: strWorkspaces,
          name: user.name,
          email: user.email,
          mobileNumber: user.phoneNo
        };
        // Boot Channel as a registered user
        ChannelService.shutdown();
        ChannelService.boot({
          pluginKey: process.env.VUE_APP_CHATBOT_KEY,
          memberId: user.id,
          profile,
          hideChannelButtonOnBoot: true,
          customLauncherSelector: '.open-chatbot'
        });
      }
    );
    const links = [
      {
        label: '運営会社',
        to: 'https://www.switch-m.com/corporate/company'
      },
      {
        label: 'プライバシーポリシー',
        to: 'https://www.switch-m.com/privacy'
      },
      {
        label: '利用規約',
        to: `https://docs.tval.jp/terms`
      }
    ];

    return {
      links,
      isLoggedIn
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/layout';
.footer-navigation {
  width: 100%;
  height: $footer-navigation-height;
  background-color: var(--dark-10-hex-color);
  white-space: nowrap;

  .divider {
    border-right: 1px solid var(--border-color);
    display: block;
    height: 8px;
  }

  .open-chatbot {
    display: flex;
    align-items: center;
    background: var(--contrast-color);
    border-radius: 8px 8px 0px 0px;
    padding: 10px 12.5px;
    font-weight: bold;
    color: var(--primary-color);

    &:hover {
      background-color: var(--primary-light-color);
    }

    svg {
      fill: var(--primary-color);
    }
  }
}
</style>
