<template>
  <g clip-path="url(#clip0_36_2278)">
    <path
      d="M0.967742 1.61292C0.431452 1.61292 0 2.04437 0 2.58066V4.51614C0 5.05243 0.431452 5.48388 0.967742 5.48388H2.90323C3.43952 5.48388 3.87097 5.05243 3.87097 4.51614V2.58066C3.87097 2.04437 3.43952 1.61292 2.90323 1.61292H0.967742ZM6.77419 2.58066C6.2379 2.58066 5.80645 3.01211 5.80645 3.5484C5.80645 4.08469 6.2379 4.51614 6.77419 4.51614H19.0323C19.5685 4.51614 20 4.08469 20 3.5484C20 3.01211 19.5685 2.58066 19.0323 2.58066H6.77419ZM6.77419 9.03227C6.2379 9.03227 5.80645 9.46372 5.80645 10C5.80645 10.5363 6.2379 10.9678 6.77419 10.9678H19.0323C19.5685 10.9678 20 10.5363 20 10C20 9.46372 19.5685 9.03227 19.0323 9.03227H6.77419ZM6.77419 15.4839C6.2379 15.4839 5.80645 15.9153 5.80645 16.4516C5.80645 16.9879 6.2379 17.4194 6.77419 17.4194H19.0323C19.5685 17.4194 20 16.9879 20 16.4516C20 15.9153 19.5685 15.4839 19.0323 15.4839H6.77419ZM0 9.03227V10.9678C0 11.504 0.431452 11.9355 0.967742 11.9355H2.90323C3.43952 11.9355 3.87097 11.504 3.87097 10.9678V9.03227C3.87097 8.49598 3.43952 8.06453 2.90323 8.06453H0.967742C0.431452 8.06453 0 8.49598 0 9.03227ZM0.967742 14.5161C0.431452 14.5161 0 14.9476 0 15.4839V17.4194C0 17.9557 0.431452 18.3871 0.967742 18.3871H2.90323C3.43952 18.3871 3.87097 17.9557 3.87097 17.4194V15.4839C3.87097 14.9476 3.43952 14.5161 2.90323 14.5161H0.967742Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_36_2278">
      <rect width="20" height="20" fill="none" />
    </clipPath>
  </defs>
</template>
