<template>
  <path
    d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
    fill="#6C9BEE"
  />
  <path
    d="M8.77539 10.7363H10.9668L9.88867 7.33789L8.77539 10.7363ZM8.88672 5.36328H10.9258L13.9844 14H12.0273L11.4707 12.2246H8.28906L7.69141 14H5.80469L8.88672 5.36328Z"
    fill="white"
  />mplate>
</template>
