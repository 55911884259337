<template>
  <label
    :for="id"
    class="base-file-input"
    :class="{ disabled: $attrs.disabled }"
  >
    <input
      ref="inputFileRef"
      type="file"
      accept=".png, .jpg, .jpeg"
      :id="id"
      @change="onChangeFileInput"
      :disabled="$attrs.disabled"
    />
    <slot />
  </label>
</template>
<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'BaseFileButton',
  emits: ['change-file'],
  props: {
    id: {
      type: String,
      default: 'upload-button'
    }
  },
  setup(_, { emit }) {
    const inputFileRef = ref();
    return {
      inputFileRef,
      onChangeFileInput: () => {
        const file = inputFileRef.value.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          emit('change-file', {
            file: reader.result,
            name: file.name,
            type: file.type
          });
        });

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/effect';
@import '@/styles/spacing';

.base-file-input input {
  display: none;
}

.base-file-input:not(.disabled) {
  @extend .hover;
}
</style>
