<template>
  <path
    d="M7.34 3.6001C7.34 3.6001 7.42 3.6301 7.42 3.6801V7.4401C7.42 7.4401 7.39 7.5201 7.34 7.5201H3.58C3.58 7.5201 3.5 7.4901 3.5 7.4401V3.6801C3.5 3.6801 3.53 3.6001 3.58 3.6001H7.34ZM7.34 2.1001H3.58C2.71 2.1001 2 2.8101 2 3.6801V7.4401C2 8.3101 2.71 9.0201 3.58 9.0201H7.34C8.21 9.0201 8.92 8.3101 8.92 7.4401V3.6801C8.92 2.8101 8.21 2.1001 7.34 2.1001Z"
    fill="#4674D7"
  />
  <path
    d="M17.4099 4.80994H12.5499L14.0799 3.27994C14.3699 2.98994 14.3699 2.50994 14.0799 2.21994C13.7899 1.92994 13.3099 1.92994 13.0199 2.21994L10.2099 5.02994C9.91993 5.31994 9.91993 5.79994 10.2099 6.08994L13.0199 8.89994C13.1699 9.04994 13.3599 9.11994 13.5499 9.11994C13.7399 9.11994 13.9299 9.04994 14.0799 8.89994C14.3699 8.60994 14.3699 8.12994 14.0799 7.83994L12.5499 6.30994H16.6599V10.2099C16.6599 10.6199 16.9999 10.9599 17.4099 10.9599C17.8199 10.9599 18.1599 10.6199 18.1599 10.2099V5.55994C18.1599 5.14994 17.8199 4.80994 17.4099 4.80994Z"
    fill="#4674D7"
  />
  <path
    d="M16.58 13.3799C16.58 13.3799 16.66 13.4099 16.66 13.4599V17.2199C16.66 17.2199 16.63 17.2999 16.58 17.2999H12.82C12.82 17.2999 12.74 17.2699 12.74 17.2199V13.4599C12.74 13.4599 12.77 13.3799 12.82 13.3799H16.58ZM16.58 11.8799H12.82C11.95 11.8799 11.24 12.5899 11.24 13.4599V17.2199C11.24 18.0899 11.95 18.7999 12.82 18.7999H16.58C17.45 18.7999 18.16 18.0899 18.16 17.2199V13.4599C18.16 12.5899 17.45 11.8799 16.58 11.8799Z"
    fill="#4674D7"
  />
  <path
    d="M9.95 14.8099L7.14 11.9999C6.85 11.7099 6.37 11.7099 6.08 11.9999C5.79 12.2899 5.79 12.7699 6.08 13.0599L7.61 14.5899H3.5V10.6899C3.5 10.2799 3.16 9.93994 2.75 9.93994C2.34 9.93994 2 10.2799 2 10.6899V15.3399C2 15.7499 2.34 16.0899 2.75 16.0899H7.61L6.08 17.6199C5.79 17.9099 5.79 18.3899 6.08 18.6799C6.23 18.8299 6.42 18.8999 6.61 18.8999C6.8 18.8999 6.99 18.8299 7.14 18.6799L9.95 15.8699C10.09 15.7299 10.17 15.5399 10.17 15.3399C10.17 15.1399 10.09 14.9499 9.95 14.8099Z"
    fill="#4674D7"
  />
</template>
