
import { defineComponent, computed, ref } from 'vue';
import IconButton from '@/components/ui/IconButton.vue';

export default defineComponent({
  name: 'BaseTextField',
  components: {
    IconButton
  },
  props: {
    modelValue: [String, Number],
    id: String,
    name: String,
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: 's'
    },
    bold: Boolean,
    error: Boolean,
    disabled: Boolean
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value)
    });
    const inputType = ref(props.type);

    return {
      inputValue,
      inputType,
      disabledClass: computed(() => (props.disabled ? 'disabled' : '')),
      errorClass: computed(() => (props.error ? 'error' : '')),
      sizeClass: computed(() => props.size || 's'),
      boldClass: computed(() => (props.bold ? 'bold' : '')),
      onClickTogglePassword: () =>
        (inputType.value =
          inputType.value === 'password' ? 'text' : 'password'),
      onBlurHandler: (event: { target: HTMLInputElement }) => {
        emit('blur', event);
      },
      onFocus: (event: { target: HTMLInputElement }) => {
        emit('focus', event);
      }
    };
  }
});
