/* tslint:disable */
/* eslint-disable */
/**
 * SiP\'s SaaS API
 * SaaSプロジェクトのAPI。
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 最近見たプロジェクト情報
 * @export
 * @interface AccessProject
 */
export interface AccessProject {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof AccessProject
     */
    id: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof AccessProject
     */
    name: string;
    /**
     * カンパニーID
     * @type {number}
     * @memberof AccessProject
     */
    companyId: number;
    /**
     * カンパニー名
     * @type {string}
     * @memberof AccessProject
     */
    companyName: string;
    /**
     * メインカードの商品／ブランドのリスト
     * @type {Array<string>}
     * @memberof AccessProject
     */
    productNames: Array<string>;
    /**
     * メインカードの企業名のリスト
     * @type {Array<string>}
     * @memberof AccessProject
     */
    cmSponsorNames: Array<string>;
    /**
     * メインカードのターゲット名のリスト
     * @type {Array<string>}
     * @memberof AccessProject
     */
    targetNames: Array<string>;
    /**
     * メインカードのエリア名
     * @type {string}
     * @memberof AccessProject
     */
    areaName: string;
    /**
     * 集計開始日
     * @type {string}
     * @memberof AccessProject
     */
    startDate: string;
    /**
     * 集計終了日
     * @type {string}
     * @memberof AccessProject
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof AccessProject
     */
    individualGrp?: number;
}
/**
 * 最近見たプロジェクトのリストモデル
 * @export
 * @interface AccessProjectList
 */
export interface AccessProjectList {
    /**
     * 
     * @type {number}
     * @memberof AccessProjectList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<AccessProject>}
     * @memberof AccessProjectList
     */
    list: Array<AccessProject>;
}
/**
 * アカウント用のモデル
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    nickname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accountDescription: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    accountPrivilege: AccountAccountPrivilegeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    isNew: boolean;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    lastActiveDateTime?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountAccountPrivilegeEnum {
    PrimaryOwner = 'PRIMARY_OWNER',
    Owner = 'OWNER',
    Manager = 'MANAGER',
    Member = 'MEMBER',
    Restricted = 'RESTRICTED',
    FreePrimaryOwner = 'FREE_PRIMARY_OWNER',
    FreeMember = 'FREE_MEMBER'
}

/**
 * カンパニーにアクセス権限に関する
 * @export
 * @interface AccountAuthority
 */
export interface AccountAuthority {
    /**
     * 
     * @type {number}
     * @memberof AccountAuthority
     */
    id: number;
    /**
     * カンパニーID
     * @type {number}
     * @memberof AccountAuthority
     */
    companyId: number;
    /**
     * アカウントID
     * @type {number}
     * @memberof AccountAuthority
     */
    accountId: number;
}
/**
 * カンパニーにアクセス権限を追加及び削除するForm
 * @export
 * @interface AccountAuthorityForm
 */
export interface AccountAuthorityForm {
    /**
     * アクセス権限追加したいアカウントIdList
     * @type {Array<number>}
     * @memberof AccountAuthorityForm
     */
    accountIds: Array<number>;
}
/**
 * アカウントのリストモデル
 * @export
 * @interface AccountList
 */
export interface AccountList {
    /**
     * 
     * @type {number}
     * @memberof AccountList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<Account>}
     * @memberof AccountList
     */
    list: Array<Account>;
}
/**
 * お知らせユーザー既読管理情報Model
 * @export
 * @interface AccountNoticeStatus
 */
export interface AccountNoticeStatus {
    /**
     * お知らせID
     * @type {number}
     * @memberof AccountNoticeStatus
     */
    noticeId: number;
    /**
     * SaaSアカウントID
     * @type {number}
     * @memberof AccountNoticeStatus
     */
    accountId: number;
    /**
     * 既読日時
     * @type {string}
     * @memberof AccountNoticeStatus
     */
    readAt: string;
    /**
     * 表示削除日時
     * @type {string}
     * @memberof AccountNoticeStatus
     */
    dismissedAt?: string;
}
/**
 * アカウントアクセス権用のモデル
 * @export
 * @interface AccountPrivilege
 */
export interface AccountPrivilege {
    /**
     * 
     * @type {string}
     * @memberof AccountPrivilege
     */
    accountPrivilege: AccountPrivilegeAccountPrivilegeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountPrivilegeAccountPrivilegeEnum {
    None = 'NONE',
    Restricted = 'RESTRICTED',
    Member = 'MEMBER',
    Manager = 'MANAGER',
    Owner = 'OWNER',
    PrimaryOwner = 'PRIMARY_OWNER'
}

/**
 * 
 * @export
 * @interface AccountStatus
 */
export interface AccountStatus {
    /**
     * 
     * @type {boolean}
     * @memberof AccountStatus
     */
    accountAlreadyExists: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountStatus
     */
    message: string;
}
/**
 * リーチ分析 - 追加出稿のヒートマップ
 * @export
 * @interface AdditionalPlacement
 */
export interface AdditionalPlacement {
    /**
     * 
     * @type {Array<AdditionalPlacementHeatMap>}
     * @memberof AdditionalPlacement
     */
    heatMaps: Array<AdditionalPlacementHeatMap>;
}
/**
 * リーチ分析 - 追加出稿のヒートマップ(局別)
 * @export
 * @interface AdditionalPlacementHeatMap
 */
export interface AdditionalPlacementHeatMap {
    /**
     * 放送局コード
     * @type {string}
     * @memberof AdditionalPlacementHeatMap
     */
    stationCode: string;
    /**
     * 放送局名
     * @type {string}
     * @memberof AdditionalPlacementHeatMap
     */
    stationName: string;
    /**
     * 放送局並び順
     * @type {number}
     * @memberof AdditionalPlacementHeatMap
     */
    stationOrder: number;
    /**
     * 追加就航ヒートマップ(曜日別)
     * @type {Array<AdditionalPlacementHeatMapByDayOfWeek>}
     * @memberof AdditionalPlacementHeatMap
     */
    heatMapByDayOfWeek: Array<AdditionalPlacementHeatMapByDayOfWeek>;
}
/**
 * リーチ分析 - 追加出稿のヒートマップ(曜日別)
 * @export
 * @interface AdditionalPlacementHeatMapByDayOfWeek
 */
export interface AdditionalPlacementHeatMapByDayOfWeek {
    /**
     * 
     * @type {number}
     * @memberof AdditionalPlacementHeatMapByDayOfWeek
     */
    dayOfWeek: number;
    /**
     * 
     * @type {Array<AdditionalPlacementHeatMapByDayOfWeekHeatMapCells>}
     * @memberof AdditionalPlacementHeatMapByDayOfWeek
     */
    heatMapCells: Array<AdditionalPlacementHeatMapByDayOfWeekHeatMapCells>;
}
/**
 * 
 * @export
 * @interface AdditionalPlacementHeatMapByDayOfWeekHeatMapCells
 */
export interface AdditionalPlacementHeatMapByDayOfWeekHeatMapCells {
    /**
     * 放送時刻
     * @type {number}
     * @memberof AdditionalPlacementHeatMapByDayOfWeekHeatMapCells
     */
    broadcastTimezone: number;
    /**
     * CM未接触者視聴率/含有率
     * @type {number}
     * @memberof AdditionalPlacementHeatMapByDayOfWeekHeatMapCells
     */
    ratio: number;
    /**
     * CM出稿本数
     * @type {number}
     * @memberof AdditionalPlacementHeatMapByDayOfWeekHeatMapCells
     */
    numberOfCmPlacement: number;
}
/**
 * 分析利用中企業のモデル
 * @export
 * @interface AnalysisUsedCompany
 */
export interface AnalysisUsedCompany {
    /**
     * 所属企業ID
     * @type {number}
     * @memberof AnalysisUsedCompany
     */
    affiliatedCompanyId: number;
    /**
     * 所属企業名
     * @type {string}
     * @memberof AnalysisUsedCompany
     */
    affiliatedCompanyName: string;
}
/**
 * エリアオブジェクト
 * @export
 * @interface AreaInfo
 */
export interface AreaInfo {
    /**
     * 
     * @type {string}
     * @memberof AreaInfo
     */
    id: AreaInfoIdEnum;
    /**
     * 
     * @type {string}
     * @memberof AreaInfo
     */
    name: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AreaInfoIdEnum {
    Kanto = 'SAAS_KANTO',
    Kansai = 'SAAS_KANSAI',
    Chukyo = 'SAAS_CHUKYO',
    Hokkaido = 'SAAS_HOKKAIDO',
    Tohoku = 'SAAS_TOHOKU',
    Hks = 'SAAS_HKS',
    Chushi = 'SAAS_CHUSHI',
    Kyuoki = 'SAAS_KYUOKI',
    Shizuoka = 'SAAS_SHIZUOKA',
    Miyagi = 'SAAS_MIYAGI',
    Hiroshima = 'SAAS_HIROSHIMA',
    Fukuoka = 'SAAS_FUKUOKA',
    AllPrefs = 'SAAS_ALL_PREFS'
}

/**
 * エリアモニタ構成のモデル
 * @export
 * @interface AreaMonitorStructure
 */
export interface AreaMonitorStructure {
    /**
     * 
     * @type {string}
     * @memberof AreaMonitorStructure
     */
    areaCode: string;
    /**
     * 
     * @type {string}
     * @memberof AreaMonitorStructure
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AreaMonitorStructure
     */
    prefectures: string;
    /**
     * 
     * @type {number}
     * @memberof AreaMonitorStructure
     */
    numberOfPersonalSamples: number;
    /**
     * 
     * @type {number}
     * @memberof AreaMonitorStructure
     */
    numberOfHouseholdSamples: number;
}
/**
 * エリアモニタ構成のリストモデル
 * @export
 * @interface AreaMonitorStructureList
 */
export interface AreaMonitorStructureList {
    /**
     * 
     * @type {number}
     * @memberof AreaMonitorStructureList
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof AreaMonitorStructureList
     */
    numberOfPersonalSamples: number;
    /**
     * 
     * @type {number}
     * @memberof AreaMonitorStructureList
     */
    numberOfHouseholdSamples: number;
    /**
     * 
     * @type {string}
     * @memberof AreaMonitorStructureList
     */
    calculationDate: string;
    /**
     * 
     * @type {Array<AreaMonitorStructure>}
     * @memberof AreaMonitorStructureList
     */
    list?: Array<AreaMonitorStructure>;
}
/**
 * 
 * @export
 * @interface AreaSampleNumber
 */
export interface AreaSampleNumber {
    /**
     * 
     * @type {string}
     * @memberof AreaSampleNumber
     */
    areaName: string;
    /**
     * 
     * @type {number}
     * @memberof AreaSampleNumber
     */
    number: number;
}
/**
 * リーチ分析-平均フリクエンシーの各種値
 * @export
 * @interface AverageFrequencies
 */
export interface AverageFrequencies {
    /**
     * 
     * @type {string}
     * @memberof AverageFrequencies
     */
    displayName: string;
    /**
     * 
     * @type {Array<AverageFrequenciesAverageFrequencies>}
     * @memberof AverageFrequencies
     */
    averageFrequencies: Array<AverageFrequenciesAverageFrequencies>;
}
/**
 * 
 * @export
 * @interface AverageFrequenciesAverageFrequencies
 */
export interface AverageFrequenciesAverageFrequencies {
    /**
     * FQ閾値
     * @type {number}
     * @memberof AverageFrequenciesAverageFrequencies
     */
    fqThreshold: number;
    /**
     * フリクエンシー割合
     * @type {number}
     * @memberof AverageFrequenciesAverageFrequencies
     */
    frequencyRate: number;
    /**
     * ターゲット視聴人数
     * @type {number}
     * @memberof AverageFrequenciesAverageFrequencies
     */
    targetViewNumber: number;
}
/**
 * リーチ分析-平均フリクエンシーのグラフデータ
 * @export
 * @interface AverageFrequency
 */
export interface AverageFrequency {
    /**
     * リーチ率
     * @type {number}
     * @memberof AverageFrequency
     */
    reachRate: number;
    /**
     * 
     * @type {Array<AverageFrequencies>}
     * @memberof AverageFrequency
     */
    frequencies: Array<AverageFrequencies>;
}
/**
 * 基本属性用のモデル
 * @export
 * @interface BasicDivision
 */
export interface BasicDivision {
    /**
     * 
     * @type {number}
     * @memberof BasicDivision
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BasicDivision
     */
    divisionName: string;
    /**
     * 
     * @type {string}
     * @memberof BasicDivision
     */
    displayDivisionName: string;
    /**
     * 
     * @type {Array<BasicTarget>}
     * @memberof BasicDivision
     */
    targets: Array<BasicTarget>;
}
/**
 * 基本属性区分のリストモデル
 * @export
 * @interface BasicDivisoinList
 */
export interface BasicDivisoinList {
    /**
     * 
     * @type {number}
     * @memberof BasicDivisoinList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<BasicDivision>}
     * @memberof BasicDivisoinList
     */
    list: Array<BasicDivision>;
}
/**
 * 基本属性ターゲット用のモデル
 * @export
 * @interface BasicTarget
 */
export interface BasicTarget {
    /**
     * 
     * @type {number}
     * @memberof BasicTarget
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BasicTarget
     */
    targetName: string;
    /**
     * 
     * @type {string}
     * @memberof BasicTarget
     */
    displayTargetName: string;
    /**
     * 
     * @type {string}
     * @memberof BasicTarget
     */
    displayTargetDescription: string;
    /**
     * 
     * @type {number}
     * @memberof BasicTarget
     */
    displayOrder: number;
}
/**
 * 基本属性ターゲットモニタ構成のモデル
 * @export
 * @interface BasicTargetMonitorStructure
 */
export interface BasicTargetMonitorStructure {
    /**
     * 
     * @type {number}
     * @memberof BasicTargetMonitorStructure
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BasicTargetMonitorStructure
     */
    targetName: string;
    /**
     * 
     * @type {string}
     * @memberof BasicTargetMonitorStructure
     */
    age: string;
    /**
     * 
     * @type {string}
     * @memberof BasicTargetMonitorStructure
     */
    gender: string;
    /**
     * 
     * @type {number}
     * @memberof BasicTargetMonitorStructure
     */
    numberOfSamples: number;
}
/**
 * 基本属性ターゲットモニタ構成のリストモデル
 * @export
 * @interface BasicTargetMonitorStructureList
 */
export interface BasicTargetMonitorStructureList {
    /**
     * 
     * @type {number}
     * @memberof BasicTargetMonitorStructureList
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof BasicTargetMonitorStructureList
     */
    numberOfPersonalSamples: number;
    /**
     * 
     * @type {number}
     * @memberof BasicTargetMonitorStructureList
     */
    numberOfHouseholdSamples: number;
    /**
     * 
     * @type {string}
     * @memberof BasicTargetMonitorStructureList
     */
    calculationDate: string;
    /**
     * 
     * @type {Array<BasicTargetMonitorStructure>}
     * @memberof BasicTargetMonitorStructureList
     */
    list?: Array<BasicTargetMonitorStructure>;
}
/**
 * アカウント権限更新用Form
 * @export
 * @interface ChangeAccountPrivilegeForm
 */
export interface ChangeAccountPrivilegeForm {
    /**
     * 
     * @type {string}
     * @memberof ChangeAccountPrivilegeForm
     */
    accountPrivilege: string;
}
/**
 * カンパニー設定
 * @export
 * @interface ChangeCompanySettingsForm
 */
export interface ChangeCompanySettingsForm {
    /**
     * 
     * @type {string}
     * @memberof ChangeCompanySettingsForm
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeCompanySettingsForm
     */
    aggregationUnit: ChangeCompanySettingsFormAggregationUnitEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeCompanySettingsForm
     */
    isConversion15sec: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChangeCompanySettingsForm
     */
    initialDisplayArea: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeCompanySettingsForm
     */
    viewingType: ChangeCompanySettingsFormViewingTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ChangeCompanySettingsFormAggregationUnitEnum {
    Personal = 'PERSONAL',
    Household = 'HOUSEHOLD'
}
/**
    * @export
    * @enum {string}
    */
export enum ChangeCompanySettingsFormViewingTypeEnum {
    Realtime = 'REALTIME',
    Total = 'TOTAL'
}

/**
 * 
 * @export
 * @interface ChangeFavoriteProduct
 */
export interface ChangeFavoriteProduct {
    /**
     * 
     * @type {number}
     * @memberof ChangeFavoriteProduct
     */
    productId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeFavoriteProduct
     */
    isFavorite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeFavoriteProduct
     */
    isCmProduct: boolean;
}
/**
 * お気に入りプロジェクト更新用フォーム
 * @export
 * @interface ChangeFavoriteProjectForm
 */
export interface ChangeFavoriteProjectForm {
    /**
     * 
     * @type {boolean}
     * @memberof ChangeFavoriteProjectForm
     */
    isFavorite: boolean;
}
/**
 * 商品更新用のForm
 * @export
 * @interface ChangeProductForm
 */
export interface ChangeProductForm {
    /**
     * 
     * @type {string}
     * @memberof ChangeProductForm
     */
    productName: string;
    /**
     * 
     * @type {ChangeProductFormTargets}
     * @memberof ChangeProductForm
     */
    targets?: ChangeProductFormTargets;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeProductForm
     */
    relatedCmProductIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeProductForm
     */
    isCompetitive: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeProductForm
     */
    relatedTvalProductIds?: Array<number>;
    /**
     * 
     * @type {Array<CompetitiveProductForm>}
     * @memberof ChangeProductForm
     */
    relatedCompetitiveCmProducts?: Array<CompetitiveProductForm>;
    /**
     * 
     * @type {Array<CompetitiveProductForm>}
     * @memberof ChangeProductForm
     */
    relatedCompetitiveTvalProducts?: Array<CompetitiveProductForm>;
}
/**
 * 
 * @export
 * @interface ChangeProductFormTargets
 */
export interface ChangeProductFormTargets {
    /**
     * 
     * @type {string}
     * @memberof ChangeProductFormTargets
     */
    targetType: ChangeProductFormTargetsTargetTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeProductFormTargets
     */
    targetIds: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum ChangeProductFormTargetsTargetTypeEnum {
    Individual = 'INDIVIDUAL',
    Basic = 'BASIC',
    Custom = 'CUSTOM'
}

/**
 * SaaSアカウント設定用Form
 * @export
 * @interface ChangeUserAccountForm
 */
export interface ChangeUserAccountForm {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserAccountForm
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserAccountForm
     */
    password?: string;
}
/**
 * マイページ：プロフィール設定用Form
 * @export
 * @interface ChangeUserProfileForm
 */
export interface ChangeUserProfileForm {
    /**
     * 
     * @type {string}
     * @memberof ChangeUserProfileForm
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserProfileForm
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserProfileForm
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeUserProfileForm
     */
    userDescription: string;
}
/**
 * 変更後の許可ドメイン
 * @export
 * @interface ChangeWorkspaceDomain
 */
export interface ChangeWorkspaceDomain {
    /**
     * 
     * @type {number}
     * @memberof ChangeWorkspaceDomain
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ChangeWorkspaceDomain
     */
    approveType?: ChangeWorkspaceDomainApproveTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeWorkspaceDomain
     */
    domainName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ChangeWorkspaceDomainApproveTypeEnum {
    Automatic = 'AUTOMATIC',
    Manual = 'MANUAL',
    Invalid = 'INVALID'
}

/**
 * 許可ドメイン変更用Form
 * @export
 * @interface ChangeWorkspaceDomainForm
 */
export interface ChangeWorkspaceDomainForm {
    /**
     * 
     * @type {string}
     * @memberof ChangeWorkspaceDomainForm
     */
    approveType?: ChangeWorkspaceDomainFormApproveTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeWorkspaceDomainForm
     */
    domainName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ChangeWorkspaceDomainFormApproveTypeEnum {
    Automatic = 'AUTOMATIC',
    Manual = 'MANUAL',
    Invalid = 'INVALID'
}

/**
 * 
 * @export
 * @interface ChangeWorkspaceForm
 */
export interface ChangeWorkspaceForm {
    /**
     * 
     * @type {string}
     * @memberof ChangeWorkspaceForm
     */
    workspaceName?: string;
}
/**
 * 商品カテゴリ用のモデル
 * @export
 * @interface CmCategory
 */
export interface CmCategory {
    /**
     * 
     * @type {number}
     * @memberof CmCategory
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CmCategory
     */
    ctgLevel: number;
    /**
     * 
     * @type {number}
     * @memberof CmCategory
     */
    level1CategoryId: number;
    /**
     * 
     * @type {number}
     * @memberof CmCategory
     */
    level2CategoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof CmCategory
     */
    level3CategoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof CmCategory
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CmCategory
     */
    displayOrder: number;
    /**
     * 
     * @type {Array<CmCategory>}
     * @memberof CmCategory
     */
    children?: Array<CmCategory>;
}
/**
 * CM商品用のモデル
 * @export
 * @interface CmProduct
 */
export interface CmProduct {
    /**
     * 
     * @type {number}
     * @memberof CmProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CmProduct
     */
    cmProductName: string;
    /**
     * 
     * @type {string}
     * @memberof CmProduct
     */
    cmSponsorName: string;
    /**
     * 
     * @type {string}
     * @memberof CmProduct
     */
    recentBroadcastDate: string;
}
/**
 * 商品一覧の中に含まれるCM商品の情報
 * @export
 * @interface CmProductInProducts
 */
export interface CmProductInProducts {
    /**
     * 
     * @type {number}
     * @memberof CmProductInProducts
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CmProductInProducts
     */
    cmProductName: string;
    /**
     * 
     * @type {string}
     * @memberof CmProductInProducts
     */
    recentBroadcastDate?: string;
}
/**
 * CM商品のリストモデル
 * @export
 * @interface CmProductList
 */
export interface CmProductList {
    /**
     * 
     * @type {number}
     * @memberof CmProductList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<CmProduct>}
     * @memberof CmProductList
     */
    list: Array<CmProduct>;
}
/**
 * CM R&F-検索条件テキストの返却モデル
 * @export
 * @interface CmReachAndFrequencyConditions
 */
export interface CmReachAndFrequencyConditions {
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    period: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    timeZone: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    areaName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CmReachAndFrequencyConditions
     */
    sampleSize: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    cmSponsorNames: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    cmProductNames: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    cmCreativeNames: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    cmType: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    cmDuration: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    stations: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    programs: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    viewingType: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    reportType: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    convertIn15Seconds: string;
    /**
     * 
     * @type {string}
     * @memberof CmReachAndFrequencyConditions
     */
    createdAt: string;
}
/**
 * CM R&F-表の列モデル
 * @export
 * @interface CmReachAndFrequencyTableColumn
 */
export interface CmReachAndFrequencyTableColumn {
    /**
     * ターゲット名
     * @type {string}
     * @memberof CmReachAndFrequencyTableColumn
     */
    targetName: string;
    /**
     * リーチ人数
     * @type {number}
     * @memberof CmReachAndFrequencyTableColumn
     */
    reachNumOfPeople?: number;
    /**
     * リーチ率
     * @type {number}
     * @memberof CmReachAndFrequencyTableColumn
     */
    reachRate: number;
    /**
     * GRP
     * @type {number}
     * @memberof CmReachAndFrequencyTableColumn
     */
    grp: number;
    /**
     * 平均フリクエンシー
     * @type {number}
     * @memberof CmReachAndFrequencyTableColumn
     */
    averageFrequency: number;
}
/**
 * CM R&F-表検索APIの返却モデル
 * @export
 * @interface CmReachAndFrequencyTableData
 */
export interface CmReachAndFrequencyTableData {
    /**
     * 
     * @type {CmReachAndFrequencyConditions}
     * @memberof CmReachAndFrequencyTableData
     */
    conditions: CmReachAndFrequencyConditions;
    /**
     * 
     * @type {Array<CmReachAndFrequencyTableColumn>}
     * @memberof CmReachAndFrequencyTableData
     */
    tableData: Array<CmReachAndFrequencyTableColumn>;
}
/**
 * CMセールスインパクト利用可能エリア用返却モデル
 * @export
 * @interface CmSalesImpactAvailableArea
 */
export interface CmSalesImpactAvailableArea {
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactAvailableArea
     */
    areaCode: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactAvailableArea
     */
    areaName: string;
}
/**
 * CMセールスインパクト相関係数の返却モデル
 * @export
 * @interface CmSalesImpactCorrelation
 */
export interface CmSalesImpactCorrelation {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactCorrelation
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactCorrelation
     */
    reachFrequency: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactCorrelation
     */
    correlationValue?: number;
}
/**
 * CMセールスインパクトグラフデータの返却モデル
 * @export
 * @interface CmSalesImpactGraphData
 */
export interface CmSalesImpactGraphData {
    /**
     * 
     * @type {PeriodTotalPurchaseIncreaseGraph}
     * @memberof CmSalesImpactGraphData
     */
    periodTotalPurchaseIncreaseGraph: PeriodTotalPurchaseIncreaseGraph;
    /**
     * 
     * @type {PeriodTotalPurchaseIncreasePerPersonGraph}
     * @memberof CmSalesImpactGraphData
     */
    periodTotalPurchaseIncreasePerPersonGraph: PeriodTotalPurchaseIncreasePerPersonGraph;
    /**
     * 
     * @type {CostEffectKpiPanel}
     * @memberof CmSalesImpactGraphData
     */
    costEffectKpiPanel: CostEffectKpiPanel;
    /**
     * 
     * @type {Array<WeeklyPurchaseIncreaseGraph>}
     * @memberof CmSalesImpactGraphData
     */
    weeklyPurchaseIncreaseGraph: Array<WeeklyPurchaseIncreaseGraph>;
    /**
     * 
     * @type {Array<WeeklyPurchaseIncreasePerPersonGraph>}
     * @memberof CmSalesImpactGraphData
     */
    weeklyPurchaseIncreasePerPersonGraph: Array<WeeklyPurchaseIncreasePerPersonGraph>;
    /**
     * 
     * @type {Array<PurchasePerPersonAndFrequencyGraph>}
     * @memberof CmSalesImpactGraphData
     */
    purchasePerPersonAndFrequencyGraph: Array<PurchasePerPersonAndFrequencyGraph>;
}
/**
 * CMセールスインパクト指標と前週比の返却モデル
 * @export
 * @interface CmSalesImpactIndicator
 */
export interface CmSalesImpactIndicator {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactIndicator
     */
    indicator: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactIndicator
     */
    previousWeekRatio?: number;
}
/**
 * CMセールスインパクト最終アクセス商品ページ情報の返却モデル
 * @export
 * @interface CmSalesImpactLastAccessProduct
 */
export interface CmSalesImpactLastAccessProduct {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactLastAccessProduct
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactLastAccessProduct
     */
    lastAccessedAt: string;
}
/**
 * CMセールスインパクトの自社商品情報返却モデル
 * @export
 * @interface CmSalesImpactOwnProduct
 */
export interface CmSalesImpactOwnProduct {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactOwnProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactOwnProduct
     */
    name: string;
}
/**
 * CMセールスインパクト商品一覧の返却モデル
 * @export
 * @interface CmSalesImpactProducts
 */
export interface CmSalesImpactProducts {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactProducts
     */
    productId: number;
    /**
     * 
     * @type {CmSalesImpactIndicator}
     * @memberof CmSalesImpactProducts
     */
    purchaseIncreaseAmount: CmSalesImpactIndicator;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactProducts
     */
    grp: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProducts
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProducts
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProducts
     */
    productType: CmSalesImpactProductsProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProducts
     */
    areaName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CmSalesImpactProducts
     */
    isAvailableWeeklyReport: boolean;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProducts
     */
    contractName: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CmSalesImpactProductsProductTypeEnum {
    OwnCompany = 'OWN_COMPANY',
    CompetitiveCompany = 'COMPETITIVE_COMPANY'
}

/**
 * CMセールスインパクト商品ページ詳細情報の返却モデル
 * @export
 * @interface CmSalesImpactProductsDetails
 */
export interface CmSalesImpactProductsDetails {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactProductsDetails
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProductsDetails
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactProductsDetails
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactProductsDetails
     */
    reachFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProductsDetails
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProductsDetails
     */
    areaCode: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactProductsDetails
     */
    sampleSize: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProductsDetails
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProductsDetails
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProductsDetails
     */
    endDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof CmSalesImpactProductsDetails
     */
    isAvailableWeeklyReport: boolean;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactProductsDetails
     */
    contractName: string;
}
/**
 * CMセールスインパクト商品レポート設定登録用のForm
 * @export
 * @interface CmSalesImpactReportSettingsForm
 */
export interface CmSalesImpactReportSettingsForm {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportSettingsForm
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportSettingsForm
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportSettingsForm
     */
    reachFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportSettingsForm
     */
    areaCode: string;
}
/**
 * CMセールスインパクトレポート一覧の返却モデル
 * @export
 * @interface CmSalesImpactReports
 */
export interface CmSalesImpactReports {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReports
     */
    reportId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReports
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReports
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReports
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReports
     */
    purchaseIncreaseAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReports
     */
    grp: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReports
     */
    lastUpdateDate: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReports
     */
    areaCode: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReports
     */
    advertisingCost: number;
}
/**
 * CMセールスインパクトレポート詳細情報の返却モデル
 * @export
 * @interface CmSalesImpactReportsDetails
 */
export interface CmSalesImpactReportsDetails {
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsDetails
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    companyName: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsDetails
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsDetails
     */
    reachFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    areaCode: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsDetails
     */
    sampleSize: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsDetails
     */
    advertisingCost: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    lastUpdateDate: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsDetails
     */
    lastUpdateAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    productType: CmSalesImpactReportsDetailsProductTypeEnum;
    /**
     * 
     * @type {Array<CmSalesImpactOwnProduct>}
     * @memberof CmSalesImpactReportsDetails
     */
    ownProductsRelatedToCompetitive: Array<CmSalesImpactOwnProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof CmSalesImpactReportsDetails
     */
    isAvailableWeeklyReport: boolean;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    contractName: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsDetails
     */
    cmsiDisplayProductName: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CmSalesImpactReportsDetailsProductTypeEnum {
    OwnCompany = 'OWN_COMPANY',
    CompetitiveCompany = 'COMPETITIVE_COMPANY'
}

/**
 * CMセールスインパクト商品レポート登録用のForm
 * @export
 * @interface CmSalesImpactReportsForm
 */
export interface CmSalesImpactReportsForm {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsForm
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsForm
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsForm
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsForm
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsForm
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsForm
     */
    reachFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsForm
     */
    areaCode: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsForm
     */
    advertisingCost: number;
}
/**
 * CMセールスインパクト商品レポート登録用の返却モデル
 * @export
 * @interface CmSalesImpactReportsPostResponse
 */
export interface CmSalesImpactReportsPostResponse {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsPostResponse
     */
    reportId: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsPostResponse
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsPostResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsPostResponse
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsPostResponse
     */
    endDate: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsPostResponse
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsPostResponse
     */
    reachFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactReportsPostResponse
     */
    areaCode: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactReportsPostResponse
     */
    advertisingCost: number;
}
/**
 * CMセールスインパクトシミュレーターシミュレーション条件の返却モデル
 * @export
 * @interface CmSalesImpactSimulatorCondition
 */
export interface CmSalesImpactSimulatorCondition {
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorCondition
     */
    productName: string;
    /**
     * 
     * @type {Array<Period>}
     * @memberof CmSalesImpactSimulatorCondition
     */
    periods: Array<Period>;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorCondition
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorCondition
     */
    reachFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorCondition
     */
    areaName: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorCondition
     */
    averagePerCost: number;
    /**
     * 
     * @type {Array<StationPlacementPattern>}
     * @memberof CmSalesImpactSimulatorCondition
     */
    stationPlacementPatterns: Array<StationPlacementPattern>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CmSalesImpactSimulatorCondition
     */
    campaignWeeks: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CmSalesImpactSimulatorCondition
     */
    grpTick: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorCondition
     */
    lastUpdateDateTime: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorCondition
     */
    cmsiProductGroupId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorCondition
     */
    cmsisProjectTitle: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorCondition
     */
    numOfDivision: number;
    /**
     * 
     * @type {Array<PerCost>}
     * @memberof CmSalesImpactSimulatorCondition
     */
    perCosts: Array<PerCost>;
}
/**
 * CMセールスインパクトシミュレータープロジェクト登録・更新後の返却モデル
 * @export
 * @interface CmSalesImpactSimulatorProject
 */
export interface CmSalesImpactSimulatorProject {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProject
     */
    cmsisProjectId: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProject
     */
    cmsiProductGroupId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProject
     */
    title: string;
    /**
     * 
     * @type {Array<Period>}
     * @memberof CmSalesImpactSimulatorProject
     */
    periods: Array<Period>;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProject
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProject
     */
    reachFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProject
     */
    areaCode: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProject
     */
    averagePerCost: number;
    /**
     * 
     * @type {Array<StationPlacementPattern>}
     * @memberof CmSalesImpactSimulatorProject
     */
    stationPlacementPatterns: Array<StationPlacementPattern>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CmSalesImpactSimulatorProject
     */
    campaignWeeks: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CmSalesImpactSimulatorProject
     */
    grpTick: Array<number>;
    /**
     * 
     * @type {Array<PerCost>}
     * @memberof CmSalesImpactSimulatorProject
     */
    perCosts: Array<PerCost>;
}
/**
 * CMセールスインパクトシミュレータープロジェクト登録・更新時の入力モデル
 * @export
 * @interface CmSalesImpactSimulatorProjectForm
 */
export interface CmSalesImpactSimulatorProjectForm {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    cmsiProductGroupId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    title: string;
    /**
     * 
     * @type {Array<Period>}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    periods: Array<Period>;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    reachWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    reachFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    areaCode: string;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    averagePerCost: number;
    /**
     * 
     * @type {Array<StationPlacementPattern>}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    stationPlacementPatterns: Array<StationPlacementPattern>;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    campaignWeek: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    grpCap: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    numOfDivision: number;
    /**
     * 
     * @type {Array<PerCost>}
     * @memberof CmSalesImpactSimulatorProjectForm
     */
    perCosts: Array<PerCost>;
}
/**
 * CMセールスインパクトシミュレータープロジェクト一覧の返却モデル
 * @export
 * @interface CmSalesImpactSimulatorProjectList
 */
export interface CmSalesImpactSimulatorProjectList {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    cmsisProjectId: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    areaName: string;
    /**
     * 
     * @type {Array<Period>}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    periods: Array<Period>;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    maxRoas?: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    cmSalesEffect?: number;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    maxRoasCondition?: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    lastUpdateDateTime: string;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    cmsisSimulationStatus: CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    productType: CmSalesImpactSimulatorProjectListProductTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    sponsorName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CmSalesImpactSimulatorProjectList
     */
    groupEnabled: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum {
    Pending = 'PENDING',
    InProgress = 'IN_PROGRESS',
    Success = 'SUCCESS',
    InvalidModelError = 'INVALID_MODEL_ERROR',
    Error = 'ERROR',
    NoAdAreaError = 'NO_AD_AREA_ERROR'
}
/**
    * @export
    * @enum {string}
    */
export enum CmSalesImpactSimulatorProjectListProductTypeEnum {
    OwnCompany = 'OWN_COMPANY',
    CompetitiveCompany = 'COMPETITIVE_COMPANY'
}

/**
 * CMセールスインパクトシミュレーターシミュレーション表データの返却モデル
 * @export
 * @interface CmSalesImpactSimulatorResult
 */
export interface CmSalesImpactSimulatorResult {
    /**
     * 
     * @type {string}
     * @memberof CmSalesImpactSimulatorResult
     */
    cmsisSimulationStatus: CmSalesImpactSimulatorResultCmsisSimulationStatusEnum;
    /**
     * 
     * @type {Array<CmSalesImpactSimulatorResultSimulationResult>}
     * @memberof CmSalesImpactSimulatorResult
     */
    simulationResult: Array<CmSalesImpactSimulatorResultSimulationResult>;
}

/**
    * @export
    * @enum {string}
    */
export enum CmSalesImpactSimulatorResultCmsisSimulationStatusEnum {
    Pending = 'PENDING',
    InProgress = 'IN_PROGRESS',
    Success = 'SUCCESS',
    InvalidModelError = 'INVALID_MODEL_ERROR',
    Error = 'ERROR',
    NoAdAreaError = 'NO_AD_AREA_ERROR'
}

/**
 * 
 * @export
 * @interface CmSalesImpactSimulatorResultSimulationResult
 */
export interface CmSalesImpactSimulatorResultSimulationResult {
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorResultSimulationResult
     */
    numberOfCampaignWeeks: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorResultSimulationResult
     */
    totalGrp: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorResultSimulationResult
     */
    totalCost: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorResultSimulationResult
     */
    cmSalesEffect: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorResultSimulationResult
     */
    roas?: number;
    /**
     * 
     * @type {number}
     * @memberof CmSalesImpactSimulatorResultSimulationResult
     */
    cmSalesEffectMinusTotalCost: number;
}
/**
 * CMセールスインパクト週次KPIの返却モデル
 * @export
 * @interface CmSalesImpactWeeklyKpiSummary
 */
export interface CmSalesImpactWeeklyKpiSummary {
    /**
     * 
     * @type {CmSalesImpactIndicator}
     * @memberof CmSalesImpactWeeklyKpiSummary
     */
    purchaseTotalAmount: CmSalesImpactIndicator;
    /**
     * 
     * @type {CmSalesImpactIndicator}
     * @memberof CmSalesImpactWeeklyKpiSummary
     */
    purchaseIncreaseAmount?: CmSalesImpactIndicator;
    /**
     * 
     * @type {CmSalesImpactIndicator}
     * @memberof CmSalesImpactWeeklyKpiSummary
     */
    grp: CmSalesImpactIndicator;
}
/**
 * スポンサー用のモデル
 * @export
 * @interface CmSponsor
 */
export interface CmSponsor {
    /**
     * 
     * @type {number}
     * @memberof CmSponsor
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CmSponsor
     */
    cmSponsorName: string;
    /**
     * 
     * @type {number}
     * @memberof CmSponsor
     */
    cmBroadcastCount: number;
}
/**
 * Cmスポンサーに紐づくCM商品用のモデル
 * @export
 * @interface CmSponsorCmProduct
 */
export interface CmSponsorCmProduct {
    /**
     * 
     * @type {number}
     * @memberof CmSponsorCmProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CmSponsorCmProduct
     */
    name: string;
}
/**
 * CM商品のリストモデル
 * @export
 * @interface CmSponsorCmProductList
 */
export interface CmSponsorCmProductList {
    /**
     * 
     * @type {number}
     * @memberof CmSponsorCmProductList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<CmSponsorCmProduct>}
     * @memberof CmSponsorCmProductList
     */
    list: Array<CmSponsorCmProduct>;
}
/**
 * 
 * @export
 * @interface CmSponsorInfo
 */
export interface CmSponsorInfo {
    /**
     * CMスポンサーID
     * @type {number}
     * @memberof CmSponsorInfo
     */
    cmSponsorId: number;
    /**
     * CMスポンサー名
     * @type {string}
     * @memberof CmSponsorInfo
     */
    cmSponsorName: string;
}
/**
 * CMスポンサーのリストモデル
 * @export
 * @interface CmSponsorList
 */
export interface CmSponsorList {
    /**
     * 
     * @type {number}
     * @memberof CmSponsorList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<CmSponsor>}
     * @memberof CmSponsorList
     */
    list: Array<CmSponsor>;
}
/**
 * カンパニーのモデル
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * カンパニーID
     * @type {number}
     * @memberof Company
     */
    id: number;
    /**
     * ワークスペースID
     * @type {number}
     * @memberof Company
     */
    workspaceId: number;
    /**
     * カンパニー名
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 集計単位 (HOUSEHOLD or PERSONAL)
     * @type {string}
     * @memberof Company
     */
    aggregationUnit: CompanyAggregationUnitEnum;
    /**
     * 15秒換算 (する or しない)
     * @type {boolean}
     * @memberof Company
     */
    isConversion15sec: boolean;
    /**
     * CMスポンサーID
     * @type {number}
     * @memberof Company
     */
    cmSponsorId: number;
    /**
     * カンパニー作成者アカウントID
     * @type {number}
     * @memberof Company
     */
    authorSaasAccountId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Company
     */
    accountIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    initialDisplayArea: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CompanyAggregationUnitEnum {
    Personal = 'PERSONAL',
    Household = 'HOUSEHOLD'
}

/**
 * カンパニー別CM企業用のモデル
 * @export
 * @interface CompanyCmSponsor
 */
export interface CompanyCmSponsor {
    /**
     * 
     * @type {number}
     * @memberof CompanyCmSponsor
     */
    cmSponsorId: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyCmSponsor
     */
    cmSponsorName: string;
    /**
     * 他社（競合含む自社以外）フラグ
     * @type {boolean}
     * @memberof CompanyCmSponsor
     */
    isOther: boolean;
    /**
     * 「名寄せ／商品名編集済みの商品を含んでいる」フラグ
     * @type {boolean}
     * @memberof CompanyCmSponsor
     */
    containsEditedProduct: boolean;
    /**
     * プロジェクト利用フラグ
     * @type {boolean}
     * @memberof CompanyCmSponsor
     */
    useProject: boolean;
}
/**
 * カンパニー内CM企業別商品／ブランド一覧用のモデル
 * @export
 * @interface CompanyCmSponsorProduct
 */
export interface CompanyCmSponsorProduct {
    /**
     * 商品／ブランドID（商品もしくはCM商品ID）
     * @type {number}
     * @memberof CompanyCmSponsorProduct
     */
    id: number;
    /**
     * 商品／ブランド名（商品もしくはCM商品名）
     * @type {string}
     * @memberof CompanyCmSponsorProduct
     */
    name: string;
    /**
     * プロジェクト利用フラグ
     * @type {boolean}
     * @memberof CompanyCmSponsorProduct
     */
    useProject: boolean;
    /**
     * 名寄せフラグ
     * @type {boolean}
     * @memberof CompanyCmSponsorProduct
     */
    isAggregationOfNames: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyCmSponsorProduct
     */
    grp: number;
    /**
     * 最新CM放送日
     * @type {string}
     * @memberof CompanyCmSponsorProduct
     */
    lastCmBroadcastDate: string;
    /**
     * 情報アイコン表示フラグ
     * @type {boolean}
     * @memberof CompanyCmSponsorProduct
     */
    displayInfoIcon: boolean;
    /**
     * CM商品フラグ
     * @type {boolean}
     * @memberof CompanyCmSponsorProduct
     */
    isCmProduct: boolean;
    /**
     * CM商品はCM企業ID、TVAL商品は所属企業IDを返却
     * @type {number}
     * @memberof CompanyCmSponsorProduct
     */
    cmSponsorId: number;
}
/**
 * カンパニーのリストモデル
 * @export
 * @interface CompanyList
 */
export interface CompanyList {
    /**
     * 
     * @type {number}
     * @memberof CompanyList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<Company>}
     * @memberof CompanyList
     */
    list: Array<Company>;
}
/**
 * カンパニー設定
 * @export
 * @interface CompanySettings
 */
export interface CompanySettings {
    /**
     * 
     * @type {number}
     * @memberof CompanySettings
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySettings
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySettings
     */
    aggregationUnit: CompanySettingsAggregationUnitEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CompanySettings
     */
    isConversion15sec: boolean;
    /**
     * 
     * @type {AreaInfo}
     * @memberof CompanySettings
     */
    initialDisplayAreaInfo: AreaInfo;
    /**
     * 
     * @type {string}
     * @memberof CompanySettings
     */
    viewingType: CompanySettingsViewingTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CompanySettingsAggregationUnitEnum {
    Household = 'HOUSEHOLD',
    Personal = 'PERSONAL'
}
/**
    * @export
    * @enum {string}
    */
export enum CompanySettingsViewingTypeEnum {
    Realtime = 'REALTIME',
    Total = 'TOTAL'
}

/**
 * カンパニー別他社CM企業登録用のモデル
 * @export
 * @interface CompanyToProductCmSponsor
 */
export interface CompanyToProductCmSponsor {
    /**
     * 
     * @type {number}
     * @memberof CompanyToProductCmSponsor
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyToProductCmSponsor
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyToProductCmSponsor
     */
    cmSponsorId: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyToProductCmSponsor
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface CompetitiveProduct
 */
export interface CompetitiveProduct {
    /**
     * 
     * @type {number}
     * @memberof CompetitiveProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitiveProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitiveProduct
     */
    competitiveCompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitiveProduct
     */
    recentBroadcastDate: string;
}
/**
 * 商品に紐づける競合商品のリクエスト情報
 * @export
 * @interface CompetitiveProductForm
 */
export interface CompetitiveProductForm {
    /**
     * 
     * @type {number}
     * @memberof CompetitiveProductForm
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CompetitiveProductForm
     */
    order: number;
}
/**
 * 
 * @export
 * @interface CompetitiveProductList
 */
export interface CompetitiveProductList {
    /**
     * 
     * @type {number}
     * @memberof CompetitiveProductList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<CompetitiveProducts>}
     * @memberof CompetitiveProductList
     */
    list: Array<CompetitiveProducts>;
}
/**
 * 
 * @export
 * @interface CompetitiveProducts
 */
export interface CompetitiveProducts {
    /**
     * 
     * @type {number}
     * @memberof CompetitiveProducts
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompetitiveProducts
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompetitiveProducts
     */
    competitiveCompanyName: string;
    /**
     * 
     * @type {CompetitiveProduct}
     * @memberof CompetitiveProducts
     */
    mainCmProduct: CompetitiveProduct;
    /**
     * 
     * @type {Array<CompetitiveProduct>}
     * @memberof CompetitiveProducts
     */
    relatedCmProducts: Array<CompetitiveProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof CompetitiveProducts
     */
    canCmSalesImpact: boolean;
}
/**
 * 条件カードのモデル（enqueteCondition と purchaseCondition は共存しない）
 * @export
 * @interface ConditionCard
 */
export interface ConditionCard {
    /**
     * 
     * @type {number}
     * @memberof ConditionCard
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ConditionCard
     */
    customTargetId: number;
    /**
     * 
     * @type {string}
     * @memberof ConditionCard
     */
    op?: ConditionCardOpEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionCard
     */
    conditionOp: ConditionCardConditionOpEnum;
    /**
     * 
     * @type {CustomTargetBasicCondition}
     * @memberof ConditionCard
     */
    basicCondition?: CustomTargetBasicCondition;
    /**
     * 
     * @type {CustomTargetEnqueteCondition}
     * @memberof ConditionCard
     */
    enqueteCondition?: CustomTargetEnqueteCondition;
    /**
     * 
     * @type {CustomTargetPurchaseCondition}
     * @memberof ConditionCard
     */
    purchaseCondition?: CustomTargetPurchaseCondition;
}

/**
    * @export
    * @enum {string}
    */
export enum ConditionCardOpEnum {
    And = 'AND',
    Or = 'OR'
}
/**
    * @export
    * @enum {string}
    */
export enum ConditionCardConditionOpEnum {
    And = 'AND',
    Or = 'OR'
}

/**
 * 条件カード用フォーム
 * @export
 * @interface ConditionCardForm
 */
export interface ConditionCardForm {
    /**
     * 
     * @type {string}
     * @memberof ConditionCardForm
     */
    conditionOp: ConditionCardFormConditionOpEnum;
    /**
     * 
     * @type {CustomTargetBasicCondition}
     * @memberof ConditionCardForm
     */
    basicCondition?: CustomTargetBasicCondition;
    /**
     * 
     * @type {CustomTargetEnqueteConditionForm}
     * @memberof ConditionCardForm
     */
    enqueteCondition?: CustomTargetEnqueteConditionForm;
    /**
     * 
     * @type {CustomTargetPurchaseConditionForm}
     * @memberof ConditionCardForm
     */
    purchaseCondition?: CustomTargetPurchaseConditionForm;
}

/**
    * @export
    * @enum {string}
    */
export enum ConditionCardFormConditionOpEnum {
    And = 'AND',
    Or = 'OR'
}

/**
 * 
 * @export
 * @interface ConditionFamilyGenderAge
 */
export interface ConditionFamilyGenderAge {
    /**
     * 
     * @type {Array<ConditionFamilyGenderAgeFilter>}
     * @memberof ConditionFamilyGenderAge
     */
    familyGenderAgeFilters: Array<ConditionFamilyGenderAgeFilter>;
}
/**
 * 
 * @export
 * @interface ConditionFamilyGenderAgeFilter
 */
export interface ConditionFamilyGenderAgeFilter {
    /**
     * 
     * @type {string}
     * @memberof ConditionFamilyGenderAgeFilter
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionFamilyGenderAgeFilter
     */
    op: ConditionFamilyGenderAgeFilterOpEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionFamilyGenderAgeFilter
     */
    value: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ConditionFamilyGenderAgeFilterOpEnum {
    In = 'IN',
    NotIn = 'NOT_IN',
    ArrayContains = 'ARRAY_CONTAINS',
    Equal = 'EQUAL',
    LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
    GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL'
}

/**
 * 
 * @export
 * @interface ConditionGenderAge
 */
export interface ConditionGenderAge {
    /**
     * 
     * @type {Array<ConditionGenderAgeFilter>}
     * @memberof ConditionGenderAge
     */
    genderAgeFilters: Array<ConditionGenderAgeFilter>;
}
/**
 * 
 * @export
 * @interface ConditionGenderAgeFilter
 */
export interface ConditionGenderAgeFilter {
    /**
     * 
     * @type {string}
     * @memberof ConditionGenderAgeFilter
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof ConditionGenderAgeFilter
     */
    op: ConditionGenderAgeFilterOpEnum;
    /**
     * 
     * @type {string}
     * @memberof ConditionGenderAgeFilter
     */
    value: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ConditionGenderAgeFilterOpEnum {
    In = 'IN',
    NotIn = 'NOT_IN',
    ArrayContains = 'ARRAY_CONTAINS',
    Equal = 'EQUAL',
    LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
    GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL'
}

/**
 * CMセールスインパクト費用効果KPIパネルの返却モデル
 * @export
 * @interface CostEffectKpiPanel
 */
export interface CostEffectKpiPanel {
    /**
     * 
     * @type {number}
     * @memberof CostEffectKpiPanel
     */
    cmEffect: number;
    /**
     * 
     * @type {number}
     * @memberof CostEffectKpiPanel
     */
    advertisingCost: number;
    /**
     * 
     * @type {number}
     * @memberof CostEffectKpiPanel
     */
    roas?: number;
    /**
     * 
     * @type {number}
     * @memberof CostEffectKpiPanel
     */
    personCmEffect: number;
    /**
     * 
     * @type {number}
     * @memberof CostEffectKpiPanel
     */
    averageWeeklyReachNumberOfPeople: number;
    /**
     * 
     * @type {number}
     * @memberof CostEffectKpiPanel
     */
    numberOfWeeks: number;
}
/**
 * アカウント登録用Form
 * @export
 * @interface CreateAccountForm
 */
export interface CreateAccountForm {
    /**
     * 
     * @type {string}
     * @memberof CreateAccountForm
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountForm
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountForm
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountForm
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAccountForm
     */
    activationCode: string;
}
/**
 * ワークスペースカンパニーの作成用Form
 * @export
 * @interface CreateCompanyForm
 */
export interface CreateCompanyForm {
    /**
     * カンパニー名
     * @type {string}
     * @memberof CreateCompanyForm
     */
    name: string;
    /**
     * CM企業ID
     * @type {number}
     * @memberof CreateCompanyForm
     */
    cmSponsorId: number;
}
/**
 * 商品登録用のForm
 * @export
 * @interface CreateProductForm
 */
export interface CreateProductForm {
    /**
     * 
     * @type {string}
     * @memberof CreateProductForm
     */
    productName: string;
    /**
     * 
     * @type {CreateProductFormTargets}
     * @memberof CreateProductForm
     */
    targets?: CreateProductFormTargets;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProductForm
     */
    relatedCmProductIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductForm
     */
    isCompetitive: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProductForm
     */
    affiliatedCompanyId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProductForm
     */
    relatedTvalProductIds?: Array<number>;
    /**
     * 
     * @type {Array<CompetitiveProductForm>}
     * @memberof CreateProductForm
     */
    relatedCompetitiveCmProducts?: Array<CompetitiveProductForm>;
    /**
     * 
     * @type {Array<CompetitiveProductForm>}
     * @memberof CreateProductForm
     */
    relatedCompetitiveTvalProducts?: Array<CompetitiveProductForm>;
}
/**
 * 
 * @export
 * @interface CreateProductFormTargets
 */
export interface CreateProductFormTargets {
    /**
     * 
     * @type {string}
     * @memberof CreateProductFormTargets
     */
    targetType: CreateProductFormTargetsTargetTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProductFormTargets
     */
    targetIds: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateProductFormTargetsTargetTypeEnum {
    Individual = 'INDIVIDUAL',
    Basic = 'BASIC',
    Custom = 'CUSTOM'
}

/**
 * 許可ドメイン変更申請生成用Form
 * @export
 * @interface CreateWorkspaceDomainChangeApplicationForm
 */
export interface CreateWorkspaceDomainChangeApplicationForm {
    /**
     * 変更申請を実施したアカウントのID
     * @type {number}
     * @memberof CreateWorkspaceDomainChangeApplicationForm
     */
    submitAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceDomainChangeApplicationForm
     */
    oldDomainName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceDomainChangeApplicationForm
     */
    newDomainName: string;
}
/**
 * 許可ドメイン生成用Form
 * @export
 * @interface CreateWorkspaceDomainForm
 */
export interface CreateWorkspaceDomainForm {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceDomainForm
     */
    domainName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWorkspaceDomainForm
     */
    approveType: CreateWorkspaceDomainFormApproveTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateWorkspaceDomainFormApproveTypeEnum {
    Manual = 'MANUAL',
    Automatic = 'AUTOMATIC',
    Invalid = 'INVALID'
}

/**
 * カスタムターゲットのモデル
 * @export
 * @interface CustomTarget
 */
export interface CustomTarget {
    /**
     * 
     * @type {number}
     * @memberof CustomTarget
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTarget
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomTarget
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTarget
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTarget
     */
    isOrderMade: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomTarget
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTarget
     */
    modifiedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomTarget
     */
    authorAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTarget
     */
    modifierAccountId?: number;
    /**
     * 
     * @type {Array<ConditionCard>}
     * @memberof CustomTarget
     */
    conditionCards: Array<ConditionCard>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTarget
     */
    isDataSourceUpdated?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTarget
     */
    inUse?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTarget
     */
    isEnabled?: boolean;
    /**
     * リーチシミュレーターで使用されているかどうか
     * @type {boolean}
     * @memberof CustomTarget
     */
    inUseWithReachSimulator?: boolean;
}
/**
 * 基本属性条件
 * @export
 * @interface CustomTargetBasicCondition
 */
export interface CustomTargetBasicCondition {
    /**
     * 
     * @type {Array<number>}
     * @memberof CustomTargetBasicCondition
     */
    basicTargetIds?: Array<number>;
    /**
     * 
     * @type {Array<ConditionGenderAge>}
     * @memberof CustomTargetBasicCondition
     */
    genderAges?: Array<ConditionGenderAge>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CustomTargetBasicCondition
     */
    occupation?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTargetBasicCondition
     */
    married?: boolean | null;
    /**
     * 
     * @type {Array<ConditionFamilyGenderAge>}
     * @memberof CustomTargetBasicCondition
     */
    familyGenderAges?: Array<ConditionFamilyGenderAge>;
}
/**
 * 
 * @export
 * @interface CustomTargetEnquete
 */
export interface CustomTargetEnquete {
    /**
     * 
     * @type {CustomTargetEnqueteTopicCode}
     * @memberof CustomTargetEnquete
     */
    topicCode: CustomTargetEnqueteTopicCode;
    /**
     * 
     * @type {Array<CustomTargetEnqueteOptionCode>}
     * @memberof CustomTargetEnquete
     */
    optionCodes: Array<CustomTargetEnqueteOptionCode>;
}
/**
 * 
 * @export
 * @interface CustomTargetEnqueteCondition
 */
export interface CustomTargetEnqueteCondition {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteCondition
     */
    enqueteKey: string;
    /**
     * 
     * @type {Array<CustomTargetEnquete>}
     * @memberof CustomTargetEnqueteCondition
     */
    enquetes: Array<CustomTargetEnquete>;
}
/**
 * アンケート条件
 * @export
 * @interface CustomTargetEnqueteConditionForm
 */
export interface CustomTargetEnqueteConditionForm {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteConditionForm
     */
    enqueteKey: string;
    /**
     * 
     * @type {Array<CustomTargetEnqueteTopicForm>}
     * @memberof CustomTargetEnqueteConditionForm
     */
    enquetes: Array<CustomTargetEnqueteTopicForm>;
}
/**
 * 
 * @export
 * @interface CustomTargetEnqueteOptionCode
 */
export interface CustomTargetEnqueteOptionCode {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteOptionCode
     */
    op: CustomTargetEnqueteOptionCodeOpEnum;
    /**
     * 
     * @type {Array<CustomTargetEnqueteOptionCodeValue>}
     * @memberof CustomTargetEnqueteOptionCode
     */
    optionCodeValues: Array<CustomTargetEnqueteOptionCodeValue>;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomTargetEnqueteOptionCodeOpEnum {
    And = 'AND',
    Or = 'OR',
    In = 'IN',
    NotIn = 'NOT_IN',
    ArrayContains = 'ARRAY_CONTAINS',
    Equal = 'EQUAL',
    LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
    GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL'
}

/**
 * 
 * @export
 * @interface CustomTargetEnqueteOptionCodeValue
 */
export interface CustomTargetEnqueteOptionCodeValue {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteOptionCodeValue
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteOptionCodeValue
     */
    optionText?: string;
}
/**
 * 
 * @export
 * @interface CustomTargetEnqueteTopicCode
 */
export interface CustomTargetEnqueteTopicCode {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteTopicCode
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteTopicCode
     */
    topicText?: string;
}
/**
 * 
 * @export
 * @interface CustomTargetEnqueteTopicForm
 */
export interface CustomTargetEnqueteTopicForm {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteTopicForm
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTargetEnqueteTopicForm
     */
    multiple?: boolean | null;
    /**
     * 
     * @type {Array<CustomTargetEnqueteTopicOptionForm>}
     * @memberof CustomTargetEnqueteTopicForm
     */
    options: Array<CustomTargetEnqueteTopicOptionForm>;
}
/**
 * 
 * @export
 * @interface CustomTargetEnqueteTopicOptionForm
 */
export interface CustomTargetEnqueteTopicOptionForm {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetEnqueteTopicOptionForm
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTargetEnqueteTopicOptionForm
     */
    checked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTargetEnqueteTopicOptionForm
     */
    not: boolean | null;
}
/**
 * カスタムターゲット用フォーム
 * @export
 * @interface CustomTargetForm
 */
export interface CustomTargetForm {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetForm
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTargetForm
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTargetForm
     */
    cardCondition: string;
    /**
     * 
     * @type {Array<ConditionCardForm>}
     * @memberof CustomTargetForm
     */
    conditionCards: Array<ConditionCardForm>;
}
/**
 * カスタムターゲットのリストモデル
 * @export
 * @interface CustomTargetList
 */
export interface CustomTargetList {
    /**
     * 
     * @type {number}
     * @memberof CustomTargetList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<CustomTarget>}
     * @memberof CustomTargetList
     */
    list: Array<CustomTarget>;
}
/**
 * カスタムターゲットモニタ構成のモデル
 * @export
 * @interface CustomTargetMonitorStructure
 */
export interface CustomTargetMonitorStructure {
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructure
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CustomTargetMonitorStructure
     */
    name: string;
    /**
     * 
     * @type {CustomTargetMonitorStructureNumberOfSamples}
     * @memberof CustomTargetMonitorStructure
     */
    numberOfSamples: CustomTargetMonitorStructureNumberOfSamples;
}
/**
 * カスタムターゲットモニタ構成のリストモデル
 * @export
 * @interface CustomTargetMonitorStructureList
 */
export interface CustomTargetMonitorStructureList {
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureList
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureList
     */
    numberOfPersonalSamples: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureList
     */
    numberOfHouseholdSamples: number;
    /**
     * 
     * @type {string}
     * @memberof CustomTargetMonitorStructureList
     */
    calculationDate: string;
    /**
     * 
     * @type {Array<CustomTargetMonitorStructure>}
     * @memberof CustomTargetMonitorStructureList
     */
    list: Array<CustomTargetMonitorStructure>;
}
/**
 * 
 * @export
 * @interface CustomTargetMonitorStructureNumberOfSamples
 */
export interface CustomTargetMonitorStructureNumberOfSamples {
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    kanto?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    kansai?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    chukyo?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    hokkaido?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    tohoku?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    hokukoushin?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    chushi?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    kyushu?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    miyagi?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    shizuoka?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    hiroshima?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    fukuoka?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomTargetMonitorStructureNumberOfSamples
     */
    zenkoku?: number;
}
/**
 * カスタムターゲットサンプル数取得用フォーム
 * @export
 * @interface CustomTargetNumberOfSamplesForm
 */
export interface CustomTargetNumberOfSamplesForm {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetNumberOfSamplesForm
     */
    cardCondition: string;
    /**
     * 
     * @type {Array<ConditionCardForm>}
     * @memberof CustomTargetNumberOfSamplesForm
     */
    conditionCards: Array<ConditionCardForm>;
}
/**
 * 購入ログ条件
 * @export
 * @interface CustomTargetPurchaseCondition
 */
export interface CustomTargetPurchaseCondition {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetPurchaseCondition
     */
    purchaseKey: string;
    /**
     * 
     * @type {Array<CustomTargetPurchaseSegment>}
     * @memberof CustomTargetPurchaseCondition
     */
    purchaseSegments: Array<CustomTargetPurchaseSegment>;
}
/**
 * 購入ログ条件
 * @export
 * @interface CustomTargetPurchaseConditionForm
 */
export interface CustomTargetPurchaseConditionForm {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetPurchaseConditionForm
     */
    purchaseKey: string;
    /**
     * 
     * @type {Array<CustomTargetPurchaseSegmentForm>}
     * @memberof CustomTargetPurchaseConditionForm
     */
    purchaseSegments: Array<CustomTargetPurchaseSegmentForm>;
}
/**
 * 
 * @export
 * @interface CustomTargetPurchaseSegment
 */
export interface CustomTargetPurchaseSegment {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetPurchaseSegment
     */
    minorClassSegmentCode: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTargetPurchaseSegment
     */
    minorClassSegmentName?: string;
    /**
     * 
     * @type {Array<CustomTargetPurchaseSegmentConsumerClass>}
     * @memberof CustomTargetPurchaseSegment
     */
    consumerClasses: Array<CustomTargetPurchaseSegmentConsumerClass>;
}
/**
 * 
 * @export
 * @interface CustomTargetPurchaseSegmentConsumerClass
 */
export interface CustomTargetPurchaseSegmentConsumerClass {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetPurchaseSegmentConsumerClass
     */
    consumerClass: CustomTargetPurchaseSegmentConsumerClassConsumerClassEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomTargetPurchaseSegmentConsumerClassConsumerClassEnum {
    HeavyUser = 'HEAVY_USER',
    MiddleUser = 'MIDDLE_USER',
    LightUser = 'LIGHT_USER',
    NonUser = 'NON_USER'
}

/**
 * 
 * @export
 * @interface CustomTargetPurchaseSegmentConsumerClassForm
 */
export interface CustomTargetPurchaseSegmentConsumerClassForm {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetPurchaseSegmentConsumerClassForm
     */
    consumerClass: CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CustomTargetPurchaseSegmentConsumerClassFormConsumerClassEnum {
    HeavyUser = 'HEAVY_USER',
    MiddleUser = 'MIDDLE_USER',
    LightUser = 'LIGHT_USER',
    NonUser = 'NON_USER'
}

/**
 * 
 * @export
 * @interface CustomTargetPurchaseSegmentForm
 */
export interface CustomTargetPurchaseSegmentForm {
    /**
     * 
     * @type {string}
     * @memberof CustomTargetPurchaseSegmentForm
     */
    minorClassSegmentCode: string;
    /**
     * 
     * @type {Array<CustomTargetPurchaseSegmentConsumerClassForm>}
     * @memberof CustomTargetPurchaseSegmentForm
     */
    consumerClasses: Array<CustomTargetPurchaseSegmentConsumerClassForm>;
}
/**
 * 
 * @export
 * @interface EffectiveReach
 */
export interface EffectiveReach {
    /**
     * 
     * @type {number}
     * @memberof EffectiveReach
     */
    targetReachNumberOfPeople: number;
    /**
     * 
     * @type {number}
     * @memberof EffectiveReach
     */
    targetEffectiveReachRate: number;
    /**
     * 
     * @type {Array<EffectiveReachs>}
     * @memberof EffectiveReach
     */
    effectiveReachs: Array<EffectiveReachs>;
}
/**
 * 有効リーチ各種値
 * @export
 * @interface EffectiveReachs
 */
export interface EffectiveReachs {
    /**
     * 
     * @type {string}
     * @memberof EffectiveReachs
     */
    displayName: string;
    /**
     * 
     * @type {Array<EffectiveReachsNumbersOfReachCurve>}
     * @memberof EffectiveReachs
     */
    numbersOfReachCurve: Array<EffectiveReachsNumbersOfReachCurve>;
}
/**
 * 
 * @export
 * @interface EffectiveReachsNumbersOfReachCurve
 */
export interface EffectiveReachsNumbersOfReachCurve {
    /**
     * 
     * @type {number}
     * @memberof EffectiveReachsNumbersOfReachCurve
     */
    grp: number;
    /**
     * 
     * @type {number}
     * @memberof EffectiveReachsNumbersOfReachCurve
     */
    numberOfPeople: number;
    /**
     * 
     * @type {number}
     * @memberof EffectiveReachsNumbersOfReachCurve
     */
    rate: number;
}
/**
 * アンケート検索結果のモデルリスト
 * @export
 * @interface EnquetesSearchResult
 */
export interface EnquetesSearchResult {
    /**
     * 
     * @type {QuestionCategoryList}
     * @memberof EnquetesSearchResult
     */
    enqueteSearchResult: QuestionCategoryList;
    /**
     * 
     * @type {PurchaseLogCategoryList}
     * @memberof EnquetesSearchResult
     */
    purchaseLogSearchResult: PurchaseLogCategoryList;
}
/**
 * エラーメッセージ用オブジェクト
 * @export
 * @interface ErrorMsg
 */
export interface ErrorMsg {
    /**
     * 
     * @type {string}
     * @memberof ErrorMsg
     */
    msg?: string;
}
/**
 * お気に入りプロジェクトのモデル
 * @export
 * @interface FavoriteProject
 */
export interface FavoriteProject {
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteProject
     */
    isFavorite: boolean;
}
/**
 * CM R&F-グラフのフリクエンシー分布
 * @export
 * @interface FrequencyDistribution
 */
export interface FrequencyDistribution {
    /**
     * 
     * @type {string}
     * @memberof FrequencyDistribution
     */
    displayName: string;
    /**
     * 
     * @type {Array<FrequencyRatio>}
     * @memberof FrequencyDistribution
     */
    frequencyRatio: Array<FrequencyRatio>;
}
/**
 * フリクエンシーにおける閾値と比率のデータセット
 * @export
 * @interface FrequencyRatio
 */
export interface FrequencyRatio {
    /**
     * FQ閾値
     * @type {number}
     * @memberof FrequencyRatio
     */
    fqThreshold: number;
    /**
     * フリクエンシー別割合
     * @type {number}
     * @memberof FrequencyRatio
     */
    frequencyRate: number;
    /**
     * フリクエンシー別人数
     * @type {number}
     * @memberof FrequencyRatio
     */
    frequencyNumOfPeople?: number;
}
/**
 * 通常使用されるレスポンス形式
 * @export
 * @interface GeneralResult
 */
export interface GeneralResult {
    /**
     * 
     * @type {boolean}
     * @memberof GeneralResult
     */
    success: boolean;
}
/**
 * Grp情報格納用オブジェクト
 * @export
 * @interface Grp
 */
export interface Grp {
    /**
     * 
     * @type {Array<Grps>}
     * @memberof Grp
     */
    grpByDay: Array<Grps>;
    /**
     * 
     * @type {Array<Grps>}
     * @memberof Grp
     */
    grpAccumulationPerDay: Array<Grps>;
}
/**
 * リーチ分析 - Grp(SOV用)
 * @export
 * @interface GrpForSov
 */
export interface GrpForSov {
    /**
     * 
     * @type {Array<GrpsForSov>}
     * @memberof GrpForSov
     */
    grpsForSov: Array<GrpsForSov>;
}
/**
 * 
 * @export
 * @interface Grps
 */
export interface Grps {
    /**
     * 
     * @type {string}
     * @memberof Grps
     */
    displayName: string;
    /**
     * 
     * @type {Array<GrpsNumbersOfGrp>}
     * @memberof Grps
     */
    numbersOfGrp: Array<GrpsNumbersOfGrp>;
}
/**
 * 
 * @export
 * @interface GrpsForSov
 */
export interface GrpsForSov {
    /**
     * 
     * @type {string}
     * @memberof GrpsForSov
     */
    stDate: string;
    /**
     * 
     * @type {string}
     * @memberof GrpsForSov
     */
    edDate: string;
    /**
     * 
     * @type {number}
     * @memberof GrpsForSov
     */
    totalGrp: number;
    /**
     * 
     * @type {Array<GrpsForSovProductShare>}
     * @memberof GrpsForSov
     */
    productShare: Array<GrpsForSovProductShare>;
}
/**
 * 
 * @export
 * @interface GrpsForSovProductShare
 */
export interface GrpsForSovProductShare {
    /**
     * 
     * @type {number}
     * @memberof GrpsForSovProductShare
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof GrpsForSovProductShare
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof GrpsForSovProductShare
     */
    grp: number;
    /**
     * 
     * @type {number}
     * @memberof GrpsForSovProductShare
     */
    share: number;
}
/**
 * 
 * @export
 * @interface GrpsNumbersOfGrp
 */
export interface GrpsNumbersOfGrp {
    /**
     * 
     * @type {string}
     * @memberof GrpsNumbersOfGrp
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof GrpsNumbersOfGrp
     */
    grp: number;
}
/**
 * 
 * @export
 * @interface HomeCompetitiveProduct
 */
export interface HomeCompetitiveProduct {
    /**
     * 
     * @type {number}
     * @memberof HomeCompetitiveProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HomeCompetitiveProduct
     */
    competitiveProductName: string;
    /**
     * 
     * @type {string}
     * @memberof HomeCompetitiveProduct
     */
    competitiveCompanyName: string;
    /**
     * 
     * @type {number}
     * @memberof HomeCompetitiveProduct
     */
    affiliatedCompanyId: number;
    /**
     * 
     * @type {number}
     * @memberof HomeCompetitiveProduct
     */
    order: number;
}
/**
 * 
 * @export
 * @interface HomeIndicator
 */
export interface HomeIndicator {
    /**
     * 
     * @type {number}
     * @memberof HomeIndicator
     */
    indicator: number;
    /**
     * 
     * @type {number}
     * @memberof HomeIndicator
     */
    previousIndicator: number;
}
/**
 * 
 * @export
 * @interface HomePlacementAmount
 */
export interface HomePlacementAmount {
    /**
     * 
     * @type {HomePlacementAmountGrpByDay}
     * @memberof HomePlacementAmount
     */
    grpByDay: HomePlacementAmountGrpByDay;
    /**
     * 
     * @type {HomePlacementAmountGrpByDay}
     * @memberof HomePlacementAmount
     */
    grpByWeek: HomePlacementAmountGrpByDay;
}
/**
 * 
 * @export
 * @interface HomePlacementAmountGrpByDay
 */
export interface HomePlacementAmountGrpByDay {
    /**
     * 
     * @type {Array<Grps>}
     * @memberof HomePlacementAmountGrpByDay
     */
    byPeriod: Array<Grps>;
    /**
     * 
     * @type {Array<Grps>}
     * @memberof HomePlacementAmountGrpByDay
     */
    accumulationPerPeriod: Array<Grps>;
}
/**
 * ホームに表示する商品またはCM商品の情報
 * @export
 * @interface HomeProduct
 */
export interface HomeProduct {
    /**
     * 
     * @type {number}
     * @memberof HomeProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HomeProduct
     */
    productName: string;
    /**
     * 
     * @type {number}
     * @memberof HomeProduct
     */
    grp: number;
    /**
     * 
     * @type {boolean}
     * @memberof HomeProduct
     */
    isCmProduct: boolean;
    /**
     * ピン留め商品かどうかのフラグ
     * @type {boolean}
     * @memberof HomeProduct
     */
    isFavorite: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HomeProduct
     */
    targetIsEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof HomeProduct
     */
    affiliatedCompanyId: number;
}
/**
 * ホームの商品詳細情報
 * @export
 * @interface HomeProductDetail
 */
export interface HomeProductDetail {
    /**
     * 
     * @type {number}
     * @memberof HomeProductDetail
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HomeProductDetail
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof HomeProductDetail
     */
    targetName: string;
    /**
     * 
     * @type {Array<HomeCompetitiveProduct>}
     * @memberof HomeProductDetail
     */
    competitiveProducts: Array<HomeCompetitiveProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof HomeProductDetail
     */
    targetIsEnabled: boolean;
}
/**
 * ホームに表示する商品のリスト
 * @export
 * @interface HomeProductList
 */
export interface HomeProductList {
    /**
     * 
     * @type {number}
     * @memberof HomeProductList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<HomeProduct>}
     * @memberof HomeProductList
     */
    list: Array<HomeProduct>;
}
/**
 * ホームのサマリ情報各種
 * @export
 * @interface HomeSummariess
 */
export interface HomeSummariess {
    /**
     * 
     * @type {HomeIndicator}
     * @memberof HomeSummariess
     */
    grp: HomeIndicator;
    /**
     * 
     * @type {HomeIndicator}
     * @memberof HomeSummariess
     */
    numberOfTotalViews: HomeIndicator;
    /**
     * 
     * @type {HomeIndicator}
     * @memberof HomeSummariess
     */
    numberOfTargetViews: HomeIndicator;
    /**
     * 
     * @type {HomeIndicator}
     * @memberof HomeSummariess
     */
    numberOfTargetReaches: HomeIndicator;
    /**
     * 
     * @type {HomeIndicator}
     * @memberof HomeSummariess
     */
    targetReachRate: HomeIndicator;
}
/**
 * 
 * @export
 * @interface HomeTargetReachNumber
 */
export interface HomeTargetReachNumber {
    /**
     * 
     * @type {HomeTargetReachNumberTargetReachNumberByDay}
     * @memberof HomeTargetReachNumber
     */
    targetReachNumberByDay: HomeTargetReachNumberTargetReachNumberByDay;
    /**
     * 
     * @type {HomeTargetReachNumberTargetReachNumberByDay}
     * @memberof HomeTargetReachNumber
     */
    targetReachNumberByWeek: HomeTargetReachNumberTargetReachNumberByDay;
}
/**
 * 
 * @export
 * @interface HomeTargetReachNumberTargetReachNumberByDay
 */
export interface HomeTargetReachNumberTargetReachNumberByDay {
    /**
     * 
     * @type {Array<TargetReachNumbers>}
     * @memberof HomeTargetReachNumberTargetReachNumberByDay
     */
    byPeriod: Array<TargetReachNumbers>;
    /**
     * 
     * @type {Array<TargetReachNumbers>}
     * @memberof HomeTargetReachNumberTargetReachNumberByDay
     */
    accumulationPerPeriod: Array<TargetReachNumbers>;
}
/**
 * アカウント参加しているワークスペース情報
 * @export
 * @interface JoinWorkspace
 */
export interface JoinWorkspace {
    /**
     * 
     * @type {number}
     * @memberof JoinWorkspace
     */
    workspaceId: number;
    /**
     * 
     * @type {string}
     * @memberof JoinWorkspace
     */
    workspaceStatus: JoinWorkspaceWorkspaceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JoinWorkspace
     */
    workspaceName: string;
    /**
     * 
     * @type {string}
     * @memberof JoinWorkspace
     */
    paymentType: JoinWorkspacePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JoinWorkspace
     */
    accountPrivilege: JoinWorkspaceAccountPrivilegeEnum;
    /**
     * 
     * @type {Array<WorkspaceFeatureOption>}
     * @memberof JoinWorkspace
     */
    featureOptions: Array<WorkspaceFeatureOption>;
}

/**
    * @export
    * @enum {string}
    */
export enum JoinWorkspaceWorkspaceStatusEnum {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    Deleted = 'DELETED',
    Disabled = 'DISABLED'
}
/**
    * @export
    * @enum {string}
    */
export enum JoinWorkspacePaymentTypeEnum {
    Invalid = 'INVALID',
    Free = 'FREE',
    Paid = 'PAID'
}
/**
    * @export
    * @enum {string}
    */
export enum JoinWorkspaceAccountPrivilegeEnum {
    PrimaryOwner = 'PRIMARY_OWNER',
    Owner = 'OWNER',
    Manager = 'MANAGER',
    Member = 'MEMBER',
    Restricted = 'RESTRICTED',
    FreePrimaryOwner = 'FREE_PRIMARY_OWNER',
    FreeMember = 'FREE_MEMBER'
}

/**
 * 参加可能カンパニース情報
 * @export
 * @interface JoinableCompany
 */
export interface JoinableCompany {
    /**
     * 
     * @type {number}
     * @memberof JoinableCompany
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof JoinableCompany
     */
    workspaceId: number;
    /**
     * 
     * @type {string}
     * @memberof JoinableCompany
     */
    workspaceName: string;
    /**
     * 
     * @type {string}
     * @memberof JoinableCompany
     */
    companyName: string;
}
/**
 * 参加済みカンパニーのリストモデル
 * @export
 * @interface JoinableCompanyList
 */
export interface JoinableCompanyList {
    /**
     * 
     * @type {number}
     * @memberof JoinableCompanyList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<JoinableCompany>}
     * @memberof JoinableCompanyList
     */
    list: Array<JoinableCompany>;
}
/**
 * 参加可能ワークスペースのモデル
 * @export
 * @interface JoinableWorkspace
 */
export interface JoinableWorkspace {
    /**
     * 
     * @type {number}
     * @memberof JoinableWorkspace
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof JoinableWorkspace
     */
    name: string;
}
/**
 * 参加可能な所有及び関連ワークスペースをまとめたモデル
 * @export
 * @interface JoinableWorkspaces
 */
export interface JoinableWorkspaces {
    /**
     * 
     * @type {string}
     * @memberof JoinableWorkspaces
     */
    domainName: string;
    /**
     * 
     * @type {JoinableWorkspace}
     * @memberof JoinableWorkspaces
     */
    contractWorkspace?: JoinableWorkspace;
    /**
     * 
     * @type {Array<JoinableWorkspace>}
     * @memberof JoinableWorkspaces
     */
    preferenceWorkspaces: Array<JoinableWorkspace>;
}
/**
 * 放送局リストの返却モデル
 * @export
 * @interface KeyStation
 */
export interface KeyStation {
    /**
     * 
     * @type {Array<string>}
     * @memberof KeyStation
     */
    stationCodes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KeyStation
     */
    displayStationName: string;
}
/**
 * 最後にアクセスしたカンパニーのモデル
 * @export
 * @interface LastActiveCompany
 */
export interface LastActiveCompany {
    /**
     * 
     * @type {number}
     * @memberof LastActiveCompany
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof LastActiveCompany
     */
    companyName: string;
    /**
     * 
     * @type {number}
     * @memberof LastActiveCompany
     */
    workspaceId: number;
}
/**
 * お知らせalert通知情報
 * @export
 * @interface NoticeAlert
 */
export interface NoticeAlert {
    /**
     * お知らせID
     * @type {number}
     * @memberof NoticeAlert
     */
    noticeId: number;
    /**
     * 本文
     * @type {string}
     * @memberof NoticeAlert
     */
    content: string;
    /**
     * 重要
     * @type {boolean}
     * @memberof NoticeAlert
     */
    important: boolean;
    /**
     * 通知レベル
     * @type {string}
     * @memberof NoticeAlert
     */
    noticeLevel: NoticeAlertNoticeLevelEnum;
    /**
     * 通知レベル
     * @type {string}
     * @memberof NoticeAlert
     */
    displayLocation: NoticeAlertDisplayLocationEnum;
    /**
     * 既読化無視 (false:無効 true:有効)
     * @type {boolean}
     * @memberof NoticeAlert
     */
    ignoreDismiss: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum NoticeAlertNoticeLevelEnum {
    Info = 'INFO',
    Notice = 'NOTICE',
    Alert = 'ALERT',
    Urgent = 'URGENT',
    Critical = 'CRITICAL'
}
/**
    * @export
    * @enum {string}
    */
export enum NoticeAlertDisplayLocationEnum {
    Headline = 'HEADLINE',
    BodyTop = 'BODY_TOP'
}

/**
 * お知らせalert通知情報一覧モデル
 * @export
 * @interface NoticeAlertList
 */
export interface NoticeAlertList {
    /**
     * 
     * @type {number}
     * @memberof NoticeAlertList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<NoticeAlert>}
     * @memberof NoticeAlertList
     */
    list: Array<NoticeAlert>;
}
/**
 * お知らせメッセージ一情報
 * @export
 * @interface NoticeMessage
 */
export interface NoticeMessage {
    /**
     * お知らせID
     * @type {number}
     * @memberof NoticeMessage
     */
    noticeId: number;
    /**
     * タイトル
     * @type {string}
     * @memberof NoticeMessage
     */
    title: string;
    /**
     * 本文
     * @type {string}
     * @memberof NoticeMessage
     */
    content: string;
    /**
     * 配信日
     * @type {string}
     * @memberof NoticeMessage
     */
    startAt: string;
    /**
     * 重要
     * @type {boolean}
     * @memberof NoticeMessage
     */
    important: boolean;
    /**
     * 既読判定 (false:未読 true:既読)
     * @type {boolean}
     * @memberof NoticeMessage
     */
    isRead: boolean;
}
/**
 * お知らせメッセージ一情報一覧モデル
 * @export
 * @interface NoticeMessageList
 */
export interface NoticeMessageList {
    /**
     * 
     * @type {number}
     * @memberof NoticeMessageList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<NoticeMessage>}
     * @memberof NoticeMessageList
     */
    list: Array<NoticeMessage>;
}
/**
 * サンプル数のモデル
 * @export
 * @interface NumberOfSamples
 */
export interface NumberOfSamples {
    /**
     * 
     * @type {NumberOfSamplesInitialAreaSamples}
     * @memberof NumberOfSamples
     */
    initialAreaSamples: NumberOfSamplesInitialAreaSamples;
    /**
     * 
     * @type {NumberOfSamplesInitialAreaSamples}
     * @memberof NumberOfSamples
     */
    contractAreaSamples: NumberOfSamplesInitialAreaSamples;
}
/**
 * 
 * @export
 * @interface NumberOfSamplesInitialAreaSamples
 */
export interface NumberOfSamplesInitialAreaSamples {
    /**
     * 
     * @type {Array<AreaSampleNumber>}
     * @memberof NumberOfSamplesInitialAreaSamples
     */
    list: Array<AreaSampleNumber>;
}
/**
 * 他社CM商品用のモデル
 * @export
 * @interface OtherCmProduct
 */
export interface OtherCmProduct {
    /**
     * 
     * @type {number}
     * @memberof OtherCmProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OtherCmProduct
     */
    cmProductName: string;
    /**
     * 
     * @type {string}
     * @memberof OtherCmProduct
     */
    cmSponsorName: string;
    /**
     * 
     * @type {number}
     * @memberof OtherCmProduct
     */
    numOfCmPlacement: number;
    /**
     * 
     * @type {string}
     * @memberof OtherCmProduct
     */
    recentBroadcastDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof OtherCmProduct
     */
    isCmProduct: boolean;
    /**
     * 
     * @type {number}
     * @memberof OtherCmProduct
     */
    grp: number;
    /**
     * 
     * @type {number}
     * @memberof OtherCmProduct
     */
    cmSponsorId: number;
}
/**
 * 他社CM商品のリストモデル
 * @export
 * @interface OtherCmProductList
 */
export interface OtherCmProductList {
    /**
     * 
     * @type {number}
     * @memberof OtherCmProductList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<OtherCmProduct>}
     * @memberof OtherCmProductList
     */
    list: Array<OtherCmProduct>;
}
/**
 * 参加ワークスペースのForm
 * @export
 * @interface ParticipateWorkspaceForm
 */
export interface ParticipateWorkspaceForm {
    /**
     * 
     * @type {number}
     * @memberof ParticipateWorkspaceForm
     */
    contractWorkspaceId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParticipateWorkspaceForm
     */
    preferenceWorkspaceIds: Array<number>;
}
/**
 * 過去比較設定情報
 * @export
 * @interface PastComparison
 */
export interface PastComparison {
    /**
     * ID
     * @type {number}
     * @memberof PastComparison
     */
    pastComparisonId?: number;
    /**
     * 開始日
     * @type {string}
     * @memberof PastComparison
     */
    startDate: string;
    /**
     * 終了日
     * @type {string}
     * @memberof PastComparison
     */
    endDate: string;
}
/**
 * CMSISパーコストの返却モデル
 * @export
 * @interface PerCost
 */
export interface PerCost {
    /**
     * 
     * @type {string}
     * @memberof PerCost
     */
    areaCode: string;
    /**
     * 
     * @type {number}
     * @memberof PerCost
     */
    perCost: number;
}
/**
 * 分単位の視聴率・視聴人数時系列データ
 * @export
 * @interface PerMinuteViewingRate
 */
export interface PerMinuteViewingRate {
    /**
     * 開始日時(YYYY-MM-DD HH-MM)
     * @type {string}
     * @memberof PerMinuteViewingRate
     */
    startDateTime: string;
    /**
     * 終了日時(YYYY-MM-DD HH-MM)
     * @type {string}
     * @memberof PerMinuteViewingRate
     */
    endDateTime: string;
    /**
     * エリアコード
     * @type {string}
     * @memberof PerMinuteViewingRate
     */
    areaCode: PerMinuteViewingRateAreaCodeEnum;
    /**
     * ターゲットタイプ
     * @type {string}
     * @memberof PerMinuteViewingRate
     */
    targetType: PerMinuteViewingRateTargetTypeEnum;
    /**
     * ターゲットID
     * @type {number}
     * @memberof PerMinuteViewingRate
     */
    targetId?: number;
    /**
     * 時系列の時刻データ
     * @type {Array<string>}
     * @memberof PerMinuteViewingRate
     */
    timestampList: Array<string>;
    /**
     * 時系列データ数
     * @type {number}
     * @memberof PerMinuteViewingRate
     */
    dataSize: number;
    /**
     * 放送局ごとの視聴率時系列データ
     * @type {Array<StationViewingRate>}
     * @memberof PerMinuteViewingRate
     */
    stations: Array<StationViewingRate>;
    /**
     * 番組表
     * @type {Array<TvProgramTable>}
     * @memberof PerMinuteViewingRate
     */
    tvProgramTable: Array<TvProgramTable>;
}

/**
    * @export
    * @enum {string}
    */
export enum PerMinuteViewingRateAreaCodeEnum {
    Kanto = 'SAAS_KANTO',
    Kansai = 'SAAS_KANSAI',
    Chukyo = 'SAAS_CHUKYO'
}
/**
    * @export
    * @enum {string}
    */
export enum PerMinuteViewingRateTargetTypeEnum {
    Individual = 'INDIVIDUAL',
    Household = 'HOUSEHOLD',
    Core = 'CORE'
}

/**
 * 期間の返却モデル
 * @export
 * @interface Period
 */
export interface Period {
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    endDate: string;
}
/**
 * CMセールスインパクト期間累計購入増加額グラフの返却モデル
 * @export
 * @interface PeriodTotalPurchaseIncreaseGraph
 */
export interface PeriodTotalPurchaseIncreaseGraph {
    /**
     * 
     * @type {number}
     * @memberof PeriodTotalPurchaseIncreaseGraph
     */
    totalPurchaseAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodTotalPurchaseIncreaseGraph
     */
    cmEffect: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodTotalPurchaseIncreaseGraph
     */
    unreachedPurchaseAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodTotalPurchaseIncreaseGraph
     */
    averageReachRate: number;
}
/**
 * CMセールスインパクト一人あたり期間累計購入増加額グラフの返却モデル
 * @export
 * @interface PeriodTotalPurchaseIncreasePerPersonGraph
 */
export interface PeriodTotalPurchaseIncreasePerPersonGraph {
    /**
     * 
     * @type {number}
     * @memberof PeriodTotalPurchaseIncreasePerPersonGraph
     */
    cmReachPurchaseAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodTotalPurchaseIncreasePerPersonGraph
     */
    cmEffect: number;
    /**
     * 
     * @type {number}
     * @memberof PeriodTotalPurchaseIncreasePerPersonGraph
     */
    unreachedPurchaseAmount: number;
}
/**
 * 
 * @export
 * @interface PostAccountResponse
 */
export interface PostAccountResponse {
    /**
     * 
     * @type {CreateAccountForm}
     * @memberof PostAccountResponse
     */
    createdAccountData: CreateAccountForm;
    /**
     * 
     * @type {AccountStatus}
     * @memberof PostAccountResponse
     */
    accountStatus: AccountStatus;
}
/**
 * 商品のモデル
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    cmProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    isCompetitive?: boolean;
}
/**
 * プロダクト比較設定情報
 * @export
 * @interface ProductComparison
 */
export interface ProductComparison {
    /**
     * productComparisonId
     * @type {number}
     * @memberof ProductComparison
     */
    productComparisonId?: number;
    /**
     * プロダクトID
     * @type {number}
     * @memberof ProductComparison
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof ProductComparison
     */
    productName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductComparison
     */
    isCmProduct?: boolean;
}
/**
 * 商品／ブランド詳細用のモデル
 * @export
 * @interface ProductDetail
 */
export interface ProductDetail {
    /**
     * 商品／ブランド名ID
     * @type {number}
     * @memberof ProductDetail
     */
    id: number;
    /**
     * 商品／ブランド名
     * @type {string}
     * @memberof ProductDetail
     */
    name: string;
    /**
     * 紐づく自社CM商品情報
     * @type {Array<ProductDetailRelatedCmProducts>}
     * @memberof ProductDetail
     */
    relatedCmProducts: Array<ProductDetailRelatedCmProducts>;
    /**
     * 紐づく競合TVAL商品情報
     * @type {Array<ProductDetailRelatedCompetitiveTvalProducts>}
     * @memberof ProductDetail
     */
    relatedCompetitiveTvalProducts: Array<ProductDetailRelatedCompetitiveTvalProducts>;
    /**
     * 利用中のプロジェクト情報
     * @type {Array<ProductDetailUsedProjects>}
     * @memberof ProductDetail
     */
    usedProjects?: Array<ProductDetailUsedProjects>;
    /**
     * 
     * @type {ProductDetailTargets}
     * @memberof ProductDetail
     */
    targets?: ProductDetailTargets;
    /**
     * 自社商品フラグ
     * @type {boolean}
     * @memberof ProductDetail
     */
    isOwnProduct: boolean;
}
/**
 * 
 * @export
 * @interface ProductDetailRelatedCmProducts
 */
export interface ProductDetailRelatedCmProducts {
    /**
     * CM商品ID
     * @type {number}
     * @memberof ProductDetailRelatedCmProducts
     */
    cmProductId: number;
    /**
     * CM商品名
     * @type {string}
     * @memberof ProductDetailRelatedCmProducts
     */
    cmProductName: string;
    /**
     * CM企業ID
     * @type {number}
     * @memberof ProductDetailRelatedCmProducts
     */
    cmSponsorId: number;
    /**
     * CM企業名
     * @type {string}
     * @memberof ProductDetailRelatedCmProducts
     */
    cmSponsorName: string;
    /**
     * 直近3ヶ月のGRP
     * @type {number}
     * @memberof ProductDetailRelatedCmProducts
     */
    grpForLast3Months: number;
    /**
     * 直近CM放送日
     * @type {string}
     * @memberof ProductDetailRelatedCmProducts
     */
    lastCmBroadcastDate?: string;
}
/**
 * 
 * @export
 * @interface ProductDetailRelatedCompetitiveTvalProducts
 */
export interface ProductDetailRelatedCompetitiveTvalProducts {
    /**
     * 商品ID
     * @type {number}
     * @memberof ProductDetailRelatedCompetitiveTvalProducts
     */
    productId: number;
    /**
     * 商品名
     * @type {string}
     * @memberof ProductDetailRelatedCompetitiveTvalProducts
     */
    productName: string;
    /**
     * CM企業ID
     * @type {number}
     * @memberof ProductDetailRelatedCompetitiveTvalProducts
     */
    cmSponsorId: number;
    /**
     * CM企業名
     * @type {string}
     * @memberof ProductDetailRelatedCompetitiveTvalProducts
     */
    cmSponsorName: string;
    /**
     * 直近3ヶ月のGRP
     * @type {number}
     * @memberof ProductDetailRelatedCompetitiveTvalProducts
     */
    grpForLast3Months: number;
    /**
     * 直近CM放送日
     * @type {string}
     * @memberof ProductDetailRelatedCompetitiveTvalProducts
     */
    lastCmBroadcastDate?: string;
    /**
     * 所属企業ID
     * @type {number}
     * @memberof ProductDetailRelatedCompetitiveTvalProducts
     */
    affiliatedCompanyId: number;
}
/**
 * ターゲット情報
 * @export
 * @interface ProductDetailTargets
 */
export interface ProductDetailTargets {
    /**
     * ターゲット種別
     * @type {string}
     * @memberof ProductDetailTargets
     */
    targetType: ProductDetailTargetsTargetTypeEnum;
    /**
     * ターゲット名
     * @type {string}
     * @memberof ProductDetailTargets
     */
    targetName?: string;
    /**
     * ターゲットIDリスト
     * @type {Array<number>}
     * @memberof ProductDetailTargets
     */
    targetIds: Array<number>;
    /**
     * 利用可能フラグ
     * @type {boolean}
     * @memberof ProductDetailTargets
     */
    isEnabled: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductDetailTargetsTargetTypeEnum {
    Basic = 'BASIC',
    Custom = 'CUSTOM',
    Individual = 'INDIVIDUAL'
}

/**
 * 
 * @export
 * @interface ProductDetailUsedProjects
 */
export interface ProductDetailUsedProjects {
    /**
     * 機能名
     * @type {string}
     * @memberof ProductDetailUsedProjects
     */
    featureName: ProductDetailUsedProjectsFeatureNameEnum;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ProductDetailUsedProjects
     */
    projectName: string;
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ProductDetailUsedProjects
     */
    projectId: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductDetailUsedProjectsFeatureNameEnum {
    ReachAnalysis = 'REACH_ANALYSIS',
    ReachAnalysis2 = 'REACH_ANALYSIS_2',
    Tro = 'TRO',
    CmSalesImpact = 'CM_SALES_IMPACT',
    CmSalesImpactSimulator = 'CM_SALES_IMPACT_SIMULATOR'
}

/**
 * 
 * @export
 * @interface ProductIdInfo
 */
export interface ProductIdInfo {
    /**
     * CM商品IDまたは商品ID
     * @type {number}
     * @memberof ProductIdInfo
     */
    id: number;
    /**
     * CM商品フラグ
     * @type {boolean}
     * @memberof ProductIdInfo
     */
    isCmProduct: boolean;
}
/**
 * 商品のリスト
 * @export
 * @interface Products
 */
export interface Products {
    /**
     * 
     * @type {number}
     * @memberof Products
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Products
     */
    name: string;
    /**
     * 
     * @type {CmProductInProducts}
     * @memberof Products
     */
    mainCmProduct: CmProductInProducts;
    /**
     * 
     * @type {ProductsTargets}
     * @memberof Products
     */
    targets?: ProductsTargets;
    /**
     * 
     * @type {Array<CmProductInProducts>}
     * @memberof Products
     */
    relatedCmProducts: Array<CmProductInProducts>;
    /**
     * youtubeURL
     * @type {string}
     * @memberof Products
     */
    cmCreativeUrl?: string | null;
    /**
     * Youtubeのサムネイル画像
     * @type {string}
     * @memberof Products
     */
    thumbnail?: string | null;
    /**
     * 競合商品
     * @type {Array<ProductsCompetitiveProducts>}
     * @memberof Products
     */
    competitiveProducts: Array<ProductsCompetitiveProducts>;
    /**
     * 
     * @type {boolean}
     * @memberof Products
     */
    isCmProduct: boolean;
    /**
     * リーチ分析プロジェクトでのCM素材設定状況
     * @type {boolean}
     * @memberof Products
     */
    isSetCmCreative: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Products
     */
    canCmSalesImpact: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Products
     */
    isSetTroProject: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Products
     */
    isReachAnalysisProjectProduct: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Products
     */
    isReachAnalysisProject2Product: boolean;
}
/**
 * 
 * @export
 * @interface ProductsCompetitiveProducts
 */
export interface ProductsCompetitiveProducts {
    /**
     * 
     * @type {number}
     * @memberof ProductsCompetitiveProducts
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductsCompetitiveProducts
     */
    competitiveProductName: string;
    /**
     * 
     * @type {number}
     * @memberof ProductsCompetitiveProducts
     */
    order: number;
}
/**
 * 商品のリストモデル
 * @export
 * @interface ProductsList
 */
export interface ProductsList {
    /**
     * 
     * @type {number}
     * @memberof ProductsList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<Products>}
     * @memberof ProductsList
     */
    list: Array<Products>;
}
/**
 * 
 * @export
 * @interface ProductsTargets
 */
export interface ProductsTargets {
    /**
     * 
     * @type {string}
     * @memberof ProductsTargets
     */
    targetType: ProductsTargetsTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProductsTargets
     */
    targetName?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductsTargets
     */
    targetIds: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsTargets
     */
    isEnabled: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductsTargetsTargetTypeEnum {
    Basic = 'BASIC',
    Custom = 'CUSTOM',
    Individual = 'INDIVIDUAL'
}

/**
 * 番組評価ランキング
 * @export
 * @interface ProgramEvaluations
 */
export interface ProgramEvaluations {
    /**
     * 
     * @type {Array<ProgramEvaluationsProgramRanking>}
     * @memberof ProgramEvaluations
     */
    programRanking: Array<ProgramEvaluationsProgramRanking>;
}
/**
 * 
 * @export
 * @interface ProgramEvaluationsProgramRanking
 */
export interface ProgramEvaluationsProgramRanking {
    /**
     * 順位
     * @type {number}
     * @memberof ProgramEvaluationsProgramRanking
     */
    ranking: number;
    /**
     * CM未接触者 視聴率/含有率
     * @type {number}
     * @memberof ProgramEvaluationsProgramRanking
     */
    ratio: number;
    /**
     * 番組名
     * @type {string}
     * @memberof ProgramEvaluationsProgramRanking
     */
    programName: string;
    /**
     * 放送局名
     * @type {string}
     * @memberof ProgramEvaluationsProgramRanking
     */
    stationName: string;
    /**
     * 曜日
     * @type {number}
     * @memberof ProgramEvaluationsProgramRanking
     */
    dayOfWeek: number;
    /**
     * 標準放送開始時間
     * @type {string}
     * @memberof ProgramEvaluationsProgramRanking
     */
    standardBroadcastStartTime: string;
    /**
     * 標準放送終了時間
     * @type {string}
     * @memberof ProgramEvaluationsProgramRanking
     */
    standardBroadcastEndTime: string;
    /**
     * 出稿CM種別
     * @type {string}
     * @memberof ProgramEvaluationsProgramRanking
     */
    cmPlacementType?: string;
}
/**
 * TVデータサーチ用番組リストにおける基本情報の返却モデル
 * @export
 * @interface ProgramListBaseData
 */
export interface ProgramListBaseData {
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    dayOfWeek: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProgramListBaseData
     */
    isHoliday: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    areaPrefectureNames: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    stationCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    stationName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    stationNetwork: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    programName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    programGenreName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramListBaseData
     */
    programType: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramListBaseData
     */
    programBroadcastMinutes: number;
}
/**
 * TVデータサーチ用番組期間平均における基本情報の返却モデル
 * @export
 * @interface ProgramPeriodAverageBaseData
 */
export interface ProgramPeriodAverageBaseData {
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    dayOfWeeks: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramPeriodAverageBaseData
     */
    numberOfHoliday: number;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    areaPrefectureNames: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    stationCode: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    stationName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    stationNetwork: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    programName: string;
    /**
     * 
     * @type {string}
     * @memberof ProgramPeriodAverageBaseData
     */
    programGenreName: string;
    /**
     * 
     * @type {number}
     * @memberof ProgramPeriodAverageBaseData
     */
    numberOfBroadcasts: number;
    /**
     * 
     * @type {Array<{ [key: string]: number; }>}
     * @memberof ProgramPeriodAverageBaseData
     */
    numberOfBroadcastsByProgramType: Array<{ [key: string]: number; }>;
    /**
     * 
     * @type {number}
     * @memberof ProgramPeriodAverageBaseData
     */
    programBroadcastMinutes: number;
}
/**
 * プロジェクト情報
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof Project
     */
    projectId: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof Project
     */
    projectName: string;
    /**
     * プロダクトID
     * @type {number}
     * @memberof Project
     */
    productId: number;
    /**
     * プロダクト名
     * @type {string}
     * @memberof Project
     */
    productName: string;
    /**
     * プロジェクトタイプ
     * @type {string}
     * @memberof Project
     */
    projectType: ProjectProjectTypeEnum;
    /**
     * リーチ分析-プロジェクトID
     * @type {number}
     * @memberof Project
     */
    reachAnalysisProjectId: number;
    /**
     * 作成者ID
     * @type {number}
     * @memberof Project
     */
    authorSaasAccountId: number;
    /**
     * 
     * @type {WorkspaceAccountProfile}
     * @memberof Project
     */
    authorSaasAccountProfile?: WorkspaceAccountProfile;
    /**
     * 作成日
     * @type {string}
     * @memberof Project
     */
    createdAt: string;
    /**
     * サムネイル
     * @type {string}
     * @memberof Project
     */
    thumbnail?: string;
    /**
     * お気に入り
     * @type {boolean}
     * @memberof Project
     */
    favorite: boolean;
    /**
     * プロジェクトに設定されている対象エリア名
     * @type {string}
     * @memberof Project
     */
    areaName: string;
    /**
     * プロジェクトに使用できない条件が設定されているかどうか
     * @type {boolean}
     * @memberof Project
     */
    projectConditionIsEnabled: boolean;
    /**
     * CM種別
     * @type {string}
     * @memberof Project
     */
    cmBuyingKind: ProjectCmBuyingKindEnum;
    /**
     * CM素材情報のリスト
     * @type {Array<TvDataSearchCmCreative>}
     * @memberof Project
     */
    cmCreatives: Array<TvDataSearchCmCreative>;
}

/**
    * @export
    * @enum {string}
    */
export enum ProjectProjectTypeEnum {
    ReachAnalysis = 'REACH_ANALYSIS'
}
/**
    * @export
    * @enum {string}
    */
export enum ProjectCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}

/**
 * プロジェクト基本情報
 * @export
 * @interface ProjectBasicInfo
 */
export interface ProjectBasicInfo {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ProjectBasicInfo
     */
    reachAnalysisProjectId?: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ProjectBasicInfo
     */
    projectName: string;
    /**
     * キャンペーン期間(Start)
     * @type {string}
     * @memberof ProjectBasicInfo
     */
    campaignStartDate: string;
    /**
     * キャンペーン期間(End)
     * @type {string}
     * @memberof ProjectBasicInfo
     */
    campaignEndDate: string;
    /**
     * カンパニーID
     * @type {number}
     * @memberof ProjectBasicInfo
     */
    companyId?: number;
    /**
     * プロダクトID
     * @type {number}
     * @memberof ProjectBasicInfo
     */
    productId: number;
    /**
     * プロダクト名
     * @type {string}
     * @memberof ProjectBasicInfo
     */
    productName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectBasicInfo
     */
    isCmProduct?: boolean;
    /**
     * サムネイル
     * @type {string}
     * @memberof ProjectBasicInfo
     */
    thumbnail?: string;
    /**
     * 目標GRP
     * @type {number}
     * @memberof ProjectBasicInfo
     */
    targetGrp: number;
    /**
     * 作成者ID
     * @type {number}
     * @memberof ProjectBasicInfo
     */
    authorSaasAccountId?: number;
    /**
     * 作成者日
     * @type {string}
     * @memberof ProjectBasicInfo
     */
    createdAt?: string;
    /**
     * CM種別
     * @type {string}
     * @memberof ProjectBasicInfo
     */
    cmBuyingKind: ProjectBasicInfoCmBuyingKindEnum;
    /**
     * CM素材IDのリスト
     * @type {Array<number>}
     * @memberof ProjectBasicInfo
     */
    cmCreativeIds?: Array<number>;
    /**
     * CM素材情報のリスト
     * @type {Array<TvDataSearchCmCreative>}
     * @memberof ProjectBasicInfo
     */
    cmCreatives?: Array<TvDataSearchCmCreative>;
}

/**
    * @export
    * @enum {string}
    */
export enum ProjectBasicInfoCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}

/**
 * プロジェクトのリストモデル
 * @export
 * @interface ProjectList
 */
export interface ProjectList {
    /**
     * 
     * @type {number}
     * @memberof ProjectList
     */
    totalCount?: number;
    /**
     * 
     * @type {Array<Project>}
     * @memberof ProjectList
     */
    list: Array<Project>;
}
/**
 * 購買ログカテゴリリスト
 * @export
 * @interface PurchaseLogCategoryList
 */
export interface PurchaseLogCategoryList {
    /**
     * 
     * @type {number}
     * @memberof PurchaseLogCategoryList
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseLogCategoryList
     */
    purchaseId?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchaseLogCategoryList
     */
    purchaseKey?: string;
    /**
     * 
     * @type {Array<PurchaseLogLargeCategory>}
     * @memberof PurchaseLogCategoryList
     */
    list: Array<PurchaseLogLargeCategory>;
}
/**
 * 購買ログ（大分類）カテゴリリスト
 * @export
 * @interface PurchaseLogLargeCategory
 */
export interface PurchaseLogLargeCategory {
    /**
     * 
     * @type {string}
     * @memberof PurchaseLogLargeCategory
     */
    segmentCode: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseLogLargeCategory
     */
    segmentName: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseLogLargeCategory
     */
    displayOrder: number;
    /**
     * 
     * @type {Array<PurchaseLogMiddleCategory>}
     * @memberof PurchaseLogLargeCategory
     */
    middleClasses: Array<PurchaseLogMiddleCategory>;
}
/**
 * 購買ログ（中分類）カテゴリリスト
 * @export
 * @interface PurchaseLogMiddleCategory
 */
export interface PurchaseLogMiddleCategory {
    /**
     * 
     * @type {string}
     * @memberof PurchaseLogMiddleCategory
     */
    segmentCode: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseLogMiddleCategory
     */
    segmentName: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseLogMiddleCategory
     */
    displayOrder: number;
    /**
     * 
     * @type {Array<PurchaseLogMinorCategory>}
     * @memberof PurchaseLogMiddleCategory
     */
    minorClasses: Array<PurchaseLogMinorCategory>;
}
/**
 * 購買ログ（小分類）カテゴリリスト
 * @export
 * @interface PurchaseLogMinorCategory
 */
export interface PurchaseLogMinorCategory {
    /**
     * 
     * @type {string}
     * @memberof PurchaseLogMinorCategory
     */
    segmentCode: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseLogMinorCategory
     */
    segmentName: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseLogMinorCategory
     */
    displayOrder: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseLogMinorCategory
     */
    purchaseAmountHigher: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseLogMinorCategory
     */
    purchaseAmountMedium: number;
}
/**
 * CMセールスインパクト一人当たり購入額とフリクエンシーの関係グラフの返却モデル
 * @export
 * @interface PurchasePerPersonAndFrequencyGraph
 */
export interface PurchasePerPersonAndFrequencyGraph {
    /**
     * 
     * @type {number}
     * @memberof PurchasePerPersonAndFrequencyGraph
     */
    frequency: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasePerPersonAndFrequencyGraph
     */
    purchaseAmountPerPerson: number;
}
/**
 * 質問のモデル
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    questionId: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    questionCode: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    questionLabel: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    questionText: string;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    questionOrder: number;
    /**
     * 
     * @type {Array<QuestionTopic>}
     * @memberof Question
     */
    topics: Array<QuestionTopic>;
}
/**
 * アンケートカテゴリ
 * @export
 * @interface QuestionCategory
 */
export interface QuestionCategory {
    /**
     * 
     * @type {number}
     * @memberof QuestionCategory
     */
    questionCategoryId: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategory
     */
    enqueteId: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategory
     */
    categoryKey: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategory
     */
    categoryOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategory
     */
    categoryLabel: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategory
     */
    categoryText: string;
    /**
     * 
     * @type {Array<Question>}
     * @memberof QuestionCategory
     */
    questions: Array<Question>;
}
/**
 * アンケートカテゴリリスト
 * @export
 * @interface QuestionCategoryList
 */
export interface QuestionCategoryList {
    /**
     * 
     * @type {number}
     * @memberof QuestionCategoryList
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionCategoryList
     */
    enqueteId?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionCategoryList
     */
    enqueteKey?: string;
    /**
     * 
     * @type {Array<QuestionCategory>}
     * @memberof QuestionCategoryList
     */
    list: Array<QuestionCategory>;
}
/**
 * 
 * @export
 * @interface QuestionOption
 */
export interface QuestionOption {
    /**
     * 
     * @type {string}
     * @memberof QuestionOption
     */
    optionCode: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionOption
     */
    optionNumber: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionOption
     */
    optionText: string;
}
/**
 * トピックのモデル
 * @export
 * @interface QuestionTopic
 */
export interface QuestionTopic {
    /**
     * 
     * @type {number}
     * @memberof QuestionTopic
     */
    topicId: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionTopic
     */
    topicCode: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionTopic
     */
    topicLabel: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionTopic
     */
    topicText: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionTopic
     */
    topicOrder: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionTopic
     */
    answerType: QuestionTopicAnswerTypeEnum;
    /**
     * 
     * @type {Array<QuestionOption>}
     * @memberof QuestionTopic
     */
    options: Array<QuestionOption>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionTopicAnswerTypeEnum {
    Single = 'SINGLE',
    Multiple = 'MULTIPLE'
}

/**
 * リーチ分析-比較設定
 * @export
 * @interface ReachAnalysisComparisonSetting
 */
export interface ReachAnalysisComparisonSetting {
    /**
     * 過去比較
     * @type {Array<PastComparison>}
     * @memberof ReachAnalysisComparisonSetting
     */
    pastComparisons: Array<PastComparison>;
    /**
     * 競合比較-プロダクトID
     * @type {Array<ProductComparison>}
     * @memberof ReachAnalysisComparisonSetting
     */
    productComparisonProducts: Array<ProductComparison>;
    /**
     * 自社比較-プロダクトID
     * @type {Array<ProductComparison>}
     * @memberof ReachAnalysisComparisonSetting
     */
    inhouseComparisonProducts: Array<ProductComparison>;
}
/**
 * リーチ分析-プロジェクト情報
 * @export
 * @interface ReachAnalysisProject
 */
export interface ReachAnalysisProject {
    /**
     * 
     * @type {ProjectBasicInfo}
     * @memberof ReachAnalysisProject
     */
    basicInfo: ProjectBasicInfo;
    /**
     * 
     * @type {ReachAnalysisTargetSetting}
     * @memberof ReachAnalysisProject
     */
    targetSettings: ReachAnalysisTargetSetting;
    /**
     * 
     * @type {ReachAnalysisComparisonSetting}
     * @memberof ReachAnalysisProject
     */
    comparisonSettings: ReachAnalysisComparisonSetting;
}
/**
 * リーチ分析プロジェクト登録時の返却用モデル
 * @export
 * @interface ReachAnalysisProject2
 */
export interface ReachAnalysisProject2 {
    /**
     * 
     * @type {ReachAnalysisProject2Condition}
     * @memberof ReachAnalysisProject2
     */
    basicInfo: ReachAnalysisProject2Condition;
    /**
     * 
     * @type {ReachAnalysisProject2TargetSettings}
     * @memberof ReachAnalysisProject2
     */
    targetSetting: ReachAnalysisProject2TargetSettings;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2AnalysisPeriod
 */
export interface ReachAnalysisProject2AnalysisPeriod {
    /**
     * キャンペーン期間開始日
     * @type {string}
     * @memberof ReachAnalysisProject2AnalysisPeriod
     */
    startDate: string;
    /**
     * キャンペーン期間終了日
     * @type {string}
     * @memberof ReachAnalysisProject2AnalysisPeriod
     */
    endDate: string;
}
/**
 * リーチ分析プロジェクトの基本情報の返却用モデル
 * @export
 * @interface ReachAnalysisProject2BasicInfo
 */
export interface ReachAnalysisProject2BasicInfo {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ReachAnalysisProject2BasicInfo
     */
    projectId: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ReachAnalysisProject2BasicInfo
     */
    name: string;
    /**
     * 最終データ更新日
     * @type {string}
     * @memberof ReachAnalysisProject2BasicInfo
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2Card
 */
export interface ReachAnalysisProject2Card {
    /**
     * 
     * @type {ReachAnalysisProject2CardCondition}
     * @memberof ReachAnalysisProject2Card
     */
    basicInfo: ReachAnalysisProject2CardCondition;
    /**
     * 
     * @type {ReachAnalysisProject2TargetSettings}
     * @memberof ReachAnalysisProject2Card
     */
    targetSetting: ReachAnalysisProject2TargetSettings;
}
/**
 * カードレスポンスモデル
 * @export
 * @interface ReachAnalysisProject2CardCondition
 */
export interface ReachAnalysisProject2CardCondition {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2CardCondition
     */
    cardId: number;
    /**
     * カードのラベル名
     * @type {string}
     * @memberof ReachAnalysisProject2CardCondition
     */
    label: string;
    /**
     * エリアコード
     * @type {string}
     * @memberof ReachAnalysisProject2CardCondition
     */
    areaCode: string;
    /**
     * 期間ショートカット
     * @type {string}
     * @memberof ReachAnalysisProject2CardCondition
     */
    calenderPeriodMode: ReachAnalysisProject2CardConditionCalenderPeriodModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2CardCondition
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2CardCondition
     */
    endDate: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CardCondition
     */
    cmSponsorIds?: Array<number>;
    /**
     * TVAL商品ID
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CardCondition
     */
    productIds: Array<number>;
    /**
     * CM素材情報のリスト
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CardCondition
     */
    cmCreativeIds?: Array<number>;
    /**
     * CM種別 
     * @type {string}
     * @memberof ReachAnalysisProject2CardCondition
     */
    cmBuyingKind: ReachAnalysisProject2CardConditionCmBuyingKindEnum;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachAnalysisProject2CardCondition
     */
    numOfEffectiveContacts: number;
    /**
     * 出稿金額
     * @type {number}
     * @memberof ReachAnalysisProject2CardCondition
     */
    placementCost?: number;
    /**
     * パーコスト
     * @type {number}
     * @memberof ReachAnalysisProject2CardCondition
     */
    perCost?: number;
    /**
     * 「出稿費用」か「パーコスト」か「メインカードに合わせる」
     * @type {string}
     * @memberof ReachAnalysisProject2CardCondition
     */
    costType?: ReachAnalysisProject2CardConditionCostTypeEnum;
    /**
     * カラー
     * @type {string}
     * @memberof ReachAnalysisProject2CardCondition
     */
    color: ReachAnalysisProject2CardConditionColorEnum;
    /**
     * 表示順序
     * @type {number}
     * @memberof ReachAnalysisProject2CardCondition
     */
    displayOrder: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardConditionCalenderPeriodModeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    PreviousQuarter = 'PREVIOUS_QUARTER',
    PreviousSeason = 'PREVIOUS_SEASON',
    CurrentSeason = 'CURRENT_SEASON',
    PreviousMonth = 'PREVIOUS_MONTH',
    Previous4Weeks = 'PREVIOUS_4_WEEKS',
    PreviousWeek = 'PREVIOUS_WEEK',
    CurrentMonth = 'CURRENT_MONTH',
    Last28Days = 'LAST_28_DAYS',
    Last14Days = 'LAST_14_DAYS',
    MainCard = 'MAIN_CARD'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardConditionCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardConditionCostTypeEnum {
    PlacementCost = 'PLACEMENT_COST',
    PerCost = 'PER_COST',
    ReferMainPerCost = 'REFER_MAIN_PER_COST'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardConditionColorEnum {
    Blue = 'BLUE',
    Sky = 'SKY',
    Green = 'GREEN',
    Yellow = 'YELLOW',
    Orange = 'ORANGE',
    Pink = 'PINK',
    Purple = 'PURPLE'
}

/**
 * カードリクエスト用モデル
 * @export
 * @interface ReachAnalysisProject2CardConditionFrom
 */
export interface ReachAnalysisProject2CardConditionFrom {
    /**
     * カードのラベル名
     * @type {string}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    label?: string;
    /**
     * エリア
     * @type {string}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    areaCode: string;
    /**
     * 期間ショートカット
     * @type {string}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    calenderPeriodMode: ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    endDate: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    cmSponsorIds?: Array<number>;
    /**
     * 商品／ブランド(リクエスト時productIdとcmProductIdのいずれかを指定。)
     * @type {Array<ReachAnalysisProject2CardConditionFromProducts>}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    products: Array<ReachAnalysisProject2CardConditionFromProducts>;
    /**
     * CM素材情報のリスト
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    cmCreativeIds?: Array<number>;
    /**
     * CM種別 
     * @type {string}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    cmBuyingKind: ReachAnalysisProject2CardConditionFromCmBuyingKindEnum;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    numOfEffectiveContacts: number;
    /**
     * 出稿金額
     * @type {number}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    placementCost?: number;
    /**
     * パーコスト
     * @type {number}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    perCost?: number;
    /**
     * 「出稿費用」か「パーコスト」か「メインカードに合わせる」
     * @type {string}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    costType?: ReachAnalysisProject2CardConditionFromCostTypeEnum;
    /**
     * カラー
     * @type {string}
     * @memberof ReachAnalysisProject2CardConditionFrom
     */
    color: ReachAnalysisProject2CardConditionFromColorEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardConditionFromCalenderPeriodModeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    PreviousQuarter = 'PREVIOUS_QUARTER',
    PreviousSeason = 'PREVIOUS_SEASON',
    CurrentSeason = 'CURRENT_SEASON',
    PreviousMonth = 'PREVIOUS_MONTH',
    Previous4Weeks = 'PREVIOUS_4_WEEKS',
    PreviousWeek = 'PREVIOUS_WEEK',
    CurrentMonth = 'CURRENT_MONTH',
    Last28Days = 'LAST_28_DAYS',
    Last14Days = 'LAST_14_DAYS',
    MainCard = 'MAIN_CARD'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardConditionFromCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardConditionFromCostTypeEnum {
    PlacementCost = 'PLACEMENT_COST',
    PerCost = 'PER_COST',
    ReferMainPerCost = 'REFER_MAIN_PER_COST'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardConditionFromColorEnum {
    Blue = 'BLUE',
    Sky = 'SKY',
    Green = 'GREEN',
    Yellow = 'YELLOW',
    Orange = 'ORANGE',
    Pink = 'PINK',
    Purple = 'PURPLE'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardConditionFromProducts
 */
export interface ReachAnalysisProject2CardConditionFromProducts {
    /**
     * TVAL商品ID
     * @type {number}
     * @memberof ReachAnalysisProject2CardConditionFromProducts
     */
    productId?: number;
    /**
     * CM商品ID
     * @type {number}
     * @memberof ReachAnalysisProject2CardConditionFromProducts
     */
    cmProductId?: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardDetail
 */
export interface ReachAnalysisProject2CardDetail {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetail
     */
    cardId: number;
    /**
     * カードのラベル名
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetail
     */
    label: string;
    /**
     * 
     * @type {ReachAnalysisProject2CardDetailArea}
     * @memberof ReachAnalysisProject2CardDetail
     */
    area: ReachAnalysisProject2CardDetailArea;
    /**
     * 期間ショートカット
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetail
     */
    calenderPeriodMode: ReachAnalysisProject2CardDetailCalenderPeriodModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetail
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetail
     */
    endDate: string;
    /**
     * 
     * @type {Array<ReachAnalysisProject2CardDetailCmSponsors>}
     * @memberof ReachAnalysisProject2CardDetail
     */
    cmSponsors?: Array<ReachAnalysisProject2CardDetailCmSponsors>;
    /**
     * 商品／ブランド
     * @type {Array<ReachAnalysisProject2CardDetailAnalysisProducts>}
     * @memberof ReachAnalysisProject2CardDetail
     */
    analysisProducts: Array<ReachAnalysisProject2CardDetailAnalysisProducts>;
    /**
     * CM素材リスト
     * @type {Array<ReachAnalysisProject2CardDetailCmCreatives>}
     * @memberof ReachAnalysisProject2CardDetail
     */
    cmCreatives?: Array<ReachAnalysisProject2CardDetailCmCreatives>;
    /**
     * CM種別 
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetail
     */
    cmBuyingKind: ReachAnalysisProject2CardDetailCmBuyingKindEnum;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetail
     */
    numOfEffectiveContacts: number;
    /**
     * 出稿金額
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetail
     */
    placementCost?: number;
    /**
     * パーコスト
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetail
     */
    perCost?: number;
    /**
     * 「出稿費用」か「パーコスト」か「メインカードに合わせる」
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetail
     */
    costType?: ReachAnalysisProject2CardDetailCostTypeEnum;
    /**
     * メインカードか否か
     * @type {boolean}
     * @memberof ReachAnalysisProject2CardDetail
     */
    isMain: boolean;
    /**
     * カラー
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetail
     */
    color: ReachAnalysisProject2CardDetailColorEnum;
    /**
     * カードの表示順序
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetail
     */
    displayOrder: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardDetailCalenderPeriodModeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    PreviousQuarter = 'PREVIOUS_QUARTER',
    PreviousSeason = 'PREVIOUS_SEASON',
    CurrentSeason = 'CURRENT_SEASON',
    PreviousMonth = 'PREVIOUS_MONTH',
    Previous4Weeks = 'PREVIOUS_4_WEEKS',
    PreviousWeek = 'PREVIOUS_WEEK',
    CurrentMonth = 'CURRENT_MONTH',
    Last28Days = 'LAST_28_DAYS',
    Last14Days = 'LAST_14_DAYS',
    MainCard = 'MAIN_CARD'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardDetailCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardDetailCostTypeEnum {
    PlacementCost = 'PLACEMENT_COST',
    PerCost = 'PER_COST',
    ReferMainPerCost = 'REFER_MAIN_PER_COST'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardDetailColorEnum {
    Blue = 'BLUE',
    Sky = 'SKY',
    Green = 'GREEN',
    Yellow = 'YELLOW',
    Orange = 'ORANGE',
    Pink = 'PINK',
    Purple = 'PURPLE'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardDetailAnalysisProducts
 */
export interface ReachAnalysisProject2CardDetailAnalysisProducts {
    /**
     * TVAL商品ID
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetailAnalysisProducts
     */
    productId?: number;
    /**
     * CM商品IDのリスト
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CardDetailAnalysisProducts
     */
    cmProductIds: Array<number>;
    /**
     * 商品名
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetailAnalysisProducts
     */
    name: string;
}
/**
 * エリア
 * @export
 * @interface ReachAnalysisProject2CardDetailArea
 */
export interface ReachAnalysisProject2CardDetailArea {
    /**
     * エリアコード
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetailArea
     */
    areaCode: string;
    /**
     * エリア名
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetailArea
     */
    areaName: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardDetailCmCreatives
 */
export interface ReachAnalysisProject2CardDetailCmCreatives {
    /**
     * CM素材ID
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetailCmCreatives
     */
    cmCreativeId: number;
    /**
     * CM秒数
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetailCmCreatives
     */
    cmDuration: number;
    /**
     * CM内容
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetailCmCreatives
     */
    cmSituation: string;
    /**
     * 出演者
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetailCmCreatives
     */
    cmTalent: string;
    /**
     * BGM
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetailCmCreatives
     */
    cmBgm: string;
    /**
     * 備考
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetailCmCreatives
     */
    cmRemarks: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardDetailCmSponsors
 */
export interface ReachAnalysisProject2CardDetailCmSponsors {
    /**
     * CM出稿企業のID
     * @type {number}
     * @memberof ReachAnalysisProject2CardDetailCmSponsors
     */
    cmSponsorId: number;
    /**
     * CM企業名
     * @type {string}
     * @memberof ReachAnalysisProject2CardDetailCmSponsors
     */
    cmSponsorName: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardForm
 */
export interface ReachAnalysisProject2CardForm {
    /**
     * 
     * @type {ReachAnalysisProject2CardConditionFrom}
     * @memberof ReachAnalysisProject2CardForm
     */
    basicInfo: ReachAnalysisProject2CardConditionFrom;
    /**
     * 
     * @type {ReachAnalysisProject2TargetSettings}
     * @memberof ReachAnalysisProject2CardForm
     */
    targetSetting: ReachAnalysisProject2TargetSettings;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardList
 */
export interface ReachAnalysisProject2CardList {
    /**
     * 
     * @type {number}
     * @memberof ReachAnalysisProject2CardList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<ReachAnalysisProject2CardListCards>}
     * @memberof ReachAnalysisProject2CardList
     */
    cards: Array<ReachAnalysisProject2CardListCards>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardListCards
 */
export interface ReachAnalysisProject2CardListCards {
    /**
     * 
     * @type {ReachAnalysisProject2CardDetail}
     * @memberof ReachAnalysisProject2CardListCards
     */
    basicInfo: ReachAnalysisProject2CardDetail;
    /**
     * 
     * @type {ReachAnalysisProject2TargetSettings}
     * @memberof ReachAnalysisProject2CardListCards
     */
    targetSetting: ReachAnalysisProject2TargetSettings;
    /**
     * 
     * @type {ReachAnalysisProject2CardStatus}
     * @memberof ReachAnalysisProject2CardListCards
     */
    cardStatus: ReachAnalysisProject2CardStatus;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardOrder
 */
export interface ReachAnalysisProject2CardOrder {
    /**
     * 
     * @type {Array<ReachAnalysisProject2CardOrderCardOrders>}
     * @memberof ReachAnalysisProject2CardOrder
     */
    cardOrders: Array<ReachAnalysisProject2CardOrderCardOrders>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardOrderCardOrders
 */
export interface ReachAnalysisProject2CardOrderCardOrders {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2CardOrderCardOrders
     */
    cardId: number;
    /**
     * 表示順序
     * @type {number}
     * @memberof ReachAnalysisProject2CardOrderCardOrders
     */
    displayOrder: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CardStatus
 */
export interface ReachAnalysisProject2CardStatus {
    /**
     * カードが有効か否か
     * @type {boolean}
     * @memberof ReachAnalysisProject2CardStatus
     */
    isEnabled: boolean;
    /**
     * 有効でない場合の理由
     * @type {string}
     * @memberof ReachAnalysisProject2CardStatus
     */
    reason?: ReachAnalysisProject2CardStatusReasonEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CardStatusReasonEnum {
    InvalidPeriod = 'INVALID_PERIOD',
    AreaContractTerminated = 'AREA_CONTRACT_TERMINATED',
    TargetDeleted = 'TARGET_DELETED',
    TargetInvalidConditionsIncluded = 'TARGET_INVALID_CONDITIONS_INCLUDED'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmap
 */
export interface ReachAnalysisProject2CmHeatmap {
    /**
     * 
     * @type {Array<ReachAnalysisProject2CmHeatmapCards>}
     * @memberof ReachAnalysisProject2CmHeatmap
     */
    cards: Array<ReachAnalysisProject2CmHeatmapCards>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapCardPrograms
 */
export interface ReachAnalysisProject2CmHeatmapCardPrograms {
    /**
     * 出稿本数
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCardPrograms
     */
    numberOfPublication?: number;
    /**
     * TRP
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCardPrograms
     */
    trp?: number;
    /**
     * 視聴率
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCardPrograms
     */
    viewingRate?: number;
    /**
     * 含有率
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCardPrograms
     */
    contentRatio?: number;
    /**
     * ターゲットスコア
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCardPrograms
     */
    targetScore?: number;
    /**
     * 番組情報リスト
     * @type {Array<ReachAnalysisProject2CmHeatmapCardProgramsPrograms>}
     * @memberof ReachAnalysisProject2CmHeatmapCardPrograms
     */
    programs: Array<ReachAnalysisProject2CmHeatmapCardProgramsPrograms>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapCardProgramsPrograms
 */
export interface ReachAnalysisProject2CmHeatmapCardProgramsPrograms {
    /**
     * 番組名
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapCardProgramsPrograms
     */
    programName: string;
    /**
     * 放送分数
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCardProgramsPrograms
     */
    programBroadcastMinutes: number;
    /**
     * タイムの出稿本数
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCardProgramsPrograms
     */
    numberOfTimePublication: number;
    /**
     * SPOTの出稿本数
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCardProgramsPrograms
     */
    numberOfSpotPublication: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapCardSettingForm
 */
export interface ReachAnalysisProject2CmHeatmapCardSettingForm {
    /**
     * 表示位置
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapCardSettingForm
     */
    position: ReachAnalysisProject2CmHeatmapCardSettingFormPositionEnum;
    /**
     * 背景色
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapCardSettingForm
     */
    visual: ReachAnalysisProject2CmHeatmapCardSettingFormVisualEnum;
    /**
     * セルの数値
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapCardSettingForm
     */
    cellValue: ReachAnalysisProject2CmHeatmapCardSettingFormCellValueEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapCardSettingFormPositionEnum {
    Primary = 'PRIMARY',
    Secondary = 'SECONDARY'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapCardSettingFormVisualEnum {
    Score = 'SCORE',
    ViewingRate = 'VIEWING_RATE',
    ContentRatio = 'CONTENT_RATIO'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapCardSettingFormCellValueEnum {
    Trp = 'TRP',
    NumberOfPublication = 'NUMBER_OF_PUBLICATION',
    ViewingRate = 'VIEWING_RATE',
    ContentRatio = 'CONTENT_RATIO'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapCardThresholdForm
 */
export interface ReachAnalysisProject2CmHeatmapCardThresholdForm {
    /**
     * 表示位置
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapCardThresholdForm
     */
    position: ReachAnalysisProject2CmHeatmapCardThresholdFormPositionEnum;
    /**
     * 背景色
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapCardThresholdForm
     */
    visual: ReachAnalysisProject2CmHeatmapCardThresholdFormVisualEnum;
    /**
     * 凡例の値
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CmHeatmapCardThresholdForm
     */
    thresholdValues: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapCardThresholdFormPositionEnum {
    Primary = 'PRIMARY',
    Secondary = 'SECONDARY'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapCardThresholdFormVisualEnum {
    Score = 'SCORE',
    ViewingRate = 'VIEWING_RATE',
    ContentRatio = 'CONTENT_RATIO'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapCardThresholdResetForm
 */
export interface ReachAnalysisProject2CmHeatmapCardThresholdResetForm {
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapCardThresholdResetForm
     */
    position: ReachAnalysisProject2CmHeatmapCardThresholdResetFormPositionEnum;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapCardThresholdResetForm
     */
    visual: ReachAnalysisProject2CmHeatmapCardThresholdResetFormVisualEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapCardThresholdResetFormPositionEnum {
    Primary = 'PRIMARY',
    Secondary = 'SECONDARY'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapCardThresholdResetFormVisualEnum {
    Score = 'SCORE',
    ViewingRate = 'VIEWING_RATE',
    ContentRatio = 'CONTENT_RATIO'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapCards
 */
export interface ReachAnalysisProject2CmHeatmapCards {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCards
     */
    cardId: number;
    /**
     * レポートデータ
     * @type {Array<ReachAnalysisProject2CmHeatmapStationReport>}
     * @memberof ReachAnalysisProject2CmHeatmapCards
     */
    stationReport: Array<ReachAnalysisProject2CmHeatmapStationReport>;
    /**
     * ヒートマップ
     * @type {Array<ReachAnalysisProject2CmHeatmapHeatmap>}
     * @memberof ReachAnalysisProject2CmHeatmapCards
     */
    heatmap: Array<ReachAnalysisProject2CmHeatmapHeatmap>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapCells
 */
export interface ReachAnalysisProject2CmHeatmapCells {
    /**
     * 放送時刻
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCells
     */
    broadcastTimezone: number;
    /**
     * 出稿本数
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCells
     */
    numberOfPublication?: number;
    /**
     * TRP
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCells
     */
    trp?: number;
    /**
     * 視聴率
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCells
     */
    viewingRate?: number;
    /**
     * 含有率
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCells
     */
    contentRatio?: number;
    /**
     * ターゲットスコア
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapCells
     */
    targetScore?: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapDisplay
 */
export interface ReachAnalysisProject2CmHeatmapDisplay {
    /**
     * 表示するカードID
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CmHeatmapDisplay
     */
    displayCardIds: Array<number>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapHeatmap
 */
export interface ReachAnalysisProject2CmHeatmapHeatmap {
    /**
     * ネットワークコード
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapHeatmap
     */
    stationNetworkCode: string;
    /**
     * 曜日ごとのヒートマップ
     * @type {Array<ReachAnalysisProject2CmHeatmapHeatmapPerDayOfWeek>}
     * @memberof ReachAnalysisProject2CmHeatmapHeatmap
     */
    heatmapPerDayOfWeek: Array<ReachAnalysisProject2CmHeatmapHeatmapPerDayOfWeek>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapHeatmapPerDayOfWeek
 */
export interface ReachAnalysisProject2CmHeatmapHeatmapPerDayOfWeek {
    /**
     * 曜日番号(1=月,...,7=日)
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapHeatmapPerDayOfWeek
     */
    dayOfWeek: number;
    /**
     * 
     * @type {Array<ReachAnalysisProject2CmHeatmapCells>}
     * @memberof ReachAnalysisProject2CmHeatmapHeatmapPerDayOfWeek
     */
    cells: Array<ReachAnalysisProject2CmHeatmapCells>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapSetting
 */
export interface ReachAnalysisProject2CmHeatmapSetting {
    /**
     * 片表示か否か
     * @type {boolean}
     * @memberof ReachAnalysisProject2CmHeatmapSetting
     */
    isSingle: boolean;
    /**
     * カードごとのCM出稿ヒートマップ設定
     * @type {Array<ReachAnalysisProject2CmHeatmapSettingCards>}
     * @memberof ReachAnalysisProject2CmHeatmapSetting
     */
    cards: Array<ReachAnalysisProject2CmHeatmapSettingCards>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapSettingCards
 */
export interface ReachAnalysisProject2CmHeatmapSettingCards {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapSettingCards
     */
    cardId: number;
    /**
     * 背景色
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapSettingCards
     */
    visual: ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum;
    /**
     * セルの数値
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapSettingCards
     */
    cellValue: ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum;
    /**
     * visual ごとの表示設定
     * @type {Array<ReachAnalysisProject2CmHeatmapSettingThresholds>}
     * @memberof ReachAnalysisProject2CmHeatmapSettingCards
     */
    thresholds?: Array<ReachAnalysisProject2CmHeatmapSettingThresholds>;
    /**
     * 表示位置
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapSettingCards
     */
    position: ReachAnalysisProject2CmHeatmapSettingCardsPositionEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapSettingCardsVisualEnum {
    Score = 'SCORE',
    ViewingRate = 'VIEWING_RATE',
    ContentRatio = 'CONTENT_RATIO'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapSettingCardsCellValueEnum {
    Trp = 'TRP',
    NumberOfPublication = 'NUMBER_OF_PUBLICATION',
    ViewingRate = 'VIEWING_RATE',
    ContentRatio = 'CONTENT_RATIO'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapSettingCardsPositionEnum {
    Primary = 'PRIMARY',
    Secondary = 'SECONDARY'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapSettingThresholds
 */
export interface ReachAnalysisProject2CmHeatmapSettingThresholds {
    /**
     * 背景色
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapSettingThresholds
     */
    visual: ReachAnalysisProject2CmHeatmapSettingThresholdsVisualEnum;
    /**
     * 閾値の種類
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapSettingThresholds
     */
    type: ReachAnalysisProject2CmHeatmapSettingThresholdsTypeEnum;
    /**
     * 凡例の値
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2CmHeatmapSettingThresholds
     */
    thresholdValues: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapSettingThresholdsVisualEnum {
    Score = 'SCORE',
    ViewingRate = 'VIEWING_RATE',
    ContentRatio = 'CONTENT_RATIO'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2CmHeatmapSettingThresholdsTypeEnum {
    Auto = 'AUTO',
    Manual = 'MANUAL'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapSingle
 */
export interface ReachAnalysisProject2CmHeatmapSingle {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapSingle
     */
    projectId: number;
    /**
     * ヒートマップが片表示か否か
     * @type {boolean}
     * @memberof ReachAnalysisProject2CmHeatmapSingle
     */
    isSingle: boolean;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CmHeatmapStationReport
 */
export interface ReachAnalysisProject2CmHeatmapStationReport {
    /**
     * ネットワークコード
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    stationNetworkCode: string;
    /**
     * 放送局の表示名
     * @type {string}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    stationDisplayName: string;
    /**
     * 表示順序
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    stationOrder: number;
    /**
     * 出稿本数
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    numberOfPublication: number;
    /**
     * 視聴回数
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    numberOfViewing: number;
    /**
     * ターゲット視聴回数
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    numberOfTargetViewing: number;
    /**
     * GRP
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    grp: number;
    /**
     * TRP
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    trp: number;
    /**
     * ターゲット含有率
     * @type {number}
     * @memberof ReachAnalysisProject2CmHeatmapStationReport
     */
    targetContentRatio: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2Condition
 */
export interface ReachAnalysisProject2Condition {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ReachAnalysisProject2Condition
     */
    projectId: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ReachAnalysisProject2Condition
     */
    projectName: string;
    /**
     * エリア
     * @type {string}
     * @memberof ReachAnalysisProject2Condition
     */
    areaCode: string;
    /**
     * 期間ショートカット
     * @type {string}
     * @memberof ReachAnalysisProject2Condition
     */
    calenderPeriodMode: ReachAnalysisProject2ConditionCalenderPeriodModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2Condition
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2Condition
     */
    endDate: string;
    /**
     * 企業IDリスト
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2Condition
     */
    cmSponsors?: Array<number>;
    /**
     * TVAL商品IDのリスト
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2Condition
     */
    productIds: Array<number>;
    /**
     * CM素材情報のリスト
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2Condition
     */
    cmCreativeIds?: Array<number>;
    /**
     * CM種別
     * @type {string}
     * @memberof ReachAnalysisProject2Condition
     */
    cmBuyingKind: ReachAnalysisProject2ConditionCmBuyingKindEnum;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachAnalysisProject2Condition
     */
    numOfEffectiveContacts: number;
    /**
     * 出稿費用
     * @type {number}
     * @memberof ReachAnalysisProject2Condition
     */
    placementCost?: number;
    /**
     * パーコスト
     * @type {number}
     * @memberof ReachAnalysisProject2Condition
     */
    perCost?: number;
    /**
     * 「出稿費用」か「パーコスト」か「メインカードに合わせる」
     * @type {string}
     * @memberof ReachAnalysisProject2Condition
     */
    costType?: ReachAnalysisProject2ConditionCostTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2ConditionCalenderPeriodModeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    PreviousQuarter = 'PREVIOUS_QUARTER',
    PreviousSeason = 'PREVIOUS_SEASON',
    CurrentSeason = 'CURRENT_SEASON',
    PreviousMonth = 'PREVIOUS_MONTH',
    Previous4Weeks = 'PREVIOUS_4_WEEKS',
    PreviousWeek = 'PREVIOUS_WEEK',
    CurrentMonth = 'CURRENT_MONTH',
    Last28Days = 'LAST_28_DAYS',
    Last14Days = 'LAST_14_DAYS',
    MainCard = 'MAIN_CARD'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2ConditionCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2ConditionCostTypeEnum {
    PlacementCost = 'PLACEMENT_COST',
    PerCost = 'PER_COST',
    ReferMainPerCost = 'REFER_MAIN_PER_COST'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2ConditionForm
 */
export interface ReachAnalysisProject2ConditionForm {
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    projectName: string;
    /**
     * エリアコード
     * @type {string}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    areaCode: string;
    /**
     * 期間ショートカット
     * @type {string}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    calenderPeriodMode: ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    endDate: string;
    /**
     * 企業IDリスト
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    cmSponsors?: Array<number>;
    /**
     * 商品／ブランド（リクエスト時productIdとcmProductIdはいずれかを指定）
     * @type {Array<ReachAnalysisProject2ConditionFormProducts>}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    products: Array<ReachAnalysisProject2ConditionFormProducts>;
    /**
     * CM素材情報のリスト
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    cmCreativeIds?: Array<number>;
    /**
     * CM種別
     * @type {string}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    cmBuyingKind: ReachAnalysisProject2ConditionFormCmBuyingKindEnum;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    numOfEffectiveContacts: number;
    /**
     * 出稿費用
     * @type {number}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    placementCost?: number;
    /**
     * パーコスト
     * @type {number}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    perCost?: number;
    /**
     * 「出稿費用」か「パーコスト」か「メインカードに合わせる」
     * @type {string}
     * @memberof ReachAnalysisProject2ConditionForm
     */
    costType?: ReachAnalysisProject2ConditionFormCostTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2ConditionFormCalenderPeriodModeEnum {
    Manual = 'MANUAL',
    Auto = 'AUTO',
    PreviousQuarter = 'PREVIOUS_QUARTER',
    PreviousSeason = 'PREVIOUS_SEASON',
    CurrentSeason = 'CURRENT_SEASON',
    PreviousMonth = 'PREVIOUS_MONTH',
    Previous4Weeks = 'PREVIOUS_4_WEEKS',
    PreviousWeek = 'PREVIOUS_WEEK',
    CurrentMonth = 'CURRENT_MONTH',
    Last28Days = 'LAST_28_DAYS',
    Last14Days = 'LAST_14_DAYS',
    MainCard = 'MAIN_CARD'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2ConditionFormCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2ConditionFormCostTypeEnum {
    PlacementCost = 'PLACEMENT_COST',
    PerCost = 'PER_COST',
    ReferMainPerCost = 'REFER_MAIN_PER_COST'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2ConditionFormProducts
 */
export interface ReachAnalysisProject2ConditionFormProducts {
    /**
     * TVAL商品ID
     * @type {number}
     * @memberof ReachAnalysisProject2ConditionFormProducts
     */
    productId?: number;
    /**
     * CM商品ID
     * @type {number}
     * @memberof ReachAnalysisProject2ConditionFormProducts
     */
    cmProductId?: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CopyForm
 */
export interface ReachAnalysisProject2CopyForm {
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ReachAnalysisProject2CopyForm
     */
    projectName: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CpeComparison
 */
export interface ReachAnalysisProject2CpeComparison {
    /**
     * 
     * @type {Array<ReachAnalysisProject2CpeComparisonCards>}
     * @memberof ReachAnalysisProject2CpeComparison
     */
    cards: Array<ReachAnalysisProject2CpeComparisonCards>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2CpeComparisonCards
 */
export interface ReachAnalysisProject2CpeComparisonCards {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    cardId: number;
    /**
     * CPE
     * @type {number}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    cpe?: number;
    /**
     * CPV
     * @type {number}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    cpv?: number;
    /**
     * エフェクティブリーチ人数
     * @type {number}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    effectiveReachNumberOfPeople: number;
    /**
     * ターゲット延べ視聴回数
     * @type {number}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    targetNumberOfCmViewing: number;
    /**
     * 出稿費用
     * @type {number}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    placementCost?: number;
    /**
     * GRP
     * @type {number}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    grp: number;
    /**
     * パーコスト
     * @type {number}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    perCost?: number;
    /**
     * メインカードを参照しているかどうか
     * @type {boolean}
     * @memberof ReachAnalysisProject2CpeComparisonCards
     */
    referMainCard?: boolean;
}
/**
 * リーチ分析プロジェクト登録時の入力モデル
 * @export
 * @interface ReachAnalysisProject2Form
 */
export interface ReachAnalysisProject2Form {
    /**
     * 
     * @type {ReachAnalysisProject2ConditionForm}
     * @memberof ReachAnalysisProject2Form
     */
    basicInfo: ReachAnalysisProject2ConditionForm;
    /**
     * 
     * @type {ReachAnalysisProject2TargetSettings}
     * @memberof ReachAnalysisProject2Form
     */
    targetSetting: ReachAnalysisProject2TargetSettings;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2Kpi
 */
export interface ReachAnalysisProject2Kpi {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    cardId: number;
    /**
     * 経過出稿費用
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    placementCostOfPresent?: number;
    /**
     * 出稿費用
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    placementCost?: number;
    /**
     * 個人GRP
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    individualGrp: number;
    /**
     * 世帯GRP
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    householdGrp: number;
    /**
     * TRP
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    trp: number;
    /**
     * エフェクティブリーチ人数
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    effectiveReachNumberOfPeople: number;
    /**
     * CPE
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    cpe?: number;
    /**
     * ターゲットリーチ人数
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    targetReachNumberOfPeople: number;
    /**
     * ターゲットリーチ単価
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    targetReachCost?: number;
    /**
     * ターゲット延べ視聴回数
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    targetNumberOfCmViewing: number;
    /**
     * ターゲットCPV
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    targetCpv?: number;
    /**
     * ターゲット平均フリークエンシー
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    targetAverageFrequency: number;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    numOfEffectiveContacts: number;
    /**
     * エリア名
     * @type {string}
     * @memberof ReachAnalysisProject2Kpi
     */
    areaName: string;
    /**
     * ターゲット名
     * @type {string}
     * @memberof ReachAnalysisProject2Kpi
     */
    targetNames: string;
    /**
     * ターゲットリーチ率
     * @type {number}
     * @memberof ReachAnalysisProject2Kpi
     */
    targetReachRate: number;
}
/**
 * リーチ分析プロジェクト一覧の返却モデル
 * @export
 * @interface ReachAnalysisProject2List
 */
export interface ReachAnalysisProject2List {
    /**
     * トータル件数
     * @type {number}
     * @memberof ReachAnalysisProject2List
     */
    totalCount: number;
    /**
     * 
     * @type {Array<ReachAnalysisProject2Overview>}
     * @memberof ReachAnalysisProject2List
     */
    list: Array<ReachAnalysisProject2Overview>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2Overview
 */
export interface ReachAnalysisProject2Overview {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ReachAnalysisProject2Overview
     */
    id: number;
    /**
     * 作成者のユーザーID
     * @type {number}
     * @memberof ReachAnalysisProject2Overview
     */
    authorSaasUserId: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ReachAnalysisProject2Overview
     */
    name: string;
    /**
     * メインカードの商品／ブランド
     * @type {Array<string>}
     * @memberof ReachAnalysisProject2Overview
     */
    products: Array<string>;
    /**
     * メインカードの企業
     * @type {Array<string>}
     * @memberof ReachAnalysisProject2Overview
     */
    cmSponsors: Array<string>;
    /**
     * メインカードのターゲット
     * @type {Array<string>}
     * @memberof ReachAnalysisProject2Overview
     */
    targets: Array<string>;
    /**
     * メインカードのエリア
     * @type {string}
     * @memberof ReachAnalysisProject2Overview
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2Overview
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2Overview
     */
    endDate: string;
    /**
     * メインカードの個人GRP(計算済みでない場合null)
     * @type {number}
     * @memberof ReachAnalysisProject2Overview
     */
    individualGrp?: number;
    /**
     * CM素材のリスト
     * @type {Array<ReachAnalysisProject2OverviewCmCreatives>}
     * @memberof ReachAnalysisProject2Overview
     */
    cmCreatives: Array<ReachAnalysisProject2OverviewCmCreatives>;
    /**
     * CM種別
     * @type {string}
     * @memberof ReachAnalysisProject2Overview
     */
    cmBuyingKind: ReachAnalysisProject2OverviewCmBuyingKindEnum;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachAnalysisProject2Overview
     */
    numOfEffectiveContacts: number;
    /**
     * 出稿費用（costType が パーコストの場合は「GRP×パーコスト」の値になる）
     * @type {number}
     * @memberof ReachAnalysisProject2Overview
     */
    placementCost?: number;
    /**
     * 「出稿費用」か「パーコスト」か「メインカードに合わせる」
     * @type {string}
     * @memberof ReachAnalysisProject2Overview
     */
    costType?: ReachAnalysisProject2OverviewCostTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2OverviewCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2OverviewCostTypeEnum {
    PlacementCost = 'PLACEMENT_COST',
    PerCost = 'PER_COST',
    ReferMainPerCost = 'REFER_MAIN_PER_COST'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2OverviewCmCreatives
 */
export interface ReachAnalysisProject2OverviewCmCreatives {
    /**
     * CM素材ID
     * @type {number}
     * @memberof ReachAnalysisProject2OverviewCmCreatives
     */
    cmCreativeId: number;
    /**
     * CM秒数
     * @type {number}
     * @memberof ReachAnalysisProject2OverviewCmCreatives
     */
    cmDuration: number;
    /**
     * CM内容
     * @type {string}
     * @memberof ReachAnalysisProject2OverviewCmCreatives
     */
    cmSituation: string;
    /**
     * 出演者
     * @type {string}
     * @memberof ReachAnalysisProject2OverviewCmCreatives
     */
    cmTalent: string;
    /**
     * BGM
     * @type {string}
     * @memberof ReachAnalysisProject2OverviewCmCreatives
     */
    cmBgm: string;
    /**
     * 備考
     * @type {string}
     * @memberof ReachAnalysisProject2OverviewCmCreatives
     */
    cmRemarks: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2Products
 */
export interface ReachAnalysisProject2Products {
    /**
     * TVAL商品ID
     * @type {number}
     * @memberof ReachAnalysisProject2Products
     */
    productId?: number;
    /**
     * CM商品IDのリスト(TVAL商品のときは紐づくCM商品ID、CM商品の場合はその商品のCM商品ID)
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2Products
     */
    cmProductIds: Array<number>;
    /**
     * 商品／ブランド名
     * @type {string}
     * @memberof ReachAnalysisProject2Products
     */
    name: string;
    /**
     * 直近CM本数
     * @type {number}
     * @memberof ReachAnalysisProject2Products
     */
    numOfCmPlacement: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2ProductsGrpGraph
 */
export interface ReachAnalysisProject2ProductsGrpGraph {
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2ProductsGrpGraph
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof ReachAnalysisProject2ProductsGrpGraph
     */
    grp: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2ProjectName
 */
export interface ReachAnalysisProject2ProjectName {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ReachAnalysisProject2ProjectName
     */
    projectId: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ReachAnalysisProject2ProjectName
     */
    projectName: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2ProjectNameForm
 */
export interface ReachAnalysisProject2ProjectNameForm {
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ReachAnalysisProject2ProjectNameForm
     */
    projectName: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2ReachAnalysisGraph
 */
export interface ReachAnalysisProject2ReachAnalysisGraph {
    /**
     * メインカードのエフェクティブリーチ人数
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraph
     */
    effectiveReachNumberOfPeople: number;
    /**
     * メインカードのエフェクティブリーチ率
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraph
     */
    effectiveReachRate: number;
    /**
     * 
     * @type {Array<ReachAnalysisProject2ReachAnalysisGraphCards>}
     * @memberof ReachAnalysisProject2ReachAnalysisGraph
     */
    cards: Array<ReachAnalysisProject2ReachAnalysisGraphCards>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2ReachAnalysisGraphCards
 */
export interface ReachAnalysisProject2ReachAnalysisGraphCards {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphCards
     */
    cardId: number;
    /**
     * メインカードか否か
     * @type {boolean}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphCards
     */
    isMain: boolean;
    /**
     * 有効接触回数(Rn+)
     * @type {string}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphCards
     */
    numOfEffectiveContacts: string;
    /**
     * エリア名
     * @type {string}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphCards
     */
    areaName: string;
    /**
     * ターゲット名
     * @type {string}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphCards
     */
    targetNames: string;
    /**
     * 個人GRPのグラフデータ
     * @type {Array<ReachAnalysisProject2ReachAnalysisGraphIndividual>}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphCards
     */
    individual: Array<ReachAnalysisProject2ReachAnalysisGraphIndividual>;
    /**
     * 世帯GRPのグラフデータ
     * @type {Array<ReachAnalysisProject2ReachAnalysisGraphHousehold>}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphCards
     */
    household: Array<ReachAnalysisProject2ReachAnalysisGraphHousehold>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2ReachAnalysisGraphHousehold
 */
export interface ReachAnalysisProject2ReachAnalysisGraphHousehold {
    /**
     * GRP
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphHousehold
     */
    grp: number;
    /**
     * エフェクティブリーチ人数
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphHousehold
     */
    numberOfPeople: number;
    /**
     * エフェクティブリーチ率
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphHousehold
     */
    rate: number;
    /**
     * CPE
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphHousehold
     */
    cpe?: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2ReachAnalysisGraphIndividual
 */
export interface ReachAnalysisProject2ReachAnalysisGraphIndividual {
    /**
     * GRP
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphIndividual
     */
    grp: number;
    /**
     * エフェクティブリーチ人数
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphIndividual
     */
    numberOfPeople: number;
    /**
     * エフェクティブリーチ率
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphIndividual
     */
    rate: number;
    /**
     * CPE
     * @type {number}
     * @memberof ReachAnalysisProject2ReachAnalysisGraphIndividual
     */
    cpe?: number;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2RecommendProducts
 */
export interface ReachAnalysisProject2RecommendProducts {
    /**
     * 件数
     * @type {number}
     * @memberof ReachAnalysisProject2RecommendProducts
     */
    totalCount: number;
    /**
     * 
     * @type {Array<ReachAnalysisProject2RecommendProductsList>}
     * @memberof ReachAnalysisProject2RecommendProducts
     */
    list?: Array<ReachAnalysisProject2RecommendProductsList>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2RecommendProductsList
 */
export interface ReachAnalysisProject2RecommendProductsList {
    /**
     * TVAL商品ID
     * @type {number}
     * @memberof ReachAnalysisProject2RecommendProductsList
     */
    productId?: number;
    /**
     * CM商品IDのリスト(TVAL商品のときは紐づくCM商品ID、CM商品の場合はその商品のCM商品ID)
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2RecommendProductsList
     */
    cmProductIds: Array<number>;
    /**
     * GRP
     * @type {number}
     * @memberof ReachAnalysisProject2RecommendProductsList
     */
    grp: number;
    /**
     * 商品／ブランド名
     * @type {string}
     * @memberof ReachAnalysisProject2RecommendProductsList
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2SectionDisplay
 */
export interface ReachAnalysisProject2SectionDisplay {
    /**
     * メインカードのID
     * @type {number}
     * @memberof ReachAnalysisProject2SectionDisplay
     */
    mainCardId: number;
    /**
     * 比較対象のカードID
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2SectionDisplay
     */
    subCardIds: Array<number>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2SectionDisplaySetting
 */
export interface ReachAnalysisProject2SectionDisplaySetting {
    /**
     * セクション名
     * @type {string}
     * @memberof ReachAnalysisProject2SectionDisplaySetting
     */
    setcion: ReachAnalysisProject2SectionDisplaySettingSetcionEnum;
    /**
     * 表示する比較対象のカードのID
     * @type {Array<number>}
     * @memberof ReachAnalysisProject2SectionDisplaySetting
     */
    subCardIds: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2SectionDisplaySettingSetcionEnum {
    EffectiveReach = 'EFFECTIVE_REACH',
    CpeComparison = 'CPE_COMPARISON'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2SectionDisplaySettingForm
 */
export interface ReachAnalysisProject2SectionDisplaySettingForm {
    /**
     * セクション表示設定のリスト
     * @type {Array<ReachAnalysisProject2SectionDisplaySettingRecord>}
     * @memberof ReachAnalysisProject2SectionDisplaySettingForm
     */
    displaySettingRecords: Array<ReachAnalysisProject2SectionDisplaySettingRecord>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2SectionDisplaySettingList
 */
export interface ReachAnalysisProject2SectionDisplaySettingList {
    /**
     * トータル件数
     * @type {number}
     * @memberof ReachAnalysisProject2SectionDisplaySettingList
     */
    totalCount: number;
    /**
     * セクション表示設定のリスト
     * @type {Array<ReachAnalysisProject2SectionDisplaySettingRecord>}
     * @memberof ReachAnalysisProject2SectionDisplaySettingList
     */
    displaySettingRecords: Array<ReachAnalysisProject2SectionDisplaySettingRecord>;
}
/**
 * 
 * @export
 * @interface ReachAnalysisProject2SectionDisplaySettingRecord
 */
export interface ReachAnalysisProject2SectionDisplaySettingRecord {
    /**
     * セクション名
     * @type {string}
     * @memberof ReachAnalysisProject2SectionDisplaySettingRecord
     */
    section: ReachAnalysisProject2SectionDisplaySettingRecordSectionEnum;
    /**
     * 表示順序
     * @type {number}
     * @memberof ReachAnalysisProject2SectionDisplaySettingRecord
     */
    displayOrder: number;
    /**
     * 表示非表示
     * @type {boolean}
     * @memberof ReachAnalysisProject2SectionDisplaySettingRecord
     */
    visible: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2SectionDisplaySettingRecordSectionEnum {
    CpeComparison = 'CPE_COMPARISON',
    EffectiveReach = 'EFFECTIVE_REACH',
    CmHeatmap = 'CM_HEATMAP'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2SectionStatus
 */
export interface ReachAnalysisProject2SectionStatus {
    /**
     * セクション名
     * @type {string}
     * @memberof ReachAnalysisProject2SectionStatus
     */
    section: ReachAnalysisProject2SectionStatusSectionEnum;
    /**
     * 
     * @type {Array<ReachAnalysisProject2SectionStatusCards>}
     * @memberof ReachAnalysisProject2SectionStatus
     */
    cards: Array<ReachAnalysisProject2SectionStatusCards>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2SectionStatusSectionEnum {
    Kpi = 'KPI',
    EffectiveReach = 'EFFECTIVE_REACH',
    CmHeatmap = 'CM_HEATMAP',
    CpeComparison = 'CPE_COMPARISON'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2SectionStatusCards
 */
export interface ReachAnalysisProject2SectionStatusCards {
    /**
     * カードID
     * @type {number}
     * @memberof ReachAnalysisProject2SectionStatusCards
     */
    cardId: number;
    /**
     * セクションの計算ステータス
     * @type {string}
     * @memberof ReachAnalysisProject2SectionStatusCards
     */
    calculateStatus: ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2SectionStatusCardsCalculateStatusEnum {
    Outdated = 'OUTDATED',
    WaitingForQueue = 'WAITING_FOR_QUEUE',
    Queued = 'QUEUED',
    Calculating = 'CALCULATING',
    Referable = 'REFERABLE',
    Failed = 'FAILED'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2Status
 */
export interface ReachAnalysisProject2Status {
    /**
     * プロジェクト全体のステータス
     * @type {string}
     * @memberof ReachAnalysisProject2Status
     */
    status: ReachAnalysisProject2StatusStatusEnum;
    /**
     * 何が起因で計算しているか（CALCULATING の時のみ）
     * @type {string}
     * @memberof ReachAnalysisProject2Status
     */
    calculateReason?: ReachAnalysisProject2StatusCalculateReasonEnum;
    /**
     * 
     * @type {Array<ReachAnalysisProject2SectionStatus>}
     * @memberof ReachAnalysisProject2Status
     */
    sections: Array<ReachAnalysisProject2SectionStatus>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2StatusStatusEnum {
    Calculating = 'CALCULATING',
    Referable = 'REFERABLE',
    Failure = 'FAILURE'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2StatusCalculateReasonEnum {
    CreateProject = 'CREATE_PROJECT',
    CreateCard = 'CREATE_CARD',
    UpdateCard = 'UPDATE_CARD',
    UpdateCustomTarget = 'UPDATE_CUSTOM_TARGET',
    ProductMerge = 'PRODUCT_MERGE',
    ChangeMapping = 'CHANGE_MAPPING',
    ChangeConversion15Sec = 'CHANGE_CONVERSION_15SEC'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2TargetSettings
 */
export interface ReachAnalysisProject2TargetSettings {
    /**
     * ターゲットタイプ
     * @type {string}
     * @memberof ReachAnalysisProject2TargetSettings
     */
    targetType: ReachAnalysisProject2TargetSettingsTargetTypeEnum;
    /**
     * ターゲット
     * @type {Array<ReachAnalysisProject2TargetSettingsTargets>}
     * @memberof ReachAnalysisProject2TargetSettings
     */
    targets: Array<ReachAnalysisProject2TargetSettingsTargets>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisProject2TargetSettingsTargetTypeEnum {
    Individual = 'INDIVIDUAL',
    BasicGa12 = 'BASIC_GA12',
    BasicGa10S = 'BASIC_GA10S',
    CustomTarget = 'CUSTOM_TARGET'
}

/**
 * 
 * @export
 * @interface ReachAnalysisProject2TargetSettingsTargets
 */
export interface ReachAnalysisProject2TargetSettingsTargets {
    /**
     * 
     * @type {number}
     * @memberof ReachAnalysisProject2TargetSettingsTargets
     */
    targetId: number;
    /**
     * 
     * @type {string}
     * @memberof ReachAnalysisProject2TargetSettingsTargets
     */
    targetName?: string;
}
/**
 * リーチ分析-プロジェクト情報登録用Form
 * @export
 * @interface ReachAnalysisProjectForm
 */
export interface ReachAnalysisProjectForm {
    /**
     * 
     * @type {ProjectBasicInfo}
     * @memberof ReachAnalysisProjectForm
     */
    basicInfo: ProjectBasicInfo;
    /**
     * 
     * @type {ReachAnalysisTargetSetting}
     * @memberof ReachAnalysisProjectForm
     */
    targetSettings: ReachAnalysisTargetSetting;
    /**
     * 
     * @type {ReachAnalysisComparisonSetting}
     * @memberof ReachAnalysisProjectForm
     */
    comparisonSettings: ReachAnalysisComparisonSetting;
}
/**
 * リーチ分析のサマリ情報各種
 * @export
 * @interface ReachAnalysisSummaries
 */
export interface ReachAnalysisSummaries {
    /**
     * 出稿金額
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    placementAmountPrice: number;
    /**
     * 出稿量(GRP)
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    placementAmount: number;
    /**
     * 有効リーチ単価
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    effectiveReachPrice: number;
    /**
     * ターゲットリーチ単価
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    targetReachPrice: number;
    /**
     * ターゲットCPV
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    targetCpv: number;
    /**
     * CPV
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    cpv: number;
    /**
     * 有効リーチ人数
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    numberOfEffectiveReach: number;
    /**
     * ターゲットリーチ人数
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    numberOfTargetReaches: number;
    /**
     * 延べ視聴回数
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    numberOfTotalViews: number;
    /**
     * ターゲット視聴回数
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    numberOfTargetViews: number;
    /**
     * 平均フリクエンシー
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    averageFrequency: number;
    /**
     * ターゲット比率
     * @type {number}
     * @memberof ReachAnalysisSummaries
     */
    targetRate: number;
}
/**
 * リーチ分析-ターゲット設定
 * @export
 * @interface ReachAnalysisTargetSetting
 */
export interface ReachAnalysisTargetSetting {
    /**
     * ターゲットタイプ
     * @type {string}
     * @memberof ReachAnalysisTargetSetting
     */
    targetType: ReachAnalysisTargetSettingTargetTypeEnum;
    /**
     * ターゲット
     * @type {Array<ReachAnalysisTargetSettingTargets>}
     * @memberof ReachAnalysisTargetSetting
     */
    targets: Array<ReachAnalysisTargetSettingTargets>;
    /**
     * 
     * @type {boolean}
     * @memberof ReachAnalysisTargetSetting
     */
    targetIsEnabled?: boolean;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachAnalysisTargetSetting
     */
    numOfEffectiveContacts: number;
    /**
     * エリア
     * @type {string}
     * @memberof ReachAnalysisTargetSetting
     */
    area: string;
    /**
     * 出稿金額総額
     * @type {number}
     * @memberof ReachAnalysisTargetSetting
     */
    totalPlacementCost: number;
    /**
     * 目標有効リーチ率
     * @type {number}
     * @memberof ReachAnalysisTargetSetting
     */
    targetEffectiveReachRate: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachAnalysisTargetSettingTargetTypeEnum {
    Individual = 'INDIVIDUAL',
    IndividualAndHousehold = 'INDIVIDUAL_AND_HOUSEHOLD',
    Household = 'HOUSEHOLD',
    Custom = 'CUSTOM',
    BasicGa12 = 'BASIC_GA12',
    BasicGa10S = 'BASIC_GA10S'
}

/**
 * 
 * @export
 * @interface ReachAnalysisTargetSettingTargets
 */
export interface ReachAnalysisTargetSettingTargets {
    /**
     * ターゲットID
     * @type {number}
     * @memberof ReachAnalysisTargetSettingTargets
     */
    targetId: number;
    /**
     * ターゲット名
     * @type {string}
     * @memberof ReachAnalysisTargetSettingTargets
     */
    targetName?: string;
}
/**
 * リーチシミュレータープロジェクトにおける基本情報
 * @export
 * @interface ReachSimulatorBasicInfo
 */
export interface ReachSimulatorBasicInfo {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ReachSimulatorBasicInfo
     */
    id?: number;
    /**
     * リーチシミュレータープロジェクト名
     * @type {string}
     * @memberof ReachSimulatorBasicInfo
     */
    projectName: string;
    /**
     * 編集者のアカウントID
     * @type {number}
     * @memberof ReachSimulatorBasicInfo
     */
    authorSaasAccountId?: number;
    /**
     * エリア
     * @type {string}
     * @memberof ReachSimulatorBasicInfo
     */
    area: string;
    /**
     * シミュレーションタイプ
     * @type {string}
     * @memberof ReachSimulatorBasicInfo
     */
    goalType: ReachSimulatorBasicInfoGoalTypeEnum;
    /**
     * GRP
     * @type {number}
     * @memberof ReachSimulatorBasicInfo
     */
    grp: number;
    /**
     * 出稿金額
     * @type {number}
     * @memberof ReachSimulatorBasicInfo
     */
    placementCost?: number;
    /**
     * 平均パーコスト
     * @type {number}
     * @memberof ReachSimulatorBasicInfo
     */
    averagePerCost?: number;
    /**
     * 期間開始日
     * @type {string}
     * @memberof ReachSimulatorBasicInfo
     */
    simulationStartDate: string;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachSimulatorBasicInfo
     */
    numOfEffectiveContacts: number;
    /**
     * 探索ステータス
     * @type {string}
     * @memberof ReachSimulatorBasicInfo
     */
    simulationStatus?: ReachSimulatorBasicInfoSimulationStatusEnum;
    /**
     * 最終更新日
     * @type {string}
     * @memberof ReachSimulatorBasicInfo
     */
    updatedAt?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachSimulatorBasicInfoGoalTypeEnum {
    Grp = 'GRP',
    Budget = 'BUDGET'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachSimulatorBasicInfoSimulationStatusEnum {
    SimulationQueued = 'SIMULATION_QUEUED',
    Simulating = 'SIMULATING',
    Referable = 'REFERABLE',
    NotReferable = 'NOT_REFERABLE',
    NeedToEdit = 'NEED_TO_EDIT'
}

/**
 * リーチシミュレーターTOPに表示する条件
 * @export
 * @interface ReachSimulatorConditions
 */
export interface ReachSimulatorConditions {
    /**
     * シミュレーションモード
     * @type {string}
     * @memberof ReachSimulatorConditions
     */
    goalType: ReachSimulatorConditionsGoalTypeEnum;
    /**
     * エリア
     * @type {string}
     * @memberof ReachSimulatorConditions
     */
    area: string;
    /**
     * ターゲット
     * @type {string}
     * @memberof ReachSimulatorConditions
     */
    target?: string;
    /**
     * GRP
     * @type {number}
     * @memberof ReachSimulatorConditions
     */
    grp: number;
    /**
     * 出稿費用
     * @type {number}
     * @memberof ReachSimulatorConditions
     */
    placementCost?: number;
    /**
     * 平均パーコスト
     * @type {number}
     * @memberof ReachSimulatorConditions
     */
    averagePerCost?: number;
    /**
     * 期間
     * @type {string}
     * @memberof ReachSimulatorConditions
     */
    period: string;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof ReachSimulatorConditions
     */
    numOfEffectiveContacts: number;
    /**
     * 週別GRP
     * @type {Array<RsmWeeklyGrp>}
     * @memberof ReachSimulatorConditions
     */
    weeklyGrps: Array<RsmWeeklyGrp>;
    /**
     * 局設定
     * @type {Array<RsmStationSetting>}
     * @memberof ReachSimulatorConditions
     */
    stationSettings: Array<RsmStationSetting>;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachSimulatorConditionsGoalTypeEnum {
    Grp = 'GRP',
    Budget = 'BUDGET'
}

/**
 * リーチシミュレーター結果系CSVに表示する条件
 * @export
 * @interface ReachSimulatorConditionsForResult
 */
export interface ReachSimulatorConditionsForResult {
    /**
     * RSMプロジェクト名
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    projectName: string;
    /**
     * シミュレーションモード
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    goalType: ReachSimulatorConditionsForResultGoalTypeEnum;
    /**
     * エリア
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    area: string;
    /**
     * ターゲット
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    target?: string;
    /**
     * 週数
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    numOfWeek: string;
    /**
     * 期間
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    period: string;
    /**
     * GRP
     * @type {number}
     * @memberof ReachSimulatorConditionsForResult
     */
    grp: number;
    /**
     * 週別GRP
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    grpWeeklySettings: string;
    /**
     * 局別パーコスト
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    stationPerCostSettings: string;
    /**
     * 局比率
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    stationRateSettings: string;
    /**
     * 有効接触回数
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    numOfEffectiveContacts: string;
    /**
     * レポート作成日時
     * @type {string}
     * @memberof ReachSimulatorConditionsForResult
     */
    createdAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachSimulatorConditionsForResultGoalTypeEnum {
    Grp = 'GRP',
    Budget = 'BUDGET'
}

/**
 * 
 * @export
 * @interface ReachSimulatorOverview
 */
export interface ReachSimulatorOverview {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof ReachSimulatorOverview
     */
    id: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof ReachSimulatorOverview
     */
    name: string;
    /**
     * シミュレーションモード
     * @type {string}
     * @memberof ReachSimulatorOverview
     */
    goalType: ReachSimulatorOverviewGoalTypeEnum;
    /**
     * エリア
     * @type {string}
     * @memberof ReachSimulatorOverview
     */
    area: string;
    /**
     * 設定中のターゲット
     * @type {string}
     * @memberof ReachSimulatorOverview
     */
    target?: string;
    /**
     * 設定中のGRP
     * @type {number}
     * @memberof ReachSimulatorOverview
     */
    grp: number;
    /**
     * Eリーチ人数（結果生データ）
     * @type {number}
     * @memberof ReachSimulatorOverview
     */
    effectiveReach?: number;
    /**
     * 出稿費用（作成画面で入力した値）
     * @type {number}
     * @memberof ReachSimulatorOverview
     */
    placementCost?: number;
    /**
     * CPE（結果生データ）
     * @type {number}
     * @memberof ReachSimulatorOverview
     */
    cpe?: number;
    /**
     * 
     * @type {ReachSimulatorConditions}
     * @memberof ReachSimulatorOverview
     */
    conditions: ReachSimulatorConditions;
    /**
     * シミュレーションステータス
     * @type {string}
     * @memberof ReachSimulatorOverview
     */
    status: ReachSimulatorOverviewStatusEnum;
    /**
     * 最終更新日
     * @type {string}
     * @memberof ReachSimulatorOverview
     */
    updatedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReachSimulatorOverviewGoalTypeEnum {
    Grp = 'GRP',
    Budget = 'BUDGET'
}
/**
    * @export
    * @enum {string}
    */
export enum ReachSimulatorOverviewStatusEnum {
    Referable = 'REFERABLE',
    NotReferable = 'NOT_REFERABLE',
    SimulationQueued = 'SIMULATION_QUEUED',
    Simulating = 'SIMULATING',
    NeedToEdit = 'NEED_TO_EDIT'
}

/**
 * 
 * @export
 * @interface ReachSimulatorProject
 */
export interface ReachSimulatorProject {
    /**
     * 
     * @type {ReachSimulatorBasicInfo}
     * @memberof ReachSimulatorProject
     */
    basicInfo: ReachSimulatorBasicInfo;
    /**
     * 
     * @type {RsmTargetSettings}
     * @memberof ReachSimulatorProject
     */
    targetSetting: RsmTargetSettings;
    /**
     * 
     * @type {Array<RsmWeeklyGrpSetting>}
     * @memberof ReachSimulatorProject
     */
    weeklyGrps: Array<RsmWeeklyGrpSetting>;
    /**
     * 
     * @type {Array<RsmStationRateSetting>}
     * @memberof ReachSimulatorProject
     */
    stationSettings: Array<RsmStationRateSetting>;
}
/**
 * リーチシミュレータープロジェクト一括作成用のform
 * @export
 * @interface ReachSimulatorProjectBulkForm
 */
export interface ReachSimulatorProjectBulkForm {
    /**
     * 複製元のプロジェクトID
     * @type {number}
     * @memberof ReachSimulatorProjectBulkForm
     */
    projectId: number;
    /**
     * 一括作成するプロジェクト名とGRPのList
     * @type {Array<ReachSimulatorProjectBulkFormBulkList>}
     * @memberof ReachSimulatorProjectBulkForm
     */
    bulkList: Array<ReachSimulatorProjectBulkFormBulkList>;
}
/**
 * 
 * @export
 * @interface ReachSimulatorProjectBulkFormBulkList
 */
export interface ReachSimulatorProjectBulkFormBulkList {
    /**
     * リーチシミュレータープロジェクト名
     * @type {string}
     * @memberof ReachSimulatorProjectBulkFormBulkList
     */
    projectName: string;
    /**
     * GRP
     * @type {number}
     * @memberof ReachSimulatorProjectBulkFormBulkList
     */
    grp: number;
}
/**
 * 
 * @export
 * @interface ReachSimulatorProjectForm
 */
export interface ReachSimulatorProjectForm {
    /**
     * 
     * @type {ReachSimulatorBasicInfo}
     * @memberof ReachSimulatorProjectForm
     */
    basicInfo: ReachSimulatorBasicInfo;
    /**
     * 
     * @type {RsmTargetSettings}
     * @memberof ReachSimulatorProjectForm
     */
    targetSetting: RsmTargetSettings;
    /**
     * 
     * @type {Array<RsmWeeklyGrpSetting>}
     * @memberof ReachSimulatorProjectForm
     */
    weeklyGrps: Array<RsmWeeklyGrpSetting>;
    /**
     * 
     * @type {Array<RsmStationRateSetting>}
     * @memberof ReachSimulatorProjectForm
     */
    stationSettings: Array<RsmStationRateSetting>;
}
/**
 * 
 * @export
 * @interface ReachSimulatorProjectList
 */
export interface ReachSimulatorProjectList {
    /**
     * トータル件数
     * @type {number}
     * @memberof ReachSimulatorProjectList
     */
    totalCount: number;
    /**
     * リーチシミュレータープロジェクトのリスト
     * @type {Array<ReachSimulatorProject>}
     * @memberof ReachSimulatorProjectList
     */
    list: Array<ReachSimulatorProject>;
}
/**
 * リーチシミュレーターTOPに表示する情報
 * @export
 * @interface ReachSimulatorProjects
 */
export interface ReachSimulatorProjects {
    /**
     * トータル件数
     * @type {number}
     * @memberof ReachSimulatorProjects
     */
    totalCount: number;
    /**
     * リーチシミュレーターTOPの一覧情報
     * @type {Array<ReachSimulatorOverview>}
     * @memberof ReachSimulatorProjects
     */
    list: Array<ReachSimulatorOverview>;
}
/**
 * 
 * @export
 * @interface RelatedCmProduct
 */
export interface RelatedCmProduct {
    /**
     * 
     * @type {number}
     * @memberof RelatedCmProduct
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof RelatedCmProduct
     */
    isMain: boolean;
}
/**
 * 
 * @export
 * @interface RsmProjectFrequency
 */
export interface RsmProjectFrequency {
    /**
     * フリークエンシー回数
     * @type {number}
     * @memberof RsmProjectFrequency
     */
    frequency: number;
    /**
     * 比率
     * @type {number}
     * @memberof RsmProjectFrequency
     */
    fraction: number;
}
/**
 * 局ごとのリーチカーブ情報
 * @export
 * @interface RsmProjectReachCurve
 */
export interface RsmProjectReachCurve {
    /**
     * GRP
     * @type {number}
     * @memberof RsmProjectReachCurve
     */
    grp: number;
    /**
     * Eリーチ人数
     * @type {number}
     * @memberof RsmProjectReachCurve
     */
    reachNumber: number;
    /**
     * Eリーチ率
     * @type {number}
     * @memberof RsmProjectReachCurve
     */
    reachRate: number;
}
/**
 * 
 * @export
 * @interface RsmProjectResult
 */
export interface RsmProjectResult {
    /**
     * 更新者
     * @type {number}
     * @memberof RsmProjectResult
     */
    authorSaasAccountId: number;
    /**
     * 最終更新日
     * @type {string}
     * @memberof RsmProjectResult
     */
    updatedAt: string;
    /**
     * 
     * @type {RsmProjectResultSummary}
     * @memberof RsmProjectResult
     */
    summary: RsmProjectResultSummary;
    /**
     * 
     * @type {Array<RsmProjectResultEffectiveReach>}
     * @memberof RsmProjectResult
     */
    effectiveReachs: Array<RsmProjectResultEffectiveReach>;
    /**
     * 
     * @type {Array<RsmProjectResultReachCurves>}
     * @memberof RsmProjectResult
     */
    reachCurves: Array<RsmProjectResultReachCurves>;
    /**
     * 
     * @type {Array<RsmProjectResultFrecuencies>}
     * @memberof RsmProjectResult
     */
    frequencies: Array<RsmProjectResultFrecuencies>;
    /**
     * 
     * @type {ReachSimulatorConditionsForResult}
     * @memberof RsmProjectResult
     */
    conditions: ReachSimulatorConditionsForResult;
}
/**
 * リーチシミュレーター結果画面のエフェクティブリーチ部分
 * @export
 * @interface RsmProjectResultEffectiveReach
 */
export interface RsmProjectResultEffectiveReach {
    /**
     * 週番号
     * @type {number}
     * @memberof RsmProjectResultEffectiveReach
     */
    weekNumber: number;
    /**
     * 累積GRP
     * @type {number}
     * @memberof RsmProjectResultEffectiveReach
     */
    grpAccumulated: number;
    /**
     * 週別GRP
     * @type {number}
     * @memberof RsmProjectResultEffectiveReach
     */
    grpWeekly: number;
    /**
     * 累積リーチ人数
     * @type {number}
     * @memberof RsmProjectResultEffectiveReach
     */
    reachAccumulatedNumber: number;
    /**
     * 週別リーチ人数
     * @type {number}
     * @memberof RsmProjectResultEffectiveReach
     */
    reachWeeklyNumber: number;
    /**
     * 累積リーチ率
     * @type {number}
     * @memberof RsmProjectResultEffectiveReach
     */
    reachAccumulatedRate: number;
    /**
     * 週別リーチ率
     * @type {number}
     * @memberof RsmProjectResultEffectiveReach
     */
    reachWeeklyRate: number;
}
/**
 * リーチシミュレーター結果画面のフリークエンシー部分
 * @export
 * @interface RsmProjectResultFrecuencies
 */
export interface RsmProjectResultFrecuencies {
    /**
     * 放送局コード
     * @type {string}
     * @memberof RsmProjectResultFrecuencies
     */
    stationCode: string;
    /**
     * 放送局名
     * @type {string}
     * @memberof RsmProjectResultFrecuencies
     */
    stationName: string;
    /**
     * 平均FQ
     * @type {number}
     * @memberof RsmProjectResultFrecuencies
     */
    averageFrequency: number;
    /**
     * 局毎のFQ
     * @type {Array<RsmProjectFrequency>}
     * @memberof RsmProjectResultFrecuencies
     */
    frequencies: Array<RsmProjectFrequency>;
}
/**
 * リーチシミュレーター結果画面のリーチカーブ部分
 * @export
 * @interface RsmProjectResultReachCurves
 */
export interface RsmProjectResultReachCurves {
    /**
     * 放送局コード
     * @type {string}
     * @memberof RsmProjectResultReachCurves
     */
    stationCode: string;
    /**
     * 放送局名
     * @type {string}
     * @memberof RsmProjectResultReachCurves
     */
    stationName: string;
    /**
     * リーチカーブ情報
     * @type {Array<RsmProjectReachCurve>}
     * @memberof RsmProjectResultReachCurves
     */
    reachCurves: Array<RsmProjectReachCurve>;
}
/**
 * リーチシミュレーター結果画面の指標部分
 * @export
 * @interface RsmProjectResultSummary
 */
export interface RsmProjectResultSummary {
    /**
     * 画面で設定したGRP
     * @type {number}
     * @memberof RsmProjectResultSummary
     */
    grp: number;
    /**
     * Eリーチ人数
     * @type {number}
     * @memberof RsmProjectResultSummary
     */
    numOfEffectiveReach: number;
    /**
     * Eリーチ率
     * @type {number}
     * @memberof RsmProjectResultSummary
     */
    effectiveReachRate: number;
    /**
     * 平均FQ
     * @type {number}
     * @memberof RsmProjectResultSummary
     */
    averageFrequency?: number;
    /**
     * 画面で設定している出稿費用
     * @type {number}
     * @memberof RsmProjectResultSummary
     */
    placementCost?: number;
    /**
     * CPE
     * @type {number}
     * @memberof RsmProjectResultSummary
     */
    cpe?: number;
}
/**
 * リーチシミュレータープロジェクトの局比率(条件登録・編集用のModel)
 * @export
 * @interface RsmStationRateSetting
 */
export interface RsmStationRateSetting {
    /**
     * 放送局コード
     * @type {string}
     * @memberof RsmStationRateSetting
     */
    stationCode: string;
    /**
     * 絵柄
     * @type {string}
     * @memberof RsmStationRateSetting
     */
    placementPattern: RsmStationRateSettingPlacementPatternEnum;
    /**
     * 局別パーコスト
     * @type {number}
     * @memberof RsmStationRateSetting
     */
    stationPerCost: number;
    /**
     * 局比率
     * @type {number}
     * @memberof RsmStationRateSetting
     */
    stationRate: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RsmStationRateSettingPlacementPatternEnum {
    All = 'ALL',
    Yo = 'YO',
    Ko = 'KO',
    Rl = 'RL',
    Ichi = 'ICHI'
}

/**
 * リーチシミュレーターの局設定
 * @export
 * @interface RsmStationSetting
 */
export interface RsmStationSetting {
    /**
     * 
     * @type {string}
     * @memberof RsmStationSetting
     */
    stationName: string;
    /**
     * 
     * @type {string}
     * @memberof RsmStationSetting
     */
    placementPattern: string;
    /**
     * 
     * @type {number}
     * @memberof RsmStationSetting
     */
    stationPerCost: number;
    /**
     * 
     * @type {number}
     * @memberof RsmStationSetting
     */
    stationRate: number;
}
/**
 * リーチシミュレータープロジェクトのターゲット設定
 * @export
 * @interface RsmTargetSettings
 */
export interface RsmTargetSettings {
    /**
     * 
     * @type {string}
     * @memberof RsmTargetSettings
     */
    targetType: string;
    /**
     * 
     * @type {Array<RsmTargetSettingsTargets>}
     * @memberof RsmTargetSettings
     */
    targets: Array<RsmTargetSettingsTargets>;
    /**
     * 設定されているターゲットが現在有効かどうか ※登録・編集時はnull
     * @type {boolean}
     * @memberof RsmTargetSettings
     */
    targetIsEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface RsmTargetSettingsTargets
 */
export interface RsmTargetSettingsTargets {
    /**
     * ターゲットID
     * @type {number}
     * @memberof RsmTargetSettingsTargets
     */
    targetId: number;
    /**
     * ターゲット名
     * @type {string}
     * @memberof RsmTargetSettingsTargets
     */
    targetName?: string;
}
/**
 * リーチシミュレーター週別GRP
 * @export
 * @interface RsmWeeklyGrp
 */
export interface RsmWeeklyGrp {
    /**
     * 週番号
     * @type {string}
     * @memberof RsmWeeklyGrp
     */
    weekNumber: string;
    /**
     * 週別GRP
     * @type {number}
     * @memberof RsmWeeklyGrp
     */
    weeklyGrp: number;
}
/**
 * リーチシミュレータープロジェクトの週別GRPの設定
 * @export
 * @interface RsmWeeklyGrpSetting
 */
export interface RsmWeeklyGrpSetting {
    /**
     * X週目
     * @type {number}
     * @memberof RsmWeeklyGrpSetting
     */
    weekNumber: number;
    /**
     * 週別のGRP
     * @type {number}
     * @memberof RsmWeeklyGrpSetting
     */
    weeklyGrp: number;
}
/**
 * サンプルサイズの返却モデル
 * @export
 * @interface SampleSize
 */
export interface SampleSize {
    /**
     * 
     * @type {string}
     * @memberof SampleSize
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SampleSize
     */
    sampleSize: number;
}
/**
 * 選択可能なエリアのリスト
 * @export
 * @interface SelectableAreas
 */
export interface SelectableAreas {
    /**
     * 
     * @type {Array<SelectableAreasList>}
     * @memberof SelectableAreas
     */
    list: Array<SelectableAreasList>;
}
/**
 * 
 * @export
 * @interface SelectableAreasList
 */
export interface SelectableAreasList {
    /**
     * 
     * @type {string}
     * @memberof SelectableAreasList
     */
    areaDivision: SelectableAreasListAreaDivisionEnum;
    /**
     * 
     * @type {Array<AreaInfo>}
     * @memberof SelectableAreasList
     */
    areas: Array<AreaInfo>;
}

/**
    * @export
    * @enum {string}
    */
export enum SelectableAreasListAreaDivisionEnum {
    Main = 'MAIN',
    Local = 'LOCAL',
    Single = 'SINGLE'
}

/**
 * サインアップ用Form
 * @export
 * @interface SignupForm
 */
export interface SignupForm {
    /**
     * 
     * @type {string}
     * @memberof SignupForm
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignupForm
     */
    inflowType: SignupFormInflowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SignupForm
     */
    advertiseCode?: string;
    /**
     * 
     * @type {number}
     * @memberof SignupForm
     */
    invitationWorkspaceId?: number;
    /**
     * 
     * @type {number}
     * @memberof SignupForm
     */
    invitatorId?: number;
    /**
     * 
     * @type {string}
     * @memberof SignupForm
     */
    reCaptchaToken: string;
}

/**
    * @export
    * @enum {string}
    */
export enum SignupFormInflowTypeEnum {
    Web = 'WEB',
    Saas = 'SAAS',
    Console = 'CONSOLE'
}

/**
 * 放送局別CM出稿パターンの返却モデル
 * @export
 * @interface StationPlacementPattern
 */
export interface StationPlacementPattern {
    /**
     * 
     * @type {string}
     * @memberof StationPlacementPattern
     */
    stationCode: string;
    /**
     * 
     * @type {string}
     * @memberof StationPlacementPattern
     */
    placementPattern: StationPlacementPatternPlacementPatternEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum StationPlacementPatternPlacementPatternEnum {
    All = 'ALL',
    Yo = 'YO',
    Ko = 'KO',
    Rl = 'RL',
    Ichi = 'ICHI'
}

/**
 * 放送局ごとの視聴率時系列データ
 * @export
 * @interface StationViewingRate
 */
export interface StationViewingRate {
    /**
     * 放送局コード
     * @type {string}
     * @memberof StationViewingRate
     */
    stationCode: string;
    /**
     * 放送局名
     * @type {string}
     * @memberof StationViewingRate
     */
    stationName: string;
    /**
     * 視聴率の時系列データ(数値は小数点６桁)
     * @type {Array<number>}
     * @memberof StationViewingRate
     */
    viewingRateList: Array<number>;
    /**
     * 視聴人数の時系列データ(数値は小数点６桁)
     * @type {Array<number>}
     * @memberof StationViewingRate
     */
    numberOfViewersList: Array<number>;
}
/**
 * リーチ分析 - ターゲット比率のグラフデータ
 * @export
 * @interface TargetRate
 */
export interface TargetRate {
    /**
     * 
     * @type {Array<TargetRateHeatMap>}
     * @memberof TargetRate
     */
    heatMaps: Array<TargetRateHeatMap>;
}
/**
 * リーチ分析 - ターゲット比率のヒートマップ(局別)
 * @export
 * @interface TargetRateHeatMap
 */
export interface TargetRateHeatMap {
    /**
     * 放送局コード
     * @type {string}
     * @memberof TargetRateHeatMap
     */
    stationCode: string;
    /**
     * 放送局名
     * @type {string}
     * @memberof TargetRateHeatMap
     */
    stationName: string;
    /**
     * 放送局並び順
     * @type {number}
     * @memberof TargetRateHeatMap
     */
    stationOrder: number;
    /**
     * 局別ターゲット比率
     * @type {number}
     * @memberof TargetRateHeatMap
     */
    targetRate: number;
    /**
     * CM出稿本数
     * @type {number}
     * @memberof TargetRateHeatMap
     */
    numOfCmPlacement: number;
    /**
     * 
     * @type {Array<TargetRateHeatMapByDayOfWeek>}
     * @memberof TargetRateHeatMap
     */
    heatMapByDayOfWeek: Array<TargetRateHeatMapByDayOfWeek>;
}
/**
 * リーチ分析 - ターゲット比率のヒートマップ(曜日別)
 * @export
 * @interface TargetRateHeatMapByDayOfWeek
 */
export interface TargetRateHeatMapByDayOfWeek {
    /**
     * 曜日
     * @type {number}
     * @memberof TargetRateHeatMapByDayOfWeek
     */
    dayOfWeek: number;
    /**
     * 時間帯(5時-29時)ごとのターゲット比率・CM出稿本数
     * @type {Array<TargetRateHeatMapByDayOfWeekHeatMapCells>}
     * @memberof TargetRateHeatMapByDayOfWeek
     */
    heatMapCells: Array<TargetRateHeatMapByDayOfWeekHeatMapCells>;
}
/**
 * 
 * @export
 * @interface TargetRateHeatMapByDayOfWeekHeatMapCells
 */
export interface TargetRateHeatMapByDayOfWeekHeatMapCells {
    /**
     * 時間帯(5時-29時)
     * @type {number}
     * @memberof TargetRateHeatMapByDayOfWeekHeatMapCells
     */
    broadcastTimezone: number;
    /**
     * ターゲット比率(時間帯別)
     * @type {number}
     * @memberof TargetRateHeatMapByDayOfWeekHeatMapCells
     */
    targetRate: number;
    /**
     * CM出稿本数(時間帯別)
     * @type {number}
     * @memberof TargetRateHeatMapByDayOfWeekHeatMapCells
     */
    numberOfCmPlacement: number;
}
/**
 * 目標リーチ人数のモデル
 * @export
 * @interface TargetReachNumber
 */
export interface TargetReachNumber {
    /**
     * 
     * @type {number}
     * @memberof TargetReachNumber
     */
    reachNumber: number;
}
/**
 * 
 * @export
 * @interface TargetReachNumbers
 */
export interface TargetReachNumbers {
    /**
     * 
     * @type {string}
     * @memberof TargetReachNumbers
     */
    displayName: string;
    /**
     * 
     * @type {Array<TargetReachNumbersNumbersOfTargetReach>}
     * @memberof TargetReachNumbers
     */
    numbersOfTargetReach?: Array<TargetReachNumbersNumbersOfTargetReach>;
}
/**
 * 
 * @export
 * @interface TargetReachNumbersNumbersOfTargetReach
 */
export interface TargetReachNumbersNumbersOfTargetReach {
    /**
     * 
     * @type {string}
     * @memberof TargetReachNumbersNumbersOfTargetReach
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof TargetReachNumbersNumbersOfTargetReach
     */
    numberOfPeople: number;
}
/**
 * TVデータサーチ - 時間帯ターゲットヒートマップ
 * @export
 * @interface Timeslot
 */
export interface Timeslot {
    /**
     * 
     * @type {Array<TimeslotTargetHeatMap>}
     * @memberof Timeslot
     */
    heatMaps: Array<TimeslotTargetHeatMap>;
}
/**
 * 時間帯の検索条件テキスト返却モデル
 * @export
 * @interface TimeslotSearchCondition
 */
export interface TimeslotSearchCondition {
    /**
     * 
     * @type {string}
     * @memberof TimeslotSearchCondition
     */
    duration: string;
    /**
     * 
     * @type {number}
     * @memberof TimeslotSearchCondition
     */
    sampleSize: number;
    /**
     * 
     * @type {string}
     * @memberof TimeslotSearchCondition
     */
    area: string;
    /**
     * 
     * @type {string}
     * @memberof TimeslotSearchCondition
     */
    dataDivision: TimeslotSearchConditionDataDivisionEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof TimeslotSearchCondition
     */
    target: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TimeslotSearchCondition
     */
    reportCreateDateTime: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TimeslotSearchConditionDataDivisionEnum {
    ViewingRate = 'VIEWING_RATE',
    ContentRate = 'CONTENT_RATE',
    ContentRateHousehold = 'CONTENT_RATE_HOUSEHOLD'
}

/**
 * TVデータサーチ - 時間帯ターゲットヒートマップ(局別)
 * @export
 * @interface TimeslotTargetHeatMap
 */
export interface TimeslotTargetHeatMap {
    /**
     * 放送局コード
     * @type {string}
     * @memberof TimeslotTargetHeatMap
     */
    stationCode: string;
    /**
     * 放送局名
     * @type {string}
     * @memberof TimeslotTargetHeatMap
     */
    stationName: string;
    /**
     * 放送局並び順
     * @type {number}
     * @memberof TimeslotTargetHeatMap
     */
    stationOrder: number;
    /**
     * 
     * @type {Array<TimeslotTargetHeatMapByDayOfWeek>}
     * @memberof TimeslotTargetHeatMap
     */
    heatMapByDayOfWeek: Array<TimeslotTargetHeatMapByDayOfWeek>;
}
/**
 * TVデータサーチ - 時間帯ターゲットヒートマップ(曜日別)
 * @export
 * @interface TimeslotTargetHeatMapByDayOfWeek
 */
export interface TimeslotTargetHeatMapByDayOfWeek {
    /**
     * 
     * @type {number}
     * @memberof TimeslotTargetHeatMapByDayOfWeek
     */
    dayOfWeek: number;
    /**
     * 
     * @type {Array<TimeslotTargetHeatMapByDayOfWeekHeatMapCells>}
     * @memberof TimeslotTargetHeatMapByDayOfWeek
     */
    heatMapCells: Array<TimeslotTargetHeatMapByDayOfWeekHeatMapCells>;
}
/**
 * 
 * @export
 * @interface TimeslotTargetHeatMapByDayOfWeekHeatMapCells
 */
export interface TimeslotTargetHeatMapByDayOfWeekHeatMapCells {
    /**
     * 
     * @type {number}
     * @memberof TimeslotTargetHeatMapByDayOfWeekHeatMapCells
     */
    broadcastTimezone: number;
    /**
     * 
     * @type {number}
     * @memberof TimeslotTargetHeatMapByDayOfWeekHeatMapCells
     */
    ratio: number;
}
/**
 * TRO番組放送情報
 * @export
 * @interface TroProgramBroadcast
 */
export interface TroProgramBroadcast {
    /**
     * 番組放送ID
     * @type {number}
     * @memberof TroProgramBroadcast
     */
    id: number;
    /**
     * 放送日
     * @type {string}
     * @memberof TroProgramBroadcast
     */
    broadcastDate: string;
    /**
     * 放送曜日
     * @type {string}
     * @memberof TroProgramBroadcast
     */
    dayOfWeek: string;
    /**
     * 開始時間
     * @type {string}
     * @memberof TroProgramBroadcast
     */
    startTime: string;
    /**
     * 終了時間
     * @type {string}
     * @memberof TroProgramBroadcast
     */
    endTime: string;
    /**
     * 番組名
     * @type {string}
     * @memberof TroProgramBroadcast
     */
    programName: string;
    /**
     * TRO番組タイプ
     * @type {string}
     * @memberof TroProgramBroadcast
     */
    troProgramType: TroProgramBroadcastTroProgramTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TroProgramBroadcastTroProgramTypeEnum {
    Regular = 'REGULAR',
    Single = 'SINGLE',
    Special = 'SPECIAL',
    Promotion = 'PROMOTION',
    Rebroadcasting = 'REBROADCASTING'
}

/**
 * TRO番組インポート詳細モデル
 * @export
 * @interface TroProgramImportDetail
 */
export interface TroProgramImportDetail {
    /**
     * 出稿状況
     * @type {string}
     * @memberof TroProgramImportDetail
     */
    troPlacementStatus?: TroProgramImportDetailTroPlacementStatusEnum;
    /**
     * 放送局
     * @type {string}
     * @memberof TroProgramImportDetail
     */
    stationCode: string;
    /**
     * 放送曜日
     * @type {string}
     * @memberof TroProgramImportDetail
     */
    dayOfWeek?: string;
    /**
     * 放送日
     * @type {string}
     * @memberof TroProgramImportDetail
     */
    broadcastDate?: string;
    /**
     * 参照日付
     * @type {string}
     * @memberof TroProgramImportDetail
     */
    analysisDate?: string;
    /**
     * 開始時間
     * @type {string}
     * @memberof TroProgramImportDetail
     */
    startTime: string;
    /**
     * 終了時間
     * @type {string}
     * @memberof TroProgramImportDetail
     */
    endTime: string;
    /**
     * 番組名
     * @type {string}
     * @memberof TroProgramImportDetail
     */
    programName: string;
    /**
     * 月額料金
     * @type {number}
     * @memberof TroProgramImportDetail
     */
    monthlyCost?: number;
    /**
     * 放送回リスト
     * @type {Array<TroProgramBroadcast>}
     * @memberof TroProgramImportDetail
     */
    broadcastList?: Array<TroProgramBroadcast>;
    /**
     * 除外対象の番組IDリスト（※詳細取得時に使用）
     * @type {Array<number>}
     * @memberof TroProgramImportDetail
     */
    excludeBroadcastList?: Array<number>;
    /**
     * 採用フラグ
     * @type {boolean}
     * @memberof TroProgramImportDetail
     */
    fixed: boolean;
    /**
     * 除外フラグ（レギュラー番組のみ）
     * @type {boolean}
     * @memberof TroProgramImportDetail
     */
    exclude?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TroProgramImportDetailTroPlacementStatusEnum {
    Sponsored = 'SPONSORED',
    Candidate = 'CANDIDATE'
}

/**
 * TRO番組インポート用フォームモデル
 * @export
 * @interface TroProgramImportDetailForm
 */
export interface TroProgramImportDetailForm {
    /**
     * エリアコード
     * @type {string}
     * @memberof TroProgramImportDetailForm
     */
    areaCode: TroProgramImportDetailFormAreaCodeEnum;
    /**
     * TRO番組タイプ
     * @type {string}
     * @memberof TroProgramImportDetailForm
     */
    troProgramType: TroProgramImportDetailFormTroProgramTypeEnum;
    /**
     * TRO組み合わせ条件
     * @type {string}
     * @memberof TroProgramImportDetailForm
     */
    troCombination: TroProgramImportDetailFormTroCombinationEnum;
    /**
     * 探索開始日
     * @type {string}
     * @memberof TroProgramImportDetailForm
     */
    startDate: string;
    /**
     * 探索終了日
     * @type {string}
     * @memberof TroProgramImportDetailForm
     */
    endDate: string;
    /**
     * インポートCSVデータ
     * @type {Array<Array<string>>}
     * @memberof TroProgramImportDetailForm
     */
    importData: Array<Array<string>>;
}

/**
    * @export
    * @enum {string}
    */
export enum TroProgramImportDetailFormAreaCodeEnum {
    Kanto = 'SAAS_KANTO',
    Kansai = 'SAAS_KANSAI',
    Chukyo = 'SAAS_CHUKYO'
}
/**
    * @export
    * @enum {string}
    */
export enum TroProgramImportDetailFormTroProgramTypeEnum {
    Regular = 'REGULAR',
    Single = 'SINGLE'
}
/**
    * @export
    * @enum {string}
    */
export enum TroProgramImportDetailFormTroCombinationEnum {
    Budget = 'BUDGET',
    Programs = 'PROGRAMS'
}

/**
 * TRO番組インポート詳細モデルのリスト
 * @export
 * @interface TroProgramImportDetailList
 */
export interface TroProgramImportDetailList {
    /**
     * トータル件数
     * @type {number}
     * @memberof TroProgramImportDetailList
     */
    totalCount: number;
    /**
     * TRO番組インポート詳細モデルのリスト
     * @type {Array<TroProgramImportDetail>}
     * @memberof TroProgramImportDetailList
     */
    list: Array<TroProgramImportDetail>;
}
/**
 * 
 * @export
 * @interface TroProject
 */
export interface TroProject {
    /**
     * 
     * @type {TroProjectBasicInfo}
     * @memberof TroProject
     */
    basicInfo: TroProjectBasicInfo;
    /**
     * 
     * @type {TroTargetSettings}
     * @memberof TroProject
     */
    targetSetting: TroTargetSettings;
    /**
     * 
     * @type {Array<TroProjectProgramSetting>}
     * @memberof TroProject
     */
    programSettings: Array<TroProjectProgramSetting>;
    /**
     * 
     * @type {TroProjectCmCombination}
     * @memberof TroProject
     */
    cmCombinations?: TroProjectCmCombination;
    /**
     * 
     * @type {Array<TroProjectStationSetting>}
     * @memberof TroProject
     */
    stationSettings?: Array<TroProjectStationSetting>;
}
/**
 * TROプロジェクトにおける基本情報
 * @export
 * @interface TroProjectBasicInfo
 */
export interface TroProjectBasicInfo {
    /**
     * プロジェクトID
     * @type {number}
     * @memberof TroProjectBasicInfo
     */
    projectId?: number;
    /**
     * TROプロジェクト名
     * @type {string}
     * @memberof TroProjectBasicInfo
     */
    projectName: string;
    /**
     * 編集者のアカウントID
     * @type {number}
     * @memberof TroProjectBasicInfo
     */
    authorSaasAccountId: number;
    /**
     * 番組タイプ
     * @type {string}
     * @memberof TroProjectBasicInfo
     */
    troProgramType: TroProjectBasicInfoTroProgramTypeEnum;
    /**
     * 組み合わせ条件
     * @type {string}
     * @memberof TroProjectBasicInfo
     */
    troCombination: TroProjectBasicInfoTroCombinationEnum;
    /**
     * エリア
     * @type {string}
     * @memberof TroProjectBasicInfo
     */
    area: string;
    /**
     * 分析対象の時間
     * @type {string}
     * @memberof TroProjectBasicInfo
     */
    broadcastTimeType?: TroProjectBasicInfoBroadcastTimeTypeEnum;
    /**
     * 予算
     * @type {number}
     * @memberof TroProjectBasicInfo
     */
    totalBudget?: number;
    /**
     * 番組数
     * @type {number}
     * @memberof TroProjectBasicInfo
     */
    numberOfPrograms?: number;
    /**
     * 入れ替える提供番組数
     * @type {number}
     * @memberof TroProjectBasicInfo
     */
    numberOfReplaceSponsoredPrograms?: number;
    /**
     * 特番除外するかどうか
     * @type {boolean}
     * @memberof TroProjectBasicInfo
     */
    excludesSingleBroadcast: boolean;
    /**
     * 集計期間(または実質値参照期間)の開始日
     * @type {string}
     * @memberof TroProjectBasicInfo
     */
    searchStartDate: string;
    /**
     * 集計期間(または実質値参照期間)の終了日
     * @type {string}
     * @memberof TroProjectBasicInfo
     */
    searchEndDate: string;
    /**
     * 有効接触回数
     * @type {number}
     * @memberof TroProjectBasicInfo
     */
    numOfEffectiveContacts: number;
    /**
     * 探索ステータス
     * @type {string}
     * @memberof TroProjectBasicInfo
     */
    searchStatus: TroProjectBasicInfoSearchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TroProjectBasicInfoTroProgramTypeEnum {
    Regular = 'REGULAR',
    Single = 'SINGLE'
}
/**
    * @export
    * @enum {string}
    */
export enum TroProjectBasicInfoTroCombinationEnum {
    Budget = 'BUDGET',
    Programs = 'PROGRAMS'
}
/**
    * @export
    * @enum {string}
    */
export enum TroProjectBasicInfoBroadcastTimeTypeEnum {
    BroadcastTimes = 'BROADCAST_TIMES',
    ImportData = 'IMPORT_DATA'
}
/**
    * @export
    * @enum {string}
    */
export enum TroProjectBasicInfoSearchStatusEnum {
    Referable = 'REFERABLE',
    Searching = 'SEARCHING',
    NotExecuted = 'NOT_EXECUTED',
    NotExecutable = 'NOT_EXECUTABLE',
    NotInContractArea = 'NOT_IN_CONTRACT_AREA',
    NeedToEdit = 'NEED_TO_EDIT',
    NoEffectiveCombination = 'NO_EFFECTIVE_COMBINATION',
    SearchingQueued = 'SEARCHING_QUEUED'
}

/**
 * 
 * @export
 * @interface TroProjectCmCombination
 */
export interface TroProjectCmCombination {
    /**
     * 参照期間の開始日
     * @type {string}
     * @memberof TroProjectCmCombination
     */
    referenceStartDate: string;
    /**
     * 参照期間の終了日
     * @type {string}
     * @memberof TroProjectCmCombination
     */
    referenceEndDate: string;
    /**
     * CMタイプ
     * @type {string}
     * @memberof TroProjectCmCombination
     */
    cmBuyingKind: TroProjectCmCombinationCmBuyingKindEnum;
    /**
     * 商品IDのリスト
     * @type {Array<number>}
     * @memberof TroProjectCmCombination
     */
    productIds: Array<number>;
    /**
     * CM素材IDのリスト
     * @type {Array<number>}
     * @memberof TroProjectCmCombination
     */
    cmCreativeIds: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum TroProjectCmCombinationCmBuyingKindEnum {
    All = 'ALL',
    Time = 'TIME',
    Spot = 'SPOT'
}

/**
 * 
 * @export
 * @interface TroProjectCmCombinationForm
 */
export interface TroProjectCmCombinationForm {
    /**
     * 参照期間の開始日
     * @type {string}
     * @memberof TroProjectCmCombinationForm
     */
    referenceStartDate: string;
    /**
     * 参照期間の終了日
     * @type {string}
     * @memberof TroProjectCmCombinationForm
     */
    referenceEndDate: string;
    /**
     * CMタイプ
     * @type {string}
     * @memberof TroProjectCmCombinationForm
     */
    cmBuyingKind: string;
    /**
     * 商品ID情報
     * @type {Array<ProductIdInfo>}
     * @memberof TroProjectCmCombinationForm
     */
    productIds: Array<ProductIdInfo>;
    /**
     * CM素材IDのリスト
     * @type {Array<number>}
     * @memberof TroProjectCmCombinationForm
     */
    cmCreativeIds: Array<number>;
}
/**
 * 保存された探索条件
 * @export
 * @interface TroProjectConditions
 */
export interface TroProjectConditions {
    /**
     * 番組タイプ
     * @type {string}
     * @memberof TroProjectConditions
     */
    programType: string;
    /**
     * 組み合わせ条件
     * @type {string}
     * @memberof TroProjectConditions
     */
    troCombination: string;
    /**
     * エリア
     * @type {string}
     * @memberof TroProjectConditions
     */
    area: string;
    /**
     * 集計期間、実質参照期間
     * @type {string}
     * @memberof TroProjectConditions
     */
    searchPeriod: string;
    /**
     * プランニング期間
     * @type {string}
     * @memberof TroProjectConditions
     */
    planningPeriod?: string;
    /**
     * 予算
     * @type {number}
     * @memberof TroProjectConditions
     */
    budget?: number;
    /**
     * 番組数
     * @type {number}
     * @memberof TroProjectConditions
     */
    numOfPrograms?: number;
    /**
     * 提供番組数
     * @type {number}
     * @memberof TroProjectConditions
     */
    numOfSponsoredPrograms?: number;
    /**
     * 提供中採用番組
     * @type {string}
     * @memberof TroProjectConditions
     */
    sponsoredIncludePrograms?: string;
    /**
     * 提供中除外番組
     * @type {string}
     * @memberof TroProjectConditions
     */
    sponsoredExcludePrograms?: string;
    /**
     * 候補番組数
     * @type {number}
     * @memberof TroProjectConditions
     */
    numOfCandidatePrograms?: number;
    /**
     * 候補採用番組
     * @type {string}
     * @memberof TroProjectConditions
     */
    candidateIncludePrograms?: string;
    /**
     * 候補除外番組
     * @type {string}
     * @memberof TroProjectConditions
     */
    candidateExcludePrograms?: string;
    /**
     * 番組数の条件
     * @type {string}
     * @memberof TroProjectConditions
     */
    stationSetting?: string;
    /**
     * 放送時間を固定
     * @type {string}
     * @memberof TroProjectConditions
     */
    broadcastTimeType?: string;
    /**
     * ターゲット
     * @type {string}
     * @memberof TroProjectConditions
     */
    target: string;
    /**
     * 有効接触回数
     * @type {string}
     * @memberof TroProjectConditions
     */
    numOfEffectiveContacts: string;
    /**
     * 既存CM参照期間
     * @type {string}
     * @memberof TroProjectConditions
     */
    cmReferablePeriod?: string;
    /**
     * CM種別
     * @type {string}
     * @memberof TroProjectConditions
     */
    cmType?: string;
    /**
     * 既存CMのカンパニー名
     * @type {string}
     * @memberof TroProjectConditions
     */
    cmCompanyName?: string;
    /**
     * 既存CMの商品名
     * @type {string}
     * @memberof TroProjectConditions
     */
    cmProductName?: string;
    /**
     * 既存CMの対象CM素材
     * @type {string}
     * @memberof TroProjectConditions
     */
    cmTargetCmCreative?: string;
    /**
     * レポート作成日時
     * @type {string}
     * @memberof TroProjectConditions
     */
    reportCreatedDate: string;
}
/**
 * 
 * @export
 * @interface TroProjectDetail
 */
export interface TroProjectDetail {
    /**
     * 
     * @type {TroProjectBasicInfo}
     * @memberof TroProjectDetail
     */
    basicInfo: TroProjectBasicInfo;
    /**
     * 
     * @type {TroTargetSettings}
     * @memberof TroProjectDetail
     */
    targetSetting: TroTargetSettings;
    /**
     * 
     * @type {Array<TroProgramImportDetail>}
     * @memberof TroProjectDetail
     */
    programSettings: Array<TroProgramImportDetail>;
    /**
     * 
     * @type {TroProjectCmCombination}
     * @memberof TroProjectDetail
     */
    cmCombinations?: TroProjectCmCombination;
    /**
     * 
     * @type {Array<TroProjectStationSetting>}
     * @memberof TroProjectDetail
     */
    stationSettings?: Array<TroProjectStationSetting>;
    /**
     * プロジェクトの最終更新日時
     * @type {string}
     * @memberof TroProjectDetail
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface TroProjectForm
 */
export interface TroProjectForm {
    /**
     * 
     * @type {TroProjectBasicInfo}
     * @memberof TroProjectForm
     */
    basicInfo: TroProjectBasicInfo;
    /**
     * 
     * @type {TroTargetSettings}
     * @memberof TroProjectForm
     */
    targetSetting: TroTargetSettings;
    /**
     * 
     * @type {Array<TroProjectProgramSetting>}
     * @memberof TroProjectForm
     */
    programSettings: Array<TroProjectProgramSetting>;
    /**
     * 
     * @type {TroProjectCmCombinationForm}
     * @memberof TroProjectForm
     */
    cmCombinations?: TroProjectCmCombinationForm;
    /**
     * 
     * @type {Array<TroProjectStationSetting>}
     * @memberof TroProjectForm
     */
    stationSettings?: Array<TroProjectStationSetting>;
}
/**
 * TROプロジェクト一覧
 * @export
 * @interface TroProjectList
 */
export interface TroProjectList {
    /**
     * トータル件数
     * @type {number}
     * @memberof TroProjectList
     */
    totalCount: number;
    /**
     * 保存済みTROプロジェクトの一覧
     * @type {Array<TroProjectOverview>}
     * @memberof TroProjectList
     */
    list: Array<TroProjectOverview>;
}
/**
 * TROプロジェクト一覧に表示するプロジェクトの概要情報
 * @export
 * @interface TroProjectOverview
 */
export interface TroProjectOverview {
    /**
     * TROプロジェクトのID
     * @type {number}
     * @memberof TroProjectOverview
     */
    id: number;
    /**
     * プロジェクト名
     * @type {string}
     * @memberof TroProjectOverview
     */
    projectName: string;
    /**
     * プロジェクトに設定されているターゲット名
     * @type {string}
     * @memberof TroProjectOverview
     */
    targetName: string;
    /**
     * プロジェクトに設定されている有効接触回数
     * @type {string}
     * @memberof TroProjectOverview
     */
    numOfEffectiveContacts: string;
    /**
     * 探索開始日
     * @type {string}
     * @memberof TroProjectOverview
     */
    startDate: string;
    /**
     * 探索終了日
     * @type {string}
     * @memberof TroProjectOverview
     */
    endDate: string;
    /**
     * 実行ステータス
     * @type {string}
     * @memberof TroProjectOverview
     */
    status: TroProjectOverviewStatusEnum;
    /**
     * 編集者のアカウント名
     * @type {string}
     * @memberof TroProjectOverview
     */
    authorSaasAccountName: string;
    /**
     * 最終更新日
     * @type {string}
     * @memberof TroProjectOverview
     */
    updatedAt: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TroProjectOverviewStatusEnum {
    Referable = 'REFERABLE',
    Searching = 'SEARCHING',
    NotExecuted = 'NOT_EXECUTED',
    NotExecutable = 'NOT_EXECUTABLE',
    NotInContractArea = 'NOT_IN_CONTRACT_AREA',
    NeedToEdit = 'NEED_TO_EDIT',
    NoEffectiveCombination = 'NO_EFFECTIVE_COMBINATION',
    SearchingQueued = 'SEARCHING_QUEUED'
}

/**
 * TROの番組インポート情報
 * @export
 * @interface TroProjectProgramSetting
 */
export interface TroProjectProgramSetting {
    /**
     * 放送局コード
     * @type {string}
     * @memberof TroProjectProgramSetting
     */
    stationCode: string;
    /**
     * 開始時間
     * @type {string}
     * @memberof TroProjectProgramSetting
     */
    startTime: string;
    /**
     * 終了時間
     * @type {string}
     * @memberof TroProjectProgramSetting
     */
    endTime: string;
    /**
     * 番組名
     * @type {string}
     * @memberof TroProjectProgramSetting
     */
    programName: string;
    /**
     * 月額
     * @type {number}
     * @memberof TroProjectProgramSetting
     */
    monthlyCost?: number;
    /**
     * 分類
     * @type {string}
     * @memberof TroProjectProgramSetting
     */
    troPlacementStatus?: TroProjectProgramSettingTroPlacementStatusEnum;
    /**
     * 曜日
     * @type {string}
     * @memberof TroProjectProgramSetting
     */
    dayOfWeek?: string;
    /**
     * 放送日
     * @type {string}
     * @memberof TroProjectProgramSetting
     */
    broadcastDate?: string;
    /**
     * 参照日付
     * @type {string}
     * @memberof TroProjectProgramSetting
     */
    analysisDate?: string;
    /**
     * 候補の番組IDリスト
     * @type {Array<number>}
     * @memberof TroProjectProgramSetting
     */
    broadcasts?: Array<number>;
    /**
     * 除外対象の番組IDリスト
     * @type {Array<number>}
     * @memberof TroProjectProgramSetting
     */
    excludedBroadcasts?: Array<number>;
    /**
     * 固定するかどうか
     * @type {boolean}
     * @memberof TroProjectProgramSetting
     */
    fixed: boolean;
    /**
     * 除外フラグ（レギュラー番組のみ）
     * @type {boolean}
     * @memberof TroProjectProgramSetting
     */
    exclude?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TroProjectProgramSettingTroPlacementStatusEnum {
    Sponsored = 'SPONSORED',
    Candidate = 'CANDIDATE'
}

/**
 * TROの探索結果
 * @export
 * @interface TroProjectResult
 */
export interface TroProjectResult {
    /**
     * 
     * @type {TroResultSummary}
     * @memberof TroProjectResult
     */
    summary: TroResultSummary;
    /**
     * 提供中番組情報
     * @type {Array<TroResultPrograms>}
     * @memberof TroProjectResult
     */
    sponsoredProgram?: Array<TroResultPrograms>;
    /**
     * 入れ替え候補番組情報
     * @type {Array<TroResultPrograms>}
     * @memberof TroProjectResult
     */
    candidateProgram: Array<TroResultPrograms>;
    /**
     * 
     * @type {TroProjectConditions}
     * @memberof TroProjectResult
     */
    conditions: TroProjectConditions;
}
/**
 * 
 * @export
 * @interface TroProjectStationSetting
 */
export interface TroProjectStationSetting {
    /**
     * エリアコード
     * @type {string}
     * @memberof TroProjectStationSetting
     */
    stationCode: string;
    /**
     * 番組数
     * @type {number}
     * @memberof TroProjectStationSetting
     */
    numberOfPrograms: number;
}
/**
 * 
 * @export
 * @interface TroResultPrograms
 */
export interface TroResultPrograms {
    /**
     * 番組名
     * @type {string}
     * @memberof TroResultPrograms
     */
    programName: string;
    /**
     * 番組リーチR1＋
     * @type {string}
     * @memberof TroResultPrograms
     */
    fixedProgramReaches: string;
    /**
     * 番組リーチR{n}+
     * @type {string}
     * @memberof TroResultPrograms
     */
    variableProgramReaches?: string;
    /**
     * 月額
     * @type {string}
     * @memberof TroResultPrograms
     */
    monthlyCost?: string;
    /**
     * 放送局
     * @type {string}
     * @memberof TroResultPrograms
     */
    stationCode?: string;
    /**
     * 提供曜日
     * @type {string}
     * @memberof TroResultPrograms
     */
    week?: string;
    /**
     * 放送日
     * @type {string}
     * @memberof TroResultPrograms
     */
    broadcastDate?: string;
    /**
     * 開始時刻
     * @type {string}
     * @memberof TroResultPrograms
     */
    startTime?: string;
    /**
     * 終了時刻
     * @type {string}
     * @memberof TroResultPrograms
     */
    endTime?: string;
    /**
     * リーチ影響度
     * @type {Array<number>}
     * @memberof TroResultPrograms
     */
    reachImpact: Array<number>;
}
/**
 * 
 * @export
 * @interface TroResultSummary
 */
export interface TroResultSummary {
    /**
     * トータル金額
     * @type {Array<number>}
     * @memberof TroResultSummary
     */
    totalCosts: Array<number>;
    /**
     * トータルリーチ人数
     * @type {Array<number>}
     * @memberof TroResultSummary
     */
    totalReachNumbers: Array<number>;
    /**
     * トータルリーチ人数差分
     * @type {Array<number>}
     * @memberof TroResultSummary
     */
    totalReachNumberDeltas?: Array<number>;
    /**
     * トータルリーチ率差分
     * @type {Array<number>}
     * @memberof TroResultSummary
     */
    totalReachRates: Array<number>;
    /**
     * トータルリーチ率
     * @type {Array<number>}
     * @memberof TroResultSummary
     */
    totalReachRateDeltas?: Array<number>;
    /**
     * リーチ単価
     * @type {Array<number>}
     * @memberof TroResultSummary
     */
    costsPerReach: Array<number>;
    /**
     * 改善金額
     * @type {Array<number>}
     * @memberof TroResultSummary
     */
    improvementAmounts?: Array<number>;
}
/**
 * 
 * @export
 * @interface TroTargetSettings
 */
export interface TroTargetSettings {
    /**
     * 
     * @type {string}
     * @memberof TroTargetSettings
     */
    targetType: string;
    /**
     * 
     * @type {Array<TroTargetSettingsTargets>}
     * @memberof TroTargetSettings
     */
    targets: Array<TroTargetSettingsTargets>;
    /**
     * 設定されているターゲットが現在有効かどうか ※登録・編集時はnull
     * @type {boolean}
     * @memberof TroTargetSettings
     */
    targetIsEnabled?: boolean;
}
/**
 * 
 * @export
 * @interface TroTargetSettingsTargets
 */
export interface TroTargetSettingsTargets {
    /**
     * 
     * @type {number}
     * @memberof TroTargetSettingsTargets
     */
    targetId: number;
    /**
     * 
     * @type {string}
     * @memberof TroTargetSettingsTargets
     */
    targetName?: string;
}
/**
 * TVデータサーチ用CM期間集計の検索履歴に表示する情報
 * @export
 * @interface TvDataSearchCmAggregateHistories
 */
export interface TvDataSearchCmAggregateHistories {
    /**
     * トータル件数
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistories
     */
    totalCount: number;
    /**
     * 
     * @type {Array<TvDataSearchCmAggregateHistory>}
     * @memberof TvDataSearchCmAggregateHistories
     */
    list: Array<TvDataSearchCmAggregateHistory>;
}
/**
 * TVデータサーチ用CM期間集計の検索履歴情報
 * @export
 * @interface TvDataSearchCmAggregateHistory
 */
export interface TvDataSearchCmAggregateHistory {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistory
     */
    id: number;
    /**
     * エリアコード
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    areaCode: string;
    /**
     * 検索開始日
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    startDate: string;
    /**
     * 検索終了日
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    endDate: string;
    /**
     * CM出稿企業リスト
     * @type {Array<TvDataSearchCmAggregateHistoryCmSponsors>}
     * @memberof TvDataSearchCmAggregateHistory
     */
    cmSponsors?: Array<TvDataSearchCmAggregateHistoryCmSponsors>;
    /**
     * CM商品リスト
     * @type {Array<TvDataSearchCmAggregateHistoryCmProducts>}
     * @memberof TvDataSearchCmAggregateHistory
     */
    cmProducts?: Array<TvDataSearchCmAggregateHistoryCmProducts>;
    /**
     * 
     * @type {Array<TvDataSearchCmAggregateHistoryCmCreatives>}
     * @memberof TvDataSearchCmAggregateHistory
     */
    cmCreatives?: Array<TvDataSearchCmAggregateHistoryCmCreatives>;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    targetType: TvDataSearchCmAggregateHistoryTargetTypeEnum;
    /**
     * 
     * @type {Array<TvDataSearchCmAggregateHistoryTargets>}
     * @memberof TvDataSearchCmAggregateHistory
     */
    targets?: Array<TvDataSearchCmAggregateHistoryTargets>;
    /**
     * CM種別
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    buyingKind?: TvDataSearchCmAggregateHistoryBuyingKindEnum;
    /**
     * CM秒数
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistory
     */
    cmDuration?: number;
    /**
     * 放送局リスト
     * @type {Array<string>}
     * @memberof TvDataSearchCmAggregateHistory
     */
    stations: Array<string>;
    /**
     * 
     * @type {Array<TvDataSearchCmAggregateHistoryProgramSeries>}
     * @memberof TvDataSearchCmAggregateHistory
     */
    programSeries?: Array<TvDataSearchCmAggregateHistoryProgramSeries>;
    /**
     * 検索開始時刻（05:00～28:59）
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    startTime: string;
    /**
     * 検索終了時刻（05:00～28:59）
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    endTime: string;
    /**
     * 検索日時
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    searchedAt: string;
    /**
     * 履歴の商品が変更されていない場合にtrue、変更されてたらfalse
     * @type {boolean}
     * @memberof TvDataSearchCmAggregateHistory
     */
    isProductUnchanged: boolean;
    /**
     * 履歴のカスタムターゲットが削除されてない場合にtrue、削除されてたらfalse
     * @type {boolean}
     * @memberof TvDataSearchCmAggregateHistory
     */
    isCustomTargetNotDeleted: boolean;
    /**
     * 15秒換算の場合にtrue、15秒換算でない場合にfalse
     * @type {boolean}
     * @memberof TvDataSearchCmAggregateHistory
     */
    isConversion15sec?: boolean;
    /**
     * 視聴種別
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistory
     */
    viewingType: TvDataSearchCmAggregateHistoryViewingTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmAggregateHistoryTargetTypeEnum {
    IndividualAndHousehold = 'INDIVIDUAL_AND_HOUSEHOLD',
    BasicGa12 = 'BASIC_GA12',
    BasicGa10S = 'BASIC_GA10S',
    Custom = 'CUSTOM'
}
/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmAggregateHistoryBuyingKindEnum {
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmAggregateHistoryViewingTypeEnum {
    Realtime = 'REALTIME',
    Total = 'TOTAL'
}

/**
 * 
 * @export
 * @interface TvDataSearchCmAggregateHistoryCmCreatives
 */
export interface TvDataSearchCmAggregateHistoryCmCreatives {
    /**
     * CM素材ID
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistoryCmCreatives
     */
    cmCreativeId?: number;
    /**
     * CM秒数
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistoryCmCreatives
     */
    cmDuration?: number;
    /**
     * CM内容
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistoryCmCreatives
     */
    cmSituation?: string;
    /**
     * 出演者
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistoryCmCreatives
     */
    cmTalent?: string;
    /**
     * BGM
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistoryCmCreatives
     */
    cmBgm?: string;
    /**
     * 備考
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistoryCmCreatives
     */
    cmRemarks?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmAggregateHistoryCmProducts
 */
export interface TvDataSearchCmAggregateHistoryCmProducts {
    /**
     * CM商品ID
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistoryCmProducts
     */
    cmProductId?: number;
    /**
     * CM商品名
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistoryCmProducts
     */
    cmProductName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmAggregateHistoryCmSponsors
 */
export interface TvDataSearchCmAggregateHistoryCmSponsors {
    /**
     * CM出稿企業ID
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistoryCmSponsors
     */
    cmSponsorId?: number;
    /**
     * CM企業名
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistoryCmSponsors
     */
    cmSponsorName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmAggregateHistoryProgramSeries
 */
export interface TvDataSearchCmAggregateHistoryProgramSeries {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistoryProgramSeries
     */
    programSeriesId?: number;
    /**
     * 番組名
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistoryProgramSeries
     */
    programName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmAggregateHistoryTargets
 */
export interface TvDataSearchCmAggregateHistoryTargets {
    /**
     * ターゲットID
     * @type {number}
     * @memberof TvDataSearchCmAggregateHistoryTargets
     */
    targetId?: number;
    /**
     * ターゲット名
     * @type {string}
     * @memberof TvDataSearchCmAggregateHistoryTargets
     */
    targetName?: string;
}
/**
 * TVデータサーチ用CM素材情報の返却モデル
 * @export
 * @interface TvDataSearchCmCreative
 */
export interface TvDataSearchCmCreative {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmCreative
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmCreative
     */
    cmDuration: number;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmCreative
     */
    cmSituation: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmCreative
     */
    cmTalent: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmCreative
     */
    cmBgm: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmCreative
     */
    cmRemarks: string;
}
/**
 * TVデータサーチ用CM期間集計情報の各レコード用返却モデル
 * @export
 * @interface TvDataSearchCmGRPData
 */
export interface TvDataSearchCmGRPData {
    /**
     * 企業名
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmSponsorName: string;
    /**
     * 商品/ブランド名
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmProductName: string;
    /**
     * エリア
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    areaName: string;
    /**
     * 集計対象都道府県
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    areaPrefectureNames: string;
    /**
     * 放送局名
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    stationName: string;
    /**
     * 放送系列名
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    stationNetworkName: string;
    /**
     * 素材ID
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmCreativeId: string;
    /**
     * CM秒数
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmDuration: string;
    /**
     * 素材
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmSituation: string;
    /**
     * 出演者
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmTalent: string;
    /**
     * BGM
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmBgm: string;
    /**
     * 備考
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmRemarks: string;
    /**
     * CM種別
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    cmType: string;
    /**
     * 本数
     * @type {number}
     * @memberof TvDataSearchCmGRPData
     */
    numOfCmPlacement: number;
    /**
     * ターゲット
     * @type {string}
     * @memberof TvDataSearchCmGRPData
     */
    targetName: string;
    /**
     * 期間計
     * @type {number}
     * @memberof TvDataSearchCmGRPData
     */
    allPeriod: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof TvDataSearchCmGRPData
     */
    dataByPeriod: Array<number> | null;
}
/**
 * TVデータサーチ用CM期間集計情報の期間毎返却モデル
 * @export
 * @interface TvDataSearchCmGRPDataList
 */
export interface TvDataSearchCmGRPDataList {
    /**
     * 
     * @type {TvDataSearchCmGRPDataListHeader}
     * @memberof TvDataSearchCmGRPDataList
     */
    header: TvDataSearchCmGRPDataListHeader;
    /**
     * 
     * @type {Array<TvDataSearchCmGRPData>}
     * @memberof TvDataSearchCmGRPDataList
     */
    data: Array<TvDataSearchCmGRPData>;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmGRPDataListHeader
 */
export interface TvDataSearchCmGRPDataListHeader {
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmSponsorName: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmProductName: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    areaPrefectureNames: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    stationName: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    stationNetworkName: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmCreativeId: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmDuration: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmSituation: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmTalent: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmBgm: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmRemarks: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    cmType: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    numOfCmPlacement: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    targetName: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    allPeriod: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TvDataSearchCmGRPDataListHeader
     */
    dataByPeriod: Array<string>;
}
/**
 * TVデータサーチ用CM期間集計情報の全期間分を含んだ返却モデル
 * @export
 * @interface TvDataSearchCmGrpAllList
 */
export interface TvDataSearchCmGrpAllList {
    /**
     * 
     * @type {TvDataSearchCmGrpAllListConditions}
     * @memberof TvDataSearchCmGrpAllList
     */
    conditions: TvDataSearchCmGrpAllListConditions;
    /**
     * 
     * @type {TvDataSearchCmGRPDataList}
     * @memberof TvDataSearchCmGrpAllList
     */
    month: TvDataSearchCmGRPDataList;
    /**
     * 
     * @type {TvDataSearchCmGRPDataList}
     * @memberof TvDataSearchCmGrpAllList
     */
    week: TvDataSearchCmGRPDataList;
    /**
     * 
     * @type {TvDataSearchCmGRPDataList}
     * @memberof TvDataSearchCmGrpAllList
     */
    day: TvDataSearchCmGRPDataList;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmGrpAllListConditions
 */
export interface TvDataSearchCmGrpAllListConditions {
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    period: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    timeZone: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    areaName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    sampleSize: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    cmSponsorNames: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    cmProductNames: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    cmCreativeNames: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    cmType: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    cmDuration: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    stations: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    programs: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    viewingType: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    reportType: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    convertIn15Seconds: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmGrpAllListConditions
     */
    createdAt: string;
}
/**
 * TVデータサーチ用CMリスト情報の返却モデル
 * @export
 * @interface TvDataSearchCmList
 */
export interface TvDataSearchCmList {
    /**
     * 
     * @type {Array<TvDataSearchCmListBaseData>}
     * @memberof TvDataSearchCmList
     */
    baseData: Array<TvDataSearchCmListBaseData>;
    /**
     * 
     * @type {Array<{ [key: string]: number; }>}
     * @memberof TvDataSearchCmList
     */
    viewingRateData: Array<{ [key: string]: number; }>;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmList
     */
    reportCreateDateTime: string;
}
/**
 * TVデータサーチ用CMリストにおける基本情報の返却モデル
 * @export
 * @interface TvDataSearchCmListBaseData
 */
export interface TvDataSearchCmListBaseData {
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    dayOfWeek: string;
    /**
     * 
     * @type {boolean}
     * @memberof TvDataSearchCmListBaseData
     */
    isHoliday: boolean;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    areaName: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    areaPrefectureNames: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    station: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    stationNetwork: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    programName: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    cmType: TvDataSearchCmListBaseDataCmTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    cmSponsor: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    cmProductAfterSummary: string;
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListBaseData
     */
    cmProductIdBeforeSummary: number;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    cmProductBeforeSummary: string;
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListBaseData
     */
    cmCreativeId: number;
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListBaseData
     */
    cmDuration: number;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    cmSituation: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    cmTalent: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    cmBgm: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseData
     */
    cmRemarks: string;
    /**
     * 
     * @type {TvDataSearchCmListBaseDataCmCategory}
     * @memberof TvDataSearchCmListBaseData
     */
    cmCategory: TvDataSearchCmListBaseDataCmCategory;
}

/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmListBaseDataCmTypeEnum {
    Time = 'TIME',
    Pt = 'PT',
    Sb = 'SB'
}

/**
 * 
 * @export
 * @interface TvDataSearchCmListBaseDataCmCategory
 */
export interface TvDataSearchCmListBaseDataCmCategory {
    /**
     * 
     * @type {TvDataSearchCmListBaseDataCmCategoryLevel1Category}
     * @memberof TvDataSearchCmListBaseDataCmCategory
     */
    level1Category: TvDataSearchCmListBaseDataCmCategoryLevel1Category;
    /**
     * 
     * @type {TvDataSearchCmListBaseDataCmCategoryLevel2Category}
     * @memberof TvDataSearchCmListBaseDataCmCategory
     */
    level2Category?: TvDataSearchCmListBaseDataCmCategoryLevel2Category;
    /**
     * 
     * @type {TvDataSearchCmListBaseDataCmCategoryLevel3Category}
     * @memberof TvDataSearchCmListBaseDataCmCategory
     */
    level3Category?: TvDataSearchCmListBaseDataCmCategoryLevel3Category;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmListBaseDataCmCategoryLevel1Category
 */
export interface TvDataSearchCmListBaseDataCmCategoryLevel1Category {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListBaseDataCmCategoryLevel1Category
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseDataCmCategoryLevel1Category
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmListBaseDataCmCategoryLevel2Category
 */
export interface TvDataSearchCmListBaseDataCmCategoryLevel2Category {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListBaseDataCmCategoryLevel2Category
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseDataCmCategoryLevel2Category
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmListBaseDataCmCategoryLevel3Category
 */
export interface TvDataSearchCmListBaseDataCmCategoryLevel3Category {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListBaseDataCmCategoryLevel3Category
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListBaseDataCmCategoryLevel3Category
     */
    name?: string;
}
/**
 * TVデータサーチ用CMリストの検索履歴に表示する情報
 * @export
 * @interface TvDataSearchCmListHistories
 */
export interface TvDataSearchCmListHistories {
    /**
     * トータル件数
     * @type {number}
     * @memberof TvDataSearchCmListHistories
     */
    totalCount: number;
    /**
     * 
     * @type {Array<TvDataSearchCmListHistory>}
     * @memberof TvDataSearchCmListHistories
     */
    list: Array<TvDataSearchCmListHistory>;
}
/**
 * TVデータサーチ用CMリストの検索履歴情報 
 * @export
 * @interface TvDataSearchCmListHistory
 */
export interface TvDataSearchCmListHistory {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListHistory
     */
    id: number;
    /**
     * エリアコード
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    areaCode: string;
    /**
     * 検索開始日
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    startDate: string;
    /**
     * 検索終了日
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    endDate: string;
    /**
     * CM出稿企業リスト
     * @type {Array<TvDataSearchCmListHistoryCmSponsors>}
     * @memberof TvDataSearchCmListHistory
     */
    cmSponsors?: Array<TvDataSearchCmListHistoryCmSponsors>;
    /**
     * CM商品リスト
     * @type {Array<TvDataSearchCmListHistoryCmProducts>}
     * @memberof TvDataSearchCmListHistory
     */
    cmProducts?: Array<TvDataSearchCmListHistoryCmProducts>;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    targetType: TvDataSearchCmListHistoryTargetTypeEnum;
    /**
     * 
     * @type {Array<TvDataSearchCmListHistoryTargets>}
     * @memberof TvDataSearchCmListHistory
     */
    targets?: Array<TvDataSearchCmListHistoryTargets>;
    /**
     * CM種別
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    buyingKind?: TvDataSearchCmListHistoryBuyingKindEnum;
    /**
     * CM秒数
     * @type {number}
     * @memberof TvDataSearchCmListHistory
     */
    cmDuration?: number;
    /**
     * 放送局リスト
     * @type {Array<string>}
     * @memberof TvDataSearchCmListHistory
     */
    stations: Array<string>;
    /**
     * 
     * @type {Array<TvDataSearchCmListHistoryProgramSeries>}
     * @memberof TvDataSearchCmListHistory
     */
    programSeries?: Array<TvDataSearchCmListHistoryProgramSeries>;
    /**
     * 検索開始時刻（05:00～28:59）
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    startTime: string;
    /**
     * 検索終了時刻（05:00～28:59）
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    endTime: string;
    /**
     * 検索日時
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    searchedAt: string;
    /**
     * 履歴の商品が変更されていない場合にtrue、変更されてたらfalse
     * @type {boolean}
     * @memberof TvDataSearchCmListHistory
     */
    isProductUnchanged: boolean;
    /**
     * 履歴のカスタムターゲットが削除されてない場合にtrue、削除されてたらfalse
     * @type {boolean}
     * @memberof TvDataSearchCmListHistory
     */
    isCustomTargetNotDeleted: boolean;
    /**
     * 15秒換算の有無
     * @type {boolean}
     * @memberof TvDataSearchCmListHistory
     */
    isConversion15sec?: boolean;
    /**
     * 視聴種別
     * @type {string}
     * @memberof TvDataSearchCmListHistory
     */
    viewingType: TvDataSearchCmListHistoryViewingTypeEnum;
    /**
     * 
     * @type {Array<TvDataSearchCmListHistoryCmCategories>}
     * @memberof TvDataSearchCmListHistory
     */
    cmCategories?: Array<TvDataSearchCmListHistoryCmCategories>;
}

/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmListHistoryTargetTypeEnum {
    IndividualAndHousehold = 'INDIVIDUAL_AND_HOUSEHOLD',
    BasicGa12 = 'BASIC_GA12',
    BasicGa10S = 'BASIC_GA10S',
    Custom = 'CUSTOM'
}
/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmListHistoryBuyingKindEnum {
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmListHistoryViewingTypeEnum {
    Realtime = 'REALTIME',
    Total = 'TOTAL'
}

/**
 * 
 * @export
 * @interface TvDataSearchCmListHistoryCmCategories
 */
export interface TvDataSearchCmListHistoryCmCategories {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListHistoryCmCategories
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListHistoryCmCategories
     */
    level: number;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListHistoryCmCategories
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmListHistoryCmCategories
     */
    tag: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmListHistoryCmProducts
 */
export interface TvDataSearchCmListHistoryCmProducts {
    /**
     * CM商品ID
     * @type {number}
     * @memberof TvDataSearchCmListHistoryCmProducts
     */
    cmProductId?: number;
    /**
     * CM商品名
     * @type {string}
     * @memberof TvDataSearchCmListHistoryCmProducts
     */
    cmProductName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmListHistoryCmSponsors
 */
export interface TvDataSearchCmListHistoryCmSponsors {
    /**
     * CM出稿企業ID
     * @type {number}
     * @memberof TvDataSearchCmListHistoryCmSponsors
     */
    cmSponsorId?: number;
    /**
     * CM企業名
     * @type {string}
     * @memberof TvDataSearchCmListHistoryCmSponsors
     */
    cmSponsorName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmListHistoryProgramSeries
 */
export interface TvDataSearchCmListHistoryProgramSeries {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmListHistoryProgramSeries
     */
    programSeriesId?: number;
    /**
     * 番組名
     * @type {string}
     * @memberof TvDataSearchCmListHistoryProgramSeries
     */
    programName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmListHistoryTargets
 */
export interface TvDataSearchCmListHistoryTargets {
    /**
     * ターゲットID
     * @type {number}
     * @memberof TvDataSearchCmListHistoryTargets
     */
    targetId?: number;
    /**
     * ターゲット名
     * @type {string}
     * @memberof TvDataSearchCmListHistoryTargets
     */
    targetName?: string;
}
/**
 * TVデータサーチ用CM企業情報の返却モデル
 * @export
 * @interface TvDataSearchCmSponsor
 */
export interface TvDataSearchCmSponsor {
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmSponsor
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmSponsor
     */
    id: number;
}
/**
 * TVデータサーチ用CM R&Fの検索履歴に表示する情報
 * @export
 * @interface TvDataSearchCmrfHistories
 */
export interface TvDataSearchCmrfHistories {
    /**
     * トータル件数
     * @type {number}
     * @memberof TvDataSearchCmrfHistories
     */
    totalCount: number;
    /**
     * 
     * @type {Array<TvDataSearchCmrfHistory>}
     * @memberof TvDataSearchCmrfHistories
     */
    list: Array<TvDataSearchCmrfHistory>;
}
/**
 * TVデータサーチ用CM期間集計の検索履歴情報
 * @export
 * @interface TvDataSearchCmrfHistory
 */
export interface TvDataSearchCmrfHistory {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmrfHistory
     */
    id: number;
    /**
     * エリアコード
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    areaCode: string;
    /**
     * 検索開始日
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    startDate: string;
    /**
     * 検索終了日
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    endDate: string;
    /**
     * CM出稿企業リスト
     * @type {Array<TvDataSearchCmrfHistoryCmSponsors>}
     * @memberof TvDataSearchCmrfHistory
     */
    cmSponsors?: Array<TvDataSearchCmrfHistoryCmSponsors>;
    /**
     * CM商品リスト
     * @type {Array<TvDataSearchCmrfHistoryCmProducts>}
     * @memberof TvDataSearchCmrfHistory
     */
    cmProducts?: Array<TvDataSearchCmrfHistoryCmProducts>;
    /**
     * 
     * @type {Array<TvDataSearchCmrfHistoryCmCreatives>}
     * @memberof TvDataSearchCmrfHistory
     */
    cmCreatives?: Array<TvDataSearchCmrfHistoryCmCreatives>;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    targetType: TvDataSearchCmrfHistoryTargetTypeEnum;
    /**
     * 
     * @type {Array<TvDataSearchCmrfHistoryTargets>}
     * @memberof TvDataSearchCmrfHistory
     */
    targets?: Array<TvDataSearchCmrfHistoryTargets>;
    /**
     * CM種別
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    buyingKind?: TvDataSearchCmrfHistoryBuyingKindEnum;
    /**
     * CM秒数
     * @type {number}
     * @memberof TvDataSearchCmrfHistory
     */
    cmDuration?: number;
    /**
     * 放送局リスト
     * @type {Array<string>}
     * @memberof TvDataSearchCmrfHistory
     */
    stations: Array<string>;
    /**
     * 
     * @type {Array<TvDataSearchCmrfHistoryProgramSeries>}
     * @memberof TvDataSearchCmrfHistory
     */
    programSeries?: Array<TvDataSearchCmrfHistoryProgramSeries>;
    /**
     * 検索開始時刻（05:00～28:59）
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    startTime: string;
    /**
     * 検索終了時刻（05:00～28:59）
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    endTime: string;
    /**
     * 検索日時
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    searchedAt: string;
    /**
     * 履歴の商品が変更されていない場合にtrue、変更されてたらfalse
     * @type {boolean}
     * @memberof TvDataSearchCmrfHistory
     */
    isProductUnchanged: boolean;
    /**
     * 履歴のカスタムターゲットが削除されてない場合にtrue、削除されてたらfalse
     * @type {boolean}
     * @memberof TvDataSearchCmrfHistory
     */
    isCustomTargetNotDeleted: boolean;
    /**
     * 15秒換算にするならtrue、しないならfalse
     * @type {boolean}
     * @memberof TvDataSearchCmrfHistory
     */
    isConversion15sec?: boolean;
    /**
     * 視聴種別
     * @type {string}
     * @memberof TvDataSearchCmrfHistory
     */
    viewingType: TvDataSearchCmrfHistoryViewingTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmrfHistoryTargetTypeEnum {
    IndividualAndHousehold = 'INDIVIDUAL_AND_HOUSEHOLD',
    BasicGa12 = 'BASIC_GA12',
    BasicGa10S = 'BASIC_GA10S',
    Custom = 'CUSTOM'
}
/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmrfHistoryBuyingKindEnum {
    Time = 'TIME',
    Spot = 'SPOT'
}
/**
    * @export
    * @enum {string}
    */
export enum TvDataSearchCmrfHistoryViewingTypeEnum {
    Realtime = 'REALTIME',
    Total = 'TOTAL'
}

/**
 * 
 * @export
 * @interface TvDataSearchCmrfHistoryCmCreatives
 */
export interface TvDataSearchCmrfHistoryCmCreatives {
    /**
     * CM素材ID
     * @type {number}
     * @memberof TvDataSearchCmrfHistoryCmCreatives
     */
    cmCreativeId?: number;
    /**
     * CM秒数
     * @type {number}
     * @memberof TvDataSearchCmrfHistoryCmCreatives
     */
    cmDuration?: number;
    /**
     * CM内容
     * @type {string}
     * @memberof TvDataSearchCmrfHistoryCmCreatives
     */
    cmSituation?: string;
    /**
     * 出演者
     * @type {string}
     * @memberof TvDataSearchCmrfHistoryCmCreatives
     */
    cmTalent?: string;
    /**
     * BGM
     * @type {string}
     * @memberof TvDataSearchCmrfHistoryCmCreatives
     */
    cmBgm?: string;
    /**
     * 備考
     * @type {string}
     * @memberof TvDataSearchCmrfHistoryCmCreatives
     */
    cmRemarks?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmrfHistoryCmProducts
 */
export interface TvDataSearchCmrfHistoryCmProducts {
    /**
     * CM商品ID
     * @type {number}
     * @memberof TvDataSearchCmrfHistoryCmProducts
     */
    cmProductId?: number;
    /**
     * CM商品名
     * @type {string}
     * @memberof TvDataSearchCmrfHistoryCmProducts
     */
    cmProductName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmrfHistoryCmSponsors
 */
export interface TvDataSearchCmrfHistoryCmSponsors {
    /**
     * CM出稿企業ID
     * @type {number}
     * @memberof TvDataSearchCmrfHistoryCmSponsors
     */
    cmSponsorId?: number;
    /**
     * CM企業名
     * @type {string}
     * @memberof TvDataSearchCmrfHistoryCmSponsors
     */
    cmSponsorName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmrfHistoryProgramSeries
 */
export interface TvDataSearchCmrfHistoryProgramSeries {
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchCmrfHistoryProgramSeries
     */
    programSeriesId?: number;
    /**
     * 番組名
     * @type {string}
     * @memberof TvDataSearchCmrfHistoryProgramSeries
     */
    programName?: string;
}
/**
 * 
 * @export
 * @interface TvDataSearchCmrfHistoryTargets
 */
export interface TvDataSearchCmrfHistoryTargets {
    /**
     * ターゲットID
     * @type {number}
     * @memberof TvDataSearchCmrfHistoryTargets
     */
    targetId?: number;
    /**
     * ターゲット名
     * @type {string}
     * @memberof TvDataSearchCmrfHistoryTargets
     */
    targetName?: string;
}
/**
 * TVデータサーチ用商品情報の返却モデル
 * @export
 * @interface TvDataSearchProduct
 */
export interface TvDataSearchProduct {
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchProduct
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchProduct
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchProduct
     */
    latestBroadcastDate: string;
}
/**
 * TVデータサーチ用番組情報の返却モデル
 * @export
 * @interface TvDataSearchProgram
 */
export interface TvDataSearchProgram {
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchProgram
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TvDataSearchProgram
     */
    programSeriesId: number;
}
/**
 * TVデータサーチ用番組リスト情報の返却モデル
 * @export
 * @interface TvDataSearchProgramList
 */
export interface TvDataSearchProgramList {
    /**
     * 
     * @type {Array<ProgramListBaseData>}
     * @memberof TvDataSearchProgramList
     */
    baseData: Array<ProgramListBaseData>;
    /**
     * 
     * @type {Array<{ [key: string]: number; }>}
     * @memberof TvDataSearchProgramList
     */
    viewingRateData: Array<{ [key: string]: number; }>;
    /**
     * 
     * @type {Array<{ [key: string]: number; }>}
     * @memberof TvDataSearchProgramList
     */
    contentRateData: Array<{ [key: string]: number; }>;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchProgramList
     */
    reportCreateDateTime: string;
}
/**
 * TVデータサーチ用番組リスト情報の返却モデル
 * @export
 * @interface TvDataSearchProgramPeriodAverage
 */
export interface TvDataSearchProgramPeriodAverage {
    /**
     * 
     * @type {Array<ProgramPeriodAverageBaseData>}
     * @memberof TvDataSearchProgramPeriodAverage
     */
    baseData: Array<ProgramPeriodAverageBaseData>;
    /**
     * 
     * @type {Array<{ [key: string]: number; }>}
     * @memberof TvDataSearchProgramPeriodAverage
     */
    viewingRateData: Array<{ [key: string]: number; }>;
    /**
     * 
     * @type {Array<{ [key: string]: number; }>}
     * @memberof TvDataSearchProgramPeriodAverage
     */
    contentRateData: Array<{ [key: string]: number; }>;
    /**
     * 
     * @type {string}
     * @memberof TvDataSearchProgramPeriodAverage
     */
    reportCreateDateTime: string;
}
/**
 * 番組情報
 * @export
 * @interface TvProgram
 */
export interface TvProgram {
    /**
     * 番組名
     * @type {string}
     * @memberof TvProgram
     */
    name: string;
    /**
     * 番組開始時刻(YYYY-MM-DD HH:MM)
     * @type {string}
     * @memberof TvProgram
     */
    from: string;
    /**
     * 番組終了時刻(YYYY-MM-DD HH:MM)
     * @type {string}
     * @memberof TvProgram
     */
    to: string;
}
/**
 * 番組表
 * @export
 * @interface TvProgramTable
 */
export interface TvProgramTable {
    /**
     * 放送局コード
     * @type {string}
     * @memberof TvProgramTable
     */
    stationCode: string;
    /**
     * 番組情報配列 (fromの)時刻昇順で格納
     * @type {Array<TvProgram>}
     * @memberof TvProgramTable
     */
    programs: Array<TvProgram>;
}
/**
 * テレビ視聴人口
 * @export
 * @interface TvViewingNumber
 */
export interface TvViewingNumber {
    /**
     * 
     * @type {number}
     * @memberof TvViewingNumber
     */
    number: number;
}
/**
 * カンパニーステータス更新用Form
 * @export
 * @interface UpdateCompanyStatusForm
 */
export interface UpdateCompanyStatusForm {
    /**
     * ステータス (DELETED)
     * @type {string}
     * @memberof UpdateCompanyStatusForm
     */
    status: UpdateCompanyStatusFormStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateCompanyStatusFormStatusEnum {
    Deleted = 'DELETED'
}

/**
 * 利用枠の決まっているサービスの利用状況
 * @export
 * @interface UsageSituation
 */
export interface UsageSituation {
    /**
     * 
     * @type {number}
     * @memberof UsageSituation
     */
    inUse: number;
    /**
     * 
     * @type {number}
     * @memberof UsageSituation
     */
    limit: number;
}
/**
 * ログイン中ユーザー情報
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    phoneNo: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    userDescription: string;
    /**
     * 
     * @type {Array<JoinWorkspace>}
     * @memberof UserInfo
     */
    workspaces: Array<JoinWorkspace>;
}
/**
 * バリデーションエラー用オブジェクト
 * @export
 * @interface ValidationErrors
 */
export interface ValidationErrors {
    /**
     * 
     * @type {Array<ErrorMsg>}
     * @memberof ValidationErrors
     */
    errors?: Array<ErrorMsg>;
}
/**
 * 各放送局の視聴回数
 * @export
 * @interface Views
 */
export interface Views {
    /**
     * 
     * @type {Array<ViewsNumbersOfViewing>}
     * @memberof Views
     */
    numbersOfViewing: Array<ViewsNumbersOfViewing>;
}
/**
 * 
 * @export
 * @interface ViewsNumbersOfViewing
 */
export interface ViewsNumbersOfViewing {
    /**
     * 
     * @type {string}
     * @memberof ViewsNumbersOfViewing
     */
    stationCode: string;
    /**
     * 
     * @type {string}
     * @memberof ViewsNumbersOfViewing
     */
    stationName: string;
    /**
     * 放送局並び順
     * @type {number}
     * @memberof ViewsNumbersOfViewing
     */
    stationOrder: number;
    /**
     * 
     * @type {number}
     * @memberof ViewsNumbersOfViewing
     */
    numberOfViewing: number;
}
/**
 * CMセールスインパクト週次購入増加額推移グラフの返却モデル
 * @export
 * @interface WeeklyPurchaseIncreaseGraph
 */
export interface WeeklyPurchaseIncreaseGraph {
    /**
     * 
     * @type {string}
     * @memberof WeeklyPurchaseIncreaseGraph
     */
    weeklyDate: string;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreaseGraph
     */
    totalPurchaseAmount: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreaseGraph
     */
    totalPurchaseAmountWithoutCm: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreaseGraph
     */
    cmEffect: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreaseGraph
     */
    reachRate: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreaseGraph
     */
    reachNumberOfPeople: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreaseGraph
     */
    grp: number;
}
/**
 * CMセールスインパクト一人あたり週次購入増加額推移グラフの返却モデル
 * @export
 * @interface WeeklyPurchaseIncreasePerPersonGraph
 */
export interface WeeklyPurchaseIncreasePerPersonGraph {
    /**
     * 
     * @type {string}
     * @memberof WeeklyPurchaseIncreasePerPersonGraph
     */
    weeklyDate: string;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreasePerPersonGraph
     */
    cmReachPurchaseAmount: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreasePerPersonGraph
     */
    cmUnreachedPurchaseAmount: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreasePerPersonGraph
     */
    cmReachPurchaseAmountWithoutCm: number;
    /**
     * 
     * @type {number}
     * @memberof WeeklyPurchaseIncreasePerPersonGraph
     */
    cmEffect: number;
}
/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    workspaceType: WorkspaceWorkspaceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    status: WorkspaceStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    paymentType: WorkspacePaymentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    numOfUserLicenses: number;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    numOfCompanyLicenses: number;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    numOfCustomTargetLicenses: number;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceWorkspaceTypeEnum {
    AdCompany = 'AD_COMPANY',
    CmCompany = 'CM_COMPANY',
    Broadcaster = 'BROADCASTER'
}
/**
    * @export
    * @enum {string}
    */
export enum WorkspaceStatusEnum {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE',
    Archived = 'ARCHIVED',
    Deleted = 'DELETED',
    Disabled = 'DISABLED'
}
/**
    * @export
    * @enum {string}
    */
export enum WorkspacePaymentTypeEnum {
    Invalid = 'INVALID',
    Free = 'FREE',
    Paid = 'PAID'
}

/**
 * 該当ワークスペース上でのアカウント情報
 * @export
 * @interface WorkspaceAccountProfile
 */
export interface WorkspaceAccountProfile {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceAccountProfile
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAccountProfile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAccountProfile
     */
    accountPrivilege: WorkspaceAccountProfileAccountPrivilegeEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAccountProfile
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAccountProfile
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAccountProfile
     */
    nickName: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAccountProfile
     */
    phoneNo: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceAccountProfile
     */
    userDescription: string;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceAccountProfileAccountPrivilegeEnum {
    PrimaryOwner = 'PRIMARY_OWNER',
    Owner = 'OWNER',
    Manager = 'MANAGER',
    Member = 'MEMBER',
    Restricted = 'RESTRICTED',
    FreePrimaryOwner = 'FREE_PRIMARY_OWNER',
    FreeMember = 'FREE_MEMBER'
}

/**
 * ワークスペースTOPの左側に表示する基本情報
 * @export
 * @interface WorkspaceBasic
 */
export interface WorkspaceBasic {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceBasic
     */
    workspaceId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceBasic
     */
    workspaceName: string;
    /**
     * 
     * @type {WorkspaceBasicMemberList}
     * @memberof WorkspaceBasic
     */
    memberList: WorkspaceBasicMemberList;
}
/**
 * 
 * @export
 * @interface WorkspaceBasicMemberList
 */
export interface WorkspaceBasicMemberList {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceBasicMemberList
     */
    owner: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceBasicMemberList
     */
    manager: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceBasicMemberList
     */
    member: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceBasicMemberList
     */
    restricted: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceBasicMemberList
     */
    freeOwner: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceBasicMemberList
     */
    freeMember: number;
}
/**
 * 
 * @export
 * @interface WorkspaceDomain
 */
export interface WorkspaceDomain {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDomain
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDomain
     */
    workspaceId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDomain
     */
    domainName: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDomain
     */
    definitionType: WorkspaceDomainDefinitionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDomain
     */
    approveType: WorkspaceDomainApproveTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDomain
     */
    applicationStatus?: WorkspaceDomainApplicationStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceDomainDefinitionTypeEnum {
    Contract = 'CONTRACT',
    Preference = 'PREFERENCE',
    Invalid = 'INVALID'
}
/**
    * @export
    * @enum {string}
    */
export enum WorkspaceDomainApproveTypeEnum {
    Automatic = 'AUTOMATIC',
    Manual = 'MANUAL',
    Invalid = 'INVALID'
}
/**
    * @export
    * @enum {string}
    */
export enum WorkspaceDomainApplicationStatusEnum {
    Submitted = 'SUBMITTED',
    Approved = 'APPROVED',
    Invalid = 'INVALID'
}

/**
 * 
 * @export
 * @interface WorkspaceDomainChangeApplication
 */
export interface WorkspaceDomainChangeApplication {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDomainChangeApplication
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDomainChangeApplication
     */
    workspaceId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDomainChangeApplication
     */
    applicationStatus: WorkspaceDomainChangeApplicationApplicationStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDomainChangeApplication
     */
    submitAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDomainChangeApplication
     */
    approveAccountId?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDomainChangeApplication
     */
    oldDomainName: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDomainChangeApplication
     */
    newDomainName: string;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceDomainChangeApplicationApplicationStatusEnum {
    Invalid = 'INVALID',
    Submitted = 'SUBMITTED',
    Approved = 'APPROVED'
}

/**
 * ワークスペースドメインのリストモデル
 * @export
 * @interface WorkspaceDomainList
 */
export interface WorkspaceDomainList {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDomainList
     */
    totalCount: number;
    /**
     * 
     * @type {Array<WorkspaceDomain>}
     * @memberof WorkspaceDomainList
     */
    list: Array<WorkspaceDomain>;
}
/**
 * オプション機能情報
 * @export
 * @interface WorkspaceFeatureOption
 */
export interface WorkspaceFeatureOption {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceFeatureOption
     */
    type: WorkspaceFeatureOptionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceFeatureOption
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceFeatureOption
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceFeatureOption
     */
    status: WorkspaceFeatureOptionStatusEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkspaceFeatureOption
     */
    companyIds?: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceFeatureOptionTypeEnum {
    PurchaseLog = 'PURCHASE_LOG',
    Area = 'AREA',
    CmSalesImpact = 'CM_SALES_IMPACT',
    Tro = 'TRO',
    ReachSimulator = 'REACH_SIMULATOR',
    CmSalesImpactSimulator = 'CM_SALES_IMPACT_SIMULATOR',
    ViewingTypeTotal = 'VIEWING_TYPE_TOTAL'
}
/**
    * @export
    * @enum {string}
    */
export enum WorkspaceFeatureOptionStatusEnum {
    Disabled = 'DISABLED',
    Enabled = 'ENABLED',
    Expired = 'EXPIRED',
    Finished = 'FINISHED',
    Cancelled = 'CANCELLED'
}

/**
 * 
 * @export
 * @interface WorkspaceName
 */
export interface WorkspaceName {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceName
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceName
     */
    name: string;
}
/**
 * ワークスペース開設申請通知アカウントのモデル
 * @export
 * @interface WorkspaceOpeningApplicationFollower
 */
export interface WorkspaceOpeningApplicationFollower {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceOpeningApplicationFollower
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceOpeningApplicationFollower
     */
    workspaceOpeningApplicationId: number;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceOpeningApplicationFollower
     */
    accountId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceOpeningApplicationFollower
     */
    createdAt: string;
}
/**
 * ワークスペース開設申請のForm
 * @export
 * @interface WorkspaceOpeningApplicationForm
 */
export interface WorkspaceOpeningApplicationForm {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceOpeningApplicationForm
     */
    businessType: WorkspaceOpeningApplicationFormBusinessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceOpeningApplicationForm
     */
    companyName?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceOpeningApplicationForm
     */
    cmSponsorId?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceOpeningApplicationForm
     */
    area: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceOpeningApplicationForm
     */
    departmentName?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceOpeningApplicationForm
     */
    phoneNo: string;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceOpeningApplicationFormBusinessTypeEnum {
    Advertiser = 'ADVERTISER',
    AdvertisingAgency = 'ADVERTISING_AGENCY',
    Broadcaster = 'BROADCASTER'
}

/**
 * ワークスペース開設申請状況のモデル
 * @export
 * @interface WorkspaceOpeningApplicationStatus
 */
export interface WorkspaceOpeningApplicationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceOpeningApplicationStatus
     */
    submitted: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceOpeningApplicationStatus
     */
    applicationStatus?: WorkspaceOpeningApplicationStatusApplicationStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceOpeningApplicationStatusApplicationStatusEnum {
    Submitted = 'SUBMITTED',
    InReview = 'IN_REVIEW',
    Approved = 'APPROVED'
}

/**
 * ワークスペースの利用状況
 * @export
 * @interface WorkspaceUsageSituation
 */
export interface WorkspaceUsageSituation {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceUsageSituation
     */
    workspaceId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceUsageSituation
     */
    paymentType: WorkspaceUsageSituationPaymentTypeEnum;
    /**
     * 
     * @type {UsageSituation}
     * @memberof WorkspaceUsageSituation
     */
    userLicenses: UsageSituation;
    /**
     * 
     * @type {UsageSituation}
     * @memberof WorkspaceUsageSituation
     */
    companyLicenses: UsageSituation;
    /**
     * 
     * @type {UsageSituation}
     * @memberof WorkspaceUsageSituation
     */
    customTargetLicenses: UsageSituation;
}

/**
    * @export
    * @enum {string}
    */
export enum WorkspaceUsageSituationPaymentTypeEnum {
    Invalid = 'INVALID',
    Paid = 'PAID',
    Free = 'FREE'
}


/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 最近見たリーチ分析プロジェクトの一覧を取得するAPI
         * @summary 最近見たリーチ分析プロジェクト一覧取得
         * @param {number} accountId 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdAccessProjects: async (accountId: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdAccessProjects', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/access-projects`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントのアバター画像を取得する
         * @summary アカウントアバター画像取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdAvatarImage: async (accountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdAvatarImage', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/avatar-image`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントのドメインに紐づく所有（契約ドメイン）ワークスペース、関連（許可ドメイン）ワークスペースの一覧を取得する
         * @summary 参加可能ワークスペース取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdJoinableWorkspaces: async (accountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdJoinableWorkspaces', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/joinable-workspaces`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントの詳細情報を登録する
         * @summary アカウント登録
         * @param {CreateAccountForm} [createAccountForm] アカウント登録用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccounts: async (createAccountForm?: CreateAccountForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAccountForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 既存のワークスペースに対して、アカウントを参加させるAPI
         * @summary アカウント別ワークスペース参加
         * @param {number} accountId 
         * @param {ParticipateWorkspaceForm} [participateWorkspaceForm] 参加ワークスペースのForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountsAccountIdWorkspaces: async (accountId: number, participateWorkspaceForm?: ParticipateWorkspaceForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('postAccountsAccountIdWorkspaces', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/workspaces`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participateWorkspaceForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールアドレスによるアカウントのサインアップを行う
         * @summary サインアップ
         * @param {SignupForm} [signupForm] サインアップ用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignup: async (signupForm?: SignupForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signupForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントのアバター画像を設定する
         * @summary アカウントアバター画像設定
         * @param {number} accountId 
         * @param {object} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAccountsAccountIdAvatarImage: async (accountId: number, file?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('putAccountsAccountIdAvatarImage', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/avatar-image`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 最近見たリーチ分析プロジェクトの一覧を取得するAPI
         * @summary 最近見たリーチ分析プロジェクト一覧取得
         * @param {number} accountId 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdAccessProjects(accountId: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessProjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdAccessProjects(accountId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントのアバター画像を取得する
         * @summary アカウントアバター画像取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdAvatarImage(accountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdAvatarImage(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントのドメインに紐づく所有（契約ドメイン）ワークスペース、関連（許可ドメイン）ワークスペースの一覧を取得する
         * @summary 参加可能ワークスペース取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdJoinableWorkspaces(accountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JoinableWorkspaces>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdJoinableWorkspaces(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントの詳細情報を登録する
         * @summary アカウント登録
         * @param {CreateAccountForm} [createAccountForm] アカウント登録用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccounts(createAccountForm?: CreateAccountForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccounts(createAccountForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 既存のワークスペースに対して、アカウントを参加させるAPI
         * @summary アカウント別ワークスペース参加
         * @param {number} accountId 
         * @param {ParticipateWorkspaceForm} [participateWorkspaceForm] 参加ワークスペースのForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccountsAccountIdWorkspaces(accountId: number, participateWorkspaceForm?: ParticipateWorkspaceForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipateWorkspaceForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccountsAccountIdWorkspaces(accountId, participateWorkspaceForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールアドレスによるアカウントのサインアップを行う
         * @summary サインアップ
         * @param {SignupForm} [signupForm] サインアップ用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSignup(signupForm?: SignupForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignupForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSignup(signupForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントのアバター画像を設定する
         * @summary アカウントアバター画像設定
         * @param {number} accountId 
         * @param {object} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAccountsAccountIdAvatarImage(accountId: number, file?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAccountsAccountIdAvatarImage(accountId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 最近見たリーチ分析プロジェクトの一覧を取得するAPI
         * @summary 最近見たリーチ分析プロジェクト一覧取得
         * @param {number} accountId 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdAccessProjects(accountId: number, limit?: number, offset?: number, options?: any): AxiosPromise<AccessProjectList> {
            return localVarFp.getAccountsAccountIdAccessProjects(accountId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントのアバター画像を取得する
         * @summary アカウントアバター画像取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdAvatarImage(accountId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getAccountsAccountIdAvatarImage(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントのドメインに紐づく所有（契約ドメイン）ワークスペース、関連（許可ドメイン）ワークスペースの一覧を取得する
         * @summary 参加可能ワークスペース取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdJoinableWorkspaces(accountId: number, options?: any): AxiosPromise<JoinableWorkspaces> {
            return localVarFp.getAccountsAccountIdJoinableWorkspaces(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントの詳細情報を登録する
         * @summary アカウント登録
         * @param {CreateAccountForm} [createAccountForm] アカウント登録用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccounts(createAccountForm?: CreateAccountForm, options?: any): AxiosPromise<PostAccountResponse> {
            return localVarFp.postAccounts(createAccountForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 既存のワークスペースに対して、アカウントを参加させるAPI
         * @summary アカウント別ワークスペース参加
         * @param {number} accountId 
         * @param {ParticipateWorkspaceForm} [participateWorkspaceForm] 参加ワークスペースのForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountsAccountIdWorkspaces(accountId: number, participateWorkspaceForm?: ParticipateWorkspaceForm, options?: any): AxiosPromise<ParticipateWorkspaceForm> {
            return localVarFp.postAccountsAccountIdWorkspaces(accountId, participateWorkspaceForm, options).then((request) => request(axios, basePath));
        },
        /**
         * メールアドレスによるアカウントのサインアップを行う
         * @summary サインアップ
         * @param {SignupForm} [signupForm] サインアップ用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignup(signupForm?: SignupForm, options?: any): AxiosPromise<SignupForm> {
            return localVarFp.postSignup(signupForm, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントのアバター画像を設定する
         * @summary アカウントアバター画像設定
         * @param {number} accountId 
         * @param {object} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAccountsAccountIdAvatarImage(accountId: number, file?: object, options?: any): AxiosPromise<void> {
            return localVarFp.putAccountsAccountIdAvatarImage(accountId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 最近見たリーチ分析プロジェクトの一覧を取得するAPI
     * @summary 最近見たリーチ分析プロジェクト一覧取得
     * @param {number} accountId 
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountsAccountIdAccessProjects(accountId: number, limit?: number, offset?: number, options?: any) {
        return AccountApiFp(this.configuration).getAccountsAccountIdAccessProjects(accountId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントのアバター画像を取得する
     * @summary アカウントアバター画像取得
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountsAccountIdAvatarImage(accountId: number, options?: any) {
        return AccountApiFp(this.configuration).getAccountsAccountIdAvatarImage(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントのドメインに紐づく所有（契約ドメイン）ワークスペース、関連（許可ドメイン）ワークスペースの一覧を取得する
     * @summary 参加可能ワークスペース取得
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountsAccountIdJoinableWorkspaces(accountId: number, options?: any) {
        return AccountApiFp(this.configuration).getAccountsAccountIdJoinableWorkspaces(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントの詳細情報を登録する
     * @summary アカウント登録
     * @param {CreateAccountForm} [createAccountForm] アカウント登録用Form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postAccounts(createAccountForm?: CreateAccountForm, options?: any) {
        return AccountApiFp(this.configuration).postAccounts(createAccountForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 既存のワークスペースに対して、アカウントを参加させるAPI
     * @summary アカウント別ワークスペース参加
     * @param {number} accountId 
     * @param {ParticipateWorkspaceForm} [participateWorkspaceForm] 参加ワークスペースのForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postAccountsAccountIdWorkspaces(accountId: number, participateWorkspaceForm?: ParticipateWorkspaceForm, options?: any) {
        return AccountApiFp(this.configuration).postAccountsAccountIdWorkspaces(accountId, participateWorkspaceForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールアドレスによるアカウントのサインアップを行う
     * @summary サインアップ
     * @param {SignupForm} [signupForm] サインアップ用Form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public postSignup(signupForm?: SignupForm, options?: any) {
        return AccountApiFp(this.configuration).postSignup(signupForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントのアバター画像を設定する
     * @summary アカウントアバター画像設定
     * @param {number} accountId 
     * @param {object} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public putAccountsAccountIdAvatarImage(accountId: number, file?: object, options?: any) {
        return AccountApiFp(this.configuration).putAccountsAccountIdAvatarImage(accountId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AreaApi - axios parameter creator
 * @export
 */
export const AreaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * エリアのモニタ構成を取得する
         * @summary エリアモニタ構成取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdAreasMonitorStructure: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdAreasMonitorStructure', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/areas/monitor-structure`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AreaApi - functional programming interface
 * @export
 */
export const AreaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AreaApiAxiosParamCreator(configuration)
    return {
        /**
         * エリアのモニタ構成を取得する
         * @summary エリアモニタ構成取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdAreasMonitorStructure(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaMonitorStructureList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdAreasMonitorStructure(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AreaApi - factory interface
 * @export
 */
export const AreaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AreaApiFp(configuration)
    return {
        /**
         * エリアのモニタ構成を取得する
         * @summary エリアモニタ構成取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdAreasMonitorStructure(companyId: number, options?: any): AxiosPromise<AreaMonitorStructureList> {
            return localVarFp.getCompaniesCompanyIdAreasMonitorStructure(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AreaApi - object-oriented interface
 * @export
 * @class AreaApi
 * @extends {BaseAPI}
 */
export class AreaApi extends BaseAPI {
    /**
     * エリアのモニタ構成を取得する
     * @summary エリアモニタ構成取得
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AreaApi
     */
    public getCompaniesCompanyIdAreasMonitorStructure(companyId: number, options?: any) {
        return AreaApiFp(this.configuration).getCompaniesCompanyIdAreasMonitorStructure(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ログインしているかどうかのチェック
         * @summary ログインチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/login-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログアウトする。
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * ログインしているかどうかのチェック
         * @summary ログインチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログアウトする。
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLogout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * ログインしているかどうかのチェック
         * @summary ログインチェック
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginStatus(options?: any): AxiosPromise<void> {
            return localVarFp.getLoginStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * ログアウトする。
         * @summary ログアウト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLogout(options?: any): AxiosPromise<void> {
            return localVarFp.postLogout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * ログインしているかどうかのチェック
     * @summary ログインチェック
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getLoginStatus(options?: any) {
        return AuthApiFp(this.configuration).getLoginStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログアウトする。
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postLogout(options?: any) {
        return AuthApiFp(this.configuration).postLogout(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BasicTargetApi - axios parameter creator
 * @export
 */
export const BasicTargetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 基本属性ターゲットの一覧を取得する
         * @summary 基本属性ターゲット一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicTargets: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/basic-targets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本属性ターゲットのモニタ構成を取得する
         * @summary 基本属性ターゲットモニタ構成取得
         * @param {number} companyId 
         * @param {'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC'} basicDivisionType 基本属性区分タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdBasicTargetsMonitorStructure: async (companyId: number, basicDivisionType: 'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdBasicTargetsMonitorStructure', 'companyId', companyId)
            // verify required parameter 'basicDivisionType' is not null or undefined
            assertParamExists('getCompaniesCompanyIdBasicTargetsMonitorStructure', 'basicDivisionType', basicDivisionType)
            const localVarPath = `/companies/{companyId}/basic-targets/monitor-structure`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (basicDivisionType !== undefined) {
                localVarQueryParameter['basicDivisionType'] = basicDivisionType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasicTargetApi - functional programming interface
 * @export
 */
export const BasicTargetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BasicTargetApiAxiosParamCreator(configuration)
    return {
        /**
         * 基本属性ターゲットの一覧を取得する
         * @summary 基本属性ターゲット一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBasicTargets(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicDivisoinList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBasicTargets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本属性ターゲットのモニタ構成を取得する
         * @summary 基本属性ターゲットモニタ構成取得
         * @param {number} companyId 
         * @param {'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC'} basicDivisionType 基本属性区分タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdBasicTargetsMonitorStructure(companyId: number, basicDivisionType: 'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicTargetMonitorStructureList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdBasicTargetsMonitorStructure(companyId, basicDivisionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BasicTargetApi - factory interface
 * @export
 */
export const BasicTargetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BasicTargetApiFp(configuration)
    return {
        /**
         * 基本属性ターゲットの一覧を取得する
         * @summary 基本属性ターゲット一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicTargets(options?: any): AxiosPromise<BasicDivisoinList> {
            return localVarFp.getBasicTargets(options).then((request) => request(axios, basePath));
        },
        /**
         * 基本属性ターゲットのモニタ構成を取得する
         * @summary 基本属性ターゲットモニタ構成取得
         * @param {number} companyId 
         * @param {'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC'} basicDivisionType 基本属性区分タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdBasicTargetsMonitorStructure(companyId: number, basicDivisionType: 'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC', options?: any): AxiosPromise<BasicTargetMonitorStructureList> {
            return localVarFp.getCompaniesCompanyIdBasicTargetsMonitorStructure(companyId, basicDivisionType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BasicTargetApi - object-oriented interface
 * @export
 * @class BasicTargetApi
 * @extends {BaseAPI}
 */
export class BasicTargetApi extends BaseAPI {
    /**
     * 基本属性ターゲットの一覧を取得する
     * @summary 基本属性ターゲット一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicTargetApi
     */
    public getBasicTargets(options?: any) {
        return BasicTargetApiFp(this.configuration).getBasicTargets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本属性ターゲットのモニタ構成を取得する
     * @summary 基本属性ターゲットモニタ構成取得
     * @param {number} companyId 
     * @param {'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC'} basicDivisionType 基本属性区分タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicTargetApi
     */
    public getCompaniesCompanyIdBasicTargetsMonitorStructure(companyId: number, basicDivisionType: 'GA8' | 'GA12' | 'GA10S' | 'GM' | 'OC', options?: any) {
        return BasicTargetApiFp(this.configuration).getCompaniesCompanyIdBasicTargetsMonitorStructure(companyId, basicDivisionType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CmCategoryApi - axios parameter creator
 * @export
 */
export const CmCategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 商品カテゴリを取得する
         * @summary 商品カテゴリ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmCategories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cm-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CmCategoryApi - functional programming interface
 * @export
 */
export const CmCategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CmCategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 商品カテゴリを取得する
         * @summary 商品カテゴリ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmCategories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CmCategoryApi - factory interface
 * @export
 */
export const CmCategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CmCategoryApiFp(configuration)
    return {
        /**
         * 商品カテゴリを取得する
         * @summary 商品カテゴリ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmCategories(options?: any): AxiosPromise<Array<CmCategory>> {
            return localVarFp.getCmCategories(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CmCategoryApi - object-oriented interface
 * @export
 * @class CmCategoryApi
 * @extends {BaseAPI}
 */
export class CmCategoryApi extends BaseAPI {
    /**
     * 商品カテゴリを取得する
     * @summary 商品カテゴリ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmCategoryApi
     */
    public getCmCategories(options?: any) {
        return CmCategoryApiFp(this.configuration).getCmCategories(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CmSalesImpactApi - axios parameter creator
 * @export
 */
export const CmSalesImpactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を削除する
         * @summary CMセールスインパクト最終アクセス商品ページ情報削除
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmSalesImpactLastAccessProduct: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCmSalesImpactLastAccessProduct', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/last-access-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトのレポートを削除する
         * @summary CMセールスインパクトのレポート削除
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmSalesImpactReportsReportId: async (reportId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteCmSalesImpactReportsReportId', 'reportId', reportId)
            const localVarPath = `/cm-sales-impact/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクト利用可能エリアを取得する
         * @summary CMセールスインパクト利用可能エリア取得
         * @param {number} companyId カンパニーID
         * @param {number} productId 商品ブランドID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactAvailableAreas: async (companyId: number, productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactAvailableAreas', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getCmSalesImpactAvailableAreas', 'productId', productId)
            const localVarPath = `/cm-sales-impact/available-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトグラフデータを取得する
         * @summary CMセールスインパクトグラフデータ取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {number} [reportId] レポートID
         * @param {string} [startDate] 期間開始日
         * @param {string} [endDate] 期間終了日
         * @param {number} [reachWeeks] リーチ基準週
         * @param {number} [reachFrequency] リーチ基準頻度
         * @param {string} [areaCode] エリアコード
         * @param {boolean} [isCmsis] CMSISフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactGraphData: async (productId: number, companyId: number, reportId?: number, startDate?: string, endDate?: string, reachWeeks?: number, reachFrequency?: number, areaCode?: string, isCmsis?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getCmSalesImpactGraphData', 'productId', productId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactGraphData', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/graph-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (reportId !== undefined) {
                localVarQueryParameter['reportId'] = reportId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (reachWeeks !== undefined) {
                localVarQueryParameter['reachWeeks'] = reachWeeks;
            }

            if (reachFrequency !== undefined) {
                localVarQueryParameter['reachFrequency'] = reachFrequency;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (isCmsis !== undefined) {
                localVarQueryParameter['isCmsis'] = isCmsis;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトのCM効果相関ヒートマップデータを取得する
         * @summary CMセールスインパクトのCM効果相関ヒートマップデータ取得
         * @param {number} cmsiProductGroupId CMSI商品グループID
         * @param {number} companyId カンパニーID
         * @param {Array<string>} [startDates] 期間開始日リスト
         * @param {Array<string>} [endDates] 期間終了日リスト
         * @param {string} [areaCode] エリアコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactHeatmap: async (cmsiProductGroupId: number, companyId: number, startDates?: Array<string>, endDates?: Array<string>, areaCode?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmsiProductGroupId' is not null or undefined
            assertParamExists('getCmSalesImpactHeatmap', 'cmsiProductGroupId', cmsiProductGroupId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactHeatmap', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/heatmap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmsiProductGroupId !== undefined) {
                localVarQueryParameter['cmsiProductGroupId'] = cmsiProductGroupId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (startDates) {
                localVarQueryParameter['startDates'] = startDates;
            }

            if (endDates) {
                localVarQueryParameter['endDates'] = endDates;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を取得する
         * @summary CMセールスインパクト最終アクセス商品ページ情報取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactLastAccessProduct: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactLastAccessProduct', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/last-access-product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクト商品一覧を取得する
         * @summary CMセールスインパクト商品一覧取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactProducts: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactProducts', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクト商品ページ詳細情報を取得する
         * @summary CMセールスインパクト商品ページ詳細情報取得
         * @param {number} productId 
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactProductsProductIdDetails: async (productId: number, companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getCmSalesImpactProductsProductIdDetails', 'productId', productId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactProductsProductIdDetails', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/products/{productId}/details`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトレポート一覧を取得する
         * @summary CMセールスインパクトレポート一覧取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactReports: async (productId: number, companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getCmSalesImpactReports', 'productId', productId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactReports', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトレポート詳細情報を取得する
         * @summary CMセールスインパクトレポート詳細情報取得
         * @param {number} reportId 
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactReportsReportIdDetails: async (reportId: number, companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getCmSalesImpactReportsReportIdDetails', 'reportId', reportId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactReportsReportIdDetails', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/reports/{reportId}/details`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクト週次KPIサマリー情報を取得する
         * @summary CMセールスインパクト週次KPIサマリー情報取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactWeeklyKpiSummary: async (productId: number, companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getCmSalesImpactWeeklyKpiSummary', 'productId', productId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactWeeklyKpiSummary', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact/weekly-kpi-summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクト商品ページのレポート設定を登録する
         * @summary CMセールスインパクト商品ページのレポート設定登録
         * @param {CmSalesImpactReportSettingsForm} [cmSalesImpactReportSettingsForm] CMセールスインパクト商品レポート設定登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmSalesImpactReportSettings: async (cmSalesImpactReportSettingsForm?: CmSalesImpactReportSettingsForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cm-sales-impact/report-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cmSalesImpactReportSettingsForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトのレポートを登録する
         * @summary CMセールスインパクトレポート登録
         * @param {CmSalesImpactReportsForm} [cmSalesImpactReportsForm] CMセールスインパクト商品レポート登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmSalesImpactReports: async (cmSalesImpactReportsForm?: CmSalesImpactReportsForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cm-sales-impact/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cmSalesImpactReportsForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を更新する
         * @summary CMセールスインパクト最終アクセス商品ページ情報更新
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmSalesImpactLastAccessProductProductId: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('putCmSalesImpactLastAccessProductProductId', 'productId', productId)
            const localVarPath = `/cm-sales-impact/last-access-product/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトのレポートを更新する
         * @summary CMセールスインパクトのレポート更新
         * @param {number} reportId 
         * @param {CmSalesImpactReportsForm} [cmSalesImpactReportsForm] CMセールスインパクト商品レポート登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmSalesImpactReportsReportId: async (reportId: number, cmSalesImpactReportsForm?: CmSalesImpactReportsForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('putCmSalesImpactReportsReportId', 'reportId', reportId)
            const localVarPath = `/cm-sales-impact/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cmSalesImpactReportsForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CmSalesImpactApi - functional programming interface
 * @export
 */
export const CmSalesImpactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CmSalesImpactApiAxiosParamCreator(configuration)
    return {
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を削除する
         * @summary CMセールスインパクト最終アクセス商品ページ情報削除
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCmSalesImpactLastAccessProduct(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCmSalesImpactLastAccessProduct(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトのレポートを削除する
         * @summary CMセールスインパクトのレポート削除
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCmSalesImpactReportsReportId(reportId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCmSalesImpactReportsReportId(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクト利用可能エリアを取得する
         * @summary CMセールスインパクト利用可能エリア取得
         * @param {number} companyId カンパニーID
         * @param {number} productId 商品ブランドID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactAvailableAreas(companyId: number, productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmSalesImpactAvailableArea>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactAvailableAreas(companyId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトグラフデータを取得する
         * @summary CMセールスインパクトグラフデータ取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {number} [reportId] レポートID
         * @param {string} [startDate] 期間開始日
         * @param {string} [endDate] 期間終了日
         * @param {number} [reachWeeks] リーチ基準週
         * @param {number} [reachFrequency] リーチ基準頻度
         * @param {string} [areaCode] エリアコード
         * @param {boolean} [isCmsis] CMSISフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactGraphData(productId: number, companyId: number, reportId?: number, startDate?: string, endDate?: string, reachWeeks?: number, reachFrequency?: number, areaCode?: string, isCmsis?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactGraphData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactGraphData(productId, companyId, reportId, startDate, endDate, reachWeeks, reachFrequency, areaCode, isCmsis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトのCM効果相関ヒートマップデータを取得する
         * @summary CMセールスインパクトのCM効果相関ヒートマップデータ取得
         * @param {number} cmsiProductGroupId CMSI商品グループID
         * @param {number} companyId カンパニーID
         * @param {Array<string>} [startDates] 期間開始日リスト
         * @param {Array<string>} [endDates] 期間終了日リスト
         * @param {string} [areaCode] エリアコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactHeatmap(cmsiProductGroupId: number, companyId: number, startDates?: Array<string>, endDates?: Array<string>, areaCode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmSalesImpactCorrelation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactHeatmap(cmsiProductGroupId, companyId, startDates, endDates, areaCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を取得する
         * @summary CMセールスインパクト最終アクセス商品ページ情報取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactLastAccessProduct(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactLastAccessProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactLastAccessProduct(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクト商品一覧を取得する
         * @summary CMセールスインパクト商品一覧取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactProducts(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmSalesImpactProducts>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactProducts(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクト商品ページ詳細情報を取得する
         * @summary CMセールスインパクト商品ページ詳細情報取得
         * @param {number} productId 
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactProductsProductIdDetails(productId: number, companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactProductsDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactProductsProductIdDetails(productId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトレポート一覧を取得する
         * @summary CMセールスインパクトレポート一覧取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactReports(productId: number, companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmSalesImpactReports>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactReports(productId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトレポート詳細情報を取得する
         * @summary CMセールスインパクトレポート詳細情報取得
         * @param {number} reportId 
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactReportsReportIdDetails(reportId: number, companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactReportsDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactReportsReportIdDetails(reportId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクト週次KPIサマリー情報を取得する
         * @summary CMセールスインパクト週次KPIサマリー情報取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactWeeklyKpiSummary(productId: number, companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactWeeklyKpiSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactWeeklyKpiSummary(productId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクト商品ページのレポート設定を登録する
         * @summary CMセールスインパクト商品ページのレポート設定登録
         * @param {CmSalesImpactReportSettingsForm} [cmSalesImpactReportSettingsForm] CMセールスインパクト商品レポート設定登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCmSalesImpactReportSettings(cmSalesImpactReportSettingsForm?: CmSalesImpactReportSettingsForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactReportSettingsForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCmSalesImpactReportSettings(cmSalesImpactReportSettingsForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトのレポートを登録する
         * @summary CMセールスインパクトレポート登録
         * @param {CmSalesImpactReportsForm} [cmSalesImpactReportsForm] CMセールスインパクト商品レポート登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCmSalesImpactReports(cmSalesImpactReportsForm?: CmSalesImpactReportsForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactReportsPostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCmSalesImpactReports(cmSalesImpactReportsForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を更新する
         * @summary CMセールスインパクト最終アクセス商品ページ情報更新
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCmSalesImpactLastAccessProductProductId(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactLastAccessProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCmSalesImpactLastAccessProductProductId(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトのレポートを更新する
         * @summary CMセールスインパクトのレポート更新
         * @param {number} reportId 
         * @param {CmSalesImpactReportsForm} [cmSalesImpactReportsForm] CMセールスインパクト商品レポート登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCmSalesImpactReportsReportId(reportId: number, cmSalesImpactReportsForm?: CmSalesImpactReportsForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactReportsForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCmSalesImpactReportsReportId(reportId, cmSalesImpactReportsForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CmSalesImpactApi - factory interface
 * @export
 */
export const CmSalesImpactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CmSalesImpactApiFp(configuration)
    return {
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を削除する
         * @summary CMセールスインパクト最終アクセス商品ページ情報削除
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmSalesImpactLastAccessProduct(companyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCmSalesImpactLastAccessProduct(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトのレポートを削除する
         * @summary CMセールスインパクトのレポート削除
         * @param {number} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmSalesImpactReportsReportId(reportId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCmSalesImpactReportsReportId(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクト利用可能エリアを取得する
         * @summary CMセールスインパクト利用可能エリア取得
         * @param {number} companyId カンパニーID
         * @param {number} productId 商品ブランドID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactAvailableAreas(companyId: number, productId: number, options?: any): AxiosPromise<Array<CmSalesImpactAvailableArea>> {
            return localVarFp.getCmSalesImpactAvailableAreas(companyId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトグラフデータを取得する
         * @summary CMセールスインパクトグラフデータ取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {number} [reportId] レポートID
         * @param {string} [startDate] 期間開始日
         * @param {string} [endDate] 期間終了日
         * @param {number} [reachWeeks] リーチ基準週
         * @param {number} [reachFrequency] リーチ基準頻度
         * @param {string} [areaCode] エリアコード
         * @param {boolean} [isCmsis] CMSISフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactGraphData(productId: number, companyId: number, reportId?: number, startDate?: string, endDate?: string, reachWeeks?: number, reachFrequency?: number, areaCode?: string, isCmsis?: boolean, options?: any): AxiosPromise<CmSalesImpactGraphData> {
            return localVarFp.getCmSalesImpactGraphData(productId, companyId, reportId, startDate, endDate, reachWeeks, reachFrequency, areaCode, isCmsis, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトのCM効果相関ヒートマップデータを取得する
         * @summary CMセールスインパクトのCM効果相関ヒートマップデータ取得
         * @param {number} cmsiProductGroupId CMSI商品グループID
         * @param {number} companyId カンパニーID
         * @param {Array<string>} [startDates] 期間開始日リスト
         * @param {Array<string>} [endDates] 期間終了日リスト
         * @param {string} [areaCode] エリアコード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactHeatmap(cmsiProductGroupId: number, companyId: number, startDates?: Array<string>, endDates?: Array<string>, areaCode?: string, options?: any): AxiosPromise<Array<CmSalesImpactCorrelation>> {
            return localVarFp.getCmSalesImpactHeatmap(cmsiProductGroupId, companyId, startDates, endDates, areaCode, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を取得する
         * @summary CMセールスインパクト最終アクセス商品ページ情報取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactLastAccessProduct(companyId: number, options?: any): AxiosPromise<CmSalesImpactLastAccessProduct> {
            return localVarFp.getCmSalesImpactLastAccessProduct(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクト商品一覧を取得する
         * @summary CMセールスインパクト商品一覧取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactProducts(companyId: number, options?: any): AxiosPromise<Array<CmSalesImpactProducts>> {
            return localVarFp.getCmSalesImpactProducts(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクト商品ページ詳細情報を取得する
         * @summary CMセールスインパクト商品ページ詳細情報取得
         * @param {number} productId 
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactProductsProductIdDetails(productId: number, companyId: number, options?: any): AxiosPromise<CmSalesImpactProductsDetails> {
            return localVarFp.getCmSalesImpactProductsProductIdDetails(productId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトレポート一覧を取得する
         * @summary CMセールスインパクトレポート一覧取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactReports(productId: number, companyId: number, options?: any): AxiosPromise<Array<CmSalesImpactReports>> {
            return localVarFp.getCmSalesImpactReports(productId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトレポート詳細情報を取得する
         * @summary CMセールスインパクトレポート詳細情報取得
         * @param {number} reportId 
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactReportsReportIdDetails(reportId: number, companyId: number, options?: any): AxiosPromise<CmSalesImpactReportsDetails> {
            return localVarFp.getCmSalesImpactReportsReportIdDetails(reportId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクト週次KPIサマリー情報を取得する
         * @summary CMセールスインパクト週次KPIサマリー情報取得
         * @param {number} productId 商品ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactWeeklyKpiSummary(productId: number, companyId: number, options?: any): AxiosPromise<CmSalesImpactWeeklyKpiSummary> {
            return localVarFp.getCmSalesImpactWeeklyKpiSummary(productId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクト商品ページのレポート設定を登録する
         * @summary CMセールスインパクト商品ページのレポート設定登録
         * @param {CmSalesImpactReportSettingsForm} [cmSalesImpactReportSettingsForm] CMセールスインパクト商品レポート設定登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmSalesImpactReportSettings(cmSalesImpactReportSettingsForm?: CmSalesImpactReportSettingsForm, options?: any): AxiosPromise<CmSalesImpactReportSettingsForm> {
            return localVarFp.postCmSalesImpactReportSettings(cmSalesImpactReportSettingsForm, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトのレポートを登録する
         * @summary CMセールスインパクトレポート登録
         * @param {CmSalesImpactReportsForm} [cmSalesImpactReportsForm] CMセールスインパクト商品レポート登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmSalesImpactReports(cmSalesImpactReportsForm?: CmSalesImpactReportsForm, options?: any): AxiosPromise<CmSalesImpactReportsPostResponse> {
            return localVarFp.postCmSalesImpactReports(cmSalesImpactReportsForm, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクト最終アクセス商品ページ情報を更新する
         * @summary CMセールスインパクト最終アクセス商品ページ情報更新
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmSalesImpactLastAccessProductProductId(productId: number, options?: any): AxiosPromise<CmSalesImpactLastAccessProduct> {
            return localVarFp.putCmSalesImpactLastAccessProductProductId(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトのレポートを更新する
         * @summary CMセールスインパクトのレポート更新
         * @param {number} reportId 
         * @param {CmSalesImpactReportsForm} [cmSalesImpactReportsForm] CMセールスインパクト商品レポート登録用のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmSalesImpactReportsReportId(reportId: number, cmSalesImpactReportsForm?: CmSalesImpactReportsForm, options?: any): AxiosPromise<CmSalesImpactReportsForm> {
            return localVarFp.putCmSalesImpactReportsReportId(reportId, cmSalesImpactReportsForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CmSalesImpactApi - object-oriented interface
 * @export
 * @class CmSalesImpactApi
 * @extends {BaseAPI}
 */
export class CmSalesImpactApi extends BaseAPI {
    /**
     * CMセールスインパクト最終アクセス商品ページ情報を削除する
     * @summary CMセールスインパクト最終アクセス商品ページ情報削除
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public deleteCmSalesImpactLastAccessProduct(companyId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).deleteCmSalesImpactLastAccessProduct(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトのレポートを削除する
     * @summary CMセールスインパクトのレポート削除
     * @param {number} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public deleteCmSalesImpactReportsReportId(reportId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).deleteCmSalesImpactReportsReportId(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクト利用可能エリアを取得する
     * @summary CMセールスインパクト利用可能エリア取得
     * @param {number} companyId カンパニーID
     * @param {number} productId 商品ブランドID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactAvailableAreas(companyId: number, productId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactAvailableAreas(companyId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトグラフデータを取得する
     * @summary CMセールスインパクトグラフデータ取得
     * @param {number} productId 商品ID
     * @param {number} companyId カンパニーID
     * @param {number} [reportId] レポートID
     * @param {string} [startDate] 期間開始日
     * @param {string} [endDate] 期間終了日
     * @param {number} [reachWeeks] リーチ基準週
     * @param {number} [reachFrequency] リーチ基準頻度
     * @param {string} [areaCode] エリアコード
     * @param {boolean} [isCmsis] CMSISフラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactGraphData(productId: number, companyId: number, reportId?: number, startDate?: string, endDate?: string, reachWeeks?: number, reachFrequency?: number, areaCode?: string, isCmsis?: boolean, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactGraphData(productId, companyId, reportId, startDate, endDate, reachWeeks, reachFrequency, areaCode, isCmsis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトのCM効果相関ヒートマップデータを取得する
     * @summary CMセールスインパクトのCM効果相関ヒートマップデータ取得
     * @param {number} cmsiProductGroupId CMSI商品グループID
     * @param {number} companyId カンパニーID
     * @param {Array<string>} [startDates] 期間開始日リスト
     * @param {Array<string>} [endDates] 期間終了日リスト
     * @param {string} [areaCode] エリアコード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactHeatmap(cmsiProductGroupId: number, companyId: number, startDates?: Array<string>, endDates?: Array<string>, areaCode?: string, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactHeatmap(cmsiProductGroupId, companyId, startDates, endDates, areaCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクト最終アクセス商品ページ情報を取得する
     * @summary CMセールスインパクト最終アクセス商品ページ情報取得
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactLastAccessProduct(companyId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactLastAccessProduct(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクト商品一覧を取得する
     * @summary CMセールスインパクト商品一覧取得
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactProducts(companyId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactProducts(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクト商品ページ詳細情報を取得する
     * @summary CMセールスインパクト商品ページ詳細情報取得
     * @param {number} productId 
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactProductsProductIdDetails(productId: number, companyId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactProductsProductIdDetails(productId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトレポート一覧を取得する
     * @summary CMセールスインパクトレポート一覧取得
     * @param {number} productId 商品ID
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactReports(productId: number, companyId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactReports(productId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトレポート詳細情報を取得する
     * @summary CMセールスインパクトレポート詳細情報取得
     * @param {number} reportId 
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactReportsReportIdDetails(reportId: number, companyId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactReportsReportIdDetails(reportId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクト週次KPIサマリー情報を取得する
     * @summary CMセールスインパクト週次KPIサマリー情報取得
     * @param {number} productId 商品ID
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public getCmSalesImpactWeeklyKpiSummary(productId: number, companyId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).getCmSalesImpactWeeklyKpiSummary(productId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクト商品ページのレポート設定を登録する
     * @summary CMセールスインパクト商品ページのレポート設定登録
     * @param {CmSalesImpactReportSettingsForm} [cmSalesImpactReportSettingsForm] CMセールスインパクト商品レポート設定登録用のForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public postCmSalesImpactReportSettings(cmSalesImpactReportSettingsForm?: CmSalesImpactReportSettingsForm, options?: any) {
        return CmSalesImpactApiFp(this.configuration).postCmSalesImpactReportSettings(cmSalesImpactReportSettingsForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトのレポートを登録する
     * @summary CMセールスインパクトレポート登録
     * @param {CmSalesImpactReportsForm} [cmSalesImpactReportsForm] CMセールスインパクト商品レポート登録用のForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public postCmSalesImpactReports(cmSalesImpactReportsForm?: CmSalesImpactReportsForm, options?: any) {
        return CmSalesImpactApiFp(this.configuration).postCmSalesImpactReports(cmSalesImpactReportsForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクト最終アクセス商品ページ情報を更新する
     * @summary CMセールスインパクト最終アクセス商品ページ情報更新
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public putCmSalesImpactLastAccessProductProductId(productId: number, options?: any) {
        return CmSalesImpactApiFp(this.configuration).putCmSalesImpactLastAccessProductProductId(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトのレポートを更新する
     * @summary CMセールスインパクトのレポート更新
     * @param {number} reportId 
     * @param {CmSalesImpactReportsForm} [cmSalesImpactReportsForm] CMセールスインパクト商品レポート登録用のForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactApi
     */
    public putCmSalesImpactReportsReportId(reportId: number, cmSalesImpactReportsForm?: CmSalesImpactReportsForm, options?: any) {
        return CmSalesImpactApiFp(this.configuration).putCmSalesImpactReportsReportId(reportId, cmSalesImpactReportsForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CmSalesImpactSimulatorApi - axios parameter creator
 * @export
 */
export const CmSalesImpactSimulatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを削除する
         * @summary CMセールスインパクトシミュレーターのプロジェクト削除
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId: async (cmsisProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmsisProjectId' is not null or undefined
            assertParamExists('deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId', 'cmsisProjectId', cmsisProjectId)
            const localVarPath = `/cm-sales-impact-simulator/cmsis-projects/{cmsisProjectId}`
                .replace(`{${"cmsisProjectId"}}`, encodeURIComponent(String(cmsisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクト一覧を取得する
         * @summary CMセールスインパクトシミュレータープロジェクト一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactSimulatorCmsisProjects: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmSalesImpactSimulatorCmsisProjects', 'companyId', companyId)
            const localVarPath = `/cm-sales-impact-simulator/cmsis-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトシミュレーターのシミュレーション条件を取得する
         * @summary CMセールスインパクトシミュレーターのシミュレーション条件取得
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition: async (cmsisProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmsisProjectId' is not null or undefined
            assertParamExists('getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition', 'cmsisProjectId', cmsisProjectId)
            const localVarPath = `/cm-sales-impact-simulator/cmsis-projects/{cmsisProjectId}/condition`
                .replace(`{${"cmsisProjectId"}}`, encodeURIComponent(String(cmsisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトシミュレーターのシミュレーション表データを取得する
         * @summary CMセールスインパクトシミュレーターのシミュレーション表データ取得
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult: async (cmsisProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmsisProjectId' is not null or undefined
            assertParamExists('getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult', 'cmsisProjectId', cmsisProjectId)
            const localVarPath = `/cm-sales-impact-simulator/cmsis-projects/{cmsisProjectId}/result`
                .replace(`{${"cmsisProjectId"}}`, encodeURIComponent(String(cmsisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを登録する
         * @summary CMセールスインパクトシミュレータープロジェクト登録
         * @param {CmSalesImpactSimulatorProjectForm} [cmSalesImpactSimulatorProjectForm] CMセールスインパクトシミュレータープロジェクト用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmSalesImpactSimulatorCmsisProjects: async (cmSalesImpactSimulatorProjectForm?: CmSalesImpactSimulatorProjectForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cm-sales-impact-simulator/cmsis-projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cmSalesImpactSimulatorProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを更新する
         * @summary CMセールスインパクトシミュレーターのプロジェクト更新
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {CmSalesImpactSimulatorProjectForm} [cmSalesImpactSimulatorProjectForm] CMセールスインパクトシミュレータープロジェクト用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId: async (cmsisProjectId: number, cmSalesImpactSimulatorProjectForm?: CmSalesImpactSimulatorProjectForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmsisProjectId' is not null or undefined
            assertParamExists('putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId', 'cmsisProjectId', cmsisProjectId)
            const localVarPath = `/cm-sales-impact-simulator/cmsis-projects/{cmsisProjectId}`
                .replace(`{${"cmsisProjectId"}}`, encodeURIComponent(String(cmsisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cmSalesImpactSimulatorProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CmSalesImpactSimulatorApi - functional programming interface
 * @export
 */
export const CmSalesImpactSimulatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CmSalesImpactSimulatorApiAxiosParamCreator(configuration)
    return {
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを削除する
         * @summary CMセールスインパクトシミュレーターのプロジェクト削除
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクト一覧を取得する
         * @summary CMセールスインパクトシミュレータープロジェクト一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactSimulatorCmsisProjects(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CmSalesImpactSimulatorProjectList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactSimulatorCmsisProjects(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトシミュレーターのシミュレーション条件を取得する
         * @summary CMセールスインパクトシミュレーターのシミュレーション条件取得
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition(cmsisProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactSimulatorCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition(cmsisProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトシミュレーターのシミュレーション表データを取得する
         * @summary CMセールスインパクトシミュレーターのシミュレーション表データ取得
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult(cmsisProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactSimulatorResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult(cmsisProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを登録する
         * @summary CMセールスインパクトシミュレータープロジェクト登録
         * @param {CmSalesImpactSimulatorProjectForm} [cmSalesImpactSimulatorProjectForm] CMセールスインパクトシミュレータープロジェクト用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCmSalesImpactSimulatorCmsisProjects(cmSalesImpactSimulatorProjectForm?: CmSalesImpactSimulatorProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactSimulatorProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCmSalesImpactSimulatorCmsisProjects(cmSalesImpactSimulatorProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを更新する
         * @summary CMセールスインパクトシミュレーターのプロジェクト更新
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {CmSalesImpactSimulatorProjectForm} [cmSalesImpactSimulatorProjectForm] CMセールスインパクトシミュレータープロジェクト用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId: number, cmSalesImpactSimulatorProjectForm?: CmSalesImpactSimulatorProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSalesImpactSimulatorProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId, cmSalesImpactSimulatorProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CmSalesImpactSimulatorApi - factory interface
 * @export
 */
export const CmSalesImpactSimulatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CmSalesImpactSimulatorApiFp(configuration)
    return {
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを削除する
         * @summary CMセールスインパクトシミュレーターのプロジェクト削除
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクト一覧を取得する
         * @summary CMセールスインパクトシミュレータープロジェクト一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactSimulatorCmsisProjects(companyId: number, options?: any): AxiosPromise<Array<CmSalesImpactSimulatorProjectList>> {
            return localVarFp.getCmSalesImpactSimulatorCmsisProjects(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトシミュレーターのシミュレーション条件を取得する
         * @summary CMセールスインパクトシミュレーターのシミュレーション条件取得
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition(cmsisProjectId: number, options?: any): AxiosPromise<CmSalesImpactSimulatorCondition> {
            return localVarFp.getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition(cmsisProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトシミュレーターのシミュレーション表データを取得する
         * @summary CMセールスインパクトシミュレーターのシミュレーション表データ取得
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult(cmsisProjectId: number, options?: any): AxiosPromise<CmSalesImpactSimulatorResult> {
            return localVarFp.getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult(cmsisProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを登録する
         * @summary CMセールスインパクトシミュレータープロジェクト登録
         * @param {CmSalesImpactSimulatorProjectForm} [cmSalesImpactSimulatorProjectForm] CMセールスインパクトシミュレータープロジェクト用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmSalesImpactSimulatorCmsisProjects(cmSalesImpactSimulatorProjectForm?: CmSalesImpactSimulatorProjectForm, options?: any): AxiosPromise<CmSalesImpactSimulatorProject> {
            return localVarFp.postCmSalesImpactSimulatorCmsisProjects(cmSalesImpactSimulatorProjectForm, options).then((request) => request(axios, basePath));
        },
        /**
         * CMセールスインパクトシミュレーターのプロジェクトを更新する
         * @summary CMセールスインパクトシミュレーターのプロジェクト更新
         * @param {number} cmsisProjectId CMSISプロジェクトID
         * @param {CmSalesImpactSimulatorProjectForm} [cmSalesImpactSimulatorProjectForm] CMセールスインパクトシミュレータープロジェクト用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId: number, cmSalesImpactSimulatorProjectForm?: CmSalesImpactSimulatorProjectForm, options?: any): AxiosPromise<CmSalesImpactSimulatorProject> {
            return localVarFp.putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId, cmSalesImpactSimulatorProjectForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CmSalesImpactSimulatorApi - object-oriented interface
 * @export
 * @class CmSalesImpactSimulatorApi
 * @extends {BaseAPI}
 */
export class CmSalesImpactSimulatorApi extends BaseAPI {
    /**
     * CMセールスインパクトシミュレーターのプロジェクトを削除する
     * @summary CMセールスインパクトシミュレーターのプロジェクト削除
     * @param {number} cmsisProjectId CMSISプロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactSimulatorApi
     */
    public deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId: number, options?: any) {
        return CmSalesImpactSimulatorApiFp(this.configuration).deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトシミュレーターのプロジェクト一覧を取得する
     * @summary CMセールスインパクトシミュレータープロジェクト一覧取得
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactSimulatorApi
     */
    public getCmSalesImpactSimulatorCmsisProjects(companyId: number, options?: any) {
        return CmSalesImpactSimulatorApiFp(this.configuration).getCmSalesImpactSimulatorCmsisProjects(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトシミュレーターのシミュレーション条件を取得する
     * @summary CMセールスインパクトシミュレーターのシミュレーション条件取得
     * @param {number} cmsisProjectId CMSISプロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactSimulatorApi
     */
    public getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition(cmsisProjectId: number, options?: any) {
        return CmSalesImpactSimulatorApiFp(this.configuration).getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdCondition(cmsisProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトシミュレーターのシミュレーション表データを取得する
     * @summary CMセールスインパクトシミュレーターのシミュレーション表データ取得
     * @param {number} cmsisProjectId CMSISプロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactSimulatorApi
     */
    public getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult(cmsisProjectId: number, options?: any) {
        return CmSalesImpactSimulatorApiFp(this.configuration).getCmSalesImpactSimulatorCmsisProjectsCmsisProjectIdResult(cmsisProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトシミュレーターのプロジェクトを登録する
     * @summary CMセールスインパクトシミュレータープロジェクト登録
     * @param {CmSalesImpactSimulatorProjectForm} [cmSalesImpactSimulatorProjectForm] CMセールスインパクトシミュレータープロジェクト用Form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactSimulatorApi
     */
    public postCmSalesImpactSimulatorCmsisProjects(cmSalesImpactSimulatorProjectForm?: CmSalesImpactSimulatorProjectForm, options?: any) {
        return CmSalesImpactSimulatorApiFp(this.configuration).postCmSalesImpactSimulatorCmsisProjects(cmSalesImpactSimulatorProjectForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMセールスインパクトシミュレーターのプロジェクトを更新する
     * @summary CMセールスインパクトシミュレーターのプロジェクト更新
     * @param {number} cmsisProjectId CMSISプロジェクトID
     * @param {CmSalesImpactSimulatorProjectForm} [cmSalesImpactSimulatorProjectForm] CMセールスインパクトシミュレータープロジェクト用Form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSalesImpactSimulatorApi
     */
    public putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId: number, cmSalesImpactSimulatorProjectForm?: CmSalesImpactSimulatorProjectForm, options?: any) {
        return CmSalesImpactSimulatorApiFp(this.configuration).putCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(cmsisProjectId, cmSalesImpactSimulatorProjectForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CmSponsorApi - axios parameter creator
 * @export
 */
export const CmSponsorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * CMスポンサーに紐づくCM商品の一覧を取得する
         * @summary CMスポンサー別CM商品一覧
         * @param {number} cmSponsorId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSponsorsCmSponsorIdCmProducts: async (cmSponsorId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmSponsorId' is not null or undefined
            assertParamExists('getCmSponsorsCmSponsorIdCmProducts', 'cmSponsorId', cmSponsorId)
            const localVarPath = `/cm-sponsors/{cmSponsorId}/cm-products`
                .replace(`{${"cmSponsorId"}}`, encodeURIComponent(String(cmSponsorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMスポンサーを検索する
         * @summary CMスポンサー検索
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [cmSponsorName] CMスポンサー名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSponsorsSearch: async (offset?: number, limit?: number, cmSponsorName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cm-sponsors/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cmSponsorName !== undefined) {
                localVarQueryParameter['cmSponsorName'] = cmSponsorName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CmSponsorApi - functional programming interface
 * @export
 */
export const CmSponsorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CmSponsorApiAxiosParamCreator(configuration)
    return {
        /**
         * CMスポンサーに紐づくCM商品の一覧を取得する
         * @summary CMスポンサー別CM商品一覧
         * @param {number} cmSponsorId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSponsorsCmSponsorIdCmProducts(cmSponsorId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSponsorCmProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSponsorsCmSponsorIdCmProducts(cmSponsorId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMスポンサーを検索する
         * @summary CMスポンサー検索
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [cmSponsorName] CMスポンサー名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmSponsorsSearch(offset?: number, limit?: number, cmSponsorName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSponsorList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmSponsorsSearch(offset, limit, cmSponsorName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CmSponsorApi - factory interface
 * @export
 */
export const CmSponsorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CmSponsorApiFp(configuration)
    return {
        /**
         * CMスポンサーに紐づくCM商品の一覧を取得する
         * @summary CMスポンサー別CM商品一覧
         * @param {number} cmSponsorId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSponsorsCmSponsorIdCmProducts(cmSponsorId: number, offset?: number, limit?: number, options?: any): AxiosPromise<CmSponsorCmProductList> {
            return localVarFp.getCmSponsorsCmSponsorIdCmProducts(cmSponsorId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * CMスポンサーを検索する
         * @summary CMスポンサー検索
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [cmSponsorName] CMスポンサー名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmSponsorsSearch(offset?: number, limit?: number, cmSponsorName?: string, options?: any): AxiosPromise<CmSponsorList> {
            return localVarFp.getCmSponsorsSearch(offset, limit, cmSponsorName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CmSponsorApi - object-oriented interface
 * @export
 * @class CmSponsorApi
 * @extends {BaseAPI}
 */
export class CmSponsorApi extends BaseAPI {
    /**
     * CMスポンサーに紐づくCM商品の一覧を取得する
     * @summary CMスポンサー別CM商品一覧
     * @param {number} cmSponsorId 
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSponsorApi
     */
    public getCmSponsorsCmSponsorIdCmProducts(cmSponsorId: number, offset?: number, limit?: number, options?: any) {
        return CmSponsorApiFp(this.configuration).getCmSponsorsCmSponsorIdCmProducts(cmSponsorId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMスポンサーを検索する
     * @summary CMスポンサー検索
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {string} [cmSponsorName] CMスポンサー名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmSponsorApi
     */
    public getCmSponsorsSearch(offset?: number, limit?: number, cmSponsorName?: string, options?: any) {
        return CmSponsorApiFp(this.configuration).getCmSponsorsSearch(offset, limit, cmSponsorName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * カンパニーからアカウントアクセス権限を削除
         * @summary アクセス権削除
         * @param {number} companyId 
         * @param {AccountAuthorityForm} [accountAuthorityForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompaniesCompanyIdAccountAuthorities: async (companyId: number, accountAuthorityForm?: AccountAuthorityForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompaniesCompanyIdAccountAuthorities', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/account-authorities`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountAuthorityForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニー別他社（競合含む自社以外）CM企業を削除する
         * @summary カンパニー別他社CM企業削除
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId: async (companyId: number, cmSponsorId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId', 'companyId', companyId)
            // verify required parameter 'cmSponsorId' is not null or undefined
            assertParamExists('deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId', 'cmSponsorId', cmSponsorId)
            const localVarPath = `/companies/{companyId}/product-cm-sponsors/{cmSponsorId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"cmSponsorId"}}`, encodeURIComponent(String(cmSponsorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 最後にアクセスしたカンパニー取得するAPI
         * @summary 最後にアクセスしたカンパニー取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdActiveCompany: async (accountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdActiveCompany', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/active-company`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニーに所属するアカウントを検索する
         * @summary カンパニー別アカウント検索
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード（メールアドレス、表示名、氏名）
         * @param {boolean} [privilegePrimaryOwner] プライマリーオーナー権限
         * @param {boolean} [privilegeOwner] オーナー権限
         * @param {boolean} [privilegeManager] 管理者権限
         * @param {boolean} [privilegeMember] メンバー権限
         * @param {boolean} [privilegeRestricted] 制限付き権限
         * @param {boolean} [privilegeFreePrimaryOwner] 無料プライマリーオーナー
         * @param {boolean} [privilegeFreeMember] 無料メンバー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdAccountsSearch: async (companyId: number, offset?: number, limit?: number, keyword?: string, privilegePrimaryOwner?: boolean, privilegeOwner?: boolean, privilegeManager?: boolean, privilegeMember?: boolean, privilegeRestricted?: boolean, privilegeFreePrimaryOwner?: boolean, privilegeFreeMember?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdAccountsSearch', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/accounts/search`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (privilegePrimaryOwner !== undefined) {
                localVarQueryParameter['privilegePrimaryOwner'] = privilegePrimaryOwner;
            }

            if (privilegeOwner !== undefined) {
                localVarQueryParameter['privilegeOwner'] = privilegeOwner;
            }

            if (privilegeManager !== undefined) {
                localVarQueryParameter['privilegeManager'] = privilegeManager;
            }

            if (privilegeMember !== undefined) {
                localVarQueryParameter['privilegeMember'] = privilegeMember;
            }

            if (privilegeRestricted !== undefined) {
                localVarQueryParameter['privilegeRestricted'] = privilegeRestricted;
            }

            if (privilegeFreePrimaryOwner !== undefined) {
                localVarQueryParameter['privilegeFreePrimaryOwner'] = privilegeFreePrimaryOwner;
            }

            if (privilegeFreeMember !== undefined) {
                localVarQueryParameter['privilegeFreeMember'] = privilegeFreeMember;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニー別分析利用中企業一覧を取得する
         * @summary カンパニー別分析利用中企業一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdAnalysisUsedCompanies: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdAnalysisUsedCompanies', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/analysis-used-companies`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニーに紐づくCM商品を検索するAPI
         * @summary カンパニー別CM商品検索
         * @param {number} companyId 
         * @param {string} startDate 開始日時
         * @param {string} endDate 終了日時
         * @param {'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME'} order 並び順
         * @param {string} [keyword] 検索キーワード(CM商品名)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCmProductsSearch: async (companyId: number, startDate: string, endDate: string, order: 'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME', keyword?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmProductsSearch', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmProductsSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmProductsSearch', 'endDate', endDate)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmProductsSearch', 'order', order)
            const localVarPath = `/companies/{companyId}/cm-products/search`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニーのCMスポンサー情報を取得する
         * @summary カンパニーのCMスポンサー情報取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCmSponsor: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmSponsor', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/cm-sponsor`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニー別CM企業一覧を取得する
         * @summary カンパニー別CM企業一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCmSponsors: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmSponsors', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/cm-sponsors`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニー内CM企業別商品／ブランド一覧を取得する
         * @summary カンパニー内CM企業別商品／ブランド一覧取得
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {string} startDate 期間開始日
         * @param {string} endDate 期間終了日
         * @param {boolean} isOther 他社（競合含む自社以外）フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts: async (companyId: number, cmSponsorId: number, startDate: string, endDate: string, isOther: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts', 'companyId', companyId)
            // verify required parameter 'cmSponsorId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts', 'cmSponsorId', cmSponsorId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts', 'endDate', endDate)
            // verify required parameter 'isOther' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts', 'isOther', isOther)
            const localVarPath = `/companies/{companyId}/cm-sponsors/{cmSponsorId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"cmSponsorId"}}`, encodeURIComponent(String(cmSponsorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (isOther !== undefined) {
                localVarQueryParameter['isOther'] = isOther;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニーに属する競合商品の一覧を取得する
         * @summary カンパニー別競合商品一覧
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCompetitiveProducts: async (companyId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCompetitiveProducts', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/competitive-products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 他のカンパニーに紐づくCM商品を検索するAPI
         * @summary 他社CM商品検索
         * @param {number} companyId 
         * @param {string} keyword 検索キーワード(CM商品名または企業名)
         * @param {'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME' | 'ORDER_PRODUCT_LIST'} order 並び順
         * @param {string} [startDate] 開始日時
         * @param {string} [endDate] 終了日時
         * @param {number} [cmSponsorId] CMスポンサーID(絞り込みたいCMスポンサーを指定した場合のみ設定)
         * @param {number} [productId] 商品ID(CM商品を表示したい競合商品のID)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdOtherCompanyCmProductsSearch: async (companyId: number, keyword: string, order: 'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME' | 'ORDER_PRODUCT_LIST', startDate?: string, endDate?: string, cmSponsorId?: number, productId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdOtherCompanyCmProductsSearch', 'companyId', companyId)
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getCompaniesCompanyIdOtherCompanyCmProductsSearch', 'keyword', keyword)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getCompaniesCompanyIdOtherCompanyCmProductsSearch', 'order', order)
            const localVarPath = `/companies/{companyId}/other-company-cm-products/search`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (cmSponsorId !== undefined) {
                localVarQueryParameter['cmSponsorId'] = cmSponsorId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニーに属する商品を検索する
         * @summary カンパニー別商品検索
         * @param {number} companyId 
         * @param {string} startDate 開始日時
         * @param {string} endDate 終了日時
         * @param {'CM_CREATIVE_DATE' | 'UPDATED_AT' | 'PRODUCT_NAME'} order 並び順
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード(商品名)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdProductsSearch: async (companyId: number, startDate: string, endDate: string, order: 'CM_CREATIVE_DATE' | 'UPDATED_AT' | 'PRODUCT_NAME', offset?: number, limit?: number, keyword?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdProductsSearch', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdProductsSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdProductsSearch', 'endDate', endDate)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getCompaniesCompanyIdProductsSearch', 'order', order)
            const localVarPath = `/companies/{companyId}/products/search`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニー設定取得
         * @summary カンパニー設定取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdSettings: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdSettings', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニーにアカウントアクセス権限を追加
         * @summary アクセス権追加
         * @param {number} companyId 
         * @param {AccountAuthorityForm} [accountAuthorityForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdAccountAuthorities: async (companyId: number, accountAuthorityForm?: AccountAuthorityForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdAccountAuthorities', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/account-authorities`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountAuthorityForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニー別他社（競合含む自社以外）CM企業を登録する
         * @summary カンパニー別他社CM企業登録
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdProductCmSponsorsCmSponsorId: async (companyId: number, cmSponsorId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdProductCmSponsorsCmSponsorId', 'companyId', companyId)
            // verify required parameter 'cmSponsorId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdProductCmSponsorsCmSponsorId', 'cmSponsorId', cmSponsorId)
            const localVarPath = `/companies/{companyId}/product-cm-sponsors/{cmSponsorId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"cmSponsorId"}}`, encodeURIComponent(String(cmSponsorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CM商品に紐づいた商品を登録する
         * @summary 商品登録
         * @param {number} companyId 
         * @param {CreateProductForm} [createProductForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdProducts: async (companyId: number, createProductForm?: CreateProductForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdProducts', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/products`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトに関する情報を新規登録する
         * @summary リーチ分析プロジェクト登録
         * @param {number} companyId 
         * @param {ReachAnalysisProjectForm} [reachAnalysisProjectForm] リーチ分析-プロジェクト登録・更新用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdReachAnalysisProjects: async (companyId: number, reachAnalysisProjectForm?: ReachAnalysisProjectForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdReachAnalysisProjects', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニー設定更新
         * @summary カンパニー設定更新
         * @param {number} companyId 
         * @param {ChangeCompanySettingsForm} [changeCompanySettingsForm] カンパニー設定のフォーム
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompaniesCompanyIdSettings: async (companyId: number, changeCompanySettingsForm?: ChangeCompanySettingsForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('putCompaniesCompanyIdSettings', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeCompanySettingsForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定のカンパニーを削除する
         * @summary カンパニーステータス更新
         * @param {number} companyId カンパニーID
         * @param {UpdateCompanyStatusForm} [updateCompanyStatusForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompaniesCompanyIdStatus: async (companyId: number, updateCompanyStatusForm?: UpdateCompanyStatusForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompaniesCompanyIdStatus', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/status`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyStatusForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * カンパニーからアカウントアクセス権限を削除
         * @summary アクセス権削除
         * @param {number} companyId 
         * @param {AccountAuthorityForm} [accountAuthorityForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompaniesCompanyIdAccountAuthorities(companyId: number, accountAuthorityForm?: AccountAuthorityForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompaniesCompanyIdAccountAuthorities(companyId, accountAuthorityForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニー別他社（競合含む自社以外）CM企業を削除する
         * @summary カンパニー別他社CM企業削除
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId: number, cmSponsorId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId, cmSponsorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 最後にアクセスしたカンパニー取得するAPI
         * @summary 最後にアクセスしたカンパニー取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdActiveCompany(accountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastActiveCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdActiveCompany(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニーに所属するアカウントを検索する
         * @summary カンパニー別アカウント検索
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード（メールアドレス、表示名、氏名）
         * @param {boolean} [privilegePrimaryOwner] プライマリーオーナー権限
         * @param {boolean} [privilegeOwner] オーナー権限
         * @param {boolean} [privilegeManager] 管理者権限
         * @param {boolean} [privilegeMember] メンバー権限
         * @param {boolean} [privilegeRestricted] 制限付き権限
         * @param {boolean} [privilegeFreePrimaryOwner] 無料プライマリーオーナー
         * @param {boolean} [privilegeFreeMember] 無料メンバー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdAccountsSearch(companyId: number, offset?: number, limit?: number, keyword?: string, privilegePrimaryOwner?: boolean, privilegeOwner?: boolean, privilegeManager?: boolean, privilegeMember?: boolean, privilegeRestricted?: boolean, privilegeFreePrimaryOwner?: boolean, privilegeFreeMember?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdAccountsSearch(companyId, offset, limit, keyword, privilegePrimaryOwner, privilegeOwner, privilegeManager, privilegeMember, privilegeRestricted, privilegeFreePrimaryOwner, privilegeFreeMember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニー別分析利用中企業一覧を取得する
         * @summary カンパニー別分析利用中企業一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdAnalysisUsedCompanies(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnalysisUsedCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdAnalysisUsedCompanies(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニーに紐づくCM商品を検索するAPI
         * @summary カンパニー別CM商品検索
         * @param {number} companyId 
         * @param {string} startDate 開始日時
         * @param {string} endDate 終了日時
         * @param {'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME'} order 並び順
         * @param {string} [keyword] 検索キーワード(CM商品名)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdCmProductsSearch(companyId: number, startDate: string, endDate: string, order: 'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME', keyword?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdCmProductsSearch(companyId, startDate, endDate, order, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニーのCMスポンサー情報を取得する
         * @summary カンパニーのCMスポンサー情報取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdCmSponsor(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmSponsorInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdCmSponsor(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニー別CM企業一覧を取得する
         * @summary カンパニー別CM企業一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdCmSponsors(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCmSponsor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdCmSponsors(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニー内CM企業別商品／ブランド一覧を取得する
         * @summary カンパニー内CM企業別商品／ブランド一覧取得
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {string} startDate 期間開始日
         * @param {string} endDate 期間終了日
         * @param {boolean} isOther 他社（競合含む自社以外）フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts(companyId: number, cmSponsorId: number, startDate: string, endDate: string, isOther: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyCmSponsorProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts(companyId, cmSponsorId, startDate, endDate, isOther, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニーに属する競合商品の一覧を取得する
         * @summary カンパニー別競合商品一覧
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdCompetitiveProducts(companyId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompetitiveProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdCompetitiveProducts(companyId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 他のカンパニーに紐づくCM商品を検索するAPI
         * @summary 他社CM商品検索
         * @param {number} companyId 
         * @param {string} keyword 検索キーワード(CM商品名または企業名)
         * @param {'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME' | 'ORDER_PRODUCT_LIST'} order 並び順
         * @param {string} [startDate] 開始日時
         * @param {string} [endDate] 終了日時
         * @param {number} [cmSponsorId] CMスポンサーID(絞り込みたいCMスポンサーを指定した場合のみ設定)
         * @param {number} [productId] 商品ID(CM商品を表示したい競合商品のID)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdOtherCompanyCmProductsSearch(companyId: number, keyword: string, order: 'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME' | 'ORDER_PRODUCT_LIST', startDate?: string, endDate?: string, cmSponsorId?: number, productId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OtherCmProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdOtherCompanyCmProductsSearch(companyId, keyword, order, startDate, endDate, cmSponsorId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニーに属する商品を検索する
         * @summary カンパニー別商品検索
         * @param {number} companyId 
         * @param {string} startDate 開始日時
         * @param {string} endDate 終了日時
         * @param {'CM_CREATIVE_DATE' | 'UPDATED_AT' | 'PRODUCT_NAME'} order 並び順
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード(商品名)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdProductsSearch(companyId: number, startDate: string, endDate: string, order: 'CM_CREATIVE_DATE' | 'UPDATED_AT' | 'PRODUCT_NAME', offset?: number, limit?: number, keyword?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdProductsSearch(companyId, startDate, endDate, order, offset, limit, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニー設定取得
         * @summary カンパニー設定取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdSettings(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdSettings(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニーにアカウントアクセス権限を追加
         * @summary アクセス権追加
         * @param {number} companyId 
         * @param {AccountAuthorityForm} [accountAuthorityForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompaniesCompanyIdAccountAuthorities(companyId: number, accountAuthorityForm?: AccountAuthorityForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAuthority>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompaniesCompanyIdAccountAuthorities(companyId, accountAuthorityForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニー別他社（競合含む自社以外）CM企業を登録する
         * @summary カンパニー別他社CM企業登録
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId: number, cmSponsorId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyToProductCmSponsor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId, cmSponsorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CM商品に紐づいた商品を登録する
         * @summary 商品登録
         * @param {number} companyId 
         * @param {CreateProductForm} [createProductForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompaniesCompanyIdProducts(companyId: number, createProductForm?: CreateProductForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompaniesCompanyIdProducts(companyId, createProductForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトに関する情報を新規登録する
         * @summary リーチ分析プロジェクト登録
         * @param {number} companyId 
         * @param {ReachAnalysisProjectForm} [reachAnalysisProjectForm] リーチ分析-プロジェクト登録・更新用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompaniesCompanyIdReachAnalysisProjects(companyId: number, reachAnalysisProjectForm?: ReachAnalysisProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompaniesCompanyIdReachAnalysisProjects(companyId, reachAnalysisProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニー設定更新
         * @summary カンパニー設定更新
         * @param {number} companyId 
         * @param {ChangeCompanySettingsForm} [changeCompanySettingsForm] カンパニー設定のフォーム
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCompaniesCompanyIdSettings(companyId: number, changeCompanySettingsForm?: ChangeCompanySettingsForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCompaniesCompanyIdSettings(companyId, changeCompanySettingsForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定のカンパニーを削除する
         * @summary カンパニーステータス更新
         * @param {number} companyId カンパニーID
         * @param {UpdateCompanyStatusForm} [updateCompanyStatusForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompaniesCompanyIdStatus(companyId: number, updateCompanyStatusForm?: UpdateCompanyStatusForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompaniesCompanyIdStatus(companyId, updateCompanyStatusForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * カンパニーからアカウントアクセス権限を削除
         * @summary アクセス権削除
         * @param {number} companyId 
         * @param {AccountAuthorityForm} [accountAuthorityForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompaniesCompanyIdAccountAuthorities(companyId: number, accountAuthorityForm?: AccountAuthorityForm, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompaniesCompanyIdAccountAuthorities(companyId, accountAuthorityForm, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニー別他社（競合含む自社以外）CM企業を削除する
         * @summary カンパニー別他社CM企業削除
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId: number, cmSponsorId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId, cmSponsorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 最後にアクセスしたカンパニー取得するAPI
         * @summary 最後にアクセスしたカンパニー取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdActiveCompany(accountId: number, options?: any): AxiosPromise<LastActiveCompany> {
            return localVarFp.getAccountsAccountIdActiveCompany(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニーに所属するアカウントを検索する
         * @summary カンパニー別アカウント検索
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード（メールアドレス、表示名、氏名）
         * @param {boolean} [privilegePrimaryOwner] プライマリーオーナー権限
         * @param {boolean} [privilegeOwner] オーナー権限
         * @param {boolean} [privilegeManager] 管理者権限
         * @param {boolean} [privilegeMember] メンバー権限
         * @param {boolean} [privilegeRestricted] 制限付き権限
         * @param {boolean} [privilegeFreePrimaryOwner] 無料プライマリーオーナー
         * @param {boolean} [privilegeFreeMember] 無料メンバー
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdAccountsSearch(companyId: number, offset?: number, limit?: number, keyword?: string, privilegePrimaryOwner?: boolean, privilegeOwner?: boolean, privilegeManager?: boolean, privilegeMember?: boolean, privilegeRestricted?: boolean, privilegeFreePrimaryOwner?: boolean, privilegeFreeMember?: boolean, options?: any): AxiosPromise<AccountList> {
            return localVarFp.getCompaniesCompanyIdAccountsSearch(companyId, offset, limit, keyword, privilegePrimaryOwner, privilegeOwner, privilegeManager, privilegeMember, privilegeRestricted, privilegeFreePrimaryOwner, privilegeFreeMember, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニー別分析利用中企業一覧を取得する
         * @summary カンパニー別分析利用中企業一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdAnalysisUsedCompanies(companyId: number, options?: any): AxiosPromise<Array<AnalysisUsedCompany>> {
            return localVarFp.getCompaniesCompanyIdAnalysisUsedCompanies(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニーに紐づくCM商品を検索するAPI
         * @summary カンパニー別CM商品検索
         * @param {number} companyId 
         * @param {string} startDate 開始日時
         * @param {string} endDate 終了日時
         * @param {'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME'} order 並び順
         * @param {string} [keyword] 検索キーワード(CM商品名)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCmProductsSearch(companyId: number, startDate: string, endDate: string, order: 'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME', keyword?: string, options?: any): AxiosPromise<CmProductList> {
            return localVarFp.getCompaniesCompanyIdCmProductsSearch(companyId, startDate, endDate, order, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニーのCMスポンサー情報を取得する
         * @summary カンパニーのCMスポンサー情報取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCmSponsor(companyId: number, options?: any): AxiosPromise<CmSponsorInfo> {
            return localVarFp.getCompaniesCompanyIdCmSponsor(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニー別CM企業一覧を取得する
         * @summary カンパニー別CM企業一覧取得
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCmSponsors(companyId: number, options?: any): AxiosPromise<Array<CompanyCmSponsor>> {
            return localVarFp.getCompaniesCompanyIdCmSponsors(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニー内CM企業別商品／ブランド一覧を取得する
         * @summary カンパニー内CM企業別商品／ブランド一覧取得
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {string} startDate 期間開始日
         * @param {string} endDate 期間終了日
         * @param {boolean} isOther 他社（競合含む自社以外）フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts(companyId: number, cmSponsorId: number, startDate: string, endDate: string, isOther: boolean, options?: any): AxiosPromise<Array<CompanyCmSponsorProduct>> {
            return localVarFp.getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts(companyId, cmSponsorId, startDate, endDate, isOther, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニーに属する競合商品の一覧を取得する
         * @summary カンパニー別競合商品一覧
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCompetitiveProducts(companyId: number, offset?: number, limit?: number, options?: any): AxiosPromise<CompetitiveProductList> {
            return localVarFp.getCompaniesCompanyIdCompetitiveProducts(companyId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 他のカンパニーに紐づくCM商品を検索するAPI
         * @summary 他社CM商品検索
         * @param {number} companyId 
         * @param {string} keyword 検索キーワード(CM商品名または企業名)
         * @param {'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME' | 'ORDER_PRODUCT_LIST'} order 並び順
         * @param {string} [startDate] 開始日時
         * @param {string} [endDate] 終了日時
         * @param {number} [cmSponsorId] CMスポンサーID(絞り込みたいCMスポンサーを指定した場合のみ設定)
         * @param {number} [productId] 商品ID(CM商品を表示したい競合商品のID)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdOtherCompanyCmProductsSearch(companyId: number, keyword: string, order: 'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME' | 'ORDER_PRODUCT_LIST', startDate?: string, endDate?: string, cmSponsorId?: number, productId?: number, options?: any): AxiosPromise<OtherCmProductList> {
            return localVarFp.getCompaniesCompanyIdOtherCompanyCmProductsSearch(companyId, keyword, order, startDate, endDate, cmSponsorId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニーに属する商品を検索する
         * @summary カンパニー別商品検索
         * @param {number} companyId 
         * @param {string} startDate 開始日時
         * @param {string} endDate 終了日時
         * @param {'CM_CREATIVE_DATE' | 'UPDATED_AT' | 'PRODUCT_NAME'} order 並び順
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード(商品名)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdProductsSearch(companyId: number, startDate: string, endDate: string, order: 'CM_CREATIVE_DATE' | 'UPDATED_AT' | 'PRODUCT_NAME', offset?: number, limit?: number, keyword?: string, options?: any): AxiosPromise<ProductsList> {
            return localVarFp.getCompaniesCompanyIdProductsSearch(companyId, startDate, endDate, order, offset, limit, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニー設定取得
         * @summary カンパニー設定取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdSettings(companyId: number, options?: any): AxiosPromise<CompanySettings> {
            return localVarFp.getCompaniesCompanyIdSettings(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニーにアカウントアクセス権限を追加
         * @summary アクセス権追加
         * @param {number} companyId 
         * @param {AccountAuthorityForm} [accountAuthorityForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdAccountAuthorities(companyId: number, accountAuthorityForm?: AccountAuthorityForm, options?: any): AxiosPromise<Array<AccountAuthority>> {
            return localVarFp.postCompaniesCompanyIdAccountAuthorities(companyId, accountAuthorityForm, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニー別他社（競合含む自社以外）CM企業を登録する
         * @summary カンパニー別他社CM企業登録
         * @param {number} companyId カンパニーID
         * @param {number} cmSponsorId CM企業ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId: number, cmSponsorId: number, options?: any): AxiosPromise<CompanyToProductCmSponsor> {
            return localVarFp.postCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId, cmSponsorId, options).then((request) => request(axios, basePath));
        },
        /**
         * CM商品に紐づいた商品を登録する
         * @summary 商品登録
         * @param {number} companyId 
         * @param {CreateProductForm} [createProductForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdProducts(companyId: number, createProductForm?: CreateProductForm, options?: any): AxiosPromise<Product> {
            return localVarFp.postCompaniesCompanyIdProducts(companyId, createProductForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトに関する情報を新規登録する
         * @summary リーチ分析プロジェクト登録
         * @param {number} companyId 
         * @param {ReachAnalysisProjectForm} [reachAnalysisProjectForm] リーチ分析-プロジェクト登録・更新用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdReachAnalysisProjects(companyId: number, reachAnalysisProjectForm?: ReachAnalysisProjectForm, options?: any): AxiosPromise<ReachAnalysisProject> {
            return localVarFp.postCompaniesCompanyIdReachAnalysisProjects(companyId, reachAnalysisProjectForm, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニー設定更新
         * @summary カンパニー設定更新
         * @param {number} companyId 
         * @param {ChangeCompanySettingsForm} [changeCompanySettingsForm] カンパニー設定のフォーム
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCompaniesCompanyIdSettings(companyId: number, changeCompanySettingsForm?: ChangeCompanySettingsForm, options?: any): AxiosPromise<CompanySettings> {
            return localVarFp.putCompaniesCompanyIdSettings(companyId, changeCompanySettingsForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定のカンパニーを削除する
         * @summary カンパニーステータス更新
         * @param {number} companyId カンパニーID
         * @param {UpdateCompanyStatusForm} [updateCompanyStatusForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompaniesCompanyIdStatus(companyId: number, updateCompanyStatusForm?: UpdateCompanyStatusForm, options?: any): AxiosPromise<Company> {
            return localVarFp.updateCompaniesCompanyIdStatus(companyId, updateCompanyStatusForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * カンパニーからアカウントアクセス権限を削除
     * @summary アクセス権削除
     * @param {number} companyId 
     * @param {AccountAuthorityForm} [accountAuthorityForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deleteCompaniesCompanyIdAccountAuthorities(companyId: number, accountAuthorityForm?: AccountAuthorityForm, options?: any) {
        return CompanyApiFp(this.configuration).deleteCompaniesCompanyIdAccountAuthorities(companyId, accountAuthorityForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニー別他社（競合含む自社以外）CM企業を削除する
     * @summary カンパニー別他社CM企業削除
     * @param {number} companyId カンパニーID
     * @param {number} cmSponsorId CM企業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId: number, cmSponsorId: number, options?: any) {
        return CompanyApiFp(this.configuration).deleteCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId, cmSponsorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 最後にアクセスしたカンパニー取得するAPI
     * @summary 最後にアクセスしたカンパニー取得
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getAccountsAccountIdActiveCompany(accountId: number, options?: any) {
        return CompanyApiFp(this.configuration).getAccountsAccountIdActiveCompany(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニーに所属するアカウントを検索する
     * @summary カンパニー別アカウント検索
     * @param {number} companyId 
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {string} [keyword] 検索キーワード（メールアドレス、表示名、氏名）
     * @param {boolean} [privilegePrimaryOwner] プライマリーオーナー権限
     * @param {boolean} [privilegeOwner] オーナー権限
     * @param {boolean} [privilegeManager] 管理者権限
     * @param {boolean} [privilegeMember] メンバー権限
     * @param {boolean} [privilegeRestricted] 制限付き権限
     * @param {boolean} [privilegeFreePrimaryOwner] 無料プライマリーオーナー
     * @param {boolean} [privilegeFreeMember] 無料メンバー
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdAccountsSearch(companyId: number, offset?: number, limit?: number, keyword?: string, privilegePrimaryOwner?: boolean, privilegeOwner?: boolean, privilegeManager?: boolean, privilegeMember?: boolean, privilegeRestricted?: boolean, privilegeFreePrimaryOwner?: boolean, privilegeFreeMember?: boolean, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdAccountsSearch(companyId, offset, limit, keyword, privilegePrimaryOwner, privilegeOwner, privilegeManager, privilegeMember, privilegeRestricted, privilegeFreePrimaryOwner, privilegeFreeMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニー別分析利用中企業一覧を取得する
     * @summary カンパニー別分析利用中企業一覧取得
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdAnalysisUsedCompanies(companyId: number, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdAnalysisUsedCompanies(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニーに紐づくCM商品を検索するAPI
     * @summary カンパニー別CM商品検索
     * @param {number} companyId 
     * @param {string} startDate 開始日時
     * @param {string} endDate 終了日時
     * @param {'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME'} order 並び順
     * @param {string} [keyword] 検索キーワード(CM商品名)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdCmProductsSearch(companyId: number, startDate: string, endDate: string, order: 'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME', keyword?: string, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdCmProductsSearch(companyId, startDate, endDate, order, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニーのCMスポンサー情報を取得する
     * @summary カンパニーのCMスポンサー情報取得
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdCmSponsor(companyId: number, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdCmSponsor(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニー別CM企業一覧を取得する
     * @summary カンパニー別CM企業一覧取得
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdCmSponsors(companyId: number, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdCmSponsors(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニー内CM企業別商品／ブランド一覧を取得する
     * @summary カンパニー内CM企業別商品／ブランド一覧取得
     * @param {number} companyId カンパニーID
     * @param {number} cmSponsorId CM企業ID
     * @param {string} startDate 期間開始日
     * @param {string} endDate 期間終了日
     * @param {boolean} isOther 他社（競合含む自社以外）フラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts(companyId: number, cmSponsorId: number, startDate: string, endDate: string, isOther: boolean, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdCmSponsorsCmSponsorIdProducts(companyId, cmSponsorId, startDate, endDate, isOther, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニーに属する競合商品の一覧を取得する
     * @summary カンパニー別競合商品一覧
     * @param {number} companyId 
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdCompetitiveProducts(companyId: number, offset?: number, limit?: number, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdCompetitiveProducts(companyId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 他のカンパニーに紐づくCM商品を検索するAPI
     * @summary 他社CM商品検索
     * @param {number} companyId 
     * @param {string} keyword 検索キーワード(CM商品名または企業名)
     * @param {'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME' | 'ORDER_PRODUCT_LIST'} order 並び順
     * @param {string} [startDate] 開始日時
     * @param {string} [endDate] 終了日時
     * @param {number} [cmSponsorId] CMスポンサーID(絞り込みたいCMスポンサーを指定した場合のみ設定)
     * @param {number} [productId] 商品ID(CM商品を表示したい競合商品のID)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdOtherCompanyCmProductsSearch(companyId: number, keyword: string, order: 'ORDER_CM_CREATIVE_DATE' | 'ORDER_CM_PRODUCT_NAME' | 'ORDER_PRODUCT_LIST', startDate?: string, endDate?: string, cmSponsorId?: number, productId?: number, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdOtherCompanyCmProductsSearch(companyId, keyword, order, startDate, endDate, cmSponsorId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニーに属する商品を検索する
     * @summary カンパニー別商品検索
     * @param {number} companyId 
     * @param {string} startDate 開始日時
     * @param {string} endDate 終了日時
     * @param {'CM_CREATIVE_DATE' | 'UPDATED_AT' | 'PRODUCT_NAME'} order 並び順
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {string} [keyword] 検索キーワード(商品名)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdProductsSearch(companyId: number, startDate: string, endDate: string, order: 'CM_CREATIVE_DATE' | 'UPDATED_AT' | 'PRODUCT_NAME', offset?: number, limit?: number, keyword?: string, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdProductsSearch(companyId, startDate, endDate, order, offset, limit, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニー設定取得
     * @summary カンパニー設定取得
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompaniesCompanyIdSettings(companyId: number, options?: any) {
        return CompanyApiFp(this.configuration).getCompaniesCompanyIdSettings(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニーにアカウントアクセス権限を追加
     * @summary アクセス権追加
     * @param {number} companyId 
     * @param {AccountAuthorityForm} [accountAuthorityForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public postCompaniesCompanyIdAccountAuthorities(companyId: number, accountAuthorityForm?: AccountAuthorityForm, options?: any) {
        return CompanyApiFp(this.configuration).postCompaniesCompanyIdAccountAuthorities(companyId, accountAuthorityForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニー別他社（競合含む自社以外）CM企業を登録する
     * @summary カンパニー別他社CM企業登録
     * @param {number} companyId カンパニーID
     * @param {number} cmSponsorId CM企業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public postCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId: number, cmSponsorId: number, options?: any) {
        return CompanyApiFp(this.configuration).postCompaniesCompanyIdProductCmSponsorsCmSponsorId(companyId, cmSponsorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CM商品に紐づいた商品を登録する
     * @summary 商品登録
     * @param {number} companyId 
     * @param {CreateProductForm} [createProductForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public postCompaniesCompanyIdProducts(companyId: number, createProductForm?: CreateProductForm, options?: any) {
        return CompanyApiFp(this.configuration).postCompaniesCompanyIdProducts(companyId, createProductForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトに関する情報を新規登録する
     * @summary リーチ分析プロジェクト登録
     * @param {number} companyId 
     * @param {ReachAnalysisProjectForm} [reachAnalysisProjectForm] リーチ分析-プロジェクト登録・更新用Form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public postCompaniesCompanyIdReachAnalysisProjects(companyId: number, reachAnalysisProjectForm?: ReachAnalysisProjectForm, options?: any) {
        return CompanyApiFp(this.configuration).postCompaniesCompanyIdReachAnalysisProjects(companyId, reachAnalysisProjectForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニー設定更新
     * @summary カンパニー設定更新
     * @param {number} companyId 
     * @param {ChangeCompanySettingsForm} [changeCompanySettingsForm] カンパニー設定のフォーム
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public putCompaniesCompanyIdSettings(companyId: number, changeCompanySettingsForm?: ChangeCompanySettingsForm, options?: any) {
        return CompanyApiFp(this.configuration).putCompaniesCompanyIdSettings(companyId, changeCompanySettingsForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定のカンパニーを削除する
     * @summary カンパニーステータス更新
     * @param {number} companyId カンパニーID
     * @param {UpdateCompanyStatusForm} [updateCompanyStatusForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompaniesCompanyIdStatus(companyId: number, updateCompanyStatusForm?: UpdateCompanyStatusForm, options?: any) {
        return CompanyApiFp(this.configuration).updateCompaniesCompanyIdStatus(companyId, updateCompanyStatusForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomTargetApi - axios parameter creator
 * @export
 */
export const CustomTargetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * カスタムターゲットを1件削除する
         * @summary カスタムターゲット削除
         * @param {number} customTargetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomTargetsCustomTargetId: async (customTargetId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customTargetId' is not null or undefined
            assertParamExists('deleteCustomTargetsCustomTargetId', 'customTargetId', customTargetId)
            const localVarPath = `/custom-targets/{customTargetId}`
                .replace(`{${"customTargetId"}}`, encodeURIComponent(String(customTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムターゲットの一覧を取得する
         * @summary カスタムターゲット一覧
         * @param {number} companyId 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCustomTargets: async (companyId: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCustomTargets', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/custom-targets`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムターゲットのモニタ構成を取得する
         * @summary カスタムターゲットモニタ構成取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCustomTargetsMonitorStructure: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdCustomTargetsMonitorStructure', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/custom-targets/monitor-structure`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムターゲットを1件取得する
         * @summary カスタムターゲット取得
         * @param {number} customTargetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomTargetsCustomTargetId: async (customTargetId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customTargetId' is not null or undefined
            assertParamExists('getCustomTargetsCustomTargetId', 'customTargetId', customTargetId)
            const localVarPath = `/custom-targets/{customTargetId}`
                .replace(`{${"customTargetId"}}`, encodeURIComponent(String(customTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムターゲットのアンケート条件選択画面にて、アンケートを初期表示及び検索するためのAPI
         * @summary アンケート検索
         * @param {number} workspaceId ワークスペースID
         * @param {number} [questionCategoryId] 質問カテゴリーID
         * @param {string} [keyword] 絞り込みキーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnquetesSearch: async (workspaceId: number, questionCategoryId?: number, keyword?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getEnquetesSearch', 'workspaceId', workspaceId)
            const localVarPath = `/enquetes/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (workspaceId !== undefined) {
                localVarQueryParameter['workspaceId'] = workspaceId;
            }

            if (questionCategoryId !== undefined) {
                localVarQueryParameter['questionCategoryId'] = questionCategoryId;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムターゲットを1件登録する
         * @summary カスタムターゲット登録
         * @param {number} companyId 
         * @param {CustomTargetForm} [customTargetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdCustomTargets: async (companyId: number, customTargetForm?: CustomTargetForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdCustomTargets', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/custom-targets`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customTargetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムターゲットのサンプル数を取得する
         * @summary カスタムターゲットサンプル数取得
         * @param {number} companyId 
         * @param {CustomTargetNumberOfSamplesForm} [customTargetNumberOfSamplesForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdCustomTargetsNumberOfSamples: async (companyId: number, customTargetNumberOfSamplesForm?: CustomTargetNumberOfSamplesForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdCustomTargetsNumberOfSamples', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/custom-targets/number-of-samples`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customTargetNumberOfSamplesForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カスタムターゲットを1件更新する
         * @summary カスタムターゲット更新
         * @param {number} customTargetId 
         * @param {CustomTargetForm} [customTargetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomTargetsCustomTargetId: async (customTargetId: number, customTargetForm?: CustomTargetForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'customTargetId' is not null or undefined
            assertParamExists('putCustomTargetsCustomTargetId', 'customTargetId', customTargetId)
            const localVarPath = `/custom-targets/{customTargetId}`
                .replace(`{${"customTargetId"}}`, encodeURIComponent(String(customTargetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customTargetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomTargetApi - functional programming interface
 * @export
 */
export const CustomTargetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomTargetApiAxiosParamCreator(configuration)
    return {
        /**
         * カスタムターゲットを1件削除する
         * @summary カスタムターゲット削除
         * @param {number} customTargetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCustomTargetsCustomTargetId(customTargetId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCustomTargetsCustomTargetId(customTargetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムターゲットの一覧を取得する
         * @summary カスタムターゲット一覧
         * @param {number} companyId 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdCustomTargets(companyId: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTargetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdCustomTargets(companyId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムターゲットのモニタ構成を取得する
         * @summary カスタムターゲットモニタ構成取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdCustomTargetsMonitorStructure(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTargetMonitorStructureList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdCustomTargetsMonitorStructure(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムターゲットを1件取得する
         * @summary カスタムターゲット取得
         * @param {number} customTargetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomTargetsCustomTargetId(customTargetId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTarget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomTargetsCustomTargetId(customTargetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムターゲットのアンケート条件選択画面にて、アンケートを初期表示及び検索するためのAPI
         * @summary アンケート検索
         * @param {number} workspaceId ワークスペースID
         * @param {number} [questionCategoryId] 質問カテゴリーID
         * @param {string} [keyword] 絞り込みキーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnquetesSearch(workspaceId: number, questionCategoryId?: number, keyword?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnquetesSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnquetesSearch(workspaceId, questionCategoryId, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムターゲットを1件登録する
         * @summary カスタムターゲット登録
         * @param {number} companyId 
         * @param {CustomTargetForm} [customTargetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompaniesCompanyIdCustomTargets(companyId: number, customTargetForm?: CustomTargetForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTarget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompaniesCompanyIdCustomTargets(companyId, customTargetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムターゲットのサンプル数を取得する
         * @summary カスタムターゲットサンプル数取得
         * @param {number} companyId 
         * @param {CustomTargetNumberOfSamplesForm} [customTargetNumberOfSamplesForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompaniesCompanyIdCustomTargetsNumberOfSamples(companyId: number, customTargetNumberOfSamplesForm?: CustomTargetNumberOfSamplesForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NumberOfSamples>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompaniesCompanyIdCustomTargetsNumberOfSamples(companyId, customTargetNumberOfSamplesForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カスタムターゲットを1件更新する
         * @summary カスタムターゲット更新
         * @param {number} customTargetId 
         * @param {CustomTargetForm} [customTargetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCustomTargetsCustomTargetId(customTargetId: number, customTargetForm?: CustomTargetForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomTarget>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCustomTargetsCustomTargetId(customTargetId, customTargetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomTargetApi - factory interface
 * @export
 */
export const CustomTargetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomTargetApiFp(configuration)
    return {
        /**
         * カスタムターゲットを1件削除する
         * @summary カスタムターゲット削除
         * @param {number} customTargetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomTargetsCustomTargetId(customTargetId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCustomTargetsCustomTargetId(customTargetId, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムターゲットの一覧を取得する
         * @summary カスタムターゲット一覧
         * @param {number} companyId 
         * @param {number} [limit] 取得件数
         * @param {number} [offset] 取得開始位置
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCustomTargets(companyId: number, limit?: number, offset?: number, options?: any): AxiosPromise<CustomTargetList> {
            return localVarFp.getCompaniesCompanyIdCustomTargets(companyId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムターゲットのモニタ構成を取得する
         * @summary カスタムターゲットモニタ構成取得
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdCustomTargetsMonitorStructure(companyId: number, options?: any): AxiosPromise<CustomTargetMonitorStructureList> {
            return localVarFp.getCompaniesCompanyIdCustomTargetsMonitorStructure(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムターゲットを1件取得する
         * @summary カスタムターゲット取得
         * @param {number} customTargetId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomTargetsCustomTargetId(customTargetId: number, options?: any): AxiosPromise<CustomTarget> {
            return localVarFp.getCustomTargetsCustomTargetId(customTargetId, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムターゲットのアンケート条件選択画面にて、アンケートを初期表示及び検索するためのAPI
         * @summary アンケート検索
         * @param {number} workspaceId ワークスペースID
         * @param {number} [questionCategoryId] 質問カテゴリーID
         * @param {string} [keyword] 絞り込みキーワード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnquetesSearch(workspaceId: number, questionCategoryId?: number, keyword?: string, options?: any): AxiosPromise<EnquetesSearchResult> {
            return localVarFp.getEnquetesSearch(workspaceId, questionCategoryId, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムターゲットを1件登録する
         * @summary カスタムターゲット登録
         * @param {number} companyId 
         * @param {CustomTargetForm} [customTargetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdCustomTargets(companyId: number, customTargetForm?: CustomTargetForm, options?: any): AxiosPromise<CustomTarget> {
            return localVarFp.postCompaniesCompanyIdCustomTargets(companyId, customTargetForm, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムターゲットのサンプル数を取得する
         * @summary カスタムターゲットサンプル数取得
         * @param {number} companyId 
         * @param {CustomTargetNumberOfSamplesForm} [customTargetNumberOfSamplesForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdCustomTargetsNumberOfSamples(companyId: number, customTargetNumberOfSamplesForm?: CustomTargetNumberOfSamplesForm, options?: any): AxiosPromise<NumberOfSamples> {
            return localVarFp.postCompaniesCompanyIdCustomTargetsNumberOfSamples(companyId, customTargetNumberOfSamplesForm, options).then((request) => request(axios, basePath));
        },
        /**
         * カスタムターゲットを1件更新する
         * @summary カスタムターゲット更新
         * @param {number} customTargetId 
         * @param {CustomTargetForm} [customTargetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCustomTargetsCustomTargetId(customTargetId: number, customTargetForm?: CustomTargetForm, options?: any): AxiosPromise<CustomTarget> {
            return localVarFp.putCustomTargetsCustomTargetId(customTargetId, customTargetForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomTargetApi - object-oriented interface
 * @export
 * @class CustomTargetApi
 * @extends {BaseAPI}
 */
export class CustomTargetApi extends BaseAPI {
    /**
     * カスタムターゲットを1件削除する
     * @summary カスタムターゲット削除
     * @param {number} customTargetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTargetApi
     */
    public deleteCustomTargetsCustomTargetId(customTargetId: number, options?: any) {
        return CustomTargetApiFp(this.configuration).deleteCustomTargetsCustomTargetId(customTargetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムターゲットの一覧を取得する
     * @summary カスタムターゲット一覧
     * @param {number} companyId 
     * @param {number} [limit] 取得件数
     * @param {number} [offset] 取得開始位置
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTargetApi
     */
    public getCompaniesCompanyIdCustomTargets(companyId: number, limit?: number, offset?: number, options?: any) {
        return CustomTargetApiFp(this.configuration).getCompaniesCompanyIdCustomTargets(companyId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムターゲットのモニタ構成を取得する
     * @summary カスタムターゲットモニタ構成取得
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTargetApi
     */
    public getCompaniesCompanyIdCustomTargetsMonitorStructure(companyId: number, options?: any) {
        return CustomTargetApiFp(this.configuration).getCompaniesCompanyIdCustomTargetsMonitorStructure(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムターゲットを1件取得する
     * @summary カスタムターゲット取得
     * @param {number} customTargetId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTargetApi
     */
    public getCustomTargetsCustomTargetId(customTargetId: number, options?: any) {
        return CustomTargetApiFp(this.configuration).getCustomTargetsCustomTargetId(customTargetId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムターゲットのアンケート条件選択画面にて、アンケートを初期表示及び検索するためのAPI
     * @summary アンケート検索
     * @param {number} workspaceId ワークスペースID
     * @param {number} [questionCategoryId] 質問カテゴリーID
     * @param {string} [keyword] 絞り込みキーワード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTargetApi
     */
    public getEnquetesSearch(workspaceId: number, questionCategoryId?: number, keyword?: string, options?: any) {
        return CustomTargetApiFp(this.configuration).getEnquetesSearch(workspaceId, questionCategoryId, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムターゲットを1件登録する
     * @summary カスタムターゲット登録
     * @param {number} companyId 
     * @param {CustomTargetForm} [customTargetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTargetApi
     */
    public postCompaniesCompanyIdCustomTargets(companyId: number, customTargetForm?: CustomTargetForm, options?: any) {
        return CustomTargetApiFp(this.configuration).postCompaniesCompanyIdCustomTargets(companyId, customTargetForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムターゲットのサンプル数を取得する
     * @summary カスタムターゲットサンプル数取得
     * @param {number} companyId 
     * @param {CustomTargetNumberOfSamplesForm} [customTargetNumberOfSamplesForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTargetApi
     */
    public postCompaniesCompanyIdCustomTargetsNumberOfSamples(companyId: number, customTargetNumberOfSamplesForm?: CustomTargetNumberOfSamplesForm, options?: any) {
        return CustomTargetApiFp(this.configuration).postCompaniesCompanyIdCustomTargetsNumberOfSamples(companyId, customTargetNumberOfSamplesForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カスタムターゲットを1件更新する
     * @summary カスタムターゲット更新
     * @param {number} customTargetId 
     * @param {CustomTargetForm} [customTargetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomTargetApi
     */
    public putCustomTargetsCustomTargetId(customTargetId: number, customTargetForm?: CustomTargetForm, options?: any) {
        return CustomTargetApiFp(this.configuration).putCustomTargetsCustomTargetId(customTargetId, customTargetForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 設定されている商品の情報を初期化し、CM商品に戻す
         * @summary 商品削除
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductsProductId: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProductsProductId', 'productId', productId)
            const localVarPath = `/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * WS開設申請時に選択可能なエリアを取得する
         * @summary 選択可能エリア取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreaSelectableAreas: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/area/selectable-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースにて契約しているエリアを返却する
         * @summary ワークスペース別契約エリア取得
         * @param {number} workspaceId ワークスペースID
         * @param {boolean} [isMain] 東阪名のみに限定したい場合
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdContractAreas: async (workspaceId: number, isMain?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdContractAreas', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract-areas`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isMain !== undefined) {
                localVarQueryParameter['isMain'] = isMain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 設定されている商品の情報を初期化し、CM商品に戻す
         * @summary 商品削除
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductsProductId(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductsProductId(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * WS開設申請時に選択可能なエリアを取得する
         * @summary 選択可能エリア取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAreaSelectableAreas(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableAreas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAreaSelectableAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースにて契約しているエリアを返却する
         * @summary ワークスペース別契約エリア取得
         * @param {number} workspaceId ワークスペースID
         * @param {boolean} [isMain] 東阪名のみに限定したい場合
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceIdContractAreas(workspaceId: number, isMain?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectableAreas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceIdContractAreas(workspaceId, isMain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 設定されている商品の情報を初期化し、CM商品に戻す
         * @summary 商品削除
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductsProductId(productId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductsProductId(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * WS開設申請時に選択可能なエリアを取得する
         * @summary 選択可能エリア取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreaSelectableAreas(options?: any): AxiosPromise<SelectableAreas> {
            return localVarFp.getAreaSelectableAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースにて契約しているエリアを返却する
         * @summary ワークスペース別契約エリア取得
         * @param {number} workspaceId ワークスペースID
         * @param {boolean} [isMain] 東阪名のみに限定したい場合
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdContractAreas(workspaceId: number, isMain?: boolean, options?: any): AxiosPromise<SelectableAreas> {
            return localVarFp.getWorkspacesWorkspaceIdContractAreas(workspaceId, isMain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 設定されている商品の情報を初期化し、CM商品に戻す
     * @summary 商品削除
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProductsProductId(productId: number, options?: any) {
        return DefaultApiFp(this.configuration).deleteProductsProductId(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * WS開設申請時に選択可能なエリアを取得する
     * @summary 選択可能エリア取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAreaSelectableAreas(options?: any) {
        return DefaultApiFp(this.configuration).getAreaSelectableAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースにて契約しているエリアを返却する
     * @summary ワークスペース別契約エリア取得
     * @param {number} workspaceId ワークスペースID
     * @param {boolean} [isMain] 東阪名のみに限定したい場合
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getWorkspacesWorkspaceIdContractAreas(workspaceId: number, isMain?: boolean, options?: any) {
        return DefaultApiFp(this.configuration).getWorkspacesWorkspaceIdContractAreas(workspaceId, isMain, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * HealthCheck
         * @summary HealthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * HealthCheck
         * @summary HealthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheck(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * HealthCheck
         * @summary HealthCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any): AxiosPromise<void> {
            return localVarFp.healthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * HealthCheck
     * @summary HealthCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public healthCheck(options?: any) {
        return HealthcheckApiFp(this.configuration).healthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HomeApi - axios parameter creator
 * @export
 */
export const HomeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ホームに表示する商品の一覧
         * @summary ホーム - 商品一覧
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdHomeProducts: async (accountId: number, companyId: number, startDate: string, endDate: string, areaCode: string, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdHomeProducts', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdHomeProducts', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdHomeProducts', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdHomeProducts', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdHomeProducts', 'areaCode', areaCode)
            const localVarPath = `/accounts/{accountId}/companies/{companyId}/home/products`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ホーム画面に表示する選択された商品に関するサマリ情報
         * @summary ホーム - 商品指標サマリ取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {boolean} isCompetitiveProduct 競合商品フラグ
         * @param {number} [relatedProductId] 競合商品に紐づく商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary: async (accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, isCompetitiveProduct: boolean, relatedProductId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary', 'productId', productId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary', 'areaCode', areaCode)
            // verify required parameter 'isCmProduct' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary', 'isCmProduct', isCmProduct)
            // verify required parameter 'isCompetitiveProduct' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary', 'isCompetitiveProduct', isCompetitiveProduct)
            const localVarPath = `/accounts/{accountId}/companies/{companyId}/products/{productId}/indicator-summary`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (isCmProduct !== undefined) {
                localVarQueryParameter['isCmProduct'] = isCmProduct;
            }

            if (isCompetitiveProduct !== undefined) {
                localVarQueryParameter['isCompetitiveProduct'] = isCompetitiveProduct;
            }

            if (relatedProductId !== undefined) {
                localVarQueryParameter['relatedProductId'] = relatedProductId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ホーム画面に表示する出稿量グラフ用データ
         * @summary ホーム - 商品出稿量実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount: async (accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount', 'productId', productId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount', 'areaCode', areaCode)
            // verify required parameter 'isCmProduct' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount', 'isCmProduct', isCmProduct)
            const localVarPath = `/accounts/{accountId}/companies/{companyId}/products/{productId}/placement-amount`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (isCmProduct !== undefined) {
                localVarQueryParameter['isCmProduct'] = isCmProduct;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ホーム画面に表示するSOV円グラフ用データ
         * @summary ホーム - 商品出稿量SOV実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov: async (accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov', 'productId', productId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov', 'areaCode', areaCode)
            // verify required parameter 'isCmProduct' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov', 'isCmProduct', isCmProduct)
            const localVarPath = `/accounts/{accountId}/companies/{companyId}/products/{productId}/placement-amount-sov`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (isCmProduct !== undefined) {
                localVarQueryParameter['isCmProduct'] = isCmProduct;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ホーム画面に表示するターゲットリーチグラフ用データ
         * @summary ホーム - 商品ターゲットリーチ人数実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber: async (accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber', 'companyId', companyId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber', 'productId', productId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber', 'areaCode', areaCode)
            // verify required parameter 'isCmProduct' is not null or undefined
            assertParamExists('getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber', 'isCmProduct', isCmProduct)
            const localVarPath = `/accounts/{accountId}/companies/{companyId}/products/{productId}/target-reach-number`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (isCmProduct !== undefined) {
                localVarQueryParameter['isCmProduct'] = isCmProduct;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ホームにて使用する商品の詳細情報を取得する
         * @summary ホーム - 商品詳細取得
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsProductIdHome: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductsProductIdHome', 'productId', productId)
            const localVarPath = `/products/{productId}/home`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テレビ視聴人口数を取得する
         * @summary テレビ視聴人口取得
         * @param {string} areaCode エリア
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsTvViewerPopulation: async (areaCode: string, companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getStatsTvViewerPopulation', 'areaCode', areaCode)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getStatsTvViewerPopulation', 'companyId', companyId)
            const localVarPath = `/stats/tv-viewer-population`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント毎にお気に入りの商品を設定できる。
         * @summary アカウント別お気に入り商品更新
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {ChangeFavoriteProduct} [changeFavoriteProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountsAccountIdCompaniesCompanyIdFavoriteProducts: async (accountId: number, companyId: number, changeFavoriteProduct?: ChangeFavoriteProduct, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('postAccountsAccountIdCompaniesCompanyIdFavoriteProducts', 'accountId', accountId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postAccountsAccountIdCompaniesCompanyIdFavoriteProducts', 'companyId', companyId)
            const localVarPath = `/accounts/{accountId}/companies/{companyId}/favorite-products`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeFavoriteProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeApi - functional programming interface
 * @export
 */
export const HomeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HomeApiAxiosParamCreator(configuration)
    return {
        /**
         * ホームに表示する商品の一覧
         * @summary ホーム - 商品一覧
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdCompaniesCompanyIdHomeProducts(accountId: number, companyId: number, startDate: string, endDate: string, areaCode: string, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdCompaniesCompanyIdHomeProducts(accountId, companyId, startDate, endDate, areaCode, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ホーム画面に表示する選択された商品に関するサマリ情報
         * @summary ホーム - 商品指標サマリ取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {boolean} isCompetitiveProduct 競合商品フラグ
         * @param {number} [relatedProductId] 競合商品に紐づく商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, isCompetitiveProduct: boolean, relatedProductId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeSummariess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, isCompetitiveProduct, relatedProductId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ホーム画面に表示する出稿量グラフ用データ
         * @summary ホーム - 商品出稿量実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomePlacementAmount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ホーム画面に表示するSOV円グラフ用データ
         * @summary ホーム - 商品出稿量SOV実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrpsForSov>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ホーム画面に表示するターゲットリーチグラフ用データ
         * @summary ホーム - 商品ターゲットリーチ人数実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeTargetReachNumber>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ホームにて使用する商品の詳細情報を取得する
         * @summary ホーム - 商品詳細取得
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsProductIdHome(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomeProductDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsProductIdHome(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * テレビ視聴人口数を取得する
         * @summary テレビ視聴人口取得
         * @param {string} areaCode エリア
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatsTvViewerPopulation(areaCode: string, companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvViewingNumber>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatsTvViewerPopulation(areaCode, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウント毎にお気に入りの商品を設定できる。
         * @summary アカウント別お気に入り商品更新
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {ChangeFavoriteProduct} [changeFavoriteProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccountsAccountIdCompaniesCompanyIdFavoriteProducts(accountId: number, companyId: number, changeFavoriteProduct?: ChangeFavoriteProduct, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeFavoriteProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccountsAccountIdCompaniesCompanyIdFavoriteProducts(accountId, companyId, changeFavoriteProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HomeApi - factory interface
 * @export
 */
export const HomeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HomeApiFp(configuration)
    return {
        /**
         * ホームに表示する商品の一覧
         * @summary ホーム - 商品一覧
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdHomeProducts(accountId: number, companyId: number, startDate: string, endDate: string, areaCode: string, offset?: number, limit?: number, options?: any): AxiosPromise<HomeProductList> {
            return localVarFp.getAccountsAccountIdCompaniesCompanyIdHomeProducts(accountId, companyId, startDate, endDate, areaCode, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * ホーム画面に表示する選択された商品に関するサマリ情報
         * @summary ホーム - 商品指標サマリ取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {boolean} isCompetitiveProduct 競合商品フラグ
         * @param {number} [relatedProductId] 競合商品に紐づく商品ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, isCompetitiveProduct: boolean, relatedProductId?: number, options?: any): AxiosPromise<HomeSummariess> {
            return localVarFp.getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, isCompetitiveProduct, relatedProductId, options).then((request) => request(axios, basePath));
        },
        /**
         * ホーム画面に表示する出稿量グラフ用データ
         * @summary ホーム - 商品出稿量実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any): AxiosPromise<HomePlacementAmount> {
            return localVarFp.getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * ホーム画面に表示するSOV円グラフ用データ
         * @summary ホーム - 商品出稿量SOV実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any): AxiosPromise<GrpsForSov> {
            return localVarFp.getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * ホーム画面に表示するターゲットリーチグラフ用データ
         * @summary ホーム - 商品ターゲットリーチ人数実績取得
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {number} productId 
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} isCmProduct CM商品フラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any): AxiosPromise<HomeTargetReachNumber> {
            return localVarFp.getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * ホームにて使用する商品の詳細情報を取得する
         * @summary ホーム - 商品詳細取得
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsProductIdHome(productId: number, options?: any): AxiosPromise<HomeProductDetail> {
            return localVarFp.getProductsProductIdHome(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * テレビ視聴人口数を取得する
         * @summary テレビ視聴人口取得
         * @param {string} areaCode エリア
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatsTvViewerPopulation(areaCode: string, companyId: number, options?: any): AxiosPromise<TvViewingNumber> {
            return localVarFp.getStatsTvViewerPopulation(areaCode, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント毎にお気に入りの商品を設定できる。
         * @summary アカウント別お気に入り商品更新
         * @param {number} accountId 
         * @param {number} companyId 
         * @param {ChangeFavoriteProduct} [changeFavoriteProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountsAccountIdCompaniesCompanyIdFavoriteProducts(accountId: number, companyId: number, changeFavoriteProduct?: ChangeFavoriteProduct, options?: any): AxiosPromise<ChangeFavoriteProduct> {
            return localVarFp.postAccountsAccountIdCompaniesCompanyIdFavoriteProducts(accountId, companyId, changeFavoriteProduct, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HomeApi - object-oriented interface
 * @export
 * @class HomeApi
 * @extends {BaseAPI}
 */
export class HomeApi extends BaseAPI {
    /**
     * ホームに表示する商品の一覧
     * @summary ホーム - 商品一覧
     * @param {number} accountId 
     * @param {number} companyId 
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getAccountsAccountIdCompaniesCompanyIdHomeProducts(accountId: number, companyId: number, startDate: string, endDate: string, areaCode: string, offset?: number, limit?: number, options?: any) {
        return HomeApiFp(this.configuration).getAccountsAccountIdCompaniesCompanyIdHomeProducts(accountId, companyId, startDate, endDate, areaCode, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ホーム画面に表示する選択された商品に関するサマリ情報
     * @summary ホーム - 商品指標サマリ取得
     * @param {number} accountId 
     * @param {number} companyId 
     * @param {number} productId 
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {boolean} isCmProduct CM商品フラグ
     * @param {boolean} isCompetitiveProduct 競合商品フラグ
     * @param {number} [relatedProductId] 競合商品に紐づく商品ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, isCompetitiveProduct: boolean, relatedProductId?: number, options?: any) {
        return HomeApiFp(this.configuration).getAccountsAccountIdCompaniesCompanyIdProductsProductIdIndicatorSummary(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, isCompetitiveProduct, relatedProductId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ホーム画面に表示する出稿量グラフ用データ
     * @summary ホーム - 商品出稿量実績取得
     * @param {number} accountId 
     * @param {number} companyId 
     * @param {number} productId 
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {boolean} isCmProduct CM商品フラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any) {
        return HomeApiFp(this.configuration).getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmount(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ホーム画面に表示するSOV円グラフ用データ
     * @summary ホーム - 商品出稿量SOV実績取得
     * @param {number} accountId 
     * @param {number} companyId 
     * @param {number} productId 
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {boolean} isCmProduct CM商品フラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any) {
        return HomeApiFp(this.configuration).getAccountsAccountIdCompaniesCompanyIdProductsProductIdPlacementAmountSov(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ホーム画面に表示するターゲットリーチグラフ用データ
     * @summary ホーム - 商品ターゲットリーチ人数実績取得
     * @param {number} accountId 
     * @param {number} companyId 
     * @param {number} productId 
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {boolean} isCmProduct CM商品フラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber(accountId: number, companyId: number, productId: number, startDate: string, endDate: string, areaCode: string, isCmProduct: boolean, options?: any) {
        return HomeApiFp(this.configuration).getAccountsAccountIdCompaniesCompanyIdProductsProductIdTargetReachNumber(accountId, companyId, productId, startDate, endDate, areaCode, isCmProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ホームにて使用する商品の詳細情報を取得する
     * @summary ホーム - 商品詳細取得
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getProductsProductIdHome(productId: number, options?: any) {
        return HomeApiFp(this.configuration).getProductsProductIdHome(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テレビ視聴人口数を取得する
     * @summary テレビ視聴人口取得
     * @param {string} areaCode エリア
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public getStatsTvViewerPopulation(areaCode: string, companyId: number, options?: any) {
        return HomeApiFp(this.configuration).getStatsTvViewerPopulation(areaCode, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント毎にお気に入りの商品を設定できる。
     * @summary アカウント別お気に入り商品更新
     * @param {number} accountId 
     * @param {number} companyId 
     * @param {ChangeFavoriteProduct} [changeFavoriteProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeApi
     */
    public postAccountsAccountIdCompaniesCompanyIdFavoriteProducts(accountId: number, companyId: number, changeFavoriteProduct?: ChangeFavoriteProduct, options?: any) {
        return HomeApiFp(this.configuration).postAccountsAccountIdCompaniesCompanyIdFavoriteProducts(accountId, companyId, changeFavoriteProduct, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InstantViewingRateApi - axios parameter creator
 * @export
 */
export const InstantViewingRateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した範囲の視聴率・視聴人数を取得する 指定できる範囲は最大25時間
         * @summary 指定した範囲の視聴率・視聴人数の取得
         * @param {string} startDateTime 開始日時(YYYY-MM-DD HH:MM)
         * @param {string} endDateTime 終了日時(YYYY-MM-DD HH:MM)
         * @param {'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO'} areaCode エリアコード
         * @param {'INDIVIDUAL' | 'HOUSEHOLD' | 'CORE'} targetType ターゲットタイプ
         * @param {number} [targetId] ターゲットID (ターゲットタイプ&#x3D;BASICのとき指定)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantViewingRatePerMinute: async (startDateTime: string, endDateTime: string, areaCode: 'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO', targetType: 'INDIVIDUAL' | 'HOUSEHOLD' | 'CORE', targetId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDateTime' is not null or undefined
            assertParamExists('getInstantViewingRatePerMinute', 'startDateTime', startDateTime)
            // verify required parameter 'endDateTime' is not null or undefined
            assertParamExists('getInstantViewingRatePerMinute', 'endDateTime', endDateTime)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getInstantViewingRatePerMinute', 'areaCode', areaCode)
            // verify required parameter 'targetType' is not null or undefined
            assertParamExists('getInstantViewingRatePerMinute', 'targetType', targetType)
            const localVarPath = `/instant-viewing-rate/per-minute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDateTime !== undefined) {
                localVarQueryParameter['startDateTime'] = startDateTime;
            }

            if (endDateTime !== undefined) {
                localVarQueryParameter['endDateTime'] = endDateTime;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (targetId !== undefined) {
                localVarQueryParameter['targetId'] = targetId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstantViewingRateApi - functional programming interface
 * @export
 */
export const InstantViewingRateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstantViewingRateApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した範囲の視聴率・視聴人数を取得する 指定できる範囲は最大25時間
         * @summary 指定した範囲の視聴率・視聴人数の取得
         * @param {string} startDateTime 開始日時(YYYY-MM-DD HH:MM)
         * @param {string} endDateTime 終了日時(YYYY-MM-DD HH:MM)
         * @param {'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO'} areaCode エリアコード
         * @param {'INDIVIDUAL' | 'HOUSEHOLD' | 'CORE'} targetType ターゲットタイプ
         * @param {number} [targetId] ターゲットID (ターゲットタイプ&#x3D;BASICのとき指定)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstantViewingRatePerMinute(startDateTime: string, endDateTime: string, areaCode: 'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO', targetType: 'INDIVIDUAL' | 'HOUSEHOLD' | 'CORE', targetId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerMinuteViewingRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstantViewingRatePerMinute(startDateTime, endDateTime, areaCode, targetType, targetId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstantViewingRateApi - factory interface
 * @export
 */
export const InstantViewingRateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstantViewingRateApiFp(configuration)
    return {
        /**
         * 指定した範囲の視聴率・視聴人数を取得する 指定できる範囲は最大25時間
         * @summary 指定した範囲の視聴率・視聴人数の取得
         * @param {string} startDateTime 開始日時(YYYY-MM-DD HH:MM)
         * @param {string} endDateTime 終了日時(YYYY-MM-DD HH:MM)
         * @param {'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO'} areaCode エリアコード
         * @param {'INDIVIDUAL' | 'HOUSEHOLD' | 'CORE'} targetType ターゲットタイプ
         * @param {number} [targetId] ターゲットID (ターゲットタイプ&#x3D;BASICのとき指定)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantViewingRatePerMinute(startDateTime: string, endDateTime: string, areaCode: 'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO', targetType: 'INDIVIDUAL' | 'HOUSEHOLD' | 'CORE', targetId?: number, options?: any): AxiosPromise<PerMinuteViewingRate> {
            return localVarFp.getInstantViewingRatePerMinute(startDateTime, endDateTime, areaCode, targetType, targetId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstantViewingRateApi - object-oriented interface
 * @export
 * @class InstantViewingRateApi
 * @extends {BaseAPI}
 */
export class InstantViewingRateApi extends BaseAPI {
    /**
     * 指定した範囲の視聴率・視聴人数を取得する 指定できる範囲は最大25時間
     * @summary 指定した範囲の視聴率・視聴人数の取得
     * @param {string} startDateTime 開始日時(YYYY-MM-DD HH:MM)
     * @param {string} endDateTime 終了日時(YYYY-MM-DD HH:MM)
     * @param {'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO'} areaCode エリアコード
     * @param {'INDIVIDUAL' | 'HOUSEHOLD' | 'CORE'} targetType ターゲットタイプ
     * @param {number} [targetId] ターゲットID (ターゲットタイプ&#x3D;BASICのとき指定)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstantViewingRateApi
     */
    public getInstantViewingRatePerMinute(startDateTime: string, endDateTime: string, areaCode: 'SAAS_KANTO' | 'SAAS_KANSAI' | 'SAAS_CHUKYO', targetType: 'INDIVIDUAL' | 'HOUSEHOLD' | 'CORE', targetId?: number, options?: any) {
        return InstantViewingRateApiFp(this.configuration).getInstantViewingRatePerMinute(startDateTime, endDateTime, areaCode, targetType, targetId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoticeApi - axios parameter creator
 * @export
 */
export const NoticeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * お知らせalert通知一覧情報を取得するAPI
         * @summary お知らせalert通知一覧取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdNoticesAlerts: async (accountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdNoticesAlerts', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/notices/alerts`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせメッセージ一覧情報を取得するAPI
         * @summary お知らせメッセージ一覧取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdNoticesMessages: async (accountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAccountsAccountIdNoticesMessages', 'accountId', accountId)
            const localVarPath = `/accounts/{accountId}/notices/messages`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせユーザー既読管理情報登録
         * @summary お知らせユーザー既読管理情報登録
         * @param {number} accountId 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses: async (accountId: number, noticeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses', 'accountId', accountId)
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses', 'noticeId', noticeId)
            const localVarPath = `/accounts/{accountId}/notices/{noticeId}/account-notice-statuses`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お知らせユーザー既読管理情報更新
         * @summary お知らせユーザー既読管理情報更新
         * @param {number} accountId 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit: async (accountId: number, noticeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit', 'accountId', accountId)
            // verify required parameter 'noticeId' is not null or undefined
            assertParamExists('putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit', 'noticeId', noticeId)
            const localVarPath = `/accounts/{accountId}/notices/{noticeId}/account-notice-statuses/edit`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"noticeId"}}`, encodeURIComponent(String(noticeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticeApi - functional programming interface
 * @export
 */
export const NoticeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoticeApiAxiosParamCreator(configuration)
    return {
        /**
         * お知らせalert通知一覧情報を取得するAPI
         * @summary お知らせalert通知一覧取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdNoticesAlerts(accountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeAlertList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdNoticesAlerts(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせメッセージ一覧情報を取得するAPI
         * @summary お知らせメッセージ一覧取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountsAccountIdNoticesMessages(accountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoticeMessageList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountsAccountIdNoticesMessages(accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせユーザー既読管理情報登録
         * @summary お知らせユーザー既読管理情報登録
         * @param {number} accountId 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses(accountId: number, noticeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountNoticeStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses(accountId, noticeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お知らせユーザー既読管理情報更新
         * @summary お知らせユーザー既読管理情報更新
         * @param {number} accountId 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit(accountId: number, noticeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountNoticeStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit(accountId, noticeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoticeApi - factory interface
 * @export
 */
export const NoticeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoticeApiFp(configuration)
    return {
        /**
         * お知らせalert通知一覧情報を取得するAPI
         * @summary お知らせalert通知一覧取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdNoticesAlerts(accountId: number, options?: any): AxiosPromise<NoticeAlertList> {
            return localVarFp.getAccountsAccountIdNoticesAlerts(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせメッセージ一覧情報を取得するAPI
         * @summary お知らせメッセージ一覧取得
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsAccountIdNoticesMessages(accountId: number, options?: any): AxiosPromise<NoticeMessageList> {
            return localVarFp.getAccountsAccountIdNoticesMessages(accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせユーザー既読管理情報登録
         * @summary お知らせユーザー既読管理情報登録
         * @param {number} accountId 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses(accountId: number, noticeId: number, options?: any): AxiosPromise<AccountNoticeStatus> {
            return localVarFp.postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses(accountId, noticeId, options).then((request) => request(axios, basePath));
        },
        /**
         * お知らせユーザー既読管理情報更新
         * @summary お知らせユーザー既読管理情報更新
         * @param {number} accountId 
         * @param {number} noticeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit(accountId: number, noticeId: number, options?: any): AxiosPromise<AccountNoticeStatus> {
            return localVarFp.putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit(accountId, noticeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticeApi - object-oriented interface
 * @export
 * @class NoticeApi
 * @extends {BaseAPI}
 */
export class NoticeApi extends BaseAPI {
    /**
     * お知らせalert通知一覧情報を取得するAPI
     * @summary お知らせalert通知一覧取得
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getAccountsAccountIdNoticesAlerts(accountId: number, options?: any) {
        return NoticeApiFp(this.configuration).getAccountsAccountIdNoticesAlerts(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせメッセージ一覧情報を取得するAPI
     * @summary お知らせメッセージ一覧取得
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public getAccountsAccountIdNoticesMessages(accountId: number, options?: any) {
        return NoticeApiFp(this.configuration).getAccountsAccountIdNoticesMessages(accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせユーザー既読管理情報登録
     * @summary お知らせユーザー既読管理情報登録
     * @param {number} accountId 
     * @param {number} noticeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses(accountId: number, noticeId: number, options?: any) {
        return NoticeApiFp(this.configuration).postAccountsAccountIdNoticesNoticeIdAccountNoticeStatuses(accountId, noticeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お知らせユーザー既読管理情報更新
     * @summary お知らせユーザー既読管理情報更新
     * @param {number} accountId 
     * @param {number} noticeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticeApi
     */
    public putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit(accountId: number, noticeId: number, options?: any) {
        return NoticeApiFp(this.configuration).putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit(accountId, noticeId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 競合商品の削除
         * @summary 競合商品削除
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductsProductIdCompetitiveProducts: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProductsProductIdCompetitiveProducts', 'productId', productId)
            const localVarPath = `/products/{productId}/competitive-products`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 商品／ブランド詳細情報を取得する
         * @summary 商品／ブランド詳細取得
         * @param {number} productId 商品ID
         * @param {boolean} isTvalProduct TVAL商品フラグ
         * @param {number} affiliatedCompanyId 所属企業ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsProductIdDetail: async (productId: number, isTvalProduct: boolean, affiliatedCompanyId: number, companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductsProductIdDetail', 'productId', productId)
            // verify required parameter 'isTvalProduct' is not null or undefined
            assertParamExists('getProductsProductIdDetail', 'isTvalProduct', isTvalProduct)
            // verify required parameter 'affiliatedCompanyId' is not null or undefined
            assertParamExists('getProductsProductIdDetail', 'affiliatedCompanyId', affiliatedCompanyId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getProductsProductIdDetail', 'companyId', companyId)
            const localVarPath = `/products/{productId}/detail`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isTvalProduct !== undefined) {
                localVarQueryParameter['isTvalProduct'] = isTvalProduct;
            }

            if (affiliatedCompanyId !== undefined) {
                localVarQueryParameter['affiliatedCompanyId'] = affiliatedCompanyId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 商品更新
         * @summary 商品更新
         * @param {number} productId 
         * @param {ChangeProductForm} [changeProductForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductsProductId: async (productId: number, changeProductForm?: ChangeProductForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('putProductsProductId', 'productId', productId)
            const localVarPath = `/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeProductForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 競合商品の削除
         * @summary 競合商品削除
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductsProductIdCompetitiveProducts(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductsProductIdCompetitiveProducts(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 商品／ブランド詳細情報を取得する
         * @summary 商品／ブランド詳細取得
         * @param {number} productId 商品ID
         * @param {boolean} isTvalProduct TVAL商品フラグ
         * @param {number} affiliatedCompanyId 所属企業ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsProductIdDetail(productId: number, isTvalProduct: boolean, affiliatedCompanyId: number, companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsProductIdDetail(productId, isTvalProduct, affiliatedCompanyId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 商品更新
         * @summary 商品更新
         * @param {number} productId 
         * @param {ChangeProductForm} [changeProductForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProductsProductId(productId: number, changeProductForm?: ChangeProductForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProductsProductId(productId, changeProductForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 競合商品の削除
         * @summary 競合商品削除
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductsProductIdCompetitiveProducts(productId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductsProductIdCompetitiveProducts(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 商品／ブランド詳細情報を取得する
         * @summary 商品／ブランド詳細取得
         * @param {number} productId 商品ID
         * @param {boolean} isTvalProduct TVAL商品フラグ
         * @param {number} affiliatedCompanyId 所属企業ID
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsProductIdDetail(productId: number, isTvalProduct: boolean, affiliatedCompanyId: number, companyId: number, options?: any): AxiosPromise<ProductDetail> {
            return localVarFp.getProductsProductIdDetail(productId, isTvalProduct, affiliatedCompanyId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 商品更新
         * @summary 商品更新
         * @param {number} productId 
         * @param {ChangeProductForm} [changeProductForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductsProductId(productId: number, changeProductForm?: ChangeProductForm, options?: any): AxiosPromise<Product> {
            return localVarFp.putProductsProductId(productId, changeProductForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 競合商品の削除
     * @summary 競合商品削除
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteProductsProductIdCompetitiveProducts(productId: number, options?: any) {
        return ProductApiFp(this.configuration).deleteProductsProductIdCompetitiveProducts(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 商品／ブランド詳細情報を取得する
     * @summary 商品／ブランド詳細取得
     * @param {number} productId 商品ID
     * @param {boolean} isTvalProduct TVAL商品フラグ
     * @param {number} affiliatedCompanyId 所属企業ID
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductsProductIdDetail(productId: number, isTvalProduct: boolean, affiliatedCompanyId: number, companyId: number, options?: any) {
        return ProductApiFp(this.configuration).getProductsProductIdDetail(productId, isTvalProduct, affiliatedCompanyId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 商品更新
     * @summary 商品更新
     * @param {number} productId 
     * @param {ChangeProductForm} [changeProductForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public putProductsProductId(productId: number, changeProductForm?: ChangeProductForm, options?: any) {
        return ProductApiFp(this.configuration).putProductsProductId(productId, changeProductForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * プロジェクトの削除を実行する
         * @summary リーチ分析プロジェクト削除
         * @param {number} reachAnalysisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReachAnalysisProjectsReachAnalysisProjectId: async (reachAnalysisProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('deleteReachAnalysisProjectsReachAnalysisProjectId', 'reachAnalysisProjectId', reachAnalysisProjectId)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニーに紐づくプロジェクトの一覧情報を取得する
         * @summary リーチ分析プロジェクト一覧
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects: async (companyId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析-プロジェクトの情報を取得する
         * @summary リーチ分析プロジェクト取得
         * @param {number} reachAnalysisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProject: async (reachAnalysisProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProject', 'reachAnalysisProjectId', reachAnalysisProjectId)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 目標リーチ人数を取得する
         * @summary 目標リーチ人数取得
         * @param {number} reachRate 目標リーチ率
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetReachNumber: async (reachRate: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, companyId: number, targetIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachRate' is not null or undefined
            assertParamExists('getTargetReachNumber', 'reachRate', reachRate)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTargetReachNumber', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTargetReachNumber', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTargetReachNumber', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getTargetReachNumber', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTargetReachNumber', 'companyId', companyId)
            const localVarPath = `/target-reach-number`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (reachRate !== undefined) {
                localVarQueryParameter['reachRate'] = reachRate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * お気に入りプロジェクトの状態を更新する
         * @summary プロジェクトお気に入り更新
         * @param {number} projectId 
         * @param {ChangeFavoriteProjectForm} [changeFavoriteProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectsProjectIdFavoritesIsFavorite: async (projectId: number, changeFavoriteProjectForm?: ChangeFavoriteProjectForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('putProjectsProjectIdFavoritesIsFavorite', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/favorites/is-favorite`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeFavoriteProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクト更新
         * @summary リーチ分析プロジェクト更新
         * @param {number} reachAnalysisProjectId 
         * @param {ReachAnalysisProjectForm} [reachAnalysisProjectForm] リーチ分析-プロジェクト登録・更新用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjectsReachAnalysisProjectId: async (reachAnalysisProjectId: number, reachAnalysisProjectForm?: ReachAnalysisProjectForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('putReachAnalysisProjectsReachAnalysisProjectId', 'reachAnalysisProjectId', reachAnalysisProjectId)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * プロジェクトの削除を実行する
         * @summary リーチ分析プロジェクト削除
         * @param {number} reachAnalysisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニーに紐づくプロジェクトの一覧情報を取得する
         * @summary リーチ分析プロジェクト一覧
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdReachAnalysisProjects(companyId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdReachAnalysisProjects(companyId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析-プロジェクトの情報を取得する
         * @summary リーチ分析プロジェクト取得
         * @param {number} reachAnalysisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProject(reachAnalysisProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProject(reachAnalysisProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 目標リーチ人数を取得する
         * @summary 目標リーチ人数取得
         * @param {number} reachRate 目標リーチ率
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetReachNumber(reachRate: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, companyId: number, targetIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TargetReachNumber>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetReachNumber(reachRate, startDate, endDate, areaCode, useCustomTarget, companyId, targetIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * お気に入りプロジェクトの状態を更新する
         * @summary プロジェクトお気に入り更新
         * @param {number} projectId 
         * @param {ChangeFavoriteProjectForm} [changeFavoriteProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProjectsProjectIdFavoritesIsFavorite(projectId: number, changeFavoriteProjectForm?: ChangeFavoriteProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProjectsProjectIdFavoritesIsFavorite(projectId, changeFavoriteProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクト更新
         * @summary リーチ分析プロジェクト更新
         * @param {number} reachAnalysisProjectId 
         * @param {ReachAnalysisProjectForm} [reachAnalysisProjectForm] リーチ分析-プロジェクト登録・更新用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId: number, reachAnalysisProjectForm?: ReachAnalysisProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId, reachAnalysisProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * プロジェクトの削除を実行する
         * @summary リーチ分析プロジェクト削除
         * @param {number} reachAnalysisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニーに紐づくプロジェクトの一覧情報を取得する
         * @summary リーチ分析プロジェクト一覧
         * @param {number} companyId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects(companyId: number, offset?: number, limit?: number, options?: any): AxiosPromise<ProjectList> {
            return localVarFp.getCompaniesCompanyIdReachAnalysisProjects(companyId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析-プロジェクトの情報を取得する
         * @summary リーチ分析プロジェクト取得
         * @param {number} reachAnalysisProjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProject(reachAnalysisProjectId: number, options?: any): AxiosPromise<ReachAnalysisProject> {
            return localVarFp.getReachAnalysisProject(reachAnalysisProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 目標リーチ人数を取得する
         * @summary 目標リーチ人数取得
         * @param {number} reachRate 目標リーチ率
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetReachNumber(reachRate: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, companyId: number, targetIds?: Array<number>, options?: any): AxiosPromise<TargetReachNumber> {
            return localVarFp.getTargetReachNumber(reachRate, startDate, endDate, areaCode, useCustomTarget, companyId, targetIds, options).then((request) => request(axios, basePath));
        },
        /**
         * お気に入りプロジェクトの状態を更新する
         * @summary プロジェクトお気に入り更新
         * @param {number} projectId 
         * @param {ChangeFavoriteProjectForm} [changeFavoriteProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectsProjectIdFavoritesIsFavorite(projectId: number, changeFavoriteProjectForm?: ChangeFavoriteProjectForm, options?: any): AxiosPromise<FavoriteProject> {
            return localVarFp.putProjectsProjectIdFavoritesIsFavorite(projectId, changeFavoriteProjectForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクト更新
         * @summary リーチ分析プロジェクト更新
         * @param {number} reachAnalysisProjectId 
         * @param {ReachAnalysisProjectForm} [reachAnalysisProjectForm] リーチ分析-プロジェクト登録・更新用Form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId: number, reachAnalysisProjectForm?: ReachAnalysisProjectForm, options?: any): AxiosPromise<ReachAnalysisProject> {
            return localVarFp.putReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId, reachAnalysisProjectForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * プロジェクトの削除を実行する
     * @summary リーチ分析プロジェクト削除
     * @param {number} reachAnalysisProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId: number, options?: any) {
        return ProjectApiFp(this.configuration).deleteReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニーに紐づくプロジェクトの一覧情報を取得する
     * @summary リーチ分析プロジェクト一覧
     * @param {number} companyId 
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getCompaniesCompanyIdReachAnalysisProjects(companyId: number, offset?: number, limit?: number, options?: any) {
        return ProjectApiFp(this.configuration).getCompaniesCompanyIdReachAnalysisProjects(companyId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析-プロジェクトの情報を取得する
     * @summary リーチ分析プロジェクト取得
     * @param {number} reachAnalysisProjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getReachAnalysisProject(reachAnalysisProjectId: number, options?: any) {
        return ProjectApiFp(this.configuration).getReachAnalysisProject(reachAnalysisProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 目標リーチ人数を取得する
     * @summary 目標リーチ人数取得
     * @param {number} reachRate 目標リーチ率
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {number} companyId カンパニーID
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getTargetReachNumber(reachRate: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, companyId: number, targetIds?: Array<number>, options?: any) {
        return ProjectApiFp(this.configuration).getTargetReachNumber(reachRate, startDate, endDate, areaCode, useCustomTarget, companyId, targetIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * お気に入りプロジェクトの状態を更新する
     * @summary プロジェクトお気に入り更新
     * @param {number} projectId 
     * @param {ChangeFavoriteProjectForm} [changeFavoriteProjectForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public putProjectsProjectIdFavoritesIsFavorite(projectId: number, changeFavoriteProjectForm?: ChangeFavoriteProjectForm, options?: any) {
        return ProjectApiFp(this.configuration).putProjectsProjectIdFavoritesIsFavorite(projectId, changeFavoriteProjectForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクト更新
     * @summary リーチ分析プロジェクト更新
     * @param {number} reachAnalysisProjectId 
     * @param {ReachAnalysisProjectForm} [reachAnalysisProjectForm] リーチ分析-プロジェクト登録・更新用Form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public putReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId: number, reachAnalysisProjectForm?: ReachAnalysisProjectForm, options?: any) {
        return ProjectApiFp(this.configuration).putReachAnalysisProjectsReachAnalysisProjectId(reachAnalysisProjectId, reachAnalysisProjectForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReachAnalysisApi - axios parameter creator
 * @export
 */
export const ReachAnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リーチ分析の追加出稿のグラフデータを取得するAPI
         * @summary リーチ分析 - 追加出稿取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'VIEWING_RATE' | 'CONTENT_RATE'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'VIEWING_RATE' | 'CONTENT_RATE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement', 'areaCode', areaCode)
            // verify required parameter 'viewType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement', 'viewType', viewType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/additional-placement`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (viewType !== undefined) {
                localVarQueryParameter['viewType'] = viewType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の追加出稿のCSVデータを取得するAPI
         * @summary リーチ分析 - 追加出稿CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv', 'areaCode', areaCode)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/additional-placement/csv`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の平均フリークエンシーのグラフデータを取得する
         * @summary リーチ分析 - 平均フリークエンシー取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {Array<number>} [fqThresholds] FQ閾値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', fqThresholds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency', 'areaCode', areaCode)
            // verify required parameter 'compareType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency', 'compareType', compareType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/average-frequency`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (compareType !== undefined) {
                localVarQueryParameter['compareType'] = compareType;
            }

            if (fqThresholds) {
                localVarQueryParameter['fqThresholds'] = fqThresholds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の平均フリークエンシーのCSVデータを取得するAPI
         * @summary リーチ分析 - 平均フリークエンシーCSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {Array<number>} [fqThresholds] FQ閾値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', fqThresholds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv', 'areaCode', areaCode)
            // verify required parameter 'compareType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv', 'compareType', compareType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/average-frequency/csv`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (compareType !== undefined) {
                localVarQueryParameter['compareType'] = compareType;
            }

            if (fqThresholds) {
                localVarQueryParameter['fqThresholds'] = fqThresholds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の有効リーチ人数のグラフデータを取得するAPI
         * @summary リーチ分析 - 有効リーチ取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach', 'areaCode', areaCode)
            // verify required parameter 'compareType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach', 'compareType', compareType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/effective-reach`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (compareType !== undefined) {
                localVarQueryParameter['compareType'] = compareType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の有効リーチ人数のCSVデータを取得するAPI
         * @summary リーチ分析 - 有効リーチCSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv', 'areaCode', areaCode)
            // verify required parameter 'compareType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv', 'compareType', compareType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/effective-reach/csv`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (compareType !== undefined) {
                localVarQueryParameter['compareType'] = compareType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の出稿量のグラフデータを取得するAPI
         * @summary リーチ分析 - 出稿量取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount', 'areaCode', areaCode)
            // verify required parameter 'compareType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount', 'compareType', compareType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/placement-amount`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (compareType !== undefined) {
                localVarQueryParameter['compareType'] = compareType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の出稿量のCSVデータを取得するAPI
         * @summary リーチ分析 - 出稿量CSVダウンロード
         * @param {number} reachAnalysisProjectId 
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv', 'areaCode', areaCode)
            // verify required parameter 'compareType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv', 'compareType', compareType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/placement-amount/csv`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (compareType !== undefined) {
                localVarQueryParameter['compareType'] = compareType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の出稿量(SOV)のグラフデータを取得するAPI
         * @summary リーチ分析 - 出稿量取得 - SOV
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov', 'areaCode', areaCode)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/placement-amount-sov`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の出稿量(SOV)のCSVデータを取得するAPI
         * @summary リーチ分析 - 出稿量（SOV）CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv', 'areaCode', areaCode)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/placement-amount-sov/csv`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の番組評価の表データを取得するAPI
         * @summary リーチ分析 - 番組評価取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'VIEWING_RATE' | 'CONTENT_RATE'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'VIEWING_RATE' | 'CONTENT_RATE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation', 'areaCode', areaCode)
            // verify required parameter 'viewType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation', 'viewType', viewType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/program-evaluation`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (viewType !== undefined) {
                localVarQueryParameter['viewType'] = viewType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析のサマリ部分を取得するAPI
         * @summary リーチ分析 - サマリ取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary', 'areaCode', areaCode)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/reach-analysis-summary`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析のターゲット比率のグラフデータを取得する
         * @summary リーチ分析 - ターゲット比率取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [productIdOfProductCompare] 商品ID
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} [compareType] 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdTargetRate: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, productIdOfProductCompare?: number, compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRate', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRate', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRate', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRate', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRate', 'areaCode', areaCode)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/target-rate`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (productIdOfProductCompare !== undefined) {
                localVarQueryParameter['productIdOfProductCompare'] = productIdOfProductCompare;
            }

            if (compareType !== undefined) {
                localVarQueryParameter['compareType'] = compareType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析のターゲット比率のCSVデータを取得するAPI
         * @summary リーチ分析 - ターゲット比率CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [productIdOfProductCompare] 商品ID
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} [compareType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, productIdOfProductCompare?: number, compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv', 'areaCode', areaCode)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/target-rate/csv`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (productIdOfProductCompare !== undefined) {
                localVarQueryParameter['productIdOfProductCompare'] = productIdOfProductCompare;
            }

            if (compareType !== undefined) {
                localVarQueryParameter['compareType'] = compareType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の視聴回数のグラフデータを取得するAPI
         * @summary リーチ分析 - 視聴回数取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'TARGET' | 'TOTAL'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdViews: async (reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'TARGET' | 'TOTAL', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProjectId' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdViews', 'reachAnalysisProjectId', reachAnalysisProjectId)
            // verify required parameter 'effectiveNumberOfViews' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdViews', 'effectiveNumberOfViews', effectiveNumberOfViews)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdViews', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdViews', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdViews', 'areaCode', areaCode)
            // verify required parameter 'viewType' is not null or undefined
            assertParamExists('getReachAnalysisProjectsReachAnalysisProjectIdViews', 'viewType', viewType)
            const localVarPath = `/reach-analysis-projects/{reachAnalysisProjectId}/views`
                .replace(`{${"reachAnalysisProjectId"}}`, encodeURIComponent(String(reachAnalysisProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (effectiveNumberOfViews !== undefined) {
                localVarQueryParameter['effectiveNumberOfViews'] = effectiveNumberOfViews;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (viewType !== undefined) {
                localVarQueryParameter['viewType'] = viewType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReachAnalysisApi - functional programming interface
 * @export
 */
export const ReachAnalysisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReachAnalysisApiAxiosParamCreator(configuration)
    return {
        /**
         * リーチ分析の追加出稿のグラフデータを取得するAPI
         * @summary リーチ分析 - 追加出稿取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'VIEWING_RATE' | 'CONTENT_RATE'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'VIEWING_RATE' | 'CONTENT_RATE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdditionalPlacement>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の追加出稿のCSVデータを取得するAPI
         * @summary リーチ分析 - 追加出稿CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の平均フリークエンシーのグラフデータを取得する
         * @summary リーチ分析 - 平均フリークエンシー取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {Array<number>} [fqThresholds] FQ閾値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', fqThresholds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AverageFrequency>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, fqThresholds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の平均フリークエンシーのCSVデータを取得するAPI
         * @summary リーチ分析 - 平均フリークエンシーCSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {Array<number>} [fqThresholds] FQ閾値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', fqThresholds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, fqThresholds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の有効リーチ人数のグラフデータを取得するAPI
         * @summary リーチ分析 - 有効リーチ取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EffectiveReach>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の有効リーチ人数のCSVデータを取得するAPI
         * @summary リーチ分析 - 有効リーチCSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の出稿量のグラフデータを取得するAPI
         * @summary リーチ分析 - 出稿量取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grp>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の出稿量のCSVデータを取得するAPI
         * @summary リーチ分析 - 出稿量CSVダウンロード
         * @param {number} reachAnalysisProjectId 
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の出稿量(SOV)のグラフデータを取得するAPI
         * @summary リーチ分析 - 出稿量取得 - SOV
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrpForSov>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の出稿量(SOV)のCSVデータを取得するAPI
         * @summary リーチ分析 - 出稿量（SOV）CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の番組評価の表データを取得するAPI
         * @summary リーチ分析 - 番組評価取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'VIEWING_RATE' | 'CONTENT_RATE'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'VIEWING_RATE' | 'CONTENT_RATE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProgramEvaluations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析のサマリ部分を取得するAPI
         * @summary リーチ分析 - サマリ取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisSummaries>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析のターゲット比率のグラフデータを取得する
         * @summary リーチ分析 - ターゲット比率取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [productIdOfProductCompare] 商品ID
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} [compareType] 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdTargetRate(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, productIdOfProductCompare?: number, compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TargetRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdTargetRate(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, productIdOfProductCompare, compareType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析のターゲット比率のCSVデータを取得するAPI
         * @summary リーチ分析 - ターゲット比率CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [productIdOfProductCompare] 商品ID
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} [compareType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, productIdOfProductCompare?: number, compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, productIdOfProductCompare, compareType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の視聴回数のグラフデータを取得するAPI
         * @summary リーチ分析 - 視聴回数取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'TARGET' | 'TOTAL'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjectsReachAnalysisProjectIdViews(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'TARGET' | 'TOTAL', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Views>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjectsReachAnalysisProjectIdViews(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReachAnalysisApi - factory interface
 * @export
 */
export const ReachAnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReachAnalysisApiFp(configuration)
    return {
        /**
         * リーチ分析の追加出稿のグラフデータを取得するAPI
         * @summary リーチ分析 - 追加出稿取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'VIEWING_RATE' | 'CONTENT_RATE'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'VIEWING_RATE' | 'CONTENT_RATE', options?: any): AxiosPromise<AdditionalPlacement> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の追加出稿のCSVデータを取得するAPI
         * @summary リーチ分析 - 追加出稿CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any): AxiosPromise<any> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の平均フリークエンシーのグラフデータを取得する
         * @summary リーチ分析 - 平均フリークエンシー取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {Array<number>} [fqThresholds] FQ閾値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', fqThresholds?: Array<number>, options?: any): AxiosPromise<AverageFrequency> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, fqThresholds, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の平均フリークエンシーのCSVデータを取得するAPI
         * @summary リーチ分析 - 平均フリークエンシーCSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {Array<number>} [fqThresholds] FQ閾値
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', fqThresholds?: Array<number>, options?: any): AxiosPromise<any> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, fqThresholds, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の有効リーチ人数のグラフデータを取得するAPI
         * @summary リーチ分析 - 有効リーチ取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): AxiosPromise<EffectiveReach> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の有効リーチ人数のCSVデータを取得するAPI
         * @summary リーチ分析 - 有効リーチCSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): AxiosPromise<any> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の出稿量のグラフデータを取得するAPI
         * @summary リーチ分析 - 出稿量取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): AxiosPromise<Grp> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の出稿量のCSVデータを取得するAPI
         * @summary リーチ分析 - 出稿量CSVダウンロード
         * @param {number} reachAnalysisProjectId 
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): AxiosPromise<any> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の出稿量(SOV)のグラフデータを取得するAPI
         * @summary リーチ分析 - 出稿量取得 - SOV
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any): AxiosPromise<GrpForSov> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の出稿量(SOV)のCSVデータを取得するAPI
         * @summary リーチ分析 - 出稿量（SOV）CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any): AxiosPromise<any> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の番組評価の表データを取得するAPI
         * @summary リーチ分析 - 番組評価取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'VIEWING_RATE' | 'CONTENT_RATE'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'VIEWING_RATE' | 'CONTENT_RATE', options?: any): AxiosPromise<ProgramEvaluations> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析のサマリ部分を取得するAPI
         * @summary リーチ分析 - サマリ取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any): AxiosPromise<ReachAnalysisSummaries> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析のターゲット比率のグラフデータを取得する
         * @summary リーチ分析 - ターゲット比率取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [productIdOfProductCompare] 商品ID
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} [compareType] 比較タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdTargetRate(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, productIdOfProductCompare?: number, compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): AxiosPromise<TargetRate> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdTargetRate(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, productIdOfProductCompare, compareType, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析のターゲット比率のCSVデータを取得するAPI
         * @summary リーチ分析 - ターゲット比率CSVダウンロード
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {number} [productIdOfProductCompare] 商品ID
         * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} [compareType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, productIdOfProductCompare?: number, compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any): AxiosPromise<any> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, productIdOfProductCompare, compareType, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の視聴回数のグラフデータを取得するAPI
         * @summary リーチ分析 - 視聴回数取得
         * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
         * @param {number} effectiveNumberOfViews 有効視聴回数
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {'TARGET' | 'TOTAL'} viewType 表示タイプ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjectsReachAnalysisProjectIdViews(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'TARGET' | 'TOTAL', options?: any): AxiosPromise<Views> {
            return localVarFp.getReachAnalysisProjectsReachAnalysisProjectIdViews(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReachAnalysisApi - object-oriented interface
 * @export
 * @class ReachAnalysisApi
 * @extends {BaseAPI}
 */
export class ReachAnalysisApi extends BaseAPI {
    /**
     * リーチ分析の追加出稿のグラフデータを取得するAPI
     * @summary リーチ分析 - 追加出稿取得
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'VIEWING_RATE' | 'CONTENT_RATE'} viewType 表示タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'VIEWING_RATE' | 'CONTENT_RATE', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の追加出稿のCSVデータを取得するAPI
     * @summary リーチ分析 - 追加出稿CSVダウンロード
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の平均フリークエンシーのグラフデータを取得する
     * @summary リーチ分析 - 平均フリークエンシー取得
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
     * @param {Array<number>} [fqThresholds] FQ閾値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', fqThresholds?: Array<number>, options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, fqThresholds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の平均フリークエンシーのCSVデータを取得するAPI
     * @summary リーチ分析 - 平均フリークエンシーCSVダウンロード
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
     * @param {Array<number>} [fqThresholds] FQ閾値
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', fqThresholds?: Array<number>, options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, fqThresholds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の有効リーチ人数のグラフデータを取得するAPI
     * @summary リーチ分析 - 有効リーチ取得
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の有効リーチ人数のCSVデータを取得するAPI
     * @summary リーチ分析 - 有効リーチCSVダウンロード
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の出稿量のグラフデータを取得するAPI
     * @summary リーチ分析 - 出稿量取得
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の出稿量のCSVデータを取得するAPI
     * @summary リーチ分析 - 出稿量CSVダウンロード
     * @param {number} reachAnalysisProjectId 
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} compareType 比較タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, compareType: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, compareType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の出稿量(SOV)のグラフデータを取得するAPI
     * @summary リーチ分析 - 出稿量取得 - SOV
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の出稿量(SOV)のCSVデータを取得するAPI
     * @summary リーチ分析 - 出稿量（SOV）CSVダウンロード
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の番組評価の表データを取得するAPI
     * @summary リーチ分析 - 番組評価取得
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'VIEWING_RATE' | 'CONTENT_RATE'} viewType 表示タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'VIEWING_RATE' | 'CONTENT_RATE', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析のサマリ部分を取得するAPI
     * @summary リーチ分析 - サマリ取得
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdReachAnalysisSummary(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析のターゲット比率のグラフデータを取得する
     * @summary リーチ分析 - ターゲット比率取得
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {number} [productIdOfProductCompare] 商品ID
     * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} [compareType] 比較タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdTargetRate(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, productIdOfProductCompare?: number, compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdTargetRate(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, productIdOfProductCompare, compareType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析のターゲット比率のCSVデータを取得するAPI
     * @summary リーチ分析 - ターゲット比率CSVダウンロード
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {number} [productIdOfProductCompare] 商品ID
     * @param {'PAST' | 'COMPETITIVE' | 'OWN_COMPANY'} [compareType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, productIdOfProductCompare?: number, compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, productIdOfProductCompare, compareType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の視聴回数のグラフデータを取得するAPI
     * @summary リーチ分析 - 視聴回数取得
     * @param {number} reachAnalysisProjectId リーチ分析プロジェクトID
     * @param {number} effectiveNumberOfViews 有効視聴回数
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {'TARGET' | 'TOTAL'} viewType 表示タイプ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysisApi
     */
    public getReachAnalysisProjectsReachAnalysisProjectIdViews(reachAnalysisProjectId: number, effectiveNumberOfViews: number, startDate: string, endDate: string, areaCode: string, viewType: 'TARGET' | 'TOTAL', options?: any) {
        return ReachAnalysisApiFp(this.configuration).getReachAnalysisProjectsReachAnalysisProjectIdViews(reachAnalysisProjectId, effectiveNumberOfViews, startDate, endDate, areaCode, viewType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReachAnalysis2Api - axios parameter creator
 * @export
 */
export const ReachAnalysis2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リーチ分析プロジェクトを削除する
         * @summary プロジェクト削除
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReachAnalysisProjects2ReachAnalysisProject2Id: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('deleteReachAnalysisProjects2ReachAnalysisProject2Id', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのカードを削除する
         * @summary カードの削除
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId: async (reachAnalysisProject2Id: number, cardId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId', 'cardId', cardId)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cards/{cardId}`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カンパニーに紐づくリーチ分析プロジェクトの一覧情報を取得する
         * @summary プロジェクト一覧
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects2`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定商品の直近キャンペーン期間を取得する（最長91日間、直近キャンペーンが無かったら確報直近28日間）
         * @summary 登録商品の分析期間取得
         * @param {number} companyId 
         * @param {Array<number>} cmProductIds CM商品IDリスト
         * @param {string} areaCode エリアコード
         * @param {'ALL' | 'TIME' | 'SPOT'} cmBuyingKind CM種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod: async (companyId: number, cmProductIds: Array<number>, areaCode: string, cmBuyingKind: 'ALL' | 'TIME' | 'SPOT', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod', 'companyId', companyId)
            // verify required parameter 'cmProductIds' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod', 'cmProductIds', cmProductIds)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod', 'areaCode', areaCode)
            // verify required parameter 'cmBuyingKind' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod', 'cmBuyingKind', cmBuyingKind)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects2/products/analysis-period`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (cmBuyingKind !== undefined) {
                localVarQueryParameter['cmBuyingKind'] = cmBuyingKind;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定商品のGRPグラフデータを取得する
         * @summary 登録商品のGRPグラフ
         * @param {number} companyId 
         * @param {Array<number>} cmProductIds CM商品IDのリスト
         * @param {string} areaCode エリアコード
         * @param {'ALL' | 'TIME' | 'SPOT'} cmBuyingKind CM種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph: async (companyId: number, cmProductIds: Array<number>, areaCode: string, cmBuyingKind: 'ALL' | 'TIME' | 'SPOT', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph', 'companyId', companyId)
            // verify required parameter 'cmProductIds' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph', 'cmProductIds', cmProductIds)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph', 'areaCode', areaCode)
            // verify required parameter 'cmBuyingKind' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph', 'cmBuyingKind', cmBuyingKind)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects2/products/grp-graph`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (cmBuyingKind !== undefined) {
                localVarQueryParameter['cmBuyingKind'] = cmBuyingKind;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトの商品／ブランドのレコメンド(GRP上位5件降順)を取得する
         * @summary 商品／ブランドのレコメンド
         * @param {number} companyId カンパニーID
         * @param {string} areaCode エリアコード
         * @param {Array<number>} cmSponsorIds CMスポンサーIDのリスト
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend: async (companyId: number, areaCode: string, cmSponsorIds: Array<number>, startDate: string, endDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend', 'companyId', companyId)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend', 'areaCode', areaCode)
            // verify required parameter 'cmSponsorIds' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend', 'cmSponsorIds', cmSponsorIds)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend', 'endDate', endDate)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects2/products/recommend`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析の商品／ブランド一覧を取得する
         * @summary リーチ分析の商品／ブランド検索
         * @param {number} companyId 
         * @param {Array<number>} [cmSponsorIds] CMスポンサーIDのリスト
         * @param {string} [productName] 商品／ブランド名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch: async (companyId: number, cmSponsorIds?: Array<number>, productName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects2/products/search`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (productName !== undefined) {
                localVarQueryParameter['productName'] = productName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトの基本情報を取得する
         * @summary プロジェクト取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2Id: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2Id', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カードの一覧の情報を取得する
         * @summary カードの一覧取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCards: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCards', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cards`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップを取得する
         * @summary CM出稿ヒートマップ取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ枠内番組情報を取得する
         * @summary CM出稿ヒートマップ枠内番組情報取得
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {string} stationNetworkCode ネットワークコード
         * @param {number} dayOfWeek 曜日番号(1&#x3D;月,...,7&#x3D;日)
         * @param {number} broadcastTimezone 放送時刻
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms: async (reachAnalysisProject2Id: number, cardId: number, stationNetworkCode: string, dayOfWeek: number, broadcastTimezone: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms', 'cardId', cardId)
            // verify required parameter 'stationNetworkCode' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms', 'stationNetworkCode', stationNetworkCode)
            // verify required parameter 'dayOfWeek' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms', 'dayOfWeek', dayOfWeek)
            // verify required parameter 'broadcastTimezone' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms', 'broadcastTimezone', broadcastTimezone)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap/cards/{cardId}/programs`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stationNetworkCode !== undefined) {
                localVarQueryParameter['stationNetworkCode'] = stationNetworkCode;
            }

            if (dayOfWeek !== undefined) {
                localVarQueryParameter['dayOfWeek'] = dayOfWeek;
            }

            if (broadcastTimezone !== undefined) {
                localVarQueryParameter['broadcastTimezone'] = broadcastTimezone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCMヒートマップ比較対象カード表示設定を取得する
         * @summary CMヒートマップ表示カード設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap/display-cards`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップの設定を取得する
         * @summary CM出稿ヒートマップ設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap/settings`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCPE比較を取得する
         * @summary CPR比較取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cpe-comparison`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのグラフごとの比較対象カード表示設定を取得する(メインカード固定のセクションでこのAPIを呼び出す)
         * @summary グラフごとの表示カード設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {'EFFECTIVE_REACH' | 'CPE_COMPARISON'} section セクション名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards: async (reachAnalysisProject2Id: number, section: 'EFFECTIVE_REACH' | 'CPE_COMPARISON', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            // verify required parameter 'section' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards', 'section', section)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/display-cards`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのエフェクティブリーチグラフデータを取得する
         * @summary エフェクティブリーチグラフ取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/effective-reach`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのKPIを取得する
         * @summary KPI取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdKpi: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdKpi', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/kpi`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定を取得する
         * @summary セクション表示設定取得
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/section-display-settings`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定のデフォルト値を取得する
         * @summary セクション表示設定デフォルト値取得
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/section-display-settings/default-value`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのセクションごとの更新ステータスを取得する
         * @summary セクションごとの更新ステータス取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdStatus: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('getReachAnalysisProjects2ReachAnalysisProject2IdStatus', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/status`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトを作成する
         * @summary プロジェクト新規作成
         * @param {number} companyId 
         * @param {ReachAnalysisProject2Form} [reachAnalysisProject2Form] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdReachAnalysisProjects2: async (companyId: number, reachAnalysisProject2Form?: ReachAnalysisProject2Form, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdReachAnalysisProjects2', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects2`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2Form, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトを複製する
         * @summary プロジェクト複製
         * @param {number} companyId カンパニーID
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {ReachAnalysisProject2CopyForm} [reachAnalysisProject2CopyForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdReachAnalysisProjects2Copy: async (companyId: number, reachAnalysisProject2Id: number, reachAnalysisProject2CopyForm?: ReachAnalysisProject2CopyForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompaniesCompanyIdReachAnalysisProjects2Copy', 'companyId', companyId)
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('postCompaniesCompanyIdReachAnalysisProjects2Copy', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/companies/{companyId}/reach-analysis-projects2/{reachAnalysisProject2Id}/copy`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2CopyForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトにカードを追加する
         * @summary カードの追加
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CardForm} [reachAnalysisProject2CardForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReachAnalysisProjects2ReachAnalysisProject2IdCards: async (reachAnalysisProject2Id: number, reachAnalysisProject2CardForm?: ReachAnalysisProject2CardForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('postReachAnalysisProjects2ReachAnalysisProject2IdCards', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cards`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2CardForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのカードを複製し、計算済みデータがあればコピーする
         * @summary カードの複製
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy: async (reachAnalysisProject2Id: number, cardId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy', 'cardId', cardId)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cards/{cardId}/copy`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのセクションごとのレポートの更新チェックをする
         * @summary セクションごとのレポート更新チェックをする
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/check-reports`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのカードを編集する
         * @summary カードの編集
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CardForm} [reachAnalysisProject2CardForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId: async (reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CardForm?: ReachAnalysisProject2CardForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId', 'cardId', cardId)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cards/{cardId}`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2CardForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのカードの並び順を変更する
         * @summary カードの並び変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CardOrder} [reachAnalysisProject2CardOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder: async (reachAnalysisProject2Id: number, reachAnalysisProject2CardOrder?: ReachAnalysisProject2CardOrder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cards/order`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2CardOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCMヒートマップ比較対象カード表示設定を変更する
         * @summary CMヒートマップ表示カード設定変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CmHeatmapDisplay} [reachAnalysisProject2CmHeatmapDisplay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards: async (reachAnalysisProject2Id: number, reachAnalysisProject2CmHeatmapDisplay?: ReachAnalysisProject2CmHeatmapDisplay, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap/display-cards`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2CmHeatmapDisplay, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ表示条件を変更する
         * @summary CM出稿ヒートマップ表示条件変更
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardSettingForm} [reachAnalysisProject2CmHeatmapCardSettingForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId: async (reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardSettingForm?: ReachAnalysisProject2CmHeatmapCardSettingForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId', 'cardId', cardId)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap/settings/cards/{cardId}`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2CmHeatmapCardSettingForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ凡例の閾値を変更する
         * @summary CM出稿ヒートマップ凡例の閾値変更
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardThresholdForm} [reachAnalysisProject2CmHeatmapCardThresholdForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue: async (reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardThresholdForm?: ReachAnalysisProject2CmHeatmapCardThresholdForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue', 'cardId', cardId)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap/settings/cards/{cardId}/threshold-value`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2CmHeatmapCardThresholdForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ凡例の閾値をリセットする
         * @summary CM出稿ヒートマップの閾値をリセット
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardThresholdResetForm} [reachAnalysisProject2CmHeatmapCardThresholdResetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset: async (reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardThresholdResetForm?: ReachAnalysisProject2CmHeatmapCardThresholdResetForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            // verify required parameter 'cardId' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset', 'cardId', cardId)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap/settings/cards/{cardId}/threshold-value/reset`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)))
                .replace(`{${"cardId"}}`, encodeURIComponent(String(cardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2CmHeatmapCardThresholdResetForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ片表示に変更する
         * @summary CM出稿ヒートマップ片表示に変更
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle: async (reachAnalysisProject2Id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/cm-heatmap/settings/single`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのグラフごとの比較対象カード表示設定を変更する（メインカード固定とするため、比較対象のカードのみリクエスト）
         * @summary グラフごとの表示カード設定変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2SectionDisplaySetting} [reachAnalysisProject2SectionDisplaySetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdDisplay: async (reachAnalysisProject2Id: number, reachAnalysisProject2SectionDisplaySetting?: ReachAnalysisProject2SectionDisplaySetting, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdDisplay', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/display-cards`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2SectionDisplaySetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのプロジェクト名を編集する
         * @summary プロジェクト名編集
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2ProjectNameForm} [reachAnalysisProject2ProjectNameForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdProjectName: async (reachAnalysisProject2Id: number, reachAnalysisProject2ProjectNameForm?: ReachAnalysisProject2ProjectNameForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdProjectName', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/project-name`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2ProjectNameForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定を更新する
         * @summary セクション表示設定更新
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {ReachAnalysisProject2SectionDisplaySettingForm} [reachAnalysisProject2SectionDisplaySettingForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings: async (reachAnalysisProject2Id: number, reachAnalysisProject2SectionDisplaySettingForm?: ReachAnalysisProject2SectionDisplaySettingForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reachAnalysisProject2Id' is not null or undefined
            assertParamExists('putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings', 'reachAnalysisProject2Id', reachAnalysisProject2Id)
            const localVarPath = `/reach-analysis-projects2/{reachAnalysisProject2Id}/section-display-settings`
                .replace(`{${"reachAnalysisProject2Id"}}`, encodeURIComponent(String(reachAnalysisProject2Id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachAnalysisProject2SectionDisplaySettingForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReachAnalysis2Api - functional programming interface
 * @export
 */
export const ReachAnalysis2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReachAnalysis2ApiAxiosParamCreator(configuration)
    return {
        /**
         * リーチ分析プロジェクトを削除する
         * @summary プロジェクト削除
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのカードを削除する
         * @summary カードの削除
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id: number, cardId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カンパニーに紐づくリーチ分析プロジェクトの一覧情報を取得する
         * @summary プロジェクト一覧
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdReachAnalysisProjects2(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2List>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdReachAnalysisProjects2(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定商品の直近キャンペーン期間を取得する（最長91日間、直近キャンペーンが無かったら確報直近28日間）
         * @summary 登録商品の分析期間取得
         * @param {number} companyId 
         * @param {Array<number>} cmProductIds CM商品IDリスト
         * @param {string} areaCode エリアコード
         * @param {'ALL' | 'TIME' | 'SPOT'} cmBuyingKind CM種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod(companyId: number, cmProductIds: Array<number>, areaCode: string, cmBuyingKind: 'ALL' | 'TIME' | 'SPOT', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2AnalysisPeriod>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod(companyId, cmProductIds, areaCode, cmBuyingKind, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定商品のGRPグラフデータを取得する
         * @summary 登録商品のGRPグラフ
         * @param {number} companyId 
         * @param {Array<number>} cmProductIds CM商品IDのリスト
         * @param {string} areaCode エリアコード
         * @param {'ALL' | 'TIME' | 'SPOT'} cmBuyingKind CM種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph(companyId: number, cmProductIds: Array<number>, areaCode: string, cmBuyingKind: 'ALL' | 'TIME' | 'SPOT', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReachAnalysisProject2ProductsGrpGraph>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph(companyId, cmProductIds, areaCode, cmBuyingKind, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトの商品／ブランドのレコメンド(GRP上位5件降順)を取得する
         * @summary 商品／ブランドのレコメンド
         * @param {number} companyId カンパニーID
         * @param {string} areaCode エリアコード
         * @param {Array<number>} cmSponsorIds CMスポンサーIDのリスト
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend(companyId: number, areaCode: string, cmSponsorIds: Array<number>, startDate: string, endDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2RecommendProducts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend(companyId, areaCode, cmSponsorIds, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析の商品／ブランド一覧を取得する
         * @summary リーチ分析の商品／ブランド検索
         * @param {number} companyId 
         * @param {Array<number>} [cmSponsorIds] CMスポンサーIDのリスト
         * @param {string} [productName] 商品／ブランド名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch(companyId: number, cmSponsorIds?: Array<number>, productName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReachAnalysisProject2Products>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch(companyId, cmSponsorIds, productName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトの基本情報を取得する
         * @summary プロジェクト取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2BasicInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カードの一覧の情報を取得する
         * @summary カードの一覧取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CardList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップを取得する
         * @summary CM出稿ヒートマップ取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmap>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ枠内番組情報を取得する
         * @summary CM出稿ヒートマップ枠内番組情報取得
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {string} stationNetworkCode ネットワークコード
         * @param {number} dayOfWeek 曜日番号(1&#x3D;月,...,7&#x3D;日)
         * @param {number} broadcastTimezone 放送時刻
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms(reachAnalysisProject2Id: number, cardId: number, stationNetworkCode: string, dayOfWeek: number, broadcastTimezone: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmapCardPrograms>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms(reachAnalysisProject2Id, cardId, stationNetworkCode, dayOfWeek, broadcastTimezone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCMヒートマップ比較対象カード表示設定を取得する
         * @summary CMヒートマップ表示カード設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmapDisplay>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップの設定を取得する
         * @summary CM出稿ヒートマップ設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmapSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCPE比較を取得する
         * @summary CPR比較取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CpeComparison>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのグラフごとの比較対象カード表示設定を取得する(メインカード固定のセクションでこのAPIを呼び出す)
         * @summary グラフごとの表示カード設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {'EFFECTIVE_REACH' | 'CPE_COMPARISON'} section セクション名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards(reachAnalysisProject2Id: number, section: 'EFFECTIVE_REACH' | 'CPE_COMPARISON', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2SectionDisplay>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards(reachAnalysisProject2Id, section, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのエフェクティブリーチグラフデータを取得する
         * @summary エフェクティブリーチグラフ取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2ReachAnalysisGraph>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのKPIを取得する
         * @summary KPI取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdKpi(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2Kpi>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdKpi(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定を取得する
         * @summary セクション表示設定取得
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2SectionDisplaySettingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定のデフォルト値を取得する
         * @summary セクション表示設定デフォルト値取得
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2SectionDisplaySettingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのセクションごとの更新ステータスを取得する
         * @summary セクションごとの更新ステータス取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReachAnalysisProjects2ReachAnalysisProject2IdStatus(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2Status>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReachAnalysisProjects2ReachAnalysisProject2IdStatus(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトを作成する
         * @summary プロジェクト新規作成
         * @param {number} companyId 
         * @param {ReachAnalysisProject2Form} [reachAnalysisProject2Form] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompaniesCompanyIdReachAnalysisProjects2(companyId: number, reachAnalysisProject2Form?: ReachAnalysisProject2Form, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompaniesCompanyIdReachAnalysisProjects2(companyId, reachAnalysisProject2Form, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトを複製する
         * @summary プロジェクト複製
         * @param {number} companyId カンパニーID
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {ReachAnalysisProject2CopyForm} [reachAnalysisProject2CopyForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompaniesCompanyIdReachAnalysisProjects2Copy(companyId: number, reachAnalysisProject2Id: number, reachAnalysisProject2CopyForm?: ReachAnalysisProject2CopyForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompaniesCompanyIdReachAnalysisProjects2Copy(companyId, reachAnalysisProject2Id, reachAnalysisProject2CopyForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトにカードを追加する
         * @summary カードの追加
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CardForm} [reachAnalysisProject2CardForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id: number, reachAnalysisProject2CardForm?: ReachAnalysisProject2CardForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id, reachAnalysisProject2CardForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのカードを複製し、計算済みデータがあればコピーする
         * @summary カードの複製
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy(reachAnalysisProject2Id: number, cardId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy(reachAnalysisProject2Id, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのセクションごとのレポートの更新チェックをする
         * @summary セクションごとのレポート更新チェックをする
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのカードを編集する
         * @summary カードの編集
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CardForm} [reachAnalysisProject2CardForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CardForm?: ReachAnalysisProject2CardForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2Card>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id, cardId, reachAnalysisProject2CardForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのカードの並び順を変更する
         * @summary カードの並び変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CardOrder} [reachAnalysisProject2CardOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder(reachAnalysisProject2Id: number, reachAnalysisProject2CardOrder?: ReachAnalysisProject2CardOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CardOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder(reachAnalysisProject2Id, reachAnalysisProject2CardOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCMヒートマップ比較対象カード表示設定を変更する
         * @summary CMヒートマップ表示カード設定変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CmHeatmapDisplay} [reachAnalysisProject2CmHeatmapDisplay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id: number, reachAnalysisProject2CmHeatmapDisplay?: ReachAnalysisProject2CmHeatmapDisplay, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmapDisplay>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id, reachAnalysisProject2CmHeatmapDisplay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ表示条件を変更する
         * @summary CM出稿ヒートマップ表示条件変更
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardSettingForm} [reachAnalysisProject2CmHeatmapCardSettingForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardSettingForm?: ReachAnalysisProject2CmHeatmapCardSettingForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmapCardSettingForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardSettingForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ凡例の閾値を変更する
         * @summary CM出稿ヒートマップ凡例の閾値変更
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardThresholdForm} [reachAnalysisProject2CmHeatmapCardThresholdForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardThresholdForm?: ReachAnalysisProject2CmHeatmapCardThresholdForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmapCardThresholdForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardThresholdForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ凡例の閾値をリセットする
         * @summary CM出稿ヒートマップの閾値をリセット
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardThresholdResetForm} [reachAnalysisProject2CmHeatmapCardThresholdResetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardThresholdResetForm?: ReachAnalysisProject2CmHeatmapCardThresholdResetForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmapCardThresholdResetForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardThresholdResetForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ片表示に変更する
         * @summary CM出稿ヒートマップ片表示に変更
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle(reachAnalysisProject2Id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2CmHeatmapSingle>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle(reachAnalysisProject2Id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのグラフごとの比較対象カード表示設定を変更する（メインカード固定とするため、比較対象のカードのみリクエスト）
         * @summary グラフごとの表示カード設定変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2SectionDisplaySetting} [reachAnalysisProject2SectionDisplaySetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdDisplay(reachAnalysisProject2Id: number, reachAnalysisProject2SectionDisplaySetting?: ReachAnalysisProject2SectionDisplaySetting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2SectionDisplaySetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdDisplay(reachAnalysisProject2Id, reachAnalysisProject2SectionDisplaySetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのプロジェクト名を編集する
         * @summary プロジェクト名編集
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2ProjectNameForm} [reachAnalysisProject2ProjectNameForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdProjectName(reachAnalysisProject2Id: number, reachAnalysisProject2ProjectNameForm?: ReachAnalysisProject2ProjectNameForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2ProjectName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdProjectName(reachAnalysisProject2Id, reachAnalysisProject2ProjectNameForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定を更新する
         * @summary セクション表示設定更新
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {ReachAnalysisProject2SectionDisplaySettingForm} [reachAnalysisProject2SectionDisplaySettingForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id: number, reachAnalysisProject2SectionDisplaySettingForm?: ReachAnalysisProject2SectionDisplaySettingForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachAnalysisProject2SectionDisplaySettingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id, reachAnalysisProject2SectionDisplaySettingForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReachAnalysis2Api - factory interface
 * @export
 */
export const ReachAnalysis2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReachAnalysis2ApiFp(configuration)
    return {
        /**
         * リーチ分析プロジェクトを削除する
         * @summary プロジェクト削除
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのカードを削除する
         * @summary カードの削除
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id: number, cardId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * カンパニーに紐づくリーチ分析プロジェクトの一覧情報を取得する
         * @summary プロジェクト一覧
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2(companyId: number, options?: any): AxiosPromise<ReachAnalysisProject2List> {
            return localVarFp.getCompaniesCompanyIdReachAnalysisProjects2(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定商品の直近キャンペーン期間を取得する（最長91日間、直近キャンペーンが無かったら確報直近28日間）
         * @summary 登録商品の分析期間取得
         * @param {number} companyId 
         * @param {Array<number>} cmProductIds CM商品IDリスト
         * @param {string} areaCode エリアコード
         * @param {'ALL' | 'TIME' | 'SPOT'} cmBuyingKind CM種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod(companyId: number, cmProductIds: Array<number>, areaCode: string, cmBuyingKind: 'ALL' | 'TIME' | 'SPOT', options?: any): AxiosPromise<ReachAnalysisProject2AnalysisPeriod> {
            return localVarFp.getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod(companyId, cmProductIds, areaCode, cmBuyingKind, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定商品のGRPグラフデータを取得する
         * @summary 登録商品のGRPグラフ
         * @param {number} companyId 
         * @param {Array<number>} cmProductIds CM商品IDのリスト
         * @param {string} areaCode エリアコード
         * @param {'ALL' | 'TIME' | 'SPOT'} cmBuyingKind CM種別
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph(companyId: number, cmProductIds: Array<number>, areaCode: string, cmBuyingKind: 'ALL' | 'TIME' | 'SPOT', options?: any): AxiosPromise<Array<ReachAnalysisProject2ProductsGrpGraph>> {
            return localVarFp.getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph(companyId, cmProductIds, areaCode, cmBuyingKind, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトの商品／ブランドのレコメンド(GRP上位5件降順)を取得する
         * @summary 商品／ブランドのレコメンド
         * @param {number} companyId カンパニーID
         * @param {string} areaCode エリアコード
         * @param {Array<number>} cmSponsorIds CMスポンサーIDのリスト
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend(companyId: number, areaCode: string, cmSponsorIds: Array<number>, startDate: string, endDate: string, options?: any): AxiosPromise<ReachAnalysisProject2RecommendProducts> {
            return localVarFp.getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend(companyId, areaCode, cmSponsorIds, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析の商品／ブランド一覧を取得する
         * @summary リーチ分析の商品／ブランド検索
         * @param {number} companyId 
         * @param {Array<number>} [cmSponsorIds] CMスポンサーIDのリスト
         * @param {string} [productName] 商品／ブランド名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch(companyId: number, cmSponsorIds?: Array<number>, productName?: string, options?: any): AxiosPromise<Array<ReachAnalysisProject2Products>> {
            return localVarFp.getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch(companyId, cmSponsorIds, productName, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトの基本情報を取得する
         * @summary プロジェクト取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2BasicInfo> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * カードの一覧の情報を取得する
         * @summary カードの一覧取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2CardList> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップを取得する
         * @summary CM出稿ヒートマップ取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmap> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ枠内番組情報を取得する
         * @summary CM出稿ヒートマップ枠内番組情報取得
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {string} stationNetworkCode ネットワークコード
         * @param {number} dayOfWeek 曜日番号(1&#x3D;月,...,7&#x3D;日)
         * @param {number} broadcastTimezone 放送時刻
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms(reachAnalysisProject2Id: number, cardId: number, stationNetworkCode: string, dayOfWeek: number, broadcastTimezone: number, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmapCardPrograms> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms(reachAnalysisProject2Id, cardId, stationNetworkCode, dayOfWeek, broadcastTimezone, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCMヒートマップ比較対象カード表示設定を取得する
         * @summary CMヒートマップ表示カード設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmapDisplay> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップの設定を取得する
         * @summary CM出稿ヒートマップ設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmapSetting> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCPE比較を取得する
         * @summary CPR比較取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2CpeComparison> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのグラフごとの比較対象カード表示設定を取得する(メインカード固定のセクションでこのAPIを呼び出す)
         * @summary グラフごとの表示カード設定取得
         * @param {number} reachAnalysisProject2Id 
         * @param {'EFFECTIVE_REACH' | 'CPE_COMPARISON'} section セクション名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards(reachAnalysisProject2Id: number, section: 'EFFECTIVE_REACH' | 'CPE_COMPARISON', options?: any): AxiosPromise<ReachAnalysisProject2SectionDisplay> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards(reachAnalysisProject2Id, section, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのエフェクティブリーチグラフデータを取得する
         * @summary エフェクティブリーチグラフ取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2ReachAnalysisGraph> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのKPIを取得する
         * @summary KPI取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdKpi(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2Kpi> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdKpi(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定を取得する
         * @summary セクション表示設定取得
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2SectionDisplaySettingList> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定のデフォルト値を取得する
         * @summary セクション表示設定デフォルト値取得
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2SectionDisplaySettingList> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのセクションごとの更新ステータスを取得する
         * @summary セクションごとの更新ステータス取得
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReachAnalysisProjects2ReachAnalysisProject2IdStatus(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2Status> {
            return localVarFp.getReachAnalysisProjects2ReachAnalysisProject2IdStatus(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトを作成する
         * @summary プロジェクト新規作成
         * @param {number} companyId 
         * @param {ReachAnalysisProject2Form} [reachAnalysisProject2Form] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdReachAnalysisProjects2(companyId: number, reachAnalysisProject2Form?: ReachAnalysisProject2Form, options?: any): AxiosPromise<ReachAnalysisProject2> {
            return localVarFp.postCompaniesCompanyIdReachAnalysisProjects2(companyId, reachAnalysisProject2Form, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトを複製する
         * @summary プロジェクト複製
         * @param {number} companyId カンパニーID
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {ReachAnalysisProject2CopyForm} [reachAnalysisProject2CopyForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompaniesCompanyIdReachAnalysisProjects2Copy(companyId: number, reachAnalysisProject2Id: number, reachAnalysisProject2CopyForm?: ReachAnalysisProject2CopyForm, options?: any): AxiosPromise<ReachAnalysisProject2> {
            return localVarFp.postCompaniesCompanyIdReachAnalysisProjects2Copy(companyId, reachAnalysisProject2Id, reachAnalysisProject2CopyForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトにカードを追加する
         * @summary カードの追加
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CardForm} [reachAnalysisProject2CardForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id: number, reachAnalysisProject2CardForm?: ReachAnalysisProject2CardForm, options?: any): AxiosPromise<ReachAnalysisProject2Card> {
            return localVarFp.postReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id, reachAnalysisProject2CardForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのカードを複製し、計算済みデータがあればコピーする
         * @summary カードの複製
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy(reachAnalysisProject2Id: number, cardId: number, options?: any): AxiosPromise<ReachAnalysisProject2Card> {
            return localVarFp.postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy(reachAnalysisProject2Id, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのセクションごとのレポートの更新チェックをする
         * @summary セクションごとのレポート更新チェックをする
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports(reachAnalysisProject2Id: number, options?: any): AxiosPromise<void> {
            return localVarFp.postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのカードを編集する
         * @summary カードの編集
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CardForm} [reachAnalysisProject2CardForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CardForm?: ReachAnalysisProject2CardForm, options?: any): AxiosPromise<ReachAnalysisProject2Card> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id, cardId, reachAnalysisProject2CardForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのカードの並び順を変更する
         * @summary カードの並び変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CardOrder} [reachAnalysisProject2CardOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder(reachAnalysisProject2Id: number, reachAnalysisProject2CardOrder?: ReachAnalysisProject2CardOrder, options?: any): AxiosPromise<ReachAnalysisProject2CardOrder> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder(reachAnalysisProject2Id, reachAnalysisProject2CardOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCMヒートマップ比較対象カード表示設定を変更する
         * @summary CMヒートマップ表示カード設定変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2CmHeatmapDisplay} [reachAnalysisProject2CmHeatmapDisplay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id: number, reachAnalysisProject2CmHeatmapDisplay?: ReachAnalysisProject2CmHeatmapDisplay, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmapDisplay> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id, reachAnalysisProject2CmHeatmapDisplay, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ表示条件を変更する
         * @summary CM出稿ヒートマップ表示条件変更
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardSettingForm} [reachAnalysisProject2CmHeatmapCardSettingForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardSettingForm?: ReachAnalysisProject2CmHeatmapCardSettingForm, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmapCardSettingForm> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardSettingForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ凡例の閾値を変更する
         * @summary CM出稿ヒートマップ凡例の閾値変更
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardThresholdForm} [reachAnalysisProject2CmHeatmapCardThresholdForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardThresholdForm?: ReachAnalysisProject2CmHeatmapCardThresholdForm, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmapCardThresholdForm> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardThresholdForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ凡例の閾値をリセットする
         * @summary CM出稿ヒートマップの閾値をリセット
         * @param {number} reachAnalysisProject2Id 
         * @param {number} cardId 
         * @param {ReachAnalysisProject2CmHeatmapCardThresholdResetForm} [reachAnalysisProject2CmHeatmapCardThresholdResetForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardThresholdResetForm?: ReachAnalysisProject2CmHeatmapCardThresholdResetForm, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmapCardThresholdResetForm> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardThresholdResetForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのCM出稿ヒートマップ片表示に変更する
         * @summary CM出稿ヒートマップ片表示に変更
         * @param {number} reachAnalysisProject2Id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle(reachAnalysisProject2Id: number, options?: any): AxiosPromise<ReachAnalysisProject2CmHeatmapSingle> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle(reachAnalysisProject2Id, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのグラフごとの比較対象カード表示設定を変更する（メインカード固定とするため、比較対象のカードのみリクエスト）
         * @summary グラフごとの表示カード設定変更
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2SectionDisplaySetting} [reachAnalysisProject2SectionDisplaySetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdDisplay(reachAnalysisProject2Id: number, reachAnalysisProject2SectionDisplaySetting?: ReachAnalysisProject2SectionDisplaySetting, options?: any): AxiosPromise<ReachAnalysisProject2SectionDisplaySetting> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdDisplay(reachAnalysisProject2Id, reachAnalysisProject2SectionDisplaySetting, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのプロジェクト名を編集する
         * @summary プロジェクト名編集
         * @param {number} reachAnalysisProject2Id 
         * @param {ReachAnalysisProject2ProjectNameForm} [reachAnalysisProject2ProjectNameForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdProjectName(reachAnalysisProject2Id: number, reachAnalysisProject2ProjectNameForm?: ReachAnalysisProject2ProjectNameForm, options?: any): AxiosPromise<ReachAnalysisProject2ProjectName> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdProjectName(reachAnalysisProject2Id, reachAnalysisProject2ProjectNameForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチ分析プロジェクトのセクション表示設定を更新する
         * @summary セクション表示設定更新
         * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
         * @param {ReachAnalysisProject2SectionDisplaySettingForm} [reachAnalysisProject2SectionDisplaySettingForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id: number, reachAnalysisProject2SectionDisplaySettingForm?: ReachAnalysisProject2SectionDisplaySettingForm, options?: any): AxiosPromise<ReachAnalysisProject2SectionDisplaySettingList> {
            return localVarFp.putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id, reachAnalysisProject2SectionDisplaySettingForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReachAnalysis2Api - object-oriented interface
 * @export
 * @class ReachAnalysis2Api
 * @extends {BaseAPI}
 */
export class ReachAnalysis2Api extends BaseAPI {
    /**
     * リーチ分析プロジェクトを削除する
     * @summary プロジェクト削除
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public deleteReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).deleteReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのカードを削除する
     * @summary カードの削除
     * @param {number} reachAnalysisProject2Id 
     * @param {number} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id: number, cardId: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).deleteReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カンパニーに紐づくリーチ分析プロジェクトの一覧情報を取得する
     * @summary プロジェクト一覧
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getCompaniesCompanyIdReachAnalysisProjects2(companyId: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getCompaniesCompanyIdReachAnalysisProjects2(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定商品の直近キャンペーン期間を取得する（最長91日間、直近キャンペーンが無かったら確報直近28日間）
     * @summary 登録商品の分析期間取得
     * @param {number} companyId 
     * @param {Array<number>} cmProductIds CM商品IDリスト
     * @param {string} areaCode エリアコード
     * @param {'ALL' | 'TIME' | 'SPOT'} cmBuyingKind CM種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod(companyId: number, cmProductIds: Array<number>, areaCode: string, cmBuyingKind: 'ALL' | 'TIME' | 'SPOT', options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getCompaniesCompanyIdReachAnalysisProjects2ProductsAnalysisPeriod(companyId, cmProductIds, areaCode, cmBuyingKind, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定商品のGRPグラフデータを取得する
     * @summary 登録商品のGRPグラフ
     * @param {number} companyId 
     * @param {Array<number>} cmProductIds CM商品IDのリスト
     * @param {string} areaCode エリアコード
     * @param {'ALL' | 'TIME' | 'SPOT'} cmBuyingKind CM種別
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph(companyId: number, cmProductIds: Array<number>, areaCode: string, cmBuyingKind: 'ALL' | 'TIME' | 'SPOT', options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getCompaniesCompanyIdReachAnalysisProjects2ProductsGrpGraph(companyId, cmProductIds, areaCode, cmBuyingKind, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトの商品／ブランドのレコメンド(GRP上位5件降順)を取得する
     * @summary 商品／ブランドのレコメンド
     * @param {number} companyId カンパニーID
     * @param {string} areaCode エリアコード
     * @param {Array<number>} cmSponsorIds CMスポンサーIDのリスト
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend(companyId: number, areaCode: string, cmSponsorIds: Array<number>, startDate: string, endDate: string, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getCompaniesCompanyIdReachAnalysisProjects2ProductsRecommend(companyId, areaCode, cmSponsorIds, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析の商品／ブランド一覧を取得する
     * @summary リーチ分析の商品／ブランド検索
     * @param {number} companyId 
     * @param {Array<number>} [cmSponsorIds] CMスポンサーIDのリスト
     * @param {string} [productName] 商品／ブランド名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch(companyId: number, cmSponsorIds?: Array<number>, productName?: string, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getCompaniesCompanyIdReachAnalysisProjects2ProductsSearch(companyId, cmSponsorIds, productName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトの基本情報を取得する
     * @summary プロジェクト取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2Id(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カードの一覧の情報を取得する
     * @summary カードの一覧取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCM出稿ヒートマップを取得する
     * @summary CM出稿ヒートマップ取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmap(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCM出稿ヒートマップ枠内番組情報を取得する
     * @summary CM出稿ヒートマップ枠内番組情報取得
     * @param {number} reachAnalysisProject2Id 
     * @param {number} cardId 
     * @param {string} stationNetworkCode ネットワークコード
     * @param {number} dayOfWeek 曜日番号(1&#x3D;月,...,7&#x3D;日)
     * @param {number} broadcastTimezone 放送時刻
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms(reachAnalysisProject2Id: number, cardId: number, stationNetworkCode: string, dayOfWeek: number, broadcastTimezone: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapCardsCardIdPrograms(reachAnalysisProject2Id, cardId, stationNetworkCode, dayOfWeek, broadcastTimezone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCMヒートマップ比較対象カード表示設定を取得する
     * @summary CMヒートマップ表示カード設定取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCM出稿ヒートマップの設定を取得する
     * @summary CM出稿ヒートマップ設定取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettings(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCPE比較を取得する
     * @summary CPR比較取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdCpeComparison(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのグラフごとの比較対象カード表示設定を取得する(メインカード固定のセクションでこのAPIを呼び出す)
     * @summary グラフごとの表示カード設定取得
     * @param {number} reachAnalysisProject2Id 
     * @param {'EFFECTIVE_REACH' | 'CPE_COMPARISON'} section セクション名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards(reachAnalysisProject2Id: number, section: 'EFFECTIVE_REACH' | 'CPE_COMPARISON', options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdDisplayCards(reachAnalysisProject2Id, section, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのエフェクティブリーチグラフデータを取得する
     * @summary エフェクティブリーチグラフ取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdEffectiveReach(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのKPIを取得する
     * @summary KPI取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdKpi(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdKpi(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのセクション表示設定を取得する
     * @summary セクション表示設定取得
     * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのセクション表示設定のデフォルト値を取得する
     * @summary セクション表示設定デフォルト値取得
     * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettingsDefaultValue(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのセクションごとの更新ステータスを取得する
     * @summary セクションごとの更新ステータス取得
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public getReachAnalysisProjects2ReachAnalysisProject2IdStatus(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).getReachAnalysisProjects2ReachAnalysisProject2IdStatus(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトを作成する
     * @summary プロジェクト新規作成
     * @param {number} companyId 
     * @param {ReachAnalysisProject2Form} [reachAnalysisProject2Form] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public postCompaniesCompanyIdReachAnalysisProjects2(companyId: number, reachAnalysisProject2Form?: ReachAnalysisProject2Form, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).postCompaniesCompanyIdReachAnalysisProjects2(companyId, reachAnalysisProject2Form, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトを複製する
     * @summary プロジェクト複製
     * @param {number} companyId カンパニーID
     * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
     * @param {ReachAnalysisProject2CopyForm} [reachAnalysisProject2CopyForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public postCompaniesCompanyIdReachAnalysisProjects2Copy(companyId: number, reachAnalysisProject2Id: number, reachAnalysisProject2CopyForm?: ReachAnalysisProject2CopyForm, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).postCompaniesCompanyIdReachAnalysisProjects2Copy(companyId, reachAnalysisProject2Id, reachAnalysisProject2CopyForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトにカードを追加する
     * @summary カードの追加
     * @param {number} reachAnalysisProject2Id 
     * @param {ReachAnalysisProject2CardForm} [reachAnalysisProject2CardForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public postReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id: number, reachAnalysisProject2CardForm?: ReachAnalysisProject2CardForm, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).postReachAnalysisProjects2ReachAnalysisProject2IdCards(reachAnalysisProject2Id, reachAnalysisProject2CardForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのカードを複製し、計算済みデータがあればコピーする
     * @summary カードの複製
     * @param {number} reachAnalysisProject2Id 
     * @param {number} cardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy(reachAnalysisProject2Id: number, cardId: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).postReachAnalysisProjects2ReachAnalysisProject2IdCardsCardIdCopy(reachAnalysisProject2Id, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのセクションごとのレポートの更新チェックをする
     * @summary セクションごとのレポート更新チェックをする
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).postReachAnalysisProjects2ReachAnalysisProject2IdCheckReports(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのカードを編集する
     * @summary カードの編集
     * @param {number} reachAnalysisProject2Id 
     * @param {number} cardId 
     * @param {ReachAnalysisProject2CardForm} [reachAnalysisProject2CardForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CardForm?: ReachAnalysisProject2CardForm, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdCardsCardId(reachAnalysisProject2Id, cardId, reachAnalysisProject2CardForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのカードの並び順を変更する
     * @summary カードの並び変更
     * @param {number} reachAnalysisProject2Id 
     * @param {ReachAnalysisProject2CardOrder} [reachAnalysisProject2CardOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder(reachAnalysisProject2Id: number, reachAnalysisProject2CardOrder?: ReachAnalysisProject2CardOrder, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdCardsOrder(reachAnalysisProject2Id, reachAnalysisProject2CardOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCMヒートマップ比較対象カード表示設定を変更する
     * @summary CMヒートマップ表示カード設定変更
     * @param {number} reachAnalysisProject2Id 
     * @param {ReachAnalysisProject2CmHeatmapDisplay} [reachAnalysisProject2CmHeatmapDisplay] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id: number, reachAnalysisProject2CmHeatmapDisplay?: ReachAnalysisProject2CmHeatmapDisplay, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapDisplayCards(reachAnalysisProject2Id, reachAnalysisProject2CmHeatmapDisplay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCM出稿ヒートマップ表示条件を変更する
     * @summary CM出稿ヒートマップ表示条件変更
     * @param {number} reachAnalysisProject2Id 
     * @param {number} cardId 
     * @param {ReachAnalysisProject2CmHeatmapCardSettingForm} [reachAnalysisProject2CmHeatmapCardSettingForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardSettingForm?: ReachAnalysisProject2CmHeatmapCardSettingForm, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardId(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardSettingForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCM出稿ヒートマップ凡例の閾値を変更する
     * @summary CM出稿ヒートマップ凡例の閾値変更
     * @param {number} reachAnalysisProject2Id 
     * @param {number} cardId 
     * @param {ReachAnalysisProject2CmHeatmapCardThresholdForm} [reachAnalysisProject2CmHeatmapCardThresholdForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardThresholdForm?: ReachAnalysisProject2CmHeatmapCardThresholdForm, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValue(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardThresholdForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCM出稿ヒートマップ凡例の閾値をリセットする
     * @summary CM出稿ヒートマップの閾値をリセット
     * @param {number} reachAnalysisProject2Id 
     * @param {number} cardId 
     * @param {ReachAnalysisProject2CmHeatmapCardThresholdResetForm} [reachAnalysisProject2CmHeatmapCardThresholdResetForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset(reachAnalysisProject2Id: number, cardId: number, reachAnalysisProject2CmHeatmapCardThresholdResetForm?: ReachAnalysisProject2CmHeatmapCardThresholdResetForm, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsCardsCardIdThresholdValueReset(reachAnalysisProject2Id, cardId, reachAnalysisProject2CmHeatmapCardThresholdResetForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのCM出稿ヒートマップ片表示に変更する
     * @summary CM出稿ヒートマップ片表示に変更
     * @param {number} reachAnalysisProject2Id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle(reachAnalysisProject2Id: number, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdCmHeatmapSettingsSingle(reachAnalysisProject2Id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのグラフごとの比較対象カード表示設定を変更する（メインカード固定とするため、比較対象のカードのみリクエスト）
     * @summary グラフごとの表示カード設定変更
     * @param {number} reachAnalysisProject2Id 
     * @param {ReachAnalysisProject2SectionDisplaySetting} [reachAnalysisProject2SectionDisplaySetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdDisplay(reachAnalysisProject2Id: number, reachAnalysisProject2SectionDisplaySetting?: ReachAnalysisProject2SectionDisplaySetting, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdDisplay(reachAnalysisProject2Id, reachAnalysisProject2SectionDisplaySetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのプロジェクト名を編集する
     * @summary プロジェクト名編集
     * @param {number} reachAnalysisProject2Id 
     * @param {ReachAnalysisProject2ProjectNameForm} [reachAnalysisProject2ProjectNameForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdProjectName(reachAnalysisProject2Id: number, reachAnalysisProject2ProjectNameForm?: ReachAnalysisProject2ProjectNameForm, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdProjectName(reachAnalysisProject2Id, reachAnalysisProject2ProjectNameForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチ分析プロジェクトのセクション表示設定を更新する
     * @summary セクション表示設定更新
     * @param {number} reachAnalysisProject2Id リーチ分析プロジェクトID
     * @param {ReachAnalysisProject2SectionDisplaySettingForm} [reachAnalysisProject2SectionDisplaySettingForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachAnalysis2Api
     */
    public putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id: number, reachAnalysisProject2SectionDisplaySettingForm?: ReachAnalysisProject2SectionDisplaySettingForm, options?: any) {
        return ReachAnalysis2ApiFp(this.configuration).putReachAnalysisProjects2ReachAnalysisProject2IdSectionDisplaySettings(reachAnalysisProject2Id, reachAnalysisProject2SectionDisplaySettingForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReachSimulatorApi - axios parameter creator
 * @export
 */
export const ReachSimulatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リーチシミュレータープロジェクトを削除する
         * @summary シミュレーション削除
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmPlanningRsmProjectsProjectId: async (projectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteCmPlanningRsmProjectsProjectId', 'projectId', projectId)
            const localVarPath = `/cm-planning/rsm-projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 作成済みのシミュレーションの一覧を取得する
         * @summary シミュレーション一覧
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningCompaniesCompanyIdRsmProjects: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmPlanningCompaniesCompanyIdRsmProjects', 'companyId', companyId)
            const localVarPath = `/cm-planning/companies/{companyId}/rsm-projects`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保存されているシミュレーション条件を取得する
         * @summary シミュレーション条件取得
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningRsmProjectsProjectId: async (projectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCmPlanningRsmProjectsProjectId', 'projectId', projectId)
            const localVarPath = `/cm-planning/rsm-projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * シミュレーション結果取得
         * @summary シミュレーション結果取得
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningRsmProjectsProjectIdResult: async (projectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCmPlanningRsmProjectsProjectIdResult', 'projectId', projectId)
            const localVarPath = `/cm-planning/rsm-projects/{projectId}/result`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチシミュレータープロジェクトを作成する
         * @summary シミュレーション作成
         * @param {number} companyId カンパニーID
         * @param {ReachSimulatorProjectForm} [reachSimulatorProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningCompaniesCompanyIdRsmProjects: async (companyId: number, reachSimulatorProjectForm?: ReachSimulatorProjectForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCmPlanningCompaniesCompanyIdRsmProjects', 'companyId', companyId)
            const localVarPath = `/cm-planning/companies/{companyId}/rsm-projects`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachSimulatorProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチシミュレータープロジェクトを一括作成する
         * @summary シミュレーション一括作成
         * @param {number} companyId カンパニーID
         * @param {ReachSimulatorProjectBulkForm} [reachSimulatorProjectBulkForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningCompaniesCompanyIdRsmProjectsBulk: async (companyId: number, reachSimulatorProjectBulkForm?: ReachSimulatorProjectBulkForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCmPlanningCompaniesCompanyIdRsmProjectsBulk', 'companyId', companyId)
            const localVarPath = `/cm-planning/companies/{companyId}/rsm-projects/bulk`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachSimulatorProjectBulkForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リーチシミュレータープロジェクトを編集する
         * @summary シミュレーション編集
         * @param {number} projectId プロジェクトID
         * @param {ReachSimulatorProjectForm} [reachSimulatorProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmPlanningRsmProjectsProjectId: async (projectId: number, reachSimulatorProjectForm?: ReachSimulatorProjectForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('putCmPlanningRsmProjectsProjectId', 'projectId', projectId)
            const localVarPath = `/cm-planning/rsm-projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reachSimulatorProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReachSimulatorApi - functional programming interface
 * @export
 */
export const ReachSimulatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReachSimulatorApiAxiosParamCreator(configuration)
    return {
        /**
         * リーチシミュレータープロジェクトを削除する
         * @summary シミュレーション削除
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCmPlanningRsmProjectsProjectId(projectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCmPlanningRsmProjectsProjectId(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 作成済みのシミュレーションの一覧を取得する
         * @summary シミュレーション一覧
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmPlanningCompaniesCompanyIdRsmProjects(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachSimulatorProjects>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmPlanningCompaniesCompanyIdRsmProjects(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保存されているシミュレーション条件を取得する
         * @summary シミュレーション条件取得
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmPlanningRsmProjectsProjectId(projectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachSimulatorProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmPlanningRsmProjectsProjectId(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * シミュレーション結果取得
         * @summary シミュレーション結果取得
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmPlanningRsmProjectsProjectIdResult(projectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RsmProjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmPlanningRsmProjectsProjectIdResult(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチシミュレータープロジェクトを作成する
         * @summary シミュレーション作成
         * @param {number} companyId カンパニーID
         * @param {ReachSimulatorProjectForm} [reachSimulatorProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCmPlanningCompaniesCompanyIdRsmProjects(companyId: number, reachSimulatorProjectForm?: ReachSimulatorProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachSimulatorProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCmPlanningCompaniesCompanyIdRsmProjects(companyId, reachSimulatorProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチシミュレータープロジェクトを一括作成する
         * @summary シミュレーション一括作成
         * @param {number} companyId カンパニーID
         * @param {ReachSimulatorProjectBulkForm} [reachSimulatorProjectBulkForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCmPlanningCompaniesCompanyIdRsmProjectsBulk(companyId: number, reachSimulatorProjectBulkForm?: ReachSimulatorProjectBulkForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachSimulatorProjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCmPlanningCompaniesCompanyIdRsmProjectsBulk(companyId, reachSimulatorProjectBulkForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リーチシミュレータープロジェクトを編集する
         * @summary シミュレーション編集
         * @param {number} projectId プロジェクトID
         * @param {ReachSimulatorProjectForm} [reachSimulatorProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCmPlanningRsmProjectsProjectId(projectId: number, reachSimulatorProjectForm?: ReachSimulatorProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReachSimulatorProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCmPlanningRsmProjectsProjectId(projectId, reachSimulatorProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReachSimulatorApi - factory interface
 * @export
 */
export const ReachSimulatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReachSimulatorApiFp(configuration)
    return {
        /**
         * リーチシミュレータープロジェクトを削除する
         * @summary シミュレーション削除
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmPlanningRsmProjectsProjectId(projectId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCmPlanningRsmProjectsProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 作成済みのシミュレーションの一覧を取得する
         * @summary シミュレーション一覧
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningCompaniesCompanyIdRsmProjects(companyId: number, options?: any): AxiosPromise<ReachSimulatorProjects> {
            return localVarFp.getCmPlanningCompaniesCompanyIdRsmProjects(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保存されているシミュレーション条件を取得する
         * @summary シミュレーション条件取得
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningRsmProjectsProjectId(projectId: number, options?: any): AxiosPromise<ReachSimulatorProject> {
            return localVarFp.getCmPlanningRsmProjectsProjectId(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * シミュレーション結果取得
         * @summary シミュレーション結果取得
         * @param {number} projectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningRsmProjectsProjectIdResult(projectId: number, options?: any): AxiosPromise<RsmProjectResult> {
            return localVarFp.getCmPlanningRsmProjectsProjectIdResult(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチシミュレータープロジェクトを作成する
         * @summary シミュレーション作成
         * @param {number} companyId カンパニーID
         * @param {ReachSimulatorProjectForm} [reachSimulatorProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningCompaniesCompanyIdRsmProjects(companyId: number, reachSimulatorProjectForm?: ReachSimulatorProjectForm, options?: any): AxiosPromise<ReachSimulatorProject> {
            return localVarFp.postCmPlanningCompaniesCompanyIdRsmProjects(companyId, reachSimulatorProjectForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチシミュレータープロジェクトを一括作成する
         * @summary シミュレーション一括作成
         * @param {number} companyId カンパニーID
         * @param {ReachSimulatorProjectBulkForm} [reachSimulatorProjectBulkForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningCompaniesCompanyIdRsmProjectsBulk(companyId: number, reachSimulatorProjectBulkForm?: ReachSimulatorProjectBulkForm, options?: any): AxiosPromise<ReachSimulatorProjectList> {
            return localVarFp.postCmPlanningCompaniesCompanyIdRsmProjectsBulk(companyId, reachSimulatorProjectBulkForm, options).then((request) => request(axios, basePath));
        },
        /**
         * リーチシミュレータープロジェクトを編集する
         * @summary シミュレーション編集
         * @param {number} projectId プロジェクトID
         * @param {ReachSimulatorProjectForm} [reachSimulatorProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmPlanningRsmProjectsProjectId(projectId: number, reachSimulatorProjectForm?: ReachSimulatorProjectForm, options?: any): AxiosPromise<ReachSimulatorProject> {
            return localVarFp.putCmPlanningRsmProjectsProjectId(projectId, reachSimulatorProjectForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReachSimulatorApi - object-oriented interface
 * @export
 * @class ReachSimulatorApi
 * @extends {BaseAPI}
 */
export class ReachSimulatorApi extends BaseAPI {
    /**
     * リーチシミュレータープロジェクトを削除する
     * @summary シミュレーション削除
     * @param {number} projectId プロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachSimulatorApi
     */
    public deleteCmPlanningRsmProjectsProjectId(projectId: number, options?: any) {
        return ReachSimulatorApiFp(this.configuration).deleteCmPlanningRsmProjectsProjectId(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 作成済みのシミュレーションの一覧を取得する
     * @summary シミュレーション一覧
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachSimulatorApi
     */
    public getCmPlanningCompaniesCompanyIdRsmProjects(companyId: number, options?: any) {
        return ReachSimulatorApiFp(this.configuration).getCmPlanningCompaniesCompanyIdRsmProjects(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保存されているシミュレーション条件を取得する
     * @summary シミュレーション条件取得
     * @param {number} projectId プロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachSimulatorApi
     */
    public getCmPlanningRsmProjectsProjectId(projectId: number, options?: any) {
        return ReachSimulatorApiFp(this.configuration).getCmPlanningRsmProjectsProjectId(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * シミュレーション結果取得
     * @summary シミュレーション結果取得
     * @param {number} projectId プロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachSimulatorApi
     */
    public getCmPlanningRsmProjectsProjectIdResult(projectId: number, options?: any) {
        return ReachSimulatorApiFp(this.configuration).getCmPlanningRsmProjectsProjectIdResult(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチシミュレータープロジェクトを作成する
     * @summary シミュレーション作成
     * @param {number} companyId カンパニーID
     * @param {ReachSimulatorProjectForm} [reachSimulatorProjectForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachSimulatorApi
     */
    public postCmPlanningCompaniesCompanyIdRsmProjects(companyId: number, reachSimulatorProjectForm?: ReachSimulatorProjectForm, options?: any) {
        return ReachSimulatorApiFp(this.configuration).postCmPlanningCompaniesCompanyIdRsmProjects(companyId, reachSimulatorProjectForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチシミュレータープロジェクトを一括作成する
     * @summary シミュレーション一括作成
     * @param {number} companyId カンパニーID
     * @param {ReachSimulatorProjectBulkForm} [reachSimulatorProjectBulkForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachSimulatorApi
     */
    public postCmPlanningCompaniesCompanyIdRsmProjectsBulk(companyId: number, reachSimulatorProjectBulkForm?: ReachSimulatorProjectBulkForm, options?: any) {
        return ReachSimulatorApiFp(this.configuration).postCmPlanningCompaniesCompanyIdRsmProjectsBulk(companyId, reachSimulatorProjectBulkForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リーチシミュレータープロジェクトを編集する
     * @summary シミュレーション編集
     * @param {number} projectId プロジェクトID
     * @param {ReachSimulatorProjectForm} [reachSimulatorProjectForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReachSimulatorApi
     */
    public putCmPlanningRsmProjectsProjectId(projectId: number, reachSimulatorProjectForm?: ReachSimulatorProjectForm, options?: any) {
        return ReachSimulatorApiFp(this.configuration).putCmPlanningRsmProjectsProjectId(projectId, reachSimulatorProjectForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TimeslotApi - axios parameter creator
 * @export
 */
export const TimeslotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 時間帯の局別視聴率もしくは含有率のCSVをダウンロードするAPI
         * @summary 時間帯の局別視聴CSVダウンロード
         * @param {number} companyId カンパニーID
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdTimeslotCsv: async (companyId: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotCsv', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotCsv', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotCsv', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotCsv', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'dataDivision' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotCsv', 'dataDivision', dataDivision)
            const localVarPath = `/companies/{companyId}/timeslot/csv`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetAggregationUnit !== undefined) {
                localVarQueryParameter['targetAggregationUnit'] = targetAggregationUnit;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (dataDivision !== undefined) {
                localVarQueryParameter['dataDivision'] = dataDivision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 時間帯の局別視聴率もしくは含有率を取得するAPI
         * @summary 時間帯の局別視聴情報取得
         * @param {number} companyId カンパニーID
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdTimeslotJson: async (companyId: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotJson', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotJson', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotJson', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotJson', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotJson', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'dataDivision' is not null or undefined
            assertParamExists('getCompaniesCompanyIdTimeslotJson', 'dataDivision', dataDivision)
            const localVarPath = `/companies/{companyId}/timeslot/json`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetAggregationUnit !== undefined) {
                localVarQueryParameter['targetAggregationUnit'] = targetAggregationUnit;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (dataDivision !== undefined) {
                localVarQueryParameter['dataDivision'] = dataDivision;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 時間帯の検索条件テキストを取得するAPI
         * @summary 時間帯の検索条件テキスト取得
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {number} companyId カンパニーID
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeslotSearchCondition: async (startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', companyId: number, targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTimeslotSearchCondition', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTimeslotSearchCondition', 'endDate', endDate)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTimeslotSearchCondition', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getTimeslotSearchCondition', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'dataDivision' is not null or undefined
            assertParamExists('getTimeslotSearchCondition', 'dataDivision', dataDivision)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTimeslotSearchCondition', 'companyId', companyId)
            const localVarPath = `/timeslot/searchCondition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetAggregationUnit !== undefined) {
                localVarQueryParameter['targetAggregationUnit'] = targetAggregationUnit;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (dataDivision !== undefined) {
                localVarQueryParameter['dataDivision'] = dataDivision;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeslotApi - functional programming interface
 * @export
 */
export const TimeslotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeslotApiAxiosParamCreator(configuration)
    return {
        /**
         * 時間帯の局別視聴率もしくは含有率のCSVをダウンロードするAPI
         * @summary 時間帯の局別視聴CSVダウンロード
         * @param {number} companyId カンパニーID
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdTimeslotCsv(companyId: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdTimeslotCsv(companyId, startDate, endDate, areaCode, useCustomTarget, dataDivision, targetAggregationUnit, targetIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 時間帯の局別視聴率もしくは含有率を取得するAPI
         * @summary 時間帯の局別視聴情報取得
         * @param {number} companyId カンパニーID
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompaniesCompanyIdTimeslotJson(companyId: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Timeslot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompaniesCompanyIdTimeslotJson(companyId, startDate, endDate, areaCode, useCustomTarget, dataDivision, targetAggregationUnit, targetIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 時間帯の検索条件テキストを取得するAPI
         * @summary 時間帯の検索条件テキスト取得
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {number} companyId カンパニーID
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeslotSearchCondition(startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', companyId: number, targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeslotSearchCondition>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeslotSearchCondition(startDate, endDate, areaCode, useCustomTarget, dataDivision, companyId, targetAggregationUnit, targetIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimeslotApi - factory interface
 * @export
 */
export const TimeslotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeslotApiFp(configuration)
    return {
        /**
         * 時間帯の局別視聴率もしくは含有率のCSVをダウンロードするAPI
         * @summary 時間帯の局別視聴CSVダウンロード
         * @param {number} companyId カンパニーID
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdTimeslotCsv(companyId: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any): AxiosPromise<any> {
            return localVarFp.getCompaniesCompanyIdTimeslotCsv(companyId, startDate, endDate, areaCode, useCustomTarget, dataDivision, targetAggregationUnit, targetIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 時間帯の局別視聴率もしくは含有率を取得するAPI
         * @summary 時間帯の局別視聴情報取得
         * @param {number} companyId カンパニーID
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompaniesCompanyIdTimeslotJson(companyId: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any): AxiosPromise<Timeslot> {
            return localVarFp.getCompaniesCompanyIdTimeslotJson(companyId, startDate, endDate, areaCode, useCustomTarget, dataDivision, targetAggregationUnit, targetIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 時間帯の検索条件テキストを取得するAPI
         * @summary 時間帯の検索条件テキスト取得
         * @param {string} startDate 開始日
         * @param {string} endDate 終了日
         * @param {string} areaCode エリア
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
         * @param {number} companyId カンパニーID
         * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeslotSearchCondition(startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', companyId: number, targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any): AxiosPromise<TimeslotSearchCondition> {
            return localVarFp.getTimeslotSearchCondition(startDate, endDate, areaCode, useCustomTarget, dataDivision, companyId, targetAggregationUnit, targetIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimeslotApi - object-oriented interface
 * @export
 * @class TimeslotApi
 * @extends {BaseAPI}
 */
export class TimeslotApi extends BaseAPI {
    /**
     * 時間帯の局別視聴率もしくは含有率のCSVをダウンロードするAPI
     * @summary 時間帯の局別視聴CSVダウンロード
     * @param {number} companyId カンパニーID
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
     * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeslotApi
     */
    public getCompaniesCompanyIdTimeslotCsv(companyId: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any) {
        return TimeslotApiFp(this.configuration).getCompaniesCompanyIdTimeslotCsv(companyId, startDate, endDate, areaCode, useCustomTarget, dataDivision, targetAggregationUnit, targetIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 時間帯の局別視聴率もしくは含有率を取得するAPI
     * @summary 時間帯の局別視聴情報取得
     * @param {number} companyId カンパニーID
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
     * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeslotApi
     */
    public getCompaniesCompanyIdTimeslotJson(companyId: number, startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any) {
        return TimeslotApiFp(this.configuration).getCompaniesCompanyIdTimeslotJson(companyId, startDate, endDate, areaCode, useCustomTarget, dataDivision, targetAggregationUnit, targetIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 時間帯の検索条件テキストを取得するAPI
     * @summary 時間帯の検索条件テキスト取得
     * @param {string} startDate 開始日
     * @param {string} endDate 終了日
     * @param {string} areaCode エリア
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD'} dataDivision データ区分
     * @param {number} companyId カンパニーID
     * @param {'PERSONAL' | 'HOUSEHOLD'} [targetAggregationUnit] ターゲット集計単位（PERSONAL, HOUSEHOLD）
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeslotApi
     */
    public getTimeslotSearchCondition(startDate: string, endDate: string, areaCode: string, useCustomTarget: boolean, dataDivision: 'VIEWING_RATE' | 'CONTENT_RATE' | 'CONTENT_RATE_HOUSEHOLD', companyId: number, targetAggregationUnit?: 'PERSONAL' | 'HOUSEHOLD', targetIds?: Array<number>, options?: any) {
        return TimeslotApiFp(this.configuration).getTimeslotSearchCondition(startDate, endDate, areaCode, useCustomTarget, dataDivision, companyId, targetAggregationUnit, targetIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TroApi - axios parameter creator
 * @export
 */
export const TroApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * TROプロジェクト削除
         * @summary TROプロジェクト削除
         * @param {number} troProjectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmPlanningTroProjectsTroProjectId: async (troProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'troProjectId' is not null or undefined
            assertParamExists('deleteCmPlanningTroProjectsTroProjectId', 'troProjectId', troProjectId)
            const localVarPath = `/cm-planning/tro-projects/{troProjectId}`
                .replace(`{${"troProjectId"}}`, encodeURIComponent(String(troProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保存済みのTROプロジェクトの一覧を取得する
         * @summary TROプロジェクト一覧
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningCompaniesCompanyIdTroProjects: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCmPlanningCompaniesCompanyIdTroProjects', 'companyId', companyId)
            const localVarPath = `/cm-planning/companies/{companyId}/tro-projects`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保存されたTROプロジェクトの詳細情報を取得する（編集用）
         * @summary TROプロジェクト詳細取得
         * @param {number} troProjectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningTroProjectsTroProjectId: async (troProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'troProjectId' is not null or undefined
            assertParamExists('getCmPlanningTroProjectsTroProjectId', 'troProjectId', troProjectId)
            const localVarPath = `/cm-planning/tro-projects/{troProjectId}`
                .replace(`{${"troProjectId"}}`, encodeURIComponent(String(troProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TRO探索実行で取得・登録された結果をDBより取得する
         * @summary TRO探索結果取得
         * @param {number} troProjectId TROプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningTroProjectsTroProjectIdTroSearch: async (troProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'troProjectId' is not null or undefined
            assertParamExists('getCmPlanningTroProjectsTroProjectIdTroSearch', 'troProjectId', troProjectId)
            const localVarPath = `/cm-planning/tro-projects/{troProjectId}/tro-search`
                .replace(`{${"troProjectId"}}`, encodeURIComponent(String(troProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TROプロジェクト登録
         * @summary TROプロジェクト登録
         * @param {number} companyId カンパニーID
         * @param {TroProjectForm} [troProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningCompaniesCompanyIdTroProjects: async (companyId: number, troProjectForm?: TroProjectForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCmPlanningCompaniesCompanyIdTroProjects', 'companyId', companyId)
            const localVarPath = `/cm-planning/companies/{companyId}/tro-projects`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(troProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TRO番組インポート情報詳細取得API
         * @summary TRO番組インポート情報詳細取得
         * @param {number} companyId カンパニーID
         * @param {TroProgramImportDetailForm} [troProgramImportDetailForm] TRO番組インポート詳細フォーム
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails: async (companyId: number, troProgramImportDetailForm?: TroProgramImportDetailForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails', 'companyId', companyId)
            const localVarPath = `/cm-planning/companies/{companyId}/tro-projects/program-import-details`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(troProgramImportDetailForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TROのDSP探索を実行し、結果をDBに保管する
         * @summary TRO探索実行
         * @param {number} troProjectId TROプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningTroProjectsTroProjectIdTroSearch: async (troProjectId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'troProjectId' is not null or undefined
            assertParamExists('postCmPlanningTroProjectsTroProjectIdTroSearch', 'troProjectId', troProjectId)
            const localVarPath = `/cm-planning/tro-projects/{troProjectId}/tro-search`
                .replace(`{${"troProjectId"}}`, encodeURIComponent(String(troProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TROプロジェクト更新
         * @summary TROプロジェクト更新
         * @param {number} troProjectId プロジェクトID
         * @param {TroProjectForm} [troProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmPlanningTroProjectsTroProjectId: async (troProjectId: number, troProjectForm?: TroProjectForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'troProjectId' is not null or undefined
            assertParamExists('putCmPlanningTroProjectsTroProjectId', 'troProjectId', troProjectId)
            const localVarPath = `/cm-planning/tro-projects/{troProjectId}`
                .replace(`{${"troProjectId"}}`, encodeURIComponent(String(troProjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(troProjectForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TroApi - functional programming interface
 * @export
 */
export const TroApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TroApiAxiosParamCreator(configuration)
    return {
        /**
         * TROプロジェクト削除
         * @summary TROプロジェクト削除
         * @param {number} troProjectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCmPlanningTroProjectsTroProjectId(troProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCmPlanningTroProjectsTroProjectId(troProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保存済みのTROプロジェクトの一覧を取得する
         * @summary TROプロジェクト一覧
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmPlanningCompaniesCompanyIdTroProjects(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TroProjectList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmPlanningCompaniesCompanyIdTroProjects(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保存されたTROプロジェクトの詳細情報を取得する（編集用）
         * @summary TROプロジェクト詳細取得
         * @param {number} troProjectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmPlanningTroProjectsTroProjectId(troProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TroProjectDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmPlanningTroProjectsTroProjectId(troProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TRO探索実行で取得・登録された結果をDBより取得する
         * @summary TRO探索結果取得
         * @param {number} troProjectId TROプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TroProjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TROプロジェクト登録
         * @summary TROプロジェクト登録
         * @param {number} companyId カンパニーID
         * @param {TroProjectForm} [troProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCmPlanningCompaniesCompanyIdTroProjects(companyId: number, troProjectForm?: TroProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TroProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCmPlanningCompaniesCompanyIdTroProjects(companyId, troProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TRO番組インポート情報詳細取得API
         * @summary TRO番組インポート情報詳細取得
         * @param {number} companyId カンパニーID
         * @param {TroProgramImportDetailForm} [troProgramImportDetailForm] TRO番組インポート詳細フォーム
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails(companyId: number, troProgramImportDetailForm?: TroProgramImportDetailForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TroProgramImportDetailList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails(companyId, troProgramImportDetailForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TROのDSP探索を実行し、結果をDBに保管する
         * @summary TRO探索実行
         * @param {number} troProjectId TROプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TROプロジェクト更新
         * @summary TROプロジェクト更新
         * @param {number} troProjectId プロジェクトID
         * @param {TroProjectForm} [troProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCmPlanningTroProjectsTroProjectId(troProjectId: number, troProjectForm?: TroProjectForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TroProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCmPlanningTroProjectsTroProjectId(troProjectId, troProjectForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TroApi - factory interface
 * @export
 */
export const TroApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TroApiFp(configuration)
    return {
        /**
         * TROプロジェクト削除
         * @summary TROプロジェクト削除
         * @param {number} troProjectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCmPlanningTroProjectsTroProjectId(troProjectId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCmPlanningTroProjectsTroProjectId(troProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保存済みのTROプロジェクトの一覧を取得する
         * @summary TROプロジェクト一覧
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningCompaniesCompanyIdTroProjects(companyId: number, options?: any): AxiosPromise<TroProjectList> {
            return localVarFp.getCmPlanningCompaniesCompanyIdTroProjects(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保存されたTROプロジェクトの詳細情報を取得する（編集用）
         * @summary TROプロジェクト詳細取得
         * @param {number} troProjectId プロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningTroProjectsTroProjectId(troProjectId: number, options?: any): AxiosPromise<TroProjectDetail> {
            return localVarFp.getCmPlanningTroProjectsTroProjectId(troProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * TRO探索実行で取得・登録された結果をDBより取得する
         * @summary TRO探索結果取得
         * @param {number} troProjectId TROプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId: number, options?: any): AxiosPromise<TroProjectResult> {
            return localVarFp.getCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * TROプロジェクト登録
         * @summary TROプロジェクト登録
         * @param {number} companyId カンパニーID
         * @param {TroProjectForm} [troProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningCompaniesCompanyIdTroProjects(companyId: number, troProjectForm?: TroProjectForm, options?: any): AxiosPromise<TroProject> {
            return localVarFp.postCmPlanningCompaniesCompanyIdTroProjects(companyId, troProjectForm, options).then((request) => request(axios, basePath));
        },
        /**
         * TRO番組インポート情報詳細取得API
         * @summary TRO番組インポート情報詳細取得
         * @param {number} companyId カンパニーID
         * @param {TroProgramImportDetailForm} [troProgramImportDetailForm] TRO番組インポート詳細フォーム
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails(companyId: number, troProgramImportDetailForm?: TroProgramImportDetailForm, options?: any): AxiosPromise<TroProgramImportDetailList> {
            return localVarFp.postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails(companyId, troProgramImportDetailForm, options).then((request) => request(axios, basePath));
        },
        /**
         * TROのDSP探索を実行し、結果をDBに保管する
         * @summary TRO探索実行
         * @param {number} troProjectId TROプロジェクトID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId: number, options?: any): AxiosPromise<void> {
            return localVarFp.postCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * TROプロジェクト更新
         * @summary TROプロジェクト更新
         * @param {number} troProjectId プロジェクトID
         * @param {TroProjectForm} [troProjectForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCmPlanningTroProjectsTroProjectId(troProjectId: number, troProjectForm?: TroProjectForm, options?: any): AxiosPromise<TroProject> {
            return localVarFp.putCmPlanningTroProjectsTroProjectId(troProjectId, troProjectForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TroApi - object-oriented interface
 * @export
 * @class TroApi
 * @extends {BaseAPI}
 */
export class TroApi extends BaseAPI {
    /**
     * TROプロジェクト削除
     * @summary TROプロジェクト削除
     * @param {number} troProjectId プロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TroApi
     */
    public deleteCmPlanningTroProjectsTroProjectId(troProjectId: number, options?: any) {
        return TroApiFp(this.configuration).deleteCmPlanningTroProjectsTroProjectId(troProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保存済みのTROプロジェクトの一覧を取得する
     * @summary TROプロジェクト一覧
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TroApi
     */
    public getCmPlanningCompaniesCompanyIdTroProjects(companyId: number, options?: any) {
        return TroApiFp(this.configuration).getCmPlanningCompaniesCompanyIdTroProjects(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保存されたTROプロジェクトの詳細情報を取得する（編集用）
     * @summary TROプロジェクト詳細取得
     * @param {number} troProjectId プロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TroApi
     */
    public getCmPlanningTroProjectsTroProjectId(troProjectId: number, options?: any) {
        return TroApiFp(this.configuration).getCmPlanningTroProjectsTroProjectId(troProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TRO探索実行で取得・登録された結果をDBより取得する
     * @summary TRO探索結果取得
     * @param {number} troProjectId TROプロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TroApi
     */
    public getCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId: number, options?: any) {
        return TroApiFp(this.configuration).getCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TROプロジェクト登録
     * @summary TROプロジェクト登録
     * @param {number} companyId カンパニーID
     * @param {TroProjectForm} [troProjectForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TroApi
     */
    public postCmPlanningCompaniesCompanyIdTroProjects(companyId: number, troProjectForm?: TroProjectForm, options?: any) {
        return TroApiFp(this.configuration).postCmPlanningCompaniesCompanyIdTroProjects(companyId, troProjectForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TRO番組インポート情報詳細取得API
     * @summary TRO番組インポート情報詳細取得
     * @param {number} companyId カンパニーID
     * @param {TroProgramImportDetailForm} [troProgramImportDetailForm] TRO番組インポート詳細フォーム
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TroApi
     */
    public postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails(companyId: number, troProgramImportDetailForm?: TroProgramImportDetailForm, options?: any) {
        return TroApiFp(this.configuration).postCmPlanningCompaniesCompanyIdTroProjectsProgramImportDetails(companyId, troProgramImportDetailForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TROのDSP探索を実行し、結果をDBに保管する
     * @summary TRO探索実行
     * @param {number} troProjectId TROプロジェクトID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TroApi
     */
    public postCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId: number, options?: any) {
        return TroApiFp(this.configuration).postCmPlanningTroProjectsTroProjectIdTroSearch(troProjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TROプロジェクト更新
     * @summary TROプロジェクト更新
     * @param {number} troProjectId プロジェクトID
     * @param {TroProjectForm} [troProjectForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TroApi
     */
    public putCmPlanningTroProjectsTroProjectId(troProjectId: number, troProjectForm?: TroProjectForm, options?: any) {
        return TroApiFp(this.configuration).putCmPlanningTroProjectsTroProjectId(troProjectId, troProjectForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TvDataSearchApi - axios parameter creator
 * @export
 */
export const TvDataSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * CM期間集計の検索履歴を削除する
         * @summary CM期間集計履歴削除
         * @param {number} cmAggregateSearchHistoryId CM期間集計履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId: async (cmAggregateSearchHistoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmAggregateSearchHistoryId' is not null or undefined
            assertParamExists('deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId', 'cmAggregateSearchHistoryId', cmAggregateSearchHistoryId)
            const localVarPath = `/tvdata-search/cm-aggregate/histories/{cmAggregateSearchHistoryId}`
                .replace(`{${"cmAggregateSearchHistoryId"}}`, encodeURIComponent(String(cmAggregateSearchHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CMリストの検索履歴を削除する
         * @summary CMリスト検索履歴削除
         * @param {number} cmListSearchHistoryId CMリスト検索履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTvdataSearchCmListHistoriesCmListSearchHistoryId: async (cmListSearchHistoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmListSearchHistoryId' is not null or undefined
            assertParamExists('deleteTvdataSearchCmListHistoriesCmListSearchHistoryId', 'cmListSearchHistoryId', cmListSearchHistoryId)
            const localVarPath = `/tvdata-search/cm-list/histories/{cmListSearchHistoryId}`
                .replace(`{${"cmListSearchHistoryId"}}`, encodeURIComponent(String(cmListSearchHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * CM R&Fの検索履歴を削除する
         * @summary CM R&F履歴削除
         * @param {number} cmrfSearchHistoryId CM R&amp;F履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId: async (cmrfSearchHistoryId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cmrfSearchHistoryId' is not null or undefined
            assertParamExists('deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId', 'cmrfSearchHistoryId', cmrfSearchHistoryId)
            const localVarPath = `/tvdata-search/cm-reach-and-frequency/histories/{cmrfSearchHistoryId}`
                .replace(`{${"cmrfSearchHistoryId"}}`, encodeURIComponent(String(cmrfSearchHistoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCM期間集計の検索履歴を取得する
         * @summary CM期間集計履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmAggregateCompaniesCompanyIdHistories: async (companyId: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateCompaniesCompanyIdHistories', 'companyId', companyId)
            const localVarPath = `/tvdata-search/cm-aggregate/companies/{companyId}/histories`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCM期間集計を検索する
         * @summary TVデータサーチのCM期間集計検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmAggregateSearch: async (companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'endTime', endTime)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'areaCode', areaCode)
            // verify required parameter 'targetType' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'targetType', targetType)
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'stations', stations)
            // verify required parameter 'isConversion15sec' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'isConversion15sec', isConversion15sec)
            // verify required parameter 'viewingType' is not null or undefined
            assertParamExists('getTvdataSearchCmAggregateSearch', 'viewingType', viewingType)
            const localVarPath = `/tvdata-search/cm-aggregate/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (buyingKind !== undefined) {
                localVarQueryParameter['buyingKind'] = buyingKind;
            }

            if (cmDuration !== undefined) {
                localVarQueryParameter['cmDuration'] = cmDuration;
            }

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (cmCreativeIds) {
                localVarQueryParameter['cmCreativeIds'] = cmCreativeIds;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (isConversion15sec !== undefined) {
                localVarQueryParameter['isConversion15sec'] = isConversion15sec;
            }

            if (viewingType !== undefined) {
                localVarQueryParameter['viewingType'] = viewingType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCM素材を検索する
         * @summary TVデータサーチのCM素材検索
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} cmProductIds CM商品IDリスト
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmCreativesSearch: async (startDate: string, endDate: string, cmProductIds: Array<number>, companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchCmCreativesSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchCmCreativesSearch', 'endDate', endDate)
            // verify required parameter 'cmProductIds' is not null or undefined
            assertParamExists('getTvdataSearchCmCreativesSearch', 'cmProductIds', cmProductIds)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmCreativesSearch', 'companyId', companyId)
            const localVarPath = `/tvdata-search/cm-creatives/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCMリストの検索履歴を取得する
         * @summary CMリスト検索履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmListCompaniesCompanyIdHistories: async (companyId: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmListCompaniesCompanyIdHistories', 'companyId', companyId)
            const localVarPath = `/tvdata-search/cm-list/companies/{companyId}/histories`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCMリスト項目を検索する
         * @summary TVデータサーチのCMリスト検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {number} companyId カンパニーID
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {Array<number>} [categoryIds] カテゴリーIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmListSearch: async (stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', companyId: number, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, targetIds?: Array<number>, categoryIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'stations', stations)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'endTime', endTime)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'areaCode', areaCode)
            // verify required parameter 'targetType' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'targetType', targetType)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'companyId', companyId)
            // verify required parameter 'isConversion15sec' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'isConversion15sec', isConversion15sec)
            // verify required parameter 'viewingType' is not null or undefined
            assertParamExists('getTvdataSearchCmListSearch', 'viewingType', viewingType)
            const localVarPath = `/tvdata-search/cm-list/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (cmDuration !== undefined) {
                localVarQueryParameter['cmDuration'] = cmDuration;
            }

            if (buyingKind !== undefined) {
                localVarQueryParameter['buyingKind'] = buyingKind;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (isConversion15sec !== undefined) {
                localVarQueryParameter['isConversion15sec'] = isConversion15sec;
            }

            if (viewingType !== undefined) {
                localVarQueryParameter['viewingType'] = viewingType;
            }

            if (categoryIds) {
                localVarQueryParameter['categoryIds'] = categoryIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCM R&Fの検索履歴を取得する
         * @summary CM R&F履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories: async (companyId: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories', 'companyId', companyId)
            const localVarPath = `/tvdata-search/cm-reach-and-frequency/companies/{companyId}/histories`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCM R&F CSVファイルをダウンロードする
         * @summary TVデータサーチのCM R&F CSVダウンロード
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {Array<string>} stations 放送局リスト
         * @param {'DAY' | 'WEEK' | 'MONTH' | 'CM'} separationType 分離種別（CM or 期間区切り）
         * @param {'ALL' | 'CM_SPONSOR' | 'PRODUCT' | 'CM_CREATIVE'} aggregationType 集計種別（集計軸）
         * @param {'ALL' | 'STATION'} stationType 放送局種別
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmReachAndFrequencyCsv: async (companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, useCustomTarget: boolean, stations: Array<string>, separationType: 'DAY' | 'WEEK' | 'MONTH' | 'CM', aggregationType: 'ALL' | 'CM_SPONSOR' | 'PRODUCT' | 'CM_CREATIVE', stationType: 'ALL' | 'STATION', isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'endTime', endTime)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'stations', stations)
            // verify required parameter 'separationType' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'separationType', separationType)
            // verify required parameter 'aggregationType' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'aggregationType', aggregationType)
            // verify required parameter 'stationType' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'stationType', stationType)
            // verify required parameter 'isConversion15sec' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'isConversion15sec', isConversion15sec)
            // verify required parameter 'viewingType' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyCsv', 'viewingType', viewingType)
            const localVarPath = `/tvdata-search/cm-reach-and-frequency/csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (buyingKind !== undefined) {
                localVarQueryParameter['buyingKind'] = buyingKind;
            }

            if (cmDuration !== undefined) {
                localVarQueryParameter['cmDuration'] = cmDuration;
            }

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (cmCreativeIds) {
                localVarQueryParameter['cmCreativeIds'] = cmCreativeIds;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (separationType !== undefined) {
                localVarQueryParameter['separationType'] = separationType;
            }

            if (aggregationType !== undefined) {
                localVarQueryParameter['aggregationType'] = aggregationType;
            }

            if (stationType !== undefined) {
                localVarQueryParameter['stationType'] = stationType;
            }

            if (isConversion15sec !== undefined) {
                localVarQueryParameter['isConversion15sec'] = isConversion15sec;
            }

            if (viewingType !== undefined) {
                localVarQueryParameter['viewingType'] = viewingType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCM R&Fグラフデータを検索する
         * @summary TVデータサーチのCM R&Fグラフデータ検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [fqThresholds] FQ閾値リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmReachAndFrequencyGraphDataSearch: async (companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, useCustomTarget: boolean, stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, fqThresholds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'endTime', endTime)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'stations', stations)
            // verify required parameter 'isConversion15sec' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'isConversion15sec', isConversion15sec)
            // verify required parameter 'viewingType' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyGraphDataSearch', 'viewingType', viewingType)
            const localVarPath = `/tvdata-search/cm-reach-and-frequency/graph-data/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (buyingKind !== undefined) {
                localVarQueryParameter['buyingKind'] = buyingKind;
            }

            if (cmDuration !== undefined) {
                localVarQueryParameter['cmDuration'] = cmDuration;
            }

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (cmCreativeIds) {
                localVarQueryParameter['cmCreativeIds'] = cmCreativeIds;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (fqThresholds) {
                localVarQueryParameter['fqThresholds'] = fqThresholds;
            }

            if (isConversion15sec !== undefined) {
                localVarQueryParameter['isConversion15sec'] = isConversion15sec;
            }

            if (viewingType !== undefined) {
                localVarQueryParameter['viewingType'] = viewingType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCM R&F表データを検索する
         * @summary TVデータサーチのCM R&F表データ検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmReachAndFrequencyTableDataSearch: async (companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'endTime', endTime)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'areaCode', areaCode)
            // verify required parameter 'targetType' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'targetType', targetType)
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'stations', stations)
            // verify required parameter 'isConversion15sec' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'isConversion15sec', isConversion15sec)
            // verify required parameter 'viewingType' is not null or undefined
            assertParamExists('getTvdataSearchCmReachAndFrequencyTableDataSearch', 'viewingType', viewingType)
            const localVarPath = `/tvdata-search/cm-reach-and-frequency/table-data/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }

            if (buyingKind !== undefined) {
                localVarQueryParameter['buyingKind'] = buyingKind;
            }

            if (cmDuration !== undefined) {
                localVarQueryParameter['cmDuration'] = cmDuration;
            }

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (cmCreativeIds) {
                localVarQueryParameter['cmCreativeIds'] = cmCreativeIds;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (isConversion15sec !== undefined) {
                localVarQueryParameter['isConversion15sec'] = isConversion15sec;
            }

            if (viewingType !== undefined) {
                localVarQueryParameter['viewingType'] = viewingType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCM企業名を検索する
         * @summary TVデータサーチのCM企業名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [cmSponsorName] CM企業名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmSponsorsSearch: async (stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, cmSponsorName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchCmSponsorsSearch', 'stations', stations)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchCmSponsorsSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchCmSponsorsSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchCmSponsorsSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchCmSponsorsSearch', 'endTime', endTime)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchCmSponsorsSearch', 'companyId', companyId)
            const localVarPath = `/tvdata-search/cm-sponsors/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (cmDuration !== undefined) {
                localVarQueryParameter['cmDuration'] = cmDuration;
            }

            if (buyingKind !== undefined) {
                localVarQueryParameter['buyingKind'] = buyingKind;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (cmSponsorName !== undefined) {
                localVarQueryParameter['cmSponsorName'] = cmSponsorName;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチの商品名を検索する
         * @summary TVデータサーチのCM商品名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [cmProductName] CM商品名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProductsSearch: async (stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, cmProductName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchProductsSearch', 'stations', stations)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchProductsSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchProductsSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchProductsSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchProductsSearch', 'endTime', endTime)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchProductsSearch', 'companyId', companyId)
            const localVarPath = `/tvdata-search/cm-products/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (cmDuration !== undefined) {
                localVarQueryParameter['cmDuration'] = cmDuration;
            }

            if (buyingKind !== undefined) {
                localVarQueryParameter['buyingKind'] = buyingKind;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (cmProductName !== undefined) {
                localVarQueryParameter['cmProductName'] = cmProductName;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチの番組リスト用番組名を検索する
         * @summary TVデータサーチの番組リスト用番組名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {number} companyId カンパニーID
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [programName] 番組名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProgramListProgramsSearch: async (stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, companyId: number, programSeriesIds?: Array<number>, programName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'stations', stations)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'endTime', endTime)
            // verify required parameter 'dayOfWeeks' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'dayOfWeeks', dayOfWeeks)
            // verify required parameter 'programGenres' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'programGenres', programGenres)
            // verify required parameter 'programTypes' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'programTypes', programTypes)
            // verify required parameter 'containsHoliday' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'containsHoliday', containsHoliday)
            // verify required parameter 'containsMiniPrograms' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'containsMiniPrograms', containsMiniPrograms)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchProgramListProgramsSearch', 'companyId', companyId)
            const localVarPath = `/tvdata-search/program-list/programs/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (dayOfWeeks) {
                localVarQueryParameter['dayOfWeeks'] = dayOfWeeks;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (programName !== undefined) {
                localVarQueryParameter['programName'] = programName;
            }

            if (programGenres) {
                localVarQueryParameter['programGenres'] = programGenres;
            }

            if (programTypes) {
                localVarQueryParameter['programTypes'] = programTypes;
            }

            if (containsHoliday !== undefined) {
                localVarQueryParameter['containsHoliday'] = containsHoliday;
            }

            if (containsMiniPrograms !== undefined) {
                localVarQueryParameter['containsMiniPrograms'] = containsMiniPrograms;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチの番組リスト項目を検索する
         * @summary TVデータサーチの番組リスト検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<'VIEWING_RATE' | 'CONTENT_RATE'>} dataDivisionList データ区分リスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProgramListSearch: async (stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, areaCode: string, useCustomTarget: boolean, companyId: number, dataDivisionList: Array<'VIEWING_RATE' | 'CONTENT_RATE'>, programSeriesIds?: Array<number>, targetIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'stations', stations)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'endTime', endTime)
            // verify required parameter 'dayOfWeeks' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'dayOfWeeks', dayOfWeeks)
            // verify required parameter 'programGenres' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'programGenres', programGenres)
            // verify required parameter 'programTypes' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'programTypes', programTypes)
            // verify required parameter 'containsHoliday' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'containsHoliday', containsHoliday)
            // verify required parameter 'containsMiniPrograms' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'containsMiniPrograms', containsMiniPrograms)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'companyId', companyId)
            // verify required parameter 'dataDivisionList' is not null or undefined
            assertParamExists('getTvdataSearchProgramListSearch', 'dataDivisionList', dataDivisionList)
            const localVarPath = `/tvdata-search/program-list/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (dayOfWeeks) {
                localVarQueryParameter['dayOfWeeks'] = dayOfWeeks;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (programGenres) {
                localVarQueryParameter['programGenres'] = programGenres;
            }

            if (programTypes) {
                localVarQueryParameter['programTypes'] = programTypes;
            }

            if (containsHoliday !== undefined) {
                localVarQueryParameter['containsHoliday'] = containsHoliday;
            }

            if (containsMiniPrograms !== undefined) {
                localVarQueryParameter['containsMiniPrograms'] = containsMiniPrograms;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (dataDivisionList) {
                localVarQueryParameter['dataDivisionList'] = dataDivisionList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチの番組期間平均項目を検索する
         * @summary TVデータサーチの番組期間平均検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<'VIEWING_RATE' | 'CONTENT_RATE'>} dataDivisionList データ区分リスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProgramPeriodAverageSearch: async (stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, areaCode: string, useCustomTarget: boolean, companyId: number, dataDivisionList: Array<'VIEWING_RATE' | 'CONTENT_RATE'>, programSeriesIds?: Array<number>, targetIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'stations', stations)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'endTime', endTime)
            // verify required parameter 'dayOfWeeks' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'dayOfWeeks', dayOfWeeks)
            // verify required parameter 'programGenres' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'programGenres', programGenres)
            // verify required parameter 'programTypes' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'programTypes', programTypes)
            // verify required parameter 'containsHoliday' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'containsHoliday', containsHoliday)
            // verify required parameter 'containsMiniPrograms' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'containsMiniPrograms', containsMiniPrograms)
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'companyId', companyId)
            // verify required parameter 'dataDivisionList' is not null or undefined
            assertParamExists('getTvdataSearchProgramPeriodAverageSearch', 'dataDivisionList', dataDivisionList)
            const localVarPath = `/tvdata-search/program-period-average/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (dayOfWeeks) {
                localVarQueryParameter['dayOfWeeks'] = dayOfWeeks;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (programGenres) {
                localVarQueryParameter['programGenres'] = programGenres;
            }

            if (programTypes) {
                localVarQueryParameter['programTypes'] = programTypes;
            }

            if (containsHoliday !== undefined) {
                localVarQueryParameter['containsHoliday'] = containsHoliday;
            }

            if (containsMiniPrograms !== undefined) {
                localVarQueryParameter['containsMiniPrograms'] = containsMiniPrograms;
            }

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (dataDivisionList) {
                localVarQueryParameter['dataDivisionList'] = dataDivisionList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチのCMリスト用の番組名を検索する
         * @summary TVデータサーチのCMリスト用番組名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [programName] 番組名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProgramsSearch: async (stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, programName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stations' is not null or undefined
            assertParamExists('getTvdataSearchProgramsSearch', 'stations', stations)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchProgramsSearch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchProgramsSearch', 'endDate', endDate)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getTvdataSearchProgramsSearch', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getTvdataSearchProgramsSearch', 'endTime', endTime)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchProgramsSearch', 'companyId', companyId)
            const localVarPath = `/tvdata-search/programs/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stations) {
                localVarQueryParameter['stations'] = stations;
            }

            if (cmDuration !== undefined) {
                localVarQueryParameter['cmDuration'] = cmDuration;
            }

            if (buyingKind !== undefined) {
                localVarQueryParameter['buyingKind'] = buyingKind;
            }

            if (cmSponsorIds) {
                localVarQueryParameter['cmSponsorIds'] = cmSponsorIds;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (cmProductIds) {
                localVarQueryParameter['cmProductIds'] = cmProductIds;
            }

            if (programSeriesIds) {
                localVarQueryParameter['programSeriesIds'] = programSeriesIds;
            }

            if (programName !== undefined) {
                localVarQueryParameter['programName'] = programName;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチ用の各週有効サンプルサイズを取得する
         * @summary TVデータサーチの各週有効サンプルサイズ取得
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchSampleSize: async (areaCode: string, useCustomTarget: boolean, companyId: number, startDate: string, endDate: string, targetIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchSampleSize', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getTvdataSearchSampleSize', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchSampleSize', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchSampleSize', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchSampleSize', 'endDate', endDate)
            const localVarPath = `/tvdata-search/sample-size`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TVデータサーチ用の期間内有効サンプルサイズを取得する
         * @summary TVデータサーチの期間内有効サンプルサイズ取得
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchSampleSizeIntersection: async (areaCode: string, useCustomTarget: boolean, companyId: number, startDate: string, endDate: string, targetIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchSampleSizeIntersection', 'areaCode', areaCode)
            // verify required parameter 'useCustomTarget' is not null or undefined
            assertParamExists('getTvdataSearchSampleSizeIntersection', 'useCustomTarget', useCustomTarget)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchSampleSizeIntersection', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getTvdataSearchSampleSizeIntersection', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getTvdataSearchSampleSizeIntersection', 'endDate', endDate)
            const localVarPath = `/tvdata-search/sample-size/intersection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (targetIds) {
                localVarQueryParameter['targetIds'] = targetIds;
            }

            if (useCustomTarget !== undefined) {
                localVarQueryParameter['useCustomTarget'] = useCustomTarget;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア別の東阪名における放送局名もしくはネットワーク系列名を取得する
         * @summary エリア別の放送局取得
         * @param {string} areaCode エリアコード
         * @param {number} companyId カンパニーID
         * @param {boolean} containsCmBroadcast CM出稿を含むフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchStations: async (areaCode: string, companyId: number, containsCmBroadcast: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaCode' is not null or undefined
            assertParamExists('getTvdataSearchStations', 'areaCode', areaCode)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getTvdataSearchStations', 'companyId', companyId)
            // verify required parameter 'containsCmBroadcast' is not null or undefined
            assertParamExists('getTvdataSearchStations', 'containsCmBroadcast', containsCmBroadcast)
            const localVarPath = `/tvdata-search/stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (areaCode !== undefined) {
                localVarQueryParameter['areaCode'] = areaCode;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (containsCmBroadcast !== undefined) {
                localVarQueryParameter['containsCmBroadcast'] = containsCmBroadcast;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TvDataSearchApi - functional programming interface
 * @export
 */
export const TvDataSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TvDataSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * CM期間集計の検索履歴を削除する
         * @summary CM期間集計履歴削除
         * @param {number} cmAggregateSearchHistoryId CM期間集計履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId(cmAggregateSearchHistoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId(cmAggregateSearchHistoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CMリストの検索履歴を削除する
         * @summary CMリスト検索履歴削除
         * @param {number} cmListSearchHistoryId CMリスト検索履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTvdataSearchCmListHistoriesCmListSearchHistoryId(cmListSearchHistoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTvdataSearchCmListHistoriesCmListSearchHistoryId(cmListSearchHistoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * CM R&Fの検索履歴を削除する
         * @summary CM R&F履歴削除
         * @param {number} cmrfSearchHistoryId CM R&amp;F履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId(cmrfSearchHistoryId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId(cmrfSearchHistoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCM期間集計の検索履歴を取得する
         * @summary CM期間集計履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmAggregateCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvDataSearchCmAggregateHistories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmAggregateCompaniesCompanyIdHistories(companyId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCM期間集計を検索する
         * @summary TVデータサーチのCM期間集計検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmAggregateSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvDataSearchCmGrpAllList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmAggregateSearch(companyId, startDate, endDate, startTime, endTime, areaCode, targetType, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCM素材を検索する
         * @summary TVデータサーチのCM素材検索
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} cmProductIds CM商品IDリスト
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmCreativesSearch(startDate: string, endDate: string, cmProductIds: Array<number>, companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TvDataSearchCmCreative>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmCreativesSearch(startDate, endDate, cmProductIds, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCMリストの検索履歴を取得する
         * @summary CMリスト検索履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmListCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvDataSearchCmListHistories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmListCompaniesCompanyIdHistories(companyId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCMリスト項目を検索する
         * @summary TVデータサーチのCMリスト検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {number} companyId カンパニーID
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {Array<number>} [categoryIds] カテゴリーIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmListSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', companyId: number, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, targetIds?: Array<number>, categoryIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvDataSearchCmList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmListSearch(stations, startDate, endDate, startTime, endTime, areaCode, targetType, companyId, isConversion15sec, viewingType, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, targetIds, categoryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCM R&Fの検索履歴を取得する
         * @summary CM R&F履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvDataSearchCmrfHistories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories(companyId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCM R&F CSVファイルをダウンロードする
         * @summary TVデータサーチのCM R&F CSVダウンロード
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {Array<string>} stations 放送局リスト
         * @param {'DAY' | 'WEEK' | 'MONTH' | 'CM'} separationType 分離種別（CM or 期間区切り）
         * @param {'ALL' | 'CM_SPONSOR' | 'PRODUCT' | 'CM_CREATIVE'} aggregationType 集計種別（集計軸）
         * @param {'ALL' | 'STATION'} stationType 放送局種別
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmReachAndFrequencyCsv(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, useCustomTarget: boolean, stations: Array<string>, separationType: 'DAY' | 'WEEK' | 'MONTH' | 'CM', aggregationType: 'ALL' | 'CM_SPONSOR' | 'PRODUCT' | 'CM_CREATIVE', stationType: 'ALL' | 'STATION', isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmReachAndFrequencyCsv(companyId, startDate, endDate, startTime, endTime, areaCode, useCustomTarget, stations, separationType, aggregationType, stationType, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCM R&Fグラフデータを検索する
         * @summary TVデータサーチのCM R&Fグラフデータ検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [fqThresholds] FQ閾値リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmReachAndFrequencyGraphDataSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, useCustomTarget: boolean, stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, fqThresholds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FrequencyDistribution>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmReachAndFrequencyGraphDataSearch(companyId, startDate, endDate, startTime, endTime, areaCode, useCustomTarget, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, fqThresholds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCM R&F表データを検索する
         * @summary TVデータサーチのCM R&F表データ検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmReachAndFrequencyTableDataSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CmReachAndFrequencyTableData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmReachAndFrequencyTableDataSearch(companyId, startDate, endDate, startTime, endTime, areaCode, targetType, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCM企業名を検索する
         * @summary TVデータサーチのCM企業名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [cmSponsorName] CM企業名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchCmSponsorsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, cmSponsorName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TvDataSearchCmSponsor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchCmSponsorsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, cmSponsorName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチの商品名を検索する
         * @summary TVデータサーチのCM商品名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [cmProductName] CM商品名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchProductsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, cmProductName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TvDataSearchProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchProductsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, cmProductName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチの番組リスト用番組名を検索する
         * @summary TVデータサーチの番組リスト用番組名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {number} companyId カンパニーID
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [programName] 番組名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchProgramListProgramsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, companyId: number, programSeriesIds?: Array<number>, programName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TvDataSearchProgram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchProgramListProgramsSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, companyId, programSeriesIds, programName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチの番組リスト項目を検索する
         * @summary TVデータサーチの番組リスト検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<'VIEWING_RATE' | 'CONTENT_RATE'>} dataDivisionList データ区分リスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchProgramListSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, areaCode: string, useCustomTarget: boolean, companyId: number, dataDivisionList: Array<'VIEWING_RATE' | 'CONTENT_RATE'>, programSeriesIds?: Array<number>, targetIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvDataSearchProgramList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchProgramListSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, areaCode, useCustomTarget, companyId, dataDivisionList, programSeriesIds, targetIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチの番組期間平均項目を検索する
         * @summary TVデータサーチの番組期間平均検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<'VIEWING_RATE' | 'CONTENT_RATE'>} dataDivisionList データ区分リスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchProgramPeriodAverageSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, areaCode: string, useCustomTarget: boolean, companyId: number, dataDivisionList: Array<'VIEWING_RATE' | 'CONTENT_RATE'>, programSeriesIds?: Array<number>, targetIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvDataSearchProgramPeriodAverage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchProgramPeriodAverageSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, areaCode, useCustomTarget, companyId, dataDivisionList, programSeriesIds, targetIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチのCMリスト用の番組名を検索する
         * @summary TVデータサーチのCMリスト用番組名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [programName] 番組名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchProgramsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, programName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TvDataSearchProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchProgramsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, programName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチ用の各週有効サンプルサイズを取得する
         * @summary TVデータサーチの各週有効サンプルサイズ取得
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchSampleSize(areaCode: string, useCustomTarget: boolean, companyId: number, startDate: string, endDate: string, targetIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SampleSize>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchSampleSize(areaCode, useCustomTarget, companyId, startDate, endDate, targetIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TVデータサーチ用の期間内有効サンプルサイズを取得する
         * @summary TVデータサーチの期間内有効サンプルサイズ取得
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchSampleSizeIntersection(areaCode: string, useCustomTarget: boolean, companyId: number, startDate: string, endDate: string, targetIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SampleSize>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchSampleSizeIntersection(areaCode, useCustomTarget, companyId, startDate, endDate, targetIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * エリア別の東阪名における放送局名もしくはネットワーク系列名を取得する
         * @summary エリア別の放送局取得
         * @param {string} areaCode エリアコード
         * @param {number} companyId カンパニーID
         * @param {boolean} containsCmBroadcast CM出稿を含むフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTvdataSearchStations(areaCode: string, companyId: number, containsCmBroadcast: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KeyStation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTvdataSearchStations(areaCode, companyId, containsCmBroadcast, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TvDataSearchApi - factory interface
 * @export
 */
export const TvDataSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TvDataSearchApiFp(configuration)
    return {
        /**
         * CM期間集計の検索履歴を削除する
         * @summary CM期間集計履歴削除
         * @param {number} cmAggregateSearchHistoryId CM期間集計履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId(cmAggregateSearchHistoryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId(cmAggregateSearchHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * CMリストの検索履歴を削除する
         * @summary CMリスト検索履歴削除
         * @param {number} cmListSearchHistoryId CMリスト検索履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTvdataSearchCmListHistoriesCmListSearchHistoryId(cmListSearchHistoryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTvdataSearchCmListHistoriesCmListSearchHistoryId(cmListSearchHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * CM R&Fの検索履歴を削除する
         * @summary CM R&F履歴削除
         * @param {number} cmrfSearchHistoryId CM R&amp;F履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId(cmrfSearchHistoryId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId(cmrfSearchHistoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCM期間集計の検索履歴を取得する
         * @summary CM期間集計履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmAggregateCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any): AxiosPromise<TvDataSearchCmAggregateHistories> {
            return localVarFp.getTvdataSearchCmAggregateCompaniesCompanyIdHistories(companyId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCM期間集計を検索する
         * @summary TVデータサーチのCM期間集計検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmAggregateSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any): AxiosPromise<TvDataSearchCmGrpAllList> {
            return localVarFp.getTvdataSearchCmAggregateSearch(companyId, startDate, endDate, startTime, endTime, areaCode, targetType, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCM素材を検索する
         * @summary TVデータサーチのCM素材検索
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} cmProductIds CM商品IDリスト
         * @param {number} companyId カンパニーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmCreativesSearch(startDate: string, endDate: string, cmProductIds: Array<number>, companyId: number, options?: any): AxiosPromise<Array<TvDataSearchCmCreative>> {
            return localVarFp.getTvdataSearchCmCreativesSearch(startDate, endDate, cmProductIds, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCMリストの検索履歴を取得する
         * @summary CMリスト検索履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmListCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any): AxiosPromise<TvDataSearchCmListHistories> {
            return localVarFp.getTvdataSearchCmListCompaniesCompanyIdHistories(companyId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCMリスト項目を検索する
         * @summary TVデータサーチのCMリスト検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {number} companyId カンパニーID
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {Array<number>} [categoryIds] カテゴリーIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmListSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', companyId: number, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, targetIds?: Array<number>, categoryIds?: Array<number>, options?: any): AxiosPromise<TvDataSearchCmList> {
            return localVarFp.getTvdataSearchCmListSearch(stations, startDate, endDate, startTime, endTime, areaCode, targetType, companyId, isConversion15sec, viewingType, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, targetIds, categoryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCM R&Fの検索履歴を取得する
         * @summary CM R&F履歴取得
         * @param {number} companyId カンパニーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any): AxiosPromise<TvDataSearchCmrfHistories> {
            return localVarFp.getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories(companyId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCM R&F CSVファイルをダウンロードする
         * @summary TVデータサーチのCM R&F CSVダウンロード
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {Array<string>} stations 放送局リスト
         * @param {'DAY' | 'WEEK' | 'MONTH' | 'CM'} separationType 分離種別（CM or 期間区切り）
         * @param {'ALL' | 'CM_SPONSOR' | 'PRODUCT' | 'CM_CREATIVE'} aggregationType 集計種別（集計軸）
         * @param {'ALL' | 'STATION'} stationType 放送局種別
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmReachAndFrequencyCsv(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, useCustomTarget: boolean, stations: Array<string>, separationType: 'DAY' | 'WEEK' | 'MONTH' | 'CM', aggregationType: 'ALL' | 'CM_SPONSOR' | 'PRODUCT' | 'CM_CREATIVE', stationType: 'ALL' | 'STATION', isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any): AxiosPromise<any> {
            return localVarFp.getTvdataSearchCmReachAndFrequencyCsv(companyId, startDate, endDate, startTime, endTime, areaCode, useCustomTarget, stations, separationType, aggregationType, stationType, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCM R&Fグラフデータを検索する
         * @summary TVデータサーチのCM R&Fグラフデータ検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [fqThresholds] FQ閾値リスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmReachAndFrequencyGraphDataSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, useCustomTarget: boolean, stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, fqThresholds?: Array<number>, options?: any): AxiosPromise<Array<FrequencyDistribution>> {
            return localVarFp.getTvdataSearchCmReachAndFrequencyGraphDataSearch(companyId, startDate, endDate, startTime, endTime, areaCode, useCustomTarget, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, fqThresholds, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCM R&F表データを検索する
         * @summary TVデータサーチのCM R&F表データ検索
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {string} areaCode エリアコード
         * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
         * @param {Array<string>} stations 放送局リスト
         * @param {boolean} isConversion15sec 15秒換算
         * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] 商品IDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {string} [buyingKind] CM種別
         * @param {number} [cmDuration] CM秒数
         * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmReachAndFrequencyTableDataSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any): AxiosPromise<CmReachAndFrequencyTableData> {
            return localVarFp.getTvdataSearchCmReachAndFrequencyTableDataSearch(companyId, startDate, endDate, startTime, endTime, areaCode, targetType, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCM企業名を検索する
         * @summary TVデータサーチのCM企業名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [cmSponsorName] CM企業名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchCmSponsorsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, cmSponsorName?: string, options?: any): AxiosPromise<Array<TvDataSearchCmSponsor>> {
            return localVarFp.getTvdataSearchCmSponsorsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, cmSponsorName, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチの商品名を検索する
         * @summary TVデータサーチのCM商品名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [cmProductName] CM商品名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProductsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, cmProductName?: string, options?: any): AxiosPromise<Array<TvDataSearchProduct>> {
            return localVarFp.getTvdataSearchProductsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, cmProductName, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチの番組リスト用番組名を検索する
         * @summary TVデータサーチの番組リスト用番組名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {number} companyId カンパニーID
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [programName] 番組名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProgramListProgramsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, companyId: number, programSeriesIds?: Array<number>, programName?: string, options?: any): AxiosPromise<Array<TvDataSearchProgram>> {
            return localVarFp.getTvdataSearchProgramListProgramsSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, companyId, programSeriesIds, programName, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチの番組リスト項目を検索する
         * @summary TVデータサーチの番組リスト検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<'VIEWING_RATE' | 'CONTENT_RATE'>} dataDivisionList データ区分リスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProgramListSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, areaCode: string, useCustomTarget: boolean, companyId: number, dataDivisionList: Array<'VIEWING_RATE' | 'CONTENT_RATE'>, programSeriesIds?: Array<number>, targetIds?: Array<number>, options?: any): AxiosPromise<TvDataSearchProgramList> {
            return localVarFp.getTvdataSearchProgramListSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, areaCode, useCustomTarget, companyId, dataDivisionList, programSeriesIds, targetIds, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチの番組期間平均項目を検索する
         * @summary TVデータサーチの番組期間平均検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
         * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
         * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
         * @param {boolean} containsHoliday 祝日を含むフラグ
         * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {Array<'VIEWING_RATE' | 'CONTENT_RATE'>} dataDivisionList データ区分リスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProgramPeriodAverageSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, areaCode: string, useCustomTarget: boolean, companyId: number, dataDivisionList: Array<'VIEWING_RATE' | 'CONTENT_RATE'>, programSeriesIds?: Array<number>, targetIds?: Array<number>, options?: any): AxiosPromise<TvDataSearchProgramPeriodAverage> {
            return localVarFp.getTvdataSearchProgramPeriodAverageSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, areaCode, useCustomTarget, companyId, dataDivisionList, programSeriesIds, targetIds, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチのCMリスト用の番組名を検索する
         * @summary TVデータサーチのCMリスト用番組名検索
         * @param {Array<string>} stations 放送局リスト
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {string} startTime 検索開始時刻
         * @param {string} endTime 検索終了時刻
         * @param {number} companyId カンパニーID
         * @param {number} [cmDuration] CM秒数
         * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
         * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
         * @param {Array<number>} [cmProductIds] CM商品IDリスト
         * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
         * @param {string} [programName] 番組名
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchProgramsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, programName?: string, options?: any): AxiosPromise<TvDataSearchProgram> {
            return localVarFp.getTvdataSearchProgramsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, programName, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチ用の各週有効サンプルサイズを取得する
         * @summary TVデータサーチの各週有効サンプルサイズ取得
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchSampleSize(areaCode: string, useCustomTarget: boolean, companyId: number, startDate: string, endDate: string, targetIds?: Array<number>, options?: any): AxiosPromise<Array<SampleSize>> {
            return localVarFp.getTvdataSearchSampleSize(areaCode, useCustomTarget, companyId, startDate, endDate, targetIds, options).then((request) => request(axios, basePath));
        },
        /**
         * TVデータサーチ用の期間内有効サンプルサイズを取得する
         * @summary TVデータサーチの期間内有効サンプルサイズ取得
         * @param {string} areaCode エリアコード
         * @param {boolean} useCustomTarget カスタムターゲットの使用
         * @param {number} companyId カンパニーID
         * @param {string} startDate 検索開始日
         * @param {string} endDate 検索終了日
         * @param {Array<number>} [targetIds] ターゲットID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchSampleSizeIntersection(areaCode: string, useCustomTarget: boolean, companyId: number, startDate: string, endDate: string, targetIds?: Array<number>, options?: any): AxiosPromise<Array<SampleSize>> {
            return localVarFp.getTvdataSearchSampleSizeIntersection(areaCode, useCustomTarget, companyId, startDate, endDate, targetIds, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア別の東阪名における放送局名もしくはネットワーク系列名を取得する
         * @summary エリア別の放送局取得
         * @param {string} areaCode エリアコード
         * @param {number} companyId カンパニーID
         * @param {boolean} containsCmBroadcast CM出稿を含むフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTvdataSearchStations(areaCode: string, companyId: number, containsCmBroadcast: boolean, options?: any): AxiosPromise<Array<KeyStation>> {
            return localVarFp.getTvdataSearchStations(areaCode, companyId, containsCmBroadcast, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TvDataSearchApi - object-oriented interface
 * @export
 * @class TvDataSearchApi
 * @extends {BaseAPI}
 */
export class TvDataSearchApi extends BaseAPI {
    /**
     * CM期間集計の検索履歴を削除する
     * @summary CM期間集計履歴削除
     * @param {number} cmAggregateSearchHistoryId CM期間集計履歴ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId(cmAggregateSearchHistoryId: number, options?: any) {
        return TvDataSearchApiFp(this.configuration).deleteTvdataSearchCmAggregateCmAggregateSearchHistoryId(cmAggregateSearchHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CMリストの検索履歴を削除する
     * @summary CMリスト検索履歴削除
     * @param {number} cmListSearchHistoryId CMリスト検索履歴ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public deleteTvdataSearchCmListHistoriesCmListSearchHistoryId(cmListSearchHistoryId: number, options?: any) {
        return TvDataSearchApiFp(this.configuration).deleteTvdataSearchCmListHistoriesCmListSearchHistoryId(cmListSearchHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * CM R&Fの検索履歴を削除する
     * @summary CM R&F履歴削除
     * @param {number} cmrfSearchHistoryId CM R&amp;F履歴ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId(cmrfSearchHistoryId: number, options?: any) {
        return TvDataSearchApiFp(this.configuration).deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId(cmrfSearchHistoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCM期間集計の検索履歴を取得する
     * @summary CM期間集計履歴取得
     * @param {number} companyId カンパニーID
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmAggregateCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmAggregateCompaniesCompanyIdHistories(companyId, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCM期間集計を検索する
     * @summary TVデータサーチのCM期間集計検索
     * @param {number} companyId カンパニーID
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {string} areaCode エリアコード
     * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
     * @param {Array<string>} stations 放送局リスト
     * @param {boolean} isConversion15sec 15秒換算
     * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
     * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
     * @param {Array<number>} [cmProductIds] 商品IDリスト
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {string} [buyingKind] CM種別
     * @param {number} [cmDuration] CM秒数
     * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmAggregateSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmAggregateSearch(companyId, startDate, endDate, startTime, endTime, areaCode, targetType, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCM素材を検索する
     * @summary TVデータサーチのCM素材検索
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {Array<number>} cmProductIds CM商品IDリスト
     * @param {number} companyId カンパニーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmCreativesSearch(startDate: string, endDate: string, cmProductIds: Array<number>, companyId: number, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmCreativesSearch(startDate, endDate, cmProductIds, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCMリストの検索履歴を取得する
     * @summary CMリスト検索履歴取得
     * @param {number} companyId カンパニーID
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmListCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmListCompaniesCompanyIdHistories(companyId, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCMリスト項目を検索する
     * @summary TVデータサーチのCMリスト検索
     * @param {Array<string>} stations 放送局リスト
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {string} areaCode エリアコード
     * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
     * @param {number} companyId カンパニーID
     * @param {boolean} isConversion15sec 15秒換算
     * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
     * @param {number} [cmDuration] CM秒数
     * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
     * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
     * @param {Array<number>} [cmProductIds] 商品IDリスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {Array<number>} [categoryIds] カテゴリーIDリスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmListSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', companyId: number, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, targetIds?: Array<number>, categoryIds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmListSearch(stations, startDate, endDate, startTime, endTime, areaCode, targetType, companyId, isConversion15sec, viewingType, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, targetIds, categoryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCM R&Fの検索履歴を取得する
     * @summary CM R&F履歴取得
     * @param {number} companyId カンパニーID
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories(companyId: number, limit?: number, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories(companyId, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCM R&F CSVファイルをダウンロードする
     * @summary TVデータサーチのCM R&F CSVダウンロード
     * @param {number} companyId カンパニーID
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {string} areaCode エリアコード
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {Array<string>} stations 放送局リスト
     * @param {'DAY' | 'WEEK' | 'MONTH' | 'CM'} separationType 分離種別（CM or 期間区切り）
     * @param {'ALL' | 'CM_SPONSOR' | 'PRODUCT' | 'CM_CREATIVE'} aggregationType 集計種別（集計軸）
     * @param {'ALL' | 'STATION'} stationType 放送局種別
     * @param {boolean} isConversion15sec 15秒換算
     * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
     * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
     * @param {Array<number>} [cmProductIds] 商品IDリスト
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {string} [buyingKind] CM種別
     * @param {number} [cmDuration] CM秒数
     * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmReachAndFrequencyCsv(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, useCustomTarget: boolean, stations: Array<string>, separationType: 'DAY' | 'WEEK' | 'MONTH' | 'CM', aggregationType: 'ALL' | 'CM_SPONSOR' | 'PRODUCT' | 'CM_CREATIVE', stationType: 'ALL' | 'STATION', isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmReachAndFrequencyCsv(companyId, startDate, endDate, startTime, endTime, areaCode, useCustomTarget, stations, separationType, aggregationType, stationType, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCM R&Fグラフデータを検索する
     * @summary TVデータサーチのCM R&Fグラフデータ検索
     * @param {number} companyId カンパニーID
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {string} areaCode エリアコード
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {Array<string>} stations 放送局リスト
     * @param {boolean} isConversion15sec 15秒換算
     * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
     * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
     * @param {Array<number>} [cmProductIds] 商品IDリスト
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {string} [buyingKind] CM種別
     * @param {number} [cmDuration] CM秒数
     * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {Array<number>} [fqThresholds] FQ閾値リスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmReachAndFrequencyGraphDataSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, useCustomTarget: boolean, stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, fqThresholds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmReachAndFrequencyGraphDataSearch(companyId, startDate, endDate, startTime, endTime, areaCode, useCustomTarget, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, fqThresholds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCM R&F表データを検索する
     * @summary TVデータサーチのCM R&F表データ検索
     * @param {number} companyId カンパニーID
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {string} areaCode エリアコード
     * @param {'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM'} targetType ターゲットタイプ
     * @param {Array<string>} stations 放送局リスト
     * @param {boolean} isConversion15sec 15秒換算
     * @param {'REALTIME' | 'TOTAL'} viewingType 視聴種別
     * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
     * @param {Array<number>} [cmProductIds] 商品IDリスト
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {string} [buyingKind] CM種別
     * @param {number} [cmDuration] CM秒数
     * @param {Array<number>} [cmCreativeIds] CM素材IDリスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmReachAndFrequencyTableDataSearch(companyId: number, startDate: string, endDate: string, startTime: string, endTime: string, areaCode: string, targetType: 'INDIVIDUAL_AND_HOUSEHOLD' | 'BASIC_GA12' | 'BASIC_GA10S' | 'CUSTOM', stations: Array<string>, isConversion15sec: boolean, viewingType: 'REALTIME' | 'TOTAL', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, targetIds?: Array<number>, buyingKind?: string, cmDuration?: number, cmCreativeIds?: Array<number>, programSeriesIds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmReachAndFrequencyTableDataSearch(companyId, startDate, endDate, startTime, endTime, areaCode, targetType, stations, isConversion15sec, viewingType, cmSponsorIds, cmProductIds, targetIds, buyingKind, cmDuration, cmCreativeIds, programSeriesIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCM企業名を検索する
     * @summary TVデータサーチのCM企業名検索
     * @param {Array<string>} stations 放送局リスト
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {number} companyId カンパニーID
     * @param {number} [cmDuration] CM秒数
     * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
     * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
     * @param {Array<number>} [cmProductIds] CM商品IDリスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {string} [cmSponsorName] CM企業名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchCmSponsorsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, cmSponsorName?: string, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchCmSponsorsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, cmSponsorName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチの商品名を検索する
     * @summary TVデータサーチのCM商品名検索
     * @param {Array<string>} stations 放送局リスト
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {number} companyId カンパニーID
     * @param {number} [cmDuration] CM秒数
     * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
     * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
     * @param {Array<number>} [cmProductIds] CM商品IDリスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {string} [cmProductName] CM商品名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchProductsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, cmProductName?: string, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchProductsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, cmProductName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチの番組リスト用番組名を検索する
     * @summary TVデータサーチの番組リスト用番組名検索
     * @param {Array<string>} stations 放送局リスト
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
     * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
     * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
     * @param {boolean} containsHoliday 祝日を含むフラグ
     * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
     * @param {number} companyId カンパニーID
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {string} [programName] 番組名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchProgramListProgramsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, companyId: number, programSeriesIds?: Array<number>, programName?: string, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchProgramListProgramsSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, companyId, programSeriesIds, programName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチの番組リスト項目を検索する
     * @summary TVデータサーチの番組リスト検索
     * @param {Array<string>} stations 放送局リスト
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
     * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
     * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
     * @param {boolean} containsHoliday 祝日を含むフラグ
     * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
     * @param {string} areaCode エリアコード
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {number} companyId カンパニーID
     * @param {Array<'VIEWING_RATE' | 'CONTENT_RATE'>} dataDivisionList データ区分リスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchProgramListSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, areaCode: string, useCustomTarget: boolean, companyId: number, dataDivisionList: Array<'VIEWING_RATE' | 'CONTENT_RATE'>, programSeriesIds?: Array<number>, targetIds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchProgramListSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, areaCode, useCustomTarget, companyId, dataDivisionList, programSeriesIds, targetIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチの番組期間平均項目を検索する
     * @summary TVデータサーチの番組期間平均検索
     * @param {Array<string>} stations 放送局リスト
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>} dayOfWeeks 曜日リスト
     * @param {Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>} programGenres 番組ジャンルリスト
     * @param {Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>} programTypes 番組タイプリスト
     * @param {boolean} containsHoliday 祝日を含むフラグ
     * @param {boolean} containsMiniPrograms ミニ番組を含むフラグ
     * @param {string} areaCode エリアコード
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {number} companyId カンパニーID
     * @param {Array<'VIEWING_RATE' | 'CONTENT_RATE'>} dataDivisionList データ区分リスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchProgramPeriodAverageSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, dayOfWeeks: Array<'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'>, programGenres: Array<'NEWS' | 'WIDESHOW' | 'MUSIC' | 'VARIETY' | 'DRAMA' | 'ANIME' | 'CINEMA' | 'SPORTS' | 'DOCUMENTARY' | 'EDUCATION' | 'THEATRICAL_PLAY' | 'WELFARE' | 'OTHER'>, programTypes: Array<'REGULAR' | 'SPECIAL' | 'PROMOTION' | 'REBROADCASTING' | 'ONE_OFF' | 'UNKNOWN'>, containsHoliday: boolean, containsMiniPrograms: boolean, areaCode: string, useCustomTarget: boolean, companyId: number, dataDivisionList: Array<'VIEWING_RATE' | 'CONTENT_RATE'>, programSeriesIds?: Array<number>, targetIds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchProgramPeriodAverageSearch(stations, startDate, endDate, startTime, endTime, dayOfWeeks, programGenres, programTypes, containsHoliday, containsMiniPrograms, areaCode, useCustomTarget, companyId, dataDivisionList, programSeriesIds, targetIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチのCMリスト用の番組名を検索する
     * @summary TVデータサーチのCMリスト用番組名検索
     * @param {Array<string>} stations 放送局リスト
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {string} startTime 検索開始時刻
     * @param {string} endTime 検索終了時刻
     * @param {number} companyId カンパニーID
     * @param {number} [cmDuration] CM秒数
     * @param {'TIME' | 'SPOT'} [buyingKind] CM種別
     * @param {Array<number>} [cmSponsorIds] CM出稿企業IDリスト
     * @param {Array<number>} [cmProductIds] CM商品IDリスト
     * @param {Array<number>} [programSeriesIds] 番組シリーズIDリスト
     * @param {string} [programName] 番組名
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchProgramsSearch(stations: Array<string>, startDate: string, endDate: string, startTime: string, endTime: string, companyId: number, cmDuration?: number, buyingKind?: 'TIME' | 'SPOT', cmSponsorIds?: Array<number>, cmProductIds?: Array<number>, programSeriesIds?: Array<number>, programName?: string, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchProgramsSearch(stations, startDate, endDate, startTime, endTime, companyId, cmDuration, buyingKind, cmSponsorIds, cmProductIds, programSeriesIds, programName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチ用の各週有効サンプルサイズを取得する
     * @summary TVデータサーチの各週有効サンプルサイズ取得
     * @param {string} areaCode エリアコード
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {number} companyId カンパニーID
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchSampleSize(areaCode: string, useCustomTarget: boolean, companyId: number, startDate: string, endDate: string, targetIds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchSampleSize(areaCode, useCustomTarget, companyId, startDate, endDate, targetIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TVデータサーチ用の期間内有効サンプルサイズを取得する
     * @summary TVデータサーチの期間内有効サンプルサイズ取得
     * @param {string} areaCode エリアコード
     * @param {boolean} useCustomTarget カスタムターゲットの使用
     * @param {number} companyId カンパニーID
     * @param {string} startDate 検索開始日
     * @param {string} endDate 検索終了日
     * @param {Array<number>} [targetIds] ターゲットID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchSampleSizeIntersection(areaCode: string, useCustomTarget: boolean, companyId: number, startDate: string, endDate: string, targetIds?: Array<number>, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchSampleSizeIntersection(areaCode, useCustomTarget, companyId, startDate, endDate, targetIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア別の東阪名における放送局名もしくはネットワーク系列名を取得する
     * @summary エリア別の放送局取得
     * @param {string} areaCode エリアコード
     * @param {number} companyId カンパニーID
     * @param {boolean} containsCmBroadcast CM出稿を含むフラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvDataSearchApi
     */
    public getTvdataSearchStations(areaCode: string, companyId: number, containsCmBroadcast: boolean, options?: any) {
        return TvDataSearchApiFp(this.configuration).getTvdataSearchStations(areaCode, companyId, containsCmBroadcast, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserinfoApi - axios parameter creator
 * @export
 */
export const UserinfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 現在ログイン中のユーザー情報を取得する
         * @summary ログインユーザー情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserinfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/userinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログインユーザー利用可能カンパニー取得
         * @summary ログインユーザー利用可能カンパニー取得
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserinfoJoinableCompanies: async (limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/userinfo/joinable-companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * マイページ：アカウント情報を変更する。
         * @summary アカウント設定
         * @param {ChangeUserAccountForm} [changeUserAccountForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserinfoAccount: async (changeUserAccountForm?: ChangeUserAccountForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/userinfo/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserAccountForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * マイページ：プロフィールを編集する。
         * @summary プロフィール編集
         * @param {ChangeUserProfileForm} [changeUserProfileForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserinfoProfile: async (changeUserProfileForm?: ChangeUserProfileForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/userinfo/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeUserProfileForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserinfoApi - functional programming interface
 * @export
 */
export const UserinfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserinfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 現在ログイン中のユーザー情報を取得する
         * @summary ログインユーザー情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserinfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserinfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ログインユーザー利用可能カンパニー取得
         * @summary ログインユーザー利用可能カンパニー取得
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserinfoJoinableCompanies(limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JoinableCompanyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserinfoJoinableCompanies(limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * マイページ：アカウント情報を変更する。
         * @summary アカウント設定
         * @param {ChangeUserAccountForm} [changeUserAccountForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserinfoAccount(changeUserAccountForm?: ChangeUserAccountForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserinfoAccount(changeUserAccountForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * マイページ：プロフィールを編集する。
         * @summary プロフィール編集
         * @param {ChangeUserProfileForm} [changeUserProfileForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserinfoProfile(changeUserProfileForm?: ChangeUserProfileForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserinfoProfile(changeUserProfileForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserinfoApi - factory interface
 * @export
 */
export const UserinfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserinfoApiFp(configuration)
    return {
        /**
         * 現在ログイン中のユーザー情報を取得する
         * @summary ログインユーザー情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserinfo(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserinfo(options).then((request) => request(axios, basePath));
        },
        /**
         * ログインユーザー利用可能カンパニー取得
         * @summary ログインユーザー利用可能カンパニー取得
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserinfoJoinableCompanies(limit?: number, offset?: number, options?: any): AxiosPromise<JoinableCompanyList> {
            return localVarFp.getUserinfoJoinableCompanies(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * マイページ：アカウント情報を変更する。
         * @summary アカウント設定
         * @param {ChangeUserAccountForm} [changeUserAccountForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserinfoAccount(changeUserAccountForm?: ChangeUserAccountForm, options?: any): AxiosPromise<GeneralResult> {
            return localVarFp.putUserinfoAccount(changeUserAccountForm, options).then((request) => request(axios, basePath));
        },
        /**
         * マイページ：プロフィールを編集する。
         * @summary プロフィール編集
         * @param {ChangeUserProfileForm} [changeUserProfileForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserinfoProfile(changeUserProfileForm?: ChangeUserProfileForm, options?: any): AxiosPromise<GeneralResult> {
            return localVarFp.putUserinfoProfile(changeUserProfileForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserinfoApi - object-oriented interface
 * @export
 * @class UserinfoApi
 * @extends {BaseAPI}
 */
export class UserinfoApi extends BaseAPI {
    /**
     * 現在ログイン中のユーザー情報を取得する
     * @summary ログインユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserinfoApi
     */
    public getUserinfo(options?: any) {
        return UserinfoApiFp(this.configuration).getUserinfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログインユーザー利用可能カンパニー取得
     * @summary ログインユーザー利用可能カンパニー取得
     * @param {number} [limit] 取得開始位置
     * @param {number} [offset] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserinfoApi
     */
    public getUserinfoJoinableCompanies(limit?: number, offset?: number, options?: any) {
        return UserinfoApiFp(this.configuration).getUserinfoJoinableCompanies(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * マイページ：アカウント情報を変更する。
     * @summary アカウント設定
     * @param {ChangeUserAccountForm} [changeUserAccountForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserinfoApi
     */
    public putUserinfoAccount(changeUserAccountForm?: ChangeUserAccountForm, options?: any) {
        return UserinfoApiFp(this.configuration).putUserinfoAccount(changeUserAccountForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * マイページ：プロフィールを編集する。
     * @summary プロフィール編集
     * @param {ChangeUserProfileForm} [changeUserProfileForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserinfoApi
     */
    public putUserinfoProfile(changeUserProfileForm?: ChangeUserProfileForm, options?: any) {
        return UserinfoApiFp(this.configuration).putUserinfoProfile(changeUserProfileForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkspaceApi - axios parameter creator
 * @export
 */
export const WorkspaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 該当ワークスペースより指定の許可ドメインを削除する
         * @summary 許可ドメイン削除
         * @param {number} workspaceDomainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspaceDomainsWorkspaceDomainIdDelete: async (workspaceDomainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceDomainId' is not null or undefined
            assertParamExists('deleteWorkspaceDomainsWorkspaceDomainIdDelete', 'workspaceDomainId', workspaceDomainId)
            const localVarPath = `/workspace-domains/{workspaceDomainId}/delete`
                .replace(`{${"workspaceDomainId"}}`, encodeURIComponent(String(workspaceDomainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースに所属するアカウントを削除する（ワークスペースへの紐付けを削除する）
         * @summary ワークスペース別アカウント削除
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspacesWorkspaceIdAccountsAccountId: async (workspaceId: number, accountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('deleteWorkspacesWorkspaceIdAccountsAccountId', 'workspaceId', workspaceId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('deleteWorkspacesWorkspaceIdAccountsAccountId', 'accountId', accountId)
            const localVarPath = `/workspaces/{workspaceId}/accounts/{accountId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースの開設申請の状況を取得する
         * @summary ワークスペース開設申請状況取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceOpeningApplicationsStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspace-opening-applications/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースの詳細情報を取得する
         * @summary ワークスペース情報取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceId: async (workspaceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceId', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 該当ワークスペース上のアカウント情報を取得
         * @summary ワークスペース別アカウント詳細取得
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdAccountsAccountId: async (workspaceId: number, accountId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdAccountsAccountId', 'workspaceId', workspaceId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdAccountsAccountId', 'accountId', accountId)
            const localVarPath = `/workspaces/{workspaceId}/accounts/{accountId}`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースに所属するアカウントを検索する
         * @summary ワークスペース別アカウント検索
         * @param {number} workspaceId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード（メールアドレス、表示名、氏名）
         * @param {boolean} [privilegePrimaryOwner] プライマリーオーナー権限
         * @param {boolean} [privilegeOwner] オーナー権限
         * @param {boolean} [privilegeManager] 管理者権限
         * @param {boolean} [privilegeMember] メンバー権限
         * @param {boolean} [privilegeRestricted] 制限付き権限
         * @param {boolean} [privilegeFreePrimaryOwner] 無料プライマリーオーナー権限
         * @param {boolean} [privilegeFreeMember] 無料メンバー権限
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdAccountsSearch: async (workspaceId: number, offset?: number, limit?: number, keyword?: string, privilegePrimaryOwner?: boolean, privilegeOwner?: boolean, privilegeManager?: boolean, privilegeMember?: boolean, privilegeRestricted?: boolean, privilegeFreePrimaryOwner?: boolean, privilegeFreeMember?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdAccountsSearch', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/accounts/search`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (privilegePrimaryOwner !== undefined) {
                localVarQueryParameter['privilegePrimaryOwner'] = privilegePrimaryOwner;
            }

            if (privilegeOwner !== undefined) {
                localVarQueryParameter['privilegeOwner'] = privilegeOwner;
            }

            if (privilegeManager !== undefined) {
                localVarQueryParameter['privilegeManager'] = privilegeManager;
            }

            if (privilegeMember !== undefined) {
                localVarQueryParameter['privilegeMember'] = privilegeMember;
            }

            if (privilegeRestricted !== undefined) {
                localVarQueryParameter['privilegeRestricted'] = privilegeRestricted;
            }

            if (privilegeFreePrimaryOwner !== undefined) {
                localVarQueryParameter['privilegeFreePrimaryOwner'] = privilegeFreePrimaryOwner;
            }

            if (privilegeFreeMember !== undefined) {
                localVarQueryParameter['privilegeFreeMember'] = privilegeFreeMember;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースの基本情報を取得する
         * @summary ワークスペース基本情報取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdBasicInfo: async (workspaceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdBasicInfo', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/basic-info`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースのカンパニー一覧
         * @summary ワークスペース別カンパニー一覧
         * @param {number} workspaceId 
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdCompanies: async (workspaceId: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdCompanies', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/companies`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースに設定したロゴ画像を取得する
         * @summary ワークスペースロゴ画像取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdLogoImage: async (workspaceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdLogoImage', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/logo-image`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースの利用状況を取得する
         * @summary ワークスペース利用状況取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdUsageSituation: async (workspaceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdUsageSituation', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/usage-situation`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 該当ワークスペースに許可されているドメインの一覧を取得する
         * @summary 許可ドメイン一覧
         * @param {number} workspaceId 
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdWorkspaceDomains: async (workspaceId: number, limit?: number, offset?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('getWorkspacesWorkspaceIdWorkspaceDomains', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/workspace-domains`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペース開設申請の承認時などにメール通知を行うアカウントを登録する
         * @summary ワークスペース開設申請通知アカウント登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspaceOpeningApplicationFollowers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspace-opening-application-followers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースの開設申請内容を登録する
         * @summary ワークスペース開設申請登録
         * @param {WorkspaceOpeningApplicationForm} [workspaceOpeningApplicationForm] ワークスペース開設申請のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspaceOpeningApplications: async (workspaceOpeningApplicationForm?: WorkspaceOpeningApplicationForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/workspace-opening-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workspaceOpeningApplicationForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cmカンパニーと連携し、ワークスペースのカンパニー作成する
         * @summary カンパニー作成
         * @param {number} workspaceId 
         * @param {CreateCompanyForm} [createCompanyForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspacesWorkspaceIdCompanies: async (workspaceId: number, createCompanyForm?: CreateCompanyForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postWorkspacesWorkspaceIdCompanies', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/companies`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 契約ドメインの変更申請を出す
         * @summary 契約ドメイン変更申請
         * @param {number} workspaceId 
         * @param {CreateWorkspaceDomainChangeApplicationForm} [createWorkspaceDomainChangeApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspacesWorkspaceIdContractDomainApplications: async (workspaceId: number, createWorkspaceDomainChangeApplicationForm?: CreateWorkspaceDomainChangeApplicationForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postWorkspacesWorkspaceIdContractDomainApplications', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/contract-domain/applications`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkspaceDomainChangeApplicationForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 該当のワークスペースに許可ドメインを追加する
         * @summary 許可ドメイン追加
         * @param {number} workspaceId 
         * @param {CreateWorkspaceDomainForm} [createWorkspaceDomainForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspacesWorkspaceIdWorkspaceDomains: async (workspaceId: number, createWorkspaceDomainForm?: CreateWorkspaceDomainForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('postWorkspacesWorkspaceIdWorkspaceDomains', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/workspace-domains`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWorkspaceDomainForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 該当ワークスペースの許可ドメインの各種情報を変更する
         * @summary 許可ドメイン変更
         * @param {number} workspaceDomainId 
         * @param {ChangeWorkspaceDomainForm} [changeWorkspaceDomainForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkspaceDomainsWorkspaceDomainIdEdit: async (workspaceDomainId: number, changeWorkspaceDomainForm?: ChangeWorkspaceDomainForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceDomainId' is not null or undefined
            assertParamExists('putWorkspaceDomainsWorkspaceDomainIdEdit', 'workspaceDomainId', workspaceDomainId)
            const localVarPath = `/workspace-domains/{workspaceDomainId}/edit`
                .replace(`{${"workspaceDomainId"}}`, encodeURIComponent(String(workspaceDomainId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeWorkspaceDomainForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースに所属するアカウントの情報を部分的に更新する
         * @summary ワークスペース別アカウント部分更新
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {ChangeAccountPrivilegeForm} [changeAccountPrivilegeForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkspacesWorkspaceIdAccountsAccountIdEdit: async (workspaceId: number, accountId: number, changeAccountPrivilegeForm?: ChangeAccountPrivilegeForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putWorkspacesWorkspaceIdAccountsAccountIdEdit', 'workspaceId', workspaceId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('putWorkspacesWorkspaceIdAccountsAccountIdEdit', 'accountId', accountId)
            const localVarPath = `/workspaces/{workspaceId}/accounts/{accountId}/edit`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeAccountPrivilegeForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースの各種情報を変更する
         * @summary ワークスペース設定変更
         * @param {number} workspaceId 
         * @param {ChangeWorkspaceForm} [changeWorkspaceForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkspacesWorkspaceIdEdit: async (workspaceId: number, changeWorkspaceForm?: ChangeWorkspaceForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putWorkspacesWorkspaceIdEdit', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/edit`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeWorkspaceForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ワークスペースのロゴ画像を設定する
         * @summary ワークスペースロゴ画像設定
         * @param {number} workspaceId 
         * @param {object} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkspacesWorkspaceIdLogoImage: async (workspaceId: number, file?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'workspaceId' is not null or undefined
            assertParamExists('putWorkspacesWorkspaceIdLogoImage', 'workspaceId', workspaceId)
            const localVarPath = `/workspaces/{workspaceId}/logo-image`
                .replace(`{${"workspaceId"}}`, encodeURIComponent(String(workspaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkspaceApi - functional programming interface
 * @export
 */
export const WorkspaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkspaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 該当ワークスペースより指定の許可ドメインを削除する
         * @summary 許可ドメイン削除
         * @param {number} workspaceDomainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkspaceDomainsWorkspaceDomainIdDelete(workspaceDomainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkspaceDomainsWorkspaceDomainIdDelete(workspaceDomainId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースに所属するアカウントを削除する（ワークスペースへの紐付けを削除する）
         * @summary ワークスペース別アカウント削除
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWorkspacesWorkspaceIdAccountsAccountId(workspaceId: number, accountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWorkspacesWorkspaceIdAccountsAccountId(workspaceId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースの開設申請の状況を取得する
         * @summary ワークスペース開設申請状況取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspaceOpeningApplicationsStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceOpeningApplicationStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspaceOpeningApplicationsStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースの詳細情報を取得する
         * @summary ワークスペース情報取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceId(workspaceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Workspace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceId(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 該当ワークスペース上のアカウント情報を取得
         * @summary ワークスペース別アカウント詳細取得
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceIdAccountsAccountId(workspaceId: number, accountId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceAccountProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceIdAccountsAccountId(workspaceId, accountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースに所属するアカウントを検索する
         * @summary ワークスペース別アカウント検索
         * @param {number} workspaceId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード（メールアドレス、表示名、氏名）
         * @param {boolean} [privilegePrimaryOwner] プライマリーオーナー権限
         * @param {boolean} [privilegeOwner] オーナー権限
         * @param {boolean} [privilegeManager] 管理者権限
         * @param {boolean} [privilegeMember] メンバー権限
         * @param {boolean} [privilegeRestricted] 制限付き権限
         * @param {boolean} [privilegeFreePrimaryOwner] 無料プライマリーオーナー権限
         * @param {boolean} [privilegeFreeMember] 無料メンバー権限
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceIdAccountsSearch(workspaceId: number, offset?: number, limit?: number, keyword?: string, privilegePrimaryOwner?: boolean, privilegeOwner?: boolean, privilegeManager?: boolean, privilegeMember?: boolean, privilegeRestricted?: boolean, privilegeFreePrimaryOwner?: boolean, privilegeFreeMember?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceIdAccountsSearch(workspaceId, offset, limit, keyword, privilegePrimaryOwner, privilegeOwner, privilegeManager, privilegeMember, privilegeRestricted, privilegeFreePrimaryOwner, privilegeFreeMember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースの基本情報を取得する
         * @summary ワークスペース基本情報取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceIdBasicInfo(workspaceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceBasic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceIdBasicInfo(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースのカンパニー一覧
         * @summary ワークスペース別カンパニー一覧
         * @param {number} workspaceId 
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceIdCompanies(workspaceId: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceIdCompanies(workspaceId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースに設定したロゴ画像を取得する
         * @summary ワークスペースロゴ画像取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceIdLogoImage(workspaceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceIdLogoImage(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースの利用状況を取得する
         * @summary ワークスペース利用状況取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceIdUsageSituation(workspaceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceUsageSituation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceIdUsageSituation(workspaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 該当ワークスペースに許可されているドメインの一覧を取得する
         * @summary 許可ドメイン一覧
         * @param {number} workspaceId 
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkspacesWorkspaceIdWorkspaceDomains(workspaceId: number, limit?: number, offset?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDomainList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkspacesWorkspaceIdWorkspaceDomains(workspaceId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペース開設申請の承認時などにメール通知を行うアカウントを登録する
         * @summary ワークスペース開設申請通知アカウント登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWorkspaceOpeningApplicationFollowers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceOpeningApplicationFollower>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWorkspaceOpeningApplicationFollowers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースの開設申請内容を登録する
         * @summary ワークスペース開設申請登録
         * @param {WorkspaceOpeningApplicationForm} [workspaceOpeningApplicationForm] ワークスペース開設申請のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWorkspaceOpeningApplications(workspaceOpeningApplicationForm?: WorkspaceOpeningApplicationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceOpeningApplicationForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWorkspaceOpeningApplications(workspaceOpeningApplicationForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cmカンパニーと連携し、ワークスペースのカンパニー作成する
         * @summary カンパニー作成
         * @param {number} workspaceId 
         * @param {CreateCompanyForm} [createCompanyForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWorkspacesWorkspaceIdCompanies(workspaceId: number, createCompanyForm?: CreateCompanyForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWorkspacesWorkspaceIdCompanies(workspaceId, createCompanyForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 契約ドメインの変更申請を出す
         * @summary 契約ドメイン変更申請
         * @param {number} workspaceId 
         * @param {CreateWorkspaceDomainChangeApplicationForm} [createWorkspaceDomainChangeApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWorkspacesWorkspaceIdContractDomainApplications(workspaceId: number, createWorkspaceDomainChangeApplicationForm?: CreateWorkspaceDomainChangeApplicationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDomainChangeApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWorkspacesWorkspaceIdContractDomainApplications(workspaceId, createWorkspaceDomainChangeApplicationForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 該当のワークスペースに許可ドメインを追加する
         * @summary 許可ドメイン追加
         * @param {number} workspaceId 
         * @param {CreateWorkspaceDomainForm} [createWorkspaceDomainForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWorkspacesWorkspaceIdWorkspaceDomains(workspaceId: number, createWorkspaceDomainForm?: CreateWorkspaceDomainForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceDomain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWorkspacesWorkspaceIdWorkspaceDomains(workspaceId, createWorkspaceDomainForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 該当ワークスペースの許可ドメインの各種情報を変更する
         * @summary 許可ドメイン変更
         * @param {number} workspaceDomainId 
         * @param {ChangeWorkspaceDomainForm} [changeWorkspaceDomainForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWorkspaceDomainsWorkspaceDomainIdEdit(workspaceDomainId: number, changeWorkspaceDomainForm?: ChangeWorkspaceDomainForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeWorkspaceDomain>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWorkspaceDomainsWorkspaceDomainIdEdit(workspaceDomainId, changeWorkspaceDomainForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースに所属するアカウントの情報を部分的に更新する
         * @summary ワークスペース別アカウント部分更新
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {ChangeAccountPrivilegeForm} [changeAccountPrivilegeForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWorkspacesWorkspaceIdAccountsAccountIdEdit(workspaceId: number, accountId: number, changeAccountPrivilegeForm?: ChangeAccountPrivilegeForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountPrivilege>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWorkspacesWorkspaceIdAccountsAccountIdEdit(workspaceId, accountId, changeAccountPrivilegeForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースの各種情報を変更する
         * @summary ワークスペース設定変更
         * @param {number} workspaceId 
         * @param {ChangeWorkspaceForm} [changeWorkspaceForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWorkspacesWorkspaceIdEdit(workspaceId: number, changeWorkspaceForm?: ChangeWorkspaceForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkspaceName>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWorkspacesWorkspaceIdEdit(workspaceId, changeWorkspaceForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ワークスペースのロゴ画像を設定する
         * @summary ワークスペースロゴ画像設定
         * @param {number} workspaceId 
         * @param {object} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putWorkspacesWorkspaceIdLogoImage(workspaceId: number, file?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putWorkspacesWorkspaceIdLogoImage(workspaceId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkspaceApi - factory interface
 * @export
 */
export const WorkspaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkspaceApiFp(configuration)
    return {
        /**
         * 該当ワークスペースより指定の許可ドメインを削除する
         * @summary 許可ドメイン削除
         * @param {number} workspaceDomainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspaceDomainsWorkspaceDomainIdDelete(workspaceDomainId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkspaceDomainsWorkspaceDomainIdDelete(workspaceDomainId, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースに所属するアカウントを削除する（ワークスペースへの紐付けを削除する）
         * @summary ワークスペース別アカウント削除
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWorkspacesWorkspaceIdAccountsAccountId(workspaceId: number, accountId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWorkspacesWorkspaceIdAccountsAccountId(workspaceId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースの開設申請の状況を取得する
         * @summary ワークスペース開設申請状況取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspaceOpeningApplicationsStatus(options?: any): AxiosPromise<WorkspaceOpeningApplicationStatus> {
            return localVarFp.getWorkspaceOpeningApplicationsStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースの詳細情報を取得する
         * @summary ワークスペース情報取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceId(workspaceId: number, options?: any): AxiosPromise<Workspace> {
            return localVarFp.getWorkspacesWorkspaceId(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 該当ワークスペース上のアカウント情報を取得
         * @summary ワークスペース別アカウント詳細取得
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdAccountsAccountId(workspaceId: number, accountId: number, options?: any): AxiosPromise<WorkspaceAccountProfile> {
            return localVarFp.getWorkspacesWorkspaceIdAccountsAccountId(workspaceId, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースに所属するアカウントを検索する
         * @summary ワークスペース別アカウント検索
         * @param {number} workspaceId 
         * @param {number} [offset] 取得開始位置
         * @param {number} [limit] 取得件数
         * @param {string} [keyword] 検索キーワード（メールアドレス、表示名、氏名）
         * @param {boolean} [privilegePrimaryOwner] プライマリーオーナー権限
         * @param {boolean} [privilegeOwner] オーナー権限
         * @param {boolean} [privilegeManager] 管理者権限
         * @param {boolean} [privilegeMember] メンバー権限
         * @param {boolean} [privilegeRestricted] 制限付き権限
         * @param {boolean} [privilegeFreePrimaryOwner] 無料プライマリーオーナー権限
         * @param {boolean} [privilegeFreeMember] 無料メンバー権限
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdAccountsSearch(workspaceId: number, offset?: number, limit?: number, keyword?: string, privilegePrimaryOwner?: boolean, privilegeOwner?: boolean, privilegeManager?: boolean, privilegeMember?: boolean, privilegeRestricted?: boolean, privilegeFreePrimaryOwner?: boolean, privilegeFreeMember?: boolean, options?: any): AxiosPromise<AccountList> {
            return localVarFp.getWorkspacesWorkspaceIdAccountsSearch(workspaceId, offset, limit, keyword, privilegePrimaryOwner, privilegeOwner, privilegeManager, privilegeMember, privilegeRestricted, privilegeFreePrimaryOwner, privilegeFreeMember, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースの基本情報を取得する
         * @summary ワークスペース基本情報取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdBasicInfo(workspaceId: number, options?: any): AxiosPromise<WorkspaceBasic> {
            return localVarFp.getWorkspacesWorkspaceIdBasicInfo(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースのカンパニー一覧
         * @summary ワークスペース別カンパニー一覧
         * @param {number} workspaceId 
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdCompanies(workspaceId: number, limit?: number, offset?: number, options?: any): AxiosPromise<CompanyList> {
            return localVarFp.getWorkspacesWorkspaceIdCompanies(workspaceId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースに設定したロゴ画像を取得する
         * @summary ワークスペースロゴ画像取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdLogoImage(workspaceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getWorkspacesWorkspaceIdLogoImage(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースの利用状況を取得する
         * @summary ワークスペース利用状況取得
         * @param {number} workspaceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdUsageSituation(workspaceId: number, options?: any): AxiosPromise<WorkspaceUsageSituation> {
            return localVarFp.getWorkspacesWorkspaceIdUsageSituation(workspaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 該当ワークスペースに許可されているドメインの一覧を取得する
         * @summary 許可ドメイン一覧
         * @param {number} workspaceId 
         * @param {number} [limit] 取得開始位置
         * @param {number} [offset] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkspacesWorkspaceIdWorkspaceDomains(workspaceId: number, limit?: number, offset?: number, options?: any): AxiosPromise<WorkspaceDomainList> {
            return localVarFp.getWorkspacesWorkspaceIdWorkspaceDomains(workspaceId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペース開設申請の承認時などにメール通知を行うアカウントを登録する
         * @summary ワークスペース開設申請通知アカウント登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspaceOpeningApplicationFollowers(options?: any): AxiosPromise<WorkspaceOpeningApplicationFollower> {
            return localVarFp.postWorkspaceOpeningApplicationFollowers(options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースの開設申請内容を登録する
         * @summary ワークスペース開設申請登録
         * @param {WorkspaceOpeningApplicationForm} [workspaceOpeningApplicationForm] ワークスペース開設申請のForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspaceOpeningApplications(workspaceOpeningApplicationForm?: WorkspaceOpeningApplicationForm, options?: any): AxiosPromise<WorkspaceOpeningApplicationForm> {
            return localVarFp.postWorkspaceOpeningApplications(workspaceOpeningApplicationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * Cmカンパニーと連携し、ワークスペースのカンパニー作成する
         * @summary カンパニー作成
         * @param {number} workspaceId 
         * @param {CreateCompanyForm} [createCompanyForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspacesWorkspaceIdCompanies(workspaceId: number, createCompanyForm?: CreateCompanyForm, options?: any): AxiosPromise<Company> {
            return localVarFp.postWorkspacesWorkspaceIdCompanies(workspaceId, createCompanyForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 契約ドメインの変更申請を出す
         * @summary 契約ドメイン変更申請
         * @param {number} workspaceId 
         * @param {CreateWorkspaceDomainChangeApplicationForm} [createWorkspaceDomainChangeApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspacesWorkspaceIdContractDomainApplications(workspaceId: number, createWorkspaceDomainChangeApplicationForm?: CreateWorkspaceDomainChangeApplicationForm, options?: any): AxiosPromise<WorkspaceDomainChangeApplication> {
            return localVarFp.postWorkspacesWorkspaceIdContractDomainApplications(workspaceId, createWorkspaceDomainChangeApplicationForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 該当のワークスペースに許可ドメインを追加する
         * @summary 許可ドメイン追加
         * @param {number} workspaceId 
         * @param {CreateWorkspaceDomainForm} [createWorkspaceDomainForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWorkspacesWorkspaceIdWorkspaceDomains(workspaceId: number, createWorkspaceDomainForm?: CreateWorkspaceDomainForm, options?: any): AxiosPromise<WorkspaceDomain> {
            return localVarFp.postWorkspacesWorkspaceIdWorkspaceDomains(workspaceId, createWorkspaceDomainForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 該当ワークスペースの許可ドメインの各種情報を変更する
         * @summary 許可ドメイン変更
         * @param {number} workspaceDomainId 
         * @param {ChangeWorkspaceDomainForm} [changeWorkspaceDomainForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkspaceDomainsWorkspaceDomainIdEdit(workspaceDomainId: number, changeWorkspaceDomainForm?: ChangeWorkspaceDomainForm, options?: any): AxiosPromise<ChangeWorkspaceDomain> {
            return localVarFp.putWorkspaceDomainsWorkspaceDomainIdEdit(workspaceDomainId, changeWorkspaceDomainForm, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースに所属するアカウントの情報を部分的に更新する
         * @summary ワークスペース別アカウント部分更新
         * @param {number} workspaceId 
         * @param {number} accountId 
         * @param {ChangeAccountPrivilegeForm} [changeAccountPrivilegeForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkspacesWorkspaceIdAccountsAccountIdEdit(workspaceId: number, accountId: number, changeAccountPrivilegeForm?: ChangeAccountPrivilegeForm, options?: any): AxiosPromise<AccountPrivilege> {
            return localVarFp.putWorkspacesWorkspaceIdAccountsAccountIdEdit(workspaceId, accountId, changeAccountPrivilegeForm, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースの各種情報を変更する
         * @summary ワークスペース設定変更
         * @param {number} workspaceId 
         * @param {ChangeWorkspaceForm} [changeWorkspaceForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkspacesWorkspaceIdEdit(workspaceId: number, changeWorkspaceForm?: ChangeWorkspaceForm, options?: any): AxiosPromise<WorkspaceName> {
            return localVarFp.putWorkspacesWorkspaceIdEdit(workspaceId, changeWorkspaceForm, options).then((request) => request(axios, basePath));
        },
        /**
         * ワークスペースのロゴ画像を設定する
         * @summary ワークスペースロゴ画像設定
         * @param {number} workspaceId 
         * @param {object} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putWorkspacesWorkspaceIdLogoImage(workspaceId: number, file?: object, options?: any): AxiosPromise<void> {
            return localVarFp.putWorkspacesWorkspaceIdLogoImage(workspaceId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkspaceApi - object-oriented interface
 * @export
 * @class WorkspaceApi
 * @extends {BaseAPI}
 */
export class WorkspaceApi extends BaseAPI {
    /**
     * 該当ワークスペースより指定の許可ドメインを削除する
     * @summary 許可ドメイン削除
     * @param {number} workspaceDomainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public deleteWorkspaceDomainsWorkspaceDomainIdDelete(workspaceDomainId: number, options?: any) {
        return WorkspaceApiFp(this.configuration).deleteWorkspaceDomainsWorkspaceDomainIdDelete(workspaceDomainId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースに所属するアカウントを削除する（ワークスペースへの紐付けを削除する）
     * @summary ワークスペース別アカウント削除
     * @param {number} workspaceId 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public deleteWorkspacesWorkspaceIdAccountsAccountId(workspaceId: number, accountId: number, options?: any) {
        return WorkspaceApiFp(this.configuration).deleteWorkspacesWorkspaceIdAccountsAccountId(workspaceId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースの開設申請の状況を取得する
     * @summary ワークスペース開設申請状況取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspaceOpeningApplicationsStatus(options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspaceOpeningApplicationsStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースの詳細情報を取得する
     * @summary ワークスペース情報取得
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspacesWorkspaceId(workspaceId: number, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspacesWorkspaceId(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 該当ワークスペース上のアカウント情報を取得
     * @summary ワークスペース別アカウント詳細取得
     * @param {number} workspaceId 
     * @param {number} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspacesWorkspaceIdAccountsAccountId(workspaceId: number, accountId: number, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspacesWorkspaceIdAccountsAccountId(workspaceId, accountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースに所属するアカウントを検索する
     * @summary ワークスペース別アカウント検索
     * @param {number} workspaceId 
     * @param {number} [offset] 取得開始位置
     * @param {number} [limit] 取得件数
     * @param {string} [keyword] 検索キーワード（メールアドレス、表示名、氏名）
     * @param {boolean} [privilegePrimaryOwner] プライマリーオーナー権限
     * @param {boolean} [privilegeOwner] オーナー権限
     * @param {boolean} [privilegeManager] 管理者権限
     * @param {boolean} [privilegeMember] メンバー権限
     * @param {boolean} [privilegeRestricted] 制限付き権限
     * @param {boolean} [privilegeFreePrimaryOwner] 無料プライマリーオーナー権限
     * @param {boolean} [privilegeFreeMember] 無料メンバー権限
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspacesWorkspaceIdAccountsSearch(workspaceId: number, offset?: number, limit?: number, keyword?: string, privilegePrimaryOwner?: boolean, privilegeOwner?: boolean, privilegeManager?: boolean, privilegeMember?: boolean, privilegeRestricted?: boolean, privilegeFreePrimaryOwner?: boolean, privilegeFreeMember?: boolean, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspacesWorkspaceIdAccountsSearch(workspaceId, offset, limit, keyword, privilegePrimaryOwner, privilegeOwner, privilegeManager, privilegeMember, privilegeRestricted, privilegeFreePrimaryOwner, privilegeFreeMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースの基本情報を取得する
     * @summary ワークスペース基本情報取得
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspacesWorkspaceIdBasicInfo(workspaceId: number, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspacesWorkspaceIdBasicInfo(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースのカンパニー一覧
     * @summary ワークスペース別カンパニー一覧
     * @param {number} workspaceId 
     * @param {number} [limit] 取得開始位置
     * @param {number} [offset] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspacesWorkspaceIdCompanies(workspaceId: number, limit?: number, offset?: number, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspacesWorkspaceIdCompanies(workspaceId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースに設定したロゴ画像を取得する
     * @summary ワークスペースロゴ画像取得
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspacesWorkspaceIdLogoImage(workspaceId: number, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspacesWorkspaceIdLogoImage(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースの利用状況を取得する
     * @summary ワークスペース利用状況取得
     * @param {number} workspaceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspacesWorkspaceIdUsageSituation(workspaceId: number, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspacesWorkspaceIdUsageSituation(workspaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 該当ワークスペースに許可されているドメインの一覧を取得する
     * @summary 許可ドメイン一覧
     * @param {number} workspaceId 
     * @param {number} [limit] 取得開始位置
     * @param {number} [offset] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public getWorkspacesWorkspaceIdWorkspaceDomains(workspaceId: number, limit?: number, offset?: number, options?: any) {
        return WorkspaceApiFp(this.configuration).getWorkspacesWorkspaceIdWorkspaceDomains(workspaceId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペース開設申請の承認時などにメール通知を行うアカウントを登録する
     * @summary ワークスペース開設申請通知アカウント登録
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public postWorkspaceOpeningApplicationFollowers(options?: any) {
        return WorkspaceApiFp(this.configuration).postWorkspaceOpeningApplicationFollowers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースの開設申請内容を登録する
     * @summary ワークスペース開設申請登録
     * @param {WorkspaceOpeningApplicationForm} [workspaceOpeningApplicationForm] ワークスペース開設申請のForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public postWorkspaceOpeningApplications(workspaceOpeningApplicationForm?: WorkspaceOpeningApplicationForm, options?: any) {
        return WorkspaceApiFp(this.configuration).postWorkspaceOpeningApplications(workspaceOpeningApplicationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cmカンパニーと連携し、ワークスペースのカンパニー作成する
     * @summary カンパニー作成
     * @param {number} workspaceId 
     * @param {CreateCompanyForm} [createCompanyForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public postWorkspacesWorkspaceIdCompanies(workspaceId: number, createCompanyForm?: CreateCompanyForm, options?: any) {
        return WorkspaceApiFp(this.configuration).postWorkspacesWorkspaceIdCompanies(workspaceId, createCompanyForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 契約ドメインの変更申請を出す
     * @summary 契約ドメイン変更申請
     * @param {number} workspaceId 
     * @param {CreateWorkspaceDomainChangeApplicationForm} [createWorkspaceDomainChangeApplicationForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public postWorkspacesWorkspaceIdContractDomainApplications(workspaceId: number, createWorkspaceDomainChangeApplicationForm?: CreateWorkspaceDomainChangeApplicationForm, options?: any) {
        return WorkspaceApiFp(this.configuration).postWorkspacesWorkspaceIdContractDomainApplications(workspaceId, createWorkspaceDomainChangeApplicationForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 該当のワークスペースに許可ドメインを追加する
     * @summary 許可ドメイン追加
     * @param {number} workspaceId 
     * @param {CreateWorkspaceDomainForm} [createWorkspaceDomainForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public postWorkspacesWorkspaceIdWorkspaceDomains(workspaceId: number, createWorkspaceDomainForm?: CreateWorkspaceDomainForm, options?: any) {
        return WorkspaceApiFp(this.configuration).postWorkspacesWorkspaceIdWorkspaceDomains(workspaceId, createWorkspaceDomainForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 該当ワークスペースの許可ドメインの各種情報を変更する
     * @summary 許可ドメイン変更
     * @param {number} workspaceDomainId 
     * @param {ChangeWorkspaceDomainForm} [changeWorkspaceDomainForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public putWorkspaceDomainsWorkspaceDomainIdEdit(workspaceDomainId: number, changeWorkspaceDomainForm?: ChangeWorkspaceDomainForm, options?: any) {
        return WorkspaceApiFp(this.configuration).putWorkspaceDomainsWorkspaceDomainIdEdit(workspaceDomainId, changeWorkspaceDomainForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースに所属するアカウントの情報を部分的に更新する
     * @summary ワークスペース別アカウント部分更新
     * @param {number} workspaceId 
     * @param {number} accountId 
     * @param {ChangeAccountPrivilegeForm} [changeAccountPrivilegeForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public putWorkspacesWorkspaceIdAccountsAccountIdEdit(workspaceId: number, accountId: number, changeAccountPrivilegeForm?: ChangeAccountPrivilegeForm, options?: any) {
        return WorkspaceApiFp(this.configuration).putWorkspacesWorkspaceIdAccountsAccountIdEdit(workspaceId, accountId, changeAccountPrivilegeForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースの各種情報を変更する
     * @summary ワークスペース設定変更
     * @param {number} workspaceId 
     * @param {ChangeWorkspaceForm} [changeWorkspaceForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public putWorkspacesWorkspaceIdEdit(workspaceId: number, changeWorkspaceForm?: ChangeWorkspaceForm, options?: any) {
        return WorkspaceApiFp(this.configuration).putWorkspacesWorkspaceIdEdit(workspaceId, changeWorkspaceForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ワークスペースのロゴ画像を設定する
     * @summary ワークスペースロゴ画像設定
     * @param {number} workspaceId 
     * @param {object} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkspaceApi
     */
    public putWorkspacesWorkspaceIdLogoImage(workspaceId: number, file?: object, options?: any) {
        return WorkspaceApiFp(this.configuration).putWorkspacesWorkspaceIdLogoImage(workspaceId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


