<script>
import { defineComponent, h } from 'vue';

/**
 * Typography<br />
 * ----------------------<br />
 * ref: https://www.figma.com/file/ShKIFZxFgmOYm6Ef1U6Tk0/V3-DesignSystem?node-id=338%3A9604<br />
 * デザインガイドラインの`Body`と`強調`を管理する
 *
 * props<br />
 * -----------<br />
 * color<br />
 *   default ... --text-color<br />
 *   inherit ... parent color<br />
 *   primary ... --primary-color<br />
 *   secondary ... --secondary-color<br />
 *   error ... --error-color<br />
 *   white ... --contrast-color<br />
 *   grey ... --grey-color<br />
 *   link ... --link-color<br />
 *   premium ... --premium-color<br />
 * bold ...  boolean<br />
 * size ...  'xs'(10px) | 's'(12px) | 'm'(14px) | 'l'(16px) | 'xl'(18px) | 'xxl'(20px) | 'xxxl'(24px) | 'xxxxl'(28px) | 'xxxxxl'(32px) <br />
 * text-align ... 'left' | 'center' | 'right'<br />
 * align-self ... 'center'
 * line-hight ... (固定値許容 ：デザインチームから固定値が入る場合もあると共有あり)
 */

export default defineComponent({
  render: ({
    $slots,
    bold,
    color,
    lineHeight,
    size,
    tag,
    textAlign,
    alignSelf,
    nowrap
  }) => {
    return h(
      tag,
      {
        class: [
          'ui-typography',
          bold ? 'bold' : '',
          color,
          size,
          nowrap ? 'nowrap' : ''
        ].join(' '),
        style: {
          'line-height': lineHeight,
          'text-align': textAlign,
          'align-self': alignSelf
        }
      },
      $slots.default()
    );
  },
  props: {
    tag: {
      type: String,
      default: 'p'
    },
    color: String,
    bold: Boolean,
    textAlign: String,
    alignSelf: String,
    size: String,
    lineHeight: {
      type: String,
      default: '1'
    },
    nowrap: Boolean
  }
});
</script>
<style lang="scss" scoped>
.ui-typography {
  color: var(--text-color);
  font-size: 14px;

  &.xs {
    font-size: 10px;
  }

  &.s {
    font-size: 12px;
  }

  &.m {
    font-size: 14px;
  }

  &.l {
    font-size: 16px;
  }

  &.xl {
    font-size: 18px;
  }

  &.xxl {
    font-size: 20px;
  }

  &.xxxl {
    font-size: 24px;
  }

  &.xxxxl {
    font-size: 28px;
  }

  &.xxxxxl {
    font-size: 32px;
  }

  &.bold {
    font-weight: 700;
  }

  &.inherit {
    color: inherit;
  }

  &.primary {
    color: var(--primary-color);
  }

  &.secondary {
    color: var(--secondary-color);
  }

  &.error {
    color: var(--error-color);
  }

  &.white {
    color: var(--contrast-color);
  }

  &.grey {
    color: var(--grey-text-color);
  }

  &.grey80 {
    color: var(--grey-80-text-color);
  }

  &.grey25 {
    color: var(--grey-25-text-color);
  }

  &.link {
    color: var(--link-text-color);
  }

  &.premium {
    color: var(--premium-color);
  }

  &.disabled {
    color: var(--form-disabled-color);
  }

  &.nowrap {
    white-space: nowrap;
  }
}
</style>
