<template>
  <g clip-path="url(#clip0_1371_79874)">
    <path
      d="M15.1399 7.16671C15.3439 7.16671 15.5 7.01042 15.5 6.80604L15.476 1.51619C15.476 1.32384 15.3559 1.27575 15.2239 1.40799L9.74971 6.91424C9.60565 7.05851 9.65367 7.16671 9.85775 7.16671L15.1399 7.16671Z"
      fill="#6B90DF"
    />
    <path
      d="M7.88419 15.5C10.7323 15.5 13.3589 13.8071 14.572 11.1929L12.6627 10.2821C11.7977 12.1571 9.92006 13.3679 7.88419 13.3679C4.9728 13.3679 2.60993 10.9679 2.60993 8.01071C2.60993 5.05357 4.98335 2.64286 7.88419 2.64286C10.0044 2.64286 11.9137 3.92857 12.7471 5.91071L14.688 5.075C13.5277 2.3 10.8589 0.5 7.88419 0.500001C3.81246 0.500001 0.500225 3.86429 0.500226 8C0.500226 12.1357 3.81246 15.5 7.88419 15.5Z"
      fill="#6B90DF"
    />
  </g>
  <defs>
    <clipPath id="clip0_1371_79874">
      <rect
        width="15"
        height="15"
        fill="white"
        transform="matrix(-1 8.74228e-08 8.74228e-08 1 15.5 0.5)"
      />
    </clipPath>
  </defs>
</template>
