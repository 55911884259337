import { defineComponent } from 'vue';

export const REACH_ANALYSIS_ROUTES = {
  index: 'raIndex',
  list: 'raList',
  create: 'raCreate',
  result: 'raResult'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const MENU_TITLE = 'リーチ分析';
const TITLE = {
  index: MENU_TITLE,
  list: MENU_TITLE,
  create: '新規作成' + ' | ' + MENU_TITLE,
  result: MENU_TITLE
} as const;

const PATH = '/company/:companyId/reach-analysis';

export default [
  {
    path: PATH,
    name: REACH_ANALYSIS_ROUTES.index,
    meta: {
      title: TITLE.index
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/reachanalysis/index.vue'),
    children: [
      {
        path: '',
        name: REACH_ANALYSIS_ROUTES.list,
        meta: {
          children: true,
          title: TITLE.list
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/reachanalysis/ReachAnalysisProjectList.vue')
      },
      {
        path: `create`,
        name: REACH_ANALYSIS_ROUTES.create,
        meta: {
          children: true,
          title: TITLE.create
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/reachanalysis/create.vue')
      },
      {
        path: `${PATH}/:projectId`,
        name: REACH_ANALYSIS_ROUTES.result,
        meta: {
          children: true,
          title: TITLE.result
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/reachanalysis/result.vue')
      }
    ]
  }
];
