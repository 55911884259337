import { Ref, ref } from 'vue';

interface UseToggleMenuReturnType {
  activeMenuId: Ref<string>;
  openMenu: (modalId: string) => void;
  closeMenu: (modalId: string) => void;
}

export const useToggleMenu = (): UseToggleMenuReturnType => {
  const activeMenuId = ref('');
  const openMenu = (menuId: string) => {
    activeMenuId.value = menuId;
  };
  const closeMenu = (menuId: string) => {
    if ((activeMenuId.value = menuId)) {
      activeMenuId.value = '';
    }
  };
  return {
    activeMenuId,
    openMenu,
    closeMenu
  };
};
