export default {
  mounted(el: Element): void {
    const targetNode = el.childNodes[0];
    if (
      typeof targetNode.textContent !== 'string' ||
      targetNode.textContent.match(/^\d*$/) === null
    ) {
      return;
    }
    targetNode.textContent = Number(targetNode.textContent).toLocaleString();
  }
};
