import { defineComponent } from 'vue';

export const COMPANY_ROUTES = {
  index: 'companyIndex',
  top: 'companyHome'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE = {
  index: 'ホーム',
  top: 'ホーム'
};

// FIX topにあるものをindexにしてchildrenでhome画面とかを配置する

export default [
  {
    path: '/company/:companyId',
    meta: {
      title: TITLE.index
    },
    component: (): ReturnType<typeof defineComponent> =>
      // TODO: pages/home/index.vueが空の状態なのでいったんtop.vueへ
      // import('@/pages/home/index.vue'),
      import('@/pages/home/top.vue'),
    children: [
      {
        path: '',
        name: COMPANY_ROUTES.top,
        meta: {
          title: TITLE.top
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/home/top.vue')
      }
    ]
  }
];
