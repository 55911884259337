import { JoinWorkspaceAccountPrivilegeEnum } from '@/api/openapi';
import { PAYMENT_TYPE } from '@/common/constant';

export class AclRule {
  private _current: string;

  constructor(permission: string) {
    this._current = permission;
  }

  or(permission: string): AclRule {
    this._current += this._current === '' ? permission : `||${permission}`;
    return this;
  }

  and(permission: string | AclRule): AclRule {
    if (permission instanceof AclRule) {
      if (permission._current.match('&&'))
        throw Error('cannot include and in brackets');
      this._current +=
        this._current === ''
          ? permission._current
          : `&&(${permission._current})`;
    } else {
      this._current += this._current === '' ? permission : `&&${permission}`;
    }
    return this;
  }

  get current(): string[][] {
    // A and (B or C) => [[A], [B, C]]
    // (A or B) and (C or D)
    // return this._current.split('||').map(_ => _.split('&&'));
    const re = new RegExp('\\((.+)\\)');
    return this._current.split('&&').map(f => {
      const match = f.match(re);
      if (match) {
        return match[1].split('||');
      }
      return f.split('||');
    });
  }
}

export class AclRules {
  private static _primaryOwner = JoinWorkspaceAccountPrivilegeEnum.PrimaryOwner;
  private static _owner = JoinWorkspaceAccountPrivilegeEnum.Owner;
  private static _manager = JoinWorkspaceAccountPrivilegeEnum.Manager;
  private static _member = JoinWorkspaceAccountPrivilegeEnum.Member;
  private static _restricted = JoinWorkspaceAccountPrivilegeEnum.Restricted;
  private static _freePrimaryOwner =
    JoinWorkspaceAccountPrivilegeEnum.FreePrimaryOwner;
  private static _freeMember = JoinWorkspaceAccountPrivilegeEnum.FreeMember;
  private static _charged = PAYMENT_TYPE.paid;
  private static _free = PAYMENT_TYPE.free;

  static all = new AclRule(this._primaryOwner)
    .or(this._owner)
    .or(this._manager)
    .or(this._member)
    .or(this._restricted)
    .or(this._freePrimaryOwner)
    .or(this._freeMember);

  static owner = new AclRule(this._primaryOwner)
    .or(this._owner)
    .or(this._freePrimaryOwner);

  static privilege = new AclRule(this._primaryOwner)
    .or(this._owner)
    .or(this._manager)
    .or(this._freePrimaryOwner);

  static withoutRestricted = new AclRule(this._primaryOwner)
    .or(this._owner)
    .or(this._manager)
    .or(this._member);

  static primary = new AclRule(this._primaryOwner);

  static charged = new AclRule(this._charged).and(this.all);
  static free = new AclRule(this._free).and(this.all);

  static chargedOwner = new AclRule(this._charged).and(this.owner);
  static chargedPrivilege = new AclRule(this._charged).and(this.privilege);
}
