
import { defineComponent, computed } from 'vue';
import Image from '@/components/ui/Image.vue';
export default defineComponent({
  name: 'WorkspaceLogo',
  components: {
    Image
  },
  props: {
    src: [String],
    alt: String,
    size: String
  },
  setup(props) {
    return {
      sizeClass: computed(() => props.size)
    };
  }
});
