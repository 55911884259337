import { BasicTargetApi } from '@/api';
import { useBasicTargetsStore } from '@/store/basicTargets';
import useLoading from '@/composables/loading';
import { Ref } from 'vue';

interface UseBasicTargetReturnType {
  isLoadingBasicTarget: Ref<boolean>;
  getBasicTargets: (params?: undefined) => Promise<void>;
}
export const useBasicTarget = (): UseBasicTargetReturnType => {
  const store = useBasicTargetsStore();
  const _getBasicTargets = async () => {
    store.setLoading(true);
    const response = await BasicTargetApi.getBasicTargets();
    store.setBasicTargets(response.data.list);
    store.setLoading(false);
  };

  const [isLoadingBasicTarget, getBasicTargets] = useLoading<undefined, void>(
    _getBasicTargets
  );

  return {
    isLoadingBasicTarget,
    getBasicTargets
  };
};
