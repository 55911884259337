import { httpCode } from '@/common/constant';
import axios, { AxiosInstance } from 'axios';
import { App } from 'vue';

export default {
  install: (app: App): AxiosInstance => {
    const axios_instance = axios.create();
    const router = app.config.globalProperties.$router;
    // リクエストでCookie送信
    axios.defaults.withCredentials = true;

    // リクエスト
    axios.interceptors.request.use(
      response => {
        // console.info({ リクエスト: response });
        return response;
      },
      error => {
        console.error(error);
      }
    );

    // レスポンス
    axios.interceptors.response.use(
      response => {
        // console.info({ レスポンス: response });
        return response;
      },
      error => {
        // console.error(error);
        const res = error.response;
        const status = res === undefined ? undefined : res.status;

        const params = {
          type: 'error',
          name: 'エラーが発生しました。',
          message: '問題が発生したためページを表示できませんでした。',
          messages: [],
          back: router.options.history.state.current || '',
          status,
          redirect: router.currentRoute.value.path,
          orig: 'API ' + error
        };

        switch (status) {
          // NOTE: logout検知は src/router/index.ts に任せる
          case httpCode.unauthorized:
            params.name = 'ログアウトしました。';
            params.message = 'ログインしなおしてください。';
            break;
          case httpCode.forbidden:
            params.name = '権限がありません。';
            params.message =
              'アクセス先が存在しないか、アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。';
            params.back = undefined;
            break;
          case httpCode.notFound:
            params.name = 'ページが見つかりません。';
            params.message =
              'アクセス先のURLが存在しません。 URLをお確かめください。';
            throw error;
          case httpCode.maintenance:
            params.name = 'ただいまメンテナンス中です';
            params.message =
              'ユーザーの皆様にはご不便をおかけしますが、\nメンテナンス終了まで今しばらくお待ち下さい。';
            break;
          case httpCode.conflict:
            params.message = res.data.msg;
            break;
          case httpCode.unprocessableEntity:
            if (res.data.errors && res.data.errors.length > 0) {
              params.messages = res.data.errors.map(v => v.msg);
            } else {
              return Promise.reject(error);
            }
            break;
          default:
            return Promise.reject(error);
        }
        return Promise.reject(params);
      }
    );

    return axios_instance;
  }
};
