// refs: https://github.com/switch-m/sips-migration/blob/aee3a613ccc3958ae03d304a1a01620fc7fbba14/src/main/resources/db/migration-saas/V0.029__insert_basic_targets_table.sql

import { defineStore } from 'pinia';
import { BasicDivision } from '@/api/openapi';

export const useBasicTargetsStore = defineStore('basicTargets', {
  state: () => ({
    basicTargets: [] as BasicDivision[],
    isLoading: false
  }),
  actions: {
    setBasicTargets(basicTargets?: BasicDivision[]) {
      this.basicTargets = basicTargets || [];
    },
    setLoading(loading: boolean) {
      this.isLoading = loading;
    }
  },
  getters: {
    basicTargetLabels: state => {
      let basicTargetLabels: { id?: number; label?: string }[] = [];
      for (const { targets } of state.basicTargets) {
        const labeles = targets?.map(target => ({
          id: target.id,
          label: target.displayTargetName
        }));
        if (labeles) {
          basicTargetLabels = [...basicTargetLabels, ...labeles];
        }
      }
      return basicTargetLabels;
    }
  }
});
