
import { defineComponent, watch, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import {
  ROUTE_NAMES,
  MYPAGE_ROUTES,
  SIGNUP_WORKSPACE_ROUTERS,
  SIGNUP_ROUTES,
  WORKSPACE_ROUTES
} from '@/router';
import { useAuthStore } from '@/store/auth';
import { useUserInfoStore } from '@/store/userInfo';
import { useSidebarStore } from '@/store/sidebar';
import { useToggleMenu } from '@/composables/headerNavigation';
import { useMessages, usePopupAlert } from '@/composables/message';
import { fetchAccountAvatar } from '@/composables/fetchImage';
import HeaderNavigationMenu from './HeaderNavigationMenu.vue';
import PopupAlert from './PopupAlert.vue';
import InfoMenu from './InfoMenu.vue';
import ProjectMenu from './ProjectMenu.vue';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import AccountIcon from '@/components/ui/AccountIcon.vue';
import Button from '@/components/ui/Button.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Logo from '@/components/ui/Logo.vue';
import Tooltip from '@/components/ui/Tooltip.vue';
import useModal from '@/composables/modal';
import {
  JoinWorkspace,
  JoinWorkspacePaymentTypeEnum,
  JoinableCompany
} from '@/api/openapi';
import CompanyMenu from './CompanyMenu.vue';
import MenuIconSvg from '@/assets/images/menu-icon.svg?inline';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    PopupAlert,
    AccountIcon,
    Box,
    Button,
    Flex,
    HeaderNavigationMenu,
    InfoMenu,
    Logo,
    ProjectMenu,
    IconButton,
    Tooltip,
    CompanyMenu,
    MenuIconSvg
  },
  props: {
    restrictRouting: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const info = useMessages();
    const alert = usePopupAlert();
    const hasNotification = ref(false);
    const userInfoStore = useUserInfoStore();
    const isDisplayDashboardMenu = ref(false);
    const isDisplayCompanyMenu = ref(false);
    const authStore = useAuthStore();
    const sidebarStore = useSidebarStore();
    const { user } = storeToRefs(userInfoStore);
    const noLogoLink = route.meta.noLogoLink;

    const MENU_ID = { project: 'project', info: 'info' };
    const { activeMenuId, openMenu, closeMenu } = useToggleMenu();
    const isLoggedIn = authStore.isLoggedIn;

    const {
      isModalOpen: isCompanyMenuOpen,
      openModal: openCompanyMenu,
      closeModal: closeCompanyMenu
    } = useModal();

    const currentCompany = ref<JoinableCompany | null>(null);
    const companies = ref<JoinableCompany[]>([]);
    const workspace = ref<JoinWorkspace | null>(null);

    // Sidebar の開閉ボタン
    const showSidebarRef = ref(sidebarStore.getShowSidebar);
    watch(
      () => sidebarStore.getShowSidebar,
      getShowSidebar => (showSidebarRef.value = getShowSidebar)
    );

    // CompanyMenu の表示制御
    watch(
      () => userInfoStore.currentWorkspaceFromRoute(route),
      () => {
        const currentWorkspace = userInfoStore.currentWorkspaceFromRoute(route);
        const isPaidWorkspace =
          currentWorkspace?.paymentType === JoinWorkspacePaymentTypeEnum.Paid;
        isDisplayCompanyMenu.value = isPaidWorkspace
          ? true
          : userInfoStore.getCompaniesByWorkspaceId(
              currentWorkspace?.workspaceId ?? 0
            ).length > 1;
      }
    );

    watchEffect(() => {
      const companyId = route.params.companyId;
      if (companyId !== undefined) {
        currentCompany.value = userInfoStore.getCompanyInfo(
          companyId.toString()
        );
        companies.value = userInfoStore.getCompaniesByWorkspaceId(
          currentCompany?.value?.workspaceId ?? 0
        );
        workspace.value = userInfoStore.user.workspaces.filter(
          v => v.workspaceStatus === 'ACTIVE'
        )[0];
      }
    });

    // メニューアイコンの表示/非表示判定
    watchEffect(() => {
      if (!route.name || typeof route.name !== 'string') return;
      // MEMO: 表示しない画面を指定する
      isDisplayDashboardMenu.value = ![
        ROUTE_NAMES.login,
        ROUTE_NAMES.forgotPassword,
        ROUTE_NAMES.resetPassword,
        ROUTE_NAMES.error,
        ROUTE_NAMES.notFound,
        ...Object.values(SIGNUP_ROUTES),
        ...Object.values(SIGNUP_WORKSPACE_ROUTERS),
        ...Object.values(MYPAGE_ROUTES),
        ...Object.values(WORKSPACE_ROUTES)
      ].includes(route.name);
    });

    // お知らせがあるかどうか
    watch(
      () => info.hasNotification.value,
      () => {
        hasNotification.value = info.hasNotification.value;
      }
    );

    const onClickLogo = () => {
      router.push({ name: ROUTE_NAMES.root });
    };

    const onClickMenu = () => {
      sidebarStore.toggle();
    };
    return {
      MENU_ID,
      hasNotification,
      info,
      alert,
      toTopName: MYPAGE_ROUTES.top,
      activeMenuId,
      user,
      isLoggedIn,
      route,
      ROUTE_NAMES,
      noLogoLink,
      showSidebarRef,
      companies,
      workspace,
      currentCompany,
      isDisplayDashboardMenu,
      isDisplayCompanyMenu,
      isCompanyMenuOpen,
      onClickLogo,
      onClickMenu,
      openCompanyMenu,
      closeCompanyMenu,
      openMenu,
      closeMenu,
      fetchAccountAvatar
    };
  }
});
