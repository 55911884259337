<template>
  <path
    d="M20 40C31.05 40 40 31.05 40 20C40 8.95 31.05 0 20 0C8.95 0 0 8.95 0 20C0 31.05 8.95 40 20 40Z"
    fill="#E4EBFA"
  />
  <path
    d="M25.84 29.0801H14.05C13.75 29.1201 13.48 29.2701 13.29 29.5001C13.1 29.7301 13 30.0301 13.01 30.3201C13.02 30.6201 13.16 30.9001 13.38 31.1001C13.59 31.3001 13.88 31.4201 14.19 31.4201H25.98C26.28 31.3801 26.55 31.2301 26.74 31.0001C26.93 30.7701 27.03 30.4701 27.02 30.1801C27.01 29.8801 26.87 29.6001 26.65 29.4001C26.44 29.2001 26.15 29.0901 25.84 29.0901V29.0801Z"
    fill="#4674D7"
  />
  <path
    d="M30.52 11.7695C30.6 11.7695 30.69 11.7995 30.75 11.8595C30.82 11.9195 30.86 12.0195 30.87 12.1195V12.1795V25.5695C30.87 25.6495 30.84 25.7395 30.76 25.8195C30.71 25.8695 30.64 25.9095 30.57 25.9295H9.46999C9.38999 25.9295 9.29999 25.8995 9.21999 25.8195C9.16999 25.7695 9.12998 25.6995 9.10998 25.6295V12.1295C9.10998 12.0495 9.13999 11.9595 9.21999 11.8795C9.26999 11.8295 9.33999 11.7895 9.40999 11.7695H30.52ZM30.52 9.76953H9.28999C8.69999 9.81953 8.13998 10.0895 7.73998 10.5295C7.33998 10.9595 7.10999 11.5395 7.10999 12.1295V25.7495C7.15999 26.3395 7.41999 26.8995 7.86999 27.2995C8.29999 27.6995 8.87999 27.9295 9.46999 27.9295H30.7C31.29 27.8795 31.85 27.6095 32.25 27.1695C32.65 26.7395 32.88 26.1595 32.88 25.5695V11.9495H32.87C32.82 11.3595 32.55 10.7995 32.13 10.3995C31.7 9.99953 31.12 9.76953 30.53 9.76953H30.52Z"
    fill="#4674D7"
  />
  <path
    d="M23.26 19.5004L18.13 22.4604C17.61 22.7504 16.98 22.3804 16.98 21.7904V15.8704C16.98 15.2804 17.62 14.9104 18.13 15.2004L23.26 18.1604C23.78 18.4504 23.78 19.1904 23.26 19.4804V19.5004Z"
    fill="#4674D7"
  />
</template>
