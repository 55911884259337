<template>
  <path
    d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z"
    fill="#FF0864"
  />
  <path
    d="M9.73438 12.7109C10.4492 12.7109 11.0156 12.4473 11.4336 11.9199C11.8555 11.3926 12.0664 10.6426 12.0664 9.66992C12.0664 8.70117 11.8555 7.95312 11.4336 7.42578C11.0156 6.89453 10.4492 6.62891 9.73438 6.62891C9.01953 6.62891 8.44922 6.89258 8.02344 7.41992C7.59766 7.94727 7.38477 8.69727 7.38477 9.66992C7.38477 10.6426 7.59766 11.3926 8.02344 11.9199C8.44922 12.4473 9.01953 12.7109 9.73438 12.7109ZM13.8711 9.66992C13.8711 11.2207 13.4355 12.4082 12.5645 13.2324C11.9121 13.9043 10.9688 14.2402 9.73438 14.2402C8.5 14.2402 7.55664 13.9043 6.9043 13.2324C6.0293 12.4082 5.5918 11.2207 5.5918 9.66992C5.5918 8.08789 6.0293 6.90039 6.9043 6.10742C7.55664 5.43555 8.5 5.09961 9.73438 5.09961C10.9688 5.09961 11.9121 5.43555 12.5645 6.10742C13.4355 6.90039 13.8711 8.08789 13.8711 9.66992Z"
    fill="white"
  />
</template>
