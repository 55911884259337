import { defineComponent } from 'vue';

export const CUSTOM_ROUTES = {
  index: 'cutomIndex',
  create: 'customCreate',
  edit: 'customEdit',
  completed: 'completed'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE_BASE = ' | カスタムターゲット';
const TITLE = {
  index: 'カスタムターゲット',
  create: '新規作成' + TITLE_BASE,
  edit: 'カスタムターゲット編集 ' + TITLE_BASE,
  completed: 'カスタムターゲット作成完了' + TITLE_BASE
} as const;

export default [
  {
    path: '/company/:companyId/custom',
    name: CUSTOM_ROUTES.index,
    meta: {
      title: TITLE.index
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/custom/index.vue')
  },
  {
    path: '/company/:companyId/custom/create',
    name: CUSTOM_ROUTES.create,
    meta: {
      title: TITLE.create
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/custom/Create.vue')
  },
  {
    path: '/company/:companyId/custom/:id/edit',
    name: CUSTOM_ROUTES.edit,
    meta: {
      title: TITLE.edit
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/custom/Edit.vue')
  },
  {
    path: '/company/:companyId/custom/:id?/complete',
    name: CUSTOM_ROUTES.completed,
    meta: {
      title: TITLE.completed
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/custom/Completed.vue')
  }
];
