<template>
  <g clip-path="url(#clip0_1829_4910)">
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM18.46 9.25H16.44C16.11 6.25 13.74 3.88 10.75 3.53V1.54C14.84 1.9 18.1 5.16 18.46 9.25ZM7.25 13.76C6.83 13.76 6.5 13.43 6.5 13.01C6.5 12.75 6.54 12.49 6.63 12.26C6.9 11.5 7.56 10.93 8.38 10.79C8.5 10.77 8.63 10.76 8.76 10.76H11.26C11.39 10.76 11.51 10.76 11.64 10.79C12.45 10.93 13.12 11.5 13.39 12.26C13.47 12.49 13.52 12.75 13.52 13.01C13.52 13.43 13.19 13.76 12.77 13.76H7.25ZM10 5.75999C11.1 5.75999 12 6.65999 12 7.75999C12 8.85999 11.1 9.75999 10 9.75999C8.9 9.75999 8 8.85999 8 7.75999C8 6.65999 8.9 5.75999 10 5.75999ZM9.25 1.54V3.53C6.26 3.88 3.89 6.25 3.56 9.25H1.54C1.9 5.16 5.16 1.9 9.25 1.54ZM1.54 10.75H3.57C3.93 13.72 6.28 16.07 9.25 16.41V18.46C5.16 18.1 1.9 14.84 1.54 10.75ZM10.75 18.46V16.41C13.72 16.07 16.08 13.72 16.43 10.75H18.46C18.1 14.84 14.84 18.1 10.75 18.46Z"
      fill="#4674D7"
    />
  </g>
  <defs>
    <clipPath id="clip0_1829_4910">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
