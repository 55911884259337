<template>
  <g clip-path="url(#clip0_1060_6952)">
    <path
      d="M16.5 16.5H3.5C3.36739 16.5 3.24021 16.5527 3.14645 16.6464C3.05268 16.7402 3 16.8674 3 17V17.5C3 17.6326 3.05268 17.7598 3.14645 17.8536C3.24021 17.9473 3.36739 18 3.5 18H16.5C16.6326 18 16.7598 17.9473 16.8536 17.8536C16.9473 17.7598 17 17.6326 17 17.5V17C17 16.8674 16.9473 16.7402 16.8536 16.6464C16.7598 16.5527 16.6326 16.5 16.5 16.5ZM18.5 2H1.5C1.10218 2 0.720644 2.15804 0.43934 2.43934C0.158035 2.72064 0 3.10218 0 3.5L0 13.5C0 13.8978 0.158035 14.2794 0.43934 14.5607C0.720644 14.842 1.10218 15 1.5 15H18.5C18.8978 15 19.2794 14.842 19.5607 14.5607C19.842 14.2794 20 13.8978 20 13.5V3.5C20 3.10218 19.842 2.72064 19.5607 2.43934C19.2794 2.15804 18.8978 2 18.5 2V2ZM18.5 13.5H1.5V3.5H18.5V13.5Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_1060_6952">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
