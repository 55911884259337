<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.3038 6.70151V9.79621H13.3985V10.8038H10.3038V13.8985H9.29621V10.8038H6.20151C5.92328 10.8038 5.69773 10.5782 5.69773 10.3C5.69773 10.0218 5.92328 9.79621 6.20151 9.79621H9.29621V6.70151H10.3038Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.8 1.80758C5.10976 1.80758 1.30758 5.60976 1.30758 10.3C1.30758 14.9902 5.10976 18.7924 9.8 18.7924C14.4902 18.7924 18.2924 14.9902 18.2924 10.3C18.2924 5.60976 14.4902 1.80758 9.8 1.80758ZM0.3 10.3C0.3 5.05329 4.55329 0.8 9.8 0.8C15.0467 0.8 19.3 5.05329 19.3 10.3C19.3 15.5467 15.0467 19.8 9.8 19.8C4.55329 19.8 0.3 15.5467 0.3 10.3Z"
  />
</template>
