<template>
  <g clip-path="url(#clip0_1020_6673)">
    <path
      d="M16.625 7.49957H15.375V5.67926C15.375 2.56988 12.875 0.00737281 9.76562 -0.0004397C6.65625 -0.00825221 4.125 2.5191 4.125 5.62457V7.49957H2.875C1.83984 7.49957 1 8.33942 1 9.37457V18.1246C1 19.1597 1.83984 19.9996 2.875 19.9996H16.625C17.6602 19.9996 18.5 19.1597 18.5 18.1246V9.37457C18.5 8.33942 17.6602 7.49957 16.625 7.49957ZM6 5.62457C6 3.55816 7.68359 1.87456 9.75 1.87456C11.8164 1.87456 13.5 3.55816 13.5 5.62457V7.49957H6V5.62457ZM16.625 18.1246H2.875V9.37457H16.625V18.1246Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_1020_6673">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
