import { NoticeApi } from '@/api';
import { NoticeMessage, NoticeAlert } from '@/api/openapi';
import { DATE_FORMAT_JA } from '@/common/format';
import { toast } from '@/components/ui/Toast';
import { useAuthStore } from '@/store/auth';
import { useUserInfoStore } from '@/store/userInfo';
import { format } from 'date-fns';
import { ref, Ref } from 'vue';
import { useRouter } from 'vue-router';

interface Error {
  title: string;
}

interface ReturnTypeMessages {
  loading: Ref<boolean>;
  messages: Ref<NoticeMessage[]>;
  isError: Ref<Error>;
  hasNotification: Ref<boolean>;
}

interface ReturnPopupAlert {
  isError: Ref<Error>;
  messages: Ref<NoticeAlert[]>;
}

/** お知らせメッセージの取得
 * @throws {RequiredError}
 * @returns Promise<NoticeMessage[]>
 */
const fetchMessages = async (userId: number): Promise<NoticeMessage[]> => {
  const response = await NoticeApi.getAccountsAccountIdNoticesMessages(
    userId
  ).catch(error => {
    toast({
      title: 'お知らせの取得に失敗しました',
      variant: 'error'
    });
    throw error;
  });

  return response.data.list as NoticeMessage[];
};

/** loaing、Error、メッセージ一覧, Badgenの情報
 * @throws {RequiredError}
 * @returns ReturnTypeMessages
 */
export const useMessages = (): ReturnTypeMessages => {
  const userInfoStore = useUserInfoStore();

  const loading = ref(true);
  const messages = ref<NoticeMessage[]>([]);
  const isError = ref<Error>({} as Error);
  const hasNotification = ref(false);
  const authStore = useAuthStore();

  const router = useRouter();
  router.afterEach(async () => {
    try {
      const userId = userInfoStore.user.id;
      if (authStore.isLoggedIn && userId) {
        const res = await fetchMessages(userId);
        messages.value = res.map(value => ({
          ...value,
          startAt: format(
            new Date(value.startAt || new Date()),
            DATE_FORMAT_JA
          ).toString()
        }));
        hasNotification.value = res.filter(v => !v.isRead).length > 0;
      }
    } catch (error) {
      isError.value = {
        title: 'お知らせの取得に失敗しました'
      };
    } finally {
      loading.value = false;
    }
  });

  return {
    loading,
    messages,
    isError,
    hasNotification
  };
};

/**
 * お知らせを展開したら既読を飛ばす
 * @param messages
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const putMessage = async (messages: NoticeMessage[]) => {
  const userInfoStore = useUserInfoStore();
  const userId = userInfoStore.user.id;

  // isRead=falseのNoticeId
  const updateNoticeId = messages
    .filter((message: NoticeMessage) => !message.isRead)
    .map((message: NoticeMessage) => message.noticeId || 0);

  updateNoticeId.map(async (noticeId: number) => {
    await NoticeApi.putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit(
      userId,
      noticeId
    ).catch(error => {
      toast({
        title: 'お知らせの更新に失敗',
        variant: 'error'
      });
      throw error;
    });
  });
};

/** お知らせアラートの取得
 * @throws {RequiredError}
 * @returns Promise<NoticeAlert[]>
 */
const fetchPopupAlert = async (userId: number): Promise<NoticeAlert[]> => {
  const response = await NoticeApi.getAccountsAccountIdNoticesAlerts(
    userId
  ).catch(error => {
    toast({
      title: 'アラートの取得に失敗しました',
      variant: 'error'
    });
    throw error;
  });

  return response.data.list as NoticeAlert[];
};

/** loaing、Error、メッセージ一覧, Badgenの情報
 * @throws {RequiredError}
 * @returns ReturnTypeMessages
 */
export const usePopupAlert = (): ReturnPopupAlert => {
  const userInfoStore = useUserInfoStore();

  const messages = ref<NoticeAlert[]>([]);
  const isError = ref<Error>({} as Error);
  const authStore = useAuthStore();

  const router = useRouter();
  router.afterEach(async () => {
    try {
      const userId = userInfoStore.user.id;
      if (userId && authStore.isLoggedIn) {
        messages.value = await fetchPopupAlert(userId);
      }
    } catch (error) {
      isError.value = {
        title: 'アラートの取得に失敗しました'
      };
    }
  });

  return {
    isError,
    messages
  };
};

/**
 * お知らせを展開したら既読を飛ばす
 * @param messages
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const putPopupAlert = async (noticeId: number) => {
  const userInfoStore = useUserInfoStore();
  const userId = userInfoStore.user.id;

  await NoticeApi.putAccountsAccountIdNoticesNoticeIdAccountNoticeStatusesEdit(
    userId,
    noticeId
  ).catch(error => {
    toast({
      title: 'アラートの更新に失敗',
      variant: 'error'
    });
    throw error;
  });
};
