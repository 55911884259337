<template>
  <g clip-path="url(#clip0_31_3826)">
    <path
      d="M16.625 9.99905H6.9375V5.97171C6.9375 4.42484 8.17578 3.13968 9.72266 3.12405C11.2852 3.10843 12.5625 4.37796 12.5625 5.93655V6.56155C12.5625 7.08109 12.9805 7.49905 13.5 7.49905H14.75C15.2695 7.49905 15.6875 7.08109 15.6875 6.56155V5.93655C15.6875 2.6553 13.0117 -0.0126645 9.73047 -0.000945734C6.44922 0.010773 3.8125 2.7139 3.8125 5.99515V9.99905H2.875C1.83984 9.99905 1 10.8389 1 11.8741V18.1241C1 19.1592 1.83984 19.9991 2.875 19.9991H16.625C17.6602 19.9991 18.5 19.1592 18.5 18.1241V11.8741C18.5 10.8389 17.6602 9.99905 16.625 9.99905ZM11.3125 15.9366C11.3125 16.7998 10.6133 17.4991 9.75 17.4991C8.88672 17.4991 8.1875 16.7998 8.1875 15.9366V14.0616C8.1875 13.1983 8.88672 12.4991 9.75 12.4991C10.6133 12.4991 11.3125 13.1983 11.3125 14.0616V15.9366Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_31_3826">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
