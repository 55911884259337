import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23ef3b37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderNavigation = _resolveComponent("HeaderNavigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterNavigation = _resolveComponent("FooterNavigation")!
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_Fallback = _resolveComponent("Fallback")!
  const _component_PageLoading = _resolveComponent("PageLoading")!

  return (_openBlock(), _createBlock(_Suspense, { timeout: "0" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.noHeader)
          ? (_openBlock(), _createBlock(_component_HeaderNavigation, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.noHeader)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["header-spacer", !_ctx.noFooter ? 'footer-spacer' : ''])
            }, null, 2))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$route.fullPath
        })),
        (!_ctx.noHeader)
          ? (_openBlock(), _createBlock(_component_FooterNavigation, { key: 2 }))
          : _createCommentVNode("", true),
        _createVNode(_component_metainfo, null, {
          title: _withCtx(({ content }) => [
            _createTextVNode(_toDisplayString(content) + " ｜ TVAL", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Fallback)
      ])
    ]),
    fallback: _withCtx(() => [
      _createVNode(_component_PageLoading, { "is-transparent": "" })
    ]),
    _: 1
  }))
}