<template>
  <teleport to="body">
    <Flex
      justify-center
      align-center
      class="page-loading-overlay"
      :class="{ 'is-transparent': isTransparent }"
    >
      <Loading :color="isTransparent ? 'default' : 'white'" size="m" />
    </Flex>
  </teleport>
</template>
<script>
import { defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Loading from '@/components/ui/Loading.vue';

export default defineComponent({
  name: 'pageLoading',
  components: { Flex, Loading },
  props: {
    isTransparent: Boolean
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/layout';

.page-loading-overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dark-50-color);
  z-index: $z-index-clickgurard + 1;

  &.is-transparent {
    // https://www.figma.com/file/pUHSMWedNdhffzNrPOvV0q?node-id=8:8310#171735700
    background-color: var(--background-color);
  }
}
</style>
