<template>
  <g clip-path="url(#clip0_2_8)">
    <path
      d="M10 19.6875C4.64844 19.6875 0.3125 15.3516 0.312501 10C0.312501 4.64844 4.64844 0.312499 10 0.312499C15.3516 0.3125 19.6875 4.64844 19.6875 10C19.6875 15.3516 15.3516 19.6875 10 19.6875ZM11.1289 14.0781L8.17969 11.25L15.3125 11.25C15.832 11.25 16.25 10.832 16.25 10.3125L16.25 9.6875C16.25 9.16797 15.832 8.75 15.3125 8.75L8.17969 8.75L11.1289 5.92187C11.5078 5.55859 11.5156 4.95312 11.1445 4.58203L10.7148 4.15625C10.3477 3.78906 9.75391 3.78906 9.39063 4.15625L4.20703 9.33594C3.83985 9.70312 3.83985 10.2969 4.20703 10.6602L9.39063 15.8477C9.75781 16.2148 10.3516 16.2148 10.7148 15.8477L11.1445 15.4219C11.5156 15.0469 11.5078 14.4414 11.1289 14.0781V14.0781Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_2_8">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="translate(20 20) rotate(-180)"
      />
    </clipPath>
  </defs>
</template>
