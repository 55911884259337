import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "account-icon-image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["account-icon", [_ctx.privilegeClass, _ctx.sizeClass]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Image, { src: _ctx.imageUrl }, null, 8, ["src"])
    ])
  ], 2))
}