<template>
  <Typography
    :size="size"
    :style="{ width, display }"
    class="ellipsis"
    :lineHeight="lineHeight"
    v-bind="$attrs"
  >
    <slot />
  </Typography>
</template>
<script>
import { defineComponent } from 'vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'TypographyEllipsis',
  components: {
    Typography
  },
  props: {
    width: String,
    line: {
      type: Number,
      default: 1
    },
    lineHeight: {
      type: String,
      default: '1'
    },
    display: String,
    size: String
  }
});
</script>
<style lang="scss">
.ellipsis {
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: v-bind(line);
  text-overflow: ellipsis;
}
</style>
