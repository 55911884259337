<template>
  <div class="click-guard" />
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ClickGuard'
});
</script>
<style lang="scss" scoped>
@import '@/styles/layout';

.click-guard {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: $z-index-clickgurard;
}
</style>
