import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f93f43c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-text-field" }
const _hoisted_2 = ["type", "name", "id", "disabled", "placeholder", "inputmode", "autocomplete", "readonly", "min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(["base-input", [_ctx.errorClass, _ctx.disabledClass, _ctx.sizeClass, _ctx.boldClass, _ctx.type]]),
      type: _ctx.inputType,
      name: _ctx.name,
      id: _ctx.id,
      disabled: _ctx.disabled,
      placeholder: _ctx.$attrs.placeholder,
      inputmode: _ctx.$attrs.inputmode,
      autocomplete: _ctx.$attrs.autocomplete,
      readonly: _ctx.$attrs.readonly,
      min: _ctx.$attrs.min,
      max: _ctx.$attrs.max,
      onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlurHandler && _ctx.onBlurHandler(...args))),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputValue) = $event)),
      ref: "baseTextFieldBody"
    }, null, 42, _hoisted_2), [
      [_vModelDynamic, _ctx.inputValue]
    ]),
    (_ctx.type === 'password')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["toggle-icon", _ctx.size])
        }, [
          _createVNode(_component_IconButton, {
            type: "button",
            onClick: _ctx.onClickTogglePassword,
            "icon-name": _ctx.inputType === 'text' ? 'eye' : 'eye-slash',
            size: _ctx.size
          }, null, 8, ["onClick", "icon-name", "size"])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}