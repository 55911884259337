<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.6154"
      cy="5.88462"
      r="1.38462"
      transform="rotate(90 10.6154 5.88462)"
      fill="#425252"
    />
    <ellipse
      cx="6.00003"
      cy="5.88462"
      rx="1.38462"
      ry="1.38462"
      transform="rotate(90 6.00003 5.88462)"
      fill="#425252"
    />
    <ellipse
      cx="1.38461"
      cy="5.88462"
      rx="1.38462"
      ry="1.38462"
      transform="rotate(90 1.38461 5.88462)"
      fill="#425252"
    />
  </svg>
</template>
