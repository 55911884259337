<template>
  <template v-if="upload">
    <BaseFileButton
      :id="id"
      :disabled="disabled"
      @change-file="file => $emit('change-file', file)"
    >
      <div
        class="ui-button"
        :class="[
          variantClass,
          colorClass,
          sizeClass,
          displayClass,
          disabled ? 'disabled' : ''
        ]"
        :style="{ width }"
      >
        <Typography :size="size" color="inherit" line-height="1">
          <slot />
        </Typography>
      </div>
    </BaseFileButton>
  </template>
  <template v-else>
    <BaseButton
      class="ui-button"
      :style="{ width }"
      :class="[
        variantClass,
        sizeClass,
        displayClass,
        colorClass,
        iconOnlyClass,
        loadingClass,
        denseClass
      ]"
      :disabled="disabled"
      @click="$emit('click', $event)"
      noHover
      v-bind="$attrs"
    >
      <Flex align-center :flex-direction="iconEnd ? 'row-reverse' : ''">
        <Icon
          v-if="iconName && !loading"
          :name="iconName"
          :color="iconColor || 'inherit'"
          :class="[iconSpacingClass]"
          :sides="iconSides || '20'"
        />
        <Loading
          v-if="loading"
          :color="loadingIconColor"
          class="loading-icon"
        />
        <Typography
          :size="size"
          color="inherit"
          line-height="1"
          class="button-label"
        >
          <slot />
        </Typography>
      </Flex>
    </BaseButton>
    <ClickGuard v-if="loading" />
  </template>
</template>
<script>
import { defineComponent, computed, ref } from 'vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseFileButton from '@/components/ui/BaseFileButton.vue';
import Flex from '@/components/layout/Flex.vue';
import ClickGuard from '@/components/ui/ClickGuard.vue';
import Icon from '@/components/ui/Icon.vue';
import Loading from '@/components/ui/Loading.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'Button',
  components: {
    BaseButton,
    BaseFileButton,
    ClickGuard,
    Flex,
    Icon,
    Loading,
    Typography
  },
  props: {
    id: String,
    upload: Boolean,
    variant: {
      type: String,
      default: 'contained' // 'outlined' | 'text'
    },
    color: {
      type: String,
      default: 'primary'
    },
    iconName: String,
    iconEnd: Boolean,
    iconOnly: Boolean,
    iconColor: String,
    size: {
      type: String,
      default: 's'
    }, // xs | s | m | l
    block: Boolean,
    disabled: Boolean,
    width: String,
    iconSides: String,
    loading: Boolean,
    space: {
      type: Number,
      default: 2
    },
    dense: Boolean
  },
  emits: ['change-file', 'click'],
  setup(props) {
    const inputFileRef = ref();
    const loadingIconColor = computed(() => {
      return [
        '',
        'primary',
        'tertiary',
        'red',
        'grey',
        'dark-grey',
        'dark-grey6',
        'dark-grey10-hex'
      ].includes(props.color)
        ? 'white'
        : 'default';
    });
    return {
      inputFileRef,
      variantClass: computed(() => props.variant || 'contained'),
      colorClass: computed(() => props.color || 'primary'),
      sizeClass: computed(() => props.size || 's'),
      displayClass: computed(() => (props.block ? 'block' : '')),
      iconOnlyClass: computed(() => (props.iconOnly ? 'icon-only' : '')),
      iconSpacingClass: computed(() => {
        if (props.iconOnly || !props.iconName) return;
        return props.iconEnd ? `ml-${props.space}` : `mr-${props.space}`;
      }),
      loadingIconColor,
      loadingClass: computed(() => (props.loading ? 'loading' : '')),
      denseClass: computed(() => (props.dense ? 'dense' : ''))
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/effect';

.ui-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 $spacing-base * 4;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  box-shadow: var(--shadow-4);

  &.contained {
    color: var(--contrast-color);
    @extend .hover;
  }

  &.primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &.primary-light {
    background-color: var(--primary-light-color);
    border-color: var(--primary-light-color);
  }

  &.tertiary {
    background-color: var(--tertiary-color);
    border-color: var(--tertiary-color);
  }
  &.dark-grey {
    background-color: var(--dark-50-color);
    border-color: var(--dark-50-color);
  }
  &.dark-grey6 {
    background-color: var(--dark-60-color);
    border-color: var(--dark-60-color);
  }
  &.dark-grey10-hex {
    background-color: var(--dark-10-hex-color);
    border-color: var(--dark-10-hex-color);
  }
  &.grey {
    background-color: var(--dark-25-hex-color);
    border-color: var(--dark-25-hex-color);
  }

  &.red {
    background-color: var(--red-blue_red-5);
    border-color: var(--red-blue_red-5);
  }

  &.white {
    background-color: var(--contrast-color);
    border-color: var(--dark-10-hex-color);
    color: var(--text-color);
    @extend .hover-button-background;
  }

  &.outlined {
    background-color: var(--contrast-color);

    &.primary {
      color: var(--primary-color);
    }

    &.tertiary {
      color: var(--tertiary-color);
    }

    &.dark-grey {
      color: var(--dark-50-color);
    }
    &.dark-grey6 {
      color: var(--dark-60-color);
    }
    &.grey {
      color: var(--text-color);
    }

    &.red {
      color: var(--red-blue_red-5);
      // NOTE: red outlined の hover のデザインは検討中
    }

    &:disabled,
    &.disabled {
      color: var(--dark-50-color);
    }
  }

  &.outlined:not(.disabled) {
    @extend .hover-button-background;
  }

  &.text {
    background-color: transparent;
    border: inherit;
    box-shadow: none;
    @extend .hover-button-background;

    &:disabled {
      background-color: inherit;
    }

    &.primary {
      color: var(--primary-color);
    }

    &.grey {
      color: var(--dark-25-hex-color);
    }
    &.dark-grey {
      color: var(--dark-50-color);
    }
    &.dark-grey6 {
      color: var(--dark-60-color);
    }
    &.dark-grey10-hex {
      color: var(--dark-10-hex-color);
    }
    &.red {
      color: var(--red-blue_red-5);
    }

    &.tertiary {
      color: var(--tertiary-color);
    }
  }

  &.loading {
    position: relative;

    &:not(.icon-only) {
      .loading-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -12px 0 0 -12px;
      }
    }

    &.outlined {
      background-color: var(--primary-light-color);
    }

    &.white,
    &.text {
      background-color: var(--primary-light-color);
      border-color: transparent;
    }

    .button-label {
      opacity: 0;
    }
  }

  &.dense {
    padding: 0 $spacing-base * 2;
  }

  &:disabled,
  &.disabled {
    background-color: var(--background-disabled-color);
    border-color: transparent;
    color: var(--dark-50-color);
    box-shadow: none;
    &:hover {
      cursor: inherit;
    }
  }

  &.s {
    height: 32px;
    min-width: 58px;

    &.icon-only {
      width: 32px;
      min-width: 32px;
    }

    &.dense {
      height: 26px;
    }
  }

  &.m {
    height: 40px;
    min-width: 64px;

    &.icon-only {
      width: 40px;
      min-width: 40px;
    }
  }

  &.l {
    height: 48px;
    min-width: 72px;

    &.icon-only {
      width: 48px;
      min-width: 48px;
    }
  }

  &.block {
    display: flex;
    width: 100%;
  }

  &.xs.text {
    padding: $spacing-base;
  }
}
</style>
