export const httpCode = {
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  unprocessableEntity: 422,
  serverError: 500,
  maintenance: 503,
  timeout: 504
} as const;

export const Breakpoint = {
  xs: 0,
  sm: 360,
  md: 540,
  lg: 800,
  xl: 1280
} as const;

export const PRIVILEGE = {
  primary: 'PRIMARY_OWNER',
  owner: 'OWNER',
  freeOwner: 'FREE_PRIMARY_OWNER',
  admin: 'MANAGER',
  member: 'MEMBER',
  freeMember: 'FREE_MEMBER',
  restricted: 'RESTRICTED'
};

export const PRIVILEGE_NAME = {
  [PRIVILEGE.primary]: 'プライマリー',
  [PRIVILEGE.owner]: 'オーナー',
  [PRIVILEGE.freeOwner]: 'プライマリー',
  [PRIVILEGE.admin]: '管理者',
  [PRIVILEGE.member]: 'メンバー',
  [PRIVILEGE.freeMember]: 'メンバー',
  [PRIVILEGE.restricted]: '制限メンバー'
} as const;

export const PRIVILEGE_SLECT_OPTIONS = [
  { value: PRIVILEGE.owner, name: PRIVILEGE_NAME[PRIVILEGE.owner] },
  { value: PRIVILEGE.admin, name: PRIVILEGE_NAME[PRIVILEGE.admin] },
  { value: PRIVILEGE.member, name: PRIVILEGE_NAME[PRIVILEGE.member] },
  {
    value: PRIVILEGE.restricted,
    name: PRIVILEGE_NAME[PRIVILEGE.restricted]
  }
];

export const PAYMENT_TYPE = {
  free: 'FREE',
  paid: 'PAID'
} as const;

export const DOMAIN_DEFINITION_TYPES = {
  contract: 'CONTRACT',
  preference: 'PREFERENCE',
  invalid: 'INVALID'
} as const;

export const DOMAIN_APPROVE_TYPES = {
  automatic: 'AUTOMATIC',
  manual: 'MANUAL',
  invalid: 'INVALID'
} as const;

export const DOMAIN_APPLICATION_STATUS = {
  submited: 'SUBMITED',
  invalid: 'INVALID',
  approved: 'APPROVED'
} as const;

export const AGGREGATION_UNIT = {
  household: 'HOUSEHOLD',
  personal: 'PERSONAL'
} as const;

type ValueOf<T> = T[keyof T];

export type AGGREGATION_UNITS = ValueOf<typeof AGGREGATION_UNIT>;

export const PRODUCTS_ORDERS = {
  cmCreativeDate: 'CM_CREATIVE_DATE',
  updatedAt: 'UPDATED_AT'
} as const;

export type PRODUCTS_ORDER = ValueOf<typeof PRODUCTS_ORDERS>;

const SUCCESS_TOAST = {
  title: '完了',
  message: '設定を保存しました'
} as const;

const ERROR_TOAST = {
  title: '失敗',
  message: '設定を保存できませんでした'
} as const;

const ATTENTION_TOAST = {
  title: '通知',
  message: '設定を読み込み中です'
} as const;

export const TOAST = {
  success: SUCCESS_TOAST,
  error: ERROR_TOAST,
  attention: ATTENTION_TOAST
} as const;

export const TARGET_NAME = {
  individual: '個人全体',
  household: '世帯',
  individualAndHousehold: '個人全体・世帯',
  genderAge12Type: '性・年齢12区分',
  gender10Type: '男女10歳区分',
  customTarget: 'カスタムターゲット'
} as const;

export const BASIC_TARGET_DIVISION = {
  ga12: 'GA12',
  ga10s: 'GA10S',
  ga8: 'GA8'
} as const;

export const OCCUPATIONS = [
  { id: 1, label: '会社役員・団体役員' },
  { id: 2, label: '自営業・個人事業主' },
  { id: 3, label: '正社員(管理職)' },
  { id: 4, label: '正社員(管理職以外)' },
  { id: 5, label: '公務員・団体職員' },
  { id: 6, label: '派遣社員・契約社員' },
  { id: 7, label: 'パート・アルバイト' },
  { id: 8, label: '専業主婦･専業主夫' },
  { id: 9, label: '未就学児' },
  { id: 10, label: '小学生' },
  { id: 11, label: '中・高校生' },
  { id: 12, label: '大学(院)生' },
  { id: 13, label: 'その他の学生' },
  { id: 14, label: 'その他・無職' }
] as const;

export const LOCALS = {
  Kanto: '関東',
  Kansai: '関西',
  Chukyo: '中京',
  Hokkaido: '北海道',
  Tohoku: '東北',
  Hks: '北陸・甲信越',
  Chushi: '中国・四国',
  Kyuoki: '九州・沖縄',
  Shizuoka: '静岡',
  Miyagi: '宮城',
  Hiroshima: '広島',
  Fukuoka: '福岡'
};

export const MIN_DATE = '2021-12-27T00:00:00';
