<template>
  <g clip-path="url(#clip0_1020_11002)">
    <path
      d="M19.8125 16.7187L1.125 2.10969C1.07374 2.06865 1.01491 2.03811 0.951846 2.01981C0.888787 2.00151 0.822741 1.99581 0.757478 2.00304C0.692216 2.01026 0.629016 2.03027 0.571487 2.06192C0.513958 2.09357 0.463226 2.13624 0.422188 2.1875L0.109688 2.57781C0.0685533 2.62909 0.0379332 2.68797 0.0195795 2.75109C0.0012258 2.81421 -0.00450123 2.88033 0.00272612 2.94567C0.00995347 3.011 0.0299933 3.07427 0.061699 3.13186C0.0934047 3.18944 0.136154 3.24021 0.1875 3.28125L18.875 17.8903C18.9263 17.9313 18.9851 17.9619 19.0482 17.9802C19.1112 17.9985 19.1773 18.0042 19.2425 17.997C19.3078 17.9897 19.371 17.9697 19.4285 17.9381C19.486 17.9064 19.5368 17.8637 19.5778 17.8125L19.8903 17.4222C19.9314 17.3709 19.9621 17.312 19.9804 17.2489C19.9988 17.1858 20.0045 17.1197 19.9973 17.0543C19.99 16.989 19.97 16.9257 19.9383 16.8681C19.9066 16.8106 19.8638 16.7598 19.8125 16.7187V16.7187ZM9.27469 6.57719L13.4869 9.87031C13.4175 7.99718 11.89 6.5 10 6.5C9.7562 6.50045 9.51312 6.52632 9.27469 6.57719ZM10.7253 13.4231L6.51312 10.13C6.58281 12.0028 8.11031 13.5 10 13.5C10.2438 13.4995 10.4868 13.4737 10.7253 13.4231V13.4231ZM10 5.5C13.0828 5.5 15.9091 7.21875 17.4353 10C17.0612 10.6845 16.5989 11.317 16.0603 11.8812L17.2397 12.8031C17.8963 12.1007 18.4526 11.3108 18.8928 10.4559C18.9643 10.3145 19.0015 10.1583 19.0015 9.99984C19.0015 9.84139 18.9643 9.68516 18.8928 9.54375C17.1966 6.23719 13.8416 4 10 4C8.85312 4 7.75906 4.21875 6.73031 4.58781L8.18062 5.72187C8.7725 5.5875 9.37875 5.5 10 5.5ZM10 14.5C6.91719 14.5 4.09125 12.7812 2.56469 10C2.93931 9.31551 3.40211 8.68314 3.94125 8.11906L2.76188 7.19718C2.10536 7.89952 1.54915 8.6893 1.10906 9.54406C1.03758 9.68547 1.00033 9.8417 1.00033 10.0002C1.00033 10.1586 1.03758 10.3148 1.10906 10.4562C2.80375 13.7628 6.15875 16 10 16C11.1469 16 12.2409 15.7797 13.2697 15.4122L11.8194 14.2784C11.2275 14.4125 10.6216 14.5 10 14.5Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_1020_11002">
      <rect width="20" height="20" fill="white" />
    </clipPath>
  </defs>
</template>
