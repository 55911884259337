import { Ref, ref } from 'vue';

type useLoadingReturnType<T, U> = [Ref<boolean>, (params: T) => Promise<U>];

export default function useLoading<T, U>(
  cb: (params: T) => Promise<U>
): useLoadingReturnType<T, U> {
  const isLoading = ref(false);

  const withLoadingFunction = async (params: T): Promise<U> => {
    isLoading.value = true;
    try {
      return await cb(params);
    } finally {
      isLoading.value = false;
    }
  };

  return [isLoading, withLoadingFunction];
}
