<template>
  <Box class="pa-2">
    <Typography size="m">
      お知らせ
    </Typography>
    <Divider class="my-2" />
    <Box v-if="isError && isError.title">
      <Typography text-align="center" size="s" line-height="1.5">
        {{ isError.title }}
      </Typography>
    </Box>
    <template v-else>
      <Flex v-if="loading" justify-center class="py-8">
        <Loading />
      </Flex>
      <Box v-else max-height="260px" overflow="hidden auto">
        <template v-if="messages && messages.length !== 0">
          <Box
            v-for="(message, i) in messages"
            :key="i"
            class="py-4 px-2"
            :class="{ isRead: message.isRead }"
          >
            <Flex>
              <Icon
                small
                v-if="message.important"
                name="info"
                color="error"
                class="mr-1"
                sides="12"
              />
              <Typography bold size="s">{{ message.title }}</Typography>
            </Flex>
            <Typography class="my-2 pre-wrap" size="s" line-height="1.5">
              <div v-html="message.content" />
            </Typography>
            <Typography text-align="right" color="grey" size="xs">
              {{ message.startAt }}
            </Typography>
          </Box>
        </template>
        <template v-else>
          <Typography text-align="center" size="s" line-height="1.5">
            お知らせはありません
          </Typography>
        </template>
      </Box>
    </template>
  </Box>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { putMessage } from '@/composables/message';
import Loading from '@/components/ui/Loading';
import Box from '@/components/layout/Box.vue';
import Icon from '@/components/ui/Icon.vue';
import Typography from '@/components/ui/Typography.vue';
import Divider from '@/components/ui/Divider.vue';
import Flex from '@/components/layout/Flex.vue';

export default defineComponent({
  name: 'HeaderNavigationInfoMenu',
  components: {
    Loading,
    Flex,
    Box,
    Icon,
    Typography,
    Divider
  },
  props: {
    info: Object
  },
  setup(props) {
    // TODO: 既読時の背景色
    // TODO: putMessageしたときmessagesの再描写
    const messages = ref(props.info.messages.value);

    if (props.info.hasNotification.value) {
      putMessage(props.info.messages.value);
    }

    return {
      messages,
      loading: props.info.loading.value,
      isError: props.info.isError.value
    };
  }
});
</script>

<style scoped>
/* .isRead {
  background-color: var(--background-disabled-color);
} */
.pre-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
