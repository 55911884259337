import { defineStore } from 'pinia';
import { AclRule } from '@/plugins/acl';

export const useAcl = defineStore('acl', {
  state: () => ({
    acl: null as AclRule | null
  }),
  actions: {
    can(rules?: AclRule) {
      if (this.acl === null) {
        return false;
      } else {
        if (rules === undefined) {
          return true;
        } else {
          const checkOr = rules.current.map(rule => {
            const result = rule.map(value => {
              return this.acl?.current.some(c => {
                return c.includes(value);
              });
            });
            return result.some(_ => _);
          }, true);
          return checkOr.every(_ => _);
        }
      }
    },
    setAcl(acl: AclRule) {
      this.acl = acl;
    }
  }
});
