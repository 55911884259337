<template>
  <span class="hover" ref="targetElement">
    <slot ref="targetElement" />
  </span>
</template>
<script>
import { defineComponent, ref, toRefs } from 'vue';
import { useTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';

export default defineComponent({
  name: 'Tooltip',
  props: {
    placement: {
      type: String,
      default: 'bottom' // 'left' | 'right' | 'top' | 'bottom'
    },
    content: [String, Object],
    xOffset: {
      type: Number,
      default: 0
    },
    yOffset: {
      type: Number,
      default: 10
    },
    maxWidth: {
      type: [Number, String]
    },
    delayShow: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { content } = toRefs(props);
    const targetElement = ref();

    useTippy(targetElement, {
      arrow: false,
      animation: 'shift-away',
      content: content,
      allowHTML: true,
      placement: props.placement,
      offset: [props.xOffset, props.yOffset],
      maxWidth: props.maxWidth,
      delay: [props.delayShow, 0]
    });

    return {
      targetElement
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';

.tippy-box {
  background-color: var(--dark-80-color);
  font-size: 12px;
  line-height: 1.5;
}
.tippy-content {
  padding: $spacing-base * 2;
}
</style>
