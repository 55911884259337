import { AreaInfo } from '@/api/openapi';
import { toast } from '@/components/ui/Toast';

export const UNAUTHENTICATED = 'Expires login. Please login again';
export const NO_INTERNET = 'The server cannot be connected';
export const SERVER_ERROR = 'Error connecting to server';

// auth
export const LOGIN_CHECK_ERROR = 'ログイン確認でエラーが発生しました';
export const USER_NOT_FOUND_ERROR =
  '入力されたアカウントに誤りがあるか、登録されていません';
export const USER_INVALID_PASSWORD = 'パスワードが正しくありません';
export const PASSWORD_CHANGE_REQUIRED = 'パスワードの再設定が必要です';
export const LIMIT_EXCEEDED_EXCEPTION =
  '連続でログインに失敗したため、このアカウントは一時的にロックされています';
export const USER_NOT_CONFIRMED_EXCEPTION =
  '認証コードによる本人確認が必要です';
export const CODE_MISMATCH = '認証コードが正しくないか無効です';
export const EXPIRED_CODE_EXCEPTION = '認証コードが無効です';
export const PASSWORD_RESET_REQUIRED = 'パスワードの再設定が必要です';
export const INVALID_TOAST_TITLE = '視聴データ未提供の期間が含まれます';
export const EXCEED_TOTAL_COST_BY_FIXED_PROGRAMS =
  '「採用」に設定した番組の提供金額合計が予算を超えています。予算か「採用」の設定を見直してください。';
export const EXCEED_TOTAL_COST_BY_FIXED_STATIONS =
  '設定された局ごとの採用番組数では、提供金額合計が予算を超えています。予算か局ごとの採用番組数の設定を見直してください。';
export const INVALID_DATE_TOAST = (
  availableDate: Date,
  area?: AreaInfo
): void =>
  toast({
    title: INVALID_TOAST_TITLE,
    message: `${
      area?.name
    }は${availableDate.getFullYear()}年${availableDate.getMonth() +
      1}月${availableDate.getDate()}日以降を指定してください。`,
    variant: 'error'
  });
