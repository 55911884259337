import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-workspace-logo", [_ctx.sizeClass]])
  }, [
    _createVNode(_component_Image, {
      src: _ctx.src,
      class: "image-content"
    }, null, 8, ["src"])
  ], 2))
}