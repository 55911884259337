<template>
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    :width="width"
    :height="height"
    viewBox="0 0 113 24"
    style="enable-background:new 0 0 113 24;"
    xml:space="preserve"
  >
    <path
      style="fill:#4674D7;"
      d="M29.7,1c-0.5-0.6-1.3-1-2.1-1H8C7.4,0,6.9,0.2,6.4,0.5L8,2.6l2,2.6h14.2l-3.9,13.7l0,0l1.9,2.6
	l1.6,2.1c0.4-0.3,0.8-0.8,0.9-1.4l5.4-18.8C30.3,2.5,30.2,1.7,29.7,1z"
    />
    <path
      style="fill:#002C8C;"
      d="M22.2,21.4l-1.9-2.6H6L9.9,5.2l0,0L8,2.6L6.4,0.5C6,0.8,5.7,1.3,5.5,1.9L0.1,20.7
	C-0.1,21.5,0,22.3,0.5,23c0.5,0.6,1.3,1,2.1,1h19.6c0.6,0,1.1-0.2,1.5-0.5L22.2,21.4z"
    />
    <polygon style="fill:#AAC4F7;" points="9.9,5.2 6,18.8 20.2,18.8 10,5.2 " />
    <polygon
      style="fill:#B6CCF7;"
      points="10,5.2 20.2,18.8 20.2,18.8 24.2,5.2 "
    />
    <path
      style="fill:#4674D7;"
      d="M112.5,23.4h-8.4c-3.6,0-6.5-2.9-6.5-6.5V1.1c0-0.3,0.2-0.5,0.5-0.5h3.9c0.3,0,0.5,0.2,0.5,0.5v15.8
	c0,0.9,0.7,1.6,1.6,1.6h8.4c0.3,0,0.5,0.2,0.5,0.5v3.9C113,23.2,112.8,23.4,112.5,23.4z"
    />
    <path
      style="fill:#4674D7;"
      d="M53.5,4.9L52.3,1c-0.1-0.2-0.2-0.3-0.5-0.3H35c-0.3,0-0.5,0.2-0.5,0.5V5c0,0.3,0.2,0.5,0.5,0.5h7.4
	v17.4c0,0.3,0.2,0.5,0.5,0.5h3.9c0.3,0,0.5-0.2,0.5-0.5V5.5h5.8C53.4,5.5,53.6,5.2,53.5,4.9z"
    />
    <path
      style="fill:#4674D7;"
      d="M72.5,0.6c-0.2,0-0.4,0.1-0.5,0.3l-5.3,17.2c-0.2,0.6-0.6,0.7-0.9,0.7s-0.7-0.1-0.9-0.7L59.8,0.9
	c-0.1-0.2-0.2-0.3-0.5-0.3h-4.1l0,0c-0.3,0-0.5,0.3-0.5,0.6l5.6,18.4c0.8,2.5,3.1,4.2,5.7,4.1c2.5-0.1,4.7-1.8,5.5-4.2l5.6-18.2
	C77.2,1,77,0.7,76.6,0.7L72.5,0.6L72.5,0.6z"
    />
    <path
      style="fill:#4674D7;"
      d="M96.7,22.8L91.1,4.4c-0.8-2.5-3.1-4.2-5.7-4.1c-2.5,0.1-4.7,1.8-5.5,4.2l-5.6,18.2
	c-0.1,0.3,0.1,0.6,0.5,0.6h4.1c0.2,0,0.4-0.1,0.5-0.3l1.6-5.2h6.8c0.3,0,0.5-0.3,0.5-0.6L87,13.3c0-0.2-0.2-0.3-0.4-0.3h-4.1
	l2.2-7.1c0.2-0.6,0.6-0.7,0.9-0.7s0.7,0.1,0.9,0.7l5.3,17.2c0.1,0.2,0.2,0.3,0.5,0.3h4.1C96.5,23.4,96.8,23.1,96.7,22.8z"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Logo',
  props: {
    type: {
      type: String,
      required: false,
      default: 'none'
    }
  },
  setup(props) {
    const isHeader = props.type === 'header';
    const width = isHeader ? 177 : 113;
    const height = isHeader ? 38 : 24;
    return {
      width: width,
      height: height,
      viewBox: `0 0  ${width} ${height}`
    };
  }
});
</script>

<style scoped></style>
