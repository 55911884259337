<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <g fill="currentColor">
      <slot />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'IconBase',
  props: {
    iconName: String,
    width: {
      type: [String, Number],
      default: '20'
    },
    height: {
      type: [String, Number],
      default: '20'
    },
    viewBox: {
      type: String,
      default: '0 0 20 20'
    }
  }
});
</script>
