<template>
  <g clip-path="url(#clip0_1014_5773)">
    <path
      d="M19.6211 6.95313C19.7734 6.83203 20 6.94531 20 7.13672V15.125C20 16.1602 19.1602 17 18.125 17H1.875C0.839844 17 0 16.1602 0 15.125V7.14063C0 6.94531 0.222656 6.83594 0.378906 6.95703C1.25391 7.63672 2.41406 8.5 6.39844 11.3945C7.22266 11.9961 8.61328 13.2617 10 13.2539C11.3945 13.2656 12.8125 11.9727 13.6055 11.3945C17.5898 8.5 18.7461 7.63281 19.6211 6.95313ZM10 12C10.9063 12.0156 12.2109 10.8594 12.8672 10.3828C18.0508 6.62109 18.4453 6.29297 19.6406 5.35547C19.8672 5.17969 20 4.90625 20 4.61719V3.875C20 2.83984 19.1602 2 18.125 2H1.875C0.839844 2 0 2.83984 0 3.875V4.61719C0 4.90625 0.132812 5.17578 0.359375 5.35547C1.55469 6.28906 1.94922 6.62109 7.13281 10.3828C7.78906 10.8594 9.09375 12.0156 10 12Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_1014_5773">
      <rect width="20" height="15" fill="white" transform="translate(0 2)" />
    </clipPath>
  </defs>
</template>
