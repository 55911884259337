import { defineComponent } from 'vue';

export const CAMPAIGN_ROUTES = {
  index: 'projectList',
  create: 'createBrandLiftProject',
  edit: 'editBrandLiftProject',
  clone: 'cloneBrandLiftProject',
  graph: 'projectGraph',
  cmsiIndex: 'cmsiIndex',
  cmsi: 'cmsi',
  cmsiProduct: 'cmsiProduct',
  cmsiReport: 'cmsiReport'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const PARENT_MENU_TITLE = 'エフェクティブリーチ';
const TITLE_BASE = ' | ' + PARENT_MENU_TITLE;
const TITLE = {
  index: 'リーチ分析 (Classic)' + TITLE_BASE,
  create: 'プロジェクト新規作成 | リーチ分析 (Classic)',
  edit: 'プロジェクト編集 | リーチ分析 (Classic)',
  clone: 'プロジェクト複製 | リーチ分析 (Classic)',
  graph: 'リーチ分析 (Classic)' + TITLE_BASE, // VueでProject名追加  ${Project名} | リーチ分析 (Classic)
  lp: '【テレビCMを売上につなげる TVAL | スイッチメディア',
  cmsi: '売上効果分析' + ' | CMセールスインパクト', // FIXME: v17マージ時に整理しなおす
  cmsiProduct: ' | 売上効果分析', // VueでProject名追加  ${Project名} | 売上効果分析
  cmsiReport: ' | 売上効果分析' // VueでProject名追加  ${Project名} | 売上効果分析
};

export default [
  {
    path: '/company/:companyId/brandlift',
    name: CAMPAIGN_ROUTES.index,
    meta: {
      title: TITLE.index,
      parent: PARENT_MENU_TITLE
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/campaign/index.vue')
  },
  {
    path: '/company/:companyId/brandlift/create',
    name: CAMPAIGN_ROUTES.create,
    meta: {
      title: TITLE.create,
      parent: PARENT_MENU_TITLE
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/campaign/brandlift/Create.vue')
  },
  {
    path: '/company/:companyId/brandlift/:projectId/edit',
    name: CAMPAIGN_ROUTES.edit,
    meta: {
      title: TITLE.edit,
      parent: PARENT_MENU_TITLE
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/campaign/brandlift/Update.vue')
  },
  {
    path: '/company/:companyId/brandlift/:projectId/clone',
    name: CAMPAIGN_ROUTES.clone,
    meta: {
      title: TITLE.clone,
      parent: PARENT_MENU_TITLE
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/campaign/brandlift/Clone.vue')
  },
  {
    path: '/company/:companyId/brandlift/:projectId',
    name: CAMPAIGN_ROUTES.graph,
    meta: {
      title: TITLE.graph,
      parent: PARENT_MENU_TITLE
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/campaign/brandlift/index.vue')
  },
  {
    path: '/company/:companyId/brandlift/lp',
    name: 'freeLp',
    meta: {
      title: TITLE.lp,
      parent: PARENT_MENU_TITLE
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/campaign/lp.vue')
  },
  {
    path: '/company/:companyId/cmsi',
    name: CAMPAIGN_ROUTES.cmsiIndex,
    meta: {
      parent: PARENT_MENU_TITLE
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/campaign/cmsi.vue'),
    children: [
      {
        path: '',
        name: CAMPAIGN_ROUTES.cmsi,
        meta: {
          title: TITLE.cmsi
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/campaign/cmsi/index.vue')
      },
      {
        path: ':productId',
        name: CAMPAIGN_ROUTES.cmsiProduct,
        meta: {
          title: TITLE.cmsiProduct
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/campaign/cmsi/product.vue')
      },
      {
        path: ':productId/report/:reportId',
        name: CAMPAIGN_ROUTES.cmsiReport,
        meta: {
          title: TITLE.cmsiReport
        },
        component: (): ReturnType<typeof defineComponent> =>
          import('@/pages/campaign/cmsi/report.vue')
      }
    ]
  }
];
