<template>
  <g clip-path="url(#clip0_32_1660)">
    <path
      d="M10 0C4.47714 0 0 4.47714 0 10C0 15.5229 4.47714 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47714 15.5229 0 10 0ZM10 1.93548C14.4569 1.93548 18.0645 5.54238 18.0645 10C18.0645 14.4569 14.4576 18.0645 10 18.0645C5.54306 18.0645 1.93548 14.4576 1.93548 10C1.93548 5.54306 5.54238 1.93548 10 1.93548ZM15.6534 7.18819L14.7447 6.27214C14.5565 6.08242 14.2501 6.08117 14.0604 6.26939L8.36073 11.9233L5.94976 9.49274C5.76157 9.30302 5.4552 9.30177 5.26548 9.48996L4.34939 10.3987C4.15968 10.5869 4.15843 10.8932 4.34665 11.083L8.00718 14.7731C8.19536 14.9629 8.50173 14.9641 8.69145 14.7759L15.6507 7.8725C15.8404 7.68427 15.8416 7.3779 15.6534 7.18819Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_32_1660">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="translate(0.000976562)"
      />
    </clipPath>
  </defs>
</template>
