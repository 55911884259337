<template>
  <rect width="20" height="20" rx="10" fill="none" />
  <path
    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
    fill="#F9C857"
  />
  <path
    d="M6.06669 7.15L6.83336 8.68333C6.87503 8.75833 6.95002 8.81667 7.03336 8.825L8.73336 9.08333C8.95002 9.11667 9.04169 9.38333 8.88336 9.54167L7.65836 10.75C7.60002 10.8083 7.56669 10.9 7.58336 10.9833L7.86669 12.675C7.90002 12.8917 7.67502 13.0583 7.47502 12.9583L5.95002 12.1667C5.87502 12.125 5.78336 12.125 5.70003 12.1667L4.17502 12.9583C3.97502 13.0583 3.75003 12.8917 3.78336 12.675L4.06669 10.9833C4.08336 10.9 4.05003 10.8083 3.99169 10.75L2.76669 9.54167C2.60836 9.38333 2.70003 9.11667 2.91669 9.08333L4.61669 8.825C4.70003 8.80833 4.77502 8.75833 4.81669 8.68333L5.58336 7.15C5.68336 6.95 5.96669 6.95 6.06669 7.15Z"
    fill="white"
  />
  <path
    d="M14.4167 7.15L15.1833 8.68333C15.225 8.75833 15.3 8.81667 15.3833 8.825L17.0833 9.08333C17.3 9.11667 17.3917 9.38333 17.2333 9.54167L16.0083 10.75C15.95 10.8083 15.9167 10.9 15.9333 10.9833L16.2167 12.675C16.25 12.8917 16.025 13.0583 15.825 12.9583L14.3 12.1667C14.225 12.125 14.1333 12.125 14.05 12.1667L12.525 12.9583C12.325 13.0583 12.1 12.8917 12.1333 12.675L12.4167 10.9833C12.4333 10.9 12.4 10.8083 12.3417 10.75L11.1167 9.54167C10.9583 9.38333 11.05 9.11667 11.2667 9.08333L12.9667 8.825C13.05 8.80833 13.125 8.75833 13.1667 8.68333L13.9333 7.15C14.0333 6.95 14.3167 6.95 14.4167 7.15Z"
    fill="white"
  />
</template>
