import { defineComponent } from 'vue';

export const REALTIME_RATING_ROUTES = {
  index: 'realtimeIndex'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE = {
  index: 'リアルタイム視聴率'
} as const;

export default [
  {
    path: '/company/:companyId/realtimerating',
    name: REALTIME_RATING_ROUTES.index,
    meta: {
      title: TITLE.index
    },
    component: (): ReturnType<typeof defineComponent> =>
      import('@/pages/realtimerating/index.vue')
  }
];
