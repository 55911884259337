<template>
  <div
    :class="[
      flexClass,
      justifyClass,
      alignClass,
      flexWrapClass,
      fullWidthClass
    ]"
    :style="{
      whiteSpace,
      flexDirection,
      height,
      width,
      gap,
      maxWidth,
      overflow
    }"
  >
    <slot />
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
export default defineComponent({
  props: {
    flex: {
      type: Boolean,
      default: true
    },
    inline: Boolean,
    justifySpaceAround: Boolean,
    justifySpaceBetween: Boolean,
    justifyCenter: Boolean,
    justifyEnd: Boolean,
    alignEnd: Boolean,
    alignCenter: Boolean,
    alignBaseline: Boolean,
    alignFlexStart: Boolean,
    flexWrap: Boolean,
    whiteSpace: String,
    fullWidth: Boolean,
    flexDirection: String,
    height: String,
    width: String,
    maxWidth: String,
    overflow: String,
    gap: String
  },
  setup(props) {
    return {
      flexClass: computed(() => {
        if (props.inline) {
          return 'inline-flex';
        } else if (props.flex) {
          return 'flex';
        } else {
          return '';
        }
      }),
      justifyClass: computed(() => {
        if (props.justifySpaceBetween) {
          return 'justify space-between';
        } else if (props.justifyCenter) {
          return 'justify center';
        } else if (props.justifyEnd) {
          return 'justify end';
        } else if (props.justifySpaceAround) {
          return 'justify space-around';
        } else {
          return '';
        }
      }),
      alignClass: computed(() => {
        if (props.alignEnd) {
          return 'align-end';
        } else if (props.alignCenter) {
          return 'align-center';
        } else if (props.alignBaseline) {
          return 'align-baseline';
        } else if (props.alignFlexStart) {
          return 'align-flex-start';
        } else {
          return '';
        }
      }),
      flexWrapClass: computed(() => (props.flexWrap ? 'flex-wrap' : '')),
      fullWidthClass: computed(() => (props.fullWidth ? 'full-width' : ''))
    };
  }
});
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.justify {
  &.space-between {
    justify-content: space-between;
  }
  &.space-around {
    justify-content: space-around;
  }
  &.center {
    justify-content: center;
  }
  &.end {
    justify-content: flex-end;
  }
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-flex-start {
  align-items: flex-start;
}
.flex-wrap {
  flex-wrap: wrap;
}
.full-width {
  width: 100%;
}
</style>
