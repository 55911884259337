<template>
  <path
    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
    fill="#E4EBFA"
  />
  <path
    d="M11.4352 16.2226C10.612 16.2226 9.91394 15.9413 9.35129 15.3786C8.78864 14.816 8.50732 14.1283 8.51774 13.3051C8.51774 12.4924 8.79906 11.8047 9.36171 11.2421C9.92436 10.6794 10.612 10.3877 11.4352 10.3877C12.2583 10.3877 12.9356 10.669 13.4982 11.2421C14.0609 11.8047 14.3526 12.5028 14.3526 13.3051C14.3526 14.1074 14.0713 14.8264 13.4982 15.389C12.9356 15.9517 12.2375 16.233 11.4352 16.2226Z"
    fill="#4674D7"
  />
  <path
    d="M5.60034 22.0573V20.213C5.60034 19.8692 5.6837 19.5566 5.86083 19.2545C6.02754 18.9523 6.26719 18.7126 6.54851 18.5355C7.29871 18.0979 8.08016 17.7645 8.89288 17.5352C9.71601 17.306 10.56 17.1914 11.4352 17.1914C12.3104 17.1914 13.1544 17.306 13.9776 17.5352C14.8007 17.7645 15.5821 18.0979 16.3219 18.5355C16.6137 18.7126 16.8429 18.9523 17.02 19.2545C17.1867 19.5566 17.2805 19.8796 17.2701 20.213V22.0573H5.60034Z"
    fill="#4674D7"
  />
  <path
    d="M28.5647 16.2226C27.7416 16.2226 27.0435 15.9413 26.4808 15.3786C25.9182 14.816 25.6368 14.1283 25.6473 13.3051C25.6473 12.4924 25.9286 11.8047 26.4912 11.2421C27.0539 10.6794 27.7416 10.3877 28.5647 10.3877C29.3878 10.3877 30.0651 10.669 30.6277 11.2421C31.1904 11.8047 31.4821 12.5028 31.4821 13.3051C31.4821 14.1074 31.2008 14.8264 30.6277 15.389C30.0651 15.9517 29.367 16.233 28.5647 16.2226Z"
    fill="#4674D7"
  />
  <path
    d="M22.7299 22.0573V20.213C22.7299 19.8692 22.8132 19.5566 22.9903 19.2545C23.1571 18.9523 23.3967 18.7126 23.678 18.5355C24.4282 18.0979 25.2097 17.7645 26.0224 17.5352C26.8455 17.306 27.6895 17.1914 28.5647 17.1914C29.44 17.1914 30.2839 17.306 31.1071 17.5352C31.9302 17.7645 32.7117 18.0979 33.4514 18.5355C33.7432 18.7126 33.9724 18.9523 34.1495 19.2545C34.3163 19.5566 34.41 19.8796 34.3996 20.213V22.0573H22.7299Z"
    fill="#4674D7"
  />
  <path
    d="M20 22.5259C18.6872 22.5259 17.5723 22.0779 16.6866 21.1818C15.801 20.2962 15.3425 19.1813 15.3425 17.8684C15.3425 16.5868 15.7906 15.472 16.6762 14.5759C17.5723 13.6694 18.6872 13.2109 19.9896 13.2109C21.292 13.2109 22.3861 13.6694 23.2821 14.5655C24.1782 15.4615 24.6367 16.5764 24.6367 17.858C24.6367 19.1396 24.1782 20.2857 23.2821 21.1714C22.3861 22.057 21.2712 22.5051 19.9896 22.5051L20 22.5259Z"
    fill="#4674D7"
  />
  <path
    d="M19.9999 13.6904C21.1669 13.6904 22.1463 14.0967 22.959 14.9094C23.7718 15.7221 24.1781 16.712 24.1781 17.8686C24.1781 19.0251 23.7718 20.0462 22.959 20.8485C22.1463 21.6508 21.1565 22.0467 19.9999 22.0467C18.8121 22.0467 17.8223 21.6404 17.02 20.8381C16.2177 20.0358 15.8217 19.0459 15.8217 17.8581C15.8217 16.6912 16.2281 15.7117 17.0304 14.899C17.8327 14.0863 18.8225 13.6799 20.0103 13.6799M20.0103 12.7422C18.5725 12.7422 17.343 13.2423 16.3635 14.2426C15.3841 15.2324 14.8944 16.4515 14.8944 17.8686C14.8944 19.3064 15.3945 20.5255 16.374 21.5154C17.3534 22.4948 18.5829 22.9949 20.0208 22.9949C21.4586 22.9949 22.6569 22.4948 23.6467 21.5258C24.6366 20.5463 25.1471 19.3169 25.1471 17.879C25.1471 16.4411 24.647 15.2429 23.6571 14.253C22.6673 13.2632 21.4482 12.763 20.0312 12.763L20.0103 12.7422Z"
    fill="#E4EBFA"
  />
  <path
    d="M11.1643 30.8931V27.7776C11.1643 27.2046 11.3102 26.6628 11.5915 26.173C11.8728 25.6729 12.2688 25.277 12.7481 24.9748C13.8525 24.3288 15.0195 23.8287 16.2281 23.4952C17.4368 23.1618 18.708 22.9951 20 22.9951C21.292 22.9951 22.5632 23.1618 23.7718 23.4952C24.9805 23.8287 26.1474 24.3288 27.2519 24.9748C27.7416 25.277 28.1375 25.6833 28.4189 26.173C28.7002 26.6732 28.8461 27.215 28.8461 27.7776V30.8931H11.1643Z"
    fill="#4674D7"
  />
  <path
    d="M20 23.4534C21.2503 23.4534 22.4694 23.6201 23.6468 23.9431C24.8242 24.2661 25.9391 24.7454 27.0123 25.3706C27.4291 25.631 27.7625 25.9645 28.0021 26.4021C28.2418 26.8293 28.3668 27.2877 28.3668 27.7774V30.424H11.6332V27.7774C11.6332 27.2877 11.7583 26.8293 11.9979 26.4021C12.2376 25.9749 12.571 25.631 12.9878 25.3706C14.061 24.7454 15.1758 24.2661 16.3532 23.9431C17.5306 23.6201 18.7393 23.4534 20 23.4534ZM20 22.5156C18.6663 22.5156 17.3535 22.6928 16.1032 23.0366C14.8528 23.3804 13.6442 23.891 12.5085 24.5578C11.9458 24.9017 11.4978 25.3601 11.1748 25.9332C10.8518 26.5063 10.6851 27.121 10.6851 27.7774V31.3617H29.2941V27.7774C29.2941 27.1314 29.1274 26.5063 28.8044 25.9332C28.4814 25.3601 28.0334 24.9017 27.4812 24.5683C26.335 23.891 25.1264 23.3804 23.876 23.0366C22.6257 22.6928 21.3129 22.5156 19.9792 22.5156H20Z"
    fill="#E4EBFA"
  />
</template>
