<template>
  <g clip-path="url(#clip0_38_1895)">
    <path
      d="M4.26343 9.54668L13.437 0.298121C13.7307 0.00441668 14.2056 0.00441668 14.4993 0.298121L15.7366 1.53543C16.0303 1.82913 16.0303 2.30406 15.7366 2.59776L8.33154 10.0778L15.7366 17.5579C16.0303 17.8516 16.0303 18.3266 15.7366 18.6203L14.4993 19.8576C14.2056 20.1513 13.7307 20.1513 13.437 19.8576L4.26343 10.609C3.96973 10.3153 3.96973 9.84038 4.26343 9.54668Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_38_1895">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="translate(0.000976562)"
      />
    </clipPath>
  </defs>
</template>
