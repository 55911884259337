import { createRouter, createWebHistory } from 'vue-router';

import campaign, { CAMPAIGN_ROUTES } from './campaign';
import cmsi, { CMSI_ROUTES } from './cmsi';
import cmsis, { CMSIS_ROUTES } from './cmsis';
import custom, { CUSTOM_ROUTES } from './custom';
import datasearch, { DATASEARCH_ROUTES } from './datasearch';
import planning, { PLANNING_ROUTES } from './planning';
import project, { PROJECT_ROUTES } from './project';
import reachanalysis, { REACH_ANALYSIS_ROUTES } from './reachanalysis';
import realtimerating, { REALTIME_RATING_ROUTES } from './realtimerating';

import company, { COMPANY_ROUTES } from './company';
import mypage, { MYPAGE_ROUTES } from './mypage';
import settings, { SETTINGS_ROUTES } from './settings';
import signup, { SIGNUP_ROUTES, SIGNUP_WORKSPACE_ROUTERS } from './signup';
import workspace, { WORKSPACE_ROUTES } from './workspace';

import { toast } from '@/components/ui/Toast';
import Error from '@/pages/Error.vue';
import ForgotPassword from '@/pages/ForgotPassword.vue';
import RootIndex from '@/pages/Index.vue';
import Login from '@/pages/Login.vue';
import NotFound from '@/pages/NotFound.vue';
import ResetPassword from '@/pages/ResetPassword.vue';
import { useAuthStore } from '@/store/auth';
import { useUserInfoStore } from '@/store/userInfo';

const ROUTE_NAMES = {
  root: 'root',
  campaign: 'campaign',
  reachanalysis: 'reachanalysis',
  login: 'login',
  signup: 'signup',
  planning: 'planning',
  datasearch: 'datasearch',
  forgotPassword: 'forgotPassword',
  realtimerating: 'realtimerating',
  resetPassword: 'resetPassword',
  workspaceTop: 'workspace',
  notFound: 'notFound',
  error: 'error'
};

// https://www.notion.so/switchm/bf4a3d976e9d480598d9b7e52f29568b
const TITLE = {
  login: 'ログイン',
  forgotPassword: 'パスワード再設定',
  resetPassword: 'パスワード初期設定'
} as const;

export {
  ROUTE_NAMES,
  MYPAGE_ROUTES,
  WORKSPACE_ROUTES,
  COMPANY_ROUTES,
  PROJECT_ROUTES,
  CAMPAIGN_ROUTES,
  REACH_ANALYSIS_ROUTES,
  CUSTOM_ROUTES,
  SETTINGS_ROUTES,
  SIGNUP_ROUTES,
  SIGNUP_WORKSPACE_ROUTERS,
  PLANNING_ROUTES,
  DATASEARCH_ROUTES,
  REALTIME_RATING_ROUTES,
  CMSIS_ROUTES,
  CMSI_ROUTES
};

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: ROUTE_NAMES.notFound,
    component: NotFound,
    meta: { noAuth: true }
  },
  {
    path: '/',
    name: ROUTE_NAMES.root,
    component: RootIndex
  },
  {
    path: '/error',
    name: ROUTE_NAMES.error,
    component: Error
  },
  {
    path: '/login',
    name: ROUTE_NAMES.login,
    component: Login,
    meta: {
      noAuth: true,
      title: TITLE.login
    }
  },
  {
    path: '/login/remind/',
    name: ROUTE_NAMES.forgotPassword,
    component: ForgotPassword,
    meta: {
      noAuth: true,
      title: TITLE.forgotPassword
    }
  },
  {
    path: '/login/reset/',
    name: ROUTE_NAMES.resetPassword,
    component: ResetPassword,
    meta: {
      noAuth: true,
      title: TITLE.resetPassword
    },
    props: true
  },
  mypage,
  workspace,
  ...company,
  ...campaign,
  ...reachanalysis,
  ...custom,
  ...project,
  ...settings,
  ...signup,
  ...planning,
  ...datasearch,
  ...realtimerating,
  ...cmsis,
  ...cmsi
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  }
});

router.beforeEach(async (to, _, next) => {
  const noAuth = to.matched.some(record => record.meta.noAuth);
  const authStore = useAuthStore();
  const isLoggedIn = authStore.isLoggedIn;

  // TODO：vue3対応のmetaライブラリあれば入れ替え
  // ページタイトル設定
  if (to.meta.title && to.meta.title !== 'sips-saas-ui') {
    document.title = (to.meta.title as string) + ' | TVAL';
  } else {
    document.title = 'TVAL | TVAL';
  }

  if (!isLoggedIn && !noAuth && to.name !== 'error') {
    next({ name: ROUTE_NAMES.login });
  } else if (
    (to.name === ROUTE_NAMES.login && isLoggedIn) ||
    (to.name === ROUTE_NAMES.signup && isLoggedIn)
  ) {
    next({ name: ROUTE_NAMES.root });
  } else {
    if (isLoggedIn) {
      const store = useUserInfoStore();
      const authStore = useAuthStore();
      try {
        await store.fetchUserInfo();
      } catch (e) {
        const error = e as Error;
        await authStore.logout();
        store.changeIsLoadingUserInfo(false);
        next({ name: ROUTE_NAMES.login });
        toast({
          title: 'ログアウトしました',
          message: error.message,
          variant: 'error'
        });
      }
    }
    next();
  }
});

export default router;
