import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59c7a085"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ui-image", [_ctx.loadingClass, _ctx.noImageClass]]),
    style: _normalizeStyle({ width: _ctx.width, minWidth: _ctx.minWidth, height: _ctx.height || _ctx.width })
  }, [
    _createElementVNode("img", {
      ref: "imageElement",
      src: _ctx.src,
      class: "image-content",
      alt: _ctx.alt
    }, null, 8, _hoisted_1)
  ], 6))
}