<template>
  <div
    :style="{
      flex,
      position,
      top,
      left,
      zIndex,
      width,
      height,
      textAlign,
      marginTop,
      marginBottom,
      marginLeft,
      paddingTop,
      paddingBottom,
      paddingRight,
      paddingLeft,
      overflow,
      color,
      fill,
      minWidth,
      minHeight,
      maxWidth,
      maxHeight,
      display,
      alignItems,
      alignSelf,
      backgroundColor
    }"
  >
    <slot />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'BOX',
  /**
   *  Props
   *  ---------------------
   *  MUI の BOXを参照
   *  https://mui.com/system/properties/
   *  必要に応じて、追加していく
   * */
  props: {
    flex: String,
    width: String,
    height: String,
    textAlign: String,
    marginTop: String,
    marginBottom: String,
    marginLeft: String,
    paddingTop: String,
    paddingBottom: String,
    paddingRight: String,
    paddingLeft: String,
    position: String,
    top: String,
    left: String,
    zIndex: String,
    overflow: String,
    color: String,
    fill: String,
    minWidth: String,
    minHeight: String,
    maxWidth: String,
    maxHeight: String,
    display: String,
    alignItems: String,
    alignSelf: String,
    backgroundColor: String
  }
});
</script>
