import { TOAST } from '@/common/constant';
import IconButton from '@/components/ui/IconButton.vue';
import { useToast } from 'vue-toastification';
import ToastContent from './Content.vue';

export const toast = ({
  title,
  message,
  variant
}: {
  title?: string;
  message?: string;
  variant?: 'success' | 'error' | 'attention';
}): void => {
  // docs: https://github.com/Maronato/vue-toastification#render-a-component-with-props-and-events

  const toast = useToast();
  const content = {
    component: ToastContent,
    props: {
      title,
      message,
      variant
    }
  };
  toast(content, {
    closeButton: <IconButton icon-name="close" />,
    toastClassName: variant
  });
};

export const successToast = (): void => {
  toast({
    title: TOAST.success.title,
    message: TOAST.success.message,
    variant: 'success'
  });
};

export const errorToast = (): void => {
  toast({
    title: TOAST.error.title,
    message: TOAST.error.message,
    variant: 'error'
  });
};

export const attentionToast = (): void => {
  toast({
    title: TOAST.attention.title,
    message: TOAST.attention.message,
    variant: 'attention'
  });
};
