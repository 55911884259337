import { AccountApi, WorkspaceApi } from '@/api';

export const fetchAccountAvatar = async (
  userId: string
): Promise<string | null> => {
  return `${process.env.VUE_APP_API_BASE_URL}/accounts/${userId}/avatar-image`;
};

export const getWorkspaceLogoUrl = (workspaceId: number): string => {
  return `${process.env.VUE_APP_API_BASE_URL}/workspaces/${workspaceId}/logo-image`;
};

export const fetchWorkspaceLogo = async (
  workspaceId: string
): Promise<string | null> => {
  return `${process.env.VUE_APP_API_BASE_URL}/workspaces/${workspaceId}/logo-image`;
};

// fetchAccountAvatarData for ui/UploadImage
// ref: https://app.clickup.com/t/2kwj1a7?comment=882907687
const options = {
  responseType: 'blob'
};
const convert = (data: Blob) => {
  return URL.createObjectURL(data);
};

export const fetchAccountAvatarData = async (
  userId: number
): Promise<string> => {
  try {
    const result = await AccountApi.getAccountsAccountIdAvatarImage(
      userId,
      options
    );
    if (result === undefined || result.status === 204) {
      return '';
    }
    return convert(result.data);
  } catch (e) {
    console.error(e);
    return '';
  }
};

// fetchWorkspaceLogoData for ui/UploadImage
// ref: https://app.clickup.com/t/2kwj1a7?comment=882907687
export const fetchWorkspaceLogoData = async (
  workspaceId: number
): Promise<string> => {
  try {
    const result = await WorkspaceApi.getWorkspacesWorkspaceIdLogoImage(
      workspaceId,
      options
    );
    if (result === undefined || result.status === 204) {
      return '';
    }
    return convert(result.data);
  } catch (e) {
    console.error(e);
    return '';
  }
};
