<template>
  <div
    style="display: flex; justify-content: center; align-items: center; height: 100vh;"
  >
    <div style="text-align: center;">
      <div class="mb-4">
        <Icon name="alert" color="error" class="mr-1" sides="48" />
      </div>
      <h1 style="font-size: 28px; font-weight: bold;" class="mb-4">
        ページが見つかりません。
      </h1>
      <div class="mb-4">
        <typography size="xl" class="mb-2" lineHeight="1.2">
          アクセス先のURLが存在しません。 URLをお確かめください。
        </typography>
        <typography>(ID:404. Page Not Found. ) </typography>
      </div>
      <Button v-if="route.params.backTop" color="white">
        <a href="/">
          TOPにもどる
        </a>
      </Button>
      <Button v-else color="white" @click="router.go(-1)">
        前の画面に戻る
      </Button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Icon from '@/components/ui/Icon.vue';
import Typography from '@/components/ui/Typography.vue';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'NotFound',
  components: {
    Icon,
    Typography,
    Button
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    return {
      route,
      router
    };
  }
});
</script>
