<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4615 1.5H1.53846C1.13044 1.5 0.739122 1.66282 0.450605 1.95265C0.162087 2.24248 0 2.63557 0 3.04545V16.9545C0 17.3644 0.162087 17.7575 0.450605 18.0473C0.739122 18.3372 1.13044 18.5 1.53846 18.5H18.4615C18.8696 18.5 19.2609 18.3372 19.5494 18.0473C19.8379 17.7575 20 17.3644 20 16.9545V3.04545C20 2.63557 19.8379 2.24248 19.5494 1.95265C19.2609 1.66282 18.8696 1.5 18.4615 1.5ZM18.4615 3.04545V6.13636H1.53846V3.04545H18.4615ZM18.4615 16.9545H1.53846V7.68182H18.4615V16.9545Z"
      fill="#425252"
    />
  </svg>
</template>
