<template>
  <rect width="20" height="20" rx="10" fill="none" />
  <path
    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
    fill="#FC74A6"
  />
  <path
    d="M10 2.92499L10.7667 4.45833C10.8084 4.53333 10.8834 4.59166 10.9667 4.59999L12.6667 4.85833C12.8834 4.89166 12.975 5.15833 12.8167 5.31666L11.5917 6.52499C11.5334 6.58333 11.5 6.67499 11.5167 6.75833L11.8 8.44999C11.8334 8.66666 11.6084 8.83333 11.4084 8.73333L9.88337 7.94166C9.80837 7.89999 9.71671 7.89999 9.63337 7.94166L8.10837 8.73333C7.90837 8.83333 7.68337 8.66666 7.71671 8.44999L8.00004 6.75833C8.01671 6.67499 7.98337 6.58333 7.92504 6.52499L6.70004 5.31666C6.54171 5.15833 6.63337 4.89166 6.85004 4.85833L8.55004 4.59999C8.63337 4.58333 8.70837 4.53333 8.75004 4.45833L9.51671 2.92499C9.61671 2.72499 9.90004 2.72499 10 2.92499Z"
    fill="white"
  />
  <path
    d="M6.06669 9.49166L6.83336 11.025C6.87502 11.1 6.95002 11.1583 7.03336 11.1667L8.73336 11.425C8.95002 11.4583 9.04169 11.725 8.88336 11.8833L7.65836 13.0917C7.60002 13.15 7.56669 13.2417 7.58336 13.325L7.86669 15.0167C7.90002 15.2333 7.67502 15.4 7.47503 15.3L5.95003 14.5083C5.87503 14.4667 5.78336 14.4667 5.70003 14.5083L4.17503 15.3C3.97503 15.4 3.75003 15.2333 3.78336 15.0167L4.06669 13.325C4.08336 13.2417 4.05002 13.15 3.99169 13.0917L2.76669 11.8833C2.60836 11.725 2.70003 11.4583 2.91669 11.425L4.61669 11.1667C4.70003 11.15 4.77503 11.1 4.81669 11.025L5.58336 9.49166C5.68336 9.29166 5.96669 9.29166 6.06669 9.49166Z"
    fill="white"
  />
  <path
    d="M14.4167 9.49166L15.1833 11.025C15.225 11.1 15.3 11.1583 15.3833 11.1667L17.0833 11.425C17.3 11.4583 17.3917 11.725 17.2333 11.8833L16.0083 13.0917C15.95 13.15 15.9167 13.2417 15.9333 13.325L16.2167 15.0167C16.25 15.2333 16.025 15.4 15.825 15.3L14.3 14.5083C14.225 14.4667 14.1333 14.4667 14.05 14.5083L12.525 15.3C12.325 15.4 12.1 15.2333 12.1333 15.0167L12.4167 13.325C12.4333 13.2417 12.4 13.15 12.3417 13.0917L11.1167 11.8833C10.9583 11.725 11.05 11.4583 11.2667 11.425L12.9667 11.1667C13.05 11.15 13.125 11.1 13.1667 11.025L13.9333 9.49166C14.0333 9.29166 14.3167 9.29166 14.4167 9.49166Z"
    fill="white"
  />
</template>
