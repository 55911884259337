import { CompanyApi } from '@/api';
import { httpCode } from '@/common/constant';
import { toast } from '@/components/ui/Toast';
import { MYPAGE_ROUTES } from '@/router';
import { useUserInfoStore } from '@/store/userInfo';
import axios from 'axios';
import { useRouter } from 'vue-router';
import useLoading from './loading';

const _fetchLastCompany = async () => {
  const store = useUserInfoStore();
  const router = useRouter();
  try {
    if (store.userInfo.id) {
      const res = await CompanyApi.getAccountsAccountIdActiveCompany(
        store.userInfo.id
      );
      return res.data;
    }
  } catch (error) {
    if (
      axios.isAxiosError(error) &&
      error.response?.status === httpCode.serverError
    ) {
      toast({
        title: 'カンパニー取得エラー',
        message: '最後にアクセスしたカンパニーの取得に失敗しました',
        variant: 'error'
      });
    }
    await router.push({ name: MYPAGE_ROUTES.top });
  }
};

export const [isFetchingLastCompany, fetchLastCompany] = useLoading(
  _fetchLastCompany
);
