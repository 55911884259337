
import { defineComponent, PropType } from 'vue';
import LoadingSvg from '@/assets/images/loading.svg?inline';

export default defineComponent({
  name: 'Loading',
  components: { LoadingSvg },
  props: {
    sides: String,
    color: {
      type: String as PropType<'default' | 'white' | 'dark'>,
      default: 'default'
    },
    size: {
      type: String as PropType<'s' | 'm'>,
      default: 's'
    }
  }
});
